import React from 'react';
import { createStyles, InputLabel, withStyles, WithStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import { TState } from '../../../../RootReducer';
import { OAuth } from '../../../../Features/Authentication/redux-config';
import { connect } from 'react-redux';
import { TActivityLogFilter, ActivityLogFilter } from '../../../../Features/CldDashboard/Filters/activityLog';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import utilities from '../../../../Resources/Utils';
import { RoleFilter, TRoleFilter } from '../../../../Features/CldDashboard/Filters/roles';

interface IProps extends IStateProps, IDispatchProps, WithStyles<typeof STYLES> {}

interface IStateProps {
    appliedFilters: TRoleFilter;
}
interface IDispatchProps {
    applyFilter: (filter: TRoleFilter) => void;
}

type SelectOption = {
    name: string;
    value: TActivityLogFilter['type'];
};

const CldRoleFilter: React.FC<IProps> = props => {
    const { appliedFilters, classes } = props;

    const handleChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        child: React.ReactNode
    ) => {
        const filter = { ...appliedFilters, role: event.target.value as SelectOption['value'] };
        props.applyFilter(filter);
    };

    const SELECT_OPTIONS: Array<SelectOption> = [
        { name: 'All', value: 'all' },
        { name: 'Admin', value: 'CLD_ADMIN' },
        { name: 'Creator', value: 'CLD_CREATOR' },
        { name: 'Supervisor', value: 'CLD_SUPERVISOR' },
    ];

    return (
        <FormControl variant='filled' fullWidth>
            <InputLabel classes={{ shrink: classes.shrink }} htmlFor={`applied-role-filter`}>
                Role
            </InputLabel>
            <Select
                value={appliedFilters.role || 'all'}
                onChange={handleChange}
                inputProps={{
                    name: '',
                    id: `applied-role-filter`,
                }}
            >
                {_.map(SELECT_OPTIONS, item => (
                    <MenuItem key={`${item.value}-role`} value={item.value}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state: TState): IStateProps => ({
    appliedFilters: _.get(state.CldDashboard, 'roles.filter') || {},
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    applyFilter: (filter: TRoleFilter) => dispatch(RoleFilter.applyFilter(filter)),
});

const STYLES = (theme: Theme) =>
    createStyles({
        shrink: {
            transform: 'translate(0px, 10px) scale(0.75) !important',
            fontWeight: theme.typography.fontWeightMedium,
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(CldRoleFilter));
