import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Paper,
    Button,
    Dialog,
    Typography,
    Fab,
    DialogContent,
    Slide,
    Theme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Search from '../../Components/Search';
import {
    ODocuments,
    mapParentIdsToValues,
    mapvaluesToParentIds,
    mapValueAndId,
    buildFilterFromFormData,
    HOME_URL,
} from '../../Features/LTDDocuments/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import Tree from '../../Components/Tree';
import { TNode } from '../../Features/DocumentsTree/@types';
import { ODocumentTree } from '../../Features/DocumentsTree/redux-config';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { HISTORY } from '../RootLayout';
import { TState } from '../../RootReducer';
import moment from 'moment';
import {
    TChip,
    TSearchQueryFieldKey,
} from '../../Features/LTDDocuments/@types';
import { OAdvancedSearch } from '../../Features/AdvancedSearch/redux-config';
import { ONewLTDDocument } from '../../Features/AddNewLTDDocument/redux-config';
import { TSort } from '../../Features/LTDDocuments/@types';
import { OResponsive } from '../../Features/Responsive/redux-config';
import { TResponsiveState } from '../../Features/Responsive/@types';
import SearchByUrl from './SearchByUrl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TutorialFilms } from '../../Features/TutorialFilm/tutorialFilm';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import {
    BROWSE_LDT_COLLECTIONS_TITLE,
    SEARCH_PANEL_ADVANCED_SEARCH_BUTTON,
    SEARCH_PANEL_CASE_NAME,
    SEARCH_PANEL_FILE_NAME,
    SEARCH_PANEL_FILE_NUMBER,
    SEARCH_PANEL_SEARCH_INPUT_PLACEHOLDER,
} from '../../Features/Translations/translationKeys.ltd';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    WithTranslationProps { }

interface IDispatchProps {
    applyQuery: Function;
    searchDocuments: Function;
    openNode: Function;
    closeNode: Function;
    openDocument: Function;
    setChipList: Function;
    applySearchTerm: Function;
    showMatchedText: Function;
    resetTree: Function;
    onCheck: Function;
    openAdvancedSearchDialog: Function;
    createLTDDocument: (nodeId: string) => void;
    applySort: (sort: TSort) => void;
    closeResponsiveDialog: () => void;
}

interface IStateProps {
    itemsSelected: Array<string>;
    tree: Array<TNode>;
    formData: Record<string, any>;
    responsive: TResponsiveState['ltd'];
    fetchingNodes?: boolean;
}

const searchFilters: Array<{
    label: string;
    name: string;
    key: TSearchQueryFieldKey;
    translationKey?: string;
}> = [
        {
            label: 'File title',
            name: 'title',
            key: 'title',
            translationKey: SEARCH_PANEL_FILE_NAME,
        },
        {
            label: 'Case name',
            name: 'caseName_en',
            key: 'caseName',
            translationKey: SEARCH_PANEL_CASE_NAME,
        },
        {
            label: 'File number',
            name: 'externalId.search',
            key: 'fileNumber',
            translationKey: SEARCH_PANEL_FILE_NUMBER,
        },
    ];

export const defaultDate = {
    dateFrom: '05.02.1918',
    dateTo: '01.01.2019',
};

function Transition(props) {
    return (
        <Slide
            direction='up'
            style={{ transformOrigin: '100% 0% 0' }}
            in={true}
            {...props}
            timeout={380}
        />
    );
}

class LeftNav extends Component<Props & IStateProps & RouteComponentProps> {
    submit = (formData, isExactSearch) => {
        if (!_.isUndefined(formData.term)) {
            this.props.applySearchTerm(formData.term);
            formData = _.omit(formData, 'term');
        }

        const _formData = this.props.formData;

        let query = buildFilterFromFormData(_formData, isExactSearch);
        this.props.applyQuery(isExactSearch);
        this.props.closeResponsiveDialog();
        this.props.searchDocuments(0, true, false);
    };

    onTreeContentClick = (root: TNode) => {
        const _formData = this.props.formData;
        let query = buildFilterFromFormData(_formData);
        this.props.applyQuery({
            ...query,
            where: { ...query.where, parentIds: [root.id] },
        });
        mapValueAndId(root);
        this.props.showMatchedText(false);
        // this.props.applySort('Latest')
        this.props.closeResponsiveDialog();
        this.props.searchDocuments(0, true, false);
    };

    onCheck = (node: TNode) => {
        this.props.onCheck(node);
    };

    onCollapse = () => {
        this.props.resetTree();
    };

    onAdd = (node: TNode) => {
        if (this.props.createLTDDocument) this.props.createLTDDocument(node.id);
    };

    render() {
        const { classes, tree, itemsSelected = [], responsive, getTranslation, fetchingNodes } = this.props;
        const { dialogFor, dialogOpen = false } = responsive;

        return (
            <div className={classes.leftNav}>
                <Paper elevation={0} className={classes.root}>
                    <Button
                        style={{ minHeight: 52 }}
                        fullWidth
                        variant='contained'
                        onClick={e => this.props.openAdvancedSearchDialog()}
                    >
                        {getTranslation(SEARCH_PANEL_ADVANCED_SEARCH_BUTTON) || 'Advanced Search'}
                    </Button>
                    {TutorialFilms()['tutorial-film-2']}
                    <Search type='LTD' filters={searchFilters} onSubmit={this.submit} />
                    <Typography className={classes.label}>
                        {getTranslation(BROWSE_LDT_COLLECTIONS_TITLE) || 'BROWSE LTD COLLECTIONS'}
                        {TutorialFilms()['tutorial-film-4']}
                    </Typography>
                    <div className={classes.documentTree}>
                        {fetchingNodes ? (
                            <div
                                style={{ display: 'flex', justifyContent: 'center', width: 'auto', paddingTop: '4rem' }}
                            >
                                <CircularProgress color='primary' />
                            </div>
                        ) : (
                            <Tree
                                expandOnKey='expandOnKey' //To be removed
                                onCollapse={this.onCollapse}
                                onOpen={this.props.openNode}
                                onClose={this.props.closeNode}
                                onClick={this.onTreeContentClick}
                                disableCheck={false}
                                // onAdd={this.onAdd}
                                checked={itemsSelected}
                                tree={tree}
                                onCheck={this.onCheck}
                            />
                        )}
                    </div>
                </Paper>

                {/** components below are exclusive for mobile devices < 600px  */}

                <Dialog
                    scroll='paper'
                    className={classes.filterDialog}
                    open={dialogOpen && (dialogFor === 'tree' || dialogFor === 'search')}
                    fullScreen
                >
                    <div className={classes.dialogHeader}>
                        <Typography color='inherit'>{_.upperCase(dialogFor)}</Typography>
                        <Fab size='small' onClick={e => this.props.closeResponsiveDialog()}>
                            <i className='material-icons'>close</i>
                        </Fab>
                    </div>
                    <div className={classes.dialogContainer}>
                        {dialogFor === 'tree' ? (
                            <div className={classes.documentTree}>
                                {fetchingNodes ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: 'auto',
                                            paddingTop: '4rem',
                                        }}
                                    >
                                        <CircularProgress color='primary' />
                                    </div>
                                ) : (
                                    <Tree
                                        expandOnKey='hasChildrenMenuNode'
                                        onCollapse={this.onCollapse}
                                        onOpen={this.props.openNode}
                                        onClose={this.props.closeNode}
                                        onClick={this.onTreeContentClick}
                                        disableCheck={false}
                                        // onAdd={this.onAdd}
                                        checked={itemsSelected}
                                        tree={tree}
                                        onCheck={this.onCheck}
                                    />
                                )}
                                {/* <Button className={classes.mobileTreeSubmit} fullWidth variant="contained" color="secondary">submit</Button> */}
                            </div>
                        ) : null}
                        {dialogFor === 'search' ? (
                            <>
                                <Button
                                    style={{ minHeight: 52 }}
                                    fullWidth
                                    variant='contained'
                                    onClick={e => this.props.openAdvancedSearchDialog()}
                                >
                                    Advanced Search
                                </Button>
                                <Search type='LTD' filters={searchFilters} onSubmit={this.submit} />
                            </>
                        ) : null}
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.DocumentTree, ['tree', 'itemsSelected', 'fetchingNodes']),
    ..._.pick(state.Documents, ['filter', 'formData']),
    responsive: _.get(state.Responsive, 'ltd'),
});

const mapDispatchToProps = dispatch => ({
    createLTDDocument: (nodeId: string) =>
        dispatch(ONewLTDDocument.openDocumentSelectionDialog(nodeId)),
    setChipList: chips => dispatch(ODocuments.setChiplist(chips)),
    searchDocuments: (startFrom, keepCurrentFilter, resetToHome) =>
        dispatch(
            ODocuments.searchDocuments(
                startFrom,
                keepCurrentFilter,
                resetToHome
            )
        ),
    applyQuery: filter => dispatch(ODocuments.applyQuery(filter)),
    openNode: parentId => dispatch(ODocumentTree.fetchChildren(parentId)),
    closeNode: parentId => dispatch(ODocumentTree.removeChildren(parentId)),
    openDocument: id => dispatch(ODetail.openDocument(id)),
    applySearchTerm: text => dispatch(ODocuments.applySearchTerm(text)),
    showMatchedText: shouldShow =>
        dispatch(ODocuments.showMatchedText(shouldShow)),
    resetTree: () => dispatch(ODocumentTree.resetTree()),
    onCheck: node => dispatch(ODocumentTree.treeItemSelect(node)),
    applySort: (sort: TSort) => dispatch(ODocuments.applySort(sort)),
    openAdvancedSearchDialog: () => dispatch(OAdvancedSearch.openDialog()),
    closeResponsiveDialog: () => dispatch(OResponsive.closeDialog()),
});

const STYLES = (theme: Theme) =>
    createStyles({
        leftNav: {
            // borderRadius: 0,
            // display: 'flex',
            // height: 'calc(100vh - 50px)',
            // overflow: 'auto',
            // boxShadow: `2px 2px 3px ${fade(Theme.Colors.Grayscale.Black, 0.1)}`,
            // flexDirection: 'column',
            // minWidth: 360,
            // width: 360,
        },
        root: {
            position: 'relative',
            borderRadius: 0,
            // display: 'flex',
            height: 'calc(100vh - 50px)',
            overflow: 'auto',
            boxShadow: `2px 2px 3px ${fade(THEME.Colors.Grayscale.Black, 0.1)}`,
            // flexDirection: 'column',

            minWidth: 360,
            width: 360,
            flex: 1,
            // display: 'block',
            // borderRadius: 0,
            zIndex: 1,
            // boxShadow: `2px 2px 3px ${fade(Theme.Colors.Grayscale.Black, 0.2)}`
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        documentTree: {
            width: 324,
            margin: '0px auto 26px auto',
            // margin: '26px auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
                margin: '10px auto',
            },
        },
        label: {
            marginTop: '26px',
            position: 'relative',
            height: 52,
            fontWeight: 500,
            background: '#e0e0e0',
            color: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileTreeSubmit: {
            position: 'fixed',
            color: 'white',
            borderRadius: 0,
            bottom: 0,
        },
        filterDialog: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            width: '100vw',
            top: 0,
            bottom: 0,
            overflow: 'scroll',
        },
        dialogHeader: {
            // width: '100%',
            height: 50,
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            background: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'space-between',
            color: 'white',
            alignItems: 'center',
            paddingLeft: 10,
            '& i': {
                color: 'white',
            },
            '& button': {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        dialogContainer: {
            marginTop: 50,
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withRouter(withTranslations(LeftNav))));
