import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Fab, CircularProgress, LinearProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import _ from 'lodash';
import THEME from '../../../Resources/Theme';
import { ILtdPermissions } from '../../../Features/Authentication/@types';
import { TSearchResult, TDocument, TChip } from '../../../Features/LTDDocuments/@types';
import ListItem from '../../../Components/LTDList/ListItem';
// import ReactPaginate from 'react-paginate';
import { OApp } from '../../../RootReducer/AppReducer';
import { TNode } from '../../../Features/DocumentsTree/@types';
import { mapParentIdsToValues } from '../../../Features/CLDDocuments/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ODocuments } from '../../../Features/LTDDocuments/redux-config';
import Pagination from "react-js-pagination";
import { ONewLTDDocument } from '../../../Features/AddNewLTDDocument/redux-config';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { OLtdDashboard, LTD_DASHBOARD_SEARCH_LIMIT } from '../../../Features/LtdDashboard/redux-config';
import { TLtdDashboardState } from '../../../Features/LtdDashboard/@types';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';


interface IStateProps {
    documents: Array<TSearchResult>
    loading: boolean
    title: string
    total: number,
    pageNumber: number,
    permittedNodes: Array<TNode>,
    permissions: ILtdPermissions
    selectedIds: string[]
    searchFilter: TLtdDashboardState['tree']['searchFilter']
}

interface IDispatchProps {
    closeSidebar?: () => void
    openDocument?: (document: TDocument) => void
    fetchDocuments?: Function,
    deleteDocuments?: (ids: string[]) => void,
    setInClipboard?: (document: TDocument, action: string) => void,
    restoreDocuments?: (ids: string[]) => any
    toggleSelect?: (id: string) => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
}

const LIST_ITEM_ACTIONS = [
    {
        name: 'Copy',
        value: 'copy'
    }, {
        name: 'Move',
        value: 'move'
    },
    {
        name: 'Delete',
        value: 'delete'
    }, {
        name: 'Restore',
        value: 'restore'
    }]

class ListContainer extends Component<IProps, IState> {
    // componentDidMount() {
    //     this.assignNodeStatus();
    // }
    // componentDidUpdate(prevProps, prevState) {
    //     this.assignNodeStatus();
    // }

    // assignNodeStatus = () => {
    //     _.each(this.props.documents, document => {
    //         let node = _.get(document, 'hit.node');
    //         if (!_.isEmpty(node) && !node.assignedStatus)
    //             ODocumentTree.setNodeAssignStatus(node, this.props.permittedNodes);
    //     });
    // }

    handlePageChange = (selected) => {
        const { searchFilter } = this.props;
        const whereFilter = searchFilter.parentId ? { where: { parentId: searchFilter.parentId } } : {}
        if (this.props.fetchDocuments)
            this.props.fetchDocuments(selected - 1, whereFilter)
    }


    closeSidebar = () => {
        if (this.props.closeSidebar)
            this.props.closeSidebar();
    }
    handleActionClick = (action, document) => {
        if ((action.value === 'delete') && this.props.deleteDocuments) {
            this.props.deleteDocuments([document.id]);
        }
        else if ((action.value === 'copy' || action.value === 'move') && this.props.setInClipboard) {
            this.props.setInClipboard(document, action.value);
        } else if (action.value === 'restore' && this.props.restoreDocuments)
            this.props.restoreDocuments([document.id]);
    }
    getDocumentActions = (document) => {
        const { permissions } = this.props;
        const node = _.get(document, 'hit.node');
        let actions = (node && ((node.assignedStatus === 'ASSIGNED_CHILDREN') || (node.assignedStatus === 'ASSIGNED')) ? LIST_ITEM_ACTIONS : []);
        actions = _.filter(actions, action => {
            if (action.value === 'restore' && (!permissions.docFullAccess || !_.get(document, 'hit.deleted')))
                return false;
            if (action.value === 'delete' && _.get(document, 'hit.deleted'))
                return false;
            return true;
        });
        return actions;
    }

    handleCheck = (id: string) => {
        if (this.props.toggleSelect)
            this.props.toggleSelect(id)
    }

    render() {
        const { classes, documents, loading = true, title = '', total, pageNumber, selectedIds = [], permissions } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div>
                        {/* <Fab size="small"><i className="material-icons">chevron_left</i></Fab> */}
                        <Typography>{title}</Typography>
                    </div>
                    <Fab size="small" onClick={this.closeSidebar}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
                <div className={classes.listContainer}>
                    {
                        loading ? <LinearProgress color="primary" /> :
                            documents.map(document => {
                                return (
                                    <div key={document.hit.id} className="my-3">
                                        <ListItem
                                            disableCheckBox={!(permissions.batchCreateDocs || permissions.batchEditDocs)}
                                            documentsSelected={selectedIds}
                                            handleToggle={this.handleCheck}
                                            treeView={true}
                                            itemActions={this.getDocumentActions(document)}
                                            item={document}
                                            onClickTitle={this.props.openDocument}
                                            onActionItemClick={this.handleActionClick}
                                        />
                                    </div>
                                )
                            })
                    }
                    {
                        !loading &&
                        <div className={classes.pagination}>
                            <Pagination
                                activePage={pageNumber + 1}
                                itemsCountPerPage={LTD_DASHBOARD_SEARCH_LIMIT}
                                innerClass={classes.paginationContainer}
                                activeClass={classes.activePageIndicator}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    }
                    {/* {
                        <SelectedDocumentActions
                            actions={[]}
                            containerClass={classes.actionControlContainer}
                            docType='LtdDoc'
                            selectedIds={selectedIds}

                        />
                    } */}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.LtdDashboard.tree, ['documents', 'loading', 'pageNumber', 'total', 'searchFilter']),
    ..._.pick(state.LtdDashboard, ['selectedIds']),
    title: _.get(state.App, 'sidebar.title'),
    permissions: _.get(state.Auth, 'ltdPermissions'),
    permittedNodes: _.get(state.LtdDashboard, 'permittedNodes')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    fetchDocuments: (startingIndex, filter) => dispatch(OLtdDashboard.searchDocuments(startingIndex, filter)),
    deleteDocuments: (ids) => dispatch(ONewLTDDocument.deleteLTDDocument(ids)),
    setInClipboard: (document, action) => dispatch(ODocumentTree.setDocumentInClipboard(document, action)),
    restoreDocuments: (ids) => dispatch(ODocuments.restoreDocuments(ids)),
    toggleSelect: (id: string) => dispatch(OLtdDashboard.toggleCheck(id))
})


const CONTAINER_WIDTH = 520;

const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box',
        background: THEME.Colors.Grayscale.Grey5
    },
    header: {
        position: 'fixed',
        width: CONTAINER_WIDTH,
        right: 0,
        display: 'flex',
        height: 51,
        zIndex: 1,
        padding: '0px 20px',
        boxSizing: 'border-box',
        alignItems: 'center',
        background: theme.palette.grey['100'],
        '& p': {
            color: 'white'
        },
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    listContainer: {
        marginTop: 51,
        padding: '20px 40px',

    },
    hidden: {
        display: 'none !important',
    },
    pagination: {
        paddingBottom: 10,
        fontFamily: 'Roboto',
        '& ul': {
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            display: 'flex',
            paddingLeft: 15,
            paddingRight: 15
        },
        '& li': {
            display: 'inline-block',
            margin: '0px 4px',
            padding: '2px 8px',
            cursor: 'pointer',
            '& i': {
                position: 'relative',
                top: 3,
            },
            transition: '300ms linear',
            '&:hover': {
                background: fade(THEME.Colors.Fourth, 0.5),
                color: 'white'
            }
        },
    },
    paginationContainer: {
        margin: '0 auto',
        width: 'fit-content',
        '& a:focus': {
            outline: 'none'
        },
        '& a': {
            textDecoration: 'none',
            color: THEME.Colors.TextColor
        }
    },
    activePageIndicator: {
        background: THEME.Colors.Secondary.main,
        color: 'white',
        '& a': {
            color: 'white',
        }
    },
    actionControlContainer: {
        position: 'absolute',
        left: 100,
        bottom: 60,
        width: 180,
        zIndex: 1000,

        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            left: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(ListContainer))