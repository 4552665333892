import React from 'react';
import SortableTree, { addNodeUnderParent } from 'react-sortable-tree';

import { createStyles, WithStyles, withStyles, Menu, MenuItem, Dialog, DialogTitle, DialogContent, Button, Popover, Fade, Paper, Typography, FormControl, InputLabel, Input, RadioGroup, FormControlLabel, Radio, DialogActions, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { TNode } from '../../Features/CLDDocumentTree/@types';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import NodeRenderer from './NodeRenderer';
import { appendChildren, traverseTree, updateTree } from '../../Features/DocumentsTree/documentTree-reducer';
import { ODocumentTree } from '../../Features/DocumentsTree/redux-config';
import { TNodeType } from '../../Features/CLDDocumentTree/@types';
import utilities from '../../Resources/Utils';
import { withRouter } from 'react-router';
import { ILtdPermissions } from '../../Features/Authentication/@types';
import classNames from 'classnames';
import FileInput, { TFile } from '../FileUpload/FileInput';
import KeywordForm from '../Keyword/KeywordForm';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_CANCEL, GENERAL_OK } from '../../Features/Translations/translationKeys.cld';
import { removeChildren } from '../../Features/CLDDocumentTree/cld-documentTree-reducer';


interface IProps extends WithStyles<typeof STYLES>, WithTranslationProps {
    tree: Array<any>
    onOpen: (nodeId: string) => Promise<any>
    onClose: Function
    onCheck?: (node: TNode) => void
    onShiftNode: (nodeId, newIndex, newParentId, nextPath: string[], nextParent?: TNode) => Promise<any>,
    addNode: (nodeId, text) => Promise<any>,
    updateNode: (nodeId, text) => Promise<any>,
    addDoc: (node) => void,
    deleteNode: (node, moveChildrenInParent) => Promise<any>,
    tempDeleteNode: (node) => Promise<any>,
    // onCsvUpload: (files: TFile[], node: TNode) => void,
    restoreNode: (node) => Promise<any>,
    // permissions: ILtdPermissions
    onNodeClick: (node: TNode) => void,
    copyDocument: (documentId, nodeId) => Promise<any>,
    moveDocument: (documentId, nodeId) => Promise<any>
}

interface EditProps extends WithStyles<typeof STYLES> {
    node: TNode,
    action: string,
    isOpen: boolean,
    onResult: Function
}

class EditForm extends React.Component<EditProps> {
    state = {
        text: ''
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({ text: (this.props.action === 'edit') ? this.props.node.text : '' });
        }
    }
    handleCancel = () => {
        if (_.isFunction(this.props.onResult))
            this.props.onResult({ isCancelled: true });
    }


    handleSubmit = () => {
        const { text } = this.state;
        if (!text)
            return;
        if (_.isFunction(this.props.onResult))
            this.props.onResult({ data: { text } });
    }

    handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ text: e.target.value });
    }

    render() {
        const { classes, isOpen, action = 'add' } = this.props;
        const { text, } = this.state;
        const id = isOpen ? 'popper' : undefined;
        const dialogTitle = (action === 'edit') ? 'Edit Node' : 'Add Node';
        return (
            <div>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    fullWidth
                    aria-labelledby="Add-edit-node-dialog"
                    open={isOpen}

                >

                    <DialogTitle id="add-edit-dialog-title">
                        {dialogTitle}
                        <Button onClick={this.handleCancel} className={classes.closePopperBtn}>
                            <i className="material-icons">close</i>
                        </Button>
                    </DialogTitle>
                    <DialogContent >

                        <FormControl fullWidth>
                            <InputLabel htmlFor={'popper'}>Name</InputLabel>
                            <Input
                                autoComplete="off"
                                inputProps={{ id: 'popper' }}
                                onChange={this.handleEdit}
                                value={text || ''}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions className={classes.editDialogActions}>
                        <Button color="secondary" variant="outlined" className="my-2" onClick={this.handleSubmit} fullWidth>
                            {action === 'edit' ? 'Change' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

interface DeleteDialogProps extends WithStyles<typeof STYLES> {
    isOpen: boolean,
    onResult: Function
    getTranslation: (key: string) => string
}

class DeleteConfirmation extends React.Component<DeleteDialogProps>{
    state = {
        adjustChildrenInParent: "true",
    }

    handleDeleteCancel = () => {
        if (_.isFunction(this.props.onResult))
            this.props.onResult({ isCancelled: true });
    }
    handleDeleteConfirm = () => {
        if (_.isFunction(this.props.onResult))
            this.props.onResult({ data: { adjustChildrenInParent: (this.state.adjustChildrenInParent === 'true') } });
    }

    handleDeleteOptionChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ adjustChildrenInParent: value })
    }

    render() {
        const { isOpen, getTranslation } = this.props;
        const { adjustChildrenInParent } = this.state;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={isOpen}

            >
                <DialogTitle id="confirmation-dialog-title">Delete Node</DialogTitle>
                <DialogContent>
                    <RadioGroup
                        aria-label="ringtone"
                        name="ringtone"
                        color='primary'
                        value={adjustChildrenInParent}
                        onChange={this.handleDeleteOptionChange}
                    >

                        <FormControlLabel value={"true"} key={1} control={<Radio color='primary' />} label={'Move children nodes to parent node'} />
                        <FormControlLabel value={"false"} key={0} control={<Radio color='primary' />} label={'Delete children'} />

                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteConfirm} color="primary">
                        {getTranslation(GENERAL_OK)}
                    </Button>
                    <Button style={{ color: 'white' }} onClick={this.handleDeleteCancel} variant="contained" color="primary">
                        {getTranslation(GENERAL_CANCEL)}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class TempDeleteConfirmation extends React.Component<DeleteDialogProps>{

    handleDeleteConfirm = () => {
        if (_.isFunction(this.props.onResult))
            this.props.onResult(true);
    }
    handleDeleteCancel = () => {
        if (_.isFunction(this.props.onResult))
            this.props.onResult(false);
    }
    render() {
        const { isOpen, getTranslation } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="temp-delete-confirmation-dialog-title"
                open={isOpen}

            >
                <DialogTitle id="temp-delete-confirmation-dialog-title">Mark for deletion</DialogTitle>
                <DialogContent>
                    <Typography>Due you want to mark this node for deletion?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteConfirm} color="primary">
                        {getTranslation(GENERAL_OK)}
                    </Button>
                    <Button style={{ color: 'white' }} onClick={this.handleDeleteCancel} variant="contained" color="primary">
                        {getTranslation(GENERAL_CANCEL)}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


const NODE_MENU_CONFIG = [
    // { label: 'Paste', icon: 'description', type: 'paste', permissionKey: 'nodeCreate', toolTip: 'Paste here' },
    // { label: 'Search', icon: 'search', type: 'search', permissionKey: 'other', toolTip: 'View Documents' },
    { label: 'Add node', icon: 'add_circle', type: 'add', permissionKey: 'nodeCreate', toolTip: 'Add Node' },
    // { label: 'Add document', icon: 'insert_drive_file', type: 'add_document', permissionKey: 'docCreate', toolTip: 'Add new document' },
    // { label: 'Csv upload', icon: 'cloud_upload', type: 'csvUpload', permissionKey: 'docCsvUpload', toolTip: 'Upload CSV' },
    { label: 'Edit', icon: 'edit', type: 'edit', permissionKey: 'nodeEdit', toolTip: 'Edit node' },
    // { label: 'Delete', icon: 'delete', type: 'delete', permissionKey: 'nodeDelete', toolTip: 'Delete' },
    // { label: 'Restore', icon: 'restore', type: 'restore', permissionKey: 'nodeFullAccess', toolTip: 'Restore' },
    { label: 'Delete', icon: 'delete_forever', type: 'delete_forever', permissionKey: 'nodeDeleteForever', toolTip: 'Delete Forever' }
]

class EditableKWTree extends React.Component<IProps> {
    state = {
        gData: [],
        editForm: {
            node: {} as TNode,
            type: '',
            isOpen: false
        },
        deleteConfirm: {
            node: {} as TNode,
            isOpen: false
        },
        tempDeleteConfirm: {
            isOpen: false,
            node: {} as TNode,
        },
        menuAnchorEl: null,
        selectedNode: null as (TNode | null)
    };

    setAnchorEl = (node: TNode) => (e: any) => this.setState({ menuAnchorEl: e.target, selectedNode: node });
    closeTreeItemMenu = () => this.setState({ menuAnchorEl: null, selectedNode: null });

    componentDidMount() {
        this.setState({ gData: this.props.tree });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.tree, this.props.tree)) {
            this.setState({ gData: this.props.tree })
        }

    }




    onToggleExpanded = async ({ treeData, node, expanded, path }) => {
        if (expanded) {
            try {
                const children = await this.props.onOpen(node.id);
                this.setState({ gData: appendChildren(treeData, children, node.id) })
            } catch (error) {

            }
        } else {
            try {
                await this.props.onClose(node.id);
                this.setState({ gData: removeChildren(treeData, node.id) })
            } catch (error) {

            }
        }

    }



    onNodeMoved = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
        if (!this.props.onShiftNode)
            return;

        let currTree: any = treeData;
        for (let i = 0; i < nextPath.length - 1; i++) {
            currTree = _.find(currTree, { id: nextPath[i] });
            currTree = _.get(currTree, 'children');
        }
        const nextIndex = _.indexOf(_.map(currTree, n => n.id), node.id);
        if (nextIndex > -1)
            this.props.onShiftNode(node.id, nextIndex, nextParentNode ? nextParentNode.id : _.get(treeData, '[0].parentId'), nextPath, nextParentNode);
    }




    /*     handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, node: TNode) => {
            this.setState({
                anchorEl: {
                    ...this.state.anchorEl, [node.id]: event.currentTarget
                }
            });
        };
        handleMenuClose = (node: TNode) => {
            this.setState({ anchorEl: { ...this.state.anchorEl, [node.id]: null } });
        }; */

    handleAddNode = async (node, text) => {
        if (!_.isFunction(this.props.addNode))
            return;
        let newNode = await this.props.addNode(node.id, text);
        if (_.isEmpty(newNode))
            return;
        if (!newNode.nodeType && _.get(newNode, 'req.node.nodeType')) {
            newNode = _.get(newNode, 'req.node');
            newNode.parentIds = [...node.parentIds, node.id];
        }

        node.expanded = true;
        const updatedTree = appendChildren(this.state.gData, [newNode], node.id, false);
        if (!_.isEmpty(updatedTree))
            this.setState({ gData: updatedTree });
    }
    handleEditNode = async (node, text) => {
        const { gData } = this.state;
        if (!_.isFunction(this.props.updateNode))
            return;
        let updatedNode = await this.props.updateNode(node.id, text);
        if (_.isEmpty(updatedNode))
            return;
        if (updatedNode.action && !_.isEmpty(updatedNode.req)) {
            updatedNode = _.get(updatedNode, 'req');
        }
        const nodeToUpdate = { ...node, text: updatedNode.text, ...updatedNode };
        const currTree = traverseTree(nodeToUpdate.parentIds, gData);
        const newChildren = utilities.updateItemList(currTree, nodeToUpdate, 'UPDATE');
        const updatedTree = updateTree(gData, newChildren, nodeToUpdate.parentId);
        if (!_.isEmpty(updatedTree))
            this.setState({ gData: updatedTree });
    }
    handleEditFormResult = async (result) => {
        const { editForm } = this.state;

        if (Object.keys(result).length > 0) {
            if (editForm.type === 'add') {
                this.handleAddNode(editForm.node, result);
            }
            else if (editForm.type === 'edit') {
                const data = await this.handleEditNode(editForm.node, result);
            }
        }
        this.setState({ editForm: { isOpen: false, node: {}, type: '' } });
    }

    handleNodeDelete = async (node, isMoveChildrenToParent) => {
        const { gData } = this.state;
        if (!_.isFunction(this.props.deleteNode))
            return;
        const deletedNode = await this.props.deleteNode(node, isMoveChildrenToParent);
        const childrens = traverseTree(node.parentIds, gData);
        const newChildren = (childrens || []).filter(_node => _node.id !== node.id);
        const updatedTree = appendChildren(this.state.gData, newChildren, node.parentId, true);
        if (!_.isEmpty(updatedTree))
            this.setState({ gData: updatedTree });
    }
    handleDeleteConfirmResult = (result) => {
        if (result.data && !result.data.isCancelled) {
            const { deleteConfirm } = this.state;
            //Handle deletion
            this.handleNodeDelete(deleteConfirm.node, result.data.adjustChildrenInParent);
        }
        this.setState({ deleteConfirm: { isOpen: false, node: {} } });
    }
    setHiddenParent = (nodes, isHidden = true) => {
        _.each(nodes, node => {
            node.parentHidden = isHidden;
            if (!_.isEmpty(node.children))
                this.setHiddenParent(node.children);
        })
    }
    handleTempDeleteConfirm = (isConfirmed) => {
        const { tempDeleteConfirm, gData } = this.state;
        if (isConfirmed) {
            this.props.tempDeleteNode(tempDeleteConfirm.node);
            const node = tempDeleteConfirm.node;
            const parentIds = _.get(node, 'parentIds');
            const childrens = traverseTree(parentIds, gData);
            const currentNode = _.find(childrens, { id: node.id }) || ({} as TNode);
            if (!_.isEmpty(currentNode)) {
                // currentNode.hidden = true;
                this.setHiddenParent(currentNode.children || []);
            }
            const updatedTree = appendChildren(this.state.gData, childrens, parentIds, true);
            if (!_.isEmpty(updatedTree))
                this.setState({ gData: updatedTree });
        }
        this.setState({ tempDeleteConfirm: { isOpen: false, node: {} } });
    }
    handleRestore = async (node) => {
        const { gData } = this.state;
        const response = await this.props.restoreNode(node);
        const restoredId = _.get(response, '[0].id');
        if (!restoredId)
            return;
        const parentIds = _.get(node, 'parentIds');
        const childrens = traverseTree(parentIds, gData);
        const currentNode = _.find(childrens, { id: node.id }) as TNode;
        if (!_.isEmpty(currentNode)) {
            // currentNode.hidden = false;
            this.setHiddenParent(currentNode.children || [], false);
        }
        const updatedTree = appendChildren(this.state.gData, childrens, parentIds, true);
        if (!_.isEmpty(updatedTree))
            this.setState({ gData: updatedTree });
    }

    handleMenuItemClick = (config, node) => {
        try {
            if (config.type === 'edit' || config.type === 'add') {
                this.setState({ editForm: { node: node, type: config.type, isOpen: true } });
            } else if (config.type === 'add_document') {
                if (_.isFunction(this.props.addDoc))
                    this.props.addDoc(node);
            } else if (config.type === 'delete_forever')
                this.setState({ deleteConfirm: { node: node, isOpen: true } });
            else if (config.type === 'delete')
                this.setState({ tempDeleteConfirm: { isOpen: true, node } });
            else if (config.type === 'search')
                this.props.onNodeClick(node)

            else if (config.type === 'restore')
                this.handleRestore(node);


            // this.setState({ nodeToUpdate: node })
            /* const res = await config.onClick(node, this.state.gData)
            console.log("response ", res); */
        } catch (error) {

        }
    }


    navigationButtons = (node) => {
        // const { permissions, clipboard } = this.props;

        // if (!permissions.nodeFullAccess && (_.isEmpty(node) || (node.assignedStatus !== 'ASSIGNED' && node.assignedStatus !== 'ASSIGNED_CHILDREN')))
        //     return _.filter(NODE_MENU_CONFIG, { type: 'search' });
        // const clipboardDocId = _.get(clipboard, 'document.id');
        // let menuButtons: any = [];
        // _.each(NODE_MENU_CONFIG, btnConfig => {
        //     if (permissions[btnConfig.permissionKey]) {
        //         if (btnConfig.type === 'paste' && !clipboardDocId)
        //             return true;
        //         if (btnConfig.type === 'delete' && (node.hidden || node.parentHidden))
        //             return true;
        //         if (btnConfig.type === 'restore' && (!node.hidden || node.parentHidden))
        //             return true;
        //         menuButtons.push(btnConfig);
        //     }
        // });
        // return menuButtons;
        return NODE_MENU_CONFIG;
    }

    canDragNode = (node) => {
        // const { permissions } = this.props;
        // if (!permissions.nodeFullAccess && (_.isEmpty(node) || node.assignedStatus !== 'ASSIGNED_CHILDREN'))
        //     return false;

        // return permissions.nodeMove;

        return false;
    }

    canDropNode = (node, nextParent) => {
        // const { permissions } = this.props;
        // if (_.isEmpty(nextParent))
        //     return false;
        // if (!permissions.nodeFullAccess && (nextParent.assignedStatus !== 'ASSIGNED' && nextParent.assignedStatus !== 'ASSIGNED_CHILDREN'))
        //     return false;
        // return permissions.nodeMove;
        return true;
    }

    handleCSVUpload = (node: TNode) => (files: TFile[]) => {

        // this.props.onCsvUpload(files, node);
    }

    renderActionButton = (config, node) => {
        const { classes } = this.props;

        return (
            <Tooltip title={config.toolTip}>
                <Button className={classes.actionButton} key={config.label} onClick={e => this.handleMenuItemClick(config, node)} >
                    <i className="material-icons">{config.icon}</i>
                </Button>
            </Tooltip>
        )
    }

    closeForm = () => {
        this.setState({ editForm: { isOpen: false, node: {} } });
    }

    render() {
        const { classes, getTranslation } = this.props;
        const { editForm, deleteConfirm, tempDeleteConfirm } = this.state;

        return (
            <div className={classes.root}>

                <SortableTree
                    getNodeKey={({ node }) => node.id}
                    treeData={this.state.gData}
                    onChange={gData => this.setState({ gData })}
                    onVisibilityToggle={this.onToggleExpanded}
                    nodeContentRenderer={NodeRenderer}
                    // onMoveNode={this.onNodeMoved}
                    canDrag={({ node }) => this.canDragNode(node)}
                    canDrop={({ node, nextParent }) => this.canDropNode(node, nextParent)}
                    // rowHeight={50}
                    generateNodeProps={({ node, path }) => ({
                        buttons: this.navigationButtons(node).map(config => this.renderActionButton(config, node)),
                    })}
                />

                <EditForm action={editForm.type} isOpen={editForm.isOpen} node={editForm.node} classes={classes} onResult={this.handleEditFormResult} />
                <KeywordForm
                    handleClose={this.closeForm}
                    initialData={editForm.type === 'add' ? {} : editForm.node}
                    onSubmit={this.handleEditFormResult}
                    open={editForm.isOpen}
                    title={editForm.type === 'add' ? 'Add Keyword' : 'Edit Keyword'}
                    fields={[
                        { label: 'English', key: 'keyword_text__en' },
                        { label: 'French', key: 'keyword_text__fr' },
                        { label: 'Keyword ID', key: 'keywordGeneratedId' }
                    ]}
                />

                <DeleteConfirmation getTranslation={getTranslation} isOpen={deleteConfirm.isOpen} classes={classes} onResult={this.handleDeleteConfirmResult} />
                <TempDeleteConfirmation getTranslation={getTranslation} isOpen={tempDeleteConfirm.isOpen} classes={classes} onResult={this.handleTempDeleteConfirm}></TempDeleteConfirmation>
            </div>
        );
    }
}





const STYLES = theme => createStyles({
    root: {
        height: 'calc(100vh - 100px)',
        minWidth: 600,
        '& .rst__nodeContent': {
            right: window.innerWidth - 1100
        },
        '& .rst__rowTitle': {
            fontSize: 12
        }

    },
    nodeTitleConatiner: {
        display: 'inline-block',
    },
    actionButton: {
        minHeight: 0,
        minWidth: 0,
        height: 30,
        width: 30,
        padding: 0,
        marginRight: 6
    },
    popperContent: {
        minWidth: 300,
        minHeight: 80,
        padding: 20,
    },
    popperButton: {
        position: 'absolute',
        right: 0,
        bottom: 10,
        color: 'white',
        minHeight: 0,
        minWidth: 0,
        height: 25,
        width: 25,
        borderRadius: 20,
        padding: 0
    },
    closePopperBtn: {
        float: 'right',
        color: 'white',
        background: 'black',
        minHeight: 0,
        minWidth: 0,
        height: 30,
        width: 30,
        borderRadius: 20,
        padding: 0
    },
    editDialogActions: {
        padding: '0 24px'
    },
    popper: {
        zIndex: 1800,
        position: 'absolute',
        top: 0
    },
})



export default withStyles(STYLES)(withTranslations(EditableKWTree));