import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles, InputLabel, Input, TextField, Select, MenuItem, FormControl } from '@material-ui/core';
import { IFormConfig } from '../FormGroup';
import _ from 'lodash';
import SearchSelect from '../SearchSelect';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { LTD_DOC_DATE_FORMAT } from './useBatchEdit';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { outComeTranslation } from '../../Features/Translations/ldtOutComeTranslation';

export interface InputTypesProps extends WithStyles<typeof STYLES> {
	inputConfig: IFormConfig
	formData: Record<string, any>
}

const InputTypes: FC<InputTypesProps & WithTranslationProps> = (props) => {
	const { classes, inputConfig, formData, getTranslation } = props;

	const ldtOutComeTranslation = (label: string) => {
		return outComeTranslation(label)
	}

	return (
		<FormControl fullWidth margin="none">
			{
				inputConfig.type === 'text' || inputConfig.type === 'password' ?
					(<>
						{/* <InputLabel color="primary" htmlFor={inputConfig.id ? inputConfig.id : inputConfig.key}>{inputConfig.label}</InputLabel> */}
						<Input
							disabled={inputConfig.disabled}
							color="primary"
							disableUnderline
							type={inputConfig.type}
							margin="none"
							onChange={inputConfig.onChange}
							value={_.get(formData, inputConfig.key) || ''}
							inputProps={{
								id: inputConfig.id ? inputConfig.id : inputConfig.key,
								name: inputConfig.key
							}}
						/>
					</>) : null
			}
			{
				inputConfig.type === 'multiline' ?
					(
						<TextField
							color="primary"
							disabled={inputConfig.disabled}
							id={inputConfig.id ? inputConfig.id : inputConfig.key}
							// label={inputConfig.label}
							multiline
							name={inputConfig.key}
							onChange={inputConfig.onChange}
							rowsMax="4"
							rows={inputConfig.rows ? inputConfig.rows : "1"}
							value={formData[inputConfig.key] || ''}
							margin="none"
							classes={{ marginNormal: classes.multilineWrapper }}
							InputProps={{ disableUnderline: true, className: classes.multiLine, margin: 'none' }}
						// className={classes.multiLine}
						/>
					) : null
			}
			{
				inputConfig.type === 'select' ?
					(
						<>
							{/* <InputLabel color="primary" htmlFor={inputConfig.id ? inputConfig.id : inputConfig.key}>{inputConfig.label}</InputLabel> */}
							<Select
								margin="none"
								disableUnderline
								disabled={inputConfig.disabled}
								color="primary"
								onChange={inputConfig.onChange}
								value={_.get(formData, inputConfig.key) || ''}
								inputProps={{
									id: inputConfig.id ? inputConfig.id : inputConfig.key,
									name: inputConfig.key
								}}
							>
								{
									(inputConfig.options || []).map((option, i) => (
										<MenuItem key={i} value={option.value}>
											{
												inputConfig.isFetch ? ldtOutComeTranslation(option.value) : getTranslation(option.translationkey!) || option.name
											}
										</MenuItem>
									))
								}
							</Select>
						</>
					) : null
			}
			{
				inputConfig.type === 'multiselect' ?
					(
						<>
							<SearchSelect
								disableUnderline={true}
								value={formData[inputConfig.key] || []}
								multiple={true}
								options={inputConfig.options || []}
								id={inputConfig.id ? inputConfig.id : inputConfig.key}
								name={inputConfig.key}
								label={inputConfig.label}
								onChange={inputConfig.onChange}
							/>
						</>
					) : null
			}
			{
				inputConfig.type === 'date' ?
					(
						<InlineDatePicker
							keyboard
							clearable
							disableFuture
							minDate={new Date('01-02-1800')}
							InputProps={{ className: classes.dateInput }}
							InputLabelProps={{ className: classes.dateInputLabel }}
							name={inputConfig.key}
							fullWidth
							mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
							label={inputConfig.label}
							views={["year", "month", "day"]}
							// placeholder={inputConfig.label}
							maxDate={new Date()}
							keyboardIcon={<i className="material-icons">event</i>}
							format='DD-MM-YYYY'
							// invalidDateMessage={<InvalidDateMessage />}
							value={formData[inputConfig.key] ? moment(formData[inputConfig.key], 'YYYY-MM-DD') : null}
							onChange={(date) => {
								if (date)
									inputConfig.onChange({ target: { value: moment(date).format('YYYY-MM-DD'), name: inputConfig.key } });
								else
									inputConfig.onChange({ target: { value: null, name: inputConfig.key } });

							}}
						/>
					) : null
			}
			{
				inputConfig.type === 'component' ?
					(
						inputConfig.component
					) : null
			}
		</FormControl >
	)
}

const STYLES = (theme: Theme) => createStyles({
	multilineWrapper: {
		'& label': {
			// padding: 4
		},
	},
	multiLine: {
		borderRadius: 4,
		// padding: 4,
		// border: `1px solid ${fade('#000', 0.4)}`
	},
	dateInput: {
		// margin: '6px 0px'
	},
	dateInputLabel: {

	}
});

export default withStyles(STYLES)(withTranslations(InputTypes));