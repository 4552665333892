import _ from 'lodash';
import { Models } from '../../Resources/Model';
import { TState } from '../../RootReducer/index';
import { TEditSection, TEditFields } from './@types';
import utilities from '../../Resources/Utils';
import { OApp } from '../../RootReducer/AppReducer';
import { OCLDDocumentTree } from '../CLDDocumentTree/redux-config';
import { ODetail, getDocumentTypeFromURL, fetchCLDDocument, CLD_INCLUDES } from '../DetailPage/redux-config';
import { TCLDDocument } from '../CLDDocuments/@types';
import { TLTDDoc } from '../CLDDocuments/@types';
import { AxiosPromise } from 'axios';
import axios from 'axios';
import { parse } from 'node-html-parser';
import { getSituationCase } from '../CLDDocuments/redux-config';
import { ERROR_TEXT } from './addNewLegalFinding-reducer';
import KeywordService from '../../Services/Keyword.service';
import { CldFieldService } from '../../Services/CldFields.service';
import CldDocsService from '../../Services/CldDocs.service';

// const formatDecision: TLTDDoc = (ltdDoc: TDocument) => {
//     const doc: TLTDDoc = {

//     }

//     return doc;
// }

export class NewLegalFinding extends Models {
    constructor() {
        super('NewLegalFinding', {
            OPEN_NEW_LEGAL_FINDING_DIALOG: 'OPEN_NEW_LEGAL_FINDING_DIALOG',
            CLOSE_NEW_LEGAL_FINDING_DIALOG: 'CLOSE_NEW_LEGAL_FINDING_DIALOG',
            EDIT_LEGAL_FINDING_FIELDS: 'EDIT_LEGAL_FINDING_FIELDS',
            SET_FORM_FIELD: 'SET_FORM_FIELD',
            CLOSE_LEGAL_FINDING_EDIT_BAR: 'CLOSE_LEGAL_FINDING_EDIT_BAR',
            SAVE_LEGAL_FINDING_CHANGES: 'SAVE_LEGAL_FINDING_CHANGES',
            OPEN_DECISION_SELECTION_DIALOG: 'OPEN_DECISION_SELECTION_DIALOG',
            CLOSE_DECISION_SELECTION_DIALOG: 'CLOSE_DECISION_SELECTION_DIALOG',
            NEW_LEGAL_FINDING_CREATED: 'NEW_LEGAL_FINDING_CREATED',
            GET_KEYWORDS_FROM_TREE: 'GET_KEYWORDS_FROM_TREE',
            SET_NEW_LEGAL_FINDING_LOADING: 'SET_NEW_LEGAL_FINDING_LOADING',
            SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
            CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
            SET_FORM_ERROR: 'SET_FORM_ERROR',
            TRANSLATE_KEYWORDS: 'TRANSLATE_KEYWORDS',
        });
    }

    getRawTextFromFinding = (legalFinding: string, trim: boolean = true) => {
        return trim ? parse(legalFinding).rawText.trim() : parse(legalFinding).rawText;
    };

    getKeywordFromSelection = async (ids: string[]) => {
        try {
            const res = await axios.request<string[]>({
                url: `keywords/keyword`,
                params: {
                    ids,
                },
            });
            return res.data;
        } catch (error) {}
    };

    translateKeywords = (keywordIds: string[], lang: string) => async dispatch => {
        try {
            const { data } = await KeywordService.getTranslatedKeywords(keywordIds, lang);
            dispatch({
                type: this.actions.TRANSLATE_KEYWORDS,
                data,
            });
            return data;
        } catch (error) {
            dispatch(OApp.showToast(utilities.getErrorText(error), 'error'));
        }
    };

    getNewLegalFindingNumber = async (documentNumber: string | undefined, languageId: string) => {
        if (documentNumber)
            try {
                const res = await CldDocsService.getNextFinding(documentNumber, languageId);
                return res.data;
            } catch (error) {}
    };
    getNewLegalFindingID = async (documentNumber: string): Promise<string> => {
        try {
            const res = await utilities.request({
                url: `CldDocs/nextID?documentNumber=${documentNumber}`,
            });
            return res.data;
        } catch (error) {
            return '';
        }
    };

    validateForm = () => (dispatch, getState) => {
        const state: TState = getState();
        const { NewLegalFinding } = state;
        const formData: Partial<TCLDDocument> = NewLegalFinding.formData || {};

        // const formError = NewLegalFinding.formError;
        let formError: Partial<Record<keyof TCLDDocument, any>> = {};

        // if (!formData.situationCase) formError.situationCase = ERROR_TEXT
        if (!formData.progress) formError.progress = ERROR_TEXT;
        // if (!formData.Confidentiality) formError.Confidentiality = ERROR_TEXT
        if (!formData['Decision Type']) formError['Decision Type'] = ERROR_TEXT;
        // if (!formData.Title) formError.Title = ERROR_TEXT
        if (_.get(formData, 'Legal Findings?')) {
            if (!formData.editorCopy) formError.editorCopy = ERROR_TEXT;
            if (!formData.Finding) formError.Finding = ERROR_TEXT;
            if (!this.getRawTextFromFinding(formData.legalFinding || '')) formError.legalFinding = ERROR_TEXT;
        }

        dispatch({
            type: this.actions.SET_FORM_ERROR,
            data: formError,
        });
        return Object.keys(formError).length === 0;
        // return true
    };

    copyLegalFinding = (id: string) => async (dispatch, getState) => {
        const res = await fetchCLDDocument(id, { include: CLD_INCLUDES });
        const lf: TCLDDocument = res.data;
        const FIELDS_TO_REMOVE: (keyof TCLDDocument)[] = [
            'id',
            'Finding',
            'updated',
            'created',
            'progress',
            'deleted',
            'slug',
            'deleted',
        ];
        const lfToBeCopied = _.omit(lf, FIELDS_TO_REMOVE);
        dispatch(this.openNewLegalFindingDialog(true, 'home', lf['Document Number'], lfToBeCopied, true));
    };
    //
    // OPEN the Legal Finding edit/new entry Dialog
    // :- isNewLegalFinding if entry is New Legal Finding
    // :- section: section(id) where the screen needs to scroll after opening the dialog.
    //
    openNewLegalFindingDialog = (
        isNewLegalFinding: boolean = false,
        section: TEditSection = 'home',
        documentNumber?: string,
        prefillData?: Partial<TCLDDocument>,
        isACopy?: boolean
    ) => async (dispatch, getState) => {
        const state: TState = getState();
        const { DetailPage, Auth } = state;

        if (!isNewLegalFinding && _.isEmpty(DetailPage.currentDocument)) return;

        if (!isNewLegalFinding) {
            const legalFinding = _.get(DetailPage, 'currentDocument.document') || {};
            const { id, oldID, ltdDoc, created, deleted, updated, ...formData } = legalFinding;

            const data = {
                section,
                isNewLegalFinding,
                formData,
                legalFinding,
                isACopy,
                decision: { ...(_.get(DetailPage, 'currentDocument.document.ltdDoc') || {}) },
            };
            dispatch({
                type: this.actions.OPEN_NEW_LEGAL_FINDING_DIALOG,
                data,
            });
            dispatch(this.validateForm());
        } else {
            dispatch({ type: this.actions.SET_NEW_LEGAL_FINDING_LOADING, data: true });
            dispatch({ type: this.actions.CLEAR_ERROR_MESSAGE });

            // check if detail page is opened
            // if detail page is open: use the decision from detail page
            // else get the decision from ltddocs
            const detailPageType = getDocumentTypeFromURL();
            let res;
            let decision;
            if (detailPageType === 'none') {
                try {
                    res = await utilities.request({
                        url: `ltddocs/search`,
                        params: {
                            filter: {
                                where: { externalId: [documentNumber] },
                            },
                        },
                    });
                } catch (error) {
                    dispatch({ type: this.actions.SET_NEW_LEGAL_FINDING_LOADING, data: false });
                    dispatch({
                        type: this.actions.SET_ERROR_MESSAGE,
                        data: 'Invalid Document number',
                    });
                    return;
                }
            }

            if (detailPageType === 'legalFinding') {
                decision = { ...(_.get(DetailPage, 'currentDocument.document.ltdDoc') || {}) };
            } else if (detailPageType === 'decision') {
                decision = { ...(_.get(DetailPage, 'currentDocument.document') || {}) };
            } else if (detailPageType === 'none') decision = _.get(res.data, 'results[0].hit');

            if (_.isUndefined(decision)) {
                dispatch({
                    type: this.actions.SET_ERROR_MESSAGE,
                    data: 'Invalid Document number',
                });
                if (detailPageType !== 'none') dispatch(OApp.showToast('Something went wrong', 'error'));
                dispatch({
                    type: this.actions.SET_NEW_LEGAL_FINDING_LOADING,
                    data: false,
                });
                return;
            }

            const languageId = _.get(state, 'CldFields.groupedCldFields.language.[0].id');
            const cldFieldLanguageId =
                prefillData && prefillData.cldFieldLanguageId ? prefillData.cldFieldLanguageId : languageId;
            this.getNewLegalFindingNumber(documentNumber, languageId).then(finding => {
                const situationCase = getSituationCase(decision);
                const Finding = finding;
                const INITIAL_DATA: Partial<TCLDDocument> = {
                    situationCase,
                    ...prefillData,
                    'OCR issues?': false,
                    'Legal Findings?': true,
                    editorCopy: _.get(Auth, 'user.name') || '',
                    'Document Number': documentNumber,
                    Finding,
                    ID: `${documentNumber}_${Finding}`,
                    Title: decision.title || '',
                    cldFieldLanguageId,
                };
                const data = {
                    section,
                    isNewLegalFinding,
                    formData: {
                        ...INITIAL_DATA,
                    },
                    isACopy,
                    legalFinding: {
                        ...INITIAL_DATA,
                        ltdDoc: {
                            pdfURL: decision.pdfURL || '',
                        },
                    },
                    decision,
                };
                dispatch({
                    type: this.actions.OPEN_NEW_LEGAL_FINDING_DIALOG,
                    data,
                });
                dispatch(this.validateForm());
                dispatch({
                    type: this.actions.SET_NEW_LEGAL_FINDING_LOADING,
                    data: false,
                });
            });
        }
    };

    closeNewLegalFindingDialog = () => dispatch => {
        dispatch(OCLDDocumentTree.resetTree());
        dispatch({
            type: this.actions.CLOSE_NEW_LEGAL_FINDING_DIALOG,
        });
    };

    //
    // Open edit bar on the right
    // :- sectionToEdit: the section which is currently being edited
    // :- index: edit the field with given index in the section
    // :  else if -1, it will create new field
    //
    editSectionFields = (sectionToEdit: TEditSection, fieldIndex: number = -1) => (dispatch, getState) => {
        const data: TEditFields = {
            open: true,
            section: sectionToEdit,
            fieldIndex,
        };
        dispatch({
            type: this.actions.EDIT_LEGAL_FINDING_FIELDS,
            data,
        });
    };

    editLegalFinding = (key: string, value: any) => dispatch =>
        dispatch({
            type: this.actions.SET_FORM_FIELD,
            data: {
                key,
                value,
            },
        });

    createNewLegalFinding = () => async (dispatch, getState) => {
        const state: TState = getState();
        const { NewLegalFinding } = state;
        const { formData } = NewLegalFinding;
        try {
            const res = await utilities.request({
                url: 'clddocs',
                method: 'POST',
                data: formData,
            });

            if (res.data) {
                dispatch(OApp.showToast('New Legal finding created', 'success'));
                dispatch({
                    type: this.actions.NEW_LEGAL_FINDING_CREATED,
                    data: res.data,
                });
                dispatch(this.saveEdit(_.get(res.data, 'id')));
            }
        } catch (error) {
            const _error = _.get(error, 'response.data.error.message') || 'Something went wrong!';
            dispatch(OApp.showToast(_error, 'error'));
        }
    };

    saveEdit = (legalFindingId: string) => async (dispatch, getState) => {
        const state: TState = getState();
        const { NewLegalFinding } = state;
        const { formData, isNewLegalFinding = true } = NewLegalFinding;

        try {
            const res = await utilities.request({
                url: `/clddocs/${legalFindingId}`,
                method: 'PATCH',
                data: formData,
            });

            dispatch(OApp.showToast('Changes saved successfully', 'success'));
            dispatch({
                type: this.actions.SAVE_LEGAL_FINDING_CHANGES,
                data: {
                    data: res.data,
                    isNewLegalFinding,
                },
            });

            const detailPageType = getDocumentTypeFromURL();
            if (detailPageType === 'legalFinding') dispatch(ODetail.openDocument(legalFindingId, 'CLD'));
            else if (detailPageType === 'decision')
                dispatch(ODetail.openDocument(_.get(res, 'data.ltdDocId'), 'LTD', true));
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong. Please try again.', 'error'));
        }
    };

    closeEditSection = () => dispatch =>
        dispatch({
            type: this.actions.CLOSE_LEGAL_FINDING_EDIT_BAR,
        });

    openDecisionSelectionDialog = () => (dispatch, getState) => {
        const { DetailPage } = getState();
        if (!DetailPage) return;
        const detailPageType = getDocumentTypeFromURL();
        if (detailPageType === 'decision' || detailPageType === 'legalFinding') {
            const documentNumber =
                detailPageType === 'legalFinding'
                    ? _.get(DetailPage, 'currentDocument.document["Document Number"]') || ''
                    : _.get(
                          _.find(_.get(DetailPage, 'currentDocument.document.metadata') || [], {
                              name: 'External identifier',
                          }),
                          'val'
                      ) || '';

            dispatch(this.openNewLegalFindingDialog(true, 'home', documentNumber));
        } else
            dispatch({
                type: this.actions.OPEN_DECISION_SELECTION_DIALOG,
            });
    };

    closeDecisionSelectionDialog = () => dispatch =>
        dispatch({
            type: this.actions.CLOSE_DECISION_SELECTION_DIALOG,
        });
}

const escapeHtmlTags = (text?: string) => {};

export const ONewLegalFinding = new NewLegalFinding();
