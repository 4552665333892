import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">I-DOC</h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    Le système de documentation
d&#39;enquête (I-DOC) est un outil
numérique permettant de
travailler sur des faits pertinents
pour des violations graves du
droit international pénal ou des
droits humains, y compris la
documentation, les enquêtes, les
analyses, la préparation des
dossiers, le procès et les
procédures ultérieures. Alors que
la base de données des outils
juridiques (LTD) de la CPI et
Lexsitus fournissent aux
utilisateurs un accès à des
sources juridiques pertinentes et
à des informations juridiques
essentielles pour comprendre les
principaux crimes internationaux,
I-DOC est un outil permettant
aux praticiens de planifier et
d&#39;effectuer un travail de
documentation et d&#39;enquête
basé sur les connaissances
fournies par le LTD et Lexsitus.

<br/><br/>

I-DOC fourni des capacités
permettant de systématiser de
grandes quantités de documents
et de preuves, d&#39;incidents
criminels et de faits contextuels,
de suspects et d&#39;institutions
impliqués, de victimes et de témoins, de biens et d&#39;objets
protégés, ainsi que d&#39;autres
circonstances factuelles
pertinentes. Il contribue à
garantir la précision et la qualité
requises tout au long des
processus de travail.

<br/><br/>

La structure de la base de
données fourni un aperçu
efficace de ces catégories
d&#39;informations ainsi que la
possibilité de cartographier,
rechercher et analyser différents
modèles et liens. Les utilisateurs
peuvent créer des dossiers
pénaux et relatifs aux droits de
l&#39;homme sur la base de ces
catégories d&#39;informations,
notamment en utilisant une
fonction matricielle pour
l&#39;analyse des cas basée sur les
exigences juridiques des crimes
du Statut de la CPI.

<br/><br/>
Le concept de cet outil a été
développé par M. Ilia Utmelidze,
en s’inspirant, inter alia,
notamment de la Matrice des
affaires de la CPI, du remue-
méninge sur sa
conceptualisation, et de la
collaboration avec une équipe
d&#39;experts. M. Utmelidze dirige le
département CMN de CILRAP et
peut être contacté à <a href="mailto:utmelidze@cilrap.org">utmelidze@cilrap.org</a>.
    </p>

    <div class="grid">
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-1/" target="_blank">
            <img src="/images/i-doc-1.png" style="width: 100%;" alt="How to use the I-Doc system" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-2/" target="_blank">
            <img src="/images/i-doc-2.png" style="width: 100%;" alt="How to build international criminal law cases in I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-3/" target="_blank">
            <img src="/images/i-doc-3.png" style="width: 100%;" alt="How to customize I-DOC" />
        </a>
    </div>

    </div>`;

export default tutorialFilmEn;
