import { Models } from '../../Resources/Model';
import { TDialogVariant, TUser, TUserRole } from './@types';
import utilities, { setRequestAuthHeader } from '../../Resources/Utils';
import _ from 'lodash';
import { OApp } from '../../RootReducer/AppReducer';

export const getUserRole = (roles: any[]): TUserRole => {
    if (_.isEmpty(roles)) return 'USER';

    if (!_.isString(_.get(roles, '[0]'))) roles = roles.map(role => role.name);

    if (_.includes(roles, 'ADMIN')) return 'ADMIN';
    else if (_.includes(roles, 'SUPERVISOR')) return 'SUPERVISOR';
    else if (_.includes(roles, 'CREATOR')) return 'CREATOR';
    else if (_.includes(roles, 'SCC_USER')) return 'SCC_USER';
    else if (_.includes(roles, 'SCC_ADMIN')) return 'SCC_ADMIN';
    else if (_.includes(roles, 'SCC_SUPERVISOR')) return 'SCC_SUPERVISOR';
    else if (_.includes(roles, 'SCC_CREATOR')) return 'SCC_CREATOR';
    else return 'USER';
};
export const getCLDUserRole = (roles: any[]): TUserRole => {
    if (_.isEmpty(roles)) return 'USER';

    if (!_.isString(_.get(roles, '[0]'))) roles = roles.map(role => role.name);

    if (_.includes(roles, 'CLD_SUPER_ADMIN')) return 'CLD_SUPER_ADMIN';
    else if (_.includes(roles, 'CLD_ADMIN')) return 'ADMIN';
    else if (_.includes(roles, 'CLD_SUPERVISOR')) return 'SUPERVISOR';
    else if (_.includes(roles, 'CLD_CREATOR')) return 'CREATOR';
    else if (_.includes(roles, 'CLD_EDITOR')) return 'EDITOR';
    else return 'USER';
};

export const doesRoleInclude = (roles: any[], role: TUserRole): boolean => {
    if (_.isEmpty(roles)) return false;
    if (!_.isString(_.get(roles, '[0]'))) roles = roles.map(role => role.name);
    return _.includes(roles, role);
};
export class Authentication extends Models {
    constructor() {
        super('users', {
            OPEN_AUTH_DIALOG: 'OPEN_AUTH_DIALOG',
            CLOSE_AUTH_DIALOG: 'CLOSE_AUTH_DIALOG',
            USER_LOGGED_IN: 'USER_LOGGED_IN',
            USER_LOGGED_OUT: 'USER_LOGGED_OUT',
            USER_DETAIL_UPDATED: 'USER_DETAIL_UPDATED',
        });
        this.actions.SINGLE_ITEM_RECEIVED = 'USER_DETAILS_RECEIVED';
        this.actions.FETCHING_SINGLE_ITEM = 'REQUESTING_USER_DETAILS';
        this.actions.POSTING_SINGLE_ITEM = 'REQUESTING_LOGIN';
        this.actions.SINGLE_ITEM_POST_SUCCESS = 'LOGIN_SUCCESS';
    }

    entities = {
        LOGIN: 'login',
        ME: 'me',
        FORGOT_PASSWORD: 'forget-password',
    };

    openAuthDialog = (variant: TDialogVariant) => dispatch => {
        dispatch({
            type: this.actions.OPEN_AUTH_DIALOG,
            data: variant,
        });
    };

    closeAuthDialog = () => dispatch => {
        dispatch({
            type: this.actions.CLOSE_AUTH_DIALOG,
        });
    };

    updateUserDetail = (userId: string, formData: any) => async dispatch => {
        await this.updateUser(userId, formData);
        delete formData.password;
        dispatch({
            type: this.actions.USER_DETAIL_UPDATED,
            data: formData,
        });
        dispatch(OApp.showToast('Profile detail updated', 'success'));
    };

    updateUser = async (userId: string, formData: any): Promise<TUser> => {
        return new Promise((resolve, reject) => {
            utilities
                .request({
                    url: `/users/${userId}`,
                    method: 'PATCH',
                    data: formData,
                })
                .then(
                    res => resolve(res.data),
                    err => reject(err)
                );
        });
    };

    requestAppUser = async (accessToken: string): Promise<TUser> => {
        return new Promise((resolve, reject) => {
            setRequestAuthHeader(accessToken);
            utilities
                .request({
                    url: `/users/me`,
                })
                .then(res => resolve(res.data));
        });
    };

    // requestAppUser = (userId?: string) => dispatch => {
    //     return (
    //         new Promise((resolve, reject) => {
    //             // Requesting signed up user to save in AppUser as there's no access_token
    //             if (userId) {
    //                 dispatch(this.getItem(userId)).then(
    //                     res => dispatch({ type: this.actions.MODEL_ENTITY_RECEIVED, entity: this.entities.ME, data: res.data }),
    //                     err => console.log('User not received')
    //                 )
    //             }
    //             // Requesting logged in user using access_token
    //             else {
    //                 dispatch(this.getModelEntity(this.entities.ME)).then(
    //                     res => resolve(res),
    //                     err => reject(err)
    //                 )
    //             }
    //         })
    //     )
    // }

    submitLogin = formData => dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(this.postModelEntity(this.entities.LOGIN, formData)).then(
                res => {
                    utilities.saveUser(res.data.id, res.data.userId);
                    dispatch(
                        this.getItem(res.data.userId, {
                            filter: {
                                include: [{ relation: 'roles' }],
                            },
                        })
                    );
                    dispatch({
                        type: this.actions.USER_LOGGED_IN,
                    });
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    };

    sendResetLink = formData => dispatch => {
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         resolve()
        //     }, 2000);
        // })
        return new Promise((resolve, reject) => {
            dispatch(this.postModelEntity(this.entities.FORGOT_PASSWORD, formData)).then(
                res => {
                    // dispatch(this.getItem(res.data.userId));
                    console.log('response', res.data);
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    };

    submitSignup = formData => dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(this.postItem(formData)).then(
                res => {
                    utilities.saveUser('', res.data.id);
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    };
}

export const OAuth = new Authentication();
