import { Models } from '../../Resources/Model';
import { TDocumentTreeState, TNode } from './@types';
import _ from 'lodash';
import {
    getAllChildren,
    getAllSiblings,
    getAllAncestors,
    findNode,
    getParent,
    orderNodes,
    setExpandedFlag,
} from './documentTree-reducer';
import { mapValueAndId, ODocuments } from '../LTDDocuments/redux-config';
import utilities from '../../Resources/Utils';
import { OApp } from '../../RootReducer/AppReducer';
import Axios, { CancelTokenSource } from 'axios';

class DocumentTree extends Models {
    constructor() {
        super('nodes', {
            FETCHED_TREE_NODES: 'FETCHED_TREE_NODES',
            FETCHED_CHILDREN: 'FETCHED_CHILDREN',
            REMOVED_CHILDREN: 'REMOVED_CHILDREN',
            RESET_TREE: 'RESET_TREE',
            TREE_ITEM_SELECTED: 'TREE_ITEM_SELECTED',
            TREE_ITEM_UNSELECTED: 'TREE_ITEM_UNSELECTED',
            LTD_NODE_CREATED: 'LTD_NODE_CREATED',
            LTD_NODE_DELETED: 'LTD_NODE_DELETED',
            LTD_NODE_RESTORED: 'LTD_NODE_RESTORED',
            LTD_NODE_UPDATED: 'LTD_NODE_UPDATED',
            LTD_NEW_TREE_FETCHED: 'LTD_NEW_TREE_FETCHED',

            LTD_TREE_EXPANDED_WITH_ID: 'LTD_TREE_EXPANDED_WITH_ID',

            SET_DOC_IN_CLIPBOARD: 'SET_DOC_IN_CLIPBOARD',
            RESET_CLIPBOARD: 'RESET_CLIPBOARD',

            FETCHING_NODES_TREE: 'FETCHING_NODES_TREE',
            NODES_FETCHED_WITH_CHILDREN: 'NODES_FETCHED_WITH_CHILDREN',
        });
    }

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    treeSearch = (term: string) => async (dispatch, getState) => {
        const newValue = term;
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            if (this.ajaxCallHandle) {
                this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
            }
            dispatch(this.resetTree());
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url: `/nodes/tree-search`,
                params: {
                    term,
                    filter: {
                        where: {
                            nodeType: 'MENU',
                        },
                    },
                },
                cancelToken: this.ajaxCallHandle.token,
            });

            let cleanedData: TNode[] = _.filter(res.data.children, child => !_.isEmpty(child));
            cleanedData = setExpandedFlag(cleanedData);
            dispatch({
                type: this.actions.LTD_NEW_TREE_FETCHED,
                data: cleanedData,
            });
        } catch (error) { }
    };

    treeItemSelect = (node: TNode, variant: 'ltd' | 'scc' = 'ltd') => async (dispatch, getState) => {
        mapValueAndId(node);
        const DocumentTree: TDocumentTreeState = getState().DocumentTree;
        let itemsSelected = DocumentTree.itemsSelected || [];
        const tree = DocumentTree.tree || [];
        let idsToSearch = DocumentTree.idsToSearch || [];
        const initialSelection = idsToSearch;

        // const children = _.get(node, 'children.length') ? node.children : getAllChildren(node);
        const children = getAllChildren(node);
        let _temp = _.filter(children, (child: TNode) => child.nodeType === 'MENU');
        let childrenIds = _.map(_temp, (child: TNode) => child.id);
        _.forEach(children, (_node: TNode) => {
            mapValueAndId(_node);
        });

        const _ancestors = getAllAncestors(tree, tree, node.id, []);
        // let _siblings = await getAllSiblings(node) || [];
        let _parent = getParent(node.id, tree);
        if (!parent) {
            if (node.parentId) {
                _parent = await findNode(node.parentId);
            }
        }

        let _siblings = _parent ? _parent.children : await getAllSiblings(node);
        const temp = _.filter(_siblings, (val: TNode) => val.nodeType === 'MENU');
        let siblings: string[] = temp.map(val => val.id);
        // let siblings: string[] = _.map(temp, (val: TNode) => val.id);

        if (_.indexOf(itemsSelected, node.id) > -1) {
            idsToSearch = _.filter(idsToSearch, id => id !== node.id || id !== node.parentId);
            siblings = _.intersection<string>(itemsSelected, siblings);
            siblings = _.filter<string>(siblings, val => val !== node.id);
            idsToSearch = _.filter<string>(idsToSearch, id => id != node.parentId);
            idsToSearch = _.union(idsToSearch, siblings);
            idsToSearch = _.filter(idsToSearch, id => id !== node.id);

            itemsSelected = _.difference(itemsSelected, [
                ...childrenIds,
                node.id,
                ..._.map(_ancestors, node => node.id),
            ]);

            _.forEach(_ancestors, (node: TNode) => {
                const children = getAllChildren(node);
                let temp = _.filter(children, (child: TNode) => child.nodeType === 'MENU');
                let childrenIds = _.map(temp, (child: TNode) => child.id);

                idsToSearch = _.intersection(itemsSelected, [...idsToSearch, ...childrenIds]);
            });

            idsToSearch = _.difference(
                idsToSearch,
                _.map(_ancestors, node => node.id)
            );
            // idsToSearch = _.intersection(itemsSelected, idsToSearch);

            dispatch({
                type: this.actions.TREE_ITEM_UNSELECTED,
                data: {
                    itemsSelected,
                    idsToSearch,
                },
            });
            dispatch(
                ODocuments.applyQuery({
                    where: { parentIds: idsToSearch },
                })
            );

            dispatch(ODocuments.searchDocuments(undefined, undefined, false, variant));
        } else {
            itemsSelected = _.union(itemsSelected, [...childrenIds, node.id]);
            idsToSearch = _.uniq([..._.difference(idsToSearch, childrenIds), node.id]);

            if (_.size(_.intersection(itemsSelected, siblings)) === _.size(siblings) && node.parentId && _parent) {
                // let _parent: TNode = getParent(node.parentId, tree);
                // // const _parent:TNode = await findNode(node.parentId);

                // _parent = _parent || await findNode(node.parentId);
                const _children = _parent.childrenIds || [];
                // const temp = _.filter(_children, (val: TNode) => val.nodeType === "MENU");
                // let children = _.map(temp, (val: TNode) => val.id);
                dispatch({
                    type: this.actions.TREE_ITEM_SELECTED,
                    data: {
                        itemsSelected,
                        idsToSearch: [..._.difference(idsToSearch, _children), _parent.id],
                    },
                });
                dispatch(this.treeItemSelect(_parent, variant));
            } else {
                dispatch({
                    type: this.actions.TREE_ITEM_SELECTED,
                    data: {
                        itemsSelected,
                        idsToSearch,
                    },
                });

                dispatch(
                    ODocuments.applyQuery({
                        where: { parentIds: idsToSearch },
                    })
                );

                dispatch(ODocuments.searchDocuments(undefined, undefined, false, variant));
            }
        }
    };

    resetTree = () => dispatch => {
        dispatch({
            type: this.actions.RESET_TREE,
        });
    };

    removeChildren = parentId => (dispatch, getState) => {
        dispatch({
            type: this.actions.REMOVED_CHILDREN,
            data: parentId,
        });
    };

    setNodeAssignStatus = (treeNode, permittedNodes) => {
        _.each(permittedNodes, node => {
            if (node.id === treeNode.id) treeNode.assignedStatus = 'ASSIGNED';
            else if (_.indexOf(node.parentIds, treeNode.id) > -1) treeNode.assignedStatus = 'ASSIGNED_PARENT';
            else if (_.indexOf(treeNode.parentIds, node.id) > -1) treeNode.assignedStatus = 'ASSIGNED_CHILDREN';
        });
    };
    assignNodesStatus = (nodesTree, permittedNodes) => {
        if (_.isEmpty(permittedNodes)) return;
        _.each(nodesTree, treeNode => {
            this.setNodeAssignStatus(treeNode, permittedNodes);
            if (!_.isEmpty(treeNode.children)) this.assignNodesStatus(treeNode.children, permittedNodes);
        });
    };

    fetchBaseLevelNodes = (includeStats = false, variant: 'ltd' | 'scc' = 'ltd') => (dispatch, getState) => {
        const params = {
            filter: {
                where: { level: 0, nodeType: 'MENU' },
            },
        };
        dispatch({
            type: this.actions.FETCHING_NODES_TREE,
        });
        return new Promise((resolve, reject) => {
            dispatch(this.getItemsList(params)).then(res => {
                const rootId = _.get(res, 'data[0].id');
                if (!rootId) return;
                if (variant === 'scc') {
                    dispatch(this.fetchChildren(undefined, true, includeStats, variant)).then(() => {
                        dispatch({
                            type: this.actions.NODES_FETCHED_WITH_CHILDREN,
                        });
                    });
                } else {
                    dispatch(this.fetchChildren(rootId, true, includeStats, variant)).then(() => {
                        dispatch({
                            type: this.actions.NODES_FETCHED_WITH_CHILDREN,
                        });
                    });
                }
                /* let treeNodes = _.get(res, 'data[0].children');
                    const { LtdDashboard } = getState();
                    this.assignNodesStatus(treeNodes, _.get(LtdDashboard, 'permittedNodes'));
                    dispatch({
                        type: this.actions.FETCHED_TREE_NODES,
                        data: orderNodes(treeNodes || [], _.get(res, 'data[0].childrenIds'))
                    }) */
            });
        });
    };

    fetchLevelNodes = (level: Number) => {
        const params = {
            filter: {
                where: { level, nodeType: 'MENU' },
            },
        };
        return utilities.request({
            url: '/nodes',
            params,
        });
    };

    fetchChildren = (parentId?, isInitial?: boolean, includeStats = false, variant: 'ltd' | 'scc' = 'ltd') => (
        dispatch,
        getState
    ) => {
        /* const params = {
            filter: { where: { parentId, nodeType: 'MENU' } }
        } */
        return new Promise(async (resolve, reject) => {
            const res = await utilities.request({
                url: `nodes/fetch`,
                params: {
                    parentId,
                    includeStats,
                    org: variant === 'scc' ? 'SCC' : null,
                },
            });

            let treeNodes = res.data;
            const { LtdDashboard } = getState();
            this.assignNodesStatus(treeNodes, _.get(LtdDashboard, 'permittedNodes'));
            if (isInitial) {
                dispatch({
                    type: this.actions.FETCHED_TREE_NODES,
                    data: treeNodes,
                });
            } else {
                dispatch({
                    type: this.actions.FETCHED_CHILDREN,
                    data: {
                        children: treeNodes,
                        parentId,
                    },
                });
            }
            resolve(treeNodes);
        });
    };

    nodeDelete = (nodeIds: string[], moveChildrenToParent: boolean = false) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'nodes/delete',
                method: 'DELETE',
                params: {
                    ids: nodeIds,
                    adjustChildrenInParent: moveChildrenToParent,
                },
            });
            dispatch({
                type: this.actions.LTD_NODE_DELETED,
                data: res.data,
            });
            dispatch(OApp.showToast(`Node deleted`, 'success'));
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    nodeHide = (nodeIds: string[]) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'nodes/hide',
                method: 'PATCH',
                params: {
                    ids: nodeIds,
                },
            });
            dispatch({
                type: this.actions.LTD_NODE_DELETED,
                data: res.data,
            });
            dispatch(OApp.showToast(`Node deleted`, 'success'));
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    nodeUnHide = (nodeIds: string[]) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'nodes/unhide',
                method: 'PATCH',
                params: {
                    ids: nodeIds,
                },
            });
            dispatch({
                type: this.actions.LTD_NODE_RESTORED,
                data: res.data,
            });
            dispatch(OApp.showToast(`Node restored`, 'success'));
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    nodeEdit = (nodeId: string, text: string) => async dispatch => {
        try {
            const res = await utilities.request({
                url: `nodes/${nodeId}/edit`,
                method: 'PATCH',
                data: {
                    text,
                },
            });
            let updatedNode = res.data;
            if (updatedNode.action && !_.isEmpty(updatedNode.req)) {
                updatedNode = _.get(updatedNode, 'req');
            }
            updatedNode.parentIds = [nodeId];
            dispatch({
                type: this.actions.LTD_NODE_UPDATED,
                data: updatedNode,
            });
            dispatch(OApp.showToast(`Node renamed to ${text}`, 'success'));
            return res.data;
        } catch (error) {
            dispatch(OApp.showToast(`Something went wrong!`, 'error'));
            throw error;
        }
    };

    nodeCreate = (parentId: string, text: string) => async (dispatch, getState) => {
        try {
            const res = await utilities.request({
                url: 'nodes/create',
                method: 'POST',
                data: {
                    parentId,
                    text,
                    nodeType: 'MENU',
                },
            });
            let createdNode = res.data;
            if (!createdNode.nodeType && _.get(createdNode, 'req.node.nodeType'))
                createdNode = _.get(createdNode, 'req.node');
            const { LtdDashboard } = getState();
            const permittedNodes = _.get(LtdDashboard, 'permittedNodes');
            this.assignNodesStatus([createdNode], permittedNodes);
            dispatch({
                type: this.actions.LTD_NODE_CREATED,
                data: {
                    children: createdNode,
                    parentId,
                },
            });
            dispatch(OApp.showToast(`Node ${text} created successfully`, 'success'));
            return res.data;
        } catch (error) {
            dispatch(OApp.showToast(`Something went wrong!`, 'error'));
            throw error;
        }
    };

    shiftNode = (nodeId: string, newIndex?: number, newParentId?: string) => dispatch => {
        return new Promise((resolve, reject) => {
            utilities
                .request({
                    url: `nodes/${nodeId}/shift`,
                    method: 'PATCH',
                    params: {
                        newParentId,
                        newIndex,
                    },
                })
                .then(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
        // try {
        //     const res = await utilities.request({
        //         url: `nodes/${nodeId}/shift`,
        //         method: 'PATCH',
        //         params: {
        //             newParentId,
        //             newIndex
        //         }
        //     })
        //     return res.data;
        // } catch (error) {
        //     throw error;
        // }
    };

    createTreeByIds = async (nodeIds: string[]) => {
        const res = await utilities.request({
            url: `nodes`,
            params: {
                filter: {
                    where: {
                        id: {
                            inq: nodeIds,
                        },
                    },
                },
            },
        });
        const nodes = res.data;

        _.each(nodeIds.reverse(), (nodeId, index) => {
            const node = _.find(nodes, { id: nodeId }) as TNode;
            const nextNode = _.find(nodes, { id: nodeIds[index + 1] }) as TNode;
            if (!nextNode) return true;
            if (!nextNode.children) nextNode.children = [];
            nextNode.children.push(node);
        });
        const treeNode = _.find(nodes, { id: nodeIds[nodeIds.length - 1] }) as TNode;
        return treeNode;
    };

    expandTreeWithIds = (nodeIds: string[]) => async (dispatch, getState) => {
        try {
            const res = await utilities.request({
                url: `nodes/expand`,
                params: {
                    ids: nodeIds,
                },
            });

            let treeNodes = _.get(res.data, 'children');
            const { LtdDashboard } = getState();
            const permittedNodes = _.get(LtdDashboard, 'permittedNodes');
            this.assignNodesStatus(treeNodes, permittedNodes);
            dispatch({
                type: this.actions.LTD_TREE_EXPANDED_WITH_ID,
                data: setExpandedFlag(treeNodes),
            });
            return;
        } catch (error) {
            throw error;
        }
    };
    setDocumentInClipboard = (document, action) => dispatch => {
        if (_.isEmpty(document) || !action) return;
        dispatch({
            type: this.actions.SET_DOC_IN_CLIPBOARD,
            data: {
                document,
                action,
            },
        });
        dispatch(OApp.showToast('Document copied!', 'success'));
    };
    resetClipboard = () => dispatch => {
        dispatch({
            type: this.actions.RESET_CLIPBOARD,
        });
    };

    moveDocument = (documentId, parentNodeId) => async dispatch => {
        const resp = await utilities.request({
            url: '/ltddocs/move',
            method: 'PUT',
            data: {
                id: documentId,
                newParentId: parentNodeId,
            },
        });
        dispatch(OApp.showToast('Document moved!', 'success'));
        return resp.data;
    };
    copyDocument = (documentId, parentNodeId) => async dispatch => {
        const resp = await utilities.request({
            url: '/ltddocs/copy',
            method: 'POST',
            data: {
                id: documentId,
                parentNodeId,
            },
        });
        dispatch(OApp.showToast('Document copied!', 'success'));
    };
}

export let ODocumentTree = new DocumentTree();
