import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography, Checkbox } from '@material-ui/core';
import { LTD_DOCUMENT_SECTIONS, TLtdDocFormFieldKey, LTD_DOCUMENT_FORM_FIELDS } from './constants';
import _ from 'lodash';
import classNames from 'classnames';


export interface BatchEditFieldSelectionProps extends WithStyles<typeof STYLES> {
    selectedFieldKeys: TLtdDocFormFieldKey[]
    handleFieldSelect: (field: TLtdDocFormFieldKey) => void
    stickyLabelPos?: number
}

const BatchEditFieldSelection: FC<BatchEditFieldSelectionProps> = (props) => {
    const { classes, selectedFieldKeys = [], stickyLabelPos = 51 } = props;

    const sections = Object.keys(LTD_DOCUMENT_SECTIONS);
    return (
        <div className={classes.root}>
            {sections.map(key => {
                const section = LTD_DOCUMENT_SECTIONS[key];
                return (
                    <div key={key}>
                        <Typography style={{ top: stickyLabelPos, position: 'sticky' }} className={classes.sectionLabel}>{section.label}</Typography>
                        {section.fields.map(field => {
                            const fieldLabel = _.find(LTD_DOCUMENT_FORM_FIELDS, { key: field });
                            const isSelected = selectedFieldKeys.includes(field)
                            return fieldLabel ? (
                                <div key={field} className={classNames(classes.item, classes.border)}>
                                    <Checkbox color="primary" checked={isSelected} onClick={() => props.handleFieldSelect(field)} />
                                    <Typography variant="body2">{fieldLabel.label}</Typography>
                                </div>
                            ) : null;
                        })}
                    </div>
                )
            })}


        </div>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {

    },
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionLabel: {
        padding: '14px 16px',
        fontWeight: 600,
        // position: 'sticky',
        // top: 51,
        background: 'white',
        zIndex: 1
    },
    item: {
        display: 'flex',
        alignItems: 'center',
    },
    border: {
        borderTop: `1px dashed grey`
    }
})

export default withStyles(STYLES)(BatchEditFieldSelection);