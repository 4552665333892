import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Checkbox, Typography, Button, IconButton, Icon, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import _ from 'lodash';
import moment from 'moment';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../../Resources/Theme';
import ImporterFilter from './Filter/ImporterFilter';
import { ImporterLogFilter, TImporterLogFilter } from '../../../Features/LtdDashboard/Filters/importerLog';
import { TLtdDashboardState } from '../../../Features/LtdDashboard/@types';
import WhenFilter from './Filter/WhenFilter';
import Entry from '../Entry';


interface IStateProps {
    importerLog: TLtdDashboardState['importerLog']
}

interface IDispatchProps {
    getPageData: (pageNumber: number, appendResult?: boolean, filter?: Record<string, any>) => any
    downloadImporterLogsCSV: (whereFilter?: Record<string, any>) => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class ImporterLog extends Component<IProps, IState> {

    componentDidMount() {
        if (_.isEmpty(this.props.importerLog.logs))
            this.props.getPageData(0)
    }


    loadMore = () => {
        const { logs = [], pageNumber = 0, filter = {} } = this.props.importerLog;
        const whereFilter = ImporterLogFilter.getWhereFilterFromAppliedFilter(filter);
        this.props.getPageData(pageNumber + 1, false, whereFilter);
    }


    handleDownload = () => {
        const { filter = {} } = this.props.importerLog;
        const whereFilter = ImporterLogFilter.getCSVWhereFilterFromAppliedFilter(filter);
        this.props.downloadImporterLogsCSV(whereFilter);
    }



    getDownloadButton = () => {

        return (
            <Tooltip title="Download CSV">
                <IconButton onClick={this.handleDownload} className="mx-2">
                    <Icon>file_download</Icon>
                </IconButton>
            </Tooltip>
        )
    }


    render() {
        const { classes, importerLog } = this.props;
        const { logs = [], pageNumber = 0 } = importerLog;

        const HEADER_CONFIG = [
            { label: 'Importer', width: 4, component: (<ImporterFilter />) },
            { label: 'When', width: 6, component: (<WhenFilter />) },
            { label: 'Start date', width: 4, component: (<Entry type="label">Start date</Entry>) },
            { label: 'End date', width: 4, component: (<Entry type="label">End date</Entry>) },
            { label: 'Error', width: 5, component: (<Entry type="label">Error</Entry>) },
            { label: 'note', width: 5, component: (<Entry type="label">Note</Entry>) },
            { label: 'duplicateCount', width: 2.5, component: (<Entry type="label">Duplicate count</Entry>) },
            { label: 'successCount', width: 2.5, component: (<Entry type="label">Success count</Entry>) },
            { label: 'totalCount', width: 2.5, component: (<Entry type="label">Total count</Entry>) },
            // { label: 'downloadCSV', width: 2.5, component: (this.getDownloadButton()) },
        ]

        const COLUMNS = [
            { key: 'importer', type: 'text' },
            { key: 'created', type: 'date' },
            { key: 'startDate', type: 'text' },
            { key: 'endDate', type: 'text' },
            { key: 'error', altKey: 'by', type: 'text' },
            { key: 'note', type: 'text' },
            { key: 'duplicateCount', type: 'text' },
            { key: 'successCount', type: 'text' },
            { key: 'totalCount', type: 'text' },
        ]




        return (
            <div className={classes.root}>
                <div className={classes.toolbar}>
                    <div>
                        {
                            HEADER_CONFIG.map(config => (
                                <div style={{
                                    width: config.width * 30 * MAIN_CONTENT_WIDTH / DELTA,
                                    minWidth: config.width * 30 * MAIN_CONTENT_WIDTH / DELTA
                                }} key={config.label} className={classes.colHeader}>
                                    {config.component}
                                </div>
                            ))
                        }
                        <div className={classes.downloadButton}>
                            {this.getDownloadButton()}
                        </div>
                    </div>
                </div>
                <div className={classes.container}>
                    {
                        logs.map(log => (
                            <div key={log.id} className={classes.row}>
                                {
                                    COLUMNS.map((config, i) => (
                                        <div key={i} style={{
                                            width: HEADER_CONFIG[i].width * 30 * MAIN_CONTENT_WIDTH / DELTA,
                                            minWidth: HEADER_CONFIG[i].width * 30 * MAIN_CONTENT_WIDTH / DELTA
                                        }} className={classes.column}>
                                            { /*
                                                config.type === 'checkbox' ?
                                                    <Checkbox
                                                        checked={selectedIds.indexOf(log.id) > -1}
                                                        onClick={e => this.props.onSelect(log.id)}
                                                        className={classes.checkbox}
                                                    /> : null
                                                    */
                                            }
                                            {
                                                config.type === 'text' ?
                                                    <Typography component="a">
                                                        <Entry>
                                                            {
                                                                // !_.isEmpty(_.get(log, config.key)) ? _.get(log, config.key) :
                                                                //     config.altKey ? _.get(log, config.altKey) : ''
                                                                _.get(log, config.key)
                                                            }
                                                        </Entry>
                                                    </Typography> : null
                                            }
                                            {
                                                config.type === 'date' ?
                                                    <Entry >{moment(_.get(log, config.key)).format('DD MMM, YYYY, hh:mm a')}</Entry> : null
                                            }

                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    {
                        logs.length > 19 ?
                            <Button onClick={this.loadMore} >
                                Load more
                    </Button> : null
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    importerLog: _.get(state.LtdDashboard, 'importerLog'),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    getPageData: (pageNumber, appendResult, filter) => dispatch(OLtdDashboard.fetchImporterLogs(pageNumber, appendResult, filter)),
    downloadImporterLogsCSV: (whereFilter) => dispatch(OLtdDashboard.downloadImporterLogsCSV(whereFilter))
})

const DELTA = 1064;
const MAIN_CONTENT_WIDTH = window.outerWidth - 100;
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000,
    },
    toolbar: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        background: THEME.Colors.Grayscale.White,
        boxShadow: `0px 1px 3px ${fade('#000', 0.16)}`,
        '& > div': {
            height: TOOLBAR_HEIGHT,
            width: MAIN_CONTENT_WIDTH,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center'
        }
    },
    colHeader: {
        padding: '0px 4px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderLeft: `1px dashed ${fade('#000', 0.2)}`,
        '&:nth-child(6)': {
            borderRight: `1px dashed ${fade('#000', 0.2)}`,
        }
    },
    container: {
        paddingTop: TOOLBAR_HEIGHT,
        width: MAIN_CONTENT_WIDTH,
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 100,
    },
    downloadButton: {
        position: 'absolute',
        right: 0,
    },
    row: {
        flex: 1,
        display: 'flex',
        borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        borderLeft: `1px dashed ${fade('#000', 0.2)}`,

    },
    column: {
        borderRight: `1px dashed ${fade('#000', 0.2)}`,
        padding: 6,
        boxSizing: 'border-box',
        // '&:last-child': {
        //     background: THEME.Colors.Grayscale.White
        // }
    },
    checkbox: {
        padding: 0,
        minHeight: 0,
        minWidth: 0
    },
    actionContainer: {
        top: 140,
        right: -35,
        bottom: 'auto'
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(ImporterLog))