export const TOOLTIPS = {
    'Appeal number': 'Number of a particular proceeding on appeal within a situation or case',
    'Case': 'Specific incidents within a given situation that are defined by the suspect(s) and the conduct that gives rise to criminal liability under the Rome Statute',
    'Date': 'Date of notification/distribution of the decision',
    'Document number': 'The unique identifier of the decision in the format given by the ICC',
    'Document type': 'The type of the decision according to its content and/or source',
    'ID': 'The unique identifier of the Legal Finding in the CLD',
    'Finding': 'The unique identifier of the Legal Finding within the Decision at hand',
    'Judges': 'Composition of the Chamber that issued the resource contained in the CLD',
    'Keyword': 'Thesaurus term or concept of legal significance paired, when applicable, to corresponding legal provisions. The CLD keywords are structured in trees, ranging in specificity from general to specific, in relation to both legal concepts and legal provisions addressed in the decisions',
    'Legal finding number': 'The unique identifier of the Legal Finding within the Decision at hand',
    'Level of importance': 'The relative jurisprudential value of a Legal Finding in relation to the ICC case law as a whole',
    'Phase of case': 'Phase of the proceedings during which the decision was issued',
    'Search for Decisions': 'Search for judgments, decisions, orders, warrants, summons or any other similar type of'
}