import {
    FILTER_JUDICAL_DOCUMENT,
    METADATA_ABSTRACT,
    METADATA_ACADEMIC_WRITING,
    METADATA_ACCUSED,
    METADATA_ACQUITTAL,
    METADATA_ADMISSIBILITY,
    METADATA_ADVISORY_OPINION,
    METADATA_AD_HOC_COMMITTEE,
    METADATA_ALIA_OFACCUSED_NAME,
    METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE,
    METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_EN,
    METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_FR,
    METADATA_AMICUSCURIAE,
    METADATA_APPEAL,
    METADATA_APPLICATION_ARREST_WARRENT,
    METADATA_APPROXIMATE_DATE,
    METADATA_ARREST_WARRANT,
    METADATA_AUTHOR,
    METADATA_AUTHORIATIVE_LANGUAGE,
    METADATA_CASE_DOCUMENT_NUMBER,
    METADATA_CASE_NAME_EN,
    METADATA_CASE_NAME_FR,
    METADATA_CASE_NAME_ICC,
    METADATA_COMPOSITION_OF_CHAMBER,
    METADATA_CONFIDENTIAL,
    METADATA_CONTENT_TYPE,
    METADATA_CONVICTION,
    METADATA_CONVICTION_PARTIAL,
    METADATA_COPY_RIGHT_AUTHORISATION,
    METADATA_COUNSEL_FOR_DEFENCE,
    METADATA_COUNSEL_FOR_VICTIMS,
    METADATA_COURT_REPORTER,
    METADATA_DATABASE_RECORD_NO,
    METADATA_DATE_ACESSED,
    METADATA_DATE_CREATED,
    METADATA_DATE_EXPIRY,
    METADATA_DATE_PUBLISHED_LEGAL_TOOLS,
    METADATA_DATE_REFISTERED,
    METADATA_DECISION,
    METADATA_DECISION_APPLI_FOR_REST,
    METADATA_DECISION_CONFIRMATION,
    METADATA_DECISION_FACQUITTAL,
    METADATA_DECISION_JURISDICTION,
    METADATA_DECISION_ON_REVISION,
    METADATA_DECISION_REQ_AUTHORISATION,
    METADATA_DECISION_REVIEW,
    METADATA_DECISION_SENTENCE,
    METADATA_DEFENCE_SERVICE,
    METADATA_DIRECTION_AND_ORDER,
    METADATA_DISPLAY_AS,
    METADATA_DOCUMENT_CONTAINING,
    METADATA_DOCUMENT_NO,
    METADATA_DOCUMENT_TYPE,
    METADATA_DOMESTICLEGALINSTRUMENT,
    METADATA_DOWNLOAD_FROM_AUTHOR,
    METADATA_EARLY_INITIATIVES,
    METADATA_ENTERY_INTO_FORCE,
    METADATA_EXECUTIVE_BRANCH,
    METADATA_EXTERNAL_IDENTIFIER,
    METADATA_FRIENDLY_SETTLEMENT,
    METADATA_GABONESE_REPUBLIC,
    METADATA_ICC_CASECITED,
    METADATA_ICC_NAME,
    METADATA_ICC_PREPARATOR_WORK,
    METADATA_IMPLEMENTING_LEGISLATION,
    METADATA_IMPORTANCE,
    METADATA_IMPORTANT,
    METADATA_INTERNATIIONAL_JURIS_DICTION,
    METADATA_INTERNATIONAL,
    METADATA_INTERNATIONAL_CRIMINAL_LAW,
    METADATA_INTERNATIONAL_HUMANITARIAN_LAW,
    METADATA_INTERNATIONAL_HUMAN_RIGHTS_LAW,
    METADATA_INTERNATIONAL_LAW_COMMISSION,
    METADATA_INTERNATIONAL_LEGALINSTRUMENT,
    METADATA_INTERNATIONAL_ORGANISATION,
    METADATA_INTER_LOCUTORY_APPEAL,
    METADATA_INTER_LOCUTORY_APPEAL_NO,
    METADATA_INTER_PRETATION,
    METADATA_IS_COURT_RECORD,
    METADATA_JUDGEMENT,
    METADATA_JUDGEMENT_ON_APPEAL,
    METADATA_JUDGEMENT_ON_APPEAL_AGAINST,
    METADATA_JUDGEMENT_ON_APPEAL_AGAINST_ORDERS,
    METADATA_JUDICAL_DOCUMENT,
    METADATA_JUDICAL_DOCUMENT_EN,
    METADATA_JUDICAL_SUMMARY,
    METADATA_JUDICIAL_BODY,
    METADATA_KEY_JUDICAL_DOC,
    METADATA_KEY_WORD,
    METADATA_LANGUAGE,
    METADATA_LANGUARE,
    METADATA_LAW_OF_INTERNATIONAL_ORGANISATION,
    METADATA_LAW_OF_THE_SEA,
    METADATA_LEGISLATION,
    METADATA_LESS_IMPORTANT,
    METADATA_LEVEL_OF_CONFIDENTIALITY,
    METADATA_LIVE_INTERNET_LINK,
    METADATA_MERITS,
    METADATA_METADATA_COLLECTED_BY,
    METADATA_METADATA_FINALISEDON,
    METADATA_MILITARY_JURIS_DICTION,
    METADATA_MOST_IMPORTANT,
    METADATA_NEWS,
    METADATA_NEW_TRIAL_ORDERED,
    METADATA_NO,
    METADATA_NOTES,
    METADATA_NOT_APPLICABLE,
    METADATA_NOT_AVAILABLE,
    METADATA_NUMBER_OF_PAGE,
    METADATA_N_G_O,
    METADATA_ORDINARY_JURISDICTION,
    METADATA_ORGANISATION,
    METADATA_ORGANISATION_JUDICAL,
    METADATA_ORGANISATION_STATE_SOURCE,
    METADATA_ORIFINAL_LANGUAGE,
    METADATA_ORIGIN,
    METADATA_ORIGINAL_LANGUAGE,
    METADATA_OTHER,
    METADATA_OTHER_COURT_DOCUMENTS,
    METADATA_OTHER_PUBLIC,
    METADATA_OUT_COME_OF_TRIAL,
    METADATA_PANNEL_TEXT,
    METADATA_PARTICIPATINGS_STATES,
    METADATA_PARTICIPATING_VICTIM,
    METADATA_PEACE_AGREEMENTS,
    METADATA_PERSISTENT_URL,
    METADATA_PHASE_OF_CASE,
    METADATA_PHASE_OF_PREPARATOR_WORKS,
    METADATA_PLACE_OF_COURT,
    METADATA_PREPARATORY_COMESSION,
    METADATA_PREPARATORY_COMMITTEE,
    METADATA_PREPARATORY_WORK,
    METADATA_PRESIDING_JUDGE,
    METADATA_PRESS,
    METADATA_PRE_TRIAL,
    METADATA_PROSECUTION_SERVICE,
    METADATA_PROSECUTOR_TEAM,
    METADATA_PUBLIC,
    METADATA_PUBLICATIONS,
    METADATA_PUBLICIST,
    METADATA_PUBLISHER,
    METADATA_REFERTOTHE_LIST,
    METADATA_RELATED_CASE,
    METADATA_RELATED_CASE_NUMBER,
    METADATA_RELATED_ORGANISATION,
    METADATA_RELATED_RESOURCE,
    METADATA_REMEDY,
    METADATA_REPARATION_ORDER,
    METADATA_REPUBLIC_AFGHANISTAN,
    METADATA_REPUBLIC_PHILIPPINES,
    METADATA_REQUEST_FOR_AUTHORISATION,
    METADATA_RESOURCE_CITATION_ENGLISH,
    METADATA_RESOURCE_CITATION_FRENCH,
    METADATA_RESPONSIBLE_PARTNER,
    METADATA_REVIEW,
    METADATA_REVISION,
    METADATA_REVISION_ALLOWED,
    METADATA_ROME_CONFRENCE,
    METADATA_SCANNED_DOCUMENT,
    METADATA_SHORT_TITLE,
    METADATA_SHORT_TITLE_EN,
    METADATA_SHORT_TITLE_FR,
    METADATA_SITUATION_GEORGIA,
    METADATA_SITUATION_IN_BOLIVARIANI,
    METADATA_SITUATION_IN_BOLIVARIANII,
    METADATA_SITUATION_IN_DARFUR,
    METADATA_SITUATION_IN_LIBYA,
    METADATA_SITUATION_IN_PALESTINE,
    METADATA_SITUATION_IN_THE_PEOPLES,
    METADATA_SITUATION_REPUBLIC,
    METADATA_SITUATION_REPUBLICII,
    METADATA_SITUATION_REPUBLIC_BURUNDI,
    METADATA_SITUATION_REPUBLIC_COFCOTE,
    METADATA_SITUATION_REPUBLIC_KENYA,
    METADATA_SITUATION_REPUBLIC_MAIL,
    METADATA_SITUATION_REPUBLIC_THE_CONGO,
    METADATA_SITUATION_UGANDA,
    METADATA_SOURCE,
    METADATA_SOURCE_TYPE,
    METADATA_SOURCE_URL,
    METADATA_STATE_LEGISLATIVE_BRANCH,
    METADATA_STATE_OF_BOLIVIA,
    METADATA_STRIKING_OUT,
    METADATA_SUBJECT,
    METADATA_SUBJECT_LEVEL_2,
    METADATA_SUBJECT_LEVEL_3,
    METADATA_SUBJECT_LEVEL_4,
    METADATA_SUBMISSION,
    METADATA_TITLE,
    METADATA_TO_BE_DETERMINED,
    METADATA_TRANSCRIPTS,
    METADATA_TRANSLATED_TITLE_EN,
    METADATA_TRANSLATED_TITLE_FR,
    METADATA_TRIAL,
    METADATA_TYPE_OF_COURT,
    METADATA_UNKNOWN,
    METADATA_URL,
    METADATA_VESSELS,
    METADATA_VICTIMS_PARTICIPATION,
    METADATA_WEBSITE,
    METADATA_YES,
} from '../../Features/Translations/translationKeys.ltd';

export const getMetaData = (label: string, getTranslation: { (key: string): string; (arg0: string): any }) => {
    if (/related/i.test(label)) console.log('Related lbael', label);
    switch (label) {
        case 'Persistent URL':
            return getTranslation(METADATA_PERSISTENT_URL) || label;
        case 'Title':
            return getTranslation(METADATA_TITLE) || label;
        case 'External identifier':
            return getTranslation(METADATA_EXTERNAL_IDENTIFIER) || label;
        case 'Content type':
            return getTranslation(METADATA_CONTENT_TYPE) || label;
        case 'Organisation / State of source':
            return getTranslation(METADATA_ORGANISATION) || label;
        case 'Related organisation / state':
            return getTranslation(METADATA_RELATED_ORGANISATION) || label;
        case 'Organisation / State of judicial body':
            return getTranslation(METADATA_ORGANISATION_JUDICAL) || label;
        case 'Date created':
            return getTranslation(METADATA_DATE_CREATED) || label;
        case 'Language(s)':
            return getTranslation(METADATA_LANGUAGE) || label;
        case 'Original language':
            return getTranslation(METADATA_ORIGINAL_LANGUAGE) || label;
        case 'Authoritative language':
            return getTranslation(METADATA_AUTHORIATIVE_LANGUAGE) || label;
        case 'Abstract':
            return getTranslation(METADATA_ABSTRACT) || label;
        case 'Case name (ICC naming convention - EN)':
            return getTranslation(METADATA_CASE_NAME_EN) || label;
        case 'Case name (ICC naming convention - FR)':
            return getTranslation(METADATA_CASE_NAME_FR) || label;
        case 'Case/Document number':
            return getTranslation(METADATA_DOCUMENT_NO) || label;
        case 'Phase of case':
            return getTranslation(METADATA_PHASE_OF_CASE) || label;
        case 'ICC situation name':
            return getTranslation(METADATA_ICC_NAME) || label;
        case 'Date accessed / downloaded':
            return getTranslation(METADATA_DATE_ACESSED) || label;
        case 'Number of pages':
            return getTranslation(METADATA_NUMBER_OF_PAGE) || label;
        case 'Responsible partner':
            return getTranslation(METADATA_RESPONSIBLE_PARTNER) || label;
        case 'Origin':
            return getTranslation(METADATA_ORIGIN) || label;
        case 'Judicial document type':
            return getTranslation(METADATA_JUDICAL_DOCUMENT) || label;
        case 'URL':
            return getTranslation(METADATA_URL) || label;
        case 'Database Record Number':
            return getTranslation(METADATA_DATABASE_RECORD_NO) || label;
        case 'Judicial document':
            return getTranslation(METADATA_JUDICAL_DOCUMENT_EN) || label;
        case 'International legal instrument':
            return getTranslation(METADATA_INTERNATIONAL_LEGALINSTRUMENT) || label;
        case 'Publications':
            return getTranslation(METADATA_PUBLICATIONS) || label;
        case 'ICC Preparatory works':
            return getTranslation(METADATA_ICC_PREPARATOR_WORK) || label;
        case 'Domestic legal instrument':
            return getTranslation(METADATA_DOMESTICLEGALINSTRUMENT) || label;
        case 'News':
            return getTranslation(METADATA_NEWS) || label;
        case 'Academic writing':
            return getTranslation(METADATA_ACADEMIC_WRITING) || label;
        case 'Implementing legislation':
            return getTranslation(METADATA_IMPLEMENTING_LEGISLATION) || label;
        case 'Website':
            return getTranslation(METADATA_WEBSITE) || label;
        case 'Judicial Summary':
            return getTranslation(METADATA_JUDICAL_SUMMARY) || label;
        case 'Translated title (EN)':
            return getTranslation(METADATA_TRANSLATED_TITLE_EN) || label;
        case 'Translated title (FR)':
            return getTranslation(METADATA_TRANSLATED_TITLE_FR) || label;
        case 'Short title':
            return getTranslation(METADATA_SHORT_TITLE) || label;
        case 'Short title (EN)':
            return getTranslation(METADATA_SHORT_TITLE_EN) || label;
        case 'Short title (FR)':
            return getTranslation(METADATA_SHORT_TITLE_FR) || label;
        case 'Source':
            return getTranslation(METADATA_SOURCE) || label;
        case 'Source type':
            return getTranslation(METADATA_SOURCE_TYPE) || label;
        case 'Judicial body':
            return getTranslation(METADATA_JUDICIAL_BODY) || label;
        case 'Prosecution service':
            return getTranslation(METADATA_PROSECUTION_SERVICE) || label;
        case 'Defence service':
            return getTranslation(METADATA_DEFENCE_SERVICE) || label;
        case 'State (legislative branch)':
            return getTranslation(METADATA_STATE_LEGISLATIVE_BRANCH) || label;
        case 'State (executive branch)':
            return getTranslation(METADATA_EXECUTIVE_BRANCH) || label;
        case 'International organisation':
            return getTranslation(METADATA_INTERNATIONAL_ORGANISATION) || label;
        case 'International diplomatic conference':
            return getTranslation(METADATA_INTERNATIONAL) || label;
        case 'Non-governmental organisation':
            return getTranslation(METADATA_N_G_O) || label;
        case 'Publicist':
            return getTranslation(METADATA_PUBLICIST) || label;
        case 'Court reporter':
            return getTranslation(METADATA_COURT_REPORTER) || label;
        case 'Press':
            return getTranslation(METADATA_PRESS) || label;
        case 'Participating victim':
            return getTranslation(METADATA_PARTICIPATING_VICTIM) || label;
        case 'Other':
            return getTranslation(METADATA_OTHER) || label;
        case 'Unknown':
            return getTranslation(METADATA_UNKNOWN) || label;
        case 'Organisation / State of Source':
            return getTranslation(METADATA_ORGANISATION_STATE_SOURCE) || label;
        case 'Language(s)':
            return getTranslation(METADATA_LANGUARE) || label;
        case '(Refer to the List of Languages sheet)':
            return getTranslation(METADATA_REFERTOTHE_LIST) || label;
        case 'Original language':
            return getTranslation(METADATA_ORIFINAL_LANGUAGE) || label;
        case 'No':
            return getTranslation(METADATA_NO) || label;
        case 'Yes':
            return getTranslation(METADATA_YES) || label;
        case 'Approximate date':
            return getTranslation(METADATA_APPROXIMATE_DATE) || label;
        case 'Date of expiry':
            return getTranslation(METADATA_DATE_EXPIRY) || label;
        case 'Entry into force':
            return getTranslation(METADATA_ENTERY_INTO_FORCE) || label;
        case 'Source URL':
            return getTranslation(METADATA_SOURCE_URL) || label;
        case 'Notes':
            return getTranslation(METADATA_NOTES) || label;
        case 'Related resource':
            return getTranslation(METADATA_RELATED_RESOURCE) || label;
        case 'Related documents':
            return getTranslation(METADATA_RELATED_RESOURCE) || label;
        case 'ICC case(s) cited':
            return getTranslation(METADATA_ICC_CASECITED) || label;
        case 'Legislation cited':
            return getTranslation(METADATA_LEGISLATION) || label;
        case 'Case name (ICC naming convention)':
            return getTranslation(METADATA_CASE_NAME_ICC) || label;
        case 'Alternative and / or short case title':
            return getTranslation(METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE) || label;
        case 'Alternative and / or short case title (EN)':
            return getTranslation(METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_EN) || label;
        case 'Alternative and / or short case title (FR)':
            return getTranslation(METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_FR) || label;
        case 'Case/Document number':
            return getTranslation(METADATA_CASE_DOCUMENT_NUMBER) || label;
        case 'Related case':
            return getTranslation(METADATA_RELATED_CASE) || label;
        case 'Related case number':
            return getTranslation(METADATA_RELATED_CASE_NUMBER) || label;
        case 'Key judicial document':
            return getTranslation(METADATA_KEY_JUDICAL_DOC) || label;
        case 'Application for arrest warrant or summons to appear (article 58)':
            return getTranslation(METADATA_APPLICATION_ARREST_WARRENT) || label;
        case 'Decision of acquittal or conviction (article 74)':
            return getTranslation(METADATA_DECISION_FACQUITTAL) || label;
        case 'Decision on application for arrest warrant or summons to appear application (article 58)':
            return getTranslation(METADATA_DECISION_APPLI_FOR_REST) || label;
        case 'Decision on confirmation of charges (article 61.(7))':
            return getTranslation(METADATA_DECISION_CONFIRMATION) || label;
        case 'Decision on request for authorisation of investigation (article 15.4 and 15.5)':
            return getTranslation(METADATA_DECISION_REQ_AUTHORISATION) || label;
        case 'Decision on review concerning reduction of sentence (article 110)':
            return getTranslation(METADATA_DECISION_REVIEW) || label;
        case 'Decision on revision of conviction or sentence (article 84)':
            return getTranslation(METADATA_DECISION_ON_REVISION) || label;
        case 'Decision on sentence (article 76)':
            return getTranslation(METADATA_DECISION_SENTENCE) || label;
        case 'Document containing the charges (regulation 52)':
            return getTranslation(METADATA_DOCUMENT_CONTAINING || label);
        case 'Judgement on appeal against decision of acquittal or conviction or against sentence (article 83 and rule 150)':
            return getTranslation(METADATA_JUDGEMENT_ON_APPEAL) || label;
        case 'Judgement on appeal against other decisions (article 83 and rule 158)':
            return getTranslation(METADATA_JUDGEMENT_ON_APPEAL_AGAINST) || label;
        case 'Judgement on appeal against reparation orders (article 83 and rule 153)':
            return getTranslation(METADATA_JUDGEMENT_ON_APPEAL_AGAINST_ORDERS) || label;
        case 'Reparation order (article 75)':
            return getTranslation(METADATA_REPARATION_ORDER) || label;
        case 'Request for authorisation of investigation (article 15.3 and regulation 49)':
            return getTranslation(METADATA_REQUEST_FOR_AUTHORISATION) || label;
        case 'Not available':
            return getTranslation(METADATA_NOT_AVAILABLE) || label;
        case 'Not applicable':
            return getTranslation(METADATA_NOT_APPLICABLE) || label;
        case 'Outcome of trial':
            return getTranslation(METADATA_OUT_COME_OF_TRIAL) || label;
        case 'Acquittal (full)':
            return getTranslation(METADATA_ACQUITTAL) || label;
        case 'Conviction (full)':
            return getTranslation(METADATA_CONVICTION) || label;
        case 'Conviction (partial)':
            return getTranslation(METADATA_CONVICTION_PARTIAL) || label;
        case 'New trial ordered':
            return getTranslation(METADATA_NEW_TRIAL_ORDERED) || label;
        case 'Revision allowed':
            return getTranslation(METADATA_REVISION_ALLOWED) || label;
        case 'Pre-Trial':
            return getTranslation(METADATA_PRE_TRIAL) || label;
        case 'Trial':
            return getTranslation(METADATA_TRIAL) || label;
        case 'Interlocutory appeal':
            return getTranslation(METADATA_INTER_LOCUTORY_APPEAL) || label;
        case 'Appeal':
            return getTranslation(METADATA_APPEAL) || label;
        case 'Review':
            return getTranslation(METADATA_REVIEW) || label;
        case 'Revision':
            return getTranslation(METADATA_REVISION) || label;
        case 'Admissibility':
            return getTranslation(METADATA_ADMISSIBILITY) || label;
        case 'Merits':
            return getTranslation(METADATA_MERITS) || label;
        case '[Remedy/compensation] / [Satisfaction]':
            return getTranslation(METADATA_REMEDY) || label;
        case 'Advisory opinion':
            return getTranslation(METADATA_ADVISORY_OPINION) || label;
        case 'Interpretation':
            return getTranslation(METADATA_INTER_PRETATION) || label;
        case 'Striking out':
            return getTranslation(METADATA_STRIKING_OUT) || label;
        case 'Friendly settlement':
            return getTranslation(METADATA_FRIENDLY_SETTLEMENT) || label;
        case 'To be determined':
        case 'TO BE DETERMINED':
            return getTranslation(METADATA_TO_BE_DETERMINED) || label;
        case 'Arrest Warrant/ Indictment':
            return getTranslation(METADATA_ARREST_WARRANT) || label;
        case 'Submission':
            return getTranslation(METADATA_SUBMISSION) || label;
        case 'Direction and Order':
            return getTranslation(METADATA_DIRECTION_AND_ORDER) || label;
        case 'Decision':
            return getTranslation(METADATA_DECISION) || label;
        case 'Judgment':
            return getTranslation(METADATA_JUDGEMENT) || label;
        case 'Transcripts':
            return getTranslation(METADATA_TRANSCRIPTS) || label;
        case 'Other Court Documents':
            return getTranslation(METADATA_OTHER_COURT_DOCUMENTS) || label;
        case 'Composition of chamber':
            return getTranslation(METADATA_COMPOSITION_OF_CHAMBER) || label;
        case 'Presiding judge':
            return getTranslation(METADATA_PRESIDING_JUDGE) || label;
        case 'Place of court':
            return getTranslation(METADATA_PLACE_OF_COURT) || label;
        case 'Type of court':
            return getTranslation(METADATA_TYPE_OF_COURT) || label;
        case 'Ordinary jurisdiction':
            return getTranslation(METADATA_ORDINARY_JURISDICTION) || label;
        case 'Military jurisdiction':
            return getTranslation(METADATA_MILITARY_JURIS_DICTION) || label;
        case 'International(ised) jurisdiction':
            return getTranslation(METADATA_INTERNATIIONAL_JURIS_DICTION) || label;

        case 'Accused / Defendant':
            return getTranslation(METADATA_ACCUSED) || label;
        case 'Alias of accused name ':
            return getTranslation(METADATA_ALIA_OFACCUSED_NAME) || label;
        case 'Prosecutor team / Claimant':
        case "Prosecutor's team / Claimant":
            return getTranslation(METADATA_PROSECUTOR_TEAM) || label;
        case 'Counsel for defence':
            return getTranslation(METADATA_COUNSEL_FOR_DEFENCE) || label;
        case 'Participating states':
            return getTranslation(METADATA_PARTICIPATINGS_STATES) || label;
        case 'Victims participation':
            return getTranslation(METADATA_VICTIMS_PARTICIPATION) || label;
        case 'Counsel for victims':
            return getTranslation(METADATA_COUNSEL_FOR_VICTIMS) || label;
        case 'Amicus curiae':
            return getTranslation(METADATA_AMICUSCURIAE) || label;
        case 'Is Court record':
        case 'Is court record':
            return getTranslation(METADATA_IS_COURT_RECORD) || label;
        case 'Situation in Darfur/Sudan':
            return getTranslation(METADATA_SITUATION_IN_DARFUR) || label;
        case 'Situation in Georgia':
            return getTranslation(METADATA_SITUATION_GEORGIA) || label;
        case 'Situation in Libya':
            return getTranslation(METADATA_SITUATION_IN_LIBYA) || label;
        case 'Situation in the Bolivarian Republic of Venezuela I':
            return getTranslation(METADATA_SITUATION_IN_BOLIVARIANI) || label;
        case 'Situation in the Bolivarian Republic of Venezuela II':
            return getTranslation(METADATA_SITUATION_IN_BOLIVARIANII) || label;
        case 'Situation in the Central African Republic':
            return getTranslation(METADATA_SITUATION_REPUBLIC) || label;
        case 'Situation in the Central African Republic II':
            return getTranslation(METADATA_SITUATION_REPUBLICII) || label;
        case 'Situation in the Democratic Republic of the Congo':
            return getTranslation(METADATA_SITUATION_REPUBLIC_THE_CONGO) || label;
        case 'Situation in the Gabonese Republic':
            return getTranslation(METADATA_GABONESE_REPUBLIC) || label;
        case 'Situation in the Islamic Republic of Afghanistan':
            return getTranslation(METADATA_REPUBLIC_AFGHANISTAN) || label;
        case 'Situation in the People´s Republic of Bangladesh/Republic of the Union of Myanmar':
            return getTranslation(METADATA_SITUATION_IN_THE_PEOPLES) || label;
        case 'Situation in the Republic of Burundi':
            return getTranslation(METADATA_SITUATION_REPUBLIC_BURUNDI) || label;
        case 'Situation in the Republic of Côte dIvoire':
            return getTranslation(METADATA_SITUATION_REPUBLIC_COFCOTE) || label;
        case 'Situation in the Republic of Kenya':
            return getTranslation(METADATA_SITUATION_REPUBLIC_KENYA) || label;
        case 'Situation in the Republic of Mali':
            return getTranslation(METADATA_SITUATION_REPUBLIC_MAIL) || label;
        case 'Situation in the State of Palestine':
            return getTranslation(METADATA_SITUATION_IN_PALESTINE) || label;
        case 'Situation in Uganda':
            return getTranslation(METADATA_SITUATION_UGANDA) || label;
        case 'Situation on Registered Vessels of the Union of the Comoros, the Hellenic Republic and the Kingdom of Cambodia':
            return getTranslation(METADATA_VESSELS) || label;
        case 'Situation in the Plurinational State of Bolivia':
            return getTranslation(METADATA_STATE_OF_BOLIVIA) || label;
        case 'Situation in the Republic of the Philippines':
            return getTranslation(METADATA_REPUBLIC_PHILIPPINES) || label;
        case 'Interlocutory appeal no':
        case 'Interlocutory appeal no.':
            return getTranslation(METADATA_INTER_LOCUTORY_APPEAL_NO) || label;
        case 'Author/Editor':
            return getTranslation(METADATA_AUTHOR) || label;
        case 'Publisher':
            return getTranslation(METADATA_PUBLISHER) || label;
        case 'Level of confidentiality':
            return getTranslation(METADATA_LEVEL_OF_CONFIDENTIALITY) || label;
        case 'confidential':
            return getTranslation(METADATA_CONFIDENTIAL) || label;
        case 'public':
            return getTranslation(METADATA_PUBLIC) || label;
        case 'Importance':
            return getTranslation(METADATA_IMPORTANCE) || label;
        case 'Less important':
            return getTranslation(METADATA_LESS_IMPORTANT) || label;
        case 'Important':
            return getTranslation(METADATA_IMPORTANT) || label;
        case 'Most important':
            return getTranslation(METADATA_MOST_IMPORTANT) || label;
        case 'Copyright authorisation':
            return getTranslation(METADATA_COPY_RIGHT_AUTHORISATION) || label;
        case 'Subject':
            return getTranslation(METADATA_SUBJECT) || label;
        case 'International criminal law':
            return getTranslation(METADATA_INTERNATIONAL_CRIMINAL_LAW) || label;
        case 'International human rights law':
            return getTranslation(METADATA_INTERNATIONAL_HUMAN_RIGHTS_LAW) || label;
        case 'International humanitarian law':
            return getTranslation(METADATA_INTERNATIONAL_HUMANITARIAN_LAW) || label;
        case 'Other public international law':
            return getTranslation(METADATA_OTHER_PUBLIC) || label;
        case 'Law of international organisations':
            return getTranslation(METADATA_LAW_OF_INTERNATIONAL_ORGANISATION) || label;
        case 'Law of the sea':
            return getTranslation(METADATA_LAW_OF_THE_SEA) || label;
        case 'Peace agreements':
            return getTranslation(METADATA_PEACE_AGREEMENTS) || label;
        case 'Subject (Level 2)':
            return getTranslation(METADATA_SUBJECT_LEVEL_2) || label;
        case 'Subject (Level 3)':
            return getTranslation(METADATA_SUBJECT_LEVEL_3) || label;
        case 'Subject (Level 4)':
            return getTranslation(METADATA_SUBJECT_LEVEL_4) || label;
        case 'Phase of preparatory works':
            return getTranslation(METADATA_PHASE_OF_PREPARATOR_WORKS) || label;
        case 'Early Initiatives':
            return getTranslation(METADATA_EARLY_INITIATIVES) || label;
        case 'International Law Commission':
            return getTranslation(METADATA_INTERNATIONAL_LAW_COMMISSION) || label;
        case 'Ad Hoc Committee':
            return getTranslation(METADATA_AD_HOC_COMMITTEE) || label;
        case 'Preparatory Committee':
            return getTranslation(METADATA_PREPARATORY_COMMITTEE) || label;
        case 'Rome Conference':
            return getTranslation(METADATA_ROME_CONFRENCE) || label;
        case 'Preparatory Commission':
            return getTranslation(METADATA_PREPARATORY_COMESSION) || label;
        case 'Preparatory works (Genocide Convention)':
            return getTranslation(METADATA_PREPARATORY_WORK) || label;
        case 'Resource citation (EN)':
            return getTranslation(METADATA_RESOURCE_CITATION_ENGLISH) || label;
        case 'Resource citation (FR)':
            return getTranslation(METADATA_RESOURCE_CITATION_FRENCH) || label;
        case 'Keyword(s)':
            return getTranslation(METADATA_KEY_WORD) || label;
        case 'Document type':
            return getTranslation(METADATA_DOCUMENT_TYPE) || label;
        case 'Metadata collected by':
            return getTranslation(METADATA_METADATA_COLLECTED_BY) || label;
        case 'Metadata finalised on':
            return getTranslation(METADATA_METADATA_FINALISEDON) || label;
        case 'Date published in the Legal Tools':
        case 'Date published in Legal Tools':
            return getTranslation(METADATA_DATE_PUBLISHED_LEGAL_TOOLS) || label;
        case 'Date registered':
            return getTranslation(METADATA_DATE_REFISTERED) || label;
        case 'Display as':
            return getTranslation(METADATA_DISPLAY_AS) || label;
        case 'Scanned document':
            return getTranslation(METADATA_SCANNED_DOCUMENT) || label;
        case 'Live internet link':
            return getTranslation(METADATA_LIVE_INTERNET_LINK) || label;
        case 'Download from authoritative link':
        case 'Download from authoritative source':
            return getTranslation(METADATA_DOWNLOAD_FROM_AUTHOR) || label;
        case 'Judicial document':
            return getTranslation(FILTER_JUDICAL_DOCUMENT) || label;
        case 'Decision on jurisdiction or admissibility (articles 18 and 19)':
            return getTranslation(METADATA_DECISION_JURISDICTION) || label;
        default:
            return label;
    }
};
