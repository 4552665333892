import React, { FC, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography, Divider, Button } from '@material-ui/core';
import _ from 'lodash';
import { TState } from '../../../RootReducer';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { connect } from 'react-redux';
import { TLtdDashboardState } from '../../../Features/LtdDashboard/@types';
import SuggestionItem from './SuggestionItem';
import { ONewLTDDocument } from '../../../Features/AddNewLTDDocument/redux-config';
import { LTD_DOC_SUGGESTION_PARENT_NODE } from '../../../Features/AddNewLTDDocument/addNewLegalFinding-reducer';


export interface SuggestionsProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {

}

interface IStateProps {
    suggestions: TLtdDashboardState['suggestions']
    resolver: any[]
}
interface IDispatchProps {
    fetchSuggestions: (pageNumber: number, appendResult?: boolean) => any
    editSuggestedDoc: (form: Record<string, any>) => any
}

const Suggestions: FC<SuggestionsProps> = (props) => {
    const { classes, suggestions: { suggestions: suggestionList, pageNumber }, resolver } = props;

    useEffect(() => {
        getPageData();
    }, [])

    const getPageData = async () => {
        await props.fetchSuggestions(0, false)
    }

    const loadMore = async () => {
        await props.fetchSuggestions(pageNumber + 1, true);
    }

    return (
        <div>

            <div className={classes.container}>
                <Typography className={classes.title} variant="h6">Document addition suggestions</Typography>
                <Divider />
                {suggestionList.map(suggestionItem => (
                    <div className="my-3" key={suggestionItem.id}>
                        <SuggestionItem onEditDoc={props.editSuggestedDoc} item={suggestionItem} resolver={resolver} />
                    </div>
                ))}
                {
                    suggestionList.length > 19 ? (
                        <Button onClick={loadMore} >
                            Load more
                        </Button>
                    ) : null
                }
            </div>
        </div>
    )
}

const STYLES = (theme: Theme) => createStyles({
    container: {
        width: 700,
        margin: '20px auto',
        paddingBottom: 100
    },
    title: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    }
});


const mapStateToProps = (state: TState): IStateProps => ({
    suggestions: _.get(state.LtdDashboard, 'suggestions'),
    resolver: ({ ..._.get(state.App, 'appConstants'), ..._.get(state.App, 'ltdIncludeOptions') })
})

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    fetchSuggestions: (pageNumber, appendResult) => dispatch(OLtdDashboard.fetchSuggestions(pageNumber, appendResult)),
    editSuggestedDoc: (form) => dispatch(ONewLTDDocument.openPrefilledDialog(LTD_DOC_SUGGESTION_PARENT_NODE, form))
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Suggestions));