const legalToolAdvisoryFn = `<div id="content" class="content">
<div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/" >
Status of work on the Tools&nbsp;»&nbsp;
</a><a href="status-of-work-on-the-tools/ltac/" >
LTAC
</a></p></div><div id="getUrlText">
<div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree">
<i class="glyphicon glyphicon-collapse-down"></i></div> 
<a href="javascript:window.print();" style="float:right;">
<i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:256/text [begin] -->
    <div id="c256" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        Comité consultatif sur les Outils juridiques
        </h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext"> 
        Mis en place à l’échelle de la Cour, le Comité consultatif sur les outils juridiques est composé de membres de tous les organes. Le Comité évalue les besoins des utilisateurs concernés au sein de la Cour et d’autres participants à ses procédures, comme les conseils de la Défense et ceux des victimes. Il a mis au point le manuel sur les métadonnées et les catégories de mots-clés sur lesquels repose le fonctionnement des Outils juridiques.
           </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:256/text [end] -->
    </div>`;
export default legalToolAdvisoryFn;
