import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import Theme from '../Resources/Theme';

interface Props extends WithStyles<typeof STYLES> { }

class Divider extends Component<Props> {

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>

            </div>
        )
    }
}

const STYLES = theme => createStyles({
    root: {
        borderBottom: `1px dashed ${Theme.Colors.Fourth}`,
        margin: '8px 0px'
    }
})

export default withStyles(STYLES)(Divider)