import _ from 'lodash';
import csvtojson from 'csvtojson';
import { TLtdDocFormFieldKey, LTD_DOCUMENT_FORM_FIELDS } from "../../../Components/BatchEdit/constants";
import moment from 'moment';
import { LTD_DOC_DATE_FORMAT } from '../../../Components/BatchEdit/useBatchEdit';


export const buildFormFormCsv = async (content: string, resolver: Record<string, any>) => {
    const json = await csvtojson().fromString(content)
    if (json.length === 0) return { forms: [], labelKeys: [] };
    const forms: Array<Record<string, any>> = [];
    json.forEach(form => {
        const formattedFormData = formatForm(form, resolver);
        forms.push(formattedFormData)
    })
    const keys = (Object.keys(forms[0]) || []) as TLtdDocFormFieldKey[];
    return { forms, labelKeys: keys };

}


const formatForm = (form: Record<string, any>, resolver: Record<string, any>) => {
    const _form: typeof form = {};
    Object.keys(form).forEach(key => {
        const _field = _.find(LTD_DOCUMENT_FORM_FIELDS, { label: key });
        if (_field)
            _form[_field.key] = form[key]
    })
    return resolveFormDataValues(_form, resolver)

}


const resolveFormDataValues = (form: Record<string, any>, resolver: Record<string, any>) => {
    let _form: typeof form = {};
    Object.keys(form).forEach(k => {
        _form = _.set(_form, k, resolveValue(form[k], k as TLtdDocFormFieldKey, resolver))
    })

    return _form;
}


const resolveValue = (value: string, fieldKey: TLtdDocFormFieldKey, resolver: Record<string, { name: string, value: string }[]>, separator: string = ';') => {
    const resolverConfig = ltdDocFieldReslover[fieldKey]
    if (!resolverConfig) return value;
    if (resolverConfig.type === 'array') {
        const values = value.split(separator);
        const fields: typeof resolver[0] = [];
        values.forEach(v => {
            const field = _.find(_.get(resolver, _.get(resolverConfig, 'resolveKey') || '') || [], { name: v });
            if (field)
                fields.push(field)
            else {
                fields.push({ name: v, value: v });
            }
        })
        return fields.map(f => f.value)
    } else if (resolverConfig.type === 'date') {
        if (value) {
            const date = moment(value, LTD_DOC_DATE_FORMAT);
            return (date.isValid()) ? date : null;
        }
    } else {
        const field = _.find(_.get(resolver, _.get(resolverConfig, 'resolveKey') || '') || [], { name: value });
        return field ? field.value : value
    }
}



export const ltdDocFieldReslover: Partial<Record<TLtdDocFormFieldKey, { type: 'single' | 'array' | 'date', resolveKey: string }>> = {
    contentType: { type: 'single', resolveKey: 'contentType' },
    isAuthoritativeLanguage: { type: 'single', resolveKey: 'booleanOption' },
    isOriginalLanguage: { type: 'single', resolveKey: 'booleanOption' },
    copyRightAuthorisation: { type: 'single', resolveKey: 'booleanOption' },
    isCourtRecord: { type: 'single', resolveKey: 'booleanOption' },
    participatingStates: { type: 'single', resolveKey: 'booleanOption' },
    victimParticipation: { type: 'single', resolveKey: 'booleanOption' },
    languageIds: { type: 'array', resolveKey: 'languages' },
    sourceTypeIds: { type: 'array', resolveKey: 'sourceTypes' },
    confidentiality: { type: 'single', resolveKey: 'confidentiality' },
    importance: { type: 'single', resolveKey: 'importance' },
    preparatoryPhase: { type: 'single', resolveKey: 'preparatoryPhase' },
    Subject: { type: 'single', resolveKey: 'subject' },
    "Subject (Level 2)": { type: 'single', resolveKey: 'subject' },
    "Subject (Level 3)": { type: 'single', resolveKey: 'subject' },
    "Subject (Level 4)": { type: 'single', resolveKey: 'subject' },
    iccSituationIds: { type: 'single', resolveKey: 'iccSituations' },
    courtType: { type: 'single', resolveKey: 'courtType' },
    judicialDocumentIds: { type: 'array', resolveKey: 'judicialDocuments' },
    judicialDocumentType: { type: 'single', resolveKey: 'judicialDocumentType' },
    phaseOfCaseIds: { type: 'single', resolveKey: 'phaseOfCases' },
    trailOutcome: { type: 'single', resolveKey: 'trialOutcome' },
    "documentOrigin.source": { type: 'single', resolveKey: 'documentOrigin' },
    responsiblePartner: { type: 'single', resolveKey: 'partner' },
    dateCreated: { type: 'date', resolveKey: '' },
    approximateDate: { type: 'date', resolveKey: '' },
    expiryDate: { type: 'date', resolveKey: '' },
    entryIntoForce: { type: 'date', resolveKey: '' },
    "Metadata finalised on": { type: 'date', resolveKey: '' },
    accessDate: { type: 'date', resolveKey: '' },
    publishDate: { type: 'date', resolveKey: '' },
    registerDate: { type: 'date', resolveKey: '' },
    keywordIds: { type: 'array', resolveKey: 'keywords' },
    relatedDocIds: { type: 'array', resolveKey: '' },
    relatedPurls: { type: 'array', resolveKey: '' }
}

