import {
  IconButton,
  createStyles,
  Theme,
  InputAdornment,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import TranslationModel from "../../Models/Translations";
import { TreeNode } from "../../Models/Translations/@types.js";
import React, {
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import SortableTree, {
  changeNodeAtPath,
  getNodeAtPath,
  TreeItem,
  TreePath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
// import TranslationJson from "./translations.json";
import "./tree.css";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import _ from "lodash";

export interface TranslationJsonTreeProps extends WithStyles<typeof STYLES> {
  onNodeClick?: (pathFromRoot: string) => any;
  /**
   * Value would be the path from the root.
   */
  translationJSON: any
  selectedNode?: string;
}

const TranslationJsonTree: FC<TranslationJsonTreeProps> = (props) => {
  const { selectedNode: selectedNodePath, onNodeClick, classes, translationJSON } = props;

  const [rootNode, setRootNode] = useState<TreeNode[]>(
    TranslationModel.treeBuilderWithoutTranslation(translationJSON)
  );

  const [searchQuery, setSearchQuery] = useState<string>("");

  /**
   * id of the currently selected node
   * */
  const [activeNode, setActiveNode] = useState<string | undefined>(undefined);


  useEffect(() => {
    if (selectedNodePath !== undefined) {
      let activeNode = selectedNodePath.split("-");
      setActiveNode(activeNode[activeNode.length - 1]);
      if (typeof getNodeAtPath === "function") {
        const node = getNodeAtPath({
          treeData: rootNode,
          getNodeKey: ({ node }) => node.id,
          path: activeNode.slice(0, activeNode.length - 1),
        }).node;
        if (node)
          setRootNode(
            changeNodeAtPath({
              getNodeKey: ({ node }) => node.id,
              treeData: rootNode,
              path: activeNode.slice(0, activeNode.length - 1),
              newNode: { ...node, expanded: true },
            }) as TreeNode[]
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleActiveNode(paths: string) {
    if (typeof onNodeClick === "function") onNodeClick(paths);
    let activeNode = paths.split("-");
    setActiveNode(activeNode[activeNode.length - 1]);
  }

  function renderButton(
    node: TreeItem,
    path: TreePath["path"],
    isSearchMatch: boolean
  ): ReactElement<any> {
    let idPath = path.join("-");
    return (
      <div
        className={clsx(classes.link, { [classes.searchMatch]: isSearchMatch })}
        onClick={() => handleActiveNode(idPath)}
      >
        {node.title || ""}
      </div>
    );
  }

  return (
    <div className={classes.treeWrapper} id="translation">
      <TextField
        placeholder={"Search Items"}
        onChange={(e) => setSearchQuery(_.capitalize(e.target.value))}
        fullWidth
        value={searchQuery}
        InputProps={{
          startAdornment: <div style={{ marginRight: 16 }} />,
          endAdornment: searchQuery ? (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchQuery("")}>
                <CloseRoundedIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <SortableTree
        treeData={rootNode || []}
        onChange={(rootNode) => {
          setRootNode(rootNode as SetStateAction<TreeNode[]>);
        }}
        rowHeight={40}
        getNodeKey={({ node }) => node.id}
        searchQuery={searchQuery}
        onlyExpandSearchedNodes
        generateNodeProps={({ node, path, isSearchMatch }) => {
          return {
            title: (
              <Typography
                variant="caption"
                component="div"
                className={clsx(
                  classes.node,
                  { [classes.active]: activeNode === node.id },
                  { [classes.leaf]: (node.children || []).length === 0 }
                )}
              >
                {renderButton(node, path, isSearchMatch)}
              </Typography>
            ),
          };
        }}
      />
    </div>
  );
};

const STYLES = (theme: Theme) => {
  const {
    palette: { primary },
  } = theme;
  return createStyles({
    treeWrapper: {
      position: "relative",
      height: "100%",
      paddingTop: 48,
      "& > div:first-child": {
        position: "absolute",
        top: 0,
        "& > div": {
          height: 48,
          paddingRight: 0,
        },
      },
      "& .rst__moveHandle": {
        display: "none",
      },
    },
    link: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      alignItems: "center",
    },
    searchMatch: {
      border: `3px Solid ${primary.light}`,
      paddingLeft: 20,
    },
    node: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      width: "100%",
      height: "100%",
      transition: "100ms",
    },
    active: {
      paddingLeft: "10px",
      backgroundColor: primary["light"],
    },
    leaf: {
      left: "-25px",
      position: "relative",
    },
    '& .ReactVirtualized__Grid': {
      maxWidth: '250px !important'
    }
  });
};

export default withStyles(STYLES)(TranslationJsonTree);
