import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Input, TextField, Icon, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import THEME from '../../../Resources/Theme';
import EditTree from './EditTree';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { TUser } from '../../../Features/Authentication/@types';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import utilities from '../../../Resources/Utils';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import Entry from '../Entry';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { TState } from '../../../RootReducer';

interface IStateProps {
    user: TUser
}

interface IDispatchProps {
    setLoading: (isLoading: boolean) => void
    searchTree: (term: string) => any
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

const HEADER_SECTIONS = [{
    title: 'Tree'
}, {
    title: 'Total'
}, {
    title: 'Live'
}, {
    title: 'Last Edit'
}]
class TreeManagement extends Component<IProps, IState> {
    state = {
        term: ''
    }


    handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({ term: value });
        this.debouncedSearch(value)
    }


    searchTree = async (term: string) => {
        this.props.setLoading(true);

        await this.props.searchTree(term)
        this.props.setLoading(false)
    }

    debouncedSearch = _.debounce(this.searchTree, 800)

    clearField = () => {
        this.setState({ term: '' });
        this.searchTree('');
    }

    render() {
        const { classes, user } = this.props;
        const SECTIONS = [
            { title: 'Tree', flex: 1, component: (<EditTree />) },
            // { title: 'RECORDS', flex: 1, component: (<Typography>1212</Typography>) }
        ]




        if (!utilities.isAuthenticated())
            return <Redirect to="/" />


        return (
            <div className={classes.root}>
                <div className={classes.toolbar}>
                    <div>
                        {
                            HEADER_SECTIONS.map((section, i) => (
                                <div key={i} className="heading flex-row align-center">

                                    {i === 0 ?
                                        <TextField InputProps={{
                                            startAdornment: <Icon>search</Icon>,
                                            endAdornment: this.state.term ? <IconButton style={{ padding: 2 }} onClick={this.clearField}><Icon>close</Icon></IconButton> : null
                                        }}
                                            value={this.state.term || ''}
                                            placeholder="Search tree"
                                            onChange={this.handleSearchChange}
                                        />
                                        : <Entry type="label" key={section.title}> {section.title} </Entry>
                                        // : <Typography component="h4" key={section.title} >{section.title}</Typography>
                                    }
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className={classes.container}>
                    {
                        SECTIONS.map(section => (
                            <div key={section.title} style={{ flex: section.flex }}>
                                {section.component}
                            </div>
                        ))
                    }

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: TState): IStateProps => ({
    ..._.pick(state.Auth, ['user']),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    searchTree: (term: string) => dispatch(ODocumentTree.treeSearch(term)),
    setLoading: (isLoading: boolean) => dispatch(OLtdDashboard.setLoading(isLoading))

})


const MAIN_CONTENT_WIDTH = '100%';
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000
    },
    toolbar: {
        position: 'fixed',
        width: '100%',
        // backgroundColor: '#403F41',
        zIndex: 1,
        boxShadow: `0px 1px 3px ${fade('#000', 0.16)}`,
        background: THEME.Colors.Grayscale.White,
        paddingLeft: 146,
        '& > div': {
            height: TOOLBAR_HEIGHT,
            width: 1056,
            display: 'flex',
            alignItems: 'center',
            '& > .heading': {
                flex: 1,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                '&:nth-child(2)': {
                    maxWidth: 90
                },
                '&:nth-child(3)': {
                    maxWidth: 90
                },
                '&:nth-child(4)': {
                    maxWidth: 90
                }
            }
        }
    },
    container: {
        paddingTop: TOOLBAR_HEIGHT,
        width: MAIN_CONTENT_WIDTH,
        padding: '0px 100px',
        height: '100%',
        margin: '0 auto',
        display: 'flex'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(TreeManagement))