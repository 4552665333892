import React, { FC, useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import classNames from 'classnames';
import SimpleDialog from '../../Components/SimpleDialog';
import { useSurvey } from '../../Hooks/useSurvey';
import { SurveyService } from '../../Services/Survey.service';
import { WithTranslationProps, withTranslations } from '../Translations/withTranslations';
import { SURVEY_DO_NOT_SHOW_AGAIN, SURVEY_LATER, SURVEY_OTHER, SURVEY_PRACTITIONER, SURVEY_RESEARCHER, SURVEY_RESPONSE_TEXT, SURVEY_STUDENT, SURVEY_SUBMIT, SURVEY_SUGGESTION_ANY_IMPROVEMENTS, SURVEY_TEACHER, SURVEY_TITLE_TEXT, SURVEY_USING_THE_LTD_IN_MY } from '../Translations/translationKeys.ltd';

export interface LtdSurveyDialogProps extends WithStyles<typeof STYLES> {

}

const LtdSurveyDialog: FC<LtdSurveyDialogProps & WithTranslationProps> = (props) => {
    const { classes, getTranslation } = props;
    const { isShowSurvey, showLater, doNotShowAgain } = useSurvey();
    const [open, setOpen] = useState(isShowSurvey);
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const CHECKBOX_OPTIONS = [
        { label: 'Practitioner', name: 'Practitioner', translationKey: SURVEY_PRACTITIONER },
        { label: 'Researcher', name: 'Researcher', translationKey: SURVEY_RESEARCHER },
        { label: 'Teacher', name: 'Teacher', translationKey: SURVEY_TEACHER },
        { label: 'Student', name: 'Student', translationKey: SURVEY_STUDENT },
        { label: 'Other', name: 'Other', translationKey: SURVEY_OTHER },
    ]

    useEffect(() => {
        setOpen(isShowSurvey);
    }, [isShowSurvey])

    const handleChange = (name: string) => (e: React.ChangeEvent<{}>, checked: boolean) => {
        if (value === name) {
            setValue('');
        } else
            setValue(name);
    }


    const handleDismissPermanently = () => {

    }

    const handleLater = () => {

    }

    const handleSubmit = async () => {
        await SurveyService.submitSurvey({
            value
        });
        doNotShowAgain();
    }


    return (
        <SimpleDialog open={open} headerText={getTranslation(SURVEY_TITLE_TEXT) || "SURVEY"}>
            <Typography variant="body2" className="my-3"><b>
                {getTranslation(SURVEY_RESPONSE_TEXT) || "Your response to this anonymised survey will only be used for statistical purposes"}
            </b>
            </Typography>
            <Typography variant="body2">
                <b>
                    {getTranslation(SURVEY_USING_THE_LTD_IN_MY) || "I am using the LTD in my capacity as a"}
                </b>
            </Typography>
            <div className={classNames("flex-column", classes.checkboxOptionsContainer)}>
                {CHECKBOX_OPTIONS.map(option => (
                    <FormControlLabel
                        className={classes.label}
                        key={option.name}
                        checked={value === option.name}
                        onChange={handleChange(option.name)}
                        control={<Checkbox className={classes.checkbox} color="primary" />}
                        label={option.translationKey ? getTranslation(option.translationKey) : option.label}
                    />
                ))}
            </div>
            <Typography gutterBottom variant="caption" className="my-4">
                {getTranslation(SURVEY_SUGGESTION_ANY_IMPROVEMENTS) || "If you want to suggest any improvements or corrections, kindly click on the Report Issue button on the top menu."}

            </Typography>

            <div className="flex-row align-center">
                <Button color="primary" onClick={doNotShowAgain}>{getTranslation(SURVEY_DO_NOT_SHOW_AGAIN) || 'DO NOT SHOW AGAIN'}</Button>
                <div style={{ flex: 1 }} />
                <Button className="mx-1" color="primary" onClick={showLater}>{getTranslation(SURVEY_LATER) || "LATER"}</Button>
                <Button disabled={!value} variant="contained" onClick={handleSubmit} color="primary">{getTranslation(SURVEY_SUBMIT) || "SUBMIT"}</Button>
            </div>
        </SimpleDialog>
    )
}

const STYLES = (theme: Theme) => createStyles({
    checkbox: {
        padding: 4
    },
    label: {
        marginLeft: -10
    },
    checkboxOptionsContainer: {
        marginLeft: 3
    }
});

export default withStyles(STYLES)(withTranslations(LtdSurveyDialog));