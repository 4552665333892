import React, { Component, Fragment } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Button, Typography } from '@material-ui/core'
import { TOtherMeta, SectionLabel } from '.';
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { TEditSection } from '../../Features/AddNewLegalFinding/@types';



interface IProps extends WithStyles<typeof STYLES> {
    meta: TOtherMeta
    legalFinding: TCLDDocument | undefined
    onEdit: (index?: number) => void
    onDelete: (index: number, key: 'Keyword' | 'Appeal' | 'Opinions' | 'Related Decision') => void
}

class OtherMeta extends Component<IProps> {

    state = {
        data: []
    }

    componentDidMount() {
        this.setFormValues();
    }

    componentDidUpdate(prevProps) {
        const { meta, legalFinding } = this.props;
        if (prevProps.legalFinding !== legalFinding) {
            this.setFormValues();
        }
    }


    setFormValues = () => {
        const { meta, legalFinding } = this.props;
        if (legalFinding) {
            switch (meta.id) {
                case 'appeal': {
                    const data = legalFinding.Appeal || [];
                    this.setState({ data });
                    break;
                }
                case 'opinions': {
                    const data = legalFinding.Opinions || [];
                    this.setState({ data })
                    break;
                }
                case 'decisions': {
                    const data = legalFinding['Related Decision'] || []
                    this.setState({ data })
                    break;
                }
            }
        }
    }

    onDelete = (index: number) => {
        switch (this.props.meta.id) {

            case 'appeal': {
                this.props.onDelete(index, 'Appeal');
                return;
            }
            case 'opinions': {
                this.props.onDelete(index, 'Opinions');
                return;
            }
            case 'decisions': {
                this.props.onDelete(index, 'Related Decision');
                return;
            }
        }
    }

    render() {
        const { classes, meta } = this.props;
        const { data } = this.state;


        return (
            <div id={meta.id} className={classes.root} style={{ paddingBottom: meta.id === 'opinions' ? `calc(100vh - 140px)` : 60 }}>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <SectionLabel title={meta.title} />
                        <Button onClick={() => this.props.onEdit()} variant="outlined" color="primary">ADD NEW</Button>
                    </div>
                    {
                        !_.isEmpty(data) &&
                        <Fragment>
                            <div className={classes.labelContainer}>
                                {
                                    meta.fields.map((fields, i) => (
                                        <Typography key={i} variant="caption" style={{ flex: fields.flex }}>{fields.label}</Typography>
                                    ))
                                }
                            </div>
                            <div className={classes.fieldsContainer}>
                                {
                                    data.map((_data, i) => (
                                        <div className="flex-row align-center" key={i}>
                                            <Typography variant="caption" style={{ flex: meta.fields[0].flex }}>{_data[meta.fields[0].key]}</Typography>
                                            <Typography variant="caption" style={{ flex: meta.fields[1].flex }}>{_data[meta.fields[1].key]}</Typography>
                                            <Typography variant="caption" style={{ flex: meta.fields[2].flex }}>{_data[meta.fields[2].key]}</Typography>
                                            <Typography variant="caption" style={{ flex: meta.fields[3].flex }}>{_data[meta.fields[3].key]}</Typography>
                                            <div className="flex-row justify-end" style={{ flex: meta.fields[4].flex }}>
                                                <Button onClick={() => this.props.onEdit(i)} className={classes.button}>
                                                    <i className="material-icons">edit</i>
                                                </Button>
                                                <Button className={classes.button} onClick={e => this.onDelete(i)}>
                                                    <i className="material-icons">delete_outline</i>
                                                </Button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Fragment>
                    }

                </div>

            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        padding: '40px 0px 60px 0px',
        borderBottom: `1px dashed ${fade('#000', 0.2)}`
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 680,
        margin: '0 auto'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    labelContainer: {
        display: 'flex',
        width: '100%',
        padding: '12px 15px',
        boxSizing: 'border-box',

    },
    fieldsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > .flex-row': {
            background: 'white',
            padding: '10px 15px',
            borderRadius: 4,
            margin: '5px 0px',
        }
    },
    button: {
        width: 30,
        height: 30,
        minHeight: 30,
        minWidth: 30,
        borderRadius: 25,
        marginLeft: 8,
        padding: 0,
        border: `1px solid ${THEME.Colors.Third}`,
        '& i': {
            // fontSize: 14,
            color: THEME.Colors.Third
        }
    },
})

export default withStyles(STYLES)(OtherMeta)