import { PRIVACY_POLICY_FR } from '../../../Resources/constants';

const termUseFr = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="/" >Home&nbsp;»&nbsp;</a><a href="footer/terms-of-use/" >Terms of use</a></p></div><div id="getUrlText"><div "uid","uid","''"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" "uid","uid","''"><i class="glyphicon glyphicon-print"></i></a></div><br "uid","uid","''">
<!--  CONTENT ELEMENT, uid:51/text [begin] -->
    <div id="c51" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Conditions d’utilisation</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext">
        En utilisant ce site web, vous en acceptez les conditions générales suivantes :         <br> 
</p>
<p class="bodytext"></p><blockquote>
a) La Cour pénale internationale gère ce site Web (« le site ») par courtoisie envers celles et ceux qui choisissent de le consulter (« les utilisateurs »). Le contenu du site n'est fourni qu’à titre informatif. La Cour pénale internationale autorise les utilisateurs à consulter le site et à télécharger et copier les informations, documents, métadonnées et pièces (ensemble, « le contenu ») depuis le site pour leur utilisation personnelle non commerciale, sans aucun droit de les revendre ou de les redistribuer, ni de compiler ou de créer des œuvres dérivées de ceux-ci, sous réserve des conditions générales énoncées ci-dessous, ainsi que des restrictions plus spécifiques pouvant s’appliquer à une partie ciblée du site. La Cour décline toute responsabilité quant à l’exactitude et à l’exhaustivité du contenu de ce site.
<p class="bodytext">
b) Les utilisateurs peuvent faire usage du contenu dans le cadre de l’enseignement, de travaux universitaires et de publications, sous réserve d’indiquer la PURL des documents de <a href="https://www.legal-tools.org" target="_blank">la base de données des Outils juridiques de la CPI</a><a id="_anchor_1" href="typo3/#_msocom_1" name="_msoanchor_1"><u></u></a> ou, s'il s'agit d'un grand nombre de documents, d’indiquer l’URL <a href="https://www.legal-tools.org" target="_blank">https://www.legal-tools.org</a>. S’agissant des conclusions juridiques dans <a href="https://www.legal-tools.org/cld">la base de données jurisprudentielles de la CPI</a>, les utilisateurs doivent en indiquer la PURL ou, dans le cas d’un grand nombre de documents, l’URL <a href="https://www.legal-tools.org/cld" target="_blank">https://www.legal-tools.org/cld</a>.</p>
<p class="bodytext">
(c)  Le Centre for International Law Research and Policy (CILRAP) gère le présent site pour la Cour pénale internationale. 
L’ensemble du contenu de ce site de la Cour pénale internationale 
est publié sous réserve des présentes conditions générales.
</p>
<p class="bodytext">
(d)
Sauf indication contraire expresse, les conclusions, interprétations et constatations formulées dans le contenu proposé par le présent site sont celles des divers fonctionnaires, consultants et conseillers de la Cour pénale internationale qui ont préparé ces travaux et ne représentent pas nécessairement les vues de la Cour pénale internationale ou de ses États parties. 

<br><br></p></blockquote>
<p></p>
<h3>Généralités</h3>
<p class="bodytext">
La Cour pénale internationale se réserve le droit exclusif, à son entière discrétion, de modifier, de restreindre ou de suspendre le site ou tout document à quelque égard que ce soit. Ce faisant, elle n’est pas tenue de prendre en considération les besoins des utilisateurs. 
</p>
<p class="bodytext">
La Cour pénale internationale se réserve le droit de refuser à tout utilisateur l’accès au présent site ou à toute partie de celui-ci, à son entière discrétion.
</p>
<p class="bodytext">
Aucune renonciation par la Cour pénale internationale à une quelconque disposition des présentes conditions générales ne sera contraignante, sauf si elle est formulée par écrit et signée par son représentant dûment autorisé.
  </p>
<h3>Avis de droits d’auteur </h3>
<p  class="bodytext">
Droits d’auteurs © Cour pénale internationale 2003-2022. Tous droits réservés. Aucun des documents fournis sur ce site Web ne peut être utilisé, reproduit ou transmis, en totalité ou en partie, sous quelque forme et par quelque moyen que ce soit, électronique ou mécanique, notamment la photocopie, l’enregistrement ou l’utilisation de tout système de stockage et d’extraction de données, sans l’autorisation écrite de la Cour, sauf si les conditions d’utilisation des sites Web de la Cour pénale internationale le prévoient. Pour demander cette autorisation et pour toute autre question, veuillez contacter l’administrateur du contenu Internet à la Cour pénale internationale, Section de l’information et de la documentation, boîte postale 19519, 2500 CM La Haye, Pays Bas, ou à <a href="mailto:websupport@icc-cpi.int">websupport@icc-cpi.int</a></p>
<p "uid","uid","''" class="bodytext">
Il se peut que certains documents de la base de données des Outils juridiques aient été produits par des organisations ou des personnes externes à la Cour et qu'ils soient protégés par des droits d’auteur. Les partenaires externes du projet sont soucieux des droits de propriété intellectuelle associés à de tels documents et ne mettront ceux-ci à la disposition du public qu’après avoir obtenu l’autorisation des détenteurs des droits. C’est pourquoi certains documents de la base de données des Outils juridiques sont répertoriés sans lien actif, de façon à ce que le texte ne soit pas disponible. Le Projet invite les détenteurs des droits d’auteur à partager leurs documents par le biais de cette plateforme publique universelle.Au cours des prochaines années, il s'efforcera d'obtenir progressivement l'autorisation de mettre ces documents à la disposition du grand public.   
   </p>
<p "uid","uid","''" class="bodytext">
Toutes les mesures raisonnables ont été prises pour identifier les détenteurs des droits d'auteurs concernés et obtenir leur autorisation avant de rendre public les documents contenus dans
 <i> </i>a base de données <i>.
 </i> 
 des Outils juridiques. Si vous êtes détenteur de droits d’auteur ou êtes autorisé à agir au nom de l'auteur, et que vous pensez que les Outils juridiques ont violé vos droits d’auteur ou d’autres droits de propriété,
   <i>,</i>&nbsp;
   veuillez nous contacter en nous communiquant les informations suivantes :    
   </p><ul type="disc"><li "uid","uid","''"><ins></ins>

   l'œuvre dont vous estimez que le droit d'auteur a été violé ; 
    </li><li "uid","uid","''">
    une description exacte de l'endroit où se trouve le contenu concerné dans les Outils juridiques ;
     </li><li "uid","uid","''">
     vos coordonnées (adresse postale, numéro de téléphone et adresse électronique) ;  
     </li><li "uid","uid","''">
     une déclaration de bonne foi selon laquelle 
    </li><li "uid","uid","''">
    une déclaration de bonne foi selon laquelle l’utilisation contestée du contenu n’a pas été autorisée par le détenteur des droits d’auteur, ses représentants ou la loi ;
      </li><li "uid","uid","''">
      une déclaration indiquant que les informations que vous donnez sont exactes et que vous êtes le détenteur des droits d’auteur concernés ou êtes autorisé à agir au nom de l'auteur ; et   votre signature physique ou électronique.
      </li></ul><p class="bodytext">
      Les informations susmentionnées doivent être envoyées par courrier électronique à
       <a href="mailto:info@cilrap.org">info@cilrap.org</a>,
       ou par la poste, accompagnées de la mention « Outils juridiques : revendication de droits d’auteur », à l’adresse suivante : Cour pénale internationale, Bureau du Procureur,
     Section des avis juridiques, boîte postale 19519, 2500 CM, La Haye, Pays-Bas.
</p>
<h3>Clauses de non-responsabilité</h3>
<p class="bodytext">
Le contenu du présent site est fourni « en l’état », sans garantie d'aucune sorte, expresse ou implicite, y compris, mais sans s’y limiter, les garanties de qualité marchande, d’adéquation à un usage particulier et de non-violation. La Cour pénale internationale ne donne expressément aucune garantie et ne prend aucun engagement quant à l’exactitude ou à l’exhaustivité de ce contenu. Elle modifie, améliore et actualise périodiquement le contenu du présent site sans préavis. Elle ne peut en aucun cas être tenue responsable des pertes, dommages, responsabilités ou dépenses encourus ou subis dont on prétend qu’ils résultent de l’utilisation du site, y compris, mais sans s'y limiter, de toute faute, erreur, omission, interruption ou tout retard y afférents. L’utilisateur assume seul les risques liés à l’usage qu’il fait du présent site. En aucune circonstance, y compris, sans s’y limiter, en cas de négligence, la Cour pénale internationale ou les entités qui lui sont associées ne peuvent être tenues responsables de dommages directs, indirects, accessoires, spéciaux ou consécutifs, même si la Cour a été informée de la possibilité de tels dommages. 
</p>
<p class="bodytext">
L’utilisateur reconnaît et convient en particulier que la Cour pénale internationale n’est pas responsable du comportement des utilisateurs. 
</p>
<p class="bodytext">
Le présent Site peut contenir des avis, des opinions et des déclarations provenant de différentes sources. La Cour pénale internationale ne revendique ni ne garantit l’exactitude ou la fiabilité de tout avis, opinion, déclaration ou de toute autre information fournie par toute source d’information, tout utilisateur de ce Site ou toute autre personne ou entité. L’Utilisateur s’appuie sur tout avis, opinion, déclaration ou toute autre information fournie à ses propres risques. Ni la Cour pénale internationale ni ses affiliés ou l’un quelconque de leurs agents, employés, fournisseurs d’informations ou fournisseurs de contenu respectifs ne seront responsables envers un Utilisateur ou quiconque en cas d’inexactitudes, erreurs, omissions, interruptions, suppressions, défauts, modification ou utilisation de tout contenu ou pour son actualité ou son exhaustivité, et ils ne seront pas non plus responsables en cas de défaut d’exécution, de virus informatique ou de panne de communication, indépendamment de leur cause, ni des dommages qui en découlent. 
</p>

<p class="bodytext">
En utilisant le présent site, l’utilisateur accepte d’indemniser la Cour pénale internationale et les entités qui lui sont associées de toute action en justice, réclamation, perte, dommage, responsabilité et dépense (y compris les honoraires d’avocat raisonnables) découlant de l’utilisation qu'il aura faite du présent site, y compris, mais sans s’y limiter, toute réclamation alléguant des faits qui, s’ils étaient avérés, constitueraient une violation, par l’utilisateur, des présentes conditions générales. Si l'utilisateur n'est pas satisfait d'un élément du site ou de l'une de ses conditions d'utilisation,
l'unique solution qui s'offre à lui est de cesser d'utiliser le site. 
</p>
<p class="bodytext">
Le présent site peut contenir des liens et des références à des sites Web produits par des tiers. Les sites dont le lien est indiqué ne sont pas sous le contrôle de la Cour pénale internationale, et celle-ci n’est pas responsable du contenu de tout site dont le lien est indiqué ou de tout lien figurant sur un site dont le lien est indiqué. La Cour pénale internationale ne fournit ces liens que pour des raisons de commodité, et l'inclusion d'un lien ou d'une référence ne signifie pas que la Cour pénale internationale approuve le site en question.  
</p>
<p class="bodytext">
Si le présent site contient des tableaux d’affichage, des espaces de discussion, des possibilités d’accès à des listes de diffusion ou à d’autres outils de messagerie ou de communication (collectivement, « les forums »), l'utilisateur consent à ne recevoir et n’envoyer que des messages et des documents qui soient appropriés et en rapport avec le forum en question. À titre d’exemples non limitatifs, l’utilisateur s’engage à s’abstenir, lorsqu’il participe à un forum, de se livrer aux activités suivantes : 
   </p>
<p class="bodytext"></p><blockquote>
(a) Diffamer quiconque, l’insulter, le harceler, le traquer, le menacer ou porter atteinte de quelque autre façon à ses droits (par exemple en matière de vie privée et de diffusion d’information) ;
 <br><br> 
(b) 
Publier, poster, distribuer ou diffuser des documents ou des informations qui soient diffamatoires, de nature à offusquer, obscènes, inconvenants ou illicites ;
 <br><br> 
 (c)
 Téléverser ou joindre des fichiers contenant des logiciels ou d'autres éléments protégés par les lois sur la propriété intellectuelle (ou par la législation relative à la vie privée et à la diffusion d’information), à moins que l’utilisateur soit détenteur des droits y relatifs ou les contrôle, ou qu’il ait reçu à cette fin toutes les autorisations requises par la loi ;

    <br><br>
    (d)
    Téléverser ou joindre des fichiers contenant des virus, des fichiers corrompus ou tout autre logiciel ou programme analogue susceptible de nuire au bon fonctionnement de l’ordinateur d’autrui ;
 <br><br> 
 (e)
 Supprimer dans un fichier téléversé les mentions relatives aux auteurs, les avis juridiques ou les mentions ou signes relatifs au droit de la propriété intellectuelle ;
    <br><br> (f) 
    Falsifier l’origine ou la source d’un logiciel ou d’autres éléments contenus dans un fichier exporté ;     <br><br> 
     (g)
     Faire de la publicité pour des biens ou services, ou les offrir à la vente, ou effectuer ou transmettre des enquêtes, des concours ou des chaînes de lettres, ou importer un fichier mis à disposition par un autre utilisateur du Forum, dont l’Utilisateur sait ou devrait raisonnablement savoir que la distribution par ce moyen est illégale.
     .</blockquote>
<p></p>
<p class="bodytext">
L’utilisateur déclare comprendre que les échanges effectués sur tous les forums et dans tous les groupes de discussion sont publics et n’ont aucun caractère privé. En outre, il donne acte que les discussions, les publications, les conférences, les courriels et les autres communications émanant d'autres utilisateurs ne sont pas cautionnés par la Cour pénale internationale, et que ces communications ne sont pas réputées avoir été examinées, sélectionnées ou approuvées par la Cour pénale internationale. Celle-ci se réserve le droit de supprimer, pour quelque raison que ce soit et sans préavis, tout contenu des forums émanant des utilisateurs, y compris, mais sans s'y limiter, les courriels ou les messages des tableaux d’affichage électronique.
   <br><br>
</p>
<h3>Préservation des immunités </h3>
<p class="bodytext">
Rien de ce qui figure dans les présentes ne constitue ni ne peut être considéré comme une limitation des privilèges et immunités de la Cour pénale internationale, ni comme une renonciation à ces privilèges et immunités, que la Cour se réserve expressément.
 <br><br>
</p>
<h3>Politique de confidentialité</h3>
<p class="bodytext">${PRIVACY_POLICY_FR}</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:51/text [end] -->
    </div>`;

export default termUseFr;
