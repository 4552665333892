import utilities from "../Resources/Utils";


export class ShortUrlService {
    static generate = (query: string) => utilities.request<TGenerateShortURLResponse>({
        url: `shortUrls/generate`,
        method: 'POST',
        params: { expanded: query }
    })

    static find = (shortQuery: string) => utilities.request({
        url: `shortUrls/findOne`,
        params: {
            filter: {
                where: { short: shortQuery }
            }
        }
    })
}

export interface TGenerateShortURLResponse {
    expanded: string;
    short: string;
    id: string;
    created: Date;
    updated: Date;
}