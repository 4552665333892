const usePurlZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="purl/" >PURL&nbsp;»&nbsp;</a><a href="purl/how-to-use-the-purls-in-the-icc-legal-tools-database/" >How to use the PURLs in the ICC Legal Tools Database?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:248/text [begin] -->
    <div id="c248" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        如何使用国际刑事法院法律工具数据库中的永久链接
        </h1></div>
    <!--  Header: [end] -->			
    <!--  Text: [begin] -->
        <p class="bodytext">
        法律工具数据库（‘数据库’）包含162000多个免费的国际刑法领域的法律文件。这其中包括国际刑事法院所有公开文件（决定、申请、庭审记录）和其他国际法庭、人权机构和国内法院的决定和文件，国际刑事法院相关法律的所有筹备文件，国内立法和国际刑法领域的学术出版物。
</p>
<p class="bodytext">
数据库中的每个文件都有一个永久的网址（‘永久链接’，也叫‘PURLs’），该网址不会改变。当用户将永久链接用于文本中的超链接，读者可以直接使用被引用的文件。创建超链接简单易行，只需要不到30秒钟。这样做的公共效益在于读者可以使用文章本身以外的更多的法律资源。这样的做法有助于国际刑法的传播。
</p>
<p class="bodytext"><i><b>
如何找到相关文件的永久链接？
</b></i>
</p>
<p class="bodytext">
用户可以通过使用左上角菜单上的搜索引擎或者浏览左下角数据库菜单的树形文件夹结构找到目标文件。无论你以何种方式使用数据库，你可以通过点击文件信息中的“复制永久链接”键复制目标文件链接。你也可以点击目标文件，页面会跳转到该文件的元数据页，该页包括文件名称、创建日期、来源、法庭构成等信息。元数据页面的第一行提供该文件的永久链接。链接以斜杠结尾
      (如, <a href="https://www.legal-tools.org/doc/ea422e/" target="_blank">http://www.legal-tools.org/doc/ea422e/</a>).
  复制该永久链接并粘贴至需要的地方。只有这两种方式找到的链接是永久链接。浏览器显示的元数据页面网址或者点击“下载”键后浏览器地址栏显示的网址不是永久链接。 
</p>
<p class="bodytext"><i><b> 如何移除超链接？</b></i>
</p>
<p class="bodytext">
用户可通过以下三个步骤将永久链接用于文本超链接：:<br>1.
 用鼠标选中文本中需要创建超链接的文字
 <br>2. 同时按下“Control”和“K”键，你会看到一个新的小窗弹出。
 <br>3.	光标停在在小窗底部的地址栏，同时按“Control”和“V”键，粘贴永久链接。然后点击“Ok”键。
</p>
<p class="bodytext"><i><b>
    如何移除超链接？
</b></i>
</p>
<p class="bodytext">
    移除超链接时，选择需要移除超链接的文本，同时按下“Control”和“K”键，小窗再次弹出。小窗底部的地址栏有超链接的网址，点击右方的“移除链接”键即可移除超链接。
</p>
<p class="bodytext"><i><b>
    在法律工具数据库找不到想要的文件该怎么办？
</b></i>
</p>
<p class="bodytext">
如果找不到特定文件，请发送电子邮件至
 <a href="mailto:bais@cilrap.org">bais@cilrap.org</a>&nbsp;与德瓦希什•巴伊斯
 （Devasheesh Bais）先生（法律工具项目助理协调员）取得联系，并抄送 <a href="mailto:info@cilrap.org">info@cilrap.org</a>).</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:248/text [end] -->
    </div>`;

export default usePurlZh;
