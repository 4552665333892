const legalToolAdvisoryRu = `<div id="content" class="content">
<div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/ltac/" >LTAC</a></p></div><div id="getUrlText">
<div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree">
<i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;">
<i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:256/text [begin] -->
    <div id="c256" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">Консультативный комитет Юридических инструментов</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext">
          Был создан Консультативный комитет Юридических Инструментов (LTAC). В него вошли представители всех судебных органов МУС с целью оценки потребностей соответствующих пользователей в Суде, а также других участников судопроизвдства, таких как защита и адвокаты потерпевших. Комитет разработал Руководство по метаданным и категории ключевых слов, на основе которых работает база данных Юридических инструментов МУС.
        </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:256/text [end] -->
    </div>`;
export default legalToolAdvisoryRu;
