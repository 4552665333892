import React, { FC, useContext, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Button, Menu, MenuItem } from '@material-ui/core';
import ReportError, { TReportErrorForm } from '../ReportIssue/ReportError';
import SuggestCorrection, { TSuggestCorrectionForm } from '../ReportIssue/SuggestCorrection';
import SuggestDocAddition, { TSuggestDocAdditionForm } from '../ReportIssue/SuggestDocAddition';
import { TState } from '../../RootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TIssueSuggestionType } from '../../Services/LtdDocs.types';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';
import { SuggestionsService } from '../../Services/Suggestions.service';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    MENU_LIST_REPORT,
    MENU_LIST_REPORT_ERROR,
    MENU_LIST_SUGGESTION_CORRETION,
    MENU_LIST_SUGGEST_ADDITION,
} from '../../Features/Translations/translationKeys.ltd';
import { ThemeContext } from '../../Contexts/ThemeContext';

export interface LtdReportIssueProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {}

interface IStateProps {
    includeIdOptions?: Record<string, any>;
}

interface IDispatchProps {
    showToast: (msg: string, variant: TToastVariant) => void;
}

const LtdReportIssue: FC<LtdReportIssueProps & WithTranslationProps> = props => {
    const { classes, includeIdOptions = {}, getTranslation } = props;
    const { theme } = useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [dialogOpen, setDialogOpen] = useState<Record<TIssueSuggestionType, boolean>>({
        errorReport: false,
        docAddition: false,
        correction: false,
    });

    const openMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(e.target as HTMLElement);
    };

    const closeMenu = () => setAnchorEl(null);

    const handleDialogOpen = (dialog: keyof typeof dialogOpen) => () => {
        closeMenu();
        setDialogOpen(prev => ({ ...prev, [dialog]: true }));
    };

    const handleDialogClose = (dialog: keyof typeof dialogOpen) => () => {
        setDialogOpen(prev => ({ ...prev, [dialog]: false }));
    };

    const showSuccessToast = () =>
        props.showToast('Thank you. Your suggestion has been submitted successfully.', 'success');

    const handleReportErrorSubmit = async (form: Partial<TReportErrorForm>) => {
        try {
            await SuggestionsService.postSuggestion(form, 'errorReport');
            handleDialogClose('errorReport')();
            showSuccessToast();
        } catch (error) {}
    };

    const handleSuggestCorrectionSubmit = async (form: Partial<TSuggestCorrectionForm>) => {
        try {
            await SuggestionsService.postSuggestion(form, 'correction');
            handleDialogClose('correction')();
            showSuccessToast();
        } catch (error) {}
    };
    const handleSuggestDocAdditionSubmit = async (form: Partial<TSuggestDocAdditionForm>) => {
        const { userName, userEmail, userComment, ...doc } = form;
        try {
            await SuggestionsService.postSuggestion(
                { name: userName, email: userEmail, comment: userComment, doc },
                'docAddition'
            );
            handleDialogClose('docAddition')();
            showSuccessToast();
        } catch (error) {}
    };

    const MENU_ITEMS = [
        {
            label: getTranslation(MENU_LIST_REPORT_ERROR) || 'Report an error or suggest an improvement.',
            onClick: handleDialogOpen('errorReport'),
        },
        {
            label: getTranslation(MENU_LIST_SUGGEST_ADDITION) || 'Suggest addition of a document to the LTD.',
            onClick: handleDialogOpen('docAddition'),
        },
        {
            label:
                getTranslation(MENU_LIST_SUGGESTION_CORRETION) ||
                'Suggest correction to the metadata of an existing LTD record.',
            onClick: handleDialogOpen('correction'),
        },
    ];

    return (
        <div className={classes.root}>
            <Button
                aria-owns={anchorEl ? 'feedback-report-menu' : undefined}
                aria-haspopup='true'
                onClick={openMenu}
                variant={window.innerWidth <= theme.breakpoints.values.sm ? 'outlined' : 'text'}
                className={classes.button}
            >
                {/* REPORT ISSUE */}
                {getTranslation(MENU_LIST_REPORT)}
            </Button>
            <Menu elevation={1} id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {MENU_ITEMS.map((item, i) => (
                    <MenuItem className={classes.menuItem} key={`menu-item-${i}`} onClick={item.onClick}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>

            <ReportError
                open={dialogOpen.errorReport}
                handleClose={handleDialogClose('errorReport')}
                handleSubmitForm={handleReportErrorSubmit}
            />
            <SuggestCorrection
                open={dialogOpen.correction}
                handleClose={handleDialogClose('correction')}
                handleSubmitForm={handleSuggestCorrectionSubmit}
            />
            <SuggestDocAddition
                includeIdOptions={includeIdOptions}
                open={dialogOpen.docAddition}
                handleClose={handleDialogClose('docAddition')}
                handleSubmitForm={handleSuggestDocAdditionSubmit}
            />
        </div>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            marginRight: 8,
        },
        menuItem: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
            },
        },
        button: {
            lineHeight: 1.2,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 24,
            },
            [theme.breakpoints.up('sm')]: {
                // color: Theme.Colors.Grayscale.White,
                color: theme.palette.common.white,
            },
        },
    });

const mapStateToProps = (state: TState) => ({
    includeIdOptions: _.get(state.App, 'ltdIncludeOptions'),
});

const mapDispatchToProps = dispatch => ({
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(withTranslations(LtdReportIssue))));
