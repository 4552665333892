import {
    PRIVACY_POLICY_EN,
    TUTORIAL_FILM_URLS,
    DUMMY_TEXT,
} from '../../../Resources/constants';

const termUseEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="/" >Home&nbsp;»&nbsp;</a><a href="footer/terms-of-use/" >Terms of use</a></p></div><div id="getUrlText"><div "uid","uid","''"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" "uid","uid","''"><i class="glyphicon glyphicon-print"></i></a></div><br "uid","uid","''">
<!--  CONTENT ELEMENT, uid:51/text [begin] -->
    <div id="c51" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Terms of use</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext">The use of this web site constitutes agreement with the following terms and conditions:<br> 
</p>
<p class="bodytext"></p><blockquote>(a) The International Criminal Court maintains this web site (the "Site") as a courtesy to those who may choose to access the Site ("Users"). The information presented herein is for informative purposes only. The International Criminal Court grants permission to Users to visit the Site and to download and copy the information, documents, metadata and materials (collectively, "Materials") from the Site for the User's, non-commercial use, without any right to resell or redistribute them or to compile or create derivative works therefrom, subject to the terms and conditions outlined below, and also subject to more specific restrictions that may apply to specific Material within this Site. The Court does not accept any responsibility or
liability for the correctness or completeness of the Materials on this Site.
<p></p>
<p class="bodytext">(b) Users may use Materials in education, in academic writing, and in publication provided the PURL in the <a href="/">ICC Legal Tools Database</a><a id="_anchor_1" href="typo3/#_msocom_1" name="_msoanchor_1"><u></u></a> of documents used is indicated, or, if it concerns a larger number of documents, the URL <a href="https://www.legal-tools.org" target="_blank">https://www.legal-tools.org</a> is indicated. For legal findings in the <a href="https://www.legal-tools.org/cld">ICC Case Law Database</a>, the PURL of the legal findings used shall be indicated, or, if it concerns a larger number of legal findings, the URL <a href="https://www.legal-tools.org/cld" target="_blank">https://www.legal-tools.org/cld</a> shall be indicated.
</p>
<p class="bodytext">(c) The Centre for International Law Research and Policy (CILRAP) administers this Site for the International Criminal Court. All Material on this Site from the International Criminal Court appears subject to the present terms and conditions.
</p>
<p class="bodytext"> (d) Unless expressly stated otherwise, the findings, interpretations and conclusions expressed in the Materials on this Site are those of the various International Criminal Court staff members, consultants and advisers to the International Criminal Court who prepared the work and do not necessarily represent the views of the International Criminal Court or its States Parties.<br><br></p></blockquote>
<p></p>
<h3>General</h3>
<p class="bodytext">The International Criminal Court reserves its exclusive right in its sole discretion to alter, limit or discontinue the Site or any Materials in any respect. The International Criminal Court shall have no obligation to take the needs of any User into consideration in connection therewith. 
</p>
<p class="bodytext">The International Criminal Court reserves the right to deny in its sole discretion any user access to this Site or any portion thereof without notice.
</p>
<p class="bodytext">No waiver by the International Criminal Court of any provision of these terms and conditions shall be binding except as set forth in writing and signed by its duly authorized representative.</p>
<h3>Copyright notice </h3>
<p  class="bodytext">Copyright © International Criminal Court 2003-2022. All rights reserved. None of the materials provided on this web site may be used, reproduced or transmitted, in whole or in part, in any form or by any means, electronic or mechanical, including photocopying, recording or the use of any information storage and retrieval system, except as provided for in the terms and conditions of use of International Criminal Court web sites, without permission in writing from the Court. To request such permission and for further enquiries, contact the web content manager at International Criminal Court, Public Information and Documentation Section, P.O. Box 19519, 2500 CM The Hague, The Netherlands, or at <a href="mailto:websupport@icc-cpi.int">websupport@icc-cpi.int</a></p>
<p "uid","uid","''" class="bodytext">Documents in the Legal Tools may have been produced by external organisations or individuals and may therefore be copyrighted. The&nbsp;external Project&nbsp;partners are mindful of the rights of copyright owners and will only make such documents available to the public when they have secured authorisation from the relevant copyright owners. For this reason, some documents in the ICC Legal Tools Database may be listed without an activated link so that the text is not available. The Project invites copyright holders to share their documents through this universal public platform. The Project will make systematic efforts during the upcoming years to gradually secure permission to make these documents available to the general public.</p>
<p "uid","uid","''" class="bodytext">All reasonable measures have been taken to identify and secure authorisation from relevant copyright holders before publicly releasing the materials contained in the<i> </i>ICC Legal Tools Database<i>.</i> If you are the owner of a copyright interest, or are authorized to act on behalf of a copyright owner, and you believe that&nbsp;your copyright or other proprietary rights have been violated by material contained in the Legal Tools<i>,</i>&nbsp;please&nbsp;contact us with&nbsp;the following information: </p><ul type="disc"><li "uid","uid","''"><ins></ins>identification of the work the copyright of which you claim has been infringed;</li><li "uid","uid","''">an exact description of where the material about which you complain is located within Legal Tools; </li><li "uid","uid","''">your contact details (address, telephone number, and e-mail address); </li><li "uid","uid","''">a statement&nbsp;declaring that you have a good-faith belief that the disputed use has not been authorized by the copyright owner, its agent, or the law; </li><li "uid","uid","''">a statement&nbsp;declaring that the above information in your notice is accurate and that you are the owner of the copyright interest involved or are authorized to act on behalf of that owner; and </li><li "uid","uid","''">your electronic or physical signature. </li></ul><p class="bodytext"> The above information should be sent in&nbsp;an e-mail message to&nbsp;<a href="mailto:info@cilrap.org">info@cilrap.org</a>, or by&nbsp;postal mail marked "Legal Tools copyright claim" and addressed to International Criminal Court, Office of the Prosecutor, Legal Advisory Section, P.O. Box 19519, 2500 CM The Hague, the Netherlands.
</p>
<h3>Disclaimers</h3>
<p class="bodytext">Materials provided on this Site are provided "as is", without warranty of any kind, either express or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose and non-infringement. The International Criminal Court specifically does not make any warranties or representations as to the accuracy or completeness of any such Materials. The International Criminal Court periodically adds changes, improves or updates the Materials on this Site without notice. Under no circumstances shall the International Criminal Court be liable for any loss, damage, liability or expense incurred or suffered that is claimed to have resulted from the use of this Site, including, without limitation, any fault, error, omission, interruption or delay with respect thereto. The use of this Site is at the User's sole risk. Under no circumstances, including but not limited to negligence, shall the International Criminal Court or its affiliates be liable for any direct, indirect, incidental, special or consequential damages, even if the International Criminal Court has been advised of the possibility of such damages. 
</p>
<p class="bodytext">The User specifically acknowledges and agrees that the International Criminal Court is not liable for any conduct of any User. 
</p>
<p class="bodytext">This site may contain advice, opinions and statements of various information providers. The International Criminal Court does not represent or endorse the accuracy or reliability of any advice, opinion, statement or other information provided by any information provider, any User of this Site or any other person or entity. Reliance upon any such advice, opinion, statement, or other information shall also be at the User's own risk. Neither the International Criminal Court nor its affiliates, nor any of their respective agents, employees, information providers or content providers, shall be liable to any User or anyone else for any inaccuracy, error, omission, interruption, deletion, defect, alteration of or use of any content herein, or for its timeliness or completeness, nor shall they be liable for any failure of performance, computer virus or communication line failure, regardless of cause, or for any damages resulting therefrom.
</p>

<p class="bodytext">As a condition of use of this Site, the User agrees to indemnify the International Criminal Court and its affiliates from and against any and all actions, claims, losses, damages, liabilities and expenses (including reasonable attorneys' fees) arising out of the User's use of this Site, including, without limitation, any claims alleging facts that if true would constitute a breach by the User of these terms and conditions. If the User is dissatisfied with any Material on this Site or with any of its terms and conditions of use, the User's sole and exclusive remedy is to discontinue using the Site. 
</p>
<p class="bodytext">This Site may contain links and references to third-party web sites. The linked sites are not under the control of the International Criminal Court, and the International Criminal Court is not responsible for the content of any linked site or any link contained in a linked site. The International Criminal Court provides these links only as a convenience, and the inclusion of a link or reference does not imply the endorsement of the linked site by the International Criminal Court. 
</p>
<p class="bodytext">If this Site contains bulletin boards, chat rooms, access to mailing lists or other message or communication facilities (collectively, "Forums"), the User agrees to use the Forums only to send and receive messages and materials that are proper and related to the particular Forum. By way of example and not as a limitation, the User agrees that when using a Forum, he or she shall not do any of the following: </p>
<p class="bodytext"></p><blockquote>(a) Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others;<br><br> (b) Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information; <br><br> (c) Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the User owns or controls the rights thereto or has received all consents therefor as may be required by law; <br><br> (d) Upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another's computer; <br><br> (e) Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded; <br><br> (f) Falsify the origin or source of software or other material contained in a file that is uploaded; <br><br> (g) Advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a Forum that the User knows, or reasonably should know, cannot be legally distributed in such manner.</blockquote>
<p></p>
<p class="bodytext">The User acknowledges that all Forums and discussion groups are public and not private communications. Further, the User acknowledges that chats, postings, conferences, e-mails and other communications by other Users are not endorsed by the International Criminal Court, and that such communications shall not be considered to have been reviewed, screened or approved by the International Criminal Court. The International Criminal Court reserves the right to remove, for any reason and without notice, any content of the Forums received from Users, including, without limitation, e-mail and bulletin board postings. <br><br>
</p>
<h3>Preservation of immunities </h3>
<p class="bodytext">Nothing herein shall constitute or be considered to be a limitation upon or a waiver of the privileges and immunities of the International Criminal Court, which are specifically reserved. <br><br>
</p>
<h3>Privacy Policy </h3>
<p class="bodytext">${PRIVACY_POLICY_EN}</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:51/text [end] -->
    </div>`;

export default termUseEn;
