import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Paper,
    Typography,
    Checkbox,
    Button,
    Collapse,
    Dialog,
    Fab,
    Tooltip,
    IconButton,
    withTheme,
    WithTheme,
    Theme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    TDocument,
    TMetadata,
    TSearchResult,
} from '../../Features/LTDDocuments/@types';
import Divider from '../Divider';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import {
    ODocuments,
    DOCUMENT_BASE_URL,
    SCC_DOCUMENT_BASE_URL,
} from '../../Features/LTDDocuments/redux-config';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import { OApp, TToastVariant, TAppState } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { HISTORY } from '../../screens/RootLayout';
import Bookmark from '../Bookmark';
import { TReadingList } from '../../Features/ReadingList/@types';
import classNames from 'classnames';
import Text from '../Text';
import { TState } from '../../RootReducer/index';
import { value } from '../../../node_modules/popmotion';
import Agreement from '../../screens/DetailPage/Agreement';
import { getMetadataConfig } from './ListItemMetadataConfig';
import { ILtdPermissions } from '../../Features/Authentication/@types';
import { TNode } from '../../Features/DocumentsTree/@types';
import { ODocumentTree } from '../../Features/DocumentsTree/redux-config';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import {
    CARD_PANEL_COPYURL_TEXT,
    CARD_PANEL_DOWNLOAD,
    CARD_PANEL_LINK_COPIED_TO_CLIPBOARD,
    CARD_PANEL_SEE_FULL,
} from '../../Features/Translations/translationKeys.ltd';

interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    RouteComponentProps,
    WithTheme,
    WithTranslationProps {
    item: TSearchResult;
    readingListItem?: boolean;
    disableCheckBox?: boolean;
    treeView?: boolean;
    itemActions?: Array<{
        name: string;
        value: string;
    }>;
    documentsSelected?: string[];
    onActionItemClick?: (action: any, document: TDocument) => void;
    onRemoveFromReadingList?: () => void;
    onClickTitle?: (node: TDocument) => void;
    handleToggle?: (id: string) => void;
    type?: 'scc' | 'ltd';
}

interface IStateProps {
    showMatchedText: boolean;
    selected: string[];
    ltdPermissions: ILtdPermissions;
}

interface IDispatchProps {
    openDocument: Function;
    showToast: Function;
    toggleCheck: (id: string) => void;
    expandTreeWithId: (ids: string[]) => any;
}

// const labels = [
//     { name: 'Date created', key: "Date created" },
//     { name: 'Language(s)', key: 'metadata_language_s' },
//     { name: 'External identifier', key: 'metadata_source' },
//     { name: 'Persistent URL', key: 'metadata_persistent_url' },
// ]

const labels = ['Date created', 'Language(s)', 'Source', 'Persistent URL'];

type TAction =
    | 'COPY PURL'
    | 'SEE FULL'
    | 'DOWNLOAD'
    | 'READ LATER'
    | 'METADATA'
    | 'REMOVE FROM LIST';

const ACTIONS: Array<TAction> = [
    'COPY PURL',
    'SEE FULL',
    // 'METADATA',
    'DOWNLOAD',
    'READ LATER',
];

const READING_LIST_ACTIONS: Array<TAction> = [
    'COPY PURL',
    'SEE FULL',
    // 'METADATA',
    'DOWNLOAD',
    'REMOVE FROM LIST',
];

class ListItem extends Component<Props & IStateProps> {
    state = {
        agreementAccepted: false,
        agreementDialogOpen: false,
    };

    openDocument = () => {
        if (this.props.onClickTitle)
            this.props.onClickTitle(this.props.item.hit);
        if (this.props.treeView) return;
        this.props.openDocument(_.get(this.props.item, 'hit.id'));
    };
    getTranslatedLabel = (action: TAction) => {
        const { getTranslation } = this.props;
        switch (action) {
            case 'COPY PURL':
                return getTranslation(CARD_PANEL_COPYURL_TEXT);
            case 'SEE FULL':
                return getTranslation(CARD_PANEL_SEE_FULL);
            case 'DOWNLOAD':
                return getTranslation(CARD_PANEL_DOWNLOAD);
            default:
                return action;
        }
    };
    actionClicked = (action: TAction) => {
        const { item, getTranslation, type } = this.props;
        const document = item.hit;
        const requireDownloadPermission =
            _.get(document, 'requireDownloadPermission') || false;

        switch (action) {
            case 'COPY PURL': {
                const pdfUrl =
                    type === 'scc' ? `${SCC_DOCUMENT_BASE_URL}${document.slug}/` : `${DOCUMENT_BASE_URL}${document.slug}/`;
                utilities.copyLink(pdfUrl);

                this.props.showToast(getTranslation(CARD_PANEL_LINK_COPIED_TO_CLIPBOARD) || 'Link copied to clipboard', 'success');

                break;
            }
            case 'DOWNLOAD': {
                if (
                    requireDownloadPermission &&
                    !this.state.agreementAccepted
                ) {
                    this.toggleAgreementDialog();
                    return;
                }
                const pdfUrl =
                    type === 'scc'
                        ? `${SCC_DOCUMENT_BASE_URL}${document.slug}/pdf`
                        : `${DOCUMENT_BASE_URL}${document.slug}/pdf`;
                window.open(pdfUrl, '_blank');
                break;
            }
            case 'READ LATER': {
                break;
            }
            case 'SEE FULL': {
                if (this.props.treeView) return;

                const id = !_.isEmpty(document.slug)
                    ? document.slug
                    : document.id;
                this.props.openDocument(id);
                type === 'scc' ? HISTORY.push(`/sccdoc/${id}/`) : HISTORY.push(`/doc/${id}/`);
                break;
            }
            case 'METADATA': {
                if (this.props.treeView) return;
                const id = !_.isEmpty(document.slug)
                    ? document.slug
                    : document.id;
                this.props.openDocument(id);
                type === 'scc' ? HISTORY.push(`/sccdoc/${id}/metadata`) : HISTORY.push(`/doc/${id}/metadata`);
                break;
            }
            case 'REMOVE FROM LIST': {
                if (this.props.onRemoveFromReadingList)
                    this.props.onRemoveFromReadingList();
            }
            default:
                return;
        }
    };

    toggleAgreementDialog = () =>
        this.setState({ agreementDialogOpen: !this.state.agreementDialogOpen });

    onAgreeDownload = () => {
        const { item } = this.props;
        const document = item.hit;
        this.setState({ agreementAccepted: true, agreementDialogOpen: false });
        const pdfUrl = `${DOCUMENT_BASE_URL}${document.slug}/pdf`;
        window.open(pdfUrl, '_blank');
    };

    navigateToNode = async () => {
        const { item } = this.props;
        const document = item.hit;
        const id = _.get(document, 'node.parentId');
        if (!id) return;

        try {
            await this.props.expandTreeWithId([id]);
            this.props.history.push('/ltd-dashboard/tree-management');
        } catch (error) { }
    };

    toggleCheck = (id: string) => () => {
        if (this.props.documentsSelected && this.props.handleToggle) {
            this.props.handleToggle(id);
        } else {
            this.props.toggleCheck(id);
        }
    };

    render() {
        const {
            classes,
            item,
            theme,
            disableCheckBox = false,
            showMatchedText = false,
            selected,
            documentsSelected,
            readingListItem = false,
            ltdPermissions,
            treeView = false,
            itemActions,
            onActionItemClick,
            type = 'ltd',
        } = this.props;

        const { agreementDialogOpen } = this.state;
        const document = item.hit;

        const copyRightAuthorisation =
            _.get(document, 'copyRightAuthorisation') || false;

        const readingListActions = !copyRightAuthorisation
            ? READING_LIST_ACTIONS.filter(action => action !== 'DOWNLOAD')
            : READING_LIST_ACTIONS;
        let actions = this.props.treeView
            ? ACTIONS.filter(action => action !== 'METADATA')
            : ACTIONS;
        actions = !copyRightAuthorisation
            ? actions.filter(action => action !== 'DOWNLOAD')
            : actions;

        const METADATA = getMetadataConfig(document);
        const STATUS = [
            {
                label: 'Not published',
                icon: 'vpn_lock',
                key: 'isPublished',
                value: false,
            },
            {
                label: 'Private',
                icon: 'visibility_off',
                key: 'confidentiality',
                value: 'confidential',
            },
            { label: 'Deleted', icon: 'delete', key: 'deleted', value: true },
        ];

        if (_.isEmpty(item)) return <div />;
        return (
            <Paper elevation={0} className={classes.root}>
                <div className={classes.leading}>
                    {!readingListItem && !disableCheckBox ? (
                        // (!readingListItem && !treeView) ?
                        <Checkbox
                            className={classes.checkbox}
                            color='primary'
                            checked={_.indexOf(documentsSelected ? documentsSelected : selected, document.id) > -1}
                            onChange={this.toggleCheck(document.id)}
                        />
                    ) : null}

                    <Link
                        onClick={this.openDocument}
                        className={classes.link}
                        to={
                            treeView
                                ? '#'
                                : type === 'scc'
                                ? `/sccdoc/${document.slug || document.id}/`
                                : `/doc/${document.slug || document.id}/`
                        }
                    >
                        <Typography
                            color={!_.isEmpty(item.highlight) ? 'textPrimary' : 'textPrimary'}
                            className={classes.title}
                        >
                            {document.title || _.get(document, 'Title')}
                        </Typography>
                    </Link>
                    {ltdPermissions.other ? (
                        <div className={classes.status}>
                            {_.map(STATUS, config =>
                                _.isEqual(_.get(document, config.key), config.value) ? (
                                    <Tooltip key={config.key} title={config.label}>
                                        <i className='material-icons'>{config.icon}</i>
                                    </Tooltip>
                                ) : null
                            )}
                        </div>
                    ) : null}
                </div>
                <Divider />
                {!_.isEmpty(item.highlight) && <Preview classes={classes} highlight={item.highlight} />}

                <div>
                    {_.map(METADATA, (metadata, i) => (
                        <div key={metadata.key} className={classes.listItem}>
                            {/* <Typography className={classes.label}>{metadata.label}</Typography> */}
                            <Text linkColor={theme.palette.primary.main} variant='caption'>
                                {metadata.value}
                            </Text>
                        </div>
                    ))}
                    {ltdPermissions.other && _.get(document, 'node.parent.text') && (
                        <Button
                            color='primary'
                            size='small'
                            classes={{
                                root: classes.linkButton,
                            }}
                            onClick={this.navigateToNode}
                        >
                            {_.get(document, 'node.parent.text')}
                        </Button>
                    )}
                </div>

                {!_.isEmpty(itemActions) ? (
                    <div className={classes.actionContainer}>
                        {_.map(itemActions, action => {
                            return (
                                <Button
                                    id={`${document.id}_${action.value}`}
                                    key={`${document.id}_${action.value}`}
                                    color='primary'
                                    variant='text'
                                    onClick={e => (onActionItemClick ? onActionItemClick(action, document) : null)}
                                >
                                    {action.name}
                                </Button>
                            );
                        })}
                    </div>
                ) : (
                    <div className={classes.actionContainer}>
                        {_.map(this.props.readingListItem ? readingListActions : actions, action => {
                            if (action === 'READ LATER')
                                return (
                                    <Bookmark
                                        docType='LtdDoc'
                                        key={_.uniqueId('bookmark')}
                                        color='primary'
                                        variant='caption'
                                        id={document.id}
                                    />
                                );
                            else
                                return (
                                    <Button
                                        id={action}
                                        key={_.uniqueId('btn')}
                                        color='primary'
                                        variant='text'
                                        onClick={e => this.actionClicked(action)}
                                    >
                                        {this.getTranslatedLabel(action)}
                                    </Button>
                                );
                        })}
                    </div>
                )}

                <Dialog open={agreementDialogOpen} PaperProps={{ className: classes.dialogRoot }}>
                    <Fab size='small' className={classes.dialogCloseBtn} onClick={this.toggleAgreementDialog}>
                        <i className='material-icons'>close</i>
                    </Fab>
                    <Agreement onAgree={this.onAgreeDownload} />
                </Dialog>
            </Paper>
        );
    }
}

interface PreviewProps {
    highlight: Array<string>;
}
class Preview extends Component<WithStyles<typeof STYLES> & PreviewProps> {
    state = {
        showPreview: false,
    };

    togglePreview = () => {
        this.setState({ showPreview: !this.state.showPreview });
    };

    render() {
        const { classes, highlight } = this.props;
        const { showPreview } = this.state;
        let highlights: Array<string> = [];
        _.forEach(highlight, (value, key) => {
            _.forEach(value, text => highlights.push(text));
        });
        return (
            <div>
                <IconButton
                    color='primary'
                    className={classes.togglePreviewBtn}
                    onClick={this.togglePreview}
                >
                    <i
                        style={{
                            transition: '200ms linear',
                            transform: showPreview ? 'rotateZ(180deg)' : '',
                        }}
                        className='material-icons'
                    >
                        keyboard_arrow_down
                    </i>
                </IconButton>
                <Collapse in={showPreview}>
                    <div className={classes.previewContainer}>
                        {_.map(highlights, h => (
                            <Typography
                                className={classes.previewText}
                                key={_.uniqueId('-highlight')}
                                component='div'
                            >
                                <div>...</div>
                                {
                                    <Typography
                                        dangerouslySetInnerHTML={{ __html: h }}
                                    />
                                }
                                <div>...</div>
                            </Typography>
                        ))}
                    </div>
                </Collapse>
                {showPreview ? <Divider /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Documents, ['showMatchedText', 'selected']),
    ..._.pick(state.Auth, ['ltdPermissions']),
});

const mapDispatchToProps = dispatch => ({
    openDocument: id => dispatch(ODetail.openDocument(id)),
    showToast: (message, variant: TToastVariant) =>
        dispatch(OApp.showToast(message, variant)),
    toggleCheck: (id: string) => dispatch(ODocuments.toggleSelect(id)),
    expandTreeWithId: (ids: string[]) =>
        dispatch(ODocumentTree.expandTreeWithIds(ids)),
});

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
            padding: '10px 32px 0px 32px',
            margin: '5px 0px',
            transition: '200ms linear',
            '&:hover': {
                boxShadow: `0px 1px 3px ${fade(THEME.Colors.Fourth, 0.3)}`,
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8px 10px 0px 32px',
            },
        },
        status: {
            display: 'flex',
            '& .material-icons': {
                margin: '4px 6px',
                fontSize: 18,
                color: THEME.Colors.Grayscale.Grey3,
            },
        },
        togglePreviewBtn: {
            position: 'absolute',
            top: 0,
            right: 0,
            '& i': {
                color: 'black',
            },
        },
        checkbox: {
            position: 'absolute',
            left: 2,
            top: 10,
            [theme.breakpoints.down('sm')]: {
                top: 8,
                left: 6,
            },
        },
        primary: {
            color: theme.palette.primary.main,
        },
        extraPreview: {},
        listItem: {
            display: 'flex',
            '& p': {
                fontSize: 12,
            },
        },
        title: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.4,
        },
        leading: {
            display: 'flex',
            alignItems: 'flex-start',
            '& > span': {
                padding: 0,
                marginRight: 4,
            },
        },
        label: {
            fontWeight: 500,
        },
        link: {
            textDecoration: 'none',
            flex: '1 1 0',
        },
        linkButton: {
            padding: 0,
            minHeight: 0,
            height: 'auto',
            fontSize: 12,
            fontWeight: 400,
            textTransform: 'initial',
            minWidth: 0,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        previewContainer: {
            // paddingBottom: 16,
            margin: '4px 0px',
            '& em': {
                background: THEME.Colors.Highlight,
            },
            '& > div': {
                fontSize: 14,
                fontWeight: 500,
                margin: '4px 0px',
            },
        },
        actionContainer: {
            marginTop: 8,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& #METADATA': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: 10,
                },
                fontSize: 12,
                transition: '300ms linear',
                borderRadius: 2,
                '&:hover': {
                    background: 'white',
                    boxShadow: `0px 1px 3px ${fade(
                        THEME.Colors.Grayscale.Black,
                        0.2
                    )}`,
                },
                '& span': {
                    fontWeight: 500,
                    cursor: 'pointer',
                    transition: '500ms linear',
                    '&:hover': {
                        color: theme.palette.primary.dark,
                    },
                },
            },
        },
        previewText: {
            transition: '200ms linear',
            '&>div, &>p': {
                lineHeight: 1.2,
                display: 'inline',
            },
            '&:hover': {
                background: fade(THEME.Colors.Fourth, 0.1),
            },
        },
        dialogRoot: {
            padding: 20,
        },
        dialogCloseBtn: {
            position: 'absolute',
            top: 6,
            right: 8,
            background: THEME.Colors.Grayscale.White,
            boxShadow: 'none',
        },
    });

export default withTranslations(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withStyles(STYLES)(withTheme()(ListItem)))
    )
);
