import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, FormControl, Button, CircularProgress, LinearProgress } from '@material-ui/core';
import SimpleDialog from '../SimpleDialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { MENU_LIST_EMAIL, MENU_LIST_ENTER, MENU_LIST_NAME, MENU_LIST_PLEASE_DESCRIBE, MENU_LIST_PURL, MENU_LIST_SUGGESTION_CORRETION } from '../../Features/Translations/translationKeys.ltd';



export interface SuggestCorrectionProps extends WithStyles<typeof STYLES> {
    open: boolean
    handleClose: () => void
    handleSubmitForm: (form: Partial<TSuggestCorrectionForm>) => Promise<void>
}

export interface TSuggestCorrectionForm {
    name: string
    email: string
    purl: string
    description: string
}
const SuggestCorrection: FC<SuggestCorrectionProps & WithTranslationProps> = (props) => {
    const { classes, open = false, getTranslation } = props;
    const [formData, setFormData] = useState<Partial<TSuggestCorrectionForm>>({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async () => {
        setLoading(true)
        await props.handleSubmitForm(formData);
        setLoading(false)
    }

    return (
        <SimpleDialog open={open} handleClose={props.handleClose} headerText={ getTranslation(MENU_LIST_SUGGESTION_CORRETION)||"Suggest correction to the metadata of an existing LTD record."}>
            <ValidatorForm onSubmit={handleSubmit}>
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_NAME) || "name"}
                    name ='name'
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'name') || ''}
                    onChange={handleChange}
                />
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_EMAIL) ||'E-mail'}
                    name="email"
                    validators={['required', 'isEmail']}
                    errorMessages={['Required', 'Incorrect Email']}
                    value={_.get(formData, 'email') || ''}
                    onChange={handleChange}
                />
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_PURL) ||'PURL'}
                    name="purl"
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'purl') || ''}
                    onChange={handleChange}
                />
                <TextValidator
                    multiline
                    fullWidth
                    className="my-2"
                    label={ getTranslation(MENU_LIST_PLEASE_DESCRIBE)|| 'Please describe the correction.'}
                    name="description"
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'description') || ''}
                    onChange={handleChange}
                />
                <Button className={classes.button} type="submit" fullWidth variant="contained" color="primary">
                    {loading ? <CircularProgress /> : getTranslation(MENU_LIST_ENTER) || 'ENTER'}
                </Button>
            </ValidatorForm>
        </SimpleDialog>
    )
}

const STYLES = (theme: Theme) => createStyles({
    button: {
        marginTop: 18
    }
});

export default withStyles(STYLES)(withTranslations(SuggestCorrection));