const legalToolAdvisoryEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/ltac/" >LTAC</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:256/text [begin] -->
    <div id="c256" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Legal Tools Advisory Committee</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext"> A Court-wide Legal Tools Advisory Committee (the LTAC) has been established comprising members from all Court Organs to assess the needs of relevant users within the Court as well as other participants before the Court such as defence and victims' counsel. The Committee has developed the Metadata Manual and keyword categories on which the ICC Legal Tools Database is operating. </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:256/text [end] -->
    </div>`;
export default legalToolAdvisoryEn;
