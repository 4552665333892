import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, FormControl, InputLabel, Input, Button, Typography, CircularProgress, Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import utilities from '../Resources/Utils';

interface IStateProps {
    // onSelect: (data: { name: string, value: any }) => void
}

interface IDispatchProps { }

interface IState {
    value: string,
    error: boolean,
    loading: boolean,
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
    onSelect: ({ name: string, value: any }) => void
    formKey: string
    label: string
    placeholder: string
    resultType?: 'url' | 'document'
}

class PurlResource extends Component<IProps, IState> {
    state = {
        value: '',
        error: false,
        loading: false,
    }


    handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: e.target.value });
    }


    getDocument = async (id: string) => {
        this.setState({ loading: true })
        try {
            const res = await utilities.request({
                url: `ltddocs/${id}`,
                params: {
                    filter: {
                        fields: ['title', 'id', 'slug']
                    }
                }
            })
            this.setState({ loading: false })
            return res.data;
        } catch (error) {
            this.setState({ loading: false })
            throw error
        }
    }


    getDocIdFromPurl = async (url: string) => {
        const { resultType = 'document' } = this.props;
        const x = url.split('/').filter(x => x !== '');
        const i = x.indexOf('doc');
        if (i > -1 && x[i + 1]) {
            try {
                const value = await this.getDocument(x[i + 1]);
                if (resultType === 'document')
                    this.props.onSelect({ name: this.props.formKey, value: { ...value, name: value.title || '' } })
                else
                    this.props.onSelect({ name: this.props.formKey, value: url })

                this.setState({ error: false })
            } catch (error) {

                this.setState({ error: true })
            }
        }
        else if (!this.state.error)
            this.setState({ error: true })

    }

    handleAdd = () => {

        this.getDocIdFromPurl(this.state.value);

    }

    render() {
        const { classes, label, placeholder } = this.props
        return (
            <FormControl fullWidth>
                <InputLabel htmlFor={'purl'}>{label}</InputLabel>
                <Input
                    placeholder={placeholder}
                    autoComplete="off"
                    inputProps={{ id: 'purl' }}
                    onChange={this.handleEdit}
                    value={this.state.value}
                    startAdornment={<Icon>search</Icon>}
                    error={this.state.error}
                />
                {
                    this.state.error ?
                        <Typography variant="caption" color="error">Invalid PURL</Typography> : null
                }

                <Button disabled={this.state.loading} onClick={this.handleAdd}>{this.state.loading ? <CircularProgress /> : 'add'}</Button>
            </FormControl>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({

})

const STYLES = (theme: Theme) => createStyles({

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(PurlResource))