import { createStyles, withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isDescendant } from 'react-sortable-tree';
import THEME from '../../Resources/Theme';
import Entry from '../../screens/LTDDashboard/Entry';
// import './node-renderer-default.css';

class NodeRenderer extends Component {
    render() {
        const {
            scaffoldBlockPxWidth,
            toggleChildrenVisibility,
            connectDragPreview,
            connectDragSource,
            isDragging,
            canDrop,
            canDrag,
            node,
            title,
            subtitle,
            draggedNode,
            path,
            treeIndex,
            isSearchMatch,
            isSearchFocus,
            buttons,
            className,
            style,
            didDrop,
            treeId,
            isOver, // Not needed, but preserved for other renderers
            parentNode, // Needed for dndManager
            rowDirection,
            ...otherProps
        } = this.props;
        const { classes } = this.props;
        const nodeTitle = node['keyword_text'] || node['keyword_text__en'] || title || node.title || _.get(node, 'highlight.text[0]') || node.text;
        const nodeSubtitle = subtitle || node.subtitle;
        const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
        const statistics = node.statistics || { total: 0, live: 0 };
        // console.log("tree index - title", treeIndex, node.text)
        // const actualTreeIndex = _.indexOf(_.map(_.get(parentNode, 'children')) || [], node.id);
        // console.log("actual index", actualTreeIndex, node.text)

        let handle;
        if (canDrag) {
            if (typeof node.children === 'function' && node.expanded) {
                // Show a loading symbol on the handle when the children are expanded
                //  and yet still defined by a function (a callback to fetch the children)
                handle = (
                    <div className="rst__loadingHandle">
                        <div className="rst__loadingCircle">
                            {[...new Array(12)].map((_, index) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className={classnames(
                                        'rst__loadingCirclePoint',
                                        rowDirectionClass
                                    )}
                                />
                            ))}
                        </div>
                    </div>
                );
            } else {
                // Show the handle used to initiate a drag-and-drop
                // handle = connectDragSource(<div className="rst__moveHandle" />, {
                handle = connectDragSource((
                    <div className="drag-icon">
                        <i className="material-icons">open_with</i>
                    </div>
                ), {
                    dropEffect: 'copy',
                });
            }
        }

        const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
        const isLandingPadActive = !didDrop && isDragging;

        let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
        if (rowDirection === 'rtl') {
            buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
        }

        const isExpandable = node['hasChildren'] || ((node.menuChildren || 0) > 0);
        const isEx = toggleChildrenVisibility &&
            node.childrenIds && (node.menuChildren > 0) &&
            (node.childrenIds.length > 0)
        return (
            <div className={classes.root} style={{ height: '100%' }} >
                {isExpandable && (
                    <div>
                        <button
                            type="button"
                            aria-label={node.expanded ? 'Collapse' : 'Expand'}
                            className={classnames(
                                node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
                                rowDirectionClass
                            )}
                            style={buttonStyle}
                            onClick={() =>
                                toggleChildrenVisibility({
                                    node,
                                    path,
                                    treeIndex,
                                })
                            }
                        />

                        {node.expanded && !isDragging && (
                            <div
                                style={{ width: scaffoldBlockPxWidth }}
                                className={classnames('rst__lineChildren', rowDirectionClass)}
                            />
                        )}
                    </div>
                )}

                <div className={classnames('rst__rowWrapper', rowDirectionClass, ((node.marked) ? 'highlighted' : ''))}>
                    {/* Set the row preview to be used during drag and drop */}
                    {connectDragPreview(
                        <div
                            className={classnames(
                                'rst__row',
                                isLandingPadActive && 'rst__rowLandingPad',
                                isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                                isSearchMatch && 'rst__rowSearchMatch',
                                isSearchFocus && 'rst__rowSearchFocus',
                                rowDirectionClass,
                                className
                            )}
                            style={{
                                opacity: isDraggedDescendant ? 0.5 : 1,
                                ...style,
                            }}
                        >
                            {handle}

                            <div
                                className={classnames(
                                    'rst__rowContents',
                                    !canDrag && 'rst__rowContentsDragDisabled',
                                    rowDirectionClass
                                )}
                            >
                                <div style={{ maxWidth: 500 - node.level * 50 }} className={classnames('rst__rowLabel', rowDirectionClass, ((node.hidden || node.parentHidden) ? 'hidden-node' : ''))}>
                                    {
                                        (node.assignedStatus) &&
                                        <span className={classnames(
                                            "assign-indicator",
                                            ((node.assignedStatus === 'ASSIGNED_PARENT') ? 'assign-parent' : 'assigned')
                                        )}></span>
                                    }

                                    <span
                                        className={classnames(
                                            'rst__rowTitle',
                                            node.subtitle && 'rst__rowTitleWithSubtitle'
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: typeof nodeTitle === 'function'
                                                ? nodeTitle({
                                                    node,
                                                    path,
                                                    treeIndex,
                                                })
                                                : nodeTitle
                                        }}
                                    >
                                    </span>

                                    {nodeSubtitle && (
                                        <span className="rst__rowSubtitle">
                                            {typeof nodeSubtitle === 'function'
                                                ? nodeSubtitle({
                                                    node,
                                                    path,
                                                    treeIndex,
                                                })
                                                : nodeSubtitle}
                                        </span>
                                    )}
                                </div>

                            </div>
                            <div className="rst__rowToolbar">
                                {buttons.map((btn, index) => (
                                    <div
                                        key={index} // eslint-disable-line react/no-array-index-key
                                        className="rst__toolbarButton"
                                    >
                                        {btn}
                                    </div>
                                ))}
                            </div>
                            {/* <div className={classes.records}>
                                <Entry>{statistics.total || 0} / {statistics.totalDeep || 0}</Entry>
                                <Entry>{statistics.live || 0} / {statistics.liveDeep || 0}</Entry>
                                <Entry>{(statistics.lastChange) ? moment(statistics.lastChange).format('DD MMM, YYYY') : ''}</Entry>
                            </div> */}
                        </div>
                    )}

                </div>
            </div>
        );
    }
}

NodeRenderer.defaultProps = {
    isSearchMatch: false,
    isSearchFocus: false,
    canDrag: false,
    toggleChildrenVisibility: null,
    buttons: [],
    className: '',
    style: {},
    parentNode: null,
    draggedNode: null,
    canDrop: false,
    title: null,
    subtitle: null,
    rowDirection: 'ltr',
};

NodeRenderer.propTypes = {
    node: PropTypes.shape({}).isRequired,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    isSearchMatch: PropTypes.bool,
    isSearchFocus: PropTypes.bool,
    canDrag: PropTypes.bool,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    toggleChildrenVisibility: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    style: PropTypes.shape({}),

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    isDragging: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    // Drop target
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool,

    // rtl support
    rowDirection: PropTypes.string,
};




const STYLES = theme => createStyles({
    root: {
        '& i': {
            fontSize: 20
        },
        "& .drag-icon": {
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
            cursor: 'grab',
            transform: 'translateX(10px)',
            color: THEME.Colors.Secondary.dark,

        },
        '&.rst__rowTitle': {
            fontWeight: 600
        },
        '& .rst__rowContents': {
            background: 'transparent',
            boxShadow: 'none',
            border: 'none',
            '& .hidden-node': {
                color: THEME.Colors.Grayscale.Grey3
                /* width: 10,
                height: 10,
                background: THEME.Colors.Highlight,
                display: 'inline-block',
                borderRadius: 10,
                marginLeft: -3,
                marginRight: 3 */
            },
            '& .assign-indicator': {
                width: 10,
                height: 10,
                background: THEME.Colors.Highlight,
                display: 'inline-block',
                borderRadius: 10,
                marginLeft: -3,
                marginRight: 3,
                '&.assign-parent': {
                    background: THEME.Colors.Highlight
                },
                '&.assigned': {
                    background: THEME.Colors.Green
                }
            },
            '& .rst__rowLabel': {
                maxWidth: 300,
                whiteSpace: 'initial'
            }
        },
        '& .rst__rowWrapper': {
            border: `1px dashed ${fade('#000', 0.15)}`,
            padding: 0,
            '&.highlighted': {
                background: THEME.Colors.Highlight
            }
        },
        '& .rst_rowTitle': {
            whiteSpace: 'initial'
        },
        '& .rst__rowToolbar': {
            alignItems: 'center'
        }
    },
    records: {
        display: 'flex',

        '& p': {
            position: 'relative',
            width: 100,
            lineHeight: '50px',
            // fontWeight: 500,
            textAlign: 'center',
            borderLeft: `1px dashed ${fade('#000', 0.15)}`,
        },
        // '& p::before': {
        //     content: "' '",
        //     background: fade('#000', 0.15),
        //     height: 50,
        //     position: 'absolute',
        //     left: 0,
        //     top: -11,
        //     width: 1
        // }
        // flexDirection: 'column'
    }
})


export default withStyles(STYLES)(NodeRenderer);