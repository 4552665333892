import { OLtdDashboard } from "./redux-config";
import { Reducer } from "redux";
import { TAction } from "../../Resources/Model";
import { TLtdDashboardState, TGroup, TLog } from './@types';
import _ from 'lodash';
import utilities from "../../Resources/Utils";
import { TUser } from "../Authentication/@types";
import config from "../../Resources/Config";
import moment from 'moment';
import qs from 'querystring';
import { DEFAULT_ACTIVITY_LOG_FILTER } from "./Filters/activityLog";
import { setLTDMetadata } from "../LTDDocuments/redux-config";
import { ODocumentTree } from "../DocumentsTree/redux-config";
import { TDocument, TSearchResult } from "../LTDDocuments/@types";
import { OBatchEdit } from "../BatchEdit/redux-config";
import { DEFAULT_BATCH_EDIT_LOG_FILTER } from "./Filters/batchEditLog";

const initialState: TLtdDashboardState = {
    selectedIds: [],
    loading: false,
    tree: {
        documents: [],
        pageNumber: 0,
        loading: false,
        total: 0,
        searchFilter: {}
    },
    activityLog: {
        logs: [],
        pageNumber: 0,
        filter: { ...DEFAULT_ACTIVITY_LOG_FILTER }
    },
    batchEditLog: {
        logs: [],
        pageNumber: 0,
        filter: { ...DEFAULT_BATCH_EDIT_LOG_FILTER }
    },
    group: {
        groups: [],
        pageNumber: 0,
        groupDetail: undefined
    },
    users: {
        users: [],
        pageNumber: 0
    },
    importerLog: {
        logs: [],
        pageNumber: 0,
        filter: {}
    },
    suggestions: {
        suggestions: [],
        pageNumber: 0
    }
}


export const getImporterLogDownloadUrl = (filter?: Record<string, any>) => {
    const access_token = utilities.getAccessToken();
    const query: Record<string, any> = {
        access_token,
        where: qs.stringify({
            created: qs.stringify({
                $gte: moment().subtract(1, 'M').toISOString()
            })
        })
    };
    const API_URL = config.get('API_URL') + '/importerlogs/csv?' + qs.stringify(query);
    return API_URL;
}

export const LtdDashboard: Reducer<TLtdDashboardState> = (state = initialState, action: TAction) => {
    const {
        LTD_LOG_ACTIVITIES_FETCHED,
        LTD_LOG_STATUS_UPDATED,
        LTD_LOG_ACTIVITY_DOC_PATH_UPDATED,
        LTD_DASHBOARD_SELECT_ID,
        LTD_DASHBOARD_RESET_SELECTION,
        LTD_GROUPS_PARTNERS_FETCHED,
        LTD_NEW_GROUP_CREATED,
        LTD_GROUP_EDIT_SUCCESS,
        LTD_GROUP_DELETED,
        LTD_GROUP_DETAIL_FETCHED,
        LTD_DASHBOARD_USERS_FETCHED,
        LTD_DASHBOARD_USER_ROLE_UPDATED,
        LTD_CREATE_USER,
        LTD_UPDATE_USER,
        LTD_USER_ADDED_TO_GROUP,
        LTD_FETCH_IMPORTER_LOGS,
        LTD_PERMITTED_NODES_FETCHED,
        LTD_IMPORTER_LOGS_SET_FILTER,
        LTD_ACTIVITY_LOGS_SET_FILTER,
        LTD_DASHBOARD_DOCUMENTS_FETCHED,
        LTD_DASHBOARD_DOCUMENT_SEARCHING,
        LTD_DASHBOARD_SET_DOCUMENTS_FILTER,
        LTD_DASHBOARD_SET_SELECTIONS,
        BATCH_EDIT_LOG_FETCHED,
        LTD_BATCH_EDIT_LOGS_SET_FILTER,
        LTD_SET_DASHBOARD_LOADING,
        LTD_DASHBOARD_FETCH_SUGGESTIONS
    } = OLtdDashboard.actions;

    const {
        BATCH_EDIT_FORM_SAVED
    } = OBatchEdit.actions
    switch (action.type) {
        case LTD_SET_DASHBOARD_LOADING: {
            return { ...state, loading: action.data }
        }
        case LTD_DASHBOARD_FETCH_SUGGESTIONS: {
            return { ...state, suggestions: { ...state.suggestions, suggestions: _.get(action.data, 'appendResult') ? [...state.suggestions.suggestions, ...action.data.suggestions] : action.data.suggestions || [], pageNumber: action.data.pageNumber } }
        }
        case BATCH_EDIT_LOG_FETCHED: {
            return {
                ...state,
                batchEditLog: {
                    ...state.batchEditLog,
                    logs: _.get(action.data, 'appendResult') ? [...state.batchEditLog.logs, ...action.data.logs] : action.data.logs || [], pageNumber: action.data.pageNumber
                }
            }
        }
        case BATCH_EDIT_FORM_SAVED: {
            return { ...state, tree: { ...state.tree, documents: utilities.multiUpdateList(state.tree.documents, action.data || [], 'hit.id') } }
        }
        case LTD_DASHBOARD_SET_DOCUMENTS_FILTER: {
            return { ...state, tree: { ...state.tree, searchFilter: action.data } }
        }
        case LTD_DASHBOARD_DOCUMENT_SEARCHING: {
            return { ...state, tree: { ...state.tree, loading: true } }
        }
        case LTD_DASHBOARD_DOCUMENTS_FETCHED: {
            const documents = _.map(_.get(action.data, 'documents'), doc => ({ ...doc, hit: { ...doc.hit, metadata: setLTDMetadata(_.get(doc, 'hit'), _.get(action, 'data.appConstants')) } }))
            const updatedDocs = state.permittedNodes ? assignNodeStatus(documents, state.permittedNodes) : documents
            return {
                ...state,
                tree: {
                    ...state.tree,
                    loading: false,
                    documents: updatedDocs,
                    total: _.get(action, 'data.total') || 0,
                    pageNumber: _.get(action, 'data.pageNumber') || 0
                }
            }
        }
        case LTD_BATCH_EDIT_LOGS_SET_FILTER: {
            return { ...state, batchEditLog: { ...state.batchEditLog, filter: action.data } }
        }
        case LTD_ACTIVITY_LOGS_SET_FILTER: {
            return { ...state, activityLog: { ...state.activityLog, filter: action.data } }
        }
        case LTD_IMPORTER_LOGS_SET_FILTER: {
            return { ...state, importerLog: { ...state.activityLog, filter: action.data } }
        }
        case LTD_FETCH_IMPORTER_LOGS: {
            const { appendResult = true } = action.data
            return { ...state, importerLog: { ...state.importerLog, logs: appendResult ? [...state.importerLog.logs, ...action.data.logs] : action.data.logs || [], pageNumber: action.data.pageNumber } }
        }

        case LTD_DASHBOARD_USER_ROLE_UPDATED: {
            const { userIds = [], role } = action.data;
            const updatedUsers = _.map(state.users.users, user => {
                if (_.indexOf(userIds, user.id) > -1)
                    user.roles = [role];
                return user;
            })

            return { ...state, users: { ...state.users, users: updatedUsers } }
        }
        case LTD_CREATE_USER: {
            return { ...state, users: { ...state.users, users: [action.data, ...state.users.users] } };
        }
        case LTD_UPDATE_USER: {
            const updatedUsers = utilities.updateItemList(state.users.users, action.data, 'UPDATE')
            return { ...state, users: { ...state.users, users: updatedUsers } }
        }
        // case LTD_DASHBOARD_USERS_SEARCH_RESULT_FETCHED: {
        //     return { ...state, users: { ...state.users, users: action.data.users, pageNumber: action.data.pageNumber } }
        // }
        case LTD_DASHBOARD_USERS_FETCHED: {
            if (action.data.appendResult)
                return { ...state, users: { ...state.users, users: [...state.users.users, ...action.data.users], pageNumber: action.data.pageNumber } }
            else
                return { ...state, users: { ...state.users, users: action.data.users, pageNumber: action.data.pageNumber } }
        }
        case LTD_GROUP_DETAIL_FETCHED: {
            return { ...state, group: { ...state.group, groupDetail: action.data } }
        }
        case LTD_GROUP_DELETED: {
            const groups = _.filter(state.group.groups, group => group.id !== action.data);
            return { ...state, group: { ...state.group, groups } }
        }
        case LTD_USER_ADDED_TO_GROUP: {
            let itemToUpdate = _.find<TGroup>(state.group.groups, { id: action.data.groupId });
            if (!itemToUpdate) return { ...state };
            itemToUpdate.userIds = action.data.userIds;
            const groups = utilities.updateItemList(state.group.groups, itemToUpdate, 'UPDATE');
            return { ...state, group: { ...state.group, groups } }
        }
        case LTD_GROUP_EDIT_SUCCESS: {
            const groups = utilities.updateItemList(state.group.groups, action.data, 'UPDATE');
            return { ...state, group: { ...state.group, groups } }
        }
        case LTD_NEW_GROUP_CREATED: {
            return { ...state, group: { ...state.group, groups: [...state.group.groups, action.data] } }
        }
        case LTD_GROUPS_PARTNERS_FETCHED: {
            return { ...state, group: { groups: action.data.groups, pageNumber: action.data.pageNumber } }
        }
        case LTD_LOG_ACTIVITIES_FETCHED: {
            const { appendResult = true } = action.data
            return { ...state, activityLog: { ...state.activityLog, logs: appendResult ? [...state.activityLog.logs, ...action.data.logs] : action.data.logs || [], pageNumber: action.data.pageNumber } }
        }
        case LTD_LOG_STATUS_UPDATED: {
            if (_.isEmpty(action.data.logs) || !action.data.status)
                return state;
            let updatedActivityLogs: TLog[] = state.activityLog.logs.map<TLog>(log => {
                const _log = _.find(_.get(action.data, 'logs'), { id: log.id });
                const update = _.find(_.get(action.data, 'logUpdates'), { id: log.id });
                if (update || _log) return { ..._log, ...update, status: action.data.status };
                return log;
            })
            let updatedBatchEditLogs: TLog[] = state.batchEditLog.logs.map<TLog>(log => {
                const _log = _.find(_.get(action.data, 'logs'), { id: log.id });
                const update = _.find(_.get(action.data, 'logUpdates'), { id: log.id });
                if (update || _log) return { ..._log, ...update, status: action.data.status };
                return log;
            })
            return { ...state, activityLog: { ...state.activityLog, logs: updatedActivityLogs }, batchEditLog: { ...state.batchEditLog, logs: updatedBatchEditLogs } };
            const updateObj = action.data.updateObj || {};
            // return { ...state, activityLog: { ...state.activityLog, logs: utilities.updateItemList(state.activityLog.logs, { ...action.data.log, ...updateObj, status: action.data.status }, 'UPDATE') } };
        }
        case LTD_LOG_ACTIVITY_DOC_PATH_UPDATED: {
            if (_.isEmpty(_.get(action, 'data.docPath')) || !_.get(action, 'data.logId'))
                return state;
            let activityLog = _.find(state.activityLog.logs, { id: action.data.logId }) as object
            if (!activityLog)
                return state;
            const updateDocKey = action.data.updateDocKey || 'subject';
            let document = _.get(activityLog, updateDocKey) as object;
            if (_.isEmpty(document))
                return state;
            document = { ...document, docPath: action.data.docPath };
            activityLog = _.set(activityLog, updateDocKey, document);
            return { ...state, activityLog: { ...state.activityLog, logs: utilities.updateItemList(state.activityLog.logs, { ...activityLog }, 'UPDATE') } };
        }
        case LTD_DASHBOARD_SET_SELECTIONS: {
            return { ...state, selectedIds: action.data }
        }
        case LTD_DASHBOARD_RESET_SELECTION: {
            return { ...state, selectedIds: [] }
        }
        case LTD_DASHBOARD_SELECT_ID: {
            return { ...state, selectedIds: action.data }
        }
        case LTD_PERMITTED_NODES_FETCHED: {
            return { ...state, permittedNodes: action.data };
        }

        default: return { ...state }
    }
}

const assignNodeStatus = (docs: TSearchResult[], permittedNodes: any) => {
    _.each(docs, document => {
        let node = _.get(document, 'hit.node');
        if (!_.isEmpty(node) && !node.assignedStatus)
            ODocumentTree.setNodeAssignStatus(node, permittedNodes);
    });
    return docs;
}