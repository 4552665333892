import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Popover, Paper, CircularProgress, FormControl, Select, MenuItem, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import THEME from '../../../Resources/Theme';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import _ from 'lodash';
import { TNode } from '../../../Features/DocumentsTree/@types';
import Tree from '../../../Components/Tree';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { TGroup } from '../../../Features/LtdDashboard/@types';
import { OApp } from '../../../RootReducer/AppReducer';
import NodeTreeContainer from './NodeTreeContainer';
import { TUserRole, ILtdPermissions, TUser } from '../../../Features/Authentication/@types';
import { getUserRole } from '../../../Features/Authentication/redux-config';
import UserListContainer from './UserListContainer';


interface IStateProps {
    tree: TNode[]
    selectedIds: string[]
    data: TGroup | undefined
    ltdPermissions: ILtdPermissions
    users: TUser[]
}

interface IDispatchProps {
    fetchBaseTreeNodes: () => void
    fetchGroupDetail: (groupId: string) => void
    openSidebar: (component: JSX.Element) => void
    deleteGroup: (groupId: string) => any
    showConfirmation: (msg, onOk, onCancel) => void
    changeRole: (userIds, role, oldRole?) => void
    expandTreeWithId: (ids: string[]) => any
    editGroup: (formData, groupId) => any
    fetchUsers: (pageNumber) => any
}

interface IState {
    sidebarOpen: boolean
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps { }

class GroupDetail extends Component<IProps, IState> {
    state = {
        sidebarOpen: false
    }

    componentDidMount() {
        const { match } = this.props;
        const groupId = _.get(match.params, 'id');
        if (!_.isEmpty(groupId))
            this.props.fetchGroupDetail(groupId);

        if (_.isEmpty(this.props.tree))
            this.props.fetchBaseTreeNodes();
    }

    handleAssignUser = async () => {
        if (_.isEmpty(this.props.users))
            await this.props.fetchUsers(0);
        this.props.openSidebar((
            <UserListContainer />
        ))
    }

    openSidebar = () => {
        this.props.openSidebar((
            <NodeTreeContainer />
        ))
    }

    handleSidebarClose = () => this.setState({ sidebarOpen: false })


    reloadData = () => {
        const { data } = this.props;
        if (!data) return;
        this.props.fetchGroupDetail(data.id);
    }

    handleDeleteGroup = async () => {
        if (!this.props.data) return;

        this.props.showConfirmation(
            "Are you sure you want to delete this group?",
            () => {
                this.props.deleteGroup(this.props.data!.id);
                this.props.history.goBack();
            },
            () => null
        )
    }

    handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, user: TUser) => {
        const currentRole = getUserRole(user.roles);
        this.props.changeRole([user.id], e.target.value, currentRole)
    }

    handleNodeClick = async (node: TNode) => {
        try {
            await this.props.expandTreeWithId([node.id]);
            this.props.history.push('/ltd-dashboard/tree-management')
        } catch (error) {

        }
    }

    handleUserRemove = async (user: TUser) => {
        const { data } = this.props;
        if (!data) return;
        const userIds = _.difference(data.userIds || [], [user.id]);
        this.props.showConfirmation(
            "Remove user from group?",
            () => {
                this.removeUser(userIds, data.id)
            },
            () => null
        )

    }

    handleNodeRemove = async (node: TNode) => {
        const { data } = this.props;
        if (!data) return;
        const nodeIds = _.difference(data.assignedNodeIds || [], [node.id]);
        this.props.showConfirmation(
            "Remove node from group?",
            () => {
                this.removeNode(nodeIds, data.id)
            },
            () => null
        )

    }

    removeNode = async (nodeIds, groupId) => {
        await this.props.editGroup({
            assignedNodeIds: nodeIds
        }, groupId);
        this.reloadData();
    }
    removeUser = async (userIds, groupId) => {
        await this.props.editGroup({
            userIds
        }, groupId);
        this.reloadData();
    }


    render() {
        const { classes, selectedIds, data, ltdPermissions } = this.props;
        const { sidebarOpen } = this.state;
        const assignedNodes = _.get(data, 'assignedNodes') || [];


        const ALL_ROLES: Array<{ label: string, key: TUserRole }> = [
            { label: 'Creator', key: 'CREATOR' },
            { label: 'Supervisor', key: 'SUPERVISOR' },
            { label: 'Admin', key: 'ADMIN' },
        ]

        const ROLES = _.filter(ALL_ROLES, role => _.indexOf(ltdPermissions.userChangeRoleOptions, role.key) > -1)



        if (_.isUndefined(data))
            return <div style={{ margin: '50px auto', width: 'max-content' }}><CircularProgress color="primary" /></div>

        return (
            <div className={classes.root}>
                <div id="anchor" aria-describedby='sidebar' />
                <div className={classes.toolbar}>
                    <div className="flex-row space-between">
                        <Typography><b>{data.name} - {data.userIds.length} {data.userIds.length === 1 ? 'Member' : 'Members'}</b></Typography>
                        <div className="flex-row align-center">
                            {/* <Typography>Select</Typography> */}
                            <Button color="primary" onClick={this.openSidebar}>Assign node</Button>
                            <Button color="primary" onClick={this.handleAssignUser}>Assign user</Button>
                            {/* <Button color="secondary" onClick={this.handleDeleteGroup}>Delete group</Button> */}
                            {/* <Button color="secondary">Invite to group</Button> */}
                        </div>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <Typography gutterBottom className="my-1"><b>Assigned Nodes</b></Typography>
                        <div >
                            {
                                assignedNodes.map(node => (
                                    <div className={classes.listItem} key={node.id}>
                                        <Typography onClick={e => this.handleNodeClick(node)} className={classNames(classes.nodeItem, "pointer")} variant="caption" key={node.id} color="primary"><b>{node.text}</b></Typography>
                                        <Tooltip title="Remove node from group">
                                            <Button onClick={e => this.handleNodeRemove(node)}>
                                                <i className="material-icons">clear</i>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                    <div className={classes.section}>
                        <Typography gutterBottom className="my-1"><b>Users &amp; Roles</b></Typography>
                        <div>
                            {
                                data.users ?
                                    data.users.map(_user => (
                                        <div className={classes.listItem} key={_user.id} >
                                            <Typography className={classes.column}>{_user.name || ''}</Typography>
                                            <Typography className={classes.column}>{_user.company || ''}</Typography>
                                            <Typography className={classes.column}>{_user.email}</Typography>
                                            <div className={classes.actionContainer}>
                                                <Tooltip title="Remove user from group">
                                                    <Button onClick={e => this.handleUserRemove(_user)}>
                                                        <i className="material-icons">clear</i>
                                                    </Button>
                                                </Tooltip>
                                                {/* <FormControl variant="filled" fullWidth>
                                                    <Select
                                                        value={getUserRole(_user.roles)}
                                                        onChange={e => this.handleChange(e, _user)}
                                                        inputProps={{
                                                            name: '',
                                                            id: _user.id,
                                                        }}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {
                                                            ROLES.map(role => (
                                                                <MenuItem key={role.key} value={role.key}>
                                                                    <Typography color="secondary">{role.label}</Typography>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl> */}
                                            </div>
                                        </div>
                                    )) : null
                            }
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.LtdDashboard, ['selectedIds']),
    tree: _.get(state, 'DocumentTree.tree'),
    users: _.get(state.LtdDashboard, 'users.users'),
    data: _.get(state.LtdDashboard, 'group.groupDetail'),
    ..._.pick(state.Auth, ['user', 'ltdPermissions'])

})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchBaseTreeNodes: () => dispatch(ODocumentTree.fetchBaseLevelNodes()),
    fetchGroupDetail: (groupId: string) => dispatch(OLtdDashboard.fetchGroupDetail(groupId)),
    openSidebar: (component) => dispatch(OApp.openSidebar(component)),
    deleteGroup: (groupId: string) => dispatch(OLtdDashboard.deleteGroup(groupId)),
    showConfirmation: (msg, onOk, onCancel) => dispatch(OApp.showConfirmationDialog(msg, onOk, onCancel)),
    changeRole: (userIds, role, oldRole) => dispatch(OLtdDashboard.changeUserRole(userIds, role, oldRole)),
    expandTreeWithId: (ids: string[]) => dispatch(ODocumentTree.expandTreeWithIds(ids)),
    editGroup: (formData, groupId) => dispatch(OLtdDashboard.editGroup(formData, groupId)),
    fetchUsers: (pageNumber) => dispatch(OLtdDashboard.fetchUsers(pageNumber)),
})

const MAIN_CONTENT_WIDTH = 1000;
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000
    },
    toolbar: {
        position: 'fixed',
        width: '100%',
        zIndex: 1,
        boxShadow: `0px 1px 3px ${fade('#000', 0.16)}`,
        background: THEME.Colors.Grayscale.White,
        '& > div': {
            height: TOOLBAR_HEIGHT,
            width: MAIN_CONTENT_WIDTH,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center'
        }
    },
    container: {
        paddingTop: TOOLBAR_HEIGHT,
        width: MAIN_CONTENT_WIDTH,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column'
    },
    section: {
        margin: '50px 0px',
        width: '100%',
        '&:last-child': {
            marginBottom: 150
        }
    },
    listItem: {
        display: 'flex',
        borderTop: `1px dashed ${fade('#000', 0.2)}`,
        borderLeft: `1px dashed ${fade('#000', 0.2)}`,
        borderRight: `1px dashed ${fade('#000', 0.2)}`,
        '&:last-child': {
            borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        },
        '& i': {
            color: theme.palette.error.main
        },
        '& button': {
            // flex: 1,
            minHeight: 0,
            minWidth: 0,
            height: 40,
            width: 40,
            padding: 0,
        },
    },
    nodeItem: {
        flex: 1,
        padding: 10,
        boxSizing: 'border-box',
    },
    column: {
        padding: 10,
        boxSizing: 'border-box',
        flex: 1,
        borderRight: `1px dashed ${fade('#000', 0.2)}`,
        '&:last-child': {
            borderRight: 'none',
        }
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        // flex: 1,
        '& button': {
            // flex: 1,
            minHeight: 0,
            minWidth: 0,
            height: 40,
            width: 40,
            padding: 0,
        },
        '& > div': {
            flex: 1
        },

    },
    sidebar: {
        position: 'fixed',
        boxSizing: 'border-box',
        padding: '10px 20px',
        zIndex: 1300,
        top: 50,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        background: THEME.Colors.Grayscale.White,
        width: 400,
        borderRadius: 0
    }
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(GroupDetail)));