import React from 'react';
import _ from 'lodash';
import { createStyles, WithStyles, withStyles, Button, Typography } from '@material-ui/core';
import Theme from '../Resources/Theme';
import Bookmark from './Bookmark';
import classNames from 'classnames';


export interface SelectedDocumentActionsProps extends WithStyles<typeof STYLES> {
    selectedIds: string[]
    containerClass?: any
    variant?: 'ltd' | 'cld'
    docType: 'LtdDoc' | 'CldDoc'
    titleSuffix?: string
    actions: Array<{
        label: string,
        action: () => void
    }>
    onActionCompleted?: () => void
}

const SelectedDocumentActions: React.FC<SelectedDocumentActionsProps> = (props) => {

    const { classes, titleSuffix = 'selected', containerClass, selectedIds = [], docType = 'LtdDoc', actions = [], variant = 'cld' } = props;
    const open = selectedIds.length > 0;

    const CONTAINER_CLASS = containerClass ? containerClass : classes.actionControlContainer;

    return (
        <div className={classNames(CONTAINER_CLASS, variant === 'cld' ? classes.cldContainer : classes.ltdContainer)} style={{
            // display: open ? 'block' : 'none',
            opacity: open ? 1 : 0,
            zIndex: open ? 1 : -1,
            transform: open ? 'rotateX(0deg)' : 'rotateX(-70deg)'
        }}>
            <Typography variant="h6" className={classNames(classes.selected, variant === 'cld' ? classes.cld : classes.ltd)}>{selectedIds.length + ` ${titleSuffix}`}</Typography>
            {
                actions.map((action, i) => {
                    if (action.label === 'bookmark')
                        return <Bookmark key={i} fullWidth color="inherit" onActionCompleted={props.onActionCompleted} id={selectedIds} docType={docType} />
                    else
                        return (
                            <Button className={classes.actionBtn} key={i} fullWidth color="inherit" onClick={e => {
                                action.action();
                                if (props.onActionCompleted)
                                    props.onActionCompleted();
                            }}>
                                {action.label}

                            </Button>
                        )
                })
            }


        </div>
    )
}


const STYLES = theme => createStyles({
    actionControlContainer: {
        position: 'absolute',
        right: 100,
        bottom: 60,
        width: 180,
        zIndex: 1000,

        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            left: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    },

    cldContainer: {
        color: 'white',
        background: Theme.Colors.Primary.light,
        '&:before': {
            background: 'black'
        }
    },
    ltdContainer: {
        fontSize: 14,
        background: 'white',
        color: Theme.Colors.TextColor,
        '&:before': {
            background: Theme.Colors.TextLight,
        }
    },
    actionBtn: {
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            fontWeight: 500
        }
    },
    cld: {
        background: 'black',
    },
    ltd: {
        background: Theme.Colors.TextLight,
    },
    selected: {
        padding: '4px 8px',
        color: 'white',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            fontWeight: 500
        }
    }
});


export default withStyles(STYLES)(SelectedDocumentActions)