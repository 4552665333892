import React, { FC, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import _ from 'lodash';
import { TState } from '../../../RootReducer';
import KeywordTree from './KeywordTree';


export interface KeywordsProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {

}

interface IStateProps {
}

interface IDispatchProps {
}

const Keywords: FC<KeywordsProps> = (props) => {


    return (
        <div>
            <KeywordTree />
        </div>
    )
}

const mapStateToProps = (state: TState) => ({

});

const mapDispatchToProps = dispatch => ({

});

const STYLES = (theme: Theme) => createStyles({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Keywords));