import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cldUrls, isCld, isScc } from '../Resources/constants';

export interface MetaTagProps {
  title?: string;
  ogImage?: string;
  ogDescription?: string;

}


function getDefaults(pathname: string): MetaTagProps {
  const ogImage = process.env.PUBLIC_URL + '/icc-logo-white.png';


  if (isCld(pathname)) {
    return {
      title: 'ICC Case Law Database',
      ogImage,
      ogDescription: 'ICC Case Law Database'
    }
  }

  if (isScc(pathname)) {
      return {
          title: 'SPECIAL CRIMINAL COURT',
          ogImage,
          ogDescription: 'SPECIAL CRIMINAL COURT',
      };
  }

  return {
    title: 'ICC Legal Tools Database',
    ogImage,
    ogDescription: 'ICC Legal Tools Database',
  }
}

const MetaTag: FC<MetaTagProps & RouteComponentProps> = (props) => {
  const { title, ogImage, ogDescription, location } = props;
  const [defaults, setDefaults] = useState<MetaTagProps>({
    title: ''
  });
  useEffect(() => {
    setDefaults(getDefaults(location.pathname));
  }, [location])

  return (
    <Helmet>
      <title>{defaults.title} {title ? `| ${title}` : ''}</title>
      <meta
        name="description"
        content={ogDescription || defaults.ogDescription}
      />
      <meta
        property="og:title"
        content={title || defaults.title}
      />
      <meta
        property="og:image"
        content={ogImage || defaults.ogImage}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={title || defaults.title}
      />
      <meta
        name="twitter:image"
        content={ogImage || defaults.ogImage}
      />
      <meta
        property="og:description"
        content={ogDescription || defaults.ogDescription}
      />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default withRouter(MetaTag);
