import React, { useState } from 'react';
import { get } from 'lodash';

type TStatus = 'IDLE' | 'PROCESSING' | 'ERROR' | 'SUCCESS';
function useAsyncTask<T>(task: (arg: T) => Promise<any>) {

    const [status, setStatus] = useState<TStatus>('IDLE');
    const run = async (arg: T) => {
        setStatus('PROCESSING');
        let resp;
        try {
            resp = await task(arg);
            setStatus('SUCCESS');
        } catch (error) {
            setStatus('ERROR');
        }
        return resp;
    }
    const reset = () => {
        setStatus('IDLE');
    }


    return {
        run,
        status,
        reset
    }
}

export default useAsyncTask;