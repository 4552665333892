import React, { Component, Fragment } from 'react';
import {
    Theme,
    Typography,
    createStyles,
    withStyles,
    Input,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Chip,
    Modal,
    Button,
    Tooltip,
} from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { SectionLabel } from '.';
import THEME from '../../Resources/Theme';
import SearchWithSuggestion from '../SearchWithSuggestion';
import _ from 'lodash';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TUser } from '../../Features/Authentication/@types';
import { TState } from '../../RootReducer';
import { connect } from 'react-redux';
import { CldField } from '../../Services/CldFields.types';
import CldDocsService from '../../Services/CldDocs.service';
import { doesRoleInclude } from '../../Features/Authentication/redux-config';

interface IProps extends WithStyles<typeof STYLES> {
    user: TUser;
    legalFinding: TCLDDocument | undefined;
    onEdit: (key: string, value: any) => void;
    isNewLegalFinding: boolean;
    formData?: any;
    getNewFindingNumber: (documentNumber: string, languageId: string) => Promise<number>;
    formErrors?: TState['NewLegalFinding']['formError'];
    cldFields: CldField[];
}

class Metadata extends Component<IProps> {
    state = {
        modalOpen: false,
        // formData: {

        // }
    };

    componentDidMount() {
        const { legalFinding, isNewLegalFinding } = this.props;
        // if (isNewLegalFinding) {
        //     ONewLegalFinding.getNewLegalFindingID(legalFinding ? legalFinding["Document Number"] : '').then(
        //         res => {
        //             if (res !== '') {
        //                 this.changeValue('ID', res)
        //             }
        //         }
        //     )
        // }
        // if (this.props.legalFinding) {
        //     this.setState({
        //         formData: {
        //             ID: this.props.legalFinding.ID,
        //             Finding: this.props.legalFinding.Finding,
        //             Confidentiality: this.props.legalFinding.Confidentiality || '',
        //             situationCase: this.props.legalFinding.situationCase || '',
        //             'Applicable Appeal': this.props.legalFinding['Applicable Appeal'] || '',
        //             'Document Number': this.props.legalFinding['Document Number'] || '',
        //             'Appeal Doc ID': this.props.legalFinding['Appeal Doc ID'] || '',
        //             'Decision Type': this.props.legalFinding['Decision Type'] || '',
        //             Title: this.props.legalFinding.Title || '',
        //             progress: this.props.legalFinding.progress || '',
        //             'Legal Findings?': this.props.legalFinding['Legal Findings?'] || false

        //         }
        //     })
        // }
    }

    handleSuggestChange = ({ name, value }) => {
        this.changeValue(name, value);
    };

    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.changeValue(e.target.name, e.target.value);
    };

    handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.changeValue(e.target.name, e.target.value);
    };

    changeValue = (key: string, value: any) => {
        if (key === 'progress' && value === 'Finalised') this.setState({ modalOpen: true });
        // this.setState({ formData: { ...this.state.formData, [key]: value } });
        this.props.onEdit(key, value);

        if (key === 'cldFieldLanguageId') {
            this.changeFinding(value);
        }
    };

    changeFinding = async (languageId: string) => {
        const { formData, legalFinding } = this.props;
        const documentNumber = _.get(legalFinding, 'Document Number');
        const ltdDocId = _.get(legalFinding, 'ltdDocId');
        // const languageId = _.get(formData, 'cldFieldLanguageId');
        if (!documentNumber || !ltdDocId) return;
        const {
            data: { externalId, foundSimilar, ltdDocId: newLtdDocId },
        } = await CldDocsService.getSimilarDocByExternalId(documentNumber, ltdDocId, languageId);
        const id = await this.props.getNewFindingNumber(externalId, languageId);
        this.props.onEdit('Finding', id);
        this.props.onEdit('ID', `${externalId || documentNumber}_${id}`);
        this.props.onEdit('Document Number', externalId || documentNumber);
        this.props.onEdit('ltdDocId', newLtdDocId);
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false });
    };

    attachTooltip = (content: JSX.Element, tooltip: string = '') => {
        if (!tooltip) return content;
        return <Tooltip title={tooltip}>{content}</Tooltip>;
    };

    getProgressOptions = () => {
        const { user } = this.props;
        if (doesRoleInclude(user.roles, 'CLD_ADMIN')) {
            return [
                { label: '', key: '' },
                { label: 'Input in progress', key: 'Input in progress' },
                { label: 'Input completed', key: 'Input completed' },
                { label: 'Reviewed', key: 'Reviewed' },
                { label: 'Finalised', key: 'Finalised' },
            ];
        }
        if (doesRoleInclude(user.roles, 'CLD_SUPERVISOR')) {
            return [
                { label: '', key: '' },
                { label: 'Input in progress', key: 'Input in progress' },
                { label: 'Input completed', key: 'Input completed' },
                { label: 'Reviewed', key: 'Reviewed' },
            ];
        }
        return [
            { label: '', key: '' },
            { label: 'Input in progress', key: 'Input in progress' },
            { label: 'Input completed', key: 'Input completed' },
        ];
    };

    render() {
        const { classes, legalFinding, formErrors = {}, cldFields = [] } = this.props;
        const { formData = {} } = this.props;

        const isAdmin = _.includes(_.get(this.props.user, 'roles'), 'CLD_ADMIN');
        const INPUTS = [
            {
                label: 'Legal finding ID',
                key: 'ID',
                type: 'text',
                disabled: true,
                tooltip: 'You do not have the required privilege to edit this field. Please contact your Administrator',
            },
            // {
            //     label: 'Level of confidentiality',
            //     key: 'Confidentiality',
            //     type: 'select',
            //     options: [
            //         { label: '', key: '' },
            //         { label: 'PUBLIC', key: 'PUBLIC' },
            //     ]
            // },
            // {
            //     label: 'Situation/case',
            //     key: 'situationCase',
            //     type: 'search',
            //     suggestionUrl: '/clddocs/autocomplete',
            //     params: (text) => ({ term: text, field: ['situationCase'] }),
            //     handleSuggestions: (data: any) => _.map(data, item => item['situationCase'])
            // },
            {
                label: 'Type of document',
                key: 'Decision Type',
                type: 'select',
                options: [
                    { label: '', key: '' },
                    { label: 'Written', key: 'Written' },
                    { label: 'Oral', key: 'Oral' },
                ],
            },

            // {
            //     label: 'Document number',
            //     key: 'Document Number',
            //     type: 'search',
            //     disabled: true,
            //     suggestionUrl: '/clddocs/autocomplete',
            //     params: (text) => ({ term: text, field: ['Document Number'] }),
            //     handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
            //     tooltip: 'You do not have the required privilege to edit this field. Please contact your Administrator'
            // },
            // {
            //     label: 'Appeal relationship?',
            //     key: 'Applicable Appeal',
            //     type: 'select',
            //     options: [
            //         { label: '', key: '' },
            //         { label: 'Appeals judgment', key: 'Appeals judgment' },
            //         { label: 'Impugned decision', key: 'Impugned decision' },
            //         // { label: 'Majority Decision', key: 'Majority Decision' },
            //         // { label: 'Separate/Dissenting Opinion', key: 'SeparateDissenting Opinion' },
            //     ],
            // },

            // {
            //     label: 'Majority/Dissenting relationship',
            //     key: 'majorityDissentingRelationship',
            //     type: 'select',
            //     options: [
            //         { label: '', key: '' },
            //         { label: 'Majority', key: 'Majority' },
            //         { label: 'Dissenting', key: 'Dissenting' },
            //     ],
            // },

            // {
            //     label: 'Appeal relationship document number',
            //     key: 'Appeal Doc ID',
            //     searchKey: 'Document Number',
            //     type: 'search',
            //     suggestionUrl: '/clddocs/autocomplete',
            //     params: text => ({ term: text, field: ['Document Number'] }),
            //     handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
            // },
            // {
            //     label: 'Majority/Dissenting relationship document number',
            //     key: 'majorityDissentingRelationshipDocId',
            //     searchKey: 'Document Number',
            //     type: 'search',
            //     suggestionUrl: '/clddocs/autocomplete',
            //     params: text => ({ term: text, field: ['Document Number'] }),
            //     handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
            // },

            {
                key: 'legalFinding?',
            },
            // {
            //     label: 'Title',
            //     key: 'Title',
            //     type: 'textarea',

            // },
            {
                heading: 'ADDITIONAL INTERNAL METADATA',
                label: 'Progress',
                key: 'progress',
                type: 'select',
                // error: formErrors['progress'] || '',
                options: this.getProgressOptions(),
            },
            {
                label: 'Language',
                key: 'cldFieldLanguageId',
                type: 'select',
                options: (cldFields || []).map(f => ({ label: f.name, key: f.id })),
            },
            // {
            //     label: 'Language',
            //     key: 'ltdDocDecisionLanguageId',
            //     type: 'select',
            //     options: (cldFields || []).map(f => ({ label: f.name, key: f.id })),
            // },
            {
                label: 'Issues',
                key: 'issues',
                type: 'textarea',
            },
        ];

        return (
            <div id='metadata' className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <SectionLabel title='METADATA OF LEGAL FINDING' />
                    </div>
                    <Grid container spacing={24} className={classes.content}>
                        {INPUTS.map((input, i) => {
                            const heading = input.heading ? (
                                <Typography variant='subtitle1' className={classes.sectionHeading}>
                                    {input.heading}
                                </Typography>
                            ) : (
                                <div />
                            );

                            if (input.key === 'legalFinding?')
                                return this.attachTooltip(
                                    <Grid item md={6} key={i}>
                                        <div id={input.key} className={classes.formControl}>
                                            <Typography>Legal findings?</Typography>
                                            <div className='flex-row align-center'>
                                                <Chip
                                                    label='Yes'
                                                    color={
                                                        legalFinding && legalFinding['Legal Findings?']
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    onClick={e => this.changeValue('Legal Findings?', true)}
                                                />
                                                <Chip
                                                    className='mx-2'
                                                    color={
                                                        legalFinding && !legalFinding['Legal Findings?']
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    label='No'
                                                    onClick={e => this.changeValue('Legal Findings?', false)}
                                                />
                                                {/* <Chip label="Yes" color={legalFinding && legalFinding['Legal Findings?'] === 'Yes' ? "primary" : "default"} onClick={e => this.changeValue('Legal Findings?', "Yes")} />
                                                    <Chip className="mx-2" color={legalFinding && (legalFinding['Legal Findings?'] === 'No' || legalFinding['Legal Findings?'] === 'FALSE') ? "primary" : "default"} label="No" onClick={e => this.changeValue('Legal Findings?', 'No')} /> */}
                                            </div>
                                            <Typography color='error'>{formErrors[input.key]}</Typography>
                                        </div>
                                    </Grid>,
                                    input.tooltip
                                );

                            if (input.type === 'text')
                                return this.attachTooltip(
                                    <Grid item md={6} key={i}>
                                        {heading}
                                        <FormControl id={input.key} className={classes.formControl} key={i} fullWidth>
                                            <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                            <Input
                                                id={input.key}
                                                autoComplete='off'
                                                disabled={!!input.disabled}
                                                value={formData[input.key] || ''}
                                                name={input.key}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                        <Typography color='error'>{formErrors[input.key]}</Typography>
                                    </Grid>,
                                    input.tooltip
                                );
                            else if (input.type === 'select')
                                return this.attachTooltip(
                                    <Grid item md={6} key={i}>
                                        {heading}
                                        <FormControl id={input.key} className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                            <Select
                                                value={formData[input.key] || ''}
                                                onChange={this.handleSelect}
                                                inputProps={{ id: input.key, name: input.key }}
                                            >
                                                {input.options!.map((option, i) => (
                                                    <MenuItem key={i} value={option.key}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Typography color='error'>{formErrors[input.key]}</Typography>
                                        </FormControl>
                                    </Grid>,
                                    input.tooltip
                                );
                            else if (input.type === 'textarea')
                                return this.attachTooltip(
                                    <Grid item md={12} key={i}>
                                        {heading}
                                        <FormControl id={input.key} className={classes.formControl} key={i} fullWidth>
                                            <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                            <Input
                                                id={input.key}
                                                multiline
                                                rows={5}
                                                disableUnderline
                                                style={{
                                                    border: `1px solid ${fade(THEME.Colors.Grayscale.Grey4, 0.9)}`,
                                                    padding: 4,
                                                }}
                                                value={formData[input.key] || ''}
                                                name={input.key}
                                                onChange={this.handleChange}
                                            />
                                            <Typography color='error'>{formErrors[input.key]}</Typography>
                                        </FormControl>
                                    </Grid>,
                                    input.tooltip
                                );
                            // else if (input.type === 'search')
                            //     return this.attachTooltip(
                            //         <Grid item id={input.key} md={6} key={i}>
                            //             {heading}
                            //             <SearchWithSuggestion
                            //                 params={input.params}
                            //                 classes={{ root: classes.formControl }}
                            //                 label={input.label}
                            //                 disabled={!!input.disabled}
                            //                 formKey={input.key}
                            //                 searchKey={input.searchKey ? input.searchKey : input.key}
                            //                 value={formData[input.key] || ''}
                            //                 onChange={this.handleSuggestChange}
                            //                 handleSuggestions={input.handleSuggestions}
                            //                 suggestionUrl={input.suggestionUrl!}
                            //             />
                            //             <Typography color='error'>{formErrors[input.key]}</Typography>
                            //         </Grid>,
                            //         input.tooltip
                            //     );
                        })}
                        <Grid item md={4}>
                            <div id='Issues fixed?' className={classes.formControl}>
                                <Typography>Issues fixed?</Typography>
                                <div className='flex-row align-center'>
                                    <Chip
                                        label='Yes'
                                        color={legalFinding && legalFinding['Issues fixed?'] ? 'primary' : 'default'}
                                        onClick={e => this.changeValue('Issues fixed?', true)}
                                    />
                                    <Chip
                                        label='No'
                                        className='mx-2'
                                        color={legalFinding && !legalFinding['Issues fixed?'] ? 'primary' : 'default'}
                                        onClick={e => this.changeValue('Issues fixed?', false)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div id='OCR issues?' className={classes.formControl}>
                                <Typography>OCR issues?</Typography>
                                <div className='flex-row align-center'>
                                    <Chip
                                        label='Yes'
                                        color={legalFinding && legalFinding['OCR issues?'] ? 'primary' : 'default'}
                                        onClick={e => this.changeValue('OCR issues?', true)}
                                    />
                                    <Chip
                                        label='No'
                                        color={legalFinding && !legalFinding['OCR issues?'] ? 'primary' : 'default'}
                                        className='mx-2'
                                        onClick={e => this.changeValue('OCR issues?', false)}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Modal
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                >
                    <div className={classes.modalPaper}>
                        <Typography variant='h6' id='modal-title'>
                            Note:
                        </Typography>
                        <Typography variant='subtitle1' id='simple-modal-description'>
                            Setting this field to "Finalised" will publish the legal finding online.
                        </Typography>
                        <div className='flex-row justify-end'>
                            <Button color='primary' variant='contained' onClick={this.handleModalClose}>
                                Ok
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            padding: '40px 0px 60px 0px',
            borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: 680,
            margin: '0 auto',
        },
        formControl: {
            margin: '8px 0px',
        },
        title: {
            marginBottom: 16,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        sectionHeading: {
            fontWeight: 500,
            color: THEME.Colors.Fourth,
            margin: '8px 0px',
        },
        content: {
            background: 'white',
            padding: 30,
            maxWidth: 680,
            borderRadius: 4,
            margin: 0,
        },
        modalPaper: {
            position: 'absolute',
            width: theme.spacing.unit * 50,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing.unit * 4,
            outline: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    });

export default withStyles(STYLES)(Metadata);
