const purlRu = `<div id="content" class="content">
    <div id="breadcrumbs">
        <p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="purl/">PURL</a></p>
    </div>
    <div id="getUrlText">
        <div
            style="cursor:pointer;float:right;"
            title="Collapse tree"
            id="collapseTree"
        >
            <i class="glyphicon glyphicon-collapse-down"></i>
        </div>
        <a href="javascript:window.print();" style="float:right;"
            ><i class="glyphicon glyphicon-print"></i
        ></a>
    </div>
    <br style="clear:both;" />
    <!--  CONTENT ELEMENT, uid:147/text [begin] -->
    <div id="c147" class="csc-default">
        <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
            <h1 class="csc-firstHeader">Постоянные URL-адреса (PURL)</h1>
        </div>
        <!--  Header: [end] -->

        <!--  Text: [begin] -->
        <p class="bodytext">
        База данных Юридических инструментов МУС (LTD) использует постоянные URL-адреса (PURL). Это означает, что URL-адрес или интернет-адрес каждого документа, содержащегося в LTD, является постоянным и не подлежит изменению. Другими словами, как пользователь, вы можете ссылаться на документы в LTD, включая их PURL-адрес в свой текст (или его примечания). Если вы сделаете PURL-адрес гиперссылкой на рассматриваемый документ, то ссылка никогда не будет нарушена проектом Юридических инструментов.
        </p>
        <p class="bodytext">
            Используя такие PURL-адреса с гиперссылками на юридические документы в бесплатной общедоступной LTD, вы помогаете создавать постоянно растущее общее пространство юридических ссылок и источников. Являясь новым общественным достоянием в международном уголовном праве, LTD уравнивает доступ к правовой информации для всех специалистов практикующих в этой области права. Таким образом, проект Юридические инструменты способствует развитию потенциала, особенно на национальном уровне.
        </p>
        <p class="bodytext">
          30 мая 2012 года Апелляционная палата МУС начала использовать в своих решениях LTD PURL-адреса. <a href="https://www.legal-tools.org/en/doc/9813bb/" target="_blank">Решение суда по делу Катанга от 7 марта 2014 г.</a>
          содержит гиперссылки на 538 юридических источников в LTD. С тех пор такие гиперссылки используются во многих решениях МУС, и в 10-летнем Соглашении между МУС и Центром исследований и политики в области международного права (CILRAP, который управляет LTD для МУС) от марта 2021 года Суд обязуется «давать гиперссылки на свои решения и другие соответствующие публичные постановления и документы с постоянными URL-адресами LTD», насколько это возможно. Проект Юридические инструменты призывает суды, правительства, неправительственные организации, ученых, издателей и других лиц включать ссылки на PURL-адреса документов с гиперссылками в LTD, насколько это возможно.
        </p>
        <ul>
            <li>
                <a
                    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/161111_LTD_leaflet__Fourth_Edition.pdf"
                    target="_blank"
                    >
                    161116 Создание общего достояния юридических источников в международном уголовном праве, четвертое издание
                    </a
                >
            </li>
        </ul>
        <!--  Text: [end] -->
    </div>
    <!--  CONTENT ELEMENT, uid:147/text [end] -->
</div>`;

export default purlRu;
