import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { Redirect } from 'react-router';
import _ from 'lodash';
import { TUser } from '../../Features/Authentication/@types';
import utilities from '../../Resources/Utils';


interface IStateProps {
    user: TUser
}

interface IDispatchProps { }

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class Home extends Component<IProps, IState> {

    render() {
        const { classes, user } = this.props;

        return (
            <Redirect to="/ltd-dashboard/tree-management" />
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    user: _.get(state.Auth, 'user')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({

})

const STYLES = (theme: Theme) => createStyles({

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Home))