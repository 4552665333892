import React, { Component } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/ebene.svg';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { FOOTER, FOOTER_POST_TERMS_USE, FOOTER_TERMS_USE } from '../../Features/Translations/translationKeys.ltd';

interface IProps extends WithStyles<typeof STYLES> {}

class LTDFooter extends Component<IProps & WithTranslationProps> {
    render() {
        const { classes, getTranslation } = this.props;
        return (
            <div className={classes.root}>
                <img src={LOGO} width={90} />
                <Typography variant='caption'>
                    <span>{getTranslation(FOOTER)}&nbsp;</span>
                    <Link to='/footer/terms-of-use'>{getTranslation(FOOTER_TERMS_USE)}</Link>
                    <span>{getTranslation(FOOTER_POST_TERMS_USE)}</span>
                    {/* The Legal Tools Database has been made with the assistance of the European Union. The contents of the Database does not necessarily reflect the views of the European Union. */}
                    {/* For the responsibility of the International Criminal Court and other user information, please see the <Link to="/footer/terms-of-use">Terms of use</Link>. */}
                </Typography>
            </div>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            margin: '40px 20px 0px 20px',
            padding: '20px 40px',
            borderTop: `1px dashed ${fade('#000', 0.2)}`,

            '& img': {
                marginRight: 10,
            },
            '& span': {
                fontWeight: 500,
                fontSize: 10,
                color: THEME.Colors.Grayscale.Grey1,
                [theme.breakpoints.down('sm')]: {
                    textAlign: 'justify',
                    margin: '4px 0px',
                },
            },
            [theme.breakpoints.down('sm')]: {
                padding: '20px 12px',
                flexDirection: 'column',
                margin: '0px 0px 60px 0px',
            },
        },
    });

export default withStyles(STYLES)(withTranslations(LTDFooter));
