import { TAction, Models } from "../../Resources/Model";
import { Reducer } from "redux";
import _ from 'lodash';
import { CldField } from "../../Services/CldFields.types";


export interface TLtdFieldState {
    ltdFields: CldField[],
    groupedLtdFields: Record<string, CldField[]>,
}



export class LtdFieldClass extends Models {
    constructor() {
        super('cldField', {
            SET_LTD_FIELDS: 'SET_LTD_FIELDS',
            ADD_LTD_FIELD: 'ADD_LTD_FIELD',
            UPDATE_LTD_FIELD: 'UPDATE_LTD_FIELD',
            DELETE_LTD_FIELD: 'DELETE_LTD_FIELD',
        })
    }
}



export const OLtdField = new LtdFieldClass();


const initalState: TLtdFieldState = {
    groupedLtdFields: {},
    ltdFields: []

}

export const LtdFieldsReducer: Reducer<TLtdFieldState> = (state = initalState, action: TAction): TLtdFieldState => {
    const {
        SET_LTD_FIELDS,
    } = OLtdField.actions;


    switch (action.type) {
        case SET_LTD_FIELDS: {
            return { ...state, ltdFields: action.data, groupedLtdFields: _.groupBy(action.data, 'type') }
        }
        default: return state;
    }
}