import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Dialog, DialogContent, Slide, Fade, Zoom, Grow } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import Login from '../Features/Authentication/Login';
import Signup from '../Features/Authentication/Signup';
import { OAuth } from '../Features/Authentication/redux-config';
import { TDialogVariant } from '../Features/Authentication/@types';
import { TState } from '../RootReducer';
import ForgotPassword from '../Features/Authentication/ForgotPassword';
import { IS_MOBILE } from '../App';

interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
}

interface IStateProps {
    authDialog: {
        open?: boolean,
        variant: TDialogVariant
    }
}


interface IDispatchProps {
    closeDialog?: Function
}


function Transition(props) {
    return <Slide direction="left" style={{ transformOrigin: '100% 0% 0' }} in={true} {...props} timeout={380} />;
}


class AuthDialog extends Component<Props> {

    render() {
        const { classes, authDialog } = this.props;
        const variant = _.get(authDialog, 'variant') || undefined;

        const isMobile = IS_MOBILE

        return (
            <Dialog
                fullScreen={isMobile}
                // fullWidth={isMobile}
                TransitionComponent={Transition}
                open={_.get(authDialog, 'open') || false}
                onClose={e => this.props.closeDialog!()}
                PaperProps={{ className: classes.root, }}
                classes={{ paperScrollBody: classes.scrollBodyContainer, paperWidthSm: classes.paperWidthSm }}
                scroll={isMobile ? "paper" : "body"}
            >
                {
                    variant === 'login' &&
                    <Login />
                }
                {
                    variant === 'signup' &&
                    <Signup />
                }
                {
                    variant === 'forgotPassword' &&
                    <ForgotPassword />
                }
            </Dialog>
        )
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Auth, 'authDialog')
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OAuth.closeAuthDialog())
})

const STYLES = theme => createStyles({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
    },
    scrollBodyContainer: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    paperWidthSm: {
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(AuthDialog))