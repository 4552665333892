import utilities from '../Resources/Utils';

const OUTCOME_OF_TRIAL = [
    {
        type: 'trialOutcome',
        name: 'Acquittal (full)',
        id: 'acquittal_full',
        name__en: 'Acquittal (full)',
        name__fr: 'Acquittement (de tous les chefs)',
        name__es: 'Absolución (total)',
        name__zh: '无罪（完全）',
        name__ru: 'Оправдательный приговор (полный)',
    },
    {
        type: 'trialOutcome',
        name: 'Conviction (full)',
        id: 'acquittal_full',
        name__en: 'Conviction (full)',
        name__fr: 'Condamnation (totale)',
        name__es: 'Condena (total)',
        name__zh: '有罪（完全）',
        name__ru: 'Обвинительный приговор (полный)',
    },
    {
        type: 'trialOutcome',
        name: 'Conviction (partial)',
        id: 'conviction_full',
        name__en: 'Conviction (partial)',
        name__fr: 'Condamnation (partielle)',
        name__es: 'Condena (parcial)',
        name__zh: '有罪（部分）',
        name__ru: 'Обвинительный приговор (частичный)',
    },
    {
        type: 'trialOutcome',
        name: 'New trial ordered',
        id: 'new_trial_ordered',
        name__en: 'New trial ordered',
        name__fr: 'Nouveau procès ordonné',
        name__es: 'Orden de celebración de un nuevo juicio',
        name__zh: '命令重审',
        name__ru: 'Назначено новое судебное разбирательство',
    },
    {
        type: 'trialOutcome',
        name: 'Revision allowed',
        id: 'revision_allowed',
        name__en: 'Revision allowed',
        name__fr: 'Révision autorisée',
        name__es: 'Revisión autorizada',
        name__zh: '允许修改',
        name__ru: 'Пересмотр разрешен',
    },
    {
        type: 'trialOutcome',
        name: 'Other',
        id: 'other',
        name__en: 'Other',
        name__fr: 'Autre',
        name__es: 'Otro',
        name__zh: '其他',
        name__ru: 'Другое',
    },
    {
        type: 'trialOutcome',
        name: 'Not available',
        id: 'not_available',
        name__en: 'Not available',
        name__fr: 'Non communiqué',
        name__es: 'No está disponible',
        name__zh: '不存在',
        name__ru: 'Недоступно',
    },
    {
        type: 'trialOutcome',
        name: 'Not applicable',
        id: 'not_applicable',
        name__en: 'Not applicable',
        name__fr: 'Sans objet',
        name__es: 'No procede',
        name__zh: '不适用',
        name__ru: 'Неприменимо',
    },
    {
        type: 'trialOutcome',
        name: 'To be determined',
        id: 'to_be_determined',
        name__en: 'To be determined',
        name__fr: 'À déterminer',
        name__es: 'Por determinar',
        name__zh: '待定',
        name__ru: 'Будет определено',
    },
];

export class LtdOutComeService {
    static getLtdFieldsForType(type: string) {
        return OUTCOME_OF_TRIAL.filter(ltdField => ltdField.type === type);
    }

    static resolveLtdField(id: string) {
        const field = OUTCOME_OF_TRIAL.find(ltdField => ltdField.id === id);
        const locale = utilities.getLocale();
        if (field) return `${field[`name__${locale}`]}`;
        return id;
    }
}
