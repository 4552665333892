import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, ClickAwayListener, Paper, List, ListItem, Typography, FormControl, Input, Button, InputLabel } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames';
import utilities from '../../Resources/Utils';
import THEME from '../../Resources/Theme';
import Axios, { CancelTokenSource } from 'axios';




interface Props extends WithStyles<typeof STYLES> {
    disabled?: boolean
    disableSearch?: boolean
    suggestionUrl: string
    label: string
    placeholder?: string
    onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
    searchKey?: string
    errorText?: string
    onClear?: () => void
    formKey: string
    value?: string
    InnerComponentProps?: {
        InputLabelProps?: any

    }
    onChange: ({ name: string, value: any }) => void
    onSelect?: ({ name: string, value: any }) => void
    filter?: (value: string) => any
    handleSuggestions?: (data: any) => Array<any>
    params?: (value: string) => any
}


interface IOwnProps {
    currentTarget: null | string
    suggestions: Array<any>
    formData: any
}

const RESULT_NOT_FOUND = 'No Results found'
class SearchWithSuggestion extends Component<Props, IOwnProps> {
    state: IOwnProps = {
        currentTarget: null,
        suggestions: [],
        formData: {
        }
    }

    inputRef: HTMLInputElement | null = null;
    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;


    componentDidMount() {
        this.setState({ formData: { [this.props.formKey]: this.props.value || '' } })
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ formData: { [this.props.formKey]: this.props.value || '' } })
        }
    }

    getSuggestions = async (e) => {
        const { disableSearch = false } = this.props;
        const newValue = e.target.value;
        this.setState({ currentTarget: e.target.name });

        if (disableSearch) {
            if (_.isFunction(this.props.handleSuggestions)) {
                const suggestions = this.props.handleSuggestions(newValue);
                this.setState({ suggestions: (suggestions || []).length > 0 ? suggestions : [RESULT_NOT_FOUND] });
            }
            return;
        }

        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            // Clearing out the suggestions list if the input is cleared
            this.setState({ suggestions: [] });
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url: this.props.suggestionUrl,
                params: this.props.params ? this.props.params(newValue) : {
                    filter: {
                        match: {
                            [this.props.searchKey || e.target.name]: e.target.value
                        }
                    }
                },
                cancelToken: this.ajaxCallHandle.token
            });

            let suggestions = res.data

            if (_.isFunction(this.props.handleSuggestions))
                suggestions = this.props.handleSuggestions(res.data)

            this.setState({ suggestions: (suggestions || []).length > 0 ? suggestions : [RESULT_NOT_FOUND] });

        } catch (error) {
        }
    }



    // getSuggestions = (e) => {
    //     this.setState({ currentTarget: e.target.name });

    //     const url = this.props.suggestionUrl;
    //     let filter = {
    //         match: {
    //             [this.props.searchKey || e.target.name]: e.target.value
    //         }
    //     }

    //     if (this.props.filter)
    //         filter = this.props.filter(e.target.value)

    //     utilities.request({
    //         url,
    //         params: { filter }
    //     }).then(
    //         res => {
    //             let suggestions = res.data;
    //             if (_.isFunction(this.props.handleSuggestions))
    //                 suggestions = this.props.handleSuggestions(res.data)

    //             this.setState({ suggestions });
    //         }

    //     )
    // }

    closeSuggestion = () => {
        this.setState({ currentTarget: null });
    }


    onSelectSuggestion = (suggestion: string) => {
        if (suggestion === RESULT_NOT_FOUND) return;
        const { formKey } = this.props;
        this.setState({
            currentTarget: null, formData: { ...this.state.formData, [formKey]: suggestion },
        })
        this.props.onChange({ name: formKey, value: suggestion });
        this.props.onSelect && this.props.onSelect({ name: formKey, value: suggestion });
    }


    getSuggestionList = () => {
        const { classes, formKey } = this.props;
        const { suggestions = [] } = this.state;
        return (
            <ClickAwayListener onClickAway={this.closeSuggestion}>
                <Paper className={classes.suggestions}>
                    <List>
                        {
                            _.map(suggestions, suggestion => (
                                <ListItem key={_.uniqueId('suggestions-')} className={classes.suggestionItem} dense onClick={e => this.onSelectSuggestion(suggestion)}>
                                    {suggestion}
                                </ListItem>
                            ))
                        }
                    </List>
                    <div className={classes.clearSuggestion} onClick={this.closeSuggestion}>
                        <Typography>CLOSE SUGGESTIONS</Typography>
                    </div>
                </Paper>
            </ClickAwayListener>
        )
    }



    // handleTextChange = async (newValue: string) => {
    //     await this.setState({ value: newValue });
    //     this.timeoutHandle && clearTimeout(this.timeoutHandle);
    //     if (!newValue) {
    //         // Clearing out the suggestions list if the input is cleared
    //         this.onSearchResults([], '');
    //         return;
    //     }
    //     await this.setState({ loading: true });
    //     this.timeoutHandle = setTimeout(async () => {
    //         /**
    //          * @const this.ajaxCallHandle`
    //          * contains the token for previos request. If the susequent request is made
    //          * so the previous request with that token will be cancelled
    //          */
    //     }, this.REQUEST_DELAY);
    //     if (this.ajaxCallHandle) {
    //         this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
    //     }
    //     this.ajaxCallHandle = Axios.CancelToken.source();
    //     const res = await Axios.request({
    //         url: `users/autocomplete`,
    //         params: {
    //             term: newValue,
    //             filter: {
    //                 doc_type: 'user',
    //                 limit: 30,
    //             }
    //         }
    //     }).catch(error => { console.log('AutoSuggest Error', error); this.setState({ loading: false }); throw error });
    //     await this.setState({ loading: false });
    //     this.onSearchResults(res.data, newValue);
    // }


    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
        this.props.onChange({ name: e.target.name, value: e.target.value });
        this.getSuggestions(e)
    }


    clear = (e) => {
        if (this.props.disabled)
            return;
        if (this.props.onBlur && this.inputRef) {
            this.inputRef.focus()
        }
        this.setState({ formData: { ...this.state.formData, [this.props.formKey]: '' } });
        this.props.onChange({ name: this.props.formKey, value: '' })
        this.props.onClear && this.props.onClear();
    }


    render() {
        const { classes, formKey, label, placeholder, disabled = false, InnerComponentProps } = this.props;
        const InputLabelProps = InnerComponentProps ? InnerComponentProps.InputLabelProps : undefined;
        const { formData, suggestions, currentTarget } = this.state;

        return (
            <FormControl className={classes.root} fullWidth>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor={formKey} {...InputLabelProps}>{label}</InputLabel>
                    <Input
                        inputRef={ref => this.inputRef = ref}
                        autoComplete="off"
                        disabled={disabled}
                        id={formKey}
                        onBlur={this.props.onBlur}
                        placeholder={placeholder || ''}
                        name={formKey}
                        onChange={this.handleChange}
                        value={_.get(formData, formKey) || ''}
                    />
                    {
                        !_.isEmpty(_.get(formData, formKey)) ?
                            <Button disableRipple variant="text" className={classes.clearField} onClick={this.clear}>
                                <i className="material-icons">close</i>
                            </Button> :
                            <Button disableRipple variant="text" className={classes.clearField}>
                                <i className="material-icons">search</i>
                            </Button>

                    }
                </div>
                <Typography color="error">{this.props.errorText}</Typography>

                {
                    !_.isEmpty(suggestions) && currentTarget === formKey &&
                    this.getSuggestionList()
                }

            </FormControl>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = theme => createStyles({
    filterInput: {
        position: 'relative',
        padding: '0px 10px',
        fontSize: 14,
    },
    inputLabel: {
        padding: '0px 10px',
        fontWeight: 500
    },
    label: {
        width: '80%',
        top: -8
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&>div': {
            width: '100%',
            paddingRight: 50
        }
    },
    root: {
        // background: THEME.Colors.Grayscale.Grey5,
        // marginBottom: 14,
        // height: 40,
        // display: 'flex',
        // justifyContent: 'center',
        // '& > div > p': {
        //     position: 'absolute',
        //     bottom: -18
        // },
        '& button': {
            position: 'absolute',
            right: 20
        }
    },
    clearField: {
        position: 'absolute',
        right: 0,
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        height: 'auto',
        background: 'gray',
        borderRadius: '50%',
        '&:hover': {
            cursor: 'auto',
            background: 'gray',
        },
        '& i': {
            color: 'white',
            fontSize: 14,
            padding: 4,
        }
    },
    suggestions: {
        position: 'absolute',
        top: 40,
        zIndex: 1000,
        width: 'inherit',
        maxHeight: 300,
        overflow: 'auto',
    },
    clearSuggestion: {
        cursor: 'pointer',
        position: 'sticky',
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: THEME.Colors.Grayscale.Grey4,
        bottom: 0,
        left: 0,
        right: 0
    },
    suggestionItem: {
        cursor: 'pointer',
        fontSize: 12,
        transition: '240ms ease-in',
        '&:hover': {
            background: THEME.Colors.Fourth,
            color: 'white'
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(SearchWithSuggestion))