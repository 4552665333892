const projectBackgroundEs = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
    <div id="c258" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Antecedentes del proyecto</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p  class="bodytext">
        En su inicio, fue la Sección de Asesoría Jurídica de la Fiscalía de la CPI quien desarrolló las Herramientas Jurídicas de la CPI entre 2003 y 2005; a partir de 2006 ha dirigido su desarrollo el Director del Centre for International Law Research and Policy 
          (<a href="https://www.cilrap.org/purpose/" target="_blank">CILRAP</a>),&nbsp;
          <a href="https://www.cilrap.org/bergsmo/"> Morten Bergsmo</a> 
          (quien concibió y supervisó el Proyecto y sus distintos componentes en su capacidad de Jefe de la Sección de Asesoría Jurídica entre 2003 y 2005, y posteriormente en calidad de Coordinador del Proyecto)..
             </p>
        <p class="bodytext">
        En 2005 se creó un Grupo Consultivo de Expertos sobre las Herramientas Jurídicas de la CPI (el  LTEAG), que contaba con destacados expertos en informática jurídica, para que prestara servicios de asesoría y control de propuestas en relación con el desarrollo técnico y estratégico posterior de las Herramientas Jurídicas. Los miembros del Grupo son: 
          <br/> Prof. 
          <a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> 
          (Noruega); Prof.  <a href="http://herberger.jura.uni-sb.de/herberger/" target="_blank">
          Maximilian Herberger</a>,  Universidad de Saarland (Alemania);
          Prof. Anja Oskamp, Universidad Libre de Ámsterdam (Países Bajos);
          Prof. <a href="https://www.gu.se/en/about/find-staff/ulfpetrusson" target="_blank">
          Ulf Petrusson</a> (Universdad de Gotenburgo);  
          Prof. <a href="https://people.cs.kuleuven.be/~sien.moens/" target="_blank">
          Marie-Francine (Sien) Moens</a>, 
            Universidad Católica de Lovaina (Bélgica);  Prof. Richard V. De Mulder, Universidad Erasmus de Róterdam (Países Bajos); y Professor
            <a href="https://www.eui.eu/people?id=giovanni-sartor" target="_blank">
            Giovanni Sartor</a>,
            Instituto Universitario Europeo  (Italia). El difunto Prof. <a href="https://en.wikipedia.org/wiki/Jon_Bing" target="_blank">Jon Bing</a> actuó g como primer Presidente del Grupo. La reunión inaugural del Grupo tuvo lugar en la sede provisional de la Corte en La Haya, el 7 de diciembre de 2005.
              </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
    </div>`;
export default projectBackgroundEs;
