import { AxiosRequestConfig } from "axios";
import { TUser } from "../Features/Authentication/@types";
import { SearchResponse } from "../Models/global.types";
import utilities from "../Resources/Utils";


class UserService {
    static search(searchTerm: string, filter?: Record<string, any>, axiosConfig?: AxiosRequestConfig) {
        return utilities.request<SearchResponse<TUser>>({
            ...axiosConfig,
            url: `/users/search`,
            params: {
                term: searchTerm,
                filter: {
                    "include": [{ "relation": "roles", "scope": ["name"] }],
                    ...filter,
                }
            },

        })
    }

    static updateRole(action: 'add' | 'remove', ids: string[], role: string, axiosConfig?: AxiosRequestConfig) {
        return utilities.request<TUser>({
            ...axiosConfig,
            url: `/users/role/${action}`,
            method: 'POST',
            data: {
                ids,
                role
            }
        })
    }
}

export default UserService