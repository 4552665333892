import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Fab, CircularProgress, LinearProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import _ from 'lodash';
import THEME from '../../../Resources/Theme';
import { TSearchResult, TDocument } from '../../../Features/LTDDocuments/@types';
import ListItem from '../../../Components/LTDList/ListItem';
import { OApp } from '../../../RootReducer/AppReducer';
import { TNode } from '../../../Features/DocumentsTree/@types';
import LTDMetadata from '../../DetailPage/LTDMetadata';


interface IStateProps {
    document: TDocument
    title: string
}

interface IDispatchProps {
    closeSidebar?: () => void
    openDocument?: (document: TDocument) => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
    onBack?: () => void
}

class DocumentDetail extends Component<IProps, IState> {


    handleBackButton = () => {
        if (!this.props.onBack)
            return;
        this.props.onBack();
    }

    closeSidebar = () => {
        if (this.props.closeSidebar)
            this.props.closeSidebar();
    }

    render() {
        const { classes, document, title } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div>
                        <Fab size="small" onClick={this.handleBackButton}><i className="material-icons">chevron_left</i></Fab>
                        <Typography>{_.truncate(title, { length: 60 })}</Typography>
                    </div>
                    <Fab size="small" onClick={this.closeSidebar}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
                <div className={classes.listContainer}>
                    {
                        document ?
                            <LTDMetadata classes={{ root: classes.metadataContainer }} data={document} /> :
                            <LinearProgress color="secondary" />
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    document: _.get(state.DetailPage, 'currentDocument.document'),
    title: _.get(state.App, 'sidebar.title')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    closeSidebar: () => dispatch(OApp.closeSidebar())
})


const CONTAINER_WIDTH = 520;

const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box',
        background: THEME.Colors.Grayscale.Grey5
    },
    header: {
        position: 'fixed',
        width: CONTAINER_WIDTH,
        right: 0,
        display: 'flex',
        height: 51,
        zIndex: 1,
        alignItems: 'center',
        '& p': {
            color: 'white'
        },
        background: theme.palette.grey['100'],
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    listContainer: {
        marginTop: 51,
        // padding: '20px 40px',

    },
    metadataContainer: {
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DocumentDetail))