import { IFormConfig } from '../FormGroup';

export type TLtdDocFormFieldKey =
    | 'contentType'
    | 'externalId'
    | 'title'
    | 'title_en'
    | 'title_fr'
    | 'shortTitle'
    | 'shortTitle_en'
    | 'shortTitle_fr'
    | 'source'
    | 'sourceTypeIds'
    | 'organisation'
    | 'languageIds'
    | 'isOriginalLanguage'
    | 'isAuthoritativeLanguage'
    | 'dateCreated'
    | 'approximateDate'
    | 'expiryDate'
    | 'entryIntoForce'
    | 'sourceUrl'
    | 'abstract'
    | 'notes'
    | 'author'
    | 'publisher'
    | 'confidentiality'
    | 'importance'
    | 'copyRightAuthorisation'
    | 'disableIndexing'
    | 'preparatoryPhase'
    | 'resourceCitation_en'
    | 'resourceCitation_fr'
    | 'keywordIds'
    | 'ifri_internal'
    | 'Subject'
    | 'Subject (Level 2)'
    | 'Subject (Level 3)'
    | 'Subject (Level 4)'
    | 'isCourtRecord'
    | 'iccSituationIds'
    | 'interlocutory'
    | 'caseName'
    | 'caseName_en'
    | 'caseName_fr'
    | 'alternative'
    | 'alternative_en'
    | 'alternative_fr'
    | 'caseNumber'
    | 'relatedCases'
    | 'judicialDocumentIds'
    | 'trailOutcome'
    | 'phaseOfCaseIds'
    | 'judicialDocumentType'
    | 'chamberComposition'
    | 'presidingJudge'
    | 'placeCourt'
    | 'courtType'
    | 'participatingStates'
    | 'victimParticipation'
    | 'accusedDefendant'
    | 'Alias of accused’s name'
    | 'prosecutorClaimant'
    | 'counselForDefence'
    | 'victimsCounsel'
    | 'amicusCuriae'
    | 'accessDate'
    | 'numberOfPages'
    | 'documentType'
    | 'responsiblePartner'
    | 'Metadata collected by'
    | 'Metadata finalised on'
    | 'publishDate'
    | 'registerDate'
    | 'orignalPdfURL'
    | 'Display as'
    | 'documentOrigin.source'
    | 'documentOrigin.url'
    | 'relatedOrganisation'
    | 'orgaisationJudicialBody'
    | 'relatedDocIds'
    | 'relatedPurls'
    | 'ICC case(s) cited'
    | 'Legislation cited'
    | 'fileUrl'
    | 'isPublished';

export const LTD_DOCUMENT_FORM_FIELDS: Array<{ label: string; key: TLtdDocFormFieldKey }> = [
    // Basic information
    { label: 'Content Type', key: 'contentType' },
    { label: 'External identifier', key: 'externalId' },
    { label: 'Title', key: 'title' },
    { label: 'Translated title (EN)', key: 'title_en' },
    { label: 'Translated title (FR)', key: 'title_fr' },
    { label: 'Short title', key: 'shortTitle' },
    { label: 'Short title (EN)', key: 'shortTitle_en' },
    { label: 'Short title (FR)', key: 'shortTitle_fr' },
    { label: 'Source', key: 'source' },
    { label: 'Source type', key: 'sourceTypeIds' },
    { label: 'Organisation / State of Source', key: 'organisation' },
    { label: 'Language(s)', key: 'languageIds' },
    { label: 'Original language', key: 'isOriginalLanguage' },
    { label: 'Authoritative language', key: 'isAuthoritativeLanguage' },
    { label: 'Date created', key: 'dateCreated' },
    { label: 'Approximate date', key: 'approximateDate' },
    { label: 'Date of expiry', key: 'expiryDate' },
    { label: 'Entry into force', key: 'entryIntoForce' },
    { label: 'Source URL', key: 'sourceUrl' },
    { label: 'Abstract', key: 'abstract' },
    { label: 'Notes', key: 'notes' },

    // Bibliography
    { label: 'Author/Editor', key: 'author' },
    { label: 'Publisher', key: 'publisher' },
    { label: 'Level of confidentiality', key: 'confidentiality' },
    { label: 'Importance', key: 'importance' },
    { label: 'Copyright authorisation', key: 'copyRightAuthorisation' },
    { label: 'Exclude from search engine indexation', key: 'disableIndexing' },
    { label: 'Subject', key: 'Subject' },
    { label: 'Subject (Level 2)', key: 'Subject (Level 2)' },
    { label: 'Subject (Level 3)', key: 'Subject (Level 3)' },
    { label: 'Subject (Level 4)', key: 'Subject (Level 4)' },
    { label: 'Phase of preparatory works', key: 'preparatoryPhase' },
    { label: 'Resource citation (English)', key: 'resourceCitation_en' },
    { label: 'Resource citation (French)', key: 'resourceCitation_fr' },
    { label: 'Keywords', key: 'keywordIds' },
    { label: 'IfRI-Internal', key: 'ifri_internal' },

    // ICC Documents
    { label: 'Is court record', key: 'isCourtRecord' },
    { label: 'ICC situation name', key: 'iccSituationIds' },
    { label: 'Interlocutory appeal no.', key: 'interlocutory' },

    // Judicial Documents
    { label: 'Case name (ICC naming convention)', key: 'caseName' },
    { label: 'Case name (ICC naming convention - EN)', key: 'caseName_en' },
    { label: 'Case name (ICC naming convention - FR)', key: 'caseName_fr' },
    { label: 'Alternative and / or short case title', key: 'alternative' },
    { label: 'Alternative and / or short case title (EN)', key: 'alternative_en' },
    { label: 'Alternative and / or short case title (FR)', key: 'alternative_fr' },
    { label: 'Case/Document number', key: 'caseNumber' },
    { label: 'Related case number', key: 'relatedCases' },
    { label: 'Key judicial document', key: 'judicialDocumentIds' },
    { label: 'Outcome of trial', key: 'trailOutcome' },
    { label: 'Phase of case', key: 'phaseOfCaseIds' },
    { label: 'Type of judicial document', key: 'judicialDocumentType' },
    { label: 'Composition of chamber', key: 'chamberComposition' },
    { label: 'Presiding judge', key: 'presidingJudge' },
    { label: 'Place of court', key: 'placeCourt' },
    { label: 'Type of court', key: 'courtType' },
    { label: 'Participating states', key: 'participatingStates' },
    { label: 'Victims participation', key: 'victimParticipation' },
    { label: 'Accused / Defendant', key: 'accusedDefendant' },
    { label: 'Alias of accused’s name', key: 'Alias of accused’s name' },
    { label: 'Prosecutor’s team / Claimant', key: 'prosecutorClaimant' },
    { label: 'Counsel for defence', key: 'counselForDefence' },
    { label: 'Counsel for victims', key: 'victimsCounsel' },
    { label: 'Amicus curiae', key: 'amicusCuriae' },

    // Quality control
    { label: 'Date accessed/ downloaded', key: 'accessDate' },
    { label: 'Number of pages', key: 'numberOfPages' },
    { label: 'Document type', key: 'documentType' },
    { label: 'Responsible partner', key: 'responsiblePartner' },
    { label: 'Metadata collected by', key: 'Metadata collected by' },
    { label: 'Metadata finalised on', key: 'Metadata finalised on' },
    { label: 'Date published in the Legal Tools', key: 'publishDate' },
    { label: 'Date registered', key: 'registerDate' },
    { label: 'Resource / File', key: 'orignalPdfURL' },
    { label: 'Display as', key: 'Display as' },
    { label: 'Origin', key: 'documentOrigin.source' },
    { label: 'URL', key: 'documentOrigin.url' },
    { label: 'File URL', key: 'fileUrl' },

    // Relations
    { label: 'Legislation cited', key: 'Legislation cited' },
    { label: 'ICC case(s) cited', key: 'ICC case(s) cited' },
    { label: 'Related resource', key: 'relatedDocIds' },
    { label: 'Related Docs purls', key: 'relatedPurls' },
    { label: 'Organisation/ State of judicial body', key: 'orgaisationJudicialBody' },
    { label: 'Related organisation/ State', key: 'relatedOrganisation' },
    //extras
    { label: 'Is Published', key: 'isPublished' },
];

export const FORM_FIELD_INPUT_TYPE_MAPPING: Record<TLtdDocFormFieldKey, IFormConfig['type']> = {
    contentType: 'select',
    externalId: 'text',
    title: 'multiline',
    title_en: 'multiline',
    title_fr: 'multiline',
    shortTitle: 'multiline',
    shortTitle_en: 'multiline',
    shortTitle_fr: 'multiline',
    source: 'component',
    sourceTypeIds: 'multiselect',
    organisation: 'component',
    languageIds: 'multiselect',
    isOriginalLanguage: 'component',
    abstract: 'multiline',
    approximateDate: 'date',
    dateCreated: 'date',
    expiryDate: 'date',
    entryIntoForce: 'date',
    isAuthoritativeLanguage: 'component',
    notes: 'multiline',
    sourceUrl: 'text',

    author: 'multiline',
    publisher: 'multiline',
    confidentiality: 'select',
    importance: 'select',
    copyRightAuthorisation: 'component',
    disableIndexing: 'component',
    preparatoryPhase: 'select',
    resourceCitation_en: 'multiline',
    resourceCitation_fr: 'multiline',
    keywordIds: 'component',
    ifri_internal: 'multiline',
    Subject: 'select',
    'Subject (Level 2)': 'select',
    'Subject (Level 3)': 'select',
    'Subject (Level 4)': 'select',

    isCourtRecord: 'component',
    iccSituationIds: 'select',
    interlocutory: 'multiline',

    'Alias of accused’s name': 'multiline',
    accusedDefendant: 'multiline',
    alternative: 'multiline',
    alternative_en: 'multiline',
    alternative_fr: 'multiline',
    amicusCuriae: 'multiline',
    caseName: 'multiline',
    caseName_en: 'multiline',
    caseName_fr: 'multiline',
    caseNumber: 'multiline',
    chamberComposition: 'multiline',
    counselForDefence: 'multiline',
    courtType: 'select',
    judicialDocumentIds: 'multiselect',
    judicialDocumentType: 'select',
    participatingStates: 'component',
    phaseOfCaseIds: 'multiselect',
    placeCourt: 'multiline',
    presidingJudge: 'multiline',
    prosecutorClaimant: 'multiline',
    relatedCases: 'multiline',
    trailOutcome: 'select',
    victimParticipation: 'component',
    victimsCounsel: 'multiline',

    'Display as': 'multiline',
    'Metadata collected by': 'text',
    'Metadata finalised on': 'date',
    orignalPdfURL: 'component',
    'documentOrigin.source': 'select',
    'documentOrigin.url': 'text',
    accessDate: 'date',
    documentType: 'select',
    numberOfPages: 'text',
    publishDate: 'date',
    registerDate: 'date',
    responsiblePartner: 'select',

    'ICC case(s) cited': 'multiline',
    'Legislation cited': 'multiline',
    orgaisationJudicialBody: 'component',
    relatedDocIds: 'component',
    relatedPurls: 'text',
    fileUrl: 'text',
    relatedOrganisation: 'component',

    isPublished: 'component',
};

export const LTD_DOCUMENT_SECTIONS: Record<string, { label: string; fields: TLtdDocFormFieldKey[] }> = {
    basicInfo: {
        label: 'BASIC INFO',
        fields: [
            'contentType',
            'externalId',
            'title',
            'title_en',
            'title_fr',
            'shortTitle',
            'shortTitle_en',
            'shortTitle_fr',
            'source',
            'sourceTypeIds',
            'organisation',
            'languageIds',
            'isOriginalLanguage',
            'isAuthoritativeLanguage',
            'dateCreated',
            'approximateDate',
            'expiryDate',
            'entryIntoForce',
            'sourceUrl',
            'abstract',
            'notes',
        ],
    },
    relations: {
        label: 'RELATIONS',
        fields: [
            'Legislation cited',
            'ICC case(s) cited',
            'relatedDocIds',
            'orgaisationJudicialBody',
            'relatedOrganisation',
        ],
    },
    judicialDocuments: {
        label: 'JUDICIAL DOCUMENTS',
        fields: [
            'caseName',
            'caseName_en',
            'caseName_fr',
            'alternative',
            'alternative_en',
            'alternative_fr',
            'caseNumber',
            'relatedCases',
            'judicialDocumentIds',
            'trailOutcome',
            'phaseOfCaseIds',
            'judicialDocumentType',
            'chamberComposition',
            'presidingJudge',
            'placeCourt',
            'courtType',
            'participatingStates',
            'victimParticipation',
            'accusedDefendant',
            'Alias of accused’s name',
            'prosecutorClaimant',
            'counselForDefence',
            'victimsCounsel',
            'amicusCuriae',
        ],
    },
    iccDocuments: {
        label: 'ICC DOCUMENTS',
        fields: ['isCourtRecord', 'iccSituationIds', 'interlocutory'],
    },
    bibliography: {
        label: 'BIBLIOGRAPHY',
        fields: [
            'author',
            'publisher',
            'confidentiality',
            'importance',
            'copyRightAuthorisation',
            'disableIndexing',
            'Subject',
            'Subject (Level 2)',
            'Subject (Level 3)',
            'Subject (Level 4)',
            'preparatoryPhase',
            'resourceCitation_en',
            'resourceCitation_fr',
            'keywordIds',
            'ifri_internal',
        ],
    },

    qualityControl: {
        label: 'QUALITY CONTROL',
        fields: [
            'accessDate',
            'numberOfPages',
            'documentType',
            'responsiblePartner',
            'Metadata collected by',
            'Metadata finalised on',
            'publishDate',
            'registerDate',
            'orignalPdfURL',
            'Display as',
            'documentOrigin.source',
            'documentOrigin.url',
        ],
    },
};

export const getLtdDocumentFormFieldKeys = () => {
    let ltdDocumentFormFieldKeys: TLtdDocFormFieldKey[] = [];
    Object.keys(LTD_DOCUMENT_SECTIONS).forEach(section => {
        ltdDocumentFormFieldKeys = [...ltdDocumentFormFieldKeys, ...LTD_DOCUMENT_SECTIONS[section].fields];
    });
    return ltdDocumentFormFieldKeys;
};
