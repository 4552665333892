import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Dialog, DialogContent, Slide, Fade, Zoom, Grow } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import AdvancedSearch from '../Features/AdvancedSearch/';
import { TState } from '../RootReducer/index';
import { OAdvancedSearch } from '../Features/AdvancedSearch/redux-config';
import { IS_MOBILE } from '../App';

interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
}

interface IStateProps {
    open: boolean
}


interface IDispatchProps {
    closeDialog?: Function
}


function Transition(props) {
    return <Slide direction="right" in={true} {...props} timeout={380} />;
}


class AdvancedSearchDialog extends Component<Props> {

    render() {
        const { classes, open = false } = this.props;

        return (
            <Dialog
                fullWidth={IS_MOBILE}
                fullScreen={IS_MOBILE}
                TransitionComponent={Transition}
                open={open || false}
                keepMounted={true}
                onClose={e => this.props.closeDialog!()}
                PaperProps={{ className: classes.root }}
                scroll="body"
            >
                <AdvancedSearch />
            </Dialog>
        )
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.AdvancedSearch, ['open'])
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OAdvancedSearch.closeDialog())
})

const STYLES = theme => createStyles({
    root: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: 1000,
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(AdvancedSearchDialog))