import React, { Component, useState } from 'react'
import { createStyles, WithStyles, withStyles, Dialog, DialogContent, Slide, Fade, Zoom, Grow, Theme, IconButton, Icon, Typography, Button, CircularProgress, Backdrop } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import AdvancedSearch from '../Features/AdvancedSearch/';
import { TState } from '../RootReducer/index';
import { OAdvancedSearch } from '../Features/AdvancedSearch/redux-config';
import { IS_MOBILE } from '../App';
import BatchEdit, { BatchEditProps } from '../Components/BatchEdit';
import { OBatchEdit } from '../Features/BatchEdit/redux-config';
import { TLtdDocFormFieldKey, LTD_DOCUMENT_FORM_FIELDS } from '../Components/BatchEdit/constants';
import { TBatchEditForm } from '../Components/BatchEdit/useBatchEdit';
import { OApp, TToastVariant } from '../RootReducer/AppReducer';
import { TBatchEditState } from '../Features/BatchEdit/@types';
import { TNode } from '../Features/DocumentsTree/@types';
import { BatchReview } from '../Features/BatchReview/redux-config';
import { TBatchReviewState } from '../Features/BatchReview/@types';
import SelectedDocumentActions from '../Components/SelectedDocumentActions';
import FullscreenLoader from '../Components/FullscreenLoader';

interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
}

interface IStateProps {
    reviewDialogOpen: boolean
    docIds: string[]
    appConstants: any
    form: TBatchEditForm
    selectedDocIds: string[]
    ltdIncludeOptions: Record<string, any>
    loading: boolean
}


interface IDispatchProps {
    closeDialog?: () => void
    showConfirmation: (msg: string, onConfirm: any, onCancel: any) => void
    showToast: (msg: string, variant: TToastVariant) => void
    setForm: (form: TBatchReviewState['form']) => void
    selectAllDocs: () => void,
    clearDocumentSelection: () => void,
    toggleDocSelection: (id: string) => void
    publishSelectedDocs: (ids: string[]) => any
}


function Transition(props) {
    return <Slide direction="right" in={true} {...props} timeout={380} />;
}


class BatchReviewDialog extends Component<Props> {
    handleChange = (form: TBatchEditForm) => {
        this.props.setForm(form);
    }



    handlePublish = () => {
        const { selectedDocIds } = this.props;
        this.props.publishSelectedDocs(selectedDocIds);
        this.props.clearDocumentSelection();
    }

    render() {
        const {
            ltdIncludeOptions = {},
            form = {},
            classes,
            selectedDocIds = [],
            reviewDialogOpen = false,
            docIds = [],
            loading = false,
            closeDialog = () => { },
        } = this.props;

        const selectedDocumentActions = [
            { label: 'Publish', action: this.handlePublish }
        ]

        return (
            <Dialog
                fullWidth={true}
                fullScreen={true}
                TransitionComponent={Transition}
                open={reviewDialogOpen || false}
                keepMounted={false}
                onClose={closeDialog}
                PaperProps={{ className: classes.root }}
                scroll="body"
            >
                <DialogHeader {...this.props} />
                <div className={classes.subHeaderBg} />
                <BatchEdit
                    selectedDocIds={selectedDocIds}
                    onDisselectAll={this.props.clearDocumentSelection}
                    onSelectAll={this.props.selectAllDocs}
                    onSelectDocument={this.props.toggleDocSelection}
                    reviewMode={true}
                    errorIds={[]}
                    includeIdOptions={ltdIncludeOptions}
                    formData={form}
                    onFormChange={this.handleChange}
                    docIds={docIds}
                    isNewDocument={false}
                    formFields={LTD_DOCUMENT_FORM_FIELDS.map(f => f.key)}
                    // onDocModified={onDocUpdate}
                    appConstants={_.get(this.props.appConstants, 'LtdDoc')}
                />

                <SelectedDocumentActions
                    actions={selectedDocumentActions}
                    docType='LtdDoc'
                    variant='ltd'
                    containerClass={classes.actionControlContainer}
                    selectedIds={selectedDocIds}
                />

                {loading ? <FullscreenLoader /> : null}
            </Dialog>
        )
    }
}


const DialogHeader = (props: Props) => {
    const { classes, form } = props;
    const [loading, setLoading] = useState(false);


    const handleSave = async () => {

    }

    const handleClose = () => {
        if (!props.closeDialog) return;

        props.closeDialog()
    }

    const dialogTitle = `Batch Review - ${Object.keys(form).length}`

    return (
        <div className={classes.header}>
            <IconButton className={classes.iconButton} onClick={handleClose}><Icon>close</Icon></IconButton>
            <Typography className={classes.headerTitle}>{dialogTitle}</Typography>
            <div style={{ flex: 1 }} />

        </div>
    )
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.App, ['appConstants', 'ltdIncludeOptions']),
    ..._.pick(state.BatchReview, ['reviewDialogOpen', 'docIds', 'form', 'selectedDocIds', 'loading'])
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(BatchReview.closeDialog()),
    showConfirmation: (msg, onConfirm, onCancel) => dispatch(OApp.showConfirmationDialog(msg, onConfirm, onCancel)),
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant)),
    setForm: (form) => dispatch(BatchReview.handleFormChange(form)),
    selectAllDocs: () => dispatch(BatchReview.selectAllDocuments()),
    clearDocumentSelection: () => dispatch(BatchReview.clearDocumentSelection()),
    toggleDocSelection: (id) => dispatch(BatchReview.toggleDocumentSelection(id)),
    publishSelectedDocs: (ids: string[]) => dispatch(BatchReview.batchPublishDocuments(ids))
})

const STYLES = (theme: Theme) => createStyles({
    root: {

    },
    header: {
        backgroundColor: theme.palette.grey['A200'],
        height: 51,
        position: 'sticky',
        top: 0,
        display: 'flex',
        alignItems: 'center',

    },
    headerTitle: {
        color: theme.palette.common.white,

    },
    iconButton: {
        marginRight: 10,
        color: theme.palette.common.white
    },
    subHeaderBg: {
        position: 'absolute',
        width: '100vw',
        height: 43,
        top: 48,
        backgroundColor: theme.palette.grey['A200'],
    },
    actionControlContainer: {
        position: 'absolute',
        left: 55,
        top: 100,
        width: 180,
        zIndex: theme.zIndex.modal + 100,

        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            left: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(BatchReviewDialog))