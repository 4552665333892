import { PRIVACY_POLICY_ZH } from '../../../Resources/constants';

const termUseZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="/" >Home&nbsp;»&nbsp;</a><a href="footer/terms-of-use/" >使用条件</a></p></div><div id="getUrlText"><div "uid","uid","''"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" "uid","uid","''"><i class="glyphicon glyphicon-print"></i></a></div><br "uid","uid","''">
<!--  CONTENT ELEMENT, uid:51/text [begin] -->
    <div id="c51" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">使用条件</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext">
        使用本网址意味着你已经同意以下条款和条件：
        <br> 
</p>
<p class="bodytext"></p><blockquote>
（1）国际刑事法院为使用本网站的人（“用户”）的便利维护本网站（“网站”）。网站上的信息仅用于增加知识。国际刑事法院允许用户访问网站，从网站上下载和复制信息、文件、元数据和材料（统称“材料”）。根据下述条件和可能应用于网站上特定材料的限制条件，用户只能将这些材料用于非商业用途，不能再售卖、再传播、编纂材料或创造衍生作品。法院对网站上的材料的准确性和完整性不负责。

<p class="bodytext">
（2）用户在将材料用于教育、学术写作和出版物时应引用
<a href="/">法律工具数据库</a><a id="_anchor_1" href="typo3/#_msocom_1" name="_msoanchor_1"><u></u></a> 
的永久链接。在同时引用大量文件时，可引用数据库网址
 <a href="https://www.legal-tools.org" target="_blank">https://www.legal-tools.org</a> 
 引用<a href="https://www.legal-tools.org/cld">国际刑事法院案例数据库</a>,
 中的法律调查结果时，应当使用相应永久链接。涉及大量法律文件时，可引用判例法数据库链接
   <a href="https://www.legal-tools.org/cld" target="_blank">https://www.legal-tools.org/cld</a>。
</p>
<p class="bodytext">
（3）CILRAP为国际刑事法院管理本网站。使用条款和条件应用于网站上所有的材料。
</p>
<p class="bodytext"> 
（4）除非另有说明，网站材料中的裁决、解释、结论是由准备这些文件的国际刑事法院工作人员、顾问等做出，并不一定代表法院或者其成员国的观点。
  <br><br></p></blockquote>
<p></p>
<h3>一般条款</h3>
<p class="bodytext">
国际刑事法院保留改变、限制或者中止本网站或者任何材料的任何方面的独家权利。在行使该权利时法院没有义务考虑任何用户的需求。
</p>
<p class="bodytext">
国际刑事法院保留在没有事先通知的情况下禁止任意用户访问网站或者网站的某一部分的独家权利。
</p>
<p class="bodytext">
国际刑事法院的对任意条款的弃权只有在其合法代表签署书面声明的情况下才有效。
</p>
<h3>著作权声明 </h3>
<p  class="bodytext">
著作权© 国际刑事法院2003-2022。版权所有。在没有国际刑事法院书面同意的情况下，用户不得在网站使用条件以外以任何电子或者机械形式（包括复印、录制或者使用任何信息存储和提取系统）使用、复制、传输全部或者部分材料。申请法院同意或者获取更多信息请联系国际刑事法院网页信息管理人：国际刑事法院公共信息和记录处，P.O.Box 19519, 2500 CM 海牙，荷兰
   <a href="mailto:websupport@icc-cpi.int">websupport@icc-cpi.int</a></p>
<p "uid","uid","''" class="bodytext">
外部组织或个人可能因创建了法律工具数据库中的一些文件而对这些文件有著作权。项目合作伙伴知晓著作权所有人的著作权，且仅在取得著作权人同意的情况下将相关文件提供给公众。因此数据库中的某些文件的链接并没有被激活，相关文件也不能被获取。本项目邀请著作权人在这个全球共享平台上分享他们的文件。本项目将在接下来的几年中通过系统性的努力逐步取得将这些文件提供给公众的授权。
    </p>
<p "uid","uid","''" class="bodytext">
在将数据库中的文件提供给公众之前，工作人员已采取所有合理措施来取得相关著作权人的授权。如果你是著作权受益人或者被授权代表著作权受益人，你认为你的著作权或者其他财产性权利被法律工具数据库中的材料所侵害
  <i>,</i>&nbsp;please&nbsp;&nbsp;请联系我们并提供以下信息：
   </p><ul type="disc"><li "uid","uid","''"><ins></ins>
指出你认为被侵害著作权的文件
   </li><li "uid","uid","''">
  准确描述该文件在法律工具数据库中的位置；你的联系方式（地址、电话、电子邮件）；
    </li><li "uid","uid","''">
   声明你善意地相信该争议文件的使用没有获得著作权人、其代理人或者法律的授权；
     </li><li "uid","uid","''">
     a statement&nbsp;declaring that you have a good-faith belief that the disputed use has not been authorized by 
     the copyright owner, its agent, or the law;
      </li><li "uid","uid","''">
    声明上述信息据你所知是准确的，你是著作权人或者被授权代理著作权人；
       </li><li "uid","uid","''">你的电子或者实体签名 
       </li></ul><p class="bodytext"> 
      上述信息应以电子邮件形式发送至i<a href="mailto:info@cilrap.org">info@cilrap.org</a>, 
     或者以邮件形式发送至国际刑事法院检察官办公室，法律咨询处，P.O.Box 19519, 2500 CM 海牙，荷兰。
</p>
<h3>免责条款</h3>
<p class="bodytext">
网站提供的材料即内容“本身”，并不附带任何明示或者暗示的保证，包括但不限于适销性保证、针对某一特定用途的合适性、无侵权的保证。国际刑事法院尤其不提供材料准确性和完整性的保证或者陈述。在没有事先通知的情况下，国际刑事法院定期变更、改进、更新网站上的材料。在任何情况下国际刑事法院不为因(声称)使用网站产生的， 包括但不限于任何过失、错误、损害、责任或者费用承担责任，包括但不限于网站的过失、错误、遗漏、中断或者延误。用户为使用网站承担全部责任。在任何情况下，包括但不限于疏忽，国际刑事法院或其附属机构不为任何直接的、间接的、附带的、特殊的损害承担责任，即使国际刑事法院已经被告知相应损害的可能性。 
</p>
<p class="bodytext">
用户明确承认和同意国际刑事法院不对其任何行为承担责任。</p>
<p class="bodytext">
网站可能包含不同信息提供者的建议、观点和陈述。国际刑事法院不代表或者认可任何信息提供者、网站用户或者任何其他个人或团体提供的任何建议、观点、陈述和其他信息的准确性和可靠性。用户在使用任何建议、观点、陈述时自担风险。国际刑事法院和其附属机构、代理人、雇员、信息或内容提供者不对任何用户或者任何其他人对任何内容的不准确性、错误、遗漏、中断、删除、缺陷、改变负责，也不对内容的及时性和完整性负责，也不对任何运行失败、计算机病毒或者沟通线路故障负责，无论这些问题的起因或者损害后果为何。
</p>

<p class="bodytext">
    作为使用网站的条件之一，用户同意保证赔偿国际刑事法院及其附属机构因其使用网站，包括但不限于违反使用条款引起的任何诉讼、索赔、损失、损害、责任和费用（包括合理的律师费）。如果用户不满网站的任何材料或者使用条款，其唯一的救济措施是停止使用网站。
</p>
<p class="bodytext">
本网站可能包含和提到第三方网站和链接。这些提到的网站不在国际刑事法院的控制下，国际刑事法院也不对这些网站的内容或者其中的任何链接负责。国际刑事法院仅为方便用户目的提供这些链接。这些链接的收录不代表国际刑事法院支持链接指向的网站。
</p>
<p class="bodytext">
若本网站包含公示栏、聊天室、邮件名单或者其他发送信息或者交流平台（统称“论坛”），用户同意仅用这些论坛发送和接收与特定论坛相关的信息和材料。在使用论坛时，用户同意不开展包括但不限于以下行为：
  </p>
<p class="bodytext"></p><blockquote>
1）以诽谤、滥用、骚扰、跟踪、威胁或其他方式侵犯他人合法权利（如隐私权和形象权）；<br><br> 
（2）公布、张贴、散布或者传播任何诽谤、侵权、淫秽、下流或者非法的材料或者信息； <br><br> 
（3）上传或者添加包含受知识产权法（或者隐私权和形象权）保护的软件或者其他材料，除非用户拥有或者控制这些权利或者取得了法律规定的同意；
  <br><br> 
  （4）上传或者添加包含病毒或者受损的文件，或者任何其他可能损害他人计算机运行的软件或者程序；
  <br><br> 
（5）删除上传文件中的任何作者归属、法律通告、专有名称或者标签；  <br><br>
（6）伪造上传文件中的软件或者其他材料来源；   <br><br>
（7）发布购买任何物品或者服务的邀约、发布或者转发调查问卷、抗议或者连锁信，或者在知情或者应当知情的情况下下载任何其他用户发布的非法传播的文件。
    </blockquote>
<p></p>
<p class="bodytext">
用户承认所有论坛和小组讨论都是公开而非私下交流。用户知晓其他用户的谈话、信息、会议、邮件和其他交流内容没有得到国际刑事法院的背书，也不能被认为已经由国际刑事法院审查、筛选或者核准。国际刑事法院保留在没有事先通知的情况下以任何原因移除论坛上用户发布的任何内容的权利，包括但不限于邮件和公告栏告示。
 <br><br>
</p>
<h3>豁免权保留 </h3>
<p class="bodytext">
这里的所有条款不能被认为对国际刑事法院特别保留的放弃特权和豁免的权利产生任何限制。
  <br><br>
</p>
<h3>隐私政策 </h3>
<p class="bodytext">${PRIVACY_POLICY_ZH}</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:51/text [end] -->
    </div>`;

export default termUseZh;
