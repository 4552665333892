import React from 'react'
import { createStyles, Theme, withStyles, WithStyles, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

interface EntryProps extends WithStyles<typeof STYLES> {
    type?: 'label' | 'entry'
}

const Entry: React.FC<EntryProps> = (props) => {
    const { classes, type = 'entry' } = props;
    return (
        <Typography variant={type === 'entry' ? 'body2' : "caption"} className={classes.root}>
            {props.children}
        </Typography>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        fontWeight: theme.typography.fontWeightMedium
    }
})

export default withStyles(STYLES)(Entry)