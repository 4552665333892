import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Card, Typography, IconButton, Icon, Button } from '@material-ui/core';
import { TIssueSuggestionType, TSuggestion } from '../../../Services/LtdDocs.types';
import moment from 'moment';
import _, { unset } from 'lodash';
import { LTD_DOC_DATE_FORMAT } from '../../../Components/BatchEdit/useBatchEdit';
import { LTD_DOCUMENT_FORM_FIELDS } from '../../../Components/BatchEdit/constants';
import { ltdDocFieldReslover } from '../TreeManagement/csvUtils';
import Text from '../../../Components/Text';

export interface SuggestionItemProps extends WithStyles<typeof STYLES> {
    item: TSuggestion,
    resolver?: any[]
    onEditDoc: (form: Record<string, any>) => void
}

const KEYS_TO_IGNORE = ['documentFilePath', 'documentName'];

const SuggestionItem: FC<SuggestionItemProps> = (props) => {
    const { classes, item, resolver = [] } = props;
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const suggestionType = item.type;

    const handleEdit = () => {
        props.onEditDoc({ ...item.doc });
    }

    const getDocMetadata = () => {
        const keys = Object.keys(item.doc);

        return (
            <div className={classes.expansionPanelDetail}>
                <Typography>Suggested fields</Typography>
                {keys.map(k => {
                    if (KEYS_TO_IGNORE.includes(k)) return null;
                    const _resolverConfig = ltdDocFieldReslover[k];
                    const resolverKey = _resolverConfig ? _resolverConfig.resolveKey : null;
                    const value = resolverKey ? resolveDisplayValue(_.get(item.doc, k), resolver[resolverKey]) : _.get(item.doc, k);
                    return (
                        <div className="flex-row align-start my-2" key={k}>
                            <Typography color="primary" className={classes.label}>{resolveLabel(k)}:</Typography>
                            <Typography variant="caption" className={classes.value}>{value}</Typography>
                        </div>
                    )
                })}
                <div className="flex-row">
                    <div style={{ flex: 1 }} />
                    <Button onClick={handleEdit} color="primary" variant="contained">Edit Document</Button>
                </div>
            </div>
        );
    }


    return (
        <Card className={classes.root}>
            <div className={classes.title}>
                <Typography><b>{item.name}</b></Typography>
                <Typography variant="caption">{item.comment}</Typography>
                <div className="flex-row justify-between align-center">
                    <Typography variant="caption">{item.email}</Typography>
                    <Typography variant="caption">{moment(item.created).format(LTD_DOC_DATE_FORMAT)}</Typography>
                </div>
            </div>
            {suggestionType === 'docAddition' ? (
                <ExpansionPanel expanded={expanded}>
                    <ExpansionPanelSummary classes={{ content: classes.summaryContent }}>
                        <Typography>Document Suggestion: {_.get(item, 'doc.title')}</Typography>
                        <IconButton onClick={toggleExpanded}><Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></IconButton>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {getDocMetadata()}

                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ) : null}
        </Card>
    )
}


const resolveLabel = (key: string) => {
    const el = _.find<{ label: string, key: string }>(LTD_DOCUMENT_FORM_FIELDS, { key });
    if (el)
        return el.label
    return key;
}

const resolveDisplayValue = (value: string | string[], resolver?: any[], resolveKey: string = 'value', resolveLabel: string = 'name') => {
    if (typeof value === 'string')
        value = [value];
    const displayValue = (value || []).map(id => {
        const resolvedObj = _.find((resolver || []), { [resolveKey]: id });
        return _.get(resolvedObj, resolveLabel) || id
    })
    return (displayValue || []).join(', ');
}


const STYLES = (theme: Theme) => createStyles({
    root: {
        // padding: 20
    },
    title: {
        padding: 20
    },
    label: {
        flex: 1,
        marginRight: 8
    },
    value: {
        flex: 3
    },
    summaryContent: {
        alignItems: 'center',
        justifyContent: 'space-between',
        '& >:last-child': {
            padding: 8
        }
    },
    expansionPanelDetail: {
        width: '100%'
    }
});

export default withStyles(STYLES)(SuggestionItem);