import {
  Button,
  ClickAwayListener,
  Fab,
  Grow,
  Popper,
  TextField,
  Typography,
  createStyles,
  Theme,
  WithStyles,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
// import Loader from '../../Components/Loader';
import useAsyncTask from "../../Hooks/useAsyncTask";
import _ from "lodash";
import TranslationModel from "../../Models/Translations";
import { TLeaf, TranslationLocale } from "../../Models/Translations/@types";
import React, { FC, Fragment, useState } from "react";
import TranslationListRow from "./TranslationListRow";
import useTranslationTable from "./useTranslationTable";
import { WithTranslationProps, withTranslations } from "./withTranslations";
import { GENERAL_SAVE } from "./translationKeys.cld";

export const TABLE_CELL_WIDTH = 250;

export interface TranslationTableProps extends WithStyles<typeof STYLES>, WithTranslationProps {
  /**
   * Path of the node from root whose leaves to be shown in the table.
   * If none is provided, then whole JSON tree leaves will be shown in the table.
   */
  nodePath?: string;
  translationJSON: any
  handleSubmit: (data: Record<string, any>) => Promise<void>
}

const TranslationTable: FC<TranslationTableProps> = (props) => {
  const { nodePath, classes, handleSubmit, translationJSON, getTranslation } = props;

  const pathToNode = nodePath ? nodePath.replace("-", ".") : "";

  const {
    agencyTranslations,
    setAgencyTranslations,
    agencyDetails,
    languagesInSystem,
    agencyLanguages,
    setAgencyLanguages,
  } = useTranslationTable(translationJSON);

  const [
    addLanguageButtonAnchor,
    setAddLanguageButtonAnchor,
  ] = useState<HTMLElement | null>(null);

  const addLanguagePopperOpen = Boolean(addLanguageButtonAnchor);
  const [loading, setLoading] = useState(false);
  const updateAgencyTask = useAsyncTask(async () => { });

  const toggleAddLanguagePopper = (event: React.MouseEvent<HTMLElement>) =>
    setAddLanguageButtonAnchor(
      addLanguageButtonAnchor ? null : event.currentTarget
    );

  const updateAgencyLanguages = (lang: TranslationLocale) => {
    if (lang && !agencyLanguages.includes(lang)) {
      setAgencyLanguages((agencyLanguages) => [...agencyLanguages, lang]);
    }
  };

  const closeAddLanguagePopper = () => setAddLanguageButtonAnchor(null);

  const handleValueChange = (
    pathFromRoot: string,
    language: TranslationLocale,
    value: string
  ) => {
    const agencyTranslationsCopy = { ...agencyTranslations };
    _.set(agencyTranslationsCopy, pathFromRoot + `.${language}`, value);
    setAgencyTranslations(agencyTranslationsCopy);
  };

  const submit = async () => {
    setLoading(true);
    await handleSubmit(agencyTranslations)
    setLoading(false);
  };

  const leaves = TranslationModel.getLeaves(agencyTranslations, pathToNode);

  const languageOptions = (
    languagesInSystem.filter(
      (l) => !agencyLanguages.includes(l.value as TranslationLocale)
    ) || []
  ).map((l) => ({ id: l.value, label: l.name }));


  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.head}>
          {agencyLanguages.map((lang, index) => {
            return (
              <Fragment key={index}>
                <div className={clsx(classes.headCell, classes.nameCell)}>
                  {" "}
                  <Typography variant={"caption"}>
                    {
                      (
                        languagesInSystem.find(
                          (language) => language.value === lang
                        ) || { name: "" }
                      ).name
                    }
                  </Typography>{" "}
                </div>
                <div className={classes.borderRight} />
              </Fragment>
            );
          })}
        </div>

        {leaves.map((leaf: TLeaf, i: number) => {
          return (
            <TranslationListRow
              locales={agencyLanguages}
              key={leaf.pathFromRoot}
              pathFromRoot={leaf.pathFromRoot}
              translations={leaf.translations}
              handleChange={handleValueChange}
            />
          );
        })}
        <div className={classes.topFloatingButtons}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={submit}
          >
            {loading && <CircularProgress size={"xs"} />}{getTranslation(GENERAL_SAVE)}{" "}
          </Button>

          {/* <Fab
            className={classes.actionIcon}
            size="medium"
            onClick={toggleAddLanguagePopper}
          >
            <AddIcon fontSize="default" />
          </Fab> */}
        </div>
        {languageOptions && (
          <Popper
            anchorEl={addLanguageButtonAnchor}
            open={addLanguagePopperOpen}
            role="add language"
            transition
          >
            <Grow style={{ transformOrigin: "center top" }}>
              <ClickAwayListener onClickAway={closeAddLanguagePopper}>
                {/* <Autocomplete
                                    id="combo-box-demo"
                                    options={languageOptions}
                                    getOptionLabel={(option) => option.label}
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            className={classes.addLanguageComboBox}
                                            {...params}
                                            label="Choose language to add"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(event: React.ChangeEvent<{}>, value: { id: TranslationLocale; label: string } | null) => {
                                        value && updateAgencyLanguages(value.id);
                                    }}
                                /> */}
              </ClickAwayListener>
            </Grow>
          </Popper>
        )}
      </div>
    </div>
  );
};

const STYLES = (theme: Theme) => {
  const {
    palette: { background, primary, secondary },
  } = theme;
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      margin: 0,
      padding: 0,
      position: "relative",
    },
    container: {
      minWidth: "100%",
      width: "fit-content",
    },
    headCell: {
      height: 40,
      paddingLeft: 8,
      display: "flex",
      alignItems: "center",
      // minWidth: 153,
    },
    head: {
      height: 48,
      width: "100%",
      display: "flex",
      backgroundColor: background["default"],
      boxShadow: "0px 1px 3px #00000029",
      position: "sticky",
      top: 0,
      zIndex: 2,
      fontSize: "15px",
      alignItems: "center",
    },
    nameCell: {
      display: "flex",
      // maxWidth: 400,
      width: TABLE_CELL_WIDTH,
      paddingRight: 16,
      boxSizing: 'border-box'
    },
    addLanguageComboBox: {
      background: background["paper"],
    },
    topFloatingButtons: {
      position: "fixed",
      top: "54px",
      right: 20,
      display: "flex",
      alignItems: 'center',
      width: 150,
      justifyContent: "space-between",
      zIndex: 4,
    },
    actionIcon: {
      backgroundColor: secondary["main"],
      "&:hover": {
        backgroundColor: primary["main"],
      },
    },
    borderRight: {
      height: "inherit",
      backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 75%, #707070 25%)`,
      backgroundPosition: "right",
      backgroundSize: "1px 5px",
      backgroundRepeat: "repeat-y",
      width: 1,
      "&:after": {
        display: "table",
        clear: "both",
        content: "' '",
      },
    },
  });
};

export default withStyles(STYLES)(withTranslations(TranslationTable));
