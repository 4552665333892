import React, { Component, useState } from 'react'
import { createStyles, WithStyles, withStyles, Dialog, DialogContent, Slide, Fade, Zoom, Grow, Theme, IconButton, Icon, Typography, Button, CircularProgress, Backdrop } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import AdvancedSearch from '../Features/AdvancedSearch/';
import { TState } from '../RootReducer/index';
import { OAdvancedSearch } from '../Features/AdvancedSearch/redux-config';
import { IS_MOBILE } from '../App';
import BatchEdit, { BatchEditProps } from '../Components/BatchEdit';
import { OBatchEdit } from '../Features/BatchEdit/redux-config';
import { TLtdDocFormFieldKey } from '../Components/BatchEdit/constants';
import { TBatchEditForm } from '../Components/BatchEdit/useBatchEdit';
import { OApp, TToastVariant } from '../RootReducer/AppReducer';
import { TBatchEditState } from '../Features/BatchEdit/@types';
import { TNode } from '../Features/DocumentsTree/@types';
import FullscreenLoader from '../Components/FullscreenLoader';
import SelectedDocumentActions from '../Components/SelectedDocumentActions';
import { WithTranslationProps, withTranslations } from '../Features/Translations/withTranslations';
import { GENERAL_SAVE } from '../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, WithTranslationProps {
}

interface IStateProps {
    editorOpen: boolean
    formFields: TLtdDocFormFieldKey[]
    docIds: string[]
    appConstants: any
    batchEditForm: TBatchEditForm
    batchCreateForm: TBatchEditForm
    isNewDocument: boolean
    parentNode?: TNode
    formModified: boolean
    numOfDocuments?: number
    errorIds: string[]
    selectedDocIds: string[]
    loading: boolean
    ltdIncludeOptions: Record<string, any>
}


interface IDispatchProps {
    closeDialog?: () => void
    handleFormChange?: (form: TBatchEditForm) => void
    saveEdit: (form: TBatchEditForm, isNewDocument: boolean, parentNode?: TNode, numOfDocuments?: number) => Promise<any>
    onDocUpdate: (docId) => void
    showConfirmation: (msg: string, onConfirm: any, onCancel: any) => void
    showToast: (msg: string, variant: TToastVariant) => void
    selectAllDocs: () => void,
    clearDocumentSelection: () => void
    toggleDocSelection: (id: string) => void
    publishSelectedDocs: (ids: string[]) => void
}


function Transition(props) {
    return <Slide direction="right" in={true} {...props} timeout={380} />;
}


class BatchEditDialog extends Component<Props> {
    handleChange = (form: TBatchEditForm) => {
        if (this.props.handleFormChange)
            this.props.handleFormChange(form)
    }

    handlePublish = () => {
        const { selectedDocIds } = this.props;
        this.props.publishSelectedDocs(selectedDocIds);
        this.props.clearDocumentSelection();
    }



    render() {
        const {
            errorIds = [],
            ltdIncludeOptions = {},
            batchEditForm = {},
            isNewDocument = false,
            batchCreateForm = {},
            selectedDocIds = [],
            loading = false,
            classes, editorOpen = false, docIds = [],
            formFields = [], closeDialog = () => { },
            onDocUpdate = () => { },
        } = this.props;

        const selectedDocumentActions = [
            { label: 'Publish', action: this.handlePublish }
        ]

        return (
            <Dialog
                fullWidth={true}
                fullScreen={true}
                TransitionComponent={Transition}
                open={editorOpen || false}
                keepMounted={false}
                onClose={closeDialog}
                PaperProps={{ className: classes.root }}
                scroll="body"
            >
                <DialogHeader {...this.props} />
                <div className={classes.subHeaderBg} />
                <BatchEdit
                    selectedDocIds={selectedDocIds}
                    onDisselectAll={this.props.clearDocumentSelection}
                    onSelectAll={this.props.selectAllDocs}
                    onSelectDocument={this.props.toggleDocSelection}
                    errorIds={errorIds}
                    includeIdOptions={ltdIncludeOptions}
                    formData={isNewDocument ? batchCreateForm : batchEditForm}
                    onFormChange={this.handleChange}
                    docIds={docIds}
                    isNewDocument={isNewDocument}
                    formFields={formFields}
                    onDocModified={onDocUpdate}
                    appConstants={_.get(this.props.appConstants, 'LtdDoc')}
                />
                {loading ? <FullscreenLoader /> : null}
                <SelectedDocumentActions
                    actions={selectedDocumentActions}
                    docType='LtdDoc'
                    variant='ltd'
                    containerClass={classes.actionControlContainer}
                    selectedIds={selectedDocIds}
                />
            </Dialog>
        )
    }
}


const DialogHeader = (props: Props) => {
    const { classes, formModified = false, isNewDocument = false, batchCreateForm, parentNode, getTranslation } = props;
    const [loading, setLoading] = useState(false);


    const handleSave = async () => {
        const { batchEditForm, batchCreateForm, isNewDocument, numOfDocuments } = props;
        setLoading(true);
        try {
            await props.saveEdit(isNewDocument ? batchCreateForm : batchEditForm, isNewDocument, parentNode, numOfDocuments)
            props.showToast("Form saved successfully", 'success')
        } catch (error) {
            props.showToast("Something went wrong", 'error')
        }
        setLoading(false)
    }

    const handleClose = () => {
        if (!props.closeDialog) return;
        if (formModified)
            props.showConfirmation("Exit without saving changes?", () => props.closeDialog!(), () => { })
        else
            props.closeDialog()
    }

    const dialogTitle = `Batch ${isNewDocument ? 'Create' : 'Edit'} - ${isNewDocument ? Object.keys(batchCreateForm).length : props.docIds.length}`

    return (
        <div className={classes.header}>
            <IconButton className={classes.iconButton} onClick={handleClose}><Icon>close</Icon></IconButton>
            <Typography className={classes.headerTitle}>{dialogTitle}</Typography>
            <div style={{ flex: 1 }} />
            {loading ? <CircularProgress color="primary" /> : (
                <Button disabled={!formModified} className="mx-2" variant="contained" color="primary" onClick={handleSave}>{getTranslation(GENERAL_SAVE).toUpperCase()}</Button>
            )}
        </div>
    )
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.App, ['appConstants', 'ltdIncludeOptions']),
    ..._.pick(state.BatchEdit, ['errorIds', 'selectedDocIds', 'loading', 'editorOpen', 'docIds', 'formFields', 'batchEditForm', 'formModified', 'batchCreateForm', 'isNewDocument', 'parentNode', 'numOfDocuments'])
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OBatchEdit.closeEditor()),
    handleFormChange: (form) => dispatch(OBatchEdit.handleFormChange(form)),
    saveEdit: (form, isNewDocument, parentNode, numOfDocuments) => dispatch(OBatchEdit.submitForm(form, isNewDocument, parentNode, numOfDocuments)),
    onDocUpdate: (docId) => dispatch(OBatchEdit.docUpdated(docId)),
    showConfirmation: (msg, onConfirm, onCancel) => dispatch(OApp.showConfirmationDialog(msg, onConfirm, onCancel)),
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant)),
    selectAllDocs: () => dispatch(OBatchEdit.selectAllDocuments()),
    clearDocumentSelection: () => dispatch(OBatchEdit.clearDocumentSelection()),
    toggleDocSelection: (id) => dispatch(OBatchEdit.toggleDocumentSelection(id)),
    publishSelectedDocs: (ids) => dispatch(OBatchEdit.batchPublishDocuments(ids))
})

const STYLES = (theme: Theme) => createStyles({
    root: {

    },
    header: {
        backgroundColor: theme.palette.grey['A200'],
        height: 51,
        position: 'sticky',
        top: 0,
        display: 'flex',
        alignItems: 'center',

    },
    headerTitle: {
        color: theme.palette.common.white,

    },
    iconButton: {
        marginRight: 10,
        color: theme.palette.common.white
    },
    subHeaderBg: {
        position: 'absolute',
        width: '100vw',
        height: 43,
        top: 48,
        backgroundColor: theme.palette.grey['A200'],
    },
    actionControlContainer: {
        position: 'absolute',
        left: 55,
        top: 100,
        width: 180,
        zIndex: theme.zIndex.modal + 100,

        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            left: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(BatchEditDialog)))