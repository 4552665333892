import _ from 'lodash';
import utilities from '../../Resources/Utils';
import {
    CARD_COPY_URL,
    CARD_METADATA,
    CARD_REMOVE_FROM_LIST,
    CARD_SEE_FULL,
    GENERAL_DOWNLOAD,
    GENERAL_READ_LATER,
} from '../Translations/translationKeys.cld';
import { CLDFACETS, CLD_DECISION_FACETS } from './redux-config';

export const getListItemActionLabelTranslation = (action: string, getTranslation: (key: string) => string) => {
    switch (action) {
        case 'COPY URL':
            return getTranslation(CARD_COPY_URL);
        case 'DOWNLOAD':
            return getTranslation(GENERAL_DOWNLOAD);
        case 'METADATA':
            return getTranslation(CARD_METADATA);
        case 'READ LATER':
            return getTranslation(GENERAL_READ_LATER);
        case 'REMOVE FROM LIST':
            return getTranslation(CARD_REMOVE_FROM_LIST);
        case 'SEE FULL':
            return getTranslation(CARD_SEE_FULL);
        default:
            return action;
    }
};

export const transformStaticFilterToSearchParams = (params: Record<string, any>, isDecisionSearch: boolean) => {
    if (!isDecisionSearch) return params;

    // CHECK FOR DECISION ASSESSMENT
    const keys = _.get(params, 'filter.where.legalFindings') || [];
    if (!_.isEmpty(keys)) {
        params = _.omit(params, 'filter.where.legalFindings');
        if (keys.length < 3) {
            const should: any[] = [];
            if (keys.includes('NA')) {
                should.push({
                    bool: {
                        must_not: [
                            {
                                terms: {
                                    '_decision.Legal Findings?': [true, false],
                                },
                            },
                        ],
                    },
                });
            }

            if (keys.includes('false')) {
                should.push({
                    bool: {
                        must: [
                            {
                                terms: {
                                    '_decision.Legal Findings?': [false],
                                },
                            },
                        ],
                    },
                });
            }

            if (keys.includes('true')) {
                should.push({
                    bool: {
                        must: [
                            {
                                terms: {
                                    '_decision.Legal Findings?': [true],
                                },
                            },
                        ],
                    },
                });
            }
            params.filter.should = [should];
        }
    }

    return params;
};

export const mapLFfacetsToDecisionFacets = (filter: Record<string, any>) => {
    const replaceKeys: Record<string, string> = {};
    const omitKeys: string[] = [];
    CLDFACETS.map(facet => {
        const _f = _.find(CLD_DECISION_FACETS, { label: facet.label });
        if (!_f) omitKeys.push(facet.key);
        else replaceKeys[facet.key] = _f.key;
    });
    omitKeys.forEach(k => (filter.where = _.omit(filter.where, k)));
    const keysToReplace = Object.keys(replaceKeys);
    keysToReplace.forEach(k => {
        const val = _.get(filter.where, k);
        if (val) {
            filter.where = { ...filter.where, [replaceKeys[k]]: val };
            filter.where = _.omit(filter.where, k);
        }
    });

    return { ...filter };
};

export const mapDecisionfacetsToLFFacets = (filter: Record<string, any>) => {
    const replaceKeys: Record<string, string> = {};
    const omitKeys: string[] = [];
    CLD_DECISION_FACETS.map(facet => {
        const _f = _.find(CLDFACETS, { label: facet.label });
        if (!_f) omitKeys.push(facet.key);
        else replaceKeys[facet.key] = _f.key;
    });
    omitKeys.forEach(k => (filter.where = _.omit(filter.where, k)));
    const keysToReplace = Object.keys(replaceKeys);
    keysToReplace.forEach(k => {
        const val = _.get(filter.where, k);
        if (val) {
            filter.where = { ...filter.where, [replaceKeys[k]]: val };
            filter.where = _.omit(filter.where, k);
        }
    });

    return { ...filter };
};

export const getLanguageFilterBasedOnLocale = () => {
    const localToLanguageMap = {
        en: 'English',
        fr: 'French',
        es: 'Spanish',
    };
    const locale = utilities.getLocale();
    return localToLanguageMap[locale];
};

export const INITIAL_DECISION_SEARCH_FILTER = {
    sort: { dateCreated: { order: 'desc' } },
    where: { exists: { field: 'cld.fullDocumentNumber.keyword' }, deleted: false },
    match_phrase: {},
    from: 0,
    limit: 20,
    include: [
        { relation: 'sourceTypes', scope: { fields: ['id', 'title'] } },
        { relation: 'languages', scope: { fields: ['id', 'name'] } },
        { relation: 'relatedDocs', scope: { fields: ['id', 'title', 'slug'] } },
        { relation: 'subjects' },
        { relation: 'phaseOfCases', scope: { fields: ['id', 'title'] } },
        { relation: 'keywords', scope: { fields: ['id', 'title'] } },
        { relation: 'judicialDocuments', scope: { fields: ['id', 'title'] } },
        { relation: 'iccSituations', scope: { fields: ['id', 'title'] } },
        { relation: 'node', scope: { include: [{ relation: 'parent' }] } },
    ],
};
