import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    InputLabel,
    withStyles,
    DialogTitle,
    Typography,
    Button,
    CircularProgress,
    TextField,
    FormControl,
    MenuItem,
    Select,
    FormHelperText,
    Fab,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Theme from '../../Resources/Theme';
import { TState } from '../../RootReducer';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';
import {
    ADVANCED_SEARCH_AND,
    ADVANCED_SEARCH_CASE_NAME,
    ADVANCED_SEARCH_FILE_NUMBER,
    ADVANCED_SEARCH_FORM_QUERY,
    ADVANCED_SEARCH_FULL_TEXT,
    ADVANCED_SEARCH_NOT,
    ADVANCED_SEARCH_OR,
    ADVANCED_SEARCH_PROXIMITY_WORD,
    ADVANCED_SEARCH_TITLE,
} from '../Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps {
    // onClick: Function,
    isLastEntry?: boolean;
    addQuery: () => void;
    removeQuery: (index: number) => void;
    onChange: Function;
    query: object;
    index: number;
}

interface IDispatchProps {
    facets: object;
    iccSituations: { name: string; value: string; key: string }[];
}

class QueryBuilder extends Component<Props & WithTranslationProps> {
    state = {
        formData: {
            queryType: 'allContent',
            operation: 'AND',
            _in: '',
        },
    };

    queryTypes = [
        { name: 'Full text', value: 'allContent', translationKey: ADVANCED_SEARCH_FULL_TEXT },
        { name: 'Title', value: 'title', translationKey: ADVANCED_SEARCH_TITLE },
        { name: 'Case name', value: 'caseName_en', translationKey: ADVANCED_SEARCH_CASE_NAME },
        { name: 'File number', value: 'externalId', translationKey: ADVANCED_SEARCH_FILE_NUMBER },
        // { name: 'ICC situation', value: 'iccSituation' },
        // { name: 'Content type', value: 'lt_contenttype' },
    ];

    operations = [
        { name: 'and', value: 'AND', translationKey: ADVANCED_SEARCH_AND },
        { name: 'or', value: 'OR', translationKey: ADVANCED_SEARCH_OR },
        { name: 'not', value: 'NOT', translationKey: ADVANCED_SEARCH_NOT },
    ];

    // iccSituations = [
    //     { name: 'The Republic of Kenya', value: 'Situation in the Republic Of Kenya' },
    //     { name: 'Libyan Arab Jamahiriya', value: 'Situation in libyan Arab Jamahiriya' },
    //     { name: 'The Republic of Cote d\\\'Ivoire', value: 'Situation in the Republic of Cote d\\\'Ivoire' },
    //     { name: 'Darfur, Sudan', value: 'Situation in Darfur, Sudan' },
    //     { name: 'The Central African Republic', value: 'Situation in the Central African Republic' },
    //     { name: 'The Central African Republic II', value: 'Situation in the Central African Republic II' },
    //     { name: 'The Democratic Republic of the Congo', value: 'Situation in the Democratic Republic of the Congo' },
    //     { name: 'The Republic of Mali', value: 'Situation in the Republic of Mali' },
    //     { name: 'Uganda', value: 'Situation in Uganda' },
    //     { name: 'Registered Vessels of the Union of the Comoros', value: 'Situation in registered Vessels of the Union of the Comoros' },
    //     { name: 'Libya', value: 'Situation in Libya' },
    // ]

    contentTypes: Array<any> = [];

    componentDidMount = () => {
        this.setState({
            formData: { ...this.state.formData, ...this.props.query },
        });
        if (!_.isEmpty(this.props.facets)) {
            _.map(
                _.get(this.props.facets, 'metadata_content_type'),
                _contentType => {
                    this.contentTypes.push({
                        name: _contentType.key,
                        value: _contentType.key,
                    });
                }
            );
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.query !== this.props.query)
            this.setState({ formData: this.props.query });
    };

    handleKeyPress = () => { };

    handleChange = e => {
        const formData = {
            ...this.state.formData,
            [e.target.name]: e.target.value,
        };
        this.setState({ formData });
        this.props.onChange(this.props.index, formData);
    };

    handleClick = () => {
        const { classes, isLastEntry, facets, index } = this.props;
        isLastEntry ? this.props.addQuery() : this.props.removeQuery(index);
    };

    render() {
        const {
            classes,
            isLastEntry,
            facets,
            index,
            getTranslation,
        } = this.props;
        const { formData } = this.state;
        const { queryType = 'allContent', operation, _in } = formData;

        return (
            <div className={classes.root}>
                <Fab
                    className={classes.fab}
                    color='primary'
                    size='small'
                    onClick={this.handleClick}
                >
                    {isLastEntry ? (
                        <i className='material-icons'>add</i>
                    ) : (
                        <i className='material-icons'>remove</i>
                    )}
                </Fab>

                <div className={classes.mainContainer}>
                    <FormControl className={classes.selectControl}>
                        <Select
                            value={queryType || 'allContent'}
                            onChange={this.handleChange}
                            name='queryType'
                        // disableUnderline
                        >
                            {_.map(this.queryTypes, type => (
                                <MenuItem key={type.value} value={type.value}>
                                    {getTranslation(type.translationKey)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className={classes.queryContainer}>
                        {queryType !== 'lt_contenttype' &&
                            queryType !== 'iccSituation' ? (
                            <FormControl className={classes.inputControl}>
                                <Typography variant='subtitle1'>
                                    {getTranslation(ADVANCED_SEARCH_FORM_QUERY)}
                                </Typography>
                                <TextField
                                    fullWidth
                                    // InputProps={{ disableUnderline: true }}
                                    // placeholder="Search"
                                    name='query1'
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}
                                    value={_.get(formData, 'query1') || ''}
                                />
                            </FormControl>
                        ) : (
                            <FormControl className={classes.selectControl}>
                                <Select
                                    value={_in || ''}
                                    displayEmpty
                                    className={classes.selectAlt}
                                    onChange={this.handleChange}
                                    name='_in'
                                >
                                    {_.map(
                                        queryType === 'iccSituation'
                                            ? this.props.iccSituations
                                            : this.contentTypes,
                                        type => (
                                            <MenuItem
                                                key={type.value}
                                                value={type.name}
                                            >
                                                {type.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        )}

                        {(queryType === 'allContent' ||
                            queryType === 'title') && (
                                <Fragment>
                                    <FormControl className={classes.inputControl}>
                                        <Select
                                            style={{ marginRight: 8 }}
                                            value={operation || 'AND'}
                                            onChange={this.handleChange}
                                            name='operation'
                                        >
                                            {_.map(this.operations, type => (
                                                <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                >
                                                    {getTranslation(type.translationKey)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <TextField
                                            fullWidth
                                            name='query2'
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                            value={_.get(formData, 'query2') || ''}
                                        />
                                    </FormControl>
                                </Fragment>
                            )}

                        {queryType === 'allContent' && (
                            <FormControl className={classes.inputControl}>
                                <Typography
                                    align='right'
                                    variant='subtitle1'
                                    className={classes.proximityLabel}
                                >
                                    {getTranslation(
                                        ADVANCED_SEARCH_PROXIMITY_WORD
                                    )}
                                </Typography>
                                <TextField
                                    className={classes.proximity}
                                    name='proximity'
                                    type='number'
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}
                                    value={_.get(formData, 'proximity') || ''}
                                />
                            </FormControl>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Documents, ['facets']),
    iccSituations: _.get(state.App, 'ltdIncludeOptions.iccSituations'),
});

const mapDispatchToProps = dispatch => ({});

const STYLES = theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                flexDirection: 'column',
            },
        },
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 4,
            padding: 4,
            boxSizing: 'border-box',
            marginLeft: 16,
            [theme.breakpoints.down('sm')]: {
                border: `1px solid ${Theme.Colors.Secondary.main}`,
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
                width: '95%',
            },
        },
        fab: {
            background: 'transparent',
            marginLeft: 9,
        },
        selectControl: {
            width: 140,
            margin: '0px 8px',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        proximityLabel: {
            [theme.breakpoints.up('sm')]: {
                flex: 2,
            },
        },
        inputControl: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0px 8px',
            '& h6': {
                marginRight: 6,
                fontWeight: 500,
            },
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        queryContainer: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100vw',
                '& > div': {
                    margin: 8,
                },
            },
        },
        selectAlt: {
            width: 280,
        },
        proximity: {
            // width: 60,
            [theme.breakpoints.up('sm')]: {
                flex: 1,
            },
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(QueryBuilder)));
