import { Models } from '../../Resources/Model';
import _ from 'lodash';
import { TSort, TChip, TCLDDocument } from './@types';
import { TListHeader, INITIAL_FACETS_DECISIONS } from './cld-documents-reducer';
import { defaultDate } from '../../screens/LTD/LeftNav';
import { HISTORY } from '../../screens/RootLayout';
import { TNode } from '../CLDDocumentTree/@types';
import { ODocumentTree } from '../DocumentsTree/redux-config';
import { getAllChildren, findNode } from '../DocumentsTree/documentTree-reducer';
import utilities from '../../Resources/Utils';
import { HOME_URL } from '../LTDDocuments/redux-config';
import { value } from 'popmotion';
import { OCLDDocumentTree } from '../CLDDocumentTree/redux-config';
import moment from 'moment';
import { TState } from '../../RootReducer';
import { OApp, TAppState } from '../../RootReducer/AppReducer';
import Theme from '../../Resources/Theme';
import { getDocumentTypeFromURL, LTD_INCLUDES } from '../DetailPage/redux-config';
import Config from '../../Resources/Config';
import { OAdvancedSearch } from '../AdvancedSearch/redux-config';
import { searchFilters } from '../../screens/CLD/LeftNav';
import {
    mapLFfacetsToDecisionFacets,
    mapDecisionfacetsToLFFacets,
    INITIAL_DECISION_SEARCH_FILTER,
    getLanguageFilterBasedOnLocale,
    transformStaticFilterToSearchParams,
} from './utils';
import config from '../../Resources/Config';
import { CldField } from '../../Services/CldFields.types';
import { CldFieldService } from '../../Services/CldFields.service';

export const CLD_DOCUMENT_BASE_URL = Config.get('CLD_DOCUMENT_BASE_URL');
export const DECISION_ASSESSMENT_KEY = 'decisionAssessment';

export const DECISION_ASSESSMENT_FACETS = [
    { key: 'withLegalFindings', value: 'withLegalFindings' },
    { key: 'withoutLegalFindings', value: 'withoutLegalFindings' },
    { key: 'notAnalysed', value: 'notAnalysed' },
];

export const CLDFACETS = [
    // { key: 'ltdDoc.cld.documentSourceEn', label: 'Source', id: 'source' },
    // { key: 'ltdDoc.cld.situationNameFilterEn', label: 'Situation', id: 'situation' },
    // { key: 'ltdDoc.cld.caseNameFilterEn', label: 'Case', id: 'case' },
    // {
    //     key: 'ltdDoc.languages.name', label: 'Language(s)', featured: [
    //         "Arabic", "Chinese", "Chinese (Simplified)", "English", "French", "Russian", "Spanish"
    //     ], id: 'languages'
    // },
    // { key: 'ltdDoc.cldJudges', label: 'Presiding Judge', orderKey: 'key', id: 'presidingJudges' },
    // { key: 'ltdDoc.cld.typeOfDecisionEn', label: 'Type of Decision', orderKey: 'key', id: 'typeOfDecision' },
    // { key: 'ltdDoc.cld.phaseOfCaseEn', label: 'Phase of Case', orderKey: 'key', id: 'phaseOfCases' },
    { key: 'ltdDoc._decision.documentSourceId', label: 'Source', id: 'source', useResolver: true },
    { key: 'ltdDoc._decision.situationNameFilterId', label: 'Situation', id: 'situation' },
    { key: 'ltdDoc._decision.caseNameFilterId', label: 'Case', id: 'case', useResolver: true },
    { key: 'Importance01', label: 'Level of importance', id: 'importance' },
    {
        key: 'cldFieldLanguage.name__en',
        // key: 'ltdDoc.languages.name',
        label: 'Language(s)',
        featured: ['Arabic', 'Chinese', 'Chinese (Simplified)', 'English', 'French', 'Russian', 'Spanish'],
        id: 'languages',
    },
    { key: 'ltdDoc.cldJudges', label: 'Presiding Judge', orderKey: 'key', id: 'presidingJudges' },
    {
        key: 'ltdDoc._decision.typeOfDecisionId',
        label: 'Type of Decision',
        orderKey: 'key',
        id: 'typeOfDecision',
        useResolver: true,
    },
    {
        key: 'ltdDoc._decision.phaseOfCaseId',
        label: 'Phase of Case',
        orderKey: 'key',
        id: 'phaseOfCases',
        useResolver: true,
    },
];
export const CLD_DECISION_FACETS = [
    // { key: 'cld.documentSourceEn', label: 'Source', id: 'source' },
    // { key: 'cld.situationNameFilterEn', label: 'Situation', id: 'situation' },
    // { key: 'cld.caseNameFilterEn', label: 'Case', id: 'case' },
    // { key: 'cld.typeOfDecisionEn', label: 'Type of Decision', orderKey: 'key', id: 'typeOfDecision' },
    // { key: 'cld.phaseOfCaseEn', label: 'Phase of Case', orderKey: 'key', id: 'phaseOfCases' },
    { key: 'legalFindings', label: 'Decision Assessment', id: 'decisionAssessment', useResolver: true },
    //    {
    //        key: DECISION_ASSESSMENT_KEY,
    //        label: 'Decision Assessment',
    //        id: DECISION_ASSESSMENT_KEY,
    //        useResolver: true,
    //    },
    { key: '_decision.documentSourceId', label: 'Source', id: 'source', useResolver: true },
    { key: '_decision.situationNameFilterId', label: 'Situation', id: 'situation', useResolver: true },
    { key: '_decision.caseNameFilterId', label: 'Case', id: 'case', useResolver: true },
    {
        key: 'languages.name',
        label: 'Language(s)',
        featured: ['Arabic', 'Chinese', 'Chinese (Simplified)', 'English', 'French', 'Russian', 'Spanish'],
        id: 'languages',
    },
    { key: 'cldJudges', label: 'Presiding Judge', orderKey: 'key', id: 'presidingJudges' },
    {
        key: '_decision.typeOfDecisionId',
        label: 'Type of Decision',
        orderKey: 'key',
        id: 'typeOfDecision',
        useResolver: true,
    },
    {
        key: '_decision.phaseOfCaseId',
        label: 'Phase of Case',
        orderKey: 'key',
        id: 'phaseOfCases',
        useResolver: true,
    },
];

export const LIST_ITEM_COLOR = {
    legalFinding: Theme.Colors.Primary.dark,
    decision: '#73481D',
};

export const SituationCaseMapping = _.sortBy(
    [
        { value: 'ICC-02/17-00/00', label: 'Afghanistan' },
        { value: 'ICC-01/19-00/00', label: 'Bangladesh/Myanmar' },
        { value: 'ICC-01/17-00/00', label: 'Burundi' },
        { value: 'ICC-01/05-00/00', label: 'Central African Republic' },
        { value: 'ICC-01/14-00/00', label: 'Central African Republic II' },
        { value: 'ICC-02/11-00/00', label: "Côte d'Ivoire" },
        { value: 'ICC-02/05-00/00', label: 'Darfur, Sudan' },
        { value: 'ICC-01/04-00/00', label: 'Democratic Republic of the Congo' },
        { value: 'ICC-01/16-00/00', label: 'Gabon' },
        { value: 'ICC-01/15-00/00', label: 'Georgia' },
        { value: 'ICC-01/09-00/00', label: 'Kenya' },
        { value: 'ICC-01/11-00/00', label: 'Libya' },
        { value: 'ICC-01/12-00/00', label: 'Mali' },
        { value: 'ICC-01/18-00/00', label: 'Palestine' },
        {
            value: 'ICC-01/13-00/00',
            label: 'Registered Vessels of the Union of the Comoros, the Hellenic Republic and the Kingdom of Cambodia',
        },
        { value: 'ICC-02/04-00/00', label: 'Uganda' },
        { value: 'ICC-02/18-00/00', label: 'Venezuela' },
        { value: 'ICC-01/12-01/18', label: 'AL HASSAN Ag Abdoul Aziz' },
        { value: 'ICC-01/12-01/15', label: 'AL MAHDI Ahmad Al Faqi' },
        { value: 'ICC-01/11-01/17', label: 'AL-WERFALLI Mahmoud Mustafa Busayf' },
        { value: 'ICC-02/05-03/09', label: 'BANDA Abakaer Nourain Abdallah' },
        { value: 'ICC-01/09-01/13', label: 'BARASA Walter' },
        { value: 'ICC-01/05-01/13', label: 'BEMBA GOMBO Jean Pierre et al.' },
        { value: 'ICC-01/05-01/08', label: 'BEMBA GOMBO Jean-Pierre' },
        { value: 'ICC-02/11-02/11', label: 'BLÉ GOUDÉ Charles' },
        { value: 'ICC-01/11-01/11', label: 'GADDAFI Saif Al-Islam' },
        { value: 'ICC-02/11-01/11', label: 'GBAGBO Laurent' },
        { value: 'ICC-02/11-01/15', label: 'GBAGBO Laurent & BLÉ GOUDÉ Charles' },
        { value: 'ICC-02/11-01/12', label: 'GBAGBO Simone' },
        { value: 'ICC-01/09-01/15', label: 'GICHERU Paul & BETT Philip Kipkoech' },
        { value: 'ICC-02/05-01/07', label: 'HARUN Ahmad & KUSHAYB Ali ' },
        { value: 'ICC-02/05-01/12', label: 'HUSSEIN Abdel Raheem Muhammad' },
        { value: 'ICC-01/04-01/07', label: 'KATANGA Germain' },
        { value: 'ICC-01/09-02/11', label: 'KENYATTA Uhuru Muigai' },
        { value: 'ICC-01/11-01/13', label: 'KHALED Al-Tuhamy Mohamed' },
        { value: 'ICC-02/04-01/05', label: 'KONY Joseph et al.' },
        { value: 'ICC-01/04-01/06', label: 'LUBANGA DYILO Thomas' },
        { value: 'ICC-01/04-01/10', label: 'MBARUSHIMANA Callixte' },
        { value: 'ICC-01/04-01/12', label: 'MUDACUMURA Sylvestre' },
        { value: 'ICC-01/04-02/07', label: 'NGUDJOLO CHUI Mathieu' },
        { value: 'ICC-01/04-02/12', label: 'NGUDJOLO CHUI Mathieu' },
        { value: 'ICC-01/04-02/06', label: 'NTAGANDA Bosco' },
        { value: 'ICC-02/04-01/15', label: 'ONGWEN Dominic' },
        { value: 'ICC-01/09-01/11', label: 'RUTO William Samoei & SANG Joshua Arap' },
        { value: 'ICC-01/14-01/18', label: 'YEKATOM Alfred & NGAÏSSONA Patrice-Edouard' },
    ],
    config => config.label
);

export const getSituationCaseFromMapping = (key: string) => {
    const situationCase = _.find(SituationCaseMapping, { value: key });
    return situationCase ? situationCase.label : key;
};

export const SORTS = {
    Relevance: {},
    LegalFinding: {
        Finding: {
            order: 'asc',
        },
    },
    Newest: {
        dateCreated: {
            order: 'desc',
        },
    },
    Newest_LF: {
        'ltdDoc.dateCreated': {
            order: 'desc',
        },
    },
    Oldest: {
        dateCreated: {
            order: 'asc',
        },
    },
    Oldest_LF: {
        'ltdDoc.dateCreated': {
            order: 'asc',
        },
    },
    RecentlyModified: {
        dateModified: {
            order: 'desc',
        },
    },
    RecentlyAdded: {
        dateCreated: {
            order: 'desc',
        },
    },
    TitleLegalFinding: {
        legalFinding: {
            order: 'desc',
        },
    },
    Title: {
        'Title.normalize': {
            order: 'asc',
        },
    },
    title: {
        'title.normalize': {
            order: 'asc',
        },
    },
    decisionTitle: {
        '_decision.title.normalize': {
            order: 'asc',
        },
    },
};

export const SORT_TYPES_LEGAL_FINDINGS = [
    { key: 'Relevance', label: 'Relevance' },
    { key: 'RecentlyModified', label: 'Recently modified' },
    { key: 'RecentlyAdded', label: 'Recently added' },
    { key: 'Newest_LF', label: 'Newest' },
    { key: 'Oldest_LF', label: 'Oldest' },
    { key: 'Title', label: 'Title' },
    { key: 'LegalFinding', label: 'Legal Finding' },
];
export const SORT_TYPES_DECISIONS = [
    { key: 'Relevance', label: 'Relevance' },
    { key: 'Newest', label: 'Newest' },
    { key: 'Oldest', label: 'Oldest' },
    { key: 'decisionTitle', label: 'Title' },
];

const keyToIgnore = ['created', 'parentId'];

export let mapParentIdsToValues = {};
export let mapvaluesToParentIds = {};

export const mapValueAndId = (node: TNode) => {
    mapParentIdsToValues[node.id] = node.keyword_text;
    mapvaluesToParentIds[node.keyword_text] = node.id;
};

export const getChipFromId = (id): TChip => {
    return {
        name: mapParentIdsToValues[id],
        filterType: 'where',
        key: 'parentIds',
        value: id,
    };
};

export const getChipListFromTree = (ids: string[]) => {
    let chips: Array<TChip> = [];
    return _.map(ids, id => {
        const chip: TChip = {
            filterType: 'tree',
            key: id,
            name: mapParentIdsToValues[id],
            value: id,
        };
        return chip;
    });
};

// export const getDownloadUrl = (docId: string) => docId ? `http://35.246.140.76/api/clddocs/${docId}/pdf` : '#';
// export const getDownloadUrl = (docId: string) => docId ? `http://legal-tools.mithyalabs.com/api/clddocs/${docId}/pdf` : '#';
export const getDownloadUrl = (docId: string) => (docId ? `${config.get('BASE_URL')}clddocs/${docId}/pdf` : '#');
export const getSccDownloadUrl = (docId: string) => (docId ? `${config.get('BASE_URL')}sccdocs/${docId}/pdf` : '#');

export const getChipList = (searchFilter, searchTerm, resolveFields: CldField[] = []) => {
    let chips: Array<TChip> = [];
    const where = _.get(searchFilter, 'where');
    const match_phrase = _.get(searchFilter, 'match_phrase');
    const term: TChip = {
        name: searchTerm,
        filterType: 'term',
        key: 'term',
    };
    if (_.isEmpty(where) && _.isEmpty(match_phrase) && _.isEmpty(searchTerm)) return [];

    // if (_.isEmpty(where) && _.isEmpty(searchTerm)) {
    //     let isFieldsEmpty = true;
    //     _.forEach(match_phrase, (v, k) => {
    //         if (!_.isEmpty(v))
    //             isFieldsEmpty = false
    //     });
    //     if (isFieldsEmpty)
    //         return [];
    // }

    _.forEach(where, (values, key) => {
        if (key !== 'sort') {
            if (key === 'created') {
                if (values['gte'] !== defaultDate.dateFrom || values['lte'] !== defaultDate.dateTo)
                    chips.push({ filterType: 'where', name: `${values['gte']} - ${values['lte']}`, key });
            }
            if (key === 'dateCreated') {
                if (values['gte'] !== defaultDate.dateFrom || values['lte'] !== defaultDate.dateTo)
                    chips.push({
                        filterType: 'where',
                        name: `${moment(values['gte']).format('DD.MM.YYYY')} - ${moment(values['lte']).format(
                            'DD.MM.YYYY'
                        )}`,
                        key,
                    });
            } else if (key === 'parentIds') {
                _.forEach(values, id => chips.push(getChipFromId(id)));
            } else if (key.startsWith('_decision.') || key.startsWith('ltdDoc._decision.')) {
                _.forEach(values, value =>
                    chips.push({
                        filterType: 'where',
                        name: CldFieldService.resolveCldField(resolveFields, value),
                        key,
                        value,
                    })
                );
            } else {
                if (!_.isArray(values)) chips.push({ filterType: 'where', name: values, key, value: values });
                else _.forEach(values, value => chips.push({ filterType: 'where', name: value, key, value }));
            }
        }
    });
    _.forEach(match_phrase, (value, key) => {
        chips.push({ filterType: 'match_phrase', value, key, name: value });
    });

    if (_.isEmpty(searchTerm)) return chips;

    return [term, ...chips];
};

const MATCH_FILTER_KEY_LIST = [
    'title',
    'metadata_case_name_icc_naming_convention',
    'metadata_external_identifier',
    'Document Number',
    'documentType',
    'ltdDoc.typeOfDecisionEN',
    'ltdDoc.cld.typeOfDecisionEn',
    'ID',
];

export const APPEAL_CONFIG = [
    {
        value: 'Amends',
        text: 'Amends impugned decision',
        color: '#E5CB3E',
    },
    {
        value: 'Amended by',
        text: 'Amended on appeal',
        color: '#E5CB3E',
    },
    {
        value: 'Confirms',
        text: 'Confirms impugned decision',
        color: '#429D3E',
    },
    {
        value: 'Confirmed by',
        text: 'Confirmed on appeal',
        color: '#429D3E',
    },
    {
        value: 'Remands',
        text: 'Remands impugned decision',
        color: '#6699CC',
    },
    {
        value: 'Remanded by',
        text: 'Remanded on appeal',
        color: '#6699CC',
    },
    {
        value: 'Reverses',
        text: 'Reverses impugned decision',
        color: '#FF0000',
    },
    {
        value: 'Reversed by',
        text: 'Reversed on appeal',
        color: '#FF0000',
    },
];

export const INPUT_PROGRESS_CONFIG = [
    {
        value: 'Input in progress',
        text: 'Input in progress',
        color: '#003366',
    },
    {
        value: 'Input completed',
        text: 'Input completed',
        color: '#003366',
    },
    {
        value: 'Finalised',
        text: 'Finalised',
        color: '#429D3E',
    },
    {
        value: 'Reviewed',
        text: 'Reviewed',
        color: '#FF0000',
    },
];

const DECISION_SEARCH_KEY_MAPPING = [
    {
        key: 'Document Number',
        decisionKey: 'externalId',
    },
    // {
    //     key: 'ltdDoc.typeOfDecisionEN', decisionKey: 'typeOfDecisionEN'
    // },
    {
        key: 'ltdDoc.cld.typeOfDecisionEn',
        decisionKey: 'cld.typeOfDecisionEn',
    },
];

const getDecisionFilter = (filter: any) => {
    _.forEach(DECISION_SEARCH_KEY_MAPPING, mapping => {
        if (_.get(filter, `match_phrase["${mapping.key}"]`)) {
            filter = {
                ...filter,
                match_phrase: { ...filter.match_phrase, [mapping.decisionKey]: filter.match_phrase[mapping.key] },
            };
            filter = { ...filter, match_phrase: _.omit(filter.match_phrase, mapping.key) };
        }
        if (_.get(filter, `where["${mapping.key}"]`)) {
            filter = { ...filter, where: { ...filter.where, [mapping.decisionKey]: filter.where[mapping.key] } };
            filter = { ...filter, where: _.omit(filter.where, mapping.key) };
        }
    });

    filter.where = {
        ...filter.where,
        exists: {
            field: '_decision.fullDocumentNumber',
            // field: "cld.fullDocumentNumber.keyword"
        },
        deleted: false,
    };
    filter.include = LTD_INCLUDES;

    filter.should = [
        [
            {
                term: {
                    newVersionAvailable: false,
                },
            },
            {
                bool: {
                    must_not: {
                        exists: {
                            field: 'newVersionAvailable',
                        },
                    },
                },
            },
        ],
    ];

    return filter;
};

export const getSituationCase = (document, fields: CldField[] = []) => {
    const caseName = CldFieldService.resolveCldField(fields, _.get(document, '_decision.caseNameCardId'));
    if (caseName && caseName !== 'None (Situation only)') return caseName;
    else return CldFieldService.resolveCldField(fields, _.get(document, '_decision.situationNameCardId'));
    // if (_.get(document, 'cld.caseNameCardEn') && _.get(document, 'cld.caseNameCardEn') !== "None (Situation only)")
    //     return _.get(document, 'cld.caseNameCardEn');
    // else
    //     return _.get(document, 'cld.situationNameCardEn');
};

export const getDecisionMetaDetail = (document, fields: CldField[] = []) => {
    let meta: Array<string> = [];
    // meta.push(_.get(document, 'cld.documentSourceEn') || _.get(document, 'source'));
    meta.push(
        CldFieldService.resolveCldField(fields, _.get(document, '_decision.documentSourceId')) ||
            _.get(document, 'source')
    );
    meta.push(getSituationCase(document, fields));
    if (_.get(document, 'dateCreated')) meta.push(moment(document.dateCreated).format('DD MMMM YYYY'));

    return _.filter(meta).join(' | ');
};
export const getLegalFindingMetaDetail = (document, cldFields?: CldField[]) => {
    let meta: Array<string> = [];
    meta.push(_.get(document, 'ltdDoc.cld.documentSourceEn') || _.get(document, 'ltdDoc.source'));
    meta.push(getSituationCase(_.get(document, 'ltdDoc')));
    if (_.get(document, 'ltdDoc.dateCreated'))
        meta.push(moment(_.get(document, 'ltdDoc.dateCreated')).format('DD MMMM YYYY'));
    meta.push(_.get(document, 'ltdDoc.cld.fullDocumentNumber') || _.get(document, 'Document Number'));
    meta.push(getSituationCase(document.ltdDoc, cldFields));
    return _.filter(meta).join(' | ');
};

export const getLegalFindingMetaDetailDetailPage = (document, key?: keyof TCLDDocument) => {
    let meta: Array<string> = [];
    meta.push(_.get(document, 'ltdDoc.cld.documentSourceEn') || _.get(document, 'ltdDoc.source'));
    meta.push(getSituationCase(_.get(document, 'ltdDoc')));
    if (document.ltdDoc && document.ltdDoc.dateCreated)
        meta.push(moment(document.ltdDoc.dateCreated).format('DD MMMM YYYY'));
    meta.push(_.get(document, 'ltdDoc.cld.fullDocumentNumber') || _.get(document, 'Document Number'));

    return _.filter(meta).join(' | ');
};

export const buildFilterFromFormData = (formData: Record<string, any>, isExactSearch: boolean = false) => {
    let range;
    if (!_.isEmpty(_.get(formData, 'gte')) && !_.isEmpty(_.get(formData, 'lte')))
        range = {
            dateCreated: {
                type: 'range',
                gte: new Date(_.get(formData, 'gte')).getTime(),
                lte: new Date(_.get(formData, 'lte')).getTime(),
            },
        };

    formData = { ..._.omit(formData, ['gte', 'lte']), ...range };
    formData = _.omit(formData, 'term');

    let query = { match_phrase: {}, where: {} };
    if (!_.isEmpty(formData)) {
        _.forEach(formData, (value, key) => {
            if (key === 'created' || key === 'dateCreated' || key === 'ltdDoc.dateCreated' || isExactSearch)
                query = { ...query, where: { ...query.where, [key]: value } };
            else if (value !== '') query = { ...query, match_phrase: { ...query.match_phrase, [key]: value } };
        });
        // _.forEach(formData, (value, key) => {
        //     if (key !== 'metadata_date_created')
        //         query.match = { [key]: value }
        // })
    }

    return query;
};

export const getSearchType = key =>
    _.indexOf(MATCH_FILTER_KEY_LIST, key) > -1 ? 'match_phrase' : key === 'term' ? 'term' : 'where';

const NUM_FRAGMENTS = 3;

const STATIC_FILTER = {
    highlight: {
        pre_tags: ["<span class='search-highlight'>"],
        post_tags: ['</span>'],
        type: 'unified',
        fields: {
            _all: {
                number_of_fragments: NUM_FRAGMENTS,
                fragment_size: 300,
            },
            legalFinding: {
                number_of_fragments: NUM_FRAGMENTS,
                fragment_size: 300,
            },
            title: {
                number_of_fragments: NUM_FRAGMENTS,
                fragment_size: 300,
            },
            'ltdDoc.title': {
                number_of_fragments: NUM_FRAGMENTS,
                fragment_size: 300,
            },
            'docPages.text': {
                number_of_fragments: NUM_FRAGMENTS,
                fragment_size: 300,
            },
            caseName_en: {},
            externalId: {},
        },
    },
};

export class CLDDocuments extends Models {
    constructor() {
        super('clddocs', {
            CLD_APPLY_OPERATION: 'CLD_APPLY_OPERATION',
            CLD_APPLY_FILTER: 'CLD_APPLY_FILTER',
            CLD_SET_CURRENT_PAGE: 'CLD_SET_CURRENT_PAGE',
            CLD_APPLY_SORT: 'CLD_APPLY_SORT',
            CLD_SEARCHING_DOCUMENTS: 'CLD_SEARCHING_DOCUMENTS',
            CLD_DOCUMENTS_SEARCH_COMPLETED: 'CLD_DOCUMENTS_SEARCH_COMPLETED',
            CLD_SET_LIST_HEADER: 'CLD_SET_LIST_HEADER',
            CLD_SET_CHIP_LIST: 'CLD_SET_CHIP_LIST',
            CLD_REMOVE_CHIP: 'CLD_REMOVE_CHIP',
            CLD_APPLY_SEARCH_TERM: 'CLD_APPLY_SEARCH_TERM',
            CLD_SET_SHOW_MATCHED_TEXT: 'CLD_SET_SHOW_MATCHED_TEXT',
            CLD_SET_DECISION_SEARCH: 'CLD_SET_DECISION_SEARCH',
            CLD_TOGGLE_DOCUMENT_SELECTION: 'CLD_TOGGLE_DOCUMENT_SELECTION',
            CLD_CLEAR_DOCUMENT_SELECTION: 'CLD_CLEAR_DOCUMENT_SELECTION',
            CLD_SELECT_ALL_DOCUMENTS: 'CLD_SELECT_ALL_DOCUMENTS',
            CLD_SET_FORM_DATA: 'CLD_SET_FORM_DATA',
            CLD_UNSET_FORM_DATA: 'CLD_UNSET_FORM_DATA',
            CLD_CLEAR_FORM_DATA: 'CLD_CLEAR_FORM_DATA',
            LEGAL_FINDING_DELETED: 'LEGAL_FINDING_DELETED',
            CLD_SET_KEYWORD_SEARCH: 'CLD_SET_KEYWORD_SEARCH',
            CLD_RESET_VALUES: 'CLD_RESET_VALUES',
            CLD_DOCUMENT_ERROR: 'CLD_DOCUMENT_ERROR',
            CLD_SET_SIDEBAR_TAB: 'CLD_SET_SIDEBAR_TAB',
            CLD_SET_KEYWORD_TEXT: 'CLD_SET_KEYWORD_TEXT',
            CLD_RESET_DOCUMENT_ERROR: 'CLD_RESET_DOCUMENT_ERROR',
            CLD_SET_RECORD_PER_PAGE: 'CLD_SET_RECORD_PER_PAGE',
            CLD_SET_HIGHLIGHTER_CONFIG: 'CLD_SET_HIGHLIGHTER_CONFIG',
        });

        this.actions.MODEL_ENTITY_RECEIVED = 'CLD_SEARCH_SUCCESS';
    }

    entities = ['search'];

    setHighlighter = (config: string) => dispatch =>
        dispatch({ type: this.actions.CLD_SET_HIGHLIGHTER_CONFIG, data: config });

    setRecordPerPage = (recordPerPage: number) => dispatch =>
        dispatch({
            type: this.actions.CLD_SET_RECORD_PER_PAGE,
            data: recordPerPage,
        });

    setDocumentError = (message: string) => dispatch =>
        dispatch({
            type: this.actions.CLD_DOCUMENT_ERROR,
            data: message,
        });

    setSidebarTab = (tab: number) => dispatch =>
        dispatch({
            type: this.actions.CLD_SET_SIDEBAR_TAB,
            data: tab,
        });
    setKeywordText = (text: string) => dispatch =>
        dispatch({
            type: this.actions.CLD_SET_KEYWORD_TEXT,
            data: text,
        });

    resetDocumentError = () => dispatch =>
        dispatch({
            type: this.actions.CLD_RESET_DOCUMENT_ERROR,
        });

    resetValues = () => dispatch => {
        dispatch({
            type: this.actions.CLD_RESET_VALUES,
        });
        dispatch(this.applySort('RecentlyAdded'));
        dispatch(this.searchDocuments());
    };

    deleteLegalFinding = (legalFindingIds: string | string[]) => dispatch => {
        return new Promise(async (resolve, reject) => {
            try {
                // const res = await utilities.request({
                //     url: `clddocs/${legalFindingIds}`,
                //     method: 'DELETE'
                // });
                const ids = _.isArray(legalFindingIds) ? legalFindingIds : [legalFindingIds];
                const res = await utilities.request({
                    url: `/clddocs/remove-lf`,
                    method: 'DELETE',
                    params: { ids },
                });
                dispatch(OApp.showToast('Legal finding deleted.', 'success'));
                const detailScreen = getDocumentTypeFromURL();
                window.location.reload();
                // if (detailScreen === 'none' || detailScreen === 'legalFinding')
                //     dispatch({
                //         type: this.actions.LEGAL_FINDING_DELETED,
                //         data: legalFindingIds
                //     })
                // else
                //     dispatch(this.searchDocuments())
            } catch (error) {
                dispatch(OApp.showToast('Something went wrong. Please try again.', 'error'));
            }
        });
    };

    setFormData = (key: string, value: any) => dispatch => {
        if (_.isEmpty(value)) dispatch(this.unsetFormData(key));
        else
            dispatch({
                type: this.actions.CLD_SET_FORM_DATA,
                data: {
                    key,
                    value,
                },
            });
    };
    unsetFormData = (key: string) => dispatch => {
        if (key === 'term') dispatch(this.applySearchTerm(''));

        dispatch({
            type: this.actions.CLD_UNSET_FORM_DATA,
            data: key,
        });
    };

    clearForm = () => dispatch => dispatch({ type: this.actions.CLD_CLEAR_FORM_DATA });

    // MAINLIST DOCUMENTS CHECK CONTROL
    toggleSelect = (id: string) => dispatch => {
        dispatch({
            type: this.actions.CLD_TOGGLE_DOCUMENT_SELECTION,
            data: id,
        });
    };

    clearSelection = () => dispatch => {
        dispatch({
            type: this.actions.CLD_CLEAR_DOCUMENT_SELECTION,
        });
    };

    selectAll = (_ids?: string[]) => (dispatch, getState) => {
        if (_.isArray(_ids) && !_.isEmpty(_ids)) {
            dispatch({
                type: this.actions.CLD_SELECT_ALL_DOCUMENTS,
                data: _ids,
            });
            return;
        }
        const state: TState = getState();
        const { CLDDocuments } = state;
        // const validDocs = CLDDocuments.documents.filter(doc => {
        //     if (CLDDocuments.isDecisionSearch)
        //         if (doc.hit.ltdDoc)
        //             return true
        //         else
        //             return false
        //     return true

        // })
        const ids = CLDDocuments.documents.map(doc => {
            if (CLDDocuments.isDecisionSearch && doc.hit) return doc.hit.id;
            else if (!CLDDocuments.isDecisionSearch) return doc.hit.id;
        });

        dispatch({
            type: this.actions.CLD_SELECT_ALL_DOCUMENTS,
            data: ids,
        });
    };

    applySort = (sort: TSort) => (dispatch, getState) => {
        const { CLDDocuments } = getState();
        let filter = _.get(CLDDocuments, 'filter') || {};
        let newFilter = { ...filter, sort: SORTS[sort] };

        dispatch({
            type: this.actions.CLD_APPLY_SORT,
            data: {
                filter: newFilter,
                sort,
            },
        });
        return newFilter;
    };

    setChiplist = (list: Array<TChip>) => dispatch =>
        dispatch({
            type: this.actions.CLD_SET_CHIP_LIST,
            data: list,
        });

    // Set Header Text for Document Listing
    setListHeader = (label: TListHeader) => dispatch => {
        dispatch({
            type: this.actions.CLD_SET_LIST_HEADER,
            data: label,
        });
    };

    // If value is not passed, this function will do a RESET on the key
    // i.e: it will remove the facet for the key
    removeChip = (chip: TChip, applySearch: boolean = true) => async (dispatch, getState) => {
        const { key, value, filterType } = chip;
        const { CLDDocuments, CLDDocumentTree } = getState();
        const { chipList, isKeywordSearch = false } = CLDDocuments;
        const { itemsSelected, idsToSearch, tree } = CLDDocumentTree;
        const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;
        // if (chip.filterType === 'tree') {
        const newChipList = _.filter(chipList, _chip => _chip.value !== chip.value);
        dispatch({
            type: this.actions.CLD_SET_CHIP_LIST,
            data: newChipList,
        });
        // }

        if (filterType === 'tree') {
            const _itemsSelected = _.difference(itemsSelected, [value]);
            dispatch({
                type: OCLDDocumentTree.actions.CLD_TREE_ITEM_UNSELECTED,
                data: {
                    itemsSelected: _itemsSelected,
                    idsToSearch: _.filter(idsToSearch, obj => _.indexOf(_itemsSelected, obj.id) > -1),
                },
            });

            dispatch(this.searchDocuments(0, true, true, true));
            return;
        }

        let filter = _.get(CLDDocuments, 'filter') || {};
        const searchType = getSearchType(key);

        if (key === 'parentIds') {
            const node: TNode = await findNode(value!);
            const idsToRemove = node.childrenIds || [];

            const _idsToSearch = _.filter(idsToSearch, id => id !== value);
            dispatch({
                type: OCLDDocumentTree.actions.CLD_TREE_ITEM_UNSELECTED,
                data: {
                    itemsSelected: _.isEmpty(_idsToSearch) ? [] : _.difference(itemsSelected, [...idsToRemove, value]),
                    idsToSearch: _idsToSearch,
                },
            });
        }

        if (searchType === 'term') {
            dispatch(this.applySearchTerm(''));
        } else if (searchType === 'match_phrase') {
            let field = _.get(filter.where, key);

            if (_.isEmpty(field)) filter = { ...filter, match_phrase: { ..._.omit(filter.match_phrase, key) } };
            else {
                // const newFields = _.filter(field, (val) => val !== value);
                // if (_.isEmpty(newFields))
                filter = { ...filter, where: { ..._.omit(filter.where, key) } };
                // else
                // filter = { ...filter, where: { ...filter.where, [key]: newFields } }
            }
        } else {
            let field = _.get(filter.where, key);
            if (_.isEmpty(value)) {
                filter = { ...filter, where: { ..._.omit(filter.where, key) } };
            } else {
                const newFields = _.filter(field, val => val !== value);
                if (_.isEmpty(newFields)) filter = { ...filter, where: { ..._.omit(filter.where, key) } };
                else filter = { ...filter, where: { ...filter.where, [key]: newFields } };
            }
        }

        if (_.isEmpty(filter.where)) filter = _.omit(filter, 'where');
        if (_.isEmpty(filter.match_phrase)) filter = _.omit(filter, 'match_phrase');

        if (_.isEmpty(newChipList)) {
            if (isDecisionSearch) dispatch(this.applySort('Newest'));
            else dispatch(this.applySort('RecentlyAdded'));
        }

        dispatch({
            type: this.actions.CLD_APPLY_FILTER,
            data: filter,
        });

        if (applySearch) dispatch(this.searchDocuments(0, true, true, isKeywordSearch));

        return filter;
    };

    applyFacet = (key, value) => (dispatch, getState) => {
        const { CLDDocuments } = getState();
        let filter = _.get(CLDDocuments, 'filter') || {};
        let field: string[] = _.get(filter.where, key);
        if (_.isEmpty(field)) filter = { ...filter, where: { ...filter.where, [key]: [value] } };
        else {
            if (field.indexOf(value) === -1) field.push(value);
            filter = { ...filter, where: { ...filter.where, [key]: field } };
        }
        dispatch({
            type: this.actions.CLD_APPLY_FILTER,
            data: filter,
        });

        return filter;
    };

    applyQuery = (formData, isExactSearch = false) => (dispatch, getState) => {
        const { CLDDocuments } = getState();

        let filter = _.get(CLDDocuments, 'filter') || {};
        const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;

        // If form fields are empty, remove the fields from search filter
        const formKeys = [...searchFilters.map(f => f.name)];
        _.forEach(formKeys, k => {
            if (!formData[k]) {
                filter = { ...filter, where: _.omit(filter.where, k) };
                filter = { ...filter, match_phrase: _.omit(filter.match_phrase, k) };
            }
        });
        const dateKeys = ['ltdDoc.dateCreated', 'dateCreated'];
        if (!formData['gte'] || !formData['lte']) {
            dateKeys.map(k => {
                filter = { ...filter, where: _.omit(filter.where, k) };
            });
        }

        // If search is changed from Legal finding to Decisions or vice versa, configure the facet keys
        // If Decision search, convert all LF facet keys to Decision facet keys

        // if (isDecisionSearch) {
        //     filter = mapLFfacetsToDecisionFacets(filter);
        // } else {
        //     filter = mapDecisionfacetsToLFFacets(filter);
        // }

        if (formData.match_phrase) {
            if (_.isEmpty(formData.match_phrase['Document Number']))
                formData = { ...formData, match_phrase: _.omit(formData.match_phrase, 'Document Number') };
            if (_.isEmpty(formData.match_phrase['documentType']))
                formData = { ...formData, match_phrase: _.omit(formData.match_phrase, 'documentType') };
        }
        if (formData.where) {
            if (_.isEmpty(formData.where['Document Number']))
                formData = { ...formData, where: _.omit(formData.where, 'Document Number') };
            if (_.isEmpty(formData.where['documentType']))
                formData = { ...formData, where: _.omit(formData.where, 'documentType') };
        }

        // Check if filter is empty
        // if empty, sort to:
        // 1. "Newest" if searching form Decisions
        // 2. "Recently added" if searching for Legal findings
        let filter_;

        if (
            !_.isEmpty(formData.match_phrase) ||
            !_.isEmpty(formData.where) ||
            !_.isEmpty(_.get(CLDDocuments, 'formData.term')) ||
            !_.isEmpty(_.get(CLDDocuments, 'searchTerm'))
        ) {
            if (isExactSearch) {
                if (isDecisionSearch) filter_ = dispatch(this.applySort('Newest'));
                else filter_ = dispatch(this.applySort('LegalFinding'));
            } else {
                filter_ = dispatch(this.applySort('Relevance'));
            }
        } else {
            if (isDecisionSearch) filter_ = dispatch(this.applySort('Newest'));
            else filter_ = dispatch(this.applySort('RecentlyAdded'));
        }

        const _where = { ...filter.where, ...formData.where };

        const _match_phrase = _.omit({ ...filter.match_phrase, ...formData.match_phrase }, ['lte', 'gte']);
        dispatch({
            type: this.actions.CLD_APPLY_FILTER,
            data: {
                ...filter,
                ...formData,
                where: _where,
                match_phrase: _match_phrase,
                sort: _.get(filter_, 'sort'),
            },
        });
    };

    applyFilter = _filter => (dispatch, getState) => {
        const { CLDDocuments } = getState();
        let filter = _.get(CLDDocuments, 'filter') || {};
        let newFilter = {};
        newFilter = { ...filter, where: { ...filter.where, ..._filter } };
        dispatch({
            type: this.actions.CLD_APPLY_FILTER,
            data: newFilter,
        });
    };

    applySearchTerm = text => (dispatch, getState) => {
        dispatch({
            type: this.actions.CLD_APPLY_SEARCH_TERM,
            data: text,
        });
        if (_.isEmpty(text)) return;

        // const { CLDDocuments } = getState();
        // const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;
        // if (isDecisionSearch)
        dispatch(this.applySort('Relevance'));
        // else
        //     dispatch(this.applySort('RecentlyAdded'))
    };

    showMatchedText = (shouldShow: boolean) => dispatch =>
        dispatch({
            type: this.actions.CLD_SET_SHOW_MATCHED_TEXT,
            data: shouldShow,
        });

    applyOperation = (operation: string) => dispatch => {
        dispatch({
            type: this.actions.CLD_APPLY_OPERATION,
            data: operation,
        });
    };

    setDecisionSearch = (isDecisionSearch: boolean) => (dispatch, getState) => {
        const state: TState = getState();
        const prevSearchType = state.CLDDocuments.isDecisionSearch;
        if (prevSearchType !== isDecisionSearch)
            dispatch(this.applySort(isDecisionSearch ? 'Newest' : 'RecentlyAdded'));
        dispatch({
            type: this.actions.CLD_SET_DECISION_SEARCH,
            data: isDecisionSearch,
        });
    };

    // Search for documents
    // pageNumber: pagination page number
    // isTreeSearch: boolean value -> true if the search is from document tree/facets
    //                                false if the search is from input fields

    applyLocaleFilter = () => async (dispatch, getState) => {
        const { CLDDocuments } = getState();
        const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;
        const id = 'languages';
        const facet = (isDecisionSearch ? CLD_DECISION_FACETS : CLDFACETS).find(f => f.id === id);
        if (!facet) return;
        dispatch(this.applyFacet(facet.key, getLanguageFilterBasedOnLocale()));
        await utilities.delay(100);
    };

    searchDocuments = (
        pageNumber = 0,
        isTreeSearch = false,
        preservePrevSearch = false,
        keywordSearch = false
    ) => async (dispatch, getState) => {
        dispatch(this.clearSelection());
        dispatch(this.resetDocumentError());
        if (HISTORY) HISTORY.push(HOME_URL().path);
        await dispatch(this.applyLocaleFilter());

        const { CLDDocuments, CLDDocumentTree, AdvancedSearch, CldFields } = getState();
        const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;
        const recordPerPage = _.get(CLDDocuments, 'recordPerPage') || 10;
        const startFrom = pageNumber * recordPerPage;

        let searchTerm = _.get(CLDDocuments, 'searchTerm') || '';
        const sort: TSort = _.get(CLDDocuments, 'sort') || '';
        const highterConfig = _.get(CLDDocuments, 'highlighterConfig') || '';
        const formData: Record<string, any> = _.get(CLDDocuments, 'formData') || '';
        const itemsSelected = _.get(CLDDocumentTree, 'itemsSelected') || [];
        const idsToSearch = _.get(CLDDocumentTree, 'idsToSearch') || [];
        const opr = _.get(CLDDocuments, 'operation') || 'AND';
        const advancedSearchOperator = _.get(AdvancedSearch, 'operator') || 'AND';
        let searchFilter = _.get(CLDDocuments, 'filter') || {};
        if (isDecisionSearch) {
            searchFilter = mapLFfacetsToDecisionFacets(searchFilter);
        } else {
            searchFilter = mapDecisionfacetsToLFFacets(searchFilter);
        }

        if (isDecisionSearch && _.get(formData, 'ID')) {
            dispatch({
                type: this.actions.CLD_DOCUMENTS_SEARCH_COMPLETED,
                data: { results: [], total: 0 },
            });
            return dispatch(
                this.setDocumentError(
                    'Please note that a legal finding ID is a valid parameter to search only for legal findings, not for decisions.'
                )
            );
        }

        // const _filter = OAdvancedSearch.handleSituationCaseSearch(searchFilter, searchTerm, isDecisionSearch);
        searchFilter = {
            ...searchFilter,
            from: startFrom,
            limit: recordPerPage,
            include: [
                {
                    relation: 'ltdDoc',
                    scope: {
                        include: LTD_INCLUDES,
                    },
                },
            ],
        };

        // searchFilter = { ...searchFilter, where: _.omit(searchFilter.where, 'dateCreated') }

        let newFilter = { ...searchFilter };
        const _where = _.get(_.omit(searchFilter, 'where.dateCreated'), 'where');

        if (!isDecisionSearch && _.get(searchFilter, 'where.dateCreated')) {
            newFilter.where = {
                ..._where,
                'ltdDoc.dateCreated': {
                    ..._.get(searchFilter, 'where.dateCreated'),
                    gte: moment(_.get(searchFilter, 'where.dateCreated.gte')).toDate(),
                    lte: moment(_.get(searchFilter, 'where.dateCreated.lte')).toDate(),
                },
            };
            newFilter = _.omit(newFilter, 'where.dateCreated');
        }

        dispatch({
            type: this.actions.CLD_SET_CURRENT_PAGE,
            data: pageNumber,
        });

        dispatch({
            type: this.actions.CLD_SEARCHING_DOCUMENTS,
        });

        // Set List header
        // Legal findings or decisions
        const isSearchFieldsEmpty =
            _.isEmpty(searchFilter.match_phrase) &&
            _.isEmpty(searchFilter.where) &&
            (sort === 'Newest' || sort === 'RecentlyAdded');
        if (isDecisionSearch) {
            if (isSearchFieldsEmpty && _.isEmpty(searchTerm)) dispatch(this.setListHeader('LATEST DECISIONS'));
            else dispatch(this.setListHeader('DECISIONS'));
        } else {
            if (isSearchFieldsEmpty && _.isEmpty(searchTerm)) dispatch(this.setListHeader('LATEST LEGAL FINDINGS'));
            else dispatch(this.setListHeader('LEGAL FINDINGS'));
        }

        // dispatch({
        //     type: this.actions.CLD_SET_CHIP_LIST,
        //     data: getChipList(searchFilter, searchTerm)
        // })
        let chiplist: Array<any> = [];

        let ltdParams = {
            advancedSearchOperator,
            term: OAdvancedSearch.formatSearchTerm(searchTerm, isDecisionSearch),
            filter: isDecisionSearch ? getDecisionFilter(newFilter) : newFilter,
        };

        const cldParams = {
            keywordIds: idsToSearch,
            opr,
            ...ltdParams,
        };
        let params = isDecisionSearch ? ltdParams : cldParams;

        // If CLD page, add filter of documentTypeEN: Decision
        if (window.location.pathname.indexOf('cld') > -1 && isDecisionSearch) {
            params.filter = {
                ...(params.filter || {}),
                where: {
                    ...((params.filter || {}).where || {}),
                    documentTypeEN: 'Decision',
                },
            };
        }

        // handel static filter
        params = transformStaticFilterToSearchParams(params, isDecisionSearch) as any;

        return new Promise((resolve, reject) => {
            utilities
                .request({
                    url: isDecisionSearch ? `ltddocs/search` : `/clddocs/search`,
                    params: {
                        ...params,
                        filter: {
                            ...(highterConfig ? JSON.parse(highterConfig) : STATIC_FILTER),
                            ...params.filter,
                            where: {
                                ...(_.get(params, 'filter.where') || {}),
                                deleted: false,
                            },
                        },
                    },
                })
                .then(
                    res => {
                        dispatch({
                            type: this.actions.CLD_DOCUMENTS_SEARCH_COMPLETED,
                            data: res.data,
                        });
                        dispatch({
                            type: this.actions.CLD_SET_KEYWORD_SEARCH,
                            data: false,
                        });
                        chiplist = [...chiplist, ...getChipList(searchFilter, searchTerm, CldFields.cldFields)];
                        chiplist = [
                            ...chiplist,
                            ..._.map(res.data.chips, chip => {
                                if (chip.totalChildren === 0) return chip;
                                else return { ...chip, name: chip.name + ':' + chip.totalChildren };
                            }),
                        ];

                        dispatch({
                            type: this.actions.CLD_SET_CHIP_LIST,
                            data: chiplist,
                        });
                        return resolve(res);
                    },
                    err => {
                        dispatch({
                            type: this.actions.CLD_DOCUMENTS_SEARCH_COMPLETED,
                            data: { results: [], total: 0 },
                        });
                        const paramLength = JSON.stringify(params).length;

                        const errorMsg =
                            paramLength > 5100 || _.get(err, 'response.data.error.statusCode') === 413
                                ? 'Please narrow down your keyword search as the query reached the maximum limit (1000) of criteria. This may result in an incomplete search.'
                                : 'Something went wrong. Please try again';
                        dispatch(this.setDocumentError(errorMsg));
                        reject(err);
                    }
                );
        });
    };
}

export let OCLDDocuments = new CLDDocuments();
