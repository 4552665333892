import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    DialogTitle,
    Typography,
    Button,
    CircularProgress,
    Theme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import DialogHeader from './DialogHeader';
import { OAuth } from './redux-config';
import { TDialogVariant } from './@types';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';
import {
    AUTHINCATION_FORGOT_PASSWORD,
    AUTHINCATION_NEW_USER,
    AUTHINCATION_SIGNUP_HERE,
    MENU_LIST_EMAIL,
    MENU_LIST_LOGIN,
} from '../Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps { }

interface IDispatchProps {
    openAuthDialog: (variant: TDialogVariant) => void;
    sendResetLink: Function;
    closeDialog: Function;
}

class ForgotPassword extends Component<Props & WithTranslationProps> {
    state = {
        loading: false,
        error: '',
        successMessage: '',
        formData: {
            email: '',
        },
    };

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    submit = () => {
        this.setState({ loading: true, successMessage: '' });
        this.props.sendResetLink(this.state.formData).then(
            res => {
                this.setState({
                    loading: false,
                    error: '',
                    successMessage: `Reset link has been sent to ${this.state.formData.email}`,
                });
                // this.props.closeDialog();
            },
            err => {
                this.setState({
                    loading: false,
                    error: _.get(err, 'response.data.error.message'),
                });
            }
        );
    };

    signup = () => {
        this.props.openAuthDialog('signup');
    };

    login = () => {
        this.props.openAuthDialog('login');
    };

    render() {
        const { classes, getTranslation } = this.props;
        const { formData, error, loading, successMessage = '' } = this.state;

        return (
            <div className={classes.root}>
                <DialogHeader title={getTranslation(AUTHINCATION_FORGOT_PASSWORD) || 'Forgot password'} />

                <div className={classes.formContent}>
                    <Typography className={classes.errorMessage}>
                        {error}
                    </Typography>
                    <Typography className={classes.successMessage}>
                        {successMessage}
                    </Typography>
                    <ValidatorForm onSubmit={this.submit}>
                        <TextValidator
                            fullWidth
                            label={getTranslation(MENU_LIST_EMAIL) || 'Email'}
                            name='email'
                            validators={['required', 'isEmail']}
                            errorMessages={['Required', 'Incorrect Email']}
                            value={_.get(formData, 'email') || ''}
                            onChange={this.handleChange}
                        />
                        <Button fullWidth color='primary' type='submit'>
                            {loading ? (
                                <CircularProgress size={32} />
                            ) : (
                                'Send reset link'
                            )}
                        </Button>
                    </ValidatorForm>
                    <Typography
                        onClick={this.login}
                        className={classes.clickable}
                        variant='caption'
                    >
                        {getTranslation(MENU_LIST_LOGIN)}
                    </Typography>
                    <div className={classes.footer}>
                        <Typography variant='caption'>
                            {getTranslation(AUTHINCATION_NEW_USER)}?
                        </Typography>
                        <Typography
                            variant='caption'
                            onClick={this.signup}
                            className={classes.clickable}
                        >
                            {getTranslation(AUTHINCATION_SIGNUP_HERE)}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    openAuthDialog: (variant: TDialogVariant) =>
        dispatch(OAuth.openAuthDialog(variant)),
    sendResetLink: formData => dispatch(OAuth.sendResetLink(formData)),
    closeDialog: () => dispatch(OAuth.closeAuthDialog()),
});

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: 500,
            },
            paddingBottom: 30,
        },
        header: {
            height: 44,
            display: 'flex',
            background: theme.palette.primary.main,
            alignItems: 'center',
            paddingLeft: 15,
            justifyContent: 'space-between',
            '& h6': {
                textTransform: 'uppercase',
                color: THEME.Colors.Grayscale.White,
            },
            '& i': {
                color: THEME.Colors.Grayscale.White,
            },
        },
        formContent: {
            padding: '0px 50px',
            '& form': {
                marginTop: 8,
            },
            '& > form > div': {
                margin: '10px 0px',
            },
            '& button': {
                background: theme.palette.primary.main,
                marginTop: 8,
                color: 'white',
                transition: '200ms linear',
                '&:hover': {
                    background: theme.palette.primary.light,
                },
            },
            '& > span': {
                marginTop: 10,
            },
        },
        footer: {
            marginTop: 26,
            display: 'flex',
            justifyContent: 'space-between',
        },
        clickable: {
            display: 'inline-block',
            color: theme.palette.primary.dark,
            cursor: 'pointer',
        },
        errorMessage: {
            color: THEME.Colors.Error,
            marginTop: 10,
        },
        successMessage: {
            color: THEME.Colors.Success,
            marginTop: 10,
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(ForgotPassword)));
