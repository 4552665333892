import utilities from '../Resources/Utils';
import { SimilarDocByExternalIdResponse } from './CldDocs.types';

class CldDocs {
    static getSimilarDocByExternalId(selectedCldDocNumber: string, ltdDocId: string, cldFieldLanguageId: string) {
        return utilities.request<SimilarDocByExternalIdResponse>({
            url: `CldDocs/similar-doc-by-externalId`,
            params: {
                selectedCldDocNumber,
                ltdDocId,
                cldFieldLanguageId,
            },
        });
    }

    static getNextFinding(documentNumber: string, languageId: string) {
        return utilities.request({
            url: `CldDocs/next-finding`,
            params: {
                documentNumber,
                cldFieldLanguageId: languageId,
            },
        });
    }
}

export default CldDocs;
