import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">
        调查文档处理系统
    </h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    调查文档处理系统（I-DOC）是帮
助处理违反国际刑法或者人权
法的相关事实资料的电子工具
。该系统可协助的工作包括记
录、调查、分析、准备案件、
庭审和后续诉讼事宜等。国际
刑事法院法律工具数据库和
Lexsitus为用户提供核心国际犯
罪相关的必要法律资源和信息
，而I-DOC系统则为实务工作者
提供了根据这些信息计划、记录
和调查工作的工具。

<br/><br/>

I-DOC系统可以将信息系统化。
这些信息可以是大量的文档和
证据、犯罪事实和相关背景、
嫌疑人和涉及的机构、受害人
和目击者、受保护的财产和物
品以及其它相关的事实情况。
该系统可以提高信息处理精确
性，从而保证工作质量。

<br/><br/>


系统数据库的结构为用户呈现
不同信息类型的全景式概况，
从而帮助用户定位、寻找和分
析信息数据中的不同模式和信
息之间的关联性。用户可根据
系统的信息类型建立刑事和人
权相关的案件，包括使用基于
《国际刑事法院规约》犯罪的
法律要素形成的案件分析矩阵。

<br/><br/>

该系统由伊利亚•乌特梅利兹
(Ilia Utmelidze)先生开发，吸收
了国际刑事法院案件矩阵的经
验。该系统也是与案件矩阵网
的专家合作的结果。乌特梅利
兹先生是国际法研究与政策中
心（CILRAP）的案件矩阵网
（Case Matrix Network）部门的
主任，他的邮箱是 <a href="mailto:utmelidze@cilrap.org">utmelidze@cilrap.org</a>.
    </p>

    <div class="grid">
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-1/" target="_blank">
            <img src="/images/i-doc-1.png" style="width: 100%;" alt="How to use the I-Doc system" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-2/" target="_blank">
            <img src="/images/i-doc-2.png" style="width: 100%;" alt="How to build international criminal law cases in I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-3/" target="_blank">
            <img src="/images/i-doc-3.png" style="width: 100%;" alt="How to customize I-DOC" />
        </a>
    </div>

    </div>`;

export default tutorialFilmEn;
