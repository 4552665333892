import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">I-DOC</h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    El Sistema de Investigación
Documental (I-DOC) es una
herramienta digital que busca
servir de ayuda para trabajar
hechos vinculados a graves
violaciones al derecho
internacional penal o de los
derechos humanos, lo que
incluye documentación,
investigación, análisis,
preparación de casos, de juicios y
procedimientos ulteriores.
Mientras que la Base de Datos de
Herramientas Jurídicas de la CPI
(LTD) permite al usuario acceder
a las fuentes jurídicas relevantes
e información esencial para
comprender los principales
crímenes internacionales, I-DOC
es una herramienta destinada a
profesionales y practicantes, a fin
de coadyuvar en la planificación y
ejecución de trabajos de
documentación e investigación
basados en el conocimiento
provisto por el LTD y Lexsitus.

<br/><br/>
I-DOC ofrece la posibilidad de
sistematizar grandes cantidades
de documentación y evidencia,
incidentes criminales y datos
contextuales, sospechosos e
instituciones involucradas, víctimas y testigos, bienes y
objetos protegidos, y otras
circunstancias de hecho
relevantes. I-DOC asegura la
precisión y calidad necesaria a lo
largo de todo el proceso de
trabajo.

<br/><br/>

La estructura de la base de datos
provee una visión general de
estas categorías de información,
así como también de la
posibilidad de mapear, buscar y
analizar diferentes patrones y
vínculos. Los usuarios pueden
armar casos penales y de
derechos humanos basados en
estas categorías de información,
incluyendo el uso de una función
matriz para análisis de casos
basada en los requisitos legales
exigidos para cada crimen por el
Estatuto de la CPI.

<br/><br/>
Esta herramienta ha sido
desarrollada por el Sr. Ilia
Utmelidze, basado, inter alia, en
la lluvia de ideas que se está
realizando sobre la
conceptualización de la Matriz de
Causas de la CPI, y con la
colaboración de un equipo de
expertos. El Sr. Utmelidze lidera
el departamento CMN de CILRAP
y puede ser contactado a través
de <a href="mailto:utmelidze@cilrap.org">utmelidze@cilrap.org</a>.
    </p>

    <div class="grid">
         <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-1/" target="_blank">
            <img src="/images/i-doc-1.png" style="width: 100%;" alt="How to use the I-Doc system" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-2/" target="_blank">
            <img src="/images/i-doc-2.png" style="width: 100%;" alt="How to build international criminal law cases in I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-3/" target="_blank">
            <img src="/images/i-doc-3.png" style="width: 100%;" alt="How to customize I-DOC" />
        </a>
    </div>

    </div>`;

export default tutorialFilmEn;
