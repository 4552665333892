import { ONewLegalFinding } from './redux-config';
import { Reducer } from 'redux';
import { TNewLegalFindingState } from './@types';

export const ERROR_TEXT = 'Cannot be empty';
const initalState: TNewLegalFindingState = {
    decisionSelectionDialog: false,
    open: false,
    section: 'home',
    isNewLegalFinding: false,
    loading: false,
    isACopy: false,
    isFormDirty: false,
    formError: {
        // progress: ERROR_TEXT,
        // Confidentiality: ERROR_TEXT,
        // 'Decision Type': ERROR_TEXT,
        // Title: ERROR_TEXT,
        // editorCopy: ERROR_TEXT
    },
    decision: undefined,
    legalFinding: undefined,
    errorMessage: undefined,
    editFields: {
        open: false,
    },
};

export const NewLegalFinding: Reducer<TNewLegalFindingState> = (state = initalState, action): TNewLegalFindingState => {
    const {
        CLOSE_NEW_LEGAL_FINDING_DIALOG,
        OPEN_NEW_LEGAL_FINDING_DIALOG,
        EDIT_LEGAL_FINDING_FIELDS,
        CLOSE_LEGAL_FINDING_EDIT_BAR,
        SET_FORM_FIELD,
        SAVE_LEGAL_FINDING_CHANGES,
        CLOSE_DECISION_SELECTION_DIALOG,
        OPEN_DECISION_SELECTION_DIALOG,
        NEW_LEGAL_FINDING_CREATED,
        SET_NEW_LEGAL_FINDING_LOADING,
        SET_ERROR_MESSAGE,
        CLEAR_ERROR_MESSAGE,
        SET_FORM_ERROR,
        TRANSLATE_KEYWORDS,
    } = ONewLegalFinding.actions;

    switch (action.type) {
        case TRANSLATE_KEYWORDS: {
            if (!state.legalFinding) return state;
            const prevKws = state.legalFinding.Keyword || [];
            const translatedKws = action.data.transalated || [];
            const kwsToRetain = prevKws.slice(translatedKws.length || 0);
            const Keyword = [...translatedKws, ...kwsToRetain];
            return {
                ...state,
                legalFinding: { ...state.legalFinding, Keyword },
                formData: { ...state.formData, Keyword },
            };
        }
        case SET_FORM_ERROR: {
            return { ...state, formError: { ...action.data } };
        }
        case CLEAR_ERROR_MESSAGE: {
            return { ...state, errorMessage: undefined };
        }
        case SET_ERROR_MESSAGE: {
            return { ...state, errorMessage: action.data };
        }
        case SET_NEW_LEGAL_FINDING_LOADING: {
            return { ...state, loading: action.data };
        }
        case NEW_LEGAL_FINDING_CREATED: {
            return { ...state, isNewLegalFinding: false, legalFinding: action.data, isFormDirty: false };
        }
        case CLOSE_DECISION_SELECTION_DIALOG: {
            return { ...state, decisionSelectionDialog: false };
        }
        case OPEN_DECISION_SELECTION_DIALOG: {
            return { ...state, decisionSelectionDialog: true, isNewLegalFinding: true };
        }
        case SAVE_LEGAL_FINDING_CHANGES: {
            return {
                ...state,
                legalFinding: { ...state.legalFinding, ...action.data.data },
                isFormDirty: false,
                editFields: { open: false },
            };
        }
        case SET_FORM_FIELD: {
            let value = action.data.value;
            if (action.data.key === 'Finding') value = Number(value);
            return {
                ...state,
                isFormDirty: true,
                legalFinding: { ...state.legalFinding!, [action.data.key]: value },
                formData: { ...state.formData, [action.data.key]: value },
            };
        }
        case EDIT_LEGAL_FINDING_FIELDS: {
            return { ...state, editFields: { ...action.data } };
        }
        case CLOSE_LEGAL_FINDING_EDIT_BAR: {
            return { ...state, editFields: { open: false } };
        }
        case OPEN_NEW_LEGAL_FINDING_DIALOG: {
            return {
                ...state,
                open: true,
                isACopy: action.data.isACopy || false,
                section: action.data.section,
                formData: action.data.formData || state.formData,
                isNewLegalFinding: action.data.isNewLegalFinding,
                decision: action.data.decision,
                legalFinding: action.data.legalFinding,
            };
        }
        case CLOSE_NEW_LEGAL_FINDING_DIALOG: {
            return {
                ...state,
                open: false,
                section: 'home',
                isNewLegalFinding: false,
                decision: undefined,
                legalFinding: undefined,
                isACopy: false,
                editFields: { open: false },
                formData: undefined,
            };
        }
        default:
            return state;
    }
};
