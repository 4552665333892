import React, { FC, useEffect, useState } from 'react';
import { AppBar, Button, CircularProgress, createStyles, Dialog, DialogContent, DialogTitle, IconButton, TextField, Theme, Toolbar, Typography, withStyles, WithStyles } from '@material-ui/core';
import SubmitButton from '../SubmitButton';
import { Close } from '@material-ui/icons';

export interface KeywordFormProps extends WithStyles<typeof STYLES> {
    onSubmit: (data: any) => Promise<any>;
    open: boolean;
    handleClose: () => void;
    title: string
    fields: KeywordFormField[]
    initialData: any
}

export interface KeywordFormField {
    label: string
    key: string
    rtl?: boolean
}

export interface KeywordFormData {

}

const KeywordForm: FC<KeywordFormProps> = (props) => {
    const { classes, onSubmit, open, handleClose, title, fields, initialData } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>({});


    useEffect(() => {
        setData(initialData);
    }, [initialData, open]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.persist();
        setLoading(true);
        await onSubmit(data);
        setLoading(false);
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    return (
        <Dialog open={open} fullScreen keepMounted={false}>
            <AppBar position='sticky'>
                <Toolbar>
                    <Typography className={classes.title}>{title}</Typography>
                    <div style={{ flex: 1 }} />
                    <IconButton className={classes.closeBtn} onClick={handleClose}><Close /></IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                <form onSubmit={handleSubmit} className={classes.form}>
                    {fields.map((field, index) => (
                        <TextField dir={field.rtl ? 'rtl' : 'ltr'} fullWidth className={classes.formItem} key={field.key} label={field.label} name={field.key} value={data[field.key] || ''} onChange={handleChange} />
                    ))}
                    <Button variant="contained" color="primary" type='submit'>
                        {loading ? <CircularProgress size={22} /> : 'Submit'}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    )
}

const STYLES = (theme: Theme) => createStyles({
    closeBtn: {
        // position: 'absolute',
        // right: theme.spacing.unit,
        // top: theme.spacing.unit,
        color: '#fff'
    },
    title: {
        color: '#fff'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        padding: '40px 0px',
        flexDirection: 'column',
        width: 500,
    },
    formItem: {
        marginBottom: 24
    }
});

export default withStyles(STYLES)(KeywordForm);