import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Fab, Checkbox, List, ListItem, ListItemText, FormControl, InputLabel, Input } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { TGroup } from '../../../Features/LtdDashboard/@types';
import _ from 'lodash';
import { OApp } from '../../../RootReducer/AppReducer';
import THEME from '../../../Resources/Theme';
import { TUser } from '../../../Features/Authentication/@types';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Axios, { CancelTokenSource } from 'axios';


interface IStateProps {
    selectedIds: string[]
    groupDetail: TGroup
    pageNumber: number
    users: TUser[]
}

interface IDispatchProps {
    closeSidebar?: () => void
    onCheck?: (id: string) => void
    fetchUsers?: (pageNumber: number, appendResult?: boolean) => void
    addUsersTogroup?: (userIds, groupId) => any
    clearSelection?: () => void
    fetchGroupDetail?: (groupId: string) => any
    searchUsers?: (formData: Record<string, any>, cancelToken) => any
}

interface IState {
    formData: Record<string, any>
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class UserListContainer extends Component<IProps, IState> {
    state = {
        formData: {
            term: ''
        }
    }

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    handleCheckChange = (user: TUser) => {
        if (!this.props.onCheck) return;
        this.props.onCheck(user.id);
    }

    handleSidebarClose = () => {
        if (!this.props.closeSidebar) return;
        this.props.closeSidebar();
    }

    loadMoreUsers = () => {
        if (!this.props.fetchUsers) return;

        this.props.fetchUsers(this.props.pageNumber + 1);
    }

    handleSubmit = async () => {
        if (!this.props.addUsersTogroup) return;
        const { groupDetail, selectedIds } = this.props;
        const userIds = _.union(groupDetail.userIds || [], selectedIds);
        await this.props.addUsersTogroup(userIds, groupDetail.id);
        if (this.props.fetchGroupDetail)
            this.props.fetchGroupDetail(groupDetail.id);
        if (this.props.clearSelection)
            this.props.clearSelection()
    }


    handleSearchTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!this.props.searchUsers) return;
        const newValue = e.target.value;
        const formData = { ...this.state.formData, term: newValue };

        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue || _.isEmpty(newValue)) {
            if (this.props.fetchUsers)
                this.props.fetchUsers(0, false);
            this.setState({ formData: {} });
            return;
        }
        this.setState({ formData });
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        console.log("searching")

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await this.props.searchUsers(formData, this.ajaxCallHandle.token)
        } catch (error) {
            console.log('AutoSuggest Error', error); throw error
        }
    }

    render() {
        const { classes, selectedIds = [], groupDetail } = this.props;
        let { users = [] } = this.props;
        users = users.filter(user => user);
        return (
            <div className={classes.root}>
                <div className={classNames(classes.header, "flex-row space-between align-start")}>
                    <Typography>
                        <b>
                            ASSIGN USER TO
                        <br />
                            {groupDetail.name}
                        </b>
                    </Typography>
                    <Fab className={classes.closeBtn} size="small" onClick={this.handleSidebarClose}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
                <List dense className={classes.container}>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel color="primary" htmlFor="user-search">Seach users</InputLabel>
                        <Input
                            autoComplete="off"
                            color="primary"
                            onChange={this.handleSearchTextChange}
                            value={_.get(this.state.formData, 'term') || ''}
                            inputProps={{
                                id: "user-search",
                                name: 'term'
                            }}
                        />
                    </FormControl>
                    {
                        users.map(user => (
                            <ListItem button onClick={e => this.handleCheckChange(user)} key={user.id}>
                                <Checkbox className={classes.checkbox} checked={_.indexOf(selectedIds, user.id) > -1} />
                                <ListItemText >{user.name || user.email}</ListItemText>
                            </ListItem>
                        ))
                    }
                    {
                        users.length > 19 ?
                            <Button fullWidth onClick={this.loadMoreUsers} >
                                Load more
                    </Button> : null
                    }

                </List>
                <Button onClick={this.handleSubmit} className={classes.submitBtn} fullWidth color="primary" variant="contained">submit</Button>
            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.LtdDashboard, ['selectedIds']),
    groupDetail: _.get(state.LtdDashboard, 'group.groupDetail'),
    pageNumber: _.get(state.LtdDashboard, 'users.pageNumber'),
    users: _.get(state.LtdDashboard, 'users.users'),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchUsers: (pageNumber, appendResult) => dispatch(OLtdDashboard.fetchUsers(pageNumber, appendResult)),
    addUsersTogroup: (userIds, groupId) => dispatch(OLtdDashboard.addUsersToGroup(userIds, groupId)),
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    onCheck: (id: string) => dispatch(OLtdDashboard.toggleCheck(id)),
    clearSelection: () => dispatch(OLtdDashboard.resetSelection()),
    fetchGroupDetail: (groupId: string) => dispatch(OLtdDashboard.fetchGroupDetail(groupId)),
    searchUsers: (formData: Record<string, any>, cancelToken) => dispatch(OLtdDashboard.searchUsers(formData, cancelToken)),
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        boxSizing: 'border-box',
        overflow: 'auto',
        background: THEME.Colors.Grayscale.White,
        width: 400,
    },
    header: {
        position: 'fixed',
        height: 50,
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '0px 10px 0px 20px',
        background: 'white',
        width: 400,
        right: 0,
        top: 0,
        zIndex: 1,
        boxShadow: `0px 1px 3px ${fade('#000', 0.2)}`
    },
    closeBtn: {
        background: 'white',
        boxShadow: 'none'
    },
    formControl: {
        margin: '8px 0px',
        padding: '0px 20px',
        boxSizing: 'border-box',
        '& label': {
            marginLeft: 20
        }
    },
    container: {
        boxSizing: 'border-box',
        padding: '50px 0px',
    },
    checkbox: {
        padding: 0
    },
    submitBtn: {
        position: 'fixed',
        width: 400,
        color: 'white',
        bottom: 0,
        right: 0,
        borderRadius: 0,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(UserListContainer))