import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    IconButton,
    Button,
    Tooltip,
    CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import Theme from '../../Resources/Theme';
import Text from '../../Components/Text';
// import EditIcon from '@material-ui/icons/Edit';
import { TUser, ICldPermissions } from '../../Features/Authentication/@types';
import MetadataForm from './MetadataForm';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import moment from 'moment';
import { TEditSection } from '../../Features/AddNewLegalFinding/@types';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { OApp } from '../../RootReducer/AppReducer';
import { OCLDDocuments, getSituationCase } from '../../Features/CLDDocuments/redux-config';
import utilities from '../../Resources/Utils';
import { getCLDUserRole } from '../../Features/Authentication/redux-config';
import { TOOLTIPS } from '../../Features/CLDDocuments/tooltips';
import { CldField } from '../../Services/CldFields.types';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    LEGAL_FINDING_DOCUMENT_DATE,
    LEGAL_FINDING_DOCUMENT_DOCUMENT_NUMBER,
    LEGAL_FINDING_DOCUMENT_ID,
    LEGAL_FINDING_DOCUMENT_JUDGES,
    LEGAL_FINDING_DOCUMENT_LANGUAGE,
    LEGAL_FINDING_DOCUMENT_LEGAL_FINDING_NUMBER,
    LEGAL_FINDING_DOCUMENT_LEVEL_OF_IMPORTANCE,
    LEGAL_FINDING_DOCUMENT_MAJORITY_DISSENTING,
    LEGAL_FINDING_DOCUMENT_METADATA_OF_LF,
    LEGAL_FINDING_DOCUMENT_NUMBER_OF_PAGES,
    LEGAL_FINDING_DOCUMENT_PAGES,
    LEGAL_FINDING_DOCUMENT_PARA_LINES,
    LEGAL_FINDING_DOCUMENT_PERMANENT_URL,
    LEGAL_FINDING_DOCUMENT_PHASE_OF_CASE,
    LEGAL_FINDING_DOCUMENT_SITUATION_CASE,
    LEGAL_FINDING_DOCUMENT_SOURCE,
    LEGAL_FINDING_DOCUMENT_TITLE_OF_DECISION,
    LEGAL_FINDING_DOCUMENT_TYPE_OF_DECISION,
} from '../../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, WithTranslationProps {
    data: TCLDDocument | undefined;
}
interface IStateProps {
    user: TUser;
    currentPage: number;
    appConstants: any;
    cldPermissions: ICldPermissions;
    cldFields: CldField[];
}

interface IDispatchProps {
    editLegalFinding: (section: TEditSection) => void;
    searchDocuments: (pageNumber: number) => void;
    deleteLegalFinding: (id) => void;
    confirmationDialog: (message: string, onConfirm: () => void, onDelete: () => void) => void;
}

export const PERMANENT_URL = window.location.protocol + '//' + window.location.host + '/clddoc/';

class CLDMetadata extends Component<Props & IStateProps & IDispatchProps & WithTranslationProps> {
    state = {
        editing: false,
    };

    handleEdit = event => {
        this.props.editLegalFinding('metadata');
    };

    handleDelete = () => {
        const { data, currentPage = 0 } = this.props;
        if (_.isEmpty(data)) return;
        this.props.confirmationDialog(
            'Delete legal finding?',
            () => {
                this.props.deleteLegalFinding(data!.id);
                this.props.searchDocuments(currentPage);
            },
            () => null
        );
    };

    render() {
        const { classes, cldPermissions, data, getTranslation }: IStateProps & Props = this.props;

        return (
            <div className={classes.root}>
                {cldPermissions.docEditFn(data) ? (
                    <div className={classes.editBtnContainer}>
                        <Button className={classes.editButton} onClick={this.handleEdit}>
                            <i className='material-icons'>edit</i>
                        </Button>
                        {cldPermissions.docDeleteFn(data) ? (
                            <Button className={classes.editButton} onClick={this.handleDelete}>
                                <i className='material-icons'>delete_outline</i>
                            </Button>
                        ) : null}
                    </div>
                ) : null}
                <Typography variant='caption' className={classes.title}>
                    {getTranslation(LEGAL_FINDING_DOCUMENT_METADATA_OF_LF)}
                </Typography>
                <div>
                    <Metadata {...this.props} />
                </div>
            </div>
        );
    }
}

// Title of decision: Source:        Language:

const Metadata = props => {
    const { classes, data, appConstants, cldFields, getTranslation }: IStateProps & Props = props;

    if (data === undefined)
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        );

    const getJudges = () => {
        return (_.get(data, 'ltdDoc.cldJudges') || []).join(', ');
    };

    const DATA = [
        {
            className: '',
            label: getTranslation(LEGAL_FINDING_DOCUMENT_ID),
            value: data.ID,
            tooltip: TOOLTIPS['ID'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_PARA_LINES),
            value: data['Para(s)_'],
            tooltip: '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_PAGES),
            value: data['Page(s)_'],
            tooltip: '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_LEGAL_FINDING_NUMBER),
            value: data.Finding,
            tooltip: TOOLTIPS['Finding'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_LEVEL_OF_IMPORTANCE),
            value: data.Importance01 ? data.Importance01 + ' importance' : '',
            tooltip: TOOLTIPS['Level of importance'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_MAJORITY_DISSENTING),
            value: data.majorityDissentingRelationship || '',
        },
        ..._.map(_.take(data.Keyword, 5), (keyword, i) => ({
            className: classes.keywordContainer,
            label: `Keyword${i + 1}`,
            value:
                `<span>${keyword}</span>` +
                (i === 4 && data.Keyword && data.Keyword.length > 5
                    ? `<span class="extra"/>+${data.Keyword.length - 5} keywords</span>`
                    : ''),
            tooltip: TOOLTIPS['Keyword'],
        })),
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_PERMANENT_URL),
            value: `${PERMANENT_URL}${data.id}`,
        },
        {
            label: 'DIVIDER',
            value: '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_TITLE_OF_DECISION),
            value: data.ltdDoc ? data.ltdDoc.title : '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_SOURCE),
            value: _.get(data, 'ltdDoc.cld.documentSourceEn') || _.get(data, 'ltdDoc.source') || '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_SITUATION_CASE),
            value: getSituationCase(data.ltdDoc, cldFields),
            tooltip: TOOLTIPS['Case'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_DATE),
            value: _.get(data, 'ltdDoc.dateCreated')
                ? moment(_.get(data, 'ltdDoc.dateCreated')).format('DD MMMM YYYY')
                : '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_DOCUMENT_NUMBER),
            value: _.get(data, 'ltdDoc.cld.fullDocumentNumber') || _.get(data, 'Document Number') || '',
            tooltip: TOOLTIPS['Document number'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_PHASE_OF_CASE),
            value: _.get(data, 'ltdDoc.cld.phaseOfCaseEn') || _.get(data, 'ltdDoc.phaseOfCases[0].title') || '',
            tooltip: TOOLTIPS['Phase of case'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_TYPE_OF_DECISION),
            value: _.get(data, 'ltdDoc.cld.typeOfDecisionEn') || _.get(data, 'ltdDoc.typeOfDecisionEn') || '',
            tooltip: TOOLTIPS['Document type'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_JUDGES),
            value: getJudges(),
            tooltip: TOOLTIPS['Judges'],
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_NUMBER_OF_PAGES),
            value: data.ltdDoc ? data.ltdDoc.numberOfPages : '',
        },
        {
            label: getTranslation(LEGAL_FINDING_DOCUMENT_LANGUAGE),
            value: _.get(data, 'ltdDoc.cld.languageEn') || _.get(data, 'ltdDoc.languages[0].name') || '',
        },
    ];

    return (
        <Fragment>
            {_.map(DATA, (meta, i) => (
                <Tooltip key={i} title={meta.tooltip ? meta.tooltip : ''}>
                    <div className={classes.metaItem} key={i}>
                        {meta.label === 'DIVIDER' ? (
                            <div className={classes.divider} />
                        ) : (
                            <>
                                <Typography
                                    color='primary'
                                    className={classes.metaLabel}
                                    style={{ textAlign: 'right' }}
                                >
                                    {meta.label}:
                                </Typography>
                                {meta.label === getTranslation(LEGAL_FINDING_DOCUMENT_PERMANENT_URL) ? (
                                    <a
                                        style={{ color: Theme.Colors.Primary.light, flex: 3, marginLeft: 20 }}
                                        href={String(meta.value)}
                                        target='_blank'
                                    >
                                        <Typography color='inherit'>{meta.value}</Typography>
                                    </a>
                                ) : (
                                    <Typography
                                        className={meta.className}
                                        style={{ flex: 3, marginLeft: 20 }}
                                        dangerouslySetInnerHTML={{ __html: meta.value }}
                                    ></Typography>
                                )}
                            </>
                        )}
                    </div>
                </Tooltip>
            ))}
        </Fragment>
    );
};

const mapStateToProps = state => ({
    ..._.pick(state.Auth, ['user']),
    ..._.pick(state.App, 'appConstants'),
    ..._.pick(state.CLDDocuments, ['currentPage']),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
    cldFields: state.CldFields.cldFields,
});

const mapDispatchToProps = dispatch => ({
    searchDocuments: pageNumber => dispatch(OCLDDocuments.searchDocuments(pageNumber, false, true)),
    editLegalFinding: (section: TEditSection) => dispatch(ONewLegalFinding.openNewLegalFindingDialog(false, section)),
    deleteLegalFinding: (id: string) => dispatch(OCLDDocuments.deleteLegalFinding(id)),
    confirmationDialog: (message: string, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
});

const STYLES = theme =>
    createStyles({
        root: {
            position: 'relative',
            width: 680,
            borderRadius: 4,
            margin: '50px auto',
            background: 'white',
            boxSizing: 'border-box',
            padding: '20px 40px',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
                padding: '20px 30px',
            },
        },
        divider: {
            width: '100%',
            height: 10,
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 5,
                left: 26,
                right: 0,
                background: 'rgba(0,0,0,0.2)',
                height: 2,
            },
        },
        keywordContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        listItemContainer: {
            display: 'flex',
            marginBottom: 8,
            position: 'relative',
        },
        metaLabel: {
            flex: 1,
            [theme.breakpoints.down('sm')]: {
                flex: 2,
            },
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            color: 'white',
            background: Theme.Colors.Fourth,
            position: 'relative',
            display: 'inline-block',
            top: -30,
            padding: 1,
            lineHeight: 1,
        },
        editBtnContainer: {
            position: 'absolute',
            right: 18,
            top: -14,
        },
        editButton: {
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            border: `1px solid ${Theme.Colors.Third}`,
            '& i': {
                // fontSize: 14,
                color: Theme.Colors.Third,
            },
        },
        label: {
            flex: 2,
            marginRight: 20,
            color: Theme.Colors.Primary.main,
        },
        detail: {
            flex: 4,
        },
        metaItem: {
            position: 'relative',
            left: -22,
            width: '100%',
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '5px 0px',
            '& p': {
                fontSize: 12,
                fontWeight: 500,
            },
            '& a': {
                textDecoration: 'none',
                [theme.breakpoints.down('sm')]: {
                    flex: 3,
                    overflow: 'hidden',
                },
            },
            '& .extra': {
                textAlign: 'end',
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(CLDMetadata)));
