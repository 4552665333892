const purlEn = `<div id="content" class="content">
    <div id="breadcrumbs">
        <p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="purl/">PURL</a></p>
    </div>
    <div id="getUrlText">
        <div
            style="cursor:pointer;float:right;"
            title="Collapse tree"
            id="collapseTree"
        >
            <i class="glyphicon glyphicon-collapse-down"></i>
        </div>
        <a href="javascript:window.print();" style="float:right;"
            ><i class="glyphicon glyphicon-print"></i
        ></a>
    </div>
    <br style="clear:both;" />
    <!--  CONTENT ELEMENT, uid:147/text [begin] -->
    <div id="c147" class="csc-default">
        <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
            <h1 class="csc-firstHeader">Persistent URLs (PURLs)</h1>
        </div>
        <!--  Header: [end] -->

        <!--  Text: [begin] -->
        <p class="bodytext">
        The ICC Legal Tools Database (LTD) uses Persistent URLs (PURLs). This means that the URL or Internet address of each document contained in the LTD is permanent and will not be changed. As a user you can, in other words, refer to documents in the LTD by including their PURL in your text (or its notes). If you make the PURL a hyperlink to the document in question, the link will never be broken by the Legal Tools Project.
        </p>
        <p class="bodytext">
            By using such hyperlinked PURLs to legal documents in the free, public LTD, you help construct an ever-increasing common space of legal references and sources. Serving as the emerging public commons in international criminal law, the LTD equalizes access to legal information and levels the playing field among practitioners in this area of law. As such, the Legal Tools Project contributes to capacity development especially at the national level.
        </p>
        <p class="bodytext">
            On 30 May 2012, the ICC Appeals Chamber started using LTD PURLs in
            its decisions.
            <a href="https://www.legal-tools.org/en/doc/9813bb/" target="_blank"
                >The Katanga Trial Judgment of 7 March 2014</a
            >
            contains hyperlinks to 538 legal sources in the LTD. Since then
            numerous of ICC decisions use such hyperlinks, and in a March 2021
            10-year Agreement between the ICC and the Centre for International
            Law Research and Policy (CILRAP, which administers the LTD for the
            ICC), the Court commits itself to “hyperlink its judgments and other
            relevant public decisions and documents with persistent URLs of the
            LTD” to the extent possible. The Legal Tools Project encourages
            courts, governments, non-governmental organizations, academics,
            publishers, and others to include references to the hyperlinked PURL
            of documents in the LTD &nbsp; whenever feasible.
        </p>
        <ul>
            <li>
                <a
                    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/161111_LTD_leaflet__Fourth_Edition.pdf"
                    target="_blank"
                    >161116 Building a public commons of legal sources in
                    international criminal law, Fourth Edition</a
                >
            </li>
        </ul>
        <!--  Text: [end] -->
    </div>
    <!--  CONTENT ELEMENT, uid:147/text [end] -->
</div>`;

export default purlEn;
