import React, { Component, Fragment, useState } from 'react';
import {
    withStyles,
    createStyles,
    WithStyles,
    Dialog,
    Theme,
    Button,
    Paper,
    Typography,
    Slide,
    Tabs,
    Tab,
    Modal,
} from '@material-ui/core';
import { connect, DispatchProp } from 'react-redux';
import { TState } from '../../RootReducer/index';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import Metadata from './Metadata';
import Finding from './Finding';
import classNames from 'classnames';
import Keywords from './Keywords';
import OtherMeta from './OtherMeta';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TEditSection, TEditFields } from '../../Features/AddNewLegalFinding/@types';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { TDocument } from '../../Features/LTDDocuments/@types';
import PdfContent from '../PdfContent';
import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import EditFields from './EditFields';
import { OApp } from '../../RootReducer/AppReducer';
import { withRouter, RouteComponentProps, Prompt } from 'react-router';
import { TUser } from '../../Features/Authentication/@types';
import { DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import { ScrollToError, validateProgress, ProgressValidation } from './utils';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_SAVE } from '../../Features/Translations/translationKeys.cld';
import { CldField } from '../../Services/CldFields.types';

interface IProps
    extends WithStyles<typeof STYLES>,
        IStateProps,
        IDispatchProps,
        RouteComponentProps,
        WithTranslationProps {}

interface IDispatchProps {
    closeDialog: () => void;
    editSection: (section: TEditSection, fieldIndex?: number) => void;
    editLegalFinding: (key: string, value: any) => void;
    confirmationDialog: (msg: string, onConfirm: Function, onCancel: Function) => void;
    saveChanges: (legalFindingId: string) => void;
    createNewLegalFinding: () => void;
    validateForm: () => boolean;
    translateKeywords: (keywordIds: string[], lang: string) => Promise<void>;
    getNewFindingNumber: (documentNumber, languageId) => Promise<number>;
}

interface IStateProps {
    open: boolean;
    isACopy: boolean;
    isNewLegalFinding: boolean;
    section: TEditSection;
    legalFinding: TCLDDocument | undefined;
    isFormDirty: boolean;
    decision: TDocument | undefined;
    editFields: TEditFields;
    formData?: any;
    user: TUser;
    cldFields: CldField[];
    formError: TState['NewLegalFinding']['formError'];
}

interface IState {
    editSectionOpen: boolean;
    tabValue: number;
    activeNav: number;
    isBlocking: boolean;
}

//
// CONFIG for sticky navbar on the left of the screen
//
const TABS = [
    { label: 'Metadata', href: 'metadata' },
    { label: 'Finding', href: 'finding' },
    { label: 'Keywords', href: 'keywords' },
    { label: 'Appeal', href: 'appeal' },
    { label: 'Decisions', href: 'decisions' },
    { label: 'Opinions', href: 'opinions' },
];

//
// COMPONENT for Section Label
//
export const SectionLabel: React.FC<{ title: string }> = props => {
    return (
        <Typography
            variant='caption'
            style={{ lineHeight: 1, background: THEME.Colors.Fourth, padding: 2, fontWeight: 400, color: 'white' }}
        >
            {props.title}
        </Typography>
    );
};

export const WIDE_VARIANT = window.outerWidth > 1360;

class NewLegalFinding extends Component<IProps, IState> {
    state: IState = {
        editSectionOpen: false,
        tabValue: 0,
        activeNav: 0,
        isBlocking: false,
    };

    scrollElements: Array<number | undefined> = [];

    componentDidMount() {
        const { history } = this.props;
        history.listen(locationListner => {
            this.setState({ isBlocking: true });
            // this.props.confirmationDialog("Exit without saving changes?", () => {
            //     this.props.closeDialog;
            // }, () => null);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.open) {
            let sum = 90;
            this.scrollElements = TABS.map(tab => {
                const el = document.getElementById(tab.href);
                if (el) {
                    const val = sum - el.getBoundingClientRect().height;
                    sum = sum - el.getBoundingClientRect().height;
                    return val;
                }
            });

            if (prevProps.section !== this.props.section) {
                setTimeout(() => {
                    navigateTo(this.props.section);
                }, 500);
                const activeNavIndex = _.findIndex(TABS, { href: this.props.section });
                if (activeNavIndex > -1) {
                    this.onNavigate(activeNavIndex);
                }
            }
        }

        // Handle scroll to error
        if (this.props.formError !== prevProps.formError) ScrollToError(this.props.formError || {});
    }

    // Tab Change handler for Appbar Tabs
    onTabChange = (e: React.ChangeEvent<{}>, tabValue: number) => {
        this.setState({ tabValue });
    };

    // Change the index for Sticky nav
    onNavigate = (index: number) => {
        this.setState({ activeNav: index });
    };

    onWheel = (e: React.WheelEvent<HTMLElement>) => {
        const { activeNav } = this.state;

        TABS.forEach((tab, i) => {
            const el = document.getElementById(tab.href);
            if (el) {
                const pos = el.getBoundingClientRect().top;
                if (pos >= 70 && pos <= 110 && activeNav !== i) this.setState({ activeNav: i });
            }
        });
    };

    // Toggle Edit Sidebar
    toggleEdit = () => this.setState({ editSectionOpen: !this.state.editSectionOpen });

    render() {
        const { open, classes, editFields, getTranslation } = this.props;
        const { editSectionOpen, tabValue, activeNav, isBlocking = false } = this.state;

        const openEditBar = editFields.open;

        return (
            <Dialog
                open={open || false}
                PaperProps={{ className: classes.root }}
                classes={{ container: classes.dialogContainer }}
            >
                {/* <Prompt

                    when={isBlocking}
                    message={location =>
                        `Are you sure you want to go to ${location.pathname}`
                    }
                    
                /> */}
                <section id='new-legal-finding' className={classNames(classes.contentContainer)}>
                    <div
                        className={classes.fixedHeader}
                        style={{ width: openEditBar && false ? 'calc(100% - 400px)' : '100%' }}
                    >
                        <DialogTitle {...this.props} />
                        {WIDE_VARIANT ? null : (
                            <TabBar value={tabValue} onTabChange={this.onTabChange} {...this.props} />
                        )}
                    </div>
                    <div style={{ height: 90, width: '100%' }} />
                    <div className={classes.tabContentContainer} onWheel={this.onWheel}>
                        {tabValue === 0 && ( // STICKY NAV BAR
                            <Paper className={classes.stickyContainer}>
                                <StickyNav activeNav={activeNav} onNavigate={this.onNavigate} />
                            </Paper>
                        )}
                        {WIDE_VARIANT ? (
                            <div className={classes.wideDeviceContainer}>
                                <div className={classes.findingContainer}>
                                    <LegalFindingTab {...this.props} openEditBar={openEditBar && false} />
                                </div>
                                <div className={classes.pdf}>
                                    <DecisionTab {...this.props} />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={tabValue == 0 ? classes.findingContainer : classes.emptyContainer}>
                                    <LegalFindingTab {...this.props} openEditBar={openEditBar} />
                                </div>
                                <div
                                    className={classes.pdfContainer}
                                    style={{ zIndex: tabValue === 1 ? 1 : -1, opacity: tabValue === 1 ? 1 : 0 }}
                                >
                                    <DecisionTab {...this.props} />
                                </div>
                            </>
                        )}
                    </div>
                </section>
                <section className={classes.editContainer} style={{ display: openEditBar ? 'flex' : 'none' }}>
                    <EditFields />
                </section>
            </Dialog>
        );
    }
}

//
// Utility function to navigate to scroll to a particular section
//
const navigateTo = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

//
// STICKY NAVBAR
//
interface StickyNavProps {
    activeNav: number;
    onNavigate: (index: number) => void;
}
const StickyNav: React.FC<StickyNavProps> = props => {
    const navigate = (section: string, index: number) => {
        navigateTo(section);
        props.onNavigate(index);
    };

    return (
        <Fragment>
            {TABS.map((tab, i) => (
                <Typography
                    align='left'
                    gutterBottom
                    style={{
                        cursor: 'pointer',
                        fontWeight: 500,
                        borderRight: i === props.activeNav ? `2px solid ${THEME.Colors.Third}` : 'none',
                        color: i === props.activeNav ? THEME.Colors.Third : THEME.Colors.Fourth,
                    }}
                    key={tab.href}
                    onClick={e => navigate(tab.href, i)}
                >
                    {tab.label}
                </Typography>
            ))}
        </Fragment>
    );
};

const DecisionTab: React.FC<IProps> = props => {
    const { classes, legalFinding, decision } = props;

    const url = decision ? `${DOCUMENT_BASE_URL}${decision.slug}/pdf` : '';
    const height = window.innerHeight - (WIDE_VARIANT ? 65 : 124);

    if (decision && decision.orignalPdfURL)
        return (
            <div>
                <PdfContent documentUrl={decision.orignalPdfURL || url} />
                {/* <iframe frameBorder="0" src={decision.orignalPdfURL || url} width="100%" height={height} >
                    This browser does not support PDFs. Please download the PDF to view it:
                <a href={url}>Download PDF</a></iframe> */}
            </div>
        );

    return <div />;
};

export interface TOtherMeta {
    title: string;
    id: TEditSection;
    fields: Array<{
        label: string;
        key: string;
        flex: number;
    }>;
}
interface LegalFindingTabProps {
    openEditBar: boolean;
}
const LegalFindingTab: React.FC<IProps & LegalFindingTabProps> = props => {
    const OTHER_META: Array<TOtherMeta> = [
        {
            title: 'RESULTS OF APPEALS',
            id: 'appeal',
            fields: [
                { label: 'APPEAL TYPE', key: 'AppealType', flex: 2 },
                { label: 'DECISION', key: 'Appeal', flex: 3 },
                { label: 'PARAGRAPH(S)', key: 'AppealPara', flex: 2 },
                { label: 'PAGE(S)', key: 'AppealPage', flex: 1 },
                { label: '', key: 'actions', flex: 2 },
            ],
        },
        {
            title: 'RELATED DECISIONS',
            id: 'decisions',
            fields: [
                { label: 'RELATION TYPE', key: 'Relation Type', flex: 2 },
                { label: 'DECISION', key: 'Related Decision', flex: 3 },
                { label: 'PARAGRAPH(S)', key: 'Related Para', flex: 2 },
                { label: 'PAGE(S)', key: 'Related Pages', flex: 1 },
                { label: '', key: 'actions', flex: 2 },
            ],
        },
        {
            title: 'SEPARATE OR DISSENTING OPINIONS',
            id: 'opinions',
            fields: [
                { label: 'OPINION TYPE', key: 'OpinionType', flex: 2 },
                { label: 'DECISION', key: 'Opinion', flex: 3 },
                { label: 'PARAGRAPH(S)', key: 'OpinionPara', flex: 2 },
                { label: 'PAGE(S)', key: 'OpinionPage', flex: 1 },
                { label: '', key: 'actions', flex: 2 },
            ],
        },
    ];

    const editLegalFinding = (key: string, value: any) => {
        props.editLegalFinding(key, value);
    };

    const handleDelete = async (
        index: number | number[],
        key: 'Keyword' | 'Appeal' | 'Opinions' | 'Related Decision'
    ) => {
        return new Promise<boolean>((resolve, reject) => {
            let newValue = props.legalFinding ? (props.legalFinding[key] as Array<any>) : [];
            if (!_.isArray(index)) newValue.splice(index, 1);
            else newValue = _.filter(newValue, (_, i) => !index.includes(i));

            let confirmationMessage = `Are you sure you want to delete the ${key}`;
            if (key === 'Keyword') confirmationMessage = 'Are you sure you want to delete the keyword(s)?';
            props.confirmationDialog(
                confirmationMessage,
                () => {
                    props.editLegalFinding(key, newValue);
                    resolve(true);
                },
                () => reject(false)
            );
        });
        // props.editLegalFinding(key, newValue)
    };

    return (
        <div id='lf-container' className={props.openEditBar ? props.classes.lfContainer : props.classes.lfContainerAlt}>
            <Metadata
                cldFields={props.cldFields}
                getNewFindingNumber={props.getNewFindingNumber}
                isNewLegalFinding={props.isNewLegalFinding}
                formErrors={props.formError}
                user={props.user}
                legalFinding={props.legalFinding}
                formData={props.formData}
                onEdit={editLegalFinding}
            />
            {!_.get(props.formData, 'Legal Findings?') ? null : (
                <Finding
                    isNewLegalFinding={props.isNewLegalFinding}
                    formErrors={props.formError}
                    legalFinding={props.legalFinding}
                    formData={props.formData}
                    onEdit={editLegalFinding}
                />
            )}
            <Keywords
                translateKeywords={props.isACopy ? props.translateKeywords : undefined}
                legalFinding={props.legalFinding}
                onEdit={() => props.editSection('keywords')}
                formData={props.formData}
                onDelete={handleDelete}
            />
            {OTHER_META.map((meta, i) => (
                <OtherMeta
                    meta={meta}
                    key={i}
                    legalFinding={props.legalFinding}
                    onEdit={index => props.editSection(meta.id, index)}
                    onDelete={handleDelete}
                />
            ))}
        </div>
    );
};

interface TabBarProps {
    onTabChange: (e: React.ChangeEvent<{}>, newTabVal: number) => void;
    value: number;
}
const TabBar: React.FC<IProps & TabBarProps> = props => {
    const { classes, value } = props;

    const TABS = ['Legal Finding', 'Decision'];
    return (
        <div className={classes.tabBar}>
            <Tabs value={value} onChange={props.onTabChange} TabIndicatorProps={{ className: classes.tabIndicator }}>
                {_.map(TABS, (tab, i) => (
                    <Tab key={i} classes={{ labelContainer: classes.labelContainer, root: classes.tab }} label={tab} />
                ))}
            </Tabs>
        </div>
    );
};

const DialogTitle: React.FC<IProps> = props => {
    const { classes, legalFinding, isNewLegalFinding, formData, getTranslation } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [progressValidation, setProgressValidation] = useState<ProgressValidation>({ isValid: false, missing: {} });
    const title_edit = legalFinding
        ? `Edit legal finding ${legalFinding.Finding} of "${legalFinding.Title || _.get(legalFinding, 'ltdDoc.title')}"`
        : '';
    const title_new = legalFinding
        ? `New legal finding ${legalFinding.Finding} of "${legalFinding.Title || _.get(legalFinding, 'ltdDoc.title')}"`
        : '';

    const closeDialog = () => {
        if (props.isFormDirty) {
            props.confirmationDialog('Exit without saving changes?', props.closeDialog, () => null);
        } else props.closeDialog();
    };

    const saveChanges = () => {
        if (!props.validateForm()) {
            return;
        }

        const progressValidation = validateProgress(formData);
        setProgressValidation(progressValidation);
        if (Object.keys(progressValidation.missing).length > 0) setModalOpen(true);
        if (!progressValidation.isValid) return;

        if (props.isNewLegalFinding) {
            props.createNewLegalFinding();
            return;
        }

        const id = props.legalFinding ? props.legalFinding.id : '';
        if (_.isEmpty(id) && !props.isNewLegalFinding) return;
        props.saveChanges(id);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const missingFields = Object.keys(progressValidation.missing).map(k => progressValidation.missing[k]);
    const saveStatus = progressValidation.isValid ? 'Changes saved successfully' : 'The changes are not saved';

    return (
        <div id='home' className={classes.dialogTitle}>
            <Button onClick={closeDialog}>
                <i className='material-icons'>close</i>
            </Button>
            <Typography style={{ flex: '1 1 0' }}>{isNewLegalFinding ? title_new : title_edit}</Typography>
            {props.isFormDirty && (
                <Button onClick={saveChanges} className={classes.saveBtn}>
                    {getTranslation(GENERAL_SAVE).toUpperCase()}
                </Button>
            )}
            <Modal open={modalOpen} onClose={handleModalClose}>
                <div className={classes.modalPaper}>
                    <Typography variant='h6'>Note: The following fields are missing</Typography>
                    {missingFields.map((f, i) => (
                        <Typography variant='subtitle1' key={i}>
                            {f}
                        </Typography>
                    ))}
                    <hr />
                    <div className='flex-row justify-between my-1'>
                        <Typography color='error' variant='body1'>
                            {saveStatus}
                        </Typography>
                        <Button color='primary' variant='contained' onClick={handleModalClose}>
                            Ok
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.NewLegalFinding, [
        'open',
        'isNewLegalFinding',
        'section',
        'legalFinding',
        'decision',
        'editFields',
        'formData',
        'isFormDirty',
        'isACopy',
    ]),
    formError: _.get(state.NewLegalFinding, 'formError'),
    user: _.get(state.Auth, 'user'),
    cldFields: _.get(state, 'CldFields.groupedCldFields.language'),
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(ONewLegalFinding.closeNewLegalFindingDialog()),
    confirmationDialog: (msg: string, onConfirm: Function, onCancel: Function) =>
        dispatch(OApp.showConfirmationDialog(msg, onConfirm, onCancel)),
    editSection: (section: TEditSection, fieldIndex?: number) =>
        dispatch(ONewLegalFinding.editSectionFields(section, fieldIndex)),
    editLegalFinding: (key: string, value: any) => dispatch(ONewLegalFinding.editLegalFinding(key, value)),
    saveChanges: (legalFindingId: string) => dispatch(ONewLegalFinding.saveEdit(legalFindingId)),
    createNewLegalFinding: () => dispatch(ONewLegalFinding.createNewLegalFinding()),
    validateForm: () => dispatch(ONewLegalFinding.validateForm()),
    getNewFindingNumber: async (documentNumber, languageId) =>
        ONewLegalFinding.getNewLegalFindingNumber(documentNumber, languageId),
    translateKeywords: (keywordIds: string[], lang: string) =>
        dispatch(ONewLegalFinding.translateKeywords(keywordIds, lang)),
});

const HEIGHT = WIDE_VARIANT ? 'calc(100vh - 45px)' : 'calc(100vh - 90px)';
const STYLES = (theme: Theme) =>
    createStyles({
        dialogContainer: {
            // zIndex: 1600
        },
        root: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            background: THEME.Colors.Grayscale.Grey5,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            maxHeight: '100vh',
            overflow: 'hidden',
            borderRadius: 0,
            maxWidth: 'inherit',
        },
        wideDeviceContainer: {
            display: 'flex',
            paddingLeft: '100px',
        },
        pdf: {
            width: WIDE_VARIANT ? undefined : 720,
            margin: '0 auto',
            flex: WIDE_VARIANT ? 1 : undefined,
        },
        fixedHeader: {
            position: 'fixed',
            zIndex: 1,
            boxShadow: `0px 2px 4px ${fade('#000', 0.2)}`,
        },
        dialogTitle: {
            background: THEME.Colors.Primary.main,
            height: 45,
            display: 'flex',
            alignItems: 'center',
            '& i,  & p': {
                color: 'white',
            },
        },
        tab: {
            minWidth: 0,
            margin: '0px 24px',
        },
        tabBar: {
            height: 45,
            display: 'flex',
            alignItems: 'center',
            background: THEME.Colors.Third,
            justifyContent: 'center',
        },
        labelContainer: {
            color: 'white',
            padding: 0,
        },
        tabIndicator: {
            bottom: 2,
            height: 4,
            background: 'white',
        },
        emptyContainer: {
            // height: 'calc(100vh - 90px)',
            height: HEIGHT,
            overflow: 'hidden',
            zIndex: 0,
            opacity: 0,
            // top: 0, left: 0, right: 0,
            // bottom: 0,
        },
        lfContainer: {
            height: '100%',
            overflow: 'auto',
            '& > div': {
                paddingLeft: 75,
                transition: '200ms ease-out',
            },
        },
        lfContainerAlt: {
            height: '100%',
            overflow: 'auto',
            padding: '0px 16px',
        },
        findingContainer: {
            zIndex: 2,
            height: HEIGHT,
            // height: 'calc(100vh - 90px)',
            overflow: 'auto',
        },
        pdfContainer: {
            // height: 'calc(100vh - 90px)',
            height: HEIGHT,
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        tabContentContainer: {
            position: 'relative',
        },
        stickyContainer: {
            display: 'flex',
            zIndex: 2,
            flexDirection: 'column',
            position: 'fixed',
            // height: 200,
            top: 130,
            padding: '20px 0px 20px 8px',
            borderRadius: '0px 4px 4px 0px',
            width: 75,
        },
        contentContainer: {
            flex: 1,
            position: 'relative',
            height: '100vh',
            overflow: 'hidden',
            // background: 'grey'
        },
        scrollDisabled: {
            overflow: 'hidden',
        },
        saveBtn: {
            background: THEME.Colors.Green,
            color: 'white',
            borderRadius: 0,
            width: 120,
            height: 45,
            '&:hover': {
                background: lighten(THEME.Colors.Green, 0.2),
            },
        },
        editContainer: {
            width: 400,
            overflow: 'auto',
            background: 'white',
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: 2,
            boxShadow: `-2px 0px 4px ${fade('#000', 0.23)}`,
        },
        modalPaper: {
            position: 'absolute',
            width: theme.spacing.unit * 50,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing.unit * 4,
            outline: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    });

export default withTranslations(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(NewLegalFinding)))
);
