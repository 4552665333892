const contactUsFr = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;
</a><a href="status-of-work-on-the-tools/" >
Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
	<div id="c258" class="csc-default">
	<!--  Header: [begin] -->
		<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Nous contacter</h1></div>
	<!--  Header: [end] -->
		
	<!--  Text: [begin] -->
		<p  class="bodytext">
        Vous pouvez contacter la base de données des Outils juridiques de la CPI à l’adresse 
         <a href="mailto:info@cilrap.org">info@cilrap.org</a>.
          </p>
		<p  class="bodytext">
        Servez-vous du bouton « Signaler un problème » en haut à droite du menu de la base de données des Outils juridiques pour :          </p>
		<ul>
			<li>signaler une erreur ou suggérer une amélioration ;
            </li>
			<li>suggérer l’ajout d’un document à la base de données des Outils juridiques ; ou
            </li>
			<li>suggérer la correction de métadonnées d’un document déjà enregistré dans la base de données.
            </li>
		</ul>
	<!--  Text: [end] -->
		</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
	</div>`;
export default contactUsFr;
