const projectBackgroundEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
    <div id="c258" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Project background</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p  class="bodytext">
        The ICC Legal Tools were first developed by the Legal Advisory Section (LAS)
         of the Office of the Prosecutor of the ICC between 2003 and 2005, and since 2006
          under the leadership of the Director of the Centre for International Law Research 
          and Policy 
          (<a href="https://www.cilrap.org/purpose/" target="_blank">CILRAP</a>),&nbsp;
          <a href="https://www.cilrap.org/bergsmo/">Morten Bergsmo</a> 
          (who conceived of and supervised the Project and its different
             components as LAS Chief between 2003-05 and later as Project Co-ordinator).
             </p>
        <p class="bodytext">An ICC Legal Tools Expert Advisory Group (the LTEAG) was established in 2005 with leading legal informatics experts to serve as an advisory and sounding board for the further technical and strategic development of the Legal Tools. The members of the Group are: <br/> Professor <a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> (Norway); Professor <a href="http://herberger.jura.uni-sb.de/herberger/" target="_blank">Maximilian Herberger</a>, University of Saarland (Germany); Professor Anja Oskamp, Free University Amsterdam (the Netherlands); Professor <a href="https://www.gu.se/en/about/find-staff/ulfpetrusson" target="_blank">Ulf Petrusson</a> (University of Gothenburg); Professor <a href="https://people.cs.kuleuven.be/~sien.moens/" target="_blank">Marie-Francine (Sien) Moens</a>, Catholic University Leuven (Belgium); Professor Richard V. De Mulder, Erasmus University Rotterdam (the Netherlands); and Professor <a href="https://www.eui.eu/people?id=giovanni-sartor" target="_blank">Giovanni Sartor</a>, European University Institute (Italy). The late Professor <a href="https://en.wikipedia.org/wiki/Jon_Bing" target="_blank">Jon Bing</a> was the first Chair of the Group. The inaugural meeting of the Group took place at the interim seat of the Court in The Hague on 7 December 2005.</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
    </div>`;
export default projectBackgroundEn;
