// GENERAL
export const GENERAL = 'general';
export const GENERAL_SELECT_ALL = GENERAL + '.selectAll';
export const GENERAL_CLEAR_SELECTION = GENERAL + '.clearSelection';
export const GENERAL_SELECTED = GENERAL + '.selected';
export const GENERAL_MORE = GENERAL + '.more';
export const GENERAL_OK = GENERAL + '.ok';
export const GENERAL_YES = GENERAL + '.yes';
export const GENERAL_NO = GENERAL + '.no';
export const GENERAL_AND = GENERAL + '.and';
export const GENERAL_OR = GENERAL + '.or';
export const GENERAL_NOT = GENERAL + '.not';
export const GENERAL_CANCEL = GENERAL + '.cancel';
export const GENERAL_SUBMIT = GENERAL + '.submit';
export const GENERAL_ENTER = GENERAL + '.enter';
export const GENERAL_SAVE = GENERAL + '.save';
export const GENERAL_DELETE = GENERAL + '.delete';
export const GENERAL_READ_LATER = GENERAL + '.readLater';
export const GENERAL_ADD_TO_READING_LIST = GENERAL + '.addToReadingList';
export const GENERAL_NEW_READING_LIST = GENERAL + '.newReadingList';
export const GENERAL_CREATE_NEW_READING_LIST = GENERAL + '.createNewReadingList';
export const GENERAL_DOWNLOAD = GENERAL + '.download';
export const GENERAL_APPLY = GENERAL + '.apply';
export const GENERAL_RESET = GENERAL + '.reset';
export const GENERAL_HELP = GENERAL + '.help';
export const GENERAL_DASHBOARD = GENERAL + '.dashboard';
export const GENERAL_SETTINGS = GENERAL + '.settings';
export const GENERAL_READING_LIST = GENERAL + '.readingList';
export const GENERAL_LOGIN = GENERAL + '.login';
export const GENERAL_LOGOUT = GENERAL + '.logout';
export const GENERAL_SIGNUP = GENERAL + '.signup';
export const GENERAL_ADD = GENERAL + '.add';
export const GENERAL_NAME = GENERAL + '.name';
export const GENERAL_EMAIL = GENERAL + '.email';
export const GENERAL_PASSWORD = GENERAL + '.password';
export const GENERAL_REPEAT_PASSWORD = GENERAL + '.repeatPassword';

// TOAST MESSAGES
export const TOAST = 'toastMessages';
export const TOAST_LINK_COPIED_TO_CLIPBOARD = TOAST + '.linkCopiedToClipboard';

// SEARCH PANEL
export const SEARCH_PANEL = 'searchPanel';
export const SEARCH_PANEL_TEXT_SEARCH_TAB = SEARCH_PANEL + '.textSearchTab';
export const SEARCH_PANEL_KEYWORD_SEARCH_TAB = SEARCH_PANEL + '.keywordSearchTab';
export const SEARCH_PANEL_ADVANCED_SEARCH_BUTTON = SEARCH_PANEL + '.advancedSearchButton';
export const SEARCH_PANEL_SEARCH_INPUT_PLACEHOLDER = SEARCH_PANEL + '.searchInputPlaceholder';
export const SEARCH_PANEL_DOCUMENT_NUMBER_INPUT_PLACEHOLDER = SEARCH_PANEL + '.documentNumberInputPlaceholder';
export const SEARCH_PANEL_TYPE_OF_DECISION_INPUT_PLACEHOLDER = SEARCH_PANEL + '.typeOfDecisionInputPlaceholder';
export const SEARCH_PANEL_LEGAL_FINDING_ID_INPUT_PLACEHOLDER = SEARCH_PANEL + '.legalFindingIDInputPlaceholder';
export const SEARCH_PANEL_DATE_FROM_INPUT_PLACEHOLDER = SEARCH_PANEL + '.dateFromInputPlaceholder';
export const SEARCH_PANEL_DATE_TO_INPUT_PLACEHOLDER = SEARCH_PANEL + '.dateToInputPlaceholder';
export const SEARCH_PANEL_SEARCH_LEGAL_FINDING_BUTTON = SEARCH_PANEL + '.searchLegalFindingButton';
export const SEARCH_PANEL_SEARCH_DECISION_BUTTON = SEARCH_PANEL + '.searchDecisionButton';

// ADVANCED SEARCH DIALOG
export const ADVANCED_SEARCH_DIALOG = 'advancedSearchDialog';
export const ADVANCED_SEARCH_DIALOG_TITLE = ADVANCED_SEARCH_DIALOG + '.title';
export const ADVANCED_SEARCH_DIALOG_SEARCH_LEGAL_FINDING_BUTTON = ADVANCED_SEARCH_DIALOG + '.searchLegalFindingButton';
export const ADVANCED_SEARCH_DIALOG_SEARCH_DECISION_BUTTON = ADVANCED_SEARCH_DIALOG + '.searchDecisionButton';
export const ADVANCED_SEARCH_DIALOG_FORM_QUERY = ADVANCED_SEARCH_DIALOG + '.formQuery';
export const ADVANCED_SEARCH_DIALOG_FORM_PROXIMITY_OF_WORDS = ADVANCED_SEARCH_DIALOG + '.formProximityOfWords';

// KEYWORD TREE TAB
export const KEYWORD_TREE_TAB = 'keywordTreeTab';
export const KEYWORD_TREE_TAB_SEARCH_INPUT_PLACEHOLDER = KEYWORD_TREE_TAB + '.searchInputPlaceholder';
export const KEYWORD_TREE_TAB_COLLAPSE_KEYWORD_TREE_BUTTON = KEYWORD_TREE_TAB + '.collapseKeywordTreeButton';

// CARD
export const CARD = 'card';
export const CARD_COPY_URL = CARD + '.copyURL';
export const CARD_SEE_FULL = CARD + '.seeFull';
export const CARD_METADATA = CARD + '.metadata';
// export const CARD_DOWNLOAD = CARD + '.download';
export const CARD_REMOVE_FROM_LIST = CARD + '.removeFromList';

// LISTING HEADER
export const LISTING_HEADER = 'listingHeader';
export const LISTING_HEADER_LATEST = LISTING_HEADER + '.latest';
export const LISTING_HEADER_SELECT_ALL = LISTING_HEADER + '.selectAll';
export const LISTING_HEADER_SORTED_BY = LISTING_HEADER + '.sortedBy';
export const LISTING_HEADER_RESULTS_PER_PAGE = LISTING_HEADER + '.resultsPerPage';

// FILTER SECTION
export const FILTER_SECTION = 'filterSection';
export const FILTER_SECTION_TITLE_TEXT = FILTER_SECTION + '.filterTitleText';
export const FILTER_SECTION_SOURCE = FILTER_SECTION + '.source';
export const FILTER_SECTION_SITUATION = FILTER_SECTION + '.situation';
export const FILTER_SECTION_CASE = FILTER_SECTION + '.case';
export const FILTER_SECTION_IMPORTANCE = FILTER_SECTION + '.importance';
export const FILTER_SECTION_LANGUAGES = FILTER_SECTION + '.languages';
export const FILTER_SECTION_PRESIDING_JUDGES = FILTER_SECTION + '.presidingJudges';
export const FILTER_SECTION_TYPE_OF_DECISION = FILTER_SECTION + '.typeOfDecision';
export const FILTER_SECTION_PHASE_OF_CASE = FILTER_SECTION + '.phaseOfCases';
export const FILTER_SECTION_DECISION_ASSESSMENT = FILTER_SECTION + '.decisionAssessment';

// LEGAL FINDING DOCUMENT
export const LEGAL_FINDING_DOCUMENT = 'legalFindingDocument';
export const LEGAL_FINDING_DOCUMENT_TAB_TEXT = LEGAL_FINDING_DOCUMENT + '.legalFindingTabText';
export const LEGAL_FINDING_DOCUMENT_INFO_TAB_TEXT = LEGAL_FINDING_DOCUMENT + '.infoTabText';
export const LEGAL_FINDING_DOCUMENT_METADATA_OF_LF = LEGAL_FINDING_DOCUMENT + '.metadataOfLegalFinding';
export const LEGAL_FINDING_DOCUMENT_ID = LEGAL_FINDING_DOCUMENT + '.id';
export const LEGAL_FINDING_DOCUMENT_PARA_LINES = LEGAL_FINDING_DOCUMENT + '.paraLines';
export const LEGAL_FINDING_DOCUMENT_PAGES = LEGAL_FINDING_DOCUMENT + '.pages';
export const LEGAL_FINDING_DOCUMENT_LEGAL_FINDING_NUMBER = LEGAL_FINDING_DOCUMENT + '.legalFindingNumber';
export const LEGAL_FINDING_DOCUMENT_LEVEL_OF_IMPORTANCE = LEGAL_FINDING_DOCUMENT + '.levelOfImportance';
export const LEGAL_FINDING_DOCUMENT_MAJORITY_DISSENTING = LEGAL_FINDING_DOCUMENT + '.majorityDissenting';
export const LEGAL_FINDING_DOCUMENT_PERMANENT_URL = LEGAL_FINDING_DOCUMENT + '.permanentUrl';
export const LEGAL_FINDING_DOCUMENT_TITLE_OF_DECISION = LEGAL_FINDING_DOCUMENT + '.titleOfDecision';
export const LEGAL_FINDING_DOCUMENT_SOURCE = LEGAL_FINDING_DOCUMENT + '.source';
export const LEGAL_FINDING_DOCUMENT_SITUATION_CASE = LEGAL_FINDING_DOCUMENT + '.situationCase';
export const LEGAL_FINDING_DOCUMENT_DATE = LEGAL_FINDING_DOCUMENT + '.date';
export const LEGAL_FINDING_DOCUMENT_DOCUMENT_NUMBER = LEGAL_FINDING_DOCUMENT + '.documentNumber';
export const LEGAL_FINDING_DOCUMENT_PHASE_OF_CASE = LEGAL_FINDING_DOCUMENT + '.phaseOfCase';
export const LEGAL_FINDING_DOCUMENT_TYPE_OF_DECISION = LEGAL_FINDING_DOCUMENT + '.typeOfDecision';
export const LEGAL_FINDING_DOCUMENT_JUDGES = LEGAL_FINDING_DOCUMENT + '.judges';
export const LEGAL_FINDING_DOCUMENT_NUMBER_OF_PAGES = LEGAL_FINDING_DOCUMENT + '.numberOfPages';
export const LEGAL_FINDING_DOCUMENT_LANGUAGE = LEGAL_FINDING_DOCUMENT + '.language';

// DECISION DOCUMENT
export const DECISION_DOCUMENT = 'decisionDocument';
export const DECISION_DOCUMENT_INFO_TAB_TEXT = DECISION_DOCUMENT + '.infoTabText';
export const DECISION_DOCUMENT_PDF_TAB_TEXT = DECISION_DOCUMENT + '.pdfTabText';
export const DECISION_DOCUMENT_METADATA_OF_DECISION = DECISION_DOCUMENT + '.metadataOfDecision';
export const DECISION_DOCUMENT_TITLE_OF_DECISION = DECISION_DOCUMENT + '.titleOfDecision';
export const DECISION_DOCUMENT_SOURCE = DECISION_DOCUMENT + '.source';
export const DECISION_DOCUMENT_SITUATION_CASE = DECISION_DOCUMENT + '.situationCase';
export const DECISION_DOCUMENT_DATE = DECISION_DOCUMENT + '.date';
export const DECISION_DOCUMENT_DOCUMENT_NUMBER = DECISION_DOCUMENT + '.documentNumber';
export const DECISION_DOCUMENT_PHASE_OF_CASE = DECISION_DOCUMENT + '.phaseOfCase';
export const DECISION_DOCUMENT_TYPE_OF_DECISION = DECISION_DOCUMENT + '.typeOfDecision';
export const DECISION_DOCUMENT_JUDGES = DECISION_DOCUMENT + '.judges';
export const DECISION_DOCUMENT_NUMBER_OF_PAGES = DECISION_DOCUMENT + '.numberOfPages';
export const DECISION_DOCUMENT_LANGUAGE = DECISION_DOCUMENT + '.language';
export const DECISION_DOCUMENT_PERMANENT_URL = DECISION_DOCUMENT + '.permanentUrl';
export const DECISION_DOCUMENT_DECISION_NOT_ANALYSED = DECISION_DOCUMENT + '.decisionNotAnalysed';
export const DECISION_DOCUMENT_NO_LEGAL_FINDING_IN_DECISION = DECISION_DOCUMENT + '.noLegalFindingInDecision';
export const DECISION_DOCUMENT_LEGAL_FINDING_CONTAINED_IN_DECISION = DECISION_DOCUMENT + '.lfContainedInThisDecision';

// AUTHENTICATION
export const AUTHENTICATION = 'authentication';
export const AUTHENTICATION_FORGOT_PASSWORD = AUTHENTICATION + '.forgotPassword';
export const AUTHENTICATION_NEW_USER = AUTHENTICATION + '.newUser';
export const AUTHENTICATION_SIGN_UP_HERE = AUTHENTICATION + '.signUpHere';
export const AUTHENTICATION_ALREADY_A_REGISTERED_USER = AUTHENTICATION + '.alreadyRegisteredUser';
