import React, { Component, ReactNode, Children } from 'react'
import { createStyles, WithStyles, withStyles, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TypographyProps } from '@material-ui/core/Typography';
import { string } from 'prop-types';

interface Props extends WithStyles<typeof STYLES> {
    variant?: TypographyProps['variant'],
    color?: TypographyProps['color'],
    linkColor?: string,
    className?: React.HTMLAttributes<HTMLDivElement>['className']
    // linkColor?: 
}

class Text extends Component<Props> {

    createLinkElement = (text) => {
        const { classes, linkColor } = this.props;
        return (<a href={text} target="_blank" style={{ color: linkColor }} key={_.uniqueId(text)} className={classes.link}>{encodeURI(text)} </a>)
    }

    openLink = (url) => window.open(url, '_blank');

    renderText = (text) => {
        var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        var regex = new RegExp(expression);

        const newText = _.map(_.split(text, ' '), word => {
            if (word.match(regex))
                return this.createLinkElement(word);
            else
                return word + ' ';
        })

        return newText;
    }

    render() {
        const { classes, variant = "body1", color = "inherit", className = '' } = this.props
        return (
            <Typography style={{ display: 'flex', alignItems: 'center' }} className={className} variant={variant} color={color}>
                {this.renderText(this.props.children)}
            </Typography>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = theme => createStyles({
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        wordBreak: 'break-all',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Text))