import { TAction } from "../../Resources/Model";
import { OCLDDocumentTree } from "./redux-config";
import { Reducer } from "redux";
import { TDocumentTreeState, TNode } from "./@types";
import _ from "lodash";
import { mapValueAndId } from '../LTDDocuments/redux-config'
import utilities from '../../Resources/Utils';
import { node } from "prop-types";
import { OCLDDocuments } from "../CLDDocuments/redux-config";
import { ONewLegalFinding } from "../AddNewLegalFinding/redux-config";


const initialState = {
    tree: [],
    initialTree: [],
    itemsSelected: [],
    idsToSearch: []
}



export const selectAllNodes = (tree: Array<TNode>, isKeywordSelection) => {
    let _tree = [...tree];
    let selectedIds: Array<TNode> = [];

    _.forEach(_tree, node => {
        if (!_.isEmpty(node.children)) {
            selectedIds = [...selectedIds, ...selectAllNodes(node.children as Array<TNode>, isKeywordSelection)]
        }
        if (!isKeywordSelection && node.level && node.level > 1)
            selectedIds.push(node);
        else if (node.keywordGeneratedId && !node.hasChildrenMenuNode)
            selectedIds.push(node);

    });
    return selectedIds;
}





export const getNodesOfLevel = async (level: number): Promise<Array<string>> => {
    const res = await utilities.request({
        url: '/keywords',
        params: {
            filter: { where: { level } }
        }
    });

    return res.data.map(node => node.id);
}


export const groupTree = (tree: Array<TNode>, level2: Array<TNode>) => {
    let newTree: Array<TNode> = _.map(tree, node => node.level === 1 ? ({ ...node, nodeType: 'LABEL', text: node.keyword_text } as TNode) : node);
    if (_.isEmpty(level2))
        return newTree;

    const _tree = _.map(newTree, node => {
        const children = _.filter(level2, _node => _node.parentId === node.id);
        return { ...node, children }
    })

    return _tree;
}

// export const groupTree = (tree, ) => {
//     let newTree: Array<any> = [];
//     const groups = _.groupBy(tree, node => node.text);
//     _.forEach(groups, (value, key) => {
//         let newNode = {
//             id: _.uniqueId(key),
//             nodeType: 'LABEL',
//             text: key,
//             childrenIds: _.map(value, node => node.id),
//             children: _.map(value, node => {
//                 node.hasChildrenMenuNode = true;
//                 return node;
//             }),
//             hasChildrenMenuNode: true
//         }
//         newTree.push(newNode);
//     })
//     return newTree;
// }



export const appendChildren = (tree: Array<TNode>, children: Array<TNode>, parentId: string) => {
    let _tree = [...tree];
    _.forEach(_tree, node => {
        if (!_.isEmpty(node.children)) {
            return appendChildren(node.children as Array<TNode>, children, parentId)
        }
        else if (node.id === parentId) {
            node.children = children;
        }
    })
    return _tree;
}



export const removeChildren = (tree: Array<TNode>, parentId: string) => {
    let _tree = [...tree];
    _.forEach(_tree, node => {
        if (node.id === parentId) {
            node.children = [];
            return node;
        }
        if (!_.isEmpty(node.children)) {
            node.children = removeChildren(node.children as Array<TNode>, parentId);
        }
    })
    return _tree;
}

export const getAllChildren = (node: TNode, children: Array<TNode> = []) => {
    let _children = children
    _.forEach(node.children, (child: TNode) => {
        _children.push(child);
        if (child.children)
            _children = getAllChildren(child, _children);
    })
    return _children;
}

export const getParent = (id: string, tree: Array<TNode>) => {

    for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        // console.log("node1", node)
        if (node.childrenIds && _.findIndex(node.childrenIds, childId => childId === id) >= 0) {
            // console.log("node", node);
            return node;
        }
        else if (node.children && node.children.length) {
            let parent = getParent(id, node.children);
            if (parent) {
                // console.log("parent", parent);
                return parent;
            }
        }
    }
    // console.log("undefined", undefined);
}
export const getAllSiblings = async (node: TNode) => {
    if (node.level) {
        const res = await utilities.request({
            url: '/nodes',
            params: {
                filter: { where: { parentId: node.parentId } }
            }
        });
        return res.data;
    }
    return [];
}


export const findNode = async (id: string) => {

    const res = await utilities.request({
        url: '/nodes',
        params: {
            filter: { where: { id } }
        }
    })
    return res.data[0];
}


export const getAllAncestors = (tree: Array<TNode>, root: Array<TNode>, id: string, ancestors: Array<TNode> = []) => {
    let _ancestors = getAncestors(tree, root, id, ancestors);
    return _.filter(_ancestors, node => node.id !== id);
}

export const getAncestors = (tree: Array<TNode>, root: Array<TNode>, id: string, ancestors: Array<TNode> = []) => {
    let _ancestors = ancestors;
    _.forEach(root, (node: TNode) => {
        if (node.id === id) {
            _ancestors.push(node);
            if (node.parentId)
                _ancestors = getAncestors(tree, tree, node.parentId, _ancestors);
        }
        else if (node.children)
            _ancestors = getAncestors(tree, node.children, id, _ancestors);
    })
    return _ancestors;
}




export const NODE_LABEL_URL = {
    'Rome Statute': 'https://www.icc-cpi.int/resource-library/Documents/RS-Eng.pdf',
    'Rules of Procedure and Evidence': 'https://www.icc-cpi.int/resource-library/Documents/RulesProcedureEvidenceEng.pdf',
    'Regulations of the Court': 'https://www.icc-cpi.int/resource-library/Documents/RegulationsCourt_2018Eng.pdf',
    'Regulations of the Registry': 'https://www.icc-cpi.int/resource-library/Documents/RegulationsRegistryEng.pdf',
    'Code of Professional Conduct for counsel': 'https://www.icc-cpi.int/resource-library/Documents/COPCEng.pdf',
    // 'Independent from Legal Texts': '#'
}



export const CLDDocumentTree: Reducer<TDocumentTreeState> = (state = initialState, action: TAction) => {
    const {
        CLD_FETCHED_TREE_NODES,
        CLD_FETCHED_CHILDREN,
        CLD_REMOVED_CHILDREN,
        CLD_RESET_TREE,
        CLD_TREE_ITEM_SELECTED,
        CLD_TREE_ITEM_UNSELECTED,
        CLD_NEW_TREE_FETCHED,
        CLD_TREE_CLEAR_SELECTION
    } = OCLDDocumentTree.actions;

    const {
        CLD_RESET_VALUES
    } = OCLDDocuments.actions;

    const {
        CLOSE_NEW_LEGAL_FINDING_DIALOG
    } = ONewLegalFinding.actions



    // NOTE ----------------------------------------------------------------------------------------------------
    // itemsSelected: Array<nodeIds>  -> list of nodes which is to be shown as checked in UI                    |
    // idsToSearch: Array<nodeIds>    -> Same as [itemsToSearch] except that, it stores only the parentIds.     |
    //----------------------------------------------------------------------------------------------------------
    switch (action.type) {
        case CLOSE_NEW_LEGAL_FINDING_DIALOG:
        case CLD_RESET_VALUES:
        case CLD_TREE_CLEAR_SELECTION: {
            return { ...state, itemsSelected: [], idsToSearch: [] }
        }

        case CLD_NEW_TREE_FETCHED: {
            return { ...state, tree: action.data }
        }
        case CLD_TREE_ITEM_UNSELECTED:
        case CLD_TREE_ITEM_SELECTED:
            return { ...state, itemsSelected: action.data.itemsSelected, idsToSearch: action.data.idsToSearch }

        case CLD_RESET_TREE: {
            return { ...state, tree: _.cloneDeep(state.initialTree) }
        }
        case CLD_REMOVED_CHILDREN: {
            return { ...state, tree: removeChildren(state.tree, action.data) }
        }
        case CLD_FETCHED_CHILDREN: {
            // let itemsSelected = _.filter(state.itemsSelected, id => id !== action.data.parentId);
            // console.log("s->", itemsSelected, action.data)

            // if (_.findIndex(itemsSelected, id => id === action.data.parentId) >= 0) {
            //     let menuNodes = _.filter(action.data.children, child => child.nodeType === 'MENU')
            //     let ids = _.map(menuNodes, 'id');
            //     itemsSelected = [...itemsSelected, ...ids];
            //     _.forEach(menuNodes, mapValueAndId)
            // }
            const children = _.map(action.data.children, node => {
                node.hasChildrenMenuNode = !!node.childrenIds.length;
                return node;
            })
            return { ...state, tree: appendChildren(state.tree, children, action.data.parentId) }
        }
        case CLD_FETCHED_TREE_NODES: {
            const tree = _.map(action.data, (node) => {
                if (node.nodeType === 'LABEL')
                    return { ...node, url: NODE_LABEL_URL[node.keyword_text] };
                else
                    node.hasChildrenMenuNode = !!node.childrenIds.length;
                return node;
            })
            // here we store a copy of initial tree just in case the user wants to collapse/reset the tree
            return { ...state, tree, initialTree: _.cloneDeep(tree) }
        }
        default: return { ...state }
    }
}