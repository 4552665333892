import _ from 'lodash';
import moment from 'moment';
/**
 * {
 *    action : 'Action taken on entity. Provide string or function',
 *    when  : Date by default. Provide date/func to override,
 *    type : Try to figure out by default. Set 'type' as string/func to override,
 *    urlText : Provide entity/object key for _.get() method of lodash or function which returns a string.
 *    url : Provide link url to go to
 *    by : user.name by default. Use 'by' key as fallback. Provide string/func to override
 *    status : Read 'status' key. Provide string/func to override.
 *    availableStatuses : Read from app constants. Provide an array/func to override
 *    disablePublished : true
 * }
 */

export const LOG_CONFIG = {
    create_node: {
        action: 'Create',
        urlText: (log) => { return _.get(log, 'res.text') || _.get(log, 'req.node.text') }
    },
    edit_node: {
        action: 'Edit',
        urlText: (log) => {
            const currentText = _.get(log, 'subject.text') || '';
            const newText = _.get(log, 'req.text') || '';
            return `${currentText} --> ${newText}`;
        }
    },
    hide_node: {
        action: 'Delete',
        urlText: 'subject.text'
    },
    delete_node: {
        action: 'Permanent Delete',
        urlText: 'prevVersion.text'
    },
    shift_node: {
        action: 'Shift',
        urlText: 'subject.text'
    },
    unhide_node: {
        action: 'Restore',
        urlText: 'subject.text'
    },
    create_ltd_doc: {
        action: 'Create',
        urlText: (log) => {
            return _.get(log, 'req.doc.title')
        }
    },
    create_cld_doc: {
        action: 'Create',
        urlText: (log) => {
            return _.get(log, 'req.cldDoc.Title') || _.get(log, 'req.cldDoc.ltdDoc.title')
        }
    },
    edit_ltd_doc: {
        action: 'Edit',
        urlText: (log) => {
            return _.get(log, 'req.doc.title')
        }
    },
    edit_cld_doc: {
        action: 'Edit',
        urlText: (log) => {
            return _.get(log, 'req.cldDoc.Title') || _.get(log, 'req.cldDoc.ltdDoc.title')
        }
    },
    delete_ltd_doc: {
        action: 'Delete',
        urlText: (log) => {
            return _.get(log, 'req.doc.title')
        }
    },
    delete_cld_doc: {
        action: 'Delete',
        urlText: (log) => {
            return _.get(log, 'req.cldDoc.Title') || _.get(log, 'req.cldDoc.ltdDoc.title')
        }
    },
    move_ltd_doc: {
        action: 'Move',
        urlText: 'subject.title'
    },
    copy_ltd_doc: {
        action: 'Copy',
        urlText: 'subject.title'
    },
    restore_ltd_doc: {
        action: 'Restore',
        urlText: 'subject.title'
    },
    permanent_delete_ltd_doc: {
        action: 'Permanent Delete',
        urlText: 'prevVersion.title'
    },
    publish_ltd_doc: {
        action: 'Published',
        urlText: 'subject.title'
    },
    reject_activitylog: {
        action: 'Reject',
        urlText: (log) => {
            const subjectType = _.get(log, 'subject.subjectType');
            if (subjectType === 'Node')
                return (_.get(log, 'subject.req.text') || _.get(log, 'subject.req.node.text'));
            return _.get(log, 'subject.req.doc.title');
        },
        type: 'subject.subjectType'
    },
    publish_activitylog: {
        action: 'Publish',
        urlText: (log) => {
            const subjectType = _.get(log, 'subject.subjectType');
            if (subjectType === 'Node')
                return (_.get(log, 'subject.req.text') || _.get(log, 'subject.req.node.text'));

            return _.get(log, 'subject.req.doc.title');
        },
        type: 'subject.subjectType'
    },
    unpublish_ltd_doc: {
        action: 'Unpublished',
        urlText: 'subject.title'
    },
    update_decision_import_ltd_doc: {
        action: 'Update Decision Import',
        urlText: log => _.get(log, 'subject.title')
    },
    create_decision_import_ltd_doc: {
        action: 'Create Decision Import',
        urlText: log => _.get(log, 'subject.title')
    },
    update_decision_import_cld_doc: {
        action: 'Update Legal Finding Import',
        urlText: log => _.get(log, 'subject.title')
    },
    create_decision_import_cld_doc: {
        action: 'Create Legal Finding Import',
        urlText: log => _.get(log, 'subject.title')
    }
}

const valOrFunc = (logConfig, log, key) => {
    const val = _.get(logConfig, key);
    if (_.isFunction(val))
        return val(log);
    return _.get(log, val) || val;
}

const LOG_ENTITY_TYPE = {
    CldDoc: 'Legal Finding',
    LtdDoc: 'Decision'
}

function getLogEntityType(key, appConstants) {
    const val = _.find(_.get(appConstants, 'ActivityLog.cld.subjectType', []), { value: key });
    return val ? val.name : ''
}

const DATE_FORMAT = 'DD MMM, YYYY, HH:mm a';
export const getEntry = (log, appConstants) => {
    const configKey = `${_.snakeCase(_.get(log, 'action'))}_${_.snakeCase(_.get(log, 'subjectType'))}`;
    const config = LOG_CONFIG[configKey];
    if (_.isEmpty(config)) {
        console.warn('Config not found for log', log, configKey);
        return;
    }
    const entry = {};
    //Entry action
    entry.action = valOrFunc(config, log, 'action');
    //Entry date
    const date = valOrFunc(config, log, 'when');
    let momentDate = (_.isString(date)) ? moment(date) : moment(log['created']);
    if (momentDate.isValid && momentDate.isValid())
        entry.when = momentDate.format(DATE_FORMAT);
    //Entry type
    let entryType = valOrFunc(config, log, 'type') || log.subjectType;
    entry.type = getLogEntityType(entryType, appConstants) || entryType;
    //Entry text
    entry.urlText = valOrFunc(config, log, 'urlText');
    //Entry url
    //TODO implement entry url
    //Entry by entity
    entry.by = valOrFunc(config, log, 'by') || _.get(log, 'user.name') || _.get(log, 'by');
    //Entry status
    entry.status = valOrFunc(config, log, 'status') || _.get(log, 'status');
    return entry;

}

const AVAILABLE_STATUS = {
    'draft': ['draft', 'review', 'published', 'rejected'],
    'review': ['review', 'published', 'rejected'],
    'published': ['published'],
    'rejected': ['rejected']
}
export const getLogActions = (log, permissions) => {
    let availableOptions = AVAILABLE_STATUS[log.status] || [];
    if (availableOptions.length > 1 && log.subjectType === 'LtdDoc' && (!permissions.docPublish))
        availableOptions = _.filter(availableOptions, option => (option !== 'published'));
    if (availableOptions.length > 1 && log.subjectType === 'LtdDoc' && (log.action === 'delete' && !permissions.docDeletePublish))
        availableOptions = _.filter(availableOptions, option => (option !== 'published'));
    if (availableOptions.length > 1 && log.subjectType === 'Node' && !permissions.nodePublish)
        availableOptions = _.filter(availableOptions, option => (option !== 'published'));

    if (availableOptions.length > 1 && log.subjectType === 'Node' && (log.action === 'hide' && !permissions.nodeDeletePublish))
        availableOptions = _.filter(availableOptions, option => (option !== 'published'));
    return availableOptions;
}