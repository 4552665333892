const projectBackgroundRu = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
    <div id="c258" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">История проекта</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p  class="bodytext">
        Юридические инструменты МУС были впервые разработаны Юридическим консультативным отделом (LAS) Канцелярии Прокурора МУС в период с 2003 по 2005 год. С 2006 года они развивались под руководством директора Центра исследований и политики в области международного права 
          (<a href="https://www.cilrap.org/purpose/" target="_blank">CILRAP</a>),&nbsp;
          <a href="https://www.cilrap.org/bergsmo/">Мортена Бергсмо</a> 
          (который задумал и руководил проектом и его различными компонентами в качестве руководителя LAS в период с 2003 по 2005 год, а затем в качестве координатора проекта).
             </p>
        <p class="bodytext">
        Консультативная группа экспертов по юридическим инструментам МУС (LTEAG) была создана в 2005 году с участием ведущих экспертов в области правовой информатики, чтобы служить в качестве консультативного совета для дальнейшего технического и стратегического развития Юридических инструментов. Членами группы являются:
        <br/> профессор 
        <a href="https://www.cilrap.org/bergsmo/" target="_blank">Мортен Бергсмо</a> 
        (Норвегия); профессор <a href="http://herberger.jura.uni-sb.de/herberger/" target="_blank"> Максимилиан Хербергер</a>,
Саарский университет (Германия); профессор Аня Оскамп, Свободный университет Амстердама (Нидерланды); профессор         
 <a href="https://www.gu.se/en/about/find-staff/ulfpetrusson" target="_blank">Ульф Петруссон</a>
          (Гетеборгский университет); профессор
          <a href="https://people.cs.kuleuven.be/~sien.moens/" target="_blank">Мари-Франсин (Сиен) Моэнс</a>,
           Католический университет Левена (Бельгия); профессор Ричард В. Де Малдер, Университет Эразма, Роттердам (Нидерланды); и профессор
            <a href="https://www.eui.eu/people?id=giovanni-sartor" target="_blank">Джованни Сартор</a>, 
            Институт Европейского университета (Италия). Покойный профессор 
            <a href="https://en.wikipedia.org/wiki/Jon_Bing" target="_blank"> Джон Бинг</a> был первым председателем группы. Учредительное заседание Группы состоялось во временной резиденции Суда в Гааге 7 декабря 2005 года.
            </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
    </div>`;
export default projectBackgroundRu;
