import React, { useState, createContext, useEffect } from 'react';


export const AppContext = createContext<TAppContextProviderValue>({
    context: 'ltd',
    setContext: () => { }
});

export const AppContextProvider: React.FC = (props) => {
    const [context, setContext] = useState<TAppContext>('ltd');



    // useEffect(() => {
    //     if (context === 'cld')
    //         document.title = 'CLD'
    //     else
    //         document.title = 'ICC Legal Tools'
    // }, [context])



    const providerValue: TAppContextProviderValue = {
        context,
        setContext
    }

    return (
        <AppContext.Provider value={providerValue}>
            {props.children}
        </AppContext.Provider>
    )
}


export type TAppContextProviderValue = {
    context: TAppContext
    setContext: (context: TAppContext) => void

}

export type TAppContext = 'ltd' | 'cld' | 'scc'