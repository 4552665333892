import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">教学视频</h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    法律工具系统易于操作，可在网上免费使用。所有用户可以观看以下视频。有需要对多个法律工具用户进行培训的机构可发送请求至 <a href="mailto:info@cilrap.org">info@cilrap.org</a> 或者  <a href="mailto:bais@cilrap.org">bais@cilrap.org</a> (<a href="https://www.cilrap.org/bais/" target="_blank">德瓦希什•巴伊斯 Devasheesh Bais</a> 是法律工具项目的助理协调员).</p>

    <div class="grid">
    ${TUTORIAL_FILM_URLS.map(
        (url, i) => `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i + 1}.png" style="width: 100%;" alt="Tutorial ${i + 1}" />
        </a>
    `
    ).join('')}
    </div>

    
    <video class="my-4" controls poster="video-tutorial-dps.png" width="100%"><source src="https://cilrap-film.fra1.digitaloceanspaces.com/221001-icl-public-sources.mp4" type="video/mp4"></video>	
    <video class="my-4" controls poster="https://cilrap-film.fra1.digitaloceanspaces.com/thumbs/211005-thumbnail.jpg" width="100%"><source src="https://cilrap-film.fra1.digitaloceanspaces.com/210930-icl-digital-public-goods.mp4" type="video/mp4"></video>	
    <video class="my-4" controls poster="https://lexsitus.cmn-kh.org/210915-ltd-cld-lexsitus-thumbnail.jpg" width="100%"><source src="https://cilrap-film.fra1.digitaloceanspaces.com/210915-ltd-cld-lexsitus.mp4" type="video/mp4"></video>
    
    <!--  Text: [begin] -->
        <video controls width="100%"><source src="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Trainings_03_Aug_2020.mp4" type="video/mp4"></video>
    <!--  Text: [end] -->
    <video class="my-4" controls poster="https://cilrap-film.fra1.digitaloceanspaces.com/thumbs/211216-LTD-Ukraine-thumbnail.jpg" width="100%"><source src="https://cilrap-film.fra1.digitaloceanspaces.com/211216-LTD-Ukraine.mp4" type="video/mp4"></video>
        </div>

    </div>`;

export default tutorialFilmZh;
