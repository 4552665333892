import { combineReducers } from "redux";
import { App } from "./AppReducer";
import { TAppState } from "./AppReducer";
import { Documents } from "../Features/LTDDocuments/documents-reducer";
import { TDocumentState } from "../Features/LTDDocuments/@types";
import { TDocumentState as TCLDDocumentState } from "../Features/CLDDocuments/@types";
import { DetailPage } from '../Features/DetailPage/detailpage-reducer';
import { TDetailPageState } from "../Features/DetailPage/@types";
import { Auth } from "../Features/Authentication/authentication-reducer";
import { TAuthState } from "../Features/Authentication/@types";
import { ReadingList } from "../Features/ReadingList/redinglist-reducer";
import { TReadingListState } from "../Features/ReadingList/@types";
import { DocumentTree } from "../Features/DocumentsTree/documentTree-reducer";
import { TDocumentTreeState } from "../Features/DocumentsTree/@types";
import { AdvancedSearch } from '../Features/AdvancedSearch/advancedSearch-reducer';
import { TAdvancedSearchState } from "../Features/AdvancedSearch/@types";
import { CLDDocumentTree } from '../Features/CLDDocumentTree/cld-documentTree-reducer';
import { CLDDocuments } from '../Features/CLDDocuments/cld-documents-reducer';
import { NewLegalFinding } from '../Features/AddNewLegalFinding/addNewLegalFinding-reducer';
import { TNewLegalFindingState } from '../Features/AddNewLegalFinding/@types';
import { TNewLTDDocumentState } from "../Features/AddNewLTDDocument/@types";
import { NewLTDDocument } from '../Features/AddNewLTDDocument/addNewLegalFinding-reducer';
import { LtdDashboard } from '../Features/LtdDashboard/ltdDashboard-reducer';
import { TLtdDashboardState } from "../Features/LtdDashboard/@types";
import { TResponsiveState } from "../Features/Responsive/@types";
import { Responsive } from '../Features/Responsive/responsive-reducer';
import { TBatchEditState } from "../Features/BatchEdit/@types";
import { BatchEditReducer } from "../Features/BatchEdit/batchEdit-reducer";
import { TBatchReviewState } from "../Features/BatchReview/@types";
import { BatchReviewReducer } from "../Features/BatchReview/batchReview-reducer";
import { NewDecision, TAddNewDecisionState } from "../Features/AddNewDecision/addNewDecision-reducer";
import { CldDashboard, TCldDashboardState } from "../Features/CldDashboard/cldDashboard-reducer";
import { Translations, TTranslationsState } from "../Features/Translations/translationsReducer";
import { CldFieldsReducer, TCldFieldState } from "../Features/CldField/redux-config";
import { TLtdFieldState, LtdFieldsReducer } from "../Features/LtdField/redux-config";


export interface TState {
    AdvancedSearch: TAdvancedSearchState,
    Auth: TAuthState,
    App: TAppState,
    BatchEdit: TBatchEditState,
    BatchReview: TBatchReviewState,
    Documents: TDocumentState,
    DetailPage: TDetailPageState,
    ReadingList: TReadingListState,
    DocumentTree: TDocumentTreeState,
    CLDDocumentTree: TDocumentTreeState,
    CLDDocuments: TCLDDocumentState,
    NewLegalFinding: TNewLegalFindingState,
    NewLTDDocument: TNewLTDDocumentState,
    NewDecision: TAddNewDecisionState,
    LtdDashboard: TLtdDashboardState,
    CldDashboard: TCldDashboardState,
    Responsive: TResponsiveState,
    Translations: TTranslationsState,
    CldFields: TCldFieldState,
    LTdFields: TLtdFieldState
}







export default combineReducers({
    AdvancedSearch,
    Auth,
    App,
    BatchEdit: BatchEditReducer,
    BatchReview: BatchReviewReducer,
    Documents,
    DetailPage,
    ReadingList,
    DocumentTree,
    CLDDocuments,
    CLDDocumentTree,
    NewLegalFinding,
    NewLTDDocument,
    LtdDashboard,
    CldDashboard,
    Responsive,
    NewDecision,
    Translations,
    CldFields: CldFieldsReducer,
    LtdFields: LtdFieldsReducer
});