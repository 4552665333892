const usePurlRu = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="purl/" >PURL&nbsp;»&nbsp;</a><a href="purl/how-to-use-the-purls-in-the-icc-legal-tools-database/" >How to use the PURLs in the ICC Legal Tools Database?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:248/text [begin] -->
    <div id="c248" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">
       Как использовать PURL-адреса в базе данных Юридических инструментов МУС?
        </h1></div>
    <!--  Header: [end] -->			
    <!--  Text: [begin] -->
        <p class="bodytext">
        Более 162 000 юридических источников по международному уголовному праву находятся в свободном доступе в базе данных Юридических инструментов («LTD»). Они включают в себя все общедоступные документы МУС (решения, документы и протоколы по делам Суда), а также решения и документы других международных трибуналов, органов по правам человека и национальных судов, всю подготовительную документацию по праву МУС, национальное законодательство и научные публикации в области международного уголовного права.
</p>
<p class="bodytext">
Каждому документу в LTD назначается постоянный интернет-адрес («Постоянный URL-адрес», известный как «PURL-адрес»), который не будет изменен. Когда вы делаете PURL-адрес документа гиперссылкой в своем тексте, вы позволяете читателям напрямую обращаться к цитируемому документу. Создание таких гиперссылок легко и требует менее 30 секунд. Общественная польза такой ссылки значительна, поскольку вы предоставляете читателям доступ к гораздо большему количеству правовых источников, чем тот, над которым вы, возможно, работаете сами. Таким образом, это практика, которая может расширить распространение международного уголовного права.
</p>
<p class="bodytext"><i><b>
Как найти PURL-адрес соответствующего документа?
</b></i>
</p>
<p class="bodytext">
Вы можете найти любой документ либо с помощью поисковой системы в верхнем левом меню, либо просматривая дерево папок в нижнем левом меню LTD. Независимо от того, какой тип навигации по LTD вы выбрали, вы можете скопировать PURL-адрес нужного документа, нажав на кнопку «КОПИРОВАТЬ PURL-адрес» в его карточке документа на странице результатов. Либо нажмите на ссылку нужного документа. Это приведет вас к странице метаданных соответствующего документа, которая информирует вас о заголовке, дате создания, источнике, составе судейской палаты, и т. д. Первая строка на странице метаданных содержит формулу PURL-адреса. Он всегда заканчивается косой чертой 
     (например, <a href="https://www.legal-tools.org/doc/ea422e/"
      target="_blank">http://www.legal-tools.org/doc/ea422e/</a>). 
    Скопируйте PURL-адрес и вставьте его в свой документ. Вы должны использовать только ссылку PURL, доступную на странице метаданных соответствующего документа, или ссылку, полученную нажатием кнопки «КОПИРОВАТЬ PURL-адрес». Не стоит копировать ссылку на страницу метаданных из адресной строки браузера, а также не стоит нажимать кнопку «Скачать» и копировать ссылку на целевой документ из адресной строки браузера. 
</p>
<p class="bodytext"><i><b>
Как создать гиперссылку в моем документе?
</b></i>
</p>
<p class="bodytext">
Чтобы интегрировать PURL-адрес в качестве гиперссылки в черновик текста, который вы готовите, выполните следующие три шага:

 <br>1.	 Отметьте текстовый элемент в черновике, на который вы хотите сделать гиперссылку.
  <br>2. Нажмите «Контроль» + «К». Появится новое маленькое окно.
  <br>3.	
Вставьте PURL-адрес в адресную строку внизу маленького окна, нажав «Control» + «V». Затем нажмите «ОК».</p>
<p class="bodytext"><i><b>
Как удалить гиперссылку?
</b></i>
</p>
<p class="bodytext">
Чтобы удалить гиперссылку, отметьте еще раз текстовый элемент, PURL-ссылку на который вы хотите удалить. Нажмите «Control» + «K», после чего снова появится маленькое окно. В этом окне вы увидите, что гиперссылка упоминается в адресной строке внизу окна. С правой стороны вы увидите кнопку «Удалить ссылку». Нажмите на нее, и гиперссылка исчезнет.
</p>
<p class="bodytext"><i><b>
Что делать, если документ отсутствует в базе данных Юридических инструментов МУС?</b></i>
</p>
<p class="bodytext">
   Если вы не найдете какой-то конкретный документ, напишите электронное письмо г-ну Девашишу Байсу (заместителю координатора проекта Юридических инструментов МУС)  поадресу
  <a href="mailto:bais@cilrap.org">bais@cilrap.org</a>&nbsp;
  ( всегда копируйте 
  <a href="mailto:info@cilrap.org">info@cilrap.org</a>).</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:248/text [end] -->
    </div>`;

export default usePurlRu;
