import defaultConfig from '../config.json';
let envConfig = {};

if (process.env.REACT_APP_NODE_ENV) {
    const env = process.env.REACT_APP_NODE_ENV.trim();
    envConfig = require(`../config.${env}.json`);
}

const config = {
    get: key => {
        if (key === 'DOCUMENT_BASE_URL' && window.location.host.includes('localhost'))
            return window.location.protocol + '//' + window.location.host + '/doc/';
        return envConfig[key] || defaultConfig[key];
    },
};

export default config;
