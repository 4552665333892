import React, { FC, useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography, Fab, Button } from '@material-ui/core';
import THEME from '../../Resources/Theme';
import PurlResource from '../../Components/PurlResource';
import { TDocument } from '../../Features/LTDDocuments/@types';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CloseButton from '../../Components/Buttons/CloseButton';
import config from '../../Resources/Config';
import { TState } from '../../RootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import Text from '../../Components/Text';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';


export interface LTDMergeRecordProps extends IStateProps, IDispatchProps, WithStyles<typeof STYLES> {
    title: string
    onClose: () => void
    disableAdd?: boolean
    proxySlugs?: string[]
    // document?: TDocument
    onMerge: (purls: string) => Promise<void>
    onUnmerge: (purl: string) => Promise<void>
}

interface IStateProps {
    document?: TDocument
}
interface IDispatchProps {
    showToast?: (msg: string, variant: TToastVariant) => void
}


const LTDMergeRecord: FC<LTDMergeRecordProps> = (props) => {
    const { classes, title, proxySlugs, disableAdd = false, document } = props;
    const [purls, setPurls] = useState<string[]>([])
    const PROXY_SLUGS = purls.filter(s => s.toString().length === 6);


    useEffect(() => {
        if (proxySlugs)
            setPurls(proxySlugs)
        else
            setPurls(_.get(document, 'proxySlugs') || [])
    }, [proxySlugs, document])

    const handleSelect = async ({ name, value }) => {
        try {
            await props.onMerge(value.slug)
            props.showToast && props.showToast("Record merged", 'success')
            const _purl = config.get('DOCUMENT_BASE_URL') + value.slug
            setPurls(purls => ([...purls, _purl]));
        } catch (error) {
            props.showToast && props.showToast("An error occured during merge", 'error')
        }
    }

    const handleRemove = (index: number) => async () => {
        try {
            await props.onUnmerge(PROXY_SLUGS[index]);
            props.showToast && props.showToast("Record unmerged", 'success')
            setPurls(purls => purls.filter((_, i) => i !== index));
        } catch (error) {
            props.showToast && props.showToast("An error occured during unmerge", 'error')
        }
    }


    const handleMerge = async () => {
        try {
            // await props.onMerge(purls);
            // setPurls([])
        } catch (error) {

        }
    }



    return (
        <div className={!disableAdd ? classes.root : ''}>
            {!disableAdd && (
                <div className={classes.header}>
                    <div >
                        <Typography className={classes.trimmed}>{title}</Typography>
                    </div>
                    <Fab size="small" onClick={props.onClose}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
            )}
            <div className={!disableAdd ? classes.listContainer : ''}>
                {!disableAdd && (
                    <div className={classNames(classes.sectionItem, classes.input)}>
                        <PurlResource
                            formKey="-"
                            label="Search by PURL"
                            placeholder="PURL"
                            onSelect={handleSelect}
                        />
                    </div>
                )}
                <div className={!disableAdd ? "my-4" : ''}>
                    {PROXY_SLUGS.map((purl, i) => (
                        <div className={classNames(classes.sectionItem, classes.border)} key={i}>
                            <Text>{config.get('DOCUMENT_BASE_URL') + purl}</Text>
                            <CloseButton onClick={handleRemove(i)} />
                        </div>
                    ))}
                </div>
                {/* <div className={classNames(classes.buttonContainer, classes.sectionItem)}>
                    <Button fullWidth variant="contained" onClick={handleMerge} color="primary">MERGE</Button>
                </div> */}
            </div>
        </div>
    )
}

const CONTAINER_WIDTH = 520;


const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box',
        background: THEME.Colors.Grayscale.Grey5
    },
    trimmed: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        lineClamp: 2,
        WebkitLineClamp: 2,
    },
    header: {
        position: 'fixed',
        width: CONTAINER_WIDTH,

        right: 0,
        display: 'flex',
        height: 51,
        zIndex: 1,
        padding: '0px 8px 0px 20px',
        boxSizing: 'border-box',
        alignItems: 'center',
        background: THEME.Colors.Grayscale.Grey1,
        '& p': {
            color: 'white'
        },
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    listContainer: {
        flex: 1,
        marginTop: 51,
        paddingBottom: 20

    },
    sectionItem: {
        padding: '0px 20px',
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        backgroundColor: theme.palette.common.white
    },
    border: {
        padding: '8px 20px',
        borderBottom: `1px dashed ${fade('#000', 0.2)}`
    },
    buttonContainer: {
        position: 'sticky',
        bottom: 20
    }
});


const mapStateToProps = (state: TState): IStateProps => ({
    document: _.get(state.DetailPage, 'currentDocument.document')
});

const mapDispatchToProps = dispatch => ({
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant))
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(LTDMergeRecord));