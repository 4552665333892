import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    IconButton,
    Button,
    Tooltip,
    CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Theme from '../../Resources/Theme';
import Text from '../../Components/Text';
// import EditIcon from '@material-ui/icons/Edit';
import { ICldPermissions, TUser } from '../../Features/Authentication/@types';
import MetadataForm from './MetadataForm';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import moment from 'moment';
import { TDocument } from '../../Features/LTDDocuments/@types';
import LegalFindingItem from './LegalFindingItem';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import utilities from '../../Resources/Utils';
import SelectedDocumentActions from '../../Components/SelectedDocumentActions';
import { OCLDDocuments, getSituationCase } from '../../Features/CLDDocuments/redux-config';
import { getCLDUserRole } from '../../Features/Authentication/redux-config';
import { OApp } from '../../RootReducer/AppReducer';
import { ONewDecision } from '../../Features/AddNewDecision/redux-config';
import { TState } from '../../RootReducer';
import config from '../../Components/NewDecision/config';
import { CldField } from '../../Services/CldFields.types';
import { CldFieldService } from '../../Services/CldFields.service';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    DECISION_DOCUMENT_DATE,
    DECISION_DOCUMENT_DECISION_NOT_ANALYSED,
    DECISION_DOCUMENT_DOCUMENT_NUMBER,
    DECISION_DOCUMENT_JUDGES,
    DECISION_DOCUMENT_LANGUAGE,
    DECISION_DOCUMENT_LEGAL_FINDING_CONTAINED_IN_DECISION,
    DECISION_DOCUMENT_METADATA_OF_DECISION,
    DECISION_DOCUMENT_NO_LEGAL_FINDING_IN_DECISION,
    DECISION_DOCUMENT_NUMBER_OF_PAGES,
    DECISION_DOCUMENT_PERMANENT_URL,
    DECISION_DOCUMENT_PHASE_OF_CASE,
    DECISION_DOCUMENT_SITUATION_CASE,
    DECISION_DOCUMENT_SOURCE,
    DECISION_DOCUMENT_TITLE_OF_DECISION,
    DECISION_DOCUMENT_TYPE_OF_DECISION,
    GENERAL_CLEAR_SELECTION,
    GENERAL_DELETE,
    GENERAL_SELECT_ALL,
} from '../../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {
    data: TDocument | undefined;
}
interface IStateProps {
    user: TUser;
    docsCheckedIds: Array<string>;
    decision: TDocument | undefined;
    cldFields: CldField[];
    cldPermissions: ICldPermissions;
}

interface IDispatchProps {
    addNewLegalFinding: (documentNumber: string) => void;
    clearCheckboxSelection: () => void;
    selectAllDocs: (ids: string[]) => void;
    deleteLegalFindings: (ids: string[]) => Promise<any>;
    confirmationDialog: (message: string, onConfirm: () => void, onCancel: () => void) => void;
    editDecision: (formData, decision) => void;
}

interface ComponentProps {
    editing: boolean;
}

const LEGAL_FINDING_GROUPS = [
    { key: 'Finalised', label: 'Finalised' },
    { key: 'Reviewed', label: 'Reviewed' },
    { key: 'Input in progress', label: 'Input in progress' },
    { key: 'Input completed', label: 'Input completed' },
    { key: '', label: 'Uncategorised' },
    { key: undefined, label: 'Uncategorised' },
];

class DecisionMetadata extends Component<Props & IStateProps, ComponentProps> {
    state: ComponentProps = {
        editing: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.data) return;
    }

    handleEdit = event => {
        const { decision, editDecision } = this.props;
        if (!decision) return;
        editDecision(
            {
                ...config.covertLtdDocToDecision(decision),
                ...decision._decision,
            },
            decision
        );
    };

    addLegalFinding = () => {
        const metadata = _.get(this.props.data, 'metadata');
        const documentNumber = _.get(_.find(metadata, { name: 'External identifier' }), 'val') || '';
        this.props.addNewLegalFinding(documentNumber);
    };

    selectAll = () => {
        const { data } = this.props;
        if (!data) return;
        if (!data.includedLegalFindings) return;
        const ids = data.includedLegalFindings.map(lf => lf.id);
        this.props.selectAllDocs(ids);
    };

    deleteLegalFinding = () => {
        const { docsCheckedIds = [] } = this.props;
        this.props.confirmationDialog(
            'Are you sure you want to delete the selected legal findings.',
            () => this.props.deleteLegalFindings(docsCheckedIds),
            () => null
        );
    };

    render() {
        const { classes, user, data, docsCheckedIds = [], getTranslation, cldPermissions } = this.props;

        let _user = user || {};
        const isAdmin = getCLDUserRole(user.roles) === 'ADMIN';

        if (data === undefined) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            );
        }

        const USER_ACTIONS = [
            { label: 'bookmark', action: () => null },
            {
                label: getTranslation(GENERAL_CLEAR_SELECTION),
                action: () => this.props.clearCheckboxSelection(),
            },
        ];

        const ADMIN_ACTIONS = [{ label: getTranslation(GENERAL_DELETE), action: this.deleteLegalFinding }];

        const SELECTED_DOCUMENT_ACTIONS = [...USER_ACTIONS, ...(isAdmin ? ADMIN_ACTIONS : [])];

        // TODO:
        const noLfOrNotAnalised = !_.get(data, '_decision["Legal Findings?"]');
        // const noLfOrNotAnalised =
        //     _.isEmpty(data.includedLegalFindings) || (!data.isAnalysed && _.isEmpty(data.includedLegalFindings));
        // const noLfOrNotAnalisedText = data.isAnalysed
        //     ? getTranslation(DECISION_DOCUMENT_NO_LEGAL_FINDING_IN_DECISION)
        //     : getTranslation(DECISION_DOCUMENT_DECISION_NOT_ANALYSED);
        const noLfOrNotAnalisedText =
            _.get(data, '_decision["Legal Findings?"]') === false
                ? getTranslation(DECISION_DOCUMENT_NO_LEGAL_FINDING_IN_DECISION)
                : getTranslation(DECISION_DOCUMENT_DECISION_NOT_ANALYSED);

        return (
            <div>
                <div className={classes.root}>
                    <Typography variant='caption' className={classes.title}>
                        {getTranslation(DECISION_DOCUMENT_METADATA_OF_DECISION)}
                    </Typography>
                    {cldPermissions.docEdit ? (
                        <IconButton onClick={this.handleEdit} className={classes.editButton}>
                            {' '}
                            <i className='material-icons'>edit</i>
                        </IconButton>
                    ) : null}
                    <Metadata {...this.props} />
                </div>

                <div className={classes.container}>
                    {noLfOrNotAnalised ? (
                        <Typography variant='h6' className='my-2'>
                            {noLfOrNotAnalisedText}
                        </Typography>
                    ) : (
                        <div>
                            <div className='flex-row space-between align-center'>
                                <Typography variant='h6' className='my-4'>
                                    {getTranslation(DECISION_DOCUMENT_LEGAL_FINDING_CONTAINED_IN_DECISION)}
                                </Typography>
                                <Button className={classes.selectAllBtn} variant='text' onClick={this.selectAll}>
                                    {getTranslation(GENERAL_SELECT_ALL)}
                                </Button>
                            </div>
                            {_.map(data.includedLegalFindings, (finding, i) => (
                                <AttchedLegalFinding {...this.props} key={i} finding={finding} />
                            ))}
                        </div>
                    )}
                </div>

                <SelectedDocumentActions
                    classes={{ actionControlContainer: classes.action }}
                    selectedIds={docsCheckedIds}
                    actions={SELECTED_DOCUMENT_ACTIONS}
                    docType={'CldDoc'}
                    onActionCompleted={this.props.clearCheckboxSelection}
                />
            </div>
        );
    }
}

const Metadata = (props: Props & IStateProps) => {
    const { classes, data, cldFields, getTranslation } = props;

    const resolver = (id: string) => CldFieldService.resolveCldField(cldFields, id);

    if (data === undefined) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    const metadata = data.metadata;

    const appeal = {
        label: _.get(data, '_decision["Applicable Appeal"]'),
        value: _.get(data, '_decision["Appeal Doc ID_Instance"].externalId'),
        url: `${window.location.origin}/decision/${_.get(data, '_decision["Appeal Doc ID_Instance"].slug')}`,
    };

    const majorityDissenting = {
        label: _.get(data, '_decision["majorityDissentingRelationship"]'),
        value: _.get(data, '_decision["majorityDissentingRelationshipDocId_Instance"].externalId'),
        url: `${window.location.origin}/decision/${_.get(
            data,
            '_decision["majorityDissentingRelationshipDocId_Instance"].slug'
        )}`,
    };

    console.log('appeal', appeal, majorityDissenting);

    const DATA = [
        {
            label: getTranslation(DECISION_DOCUMENT_TITLE_OF_DECISION),
            value: _.get(data, '_decision.title') || _.get(data, 'title') || '',
            tooltip: '',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_SOURCE),
            value: resolver(_.get(data, '_decision.documentSourceId')) || _.get(data, 'source') || '',
            tooltip: '',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_SITUATION_CASE),
            value: getSituationCase(data, cldFields),
            tooltip: '',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_DATE),
            value: moment(_.get(data, 'dateCreated') || '').format('DD MMMM YYYY'),
        },
        {
            label: getTranslation(DECISION_DOCUMENT_DOCUMENT_NUMBER),
            value: _.get(data, '_decision.fullDocumentNumber') || _.get(data, 'Document Number') || '',
            tooltip: 'The unique identifier of the decision in the format given by the ICC',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_PHASE_OF_CASE),
            value: resolver(_.get(data, '_decision.phaseOfCaseId')) || _.get(data, 'phaseOfCases[0].title') || '',
            tooltip: 'Phase of the proceedings during which the decision was issued',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_TYPE_OF_DECISION),
            value: resolver(_.get(data, '_decision.typeOfDecisionId')) || '',
            tooltip: 'The type of the decision according to its content and/or source',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_JUDGES),
            value: (_.get(data, 'cldJudges') || []).join(', '),
            tooltip: 'TComposition of the Chamber that issued the resource contained in the CLD',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_NUMBER_OF_PAGES),
            value: _.get(_.find(metadata, { name: 'Number of pages' }), 'val') || '',
            tooltip: '',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_LANGUAGE),
            value: resolver(_.get(data, '_decision.languageId')) || _.get(data, 'languages[0].name') || '',
            tooltip: '',
        },
        {
            label: getTranslation(DECISION_DOCUMENT_PERMANENT_URL),
            value: _.get(data, 'pdfURL') || '',
            tooltip: '',
        },
    ];

    return (
        <div>
            {_.map(DATA, (meta, i) => (
                <Tooltip key={i} title={meta.tooltip ? meta.tooltip : ''}>
                    <div className={classes.metaItem}>
                        <Typography color='primary' style={{ flex: 1, textAlign: 'right' }}>
                            {meta.label}:
                        </Typography>
                        {meta.label === getTranslation(DECISION_DOCUMENT_PERMANENT_URL) ? (
                            <a
                                style={{
                                    color: Theme.Colors.Primary.light,
                                    flex: 3,
                                    marginLeft: 20,
                                }}
                                href={meta.value}
                                target='_blank'
                            >
                                <Typography color='inherit'>{meta.value}</Typography>
                            </a>
                        ) : (
                            <Typography style={{ flex: 3, marginLeft: 20 }}>{meta.value}</Typography>
                        )}
                    </div>
                </Tooltip>
            ))}
            {appeal.value ? (
                <div className={classes.metaItem}>
                    <Typography color='primary' style={{ flex: 1, textAlign: 'right' }}>
                        {appeal.label}
                    </Typography>
                    <a
                        style={{
                            color: Theme.Colors.Primary.light,
                            flex: 3,
                            marginLeft: 20,
                        }}
                        href={appeal.url}
                        target='_blank'
                    >
                        <Typography color='inherit'>{appeal.value}</Typography>
                    </a>
                </div>
            ) : null}
            {majorityDissenting.value ? (
                <div className={classes.metaItem}>
                    <Typography color='primary' style={{ flex: 1, textAlign: 'right' }}>
                        {majorityDissenting.label}
                    </Typography>
                    <a
                        style={{
                            color: Theme.Colors.Primary.light,
                            flex: 3,
                            marginLeft: 20,
                        }}
                        href={majorityDissenting.url}
                        target='_blank'
                    >
                        <Typography color='inherit'>{majorityDissenting.value}</Typography>
                    </a>
                </div>
            ) : null}
        </div>
    );
};

interface AttchedLegalFindingProps {
    finding: TCLDDocument;
}
const AttchedLegalFinding = (props: Props & IStateProps & AttchedLegalFindingProps) => {
    const { classes, finding } = props;
    return (
        <div className={classes.finding}>
            <LegalFindingItem item={finding} />
        </div>
    );
};

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Auth, ['user']),
    docsCheckedIds: _.get(state.CLDDocuments, 'selected'),
    decision: _.get(state.DetailPage, 'currentDocument.document'),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
    cldFields: state.CldFields.cldFields,
});

const mapDispatchToProps = dispatch => ({
    editDecision: (formData, decision) => dispatch(ONewDecision.openNewDecisionDialog(formData, decision)),
    addNewLegalFinding: (documentNumber: string) =>
        dispatch(ONewLegalFinding.openNewLegalFindingDialog(true, 'home', documentNumber)),
    clearCheckboxSelection: () => dispatch(OCLDDocuments.clearSelection()),
    selectAllDocs: (ids: string[]) => dispatch(OCLDDocuments.selectAll(ids)),
    confirmationDialog: (message, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
    deleteLegalFindings: (ids: string[]) => dispatch(OCLDDocuments.deleteLegalFinding(ids)),
});

const STYLES = theme =>
    createStyles({
        root: {
            width: 680,
            borderRadius: 4,
            margin: '50px auto',
            background: 'white',
            boxSizing: 'border-box',
            padding: '20px 40px',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        selectAllBtn: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                color: Theme.Colors.Primary.main,
            },
        },
        container: {
            width: 680,
            margin: '0 auto',
            '& h6': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                    paddingLeft: 8,
                },
            },
            '& button': {},
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        action: {
            right: 0,
            bottom: 200,
            position: 'fixed',
        },
        findingLabel: {
            width: 680,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 16px 18px 32px',
            boxSizing: 'border-box',
            '& p': {
                fontWeight: 500,
            },
            '& .btnText': {
                color: Theme.Colors.Third,
            },
            '& .addBtn': {
                width: 30,
                height: 30,
                minHeight: 0,
                minWidth: 0,
                borderRadius: 15,
                background: Theme.Colors.Third,
                '& i': {
                    position: 'relative',
                    top: -3,
                    color: 'white',
                },
            },
        },
        finding: {
            width: 680,
            borderRadius: 4,
            margin: '0px auto 48px auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            // background: 'white',
            // boxSizing: 'border-box',
            // padding: '20px 40px'
        },

        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            color: 'white',
            background: Theme.Colors.Fourth,
            position: 'relative',
            display: 'inline-block',
            top: -30,
            padding: 1,
            lineHeight: 1,
        },
        editButton: {
            float: 'right',
            top: -30,
            right: -30,
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            border: `1px solid ${Theme.Colors.Third}`,
            '& i': {
                // fontSize: 14,
                color: Theme.Colors.Third,
            },
        },
        label: {
            flex: 2,
            marginRight: 20,
            color: Theme.Colors.Primary.main,
        },
        detail: {
            flex: 4,
        },
        metaItem: {
            position: 'relative',
            left: -22,
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            margin: '5px 0px',
            '& p': {
                fontSize: 12,
                fontWeight: 500,
            },
            '& a': {
                textDecoration: 'none',
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(DecisionMetadata)));
