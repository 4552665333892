import { Models, utilities } from '../../Resources/Model';
import { TDocument } from '../LTDDocuments/@types';
import { TCurrentDocument } from './@types';
import { HOME_URL, ODocuments, setLTDMetadata, DOCUMENT_BASE_URL } from '../LTDDocuments/redux-config';
import { HISTORY } from '../../screens/RootLayout';
import _ from 'lodash';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import { TAppState } from '../../RootReducer/AppReducer';
import { TState } from '../../RootReducer';

export const fetchDocument = (id, filter) =>
    utilities.request({
        url: `ltddocs/${id}`,
        method: 'GET',
        params: {
            filter,
        },
    });

export const fetchSccDocument = (id, filter) =>
    utilities.request({
        url: `ltddocs/${id}`,
        method: 'GET',
        params: {
            filter,
        },
    });

export const fetchCLDDocument = (id, filter) =>
    utilities.request({
        url: `clddocs/${id}`,
        method: 'GET',
        params: { filter },
    });

export const patchDocument = (id, data) =>
    utilities.request({
        url: `ltddocs/${id}`,
        method: 'PATCH',
        data: data,
    });

export const mergeLtdDocs = (id: string, proxySlug: string) =>
    utilities.request({
        url: `ltddocs/${id}/merge`,
        method: 'POST',
        data: {
            proxySlug,
        },
    });

export const unMergeLtdDocs = (id: string, proxySlug: string) =>
    utilities.request({
        url: `ltddocs/${id}/un-merge`,
        method: 'POST',
        data: {
            proxySlug,
        },
    });

export type TDetailPageType = 'legalFinding' | 'decision' | 'ltdDoc' | 'none';

export const getDocumentTypeFromURL = (): TDetailPageType => {
    const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
    const ltdDoc = /\/doc.*/i.test(window.location.pathname);
    const decision = /\/decision.*/i.test(window.location.pathname);
    if (cldDoc) return 'legalFinding';
    else if (ltdDoc) return 'ltdDoc';
    else if (decision) return 'decision';
    else return 'none';
};

export const CLD_INCLUDES = [
    {
        relation: 'ltdDoc',
        scope: {
            include: [
                {
                    relation: 'sourceTypes',
                    scope: {
                        fields: ['id', 'title'],
                    },
                },
                {
                    relation: 'languages',
                    scope: {
                        fields: ['id', 'name'],
                    },
                },
                {
                    relation: 'phaseOfCases',
                    scope: {
                        fields: ['id', 'title'],
                    },
                },
                {
                    relation: 'judicialDocuments',
                    scope: {
                        fields: ['id', 'title'],
                    },
                },
                {
                    relation: 'iccSituations',
                    scope: {
                        fields: ['id', 'title'],
                    },
                },
            ],
        },
    },
];

export const LTD_INCLUDES = [
    {
        relation: 'sourceTypes',
        scope: {
            fields: ['id', 'title'],
        },
    },
    {
        relation: 'languages',
        scope: {
            fields: ['id', 'name'],
        },
    },
    {
        relation: 'relatedDocs',
        scope: {
            fields: ['id', 'title', 'slug'],
        },
    },

    {
        relation: 'subjects',
        // scope: {
        //     fields: ['id', 'name']
        // }
    },
    {
        relation: 'phaseOfCases',
        scope: {
            fields: ['id', 'title'],
        },
    },
    {
        relation: 'keywords',
        scope: {
            fields: ['id', 'title'],
        },
    },
    {
        relation: 'judicialDocuments',
        scope: {
            fields: ['id', 'title'],
        },
    },
    {
        relation: 'iccSituations',
        scope: {
            fields: ['id', 'title'],
        },
    },
    {
        relation: 'node',
        scope: {
            include: [{ relation: 'parent' }],
        },
    },
];

export class DetailPage extends Models {
    constructor() {
        super('DetailPage', {
            SET_CURRENT_DOCUMENT: 'SET_CURRENT_DOCUMENT',
            REMOVE_CURRENT_DOCUMENT: 'REMOVE_CURRENT_DOCUMENT',
            OPEN_DOCUMENT: 'OPEN_DOCUMENT',
            CLOSE_DOCUMENT: 'CLOSE_DOCUMENT',
            SET_LOADING_DOCUMENT_DETAIL: 'SET_LOADING_DOCUMENT_DETAIL',
            UPDATE_DOCUMENT_ASSIGNED_STATUS: 'UPDATE_DOCUMENT_ASSIGNED_STATUS',
            DOCUMENT_PATH_RECIEVED: 'DOCUMENT_PATH_RECIEVED',
            LTD_DOCUMENT_RECORDS_MERGED: 'LTD_DOCUMENT_RECORDS_MERGED',
            LTD_DOCUMENT_RECORDS_UNMERGED: 'LTD_DOCUMENT_RECORDS_UNMERGED',
            UPDATE_LTD_DOCUMENT_DETAIL: 'UPDATE_LTD_DOCUMENT_DETAIL',
        });
    }

    setCurrentDocument = (document: TCurrentDocument) => (dispatch, getState) => {
        dispatch({
            type: this.actions.SET_CURRENT_DOCUMENT,
            data: document,
        });
    };

    updateLtdDocumentDetail = (document: TDocument) => (dispatch, getState) => {
        dispatch({
            type: this.actions.UPDATE_LTD_DOCUMENT_DETAIL,
            data: document,
        });
    };

    mergeLTDRecords = (id: string, purl: string) => async dispatch => {
        try {
            const res = await mergeLtdDocs(id, purl);
            dispatch({
                type: this.actions.LTD_DOCUMENT_RECORDS_MERGED,
                data: res.data,
            });
        } catch (error) {}
    };
    unMergeLTDRecords = (id: string, purl: string) => async dispatch => {
        try {
            const res = await unMergeLtdDocs(id, purl);
            dispatch({
                type: this.actions.LTD_DOCUMENT_RECORDS_UNMERGED,
                data: res.data,
            });
        } catch (error) {}
    };

    removeCurrentDocument = () => dispatch => {
        dispatch({ type: this.actions.REMOVE_CURRENT_DOCUMENT });
    };

    isLTDDocAssigned = docId => async dispatch => {
        const resp = await utilities.request({
            url: `/ltddocs/${docId}/is-assigned`,
        });
        dispatch({
            type: this.actions.UPDATE_DOCUMENT_ASSIGNED_STATUS,
            data: {
                docId,
                isAssigned: resp.data,
            },
        });
    };
    fetchLTDDocPath = (docId, parentId) => async dispatch => {
        if (!parentId) return;
        const pathResp = await ONewLTDDocument.requestDocPath(parentId);
        if (_.isEmpty(pathResp)) return;
        dispatch({
            type: this.actions.DOCUMENT_PATH_RECIEVED,
            data: {
                docId,
                docPath: pathResp,
            },
        });
    };

    openDocument = (
        id: string,
        type: 'LTD' | 'SCC' | 'CLD' = 'LTD',
        includeLegalFinding: boolean = false,
        closeRedirect?: string
    ) => (dispatch, getState) => {
        const { App } = getState();
        dispatch({
            type: this.actions.SET_LOADING_DOCUMENT_DETAIL,
            data: id,
        });
        if (type === 'LTD') {
            fetchDocument(id, {
                include: LTD_INCLUDES,
            }).then(
                res => {
                    const docSlug = _.get(res.data, 'slug');
                    const decision = /\/decision.*/i.test(window.location.pathname);
                    if (decision && _.get(res.data, 'newVersion.slug')) {
                        window.location.href = `/decision/${_.get(res.data, 'newVersion.slug')}`;
                        return;
                    }
                    if (id !== docSlug) {
                        // return HISTORY.push(DOCUMENT_BASE_URL + docSlug)
                        // return dispatch(this.openDocument(docSlug))
                    }
                    if (includeLegalFinding) {
                        // This case is for Decisions.
                        // fetch related Legal findings along with the Decision
                        const decision = res.data;
                        const externalIdentifier = _.get(decision, 'externalId');
                        if (externalIdentifier) {
                            utilities
                                .request({
                                    url: `clddocs`,
                                    params: {
                                        filter: {
                                            where: { 'Document Number': externalIdentifier, deleted: false },
                                            order: 'Finding ASC',
                                            include: [
                                                {
                                                    relation: 'ltdDoc',
                                                    scope: {
                                                        include: LTD_INCLUDES,
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                })
                                .then(res => {
                                    dispatch(
                                        this.setCurrentDocument({
                                            contentType: HOME_URL().name,
                                            document: {
                                                ...decision,
                                                includedLegalFindings: res.data,
                                                metadata: setLTDMetadata(decision, _.get(App, 'appConstants')),
                                            },
                                            documentId: id,
                                            closeRedirect,
                                        })
                                    );
                                });
                        } else {
                            dispatch(
                                this.setCurrentDocument({
                                    contentType: HOME_URL().name,
                                    document: {
                                        ...decision,
                                        metadata: setLTDMetadata(res.data, _.get(App, 'appConstants')),
                                    } as TDocument,
                                    documentId: id,
                                    closeRedirect,
                                })
                            );
                        }
                    } else {
                        dispatch(this.isLTDDocAssigned(_.get(res.data, 'id')));
                        dispatch(this.fetchLTDDocPath(_.get(res.data, 'id'), _.get(res.data, 'node.parentId')));
                        dispatch(
                            this.setCurrentDocument({
                                contentType: HOME_URL().name,
                                document: {
                                    ...res.data,
                                    metadata: setLTDMetadata(res.data, _.get(App, 'appConstants')),
                                } as TDocument,
                                documentId: id,
                                closeRedirect,
                            })
                        );
                    }
                },
                err => {}
            );
        } else if (type === 'SCC') {
            fetchSccDocument(id, {
                include: LTD_INCLUDES,
            }).then(
                res => {
                    const docSlug = _.get(res.data, 'slug');
                    const decision = /\/decision.*/i.test(window.location.pathname);
                    if (decision && _.get(res.data, 'newVersion.slug')) {
                        window.location.href = `/decision/${_.get(res.data, 'newVersion.slug')}`;
                        return;
                    }
                    if (id !== docSlug) {
                        // return HISTORY.push(DOCUMENT_BASE_URL + docSlug)
                        // return dispatch(this.openDocument(docSlug))
                    }
                    if (includeLegalFinding) {
                        // This case is for Decisions.
                        // fetch related Legal findings along with the Decision
                        const decision = res.data;
                        const externalIdentifier = _.get(decision, 'externalId');
                        if (externalIdentifier) {
                            utilities
                                .request({
                                    url: `clddocs`,
                                    params: {
                                        filter: {
                                            where: { 'Document Number': externalIdentifier, deleted: false },
                                            order: 'Finding ASC',
                                            include: [
                                                {
                                                    relation: 'ltdDoc',
                                                    scope: {
                                                        include: LTD_INCLUDES,
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                })
                                .then(res => {
                                    dispatch(
                                        this.setCurrentDocument({
                                            contentType: HOME_URL().name,
                                            document: {
                                                ...decision,
                                                includedLegalFindings: res.data,
                                                metadata: setLTDMetadata(decision, _.get(App, 'appConstants')),
                                            },
                                            documentId: id,
                                            closeRedirect,
                                        })
                                    );
                                });
                        } else {
                            dispatch(
                                this.setCurrentDocument({
                                    contentType: HOME_URL().name,
                                    document: {
                                        ...decision,
                                        metadata: setLTDMetadata(res.data, _.get(App, 'appConstants')),
                                    } as TDocument,
                                    documentId: id,
                                    closeRedirect,
                                })
                            );
                        }
                    } else {
                        dispatch(this.isLTDDocAssigned(_.get(res.data, 'id')));
                        dispatch(this.fetchLTDDocPath(_.get(res.data, 'id'), _.get(res.data, 'node.parentId')));
                        dispatch(
                            this.setCurrentDocument({
                                contentType: HOME_URL().name,
                                document: {
                                    ...res.data,
                                    metadata: setLTDMetadata(res.data, _.get(App, 'appConstants')),
                                } as TDocument,
                                documentId: id,
                                closeRedirect,
                            })
                        );
                    }
                },
                err => {}
            );
        } else {
            fetchCLDDocument(id, {
                include: CLD_INCLUDES,
            }).then(
                res => {
                    dispatch(
                        this.setCurrentDocument({
                            contentType: HOME_URL().name,
                            document: res.data,
                            documentId: id,
                            closeRedirect,
                        })
                    );
                },
                err => {}
            );
        }
        dispatch({ type: this.actions.OPEN_DOCUMENT });
    };

    updateDocument = (id: string, data: object) => dispatch => {
        patchDocument(id, data).then(
            res => {
                dispatch(
                    this.setCurrentDocument({
                        contentType: HOME_URL().name,
                        document: res.data,
                        documentId: id,
                        closeRedirect: HOME_URL().path,
                    })
                );
            },
            err => {}
        );
    };

    closeDocument = (disableRedirect: boolean = false) => (dispatch, getState) => {
        const state: TState = getState();
        dispatch({ type: this.actions.CLOSE_DOCUMENT });
        dispatch({ type: this.actions.REMOVE_CURRENT_DOCUMENT });
        const closeRedirect = _.get(state, 'DetailPage.currentDocument.closeRedirect') || HOME_URL().path;
        if (!disableRedirect) HISTORY.push(closeRedirect);
        // HISTORY.push(HOME_URL().path)
    };
}

export let ODetail = new DetailPage();
