import React, { useState, useEffect } from 'react'
import { createStyles, InputLabel, Theme, withStyles, WithStyles } from '@material-ui/core'
import _ from 'lodash';
import axios from 'axios';
import { TState } from '../../../../RootReducer';
import { OAuth } from '../../../../Features/Authentication/redux-config';
import { connect } from 'react-redux';
import { TActivityLogFilter, ActivityLogFilter } from '../../../../Features/LtdDashboard/Filters/activityLog';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import utilities from '../../../../Resources/Utils';
import SearchWithSuggestion from '../../../../Components/SearchWithSuggestion';

interface IProps extends IStateProps, IDispatchProps, WithStyles<typeof STYLES> { }

interface IStateProps {
    appliedFilters: TActivityLogFilter
    appConstants: Record<string, any>
}
interface IDispatchProps {
    applyFilter: (filter: TActivityLogFilter) => void
}


type SelectOption = {
    name: string
    value: TActivityLogFilter['by']
}


const FILTER_URL = 'ActivityLogs/by'

// const SELECT_OPTIONS: Array<SelectOption> = [
//     { name: 'All', value: 'all' },
//     { name: 'ICCImport Script', value: 'ICCImport Script' },
//     { name: 'STLImport Script', value: 'STLImport Script' },
//     // { name: 'Admin', value: 'user' },
// ]


const ByFilter: React.FC<IProps> = (props) => {
    const { appliedFilters, appConstants, classes } = props;
    const [options, setOptions] = useState<{ name: string, id: string }[]>([]);

    useEffect(() => {
        fetchOptions();
    }, [])

    const fetchOptions = async () => {
        const { data } = await axios.request({ url: FILTER_URL, params: { filter: { fields: ['id', 'name'] } } });
        setOptions(data)
    }

    // const handleChange = (event: React.ChangeEvent<{
    //     name?: string | undefined;
    //     value: unknown;
    // }>, child: React.ReactNode) => {
    //     const filter: TActivityLogFilter = { ...appliedFilters, by: event.target.value as SelectOption['value'] };
    //     props.applyFilter(filter);
    // }


    const STATIC_OPTIONS: Array<string> = ['ICCImport Script', 'STLImport Script']



    const handleSelectSuggestion = ({ value }) => {
        const selectedOption = _.find(options, { name: value });
        const filter: TActivityLogFilter = { ...appliedFilters, by: selectedOption ? 'user' : value, userId: selectedOption ? selectedOption.id : undefined };
        props.applyFilter(filter);
    }


    const handleClear = () => {
        const filter: TActivityLogFilter = { ...appliedFilters, by: 'all', userId: undefined };
        props.applyFilter(filter);
    }

    const handleSuggestions = (val: string) => {
        const _options = options.filter(o => new RegExp(`^${val}`, 'i').test(o.name));
        return [...STATIC_OPTIONS, ..._options.map(o => o.name)];
    }

    return (
        <FormControl variant="filled" fullWidth>
            {/* <InputLabel classes={{ shrink: classes.shrink }} htmlFor={`applied-action-filter`}>By</InputLabel>
            <Select
                value={appliedFilters.by || 'all'}
                onChange={handleChange}
                inputProps={{
                    name: '',
                    id: `applied-action-filter`,
                }}
            >
                {
                    _.map(options, item => (
                        <MenuItem key={`${item.value}-action-type`}>{item.name}</MenuItem>
                    ))
                }

            </Select> */}
            <SearchWithSuggestion
                onClear={handleClear}
                InnerComponentProps={{ InputLabelProps: { classes: { shrink: classes.shrink } } }}
                formKey='name'
                label='Users'
                disableSearch={true}
                onChange={() => { }}
                suggestionUrl={'---'}
                onSelect={handleSelectSuggestion}
                handleSuggestions={handleSuggestions}
            />
        </FormControl>
    )
}

const mapStateToProps = (state: TState): IStateProps => ({
    appliedFilters: _.get(state.LtdDashboard, 'activityLog.filter') || {},
    appConstants: _.get(state.App, 'appConstants')
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    applyFilter: (filter: TActivityLogFilter) => dispatch(ActivityLogFilter.applyFilter(filter)),
})




const STYLES = (theme: Theme) => createStyles({
    shrink: {
        transform: "translate(0px, 18px) scale(0.75) !important",
        fontWeight: theme.typography.fontWeightMedium
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(ByFilter))