import utilities from "../Resources/Utils";


export class TranslationsService {
    static getStaticTranslations() {
        return utilities.request({
            url: 'staticTranslations',
        })
    }

    static patchStaticTranslation(id: string, data: any) {
        return utilities.request({
            url: `staticTranslations/${id}`,
            method: 'PATCH',
            data
        })
    }
}
