import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    DialogTitle,
    Typography,
    Button,
    CircularProgress,
    Theme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import DialogHeader from './DialogHeader';
import { OAuth } from './redux-config';
import { TDialogVariant } from './@types';
import { withRouter, RouteComponentProps } from 'react-router';
import { OApp } from '../../RootReducer/AppReducer';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';
import {
    AUTHENTICATION_FORGOT_PASSWORD,
    AUTHENTICATION_NEW_USER,
    AUTHENTICATION_SIGN_UP_HERE,
    GENERAL_EMAIL,
    GENERAL_ENTER,
    GENERAL_LOGIN,
    GENERAL_PASSWORD,
} from '../Translations/translationKeys.cld';

interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    RouteComponentProps,
    WithTranslationProps { }

interface IDispatchProps {
    openAuthDialog: (variant: TDialogVariant) => void;
    submitLogin: Function;
    closeDialog: Function;
    init: () => void;
}

class Login extends Component<Props> {
    state = {
        loading: false,
        error: '',
        formData: {},
    };

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    submit = () => {
        const regex = /\/reset-password.*/i;
        const shouldRedirectToHome = regex.test(window.location.pathname);

        this.setState({ loading: true });
        this.props.submitLogin(this.state.formData).then(
            res => {
                this.setState({ loading: false, error: '' });
                this.props.closeDialog();
                if (shouldRedirectToHome) {
                    this.props.history.replace('/');
                }
                window.location.reload();
                // this.props.init();
            },
            err => {
                this.setState({
                    loading: false,
                    error: _.get(err, 'response.data.error.message'),
                });
            }
        );
    };

    signup = () => {
        this.props.openAuthDialog('signup');
    };
    forgotPassword = () => {
        this.props.openAuthDialog('forgotPassword');
    };

    render() {
        const { classes, getTranslation } = this.props;
        const { formData, error, loading } = this.state;

        return (
            <div className={classes.root}>
                <DialogHeader
                    title={getTranslation(GENERAL_LOGIN)}
                />
                <div className={classes.formContent}>
                    <Typography className={classes.errorMessage}>
                        {error}
                    </Typography>
                    <ValidatorForm onSubmit={this.submit}>
                        <TextValidator
                            fullWidth
                            label={getTranslation(GENERAL_EMAIL) || 'E-mail'}
                            name='email'
                            validators={['required', 'isEmail']}
                            errorMessages={['Required', 'Incorrect Email']}
                            value={_.get(formData, 'email') || ''}
                            onChange={this.handleChange}
                        />
                        <TextValidator
                            fullWidth
                            label={
                                getTranslation(GENERAL_PASSWORD) || 'Password'
                            }
                            name='password'
                            type='password'
                            validators={['required']}
                            errorMessages={['Required']}
                            value={_.get(formData, 'password') || ''}
                            onChange={this.handleChange}
                        />

                        <Button fullWidth color='primary' type='submit'>
                            {loading ? (
                                <CircularProgress size={32} />
                            ) : (
                                getTranslation(GENERAL_ENTER)
                            )}
                        </Button>
                    </ValidatorForm>

                    <Typography
                        onClick={this.forgotPassword}
                        className={classes.clickable}
                        variant='caption'
                    >
                        {getTranslation(AUTHENTICATION_FORGOT_PASSWORD)}
                    </Typography>
                    <div className={classes.footer}>
                        <Typography variant='caption'>
                            {getTranslation(AUTHENTICATION_NEW_USER)}
                        </Typography>
                        <Typography
                            variant='caption'
                            onClick={this.signup}
                            className={classes.clickable}
                        >
                            {getTranslation(
                                AUTHENTICATION_SIGN_UP_HERE
                            ).toUpperCase()}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    openAuthDialog: (variant: TDialogVariant) =>
        dispatch(OAuth.openAuthDialog(variant)),
    submitLogin: formData => dispatch(OAuth.submitLogin(formData)),
    closeDialog: () => dispatch(OAuth.closeAuthDialog()),
    init: () => dispatch(OApp.init()),
});

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: 500,
            },
            paddingBottom: 30,
        },
        header: {
            height: 44,
            display: 'flex',
            background: theme.palette.primary.main,
            alignItems: 'center',
            paddingLeft: 15,
            justifyContent: 'space-between',
            '& h6': {
                textTransform: 'uppercase',
                color: THEME.Colors.Grayscale.White,
            },
            '& i': {
                color: THEME.Colors.Grayscale.White,
            },
        },
        formContent: {
            padding: '0px 50px',
            '& form': {
                marginTop: 8,
            },
            '& > form > div': {
                margin: '10px 0px',
            },
            '& button': {
                background: theme.palette.primary.main,
                marginTop: 8,
                color: 'white',
                transition: '200ms linear',
                '&:hover': {
                    background: theme.palette.primary.light,
                },
            },
            '& > span': {
                marginTop: 10,
            },
        },
        footer: {
            marginTop: 26,
            display: 'flex',
            justifyContent: 'space-between',
        },
        clickable: {
            display: 'inline-block',
            color: theme.palette.primary.dark,
            cursor: 'pointer',
        },
        errorMessage: {
            color: THEME.Colors.Error,
            marginTop: 10,
        },
    });

export default withTranslations(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Login))
    )
);
