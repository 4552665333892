import { StaticTranslationNode } from '../../Models/Translations/@types';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import TranslationModel from '../../Models/Translations';
import { AppContext } from '../../Contexts/AppContext';

const useTranslationTable = (translationJSON: any) => {
    const { context } = useContext(AppContext);
    const languagesInSystem =
        context === 'cld' ? TranslationModel.defaultLanguages_cld : TranslationModel.defaultLanguages_ltd;

    const [agencyTranslations, setAgencyTranslations] = useState<StaticTranslationNode>(translationJSON);

    const [agencyLanguages, setAgencyLanguages] = useState(
        TranslationModel.getUsedLanguages(agencyTranslations) || ['en']
    );

    useEffect(() => {
        setAgencyTranslations(translationJSON);
    }, [translationJSON]);
    // useEffect(() => {
    //     if (agencyDetails?.id) {
    //         const at = initializingTranslationTree(TranslationJson, agencyDetails.staticTranslations);
    //         setAgencyTranslations(at);
    //         setAgencyLanguages(TranslationModel.getUsedLanguages(at));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [agencyDetails?.id]);

    return {
        agencyTranslations,
        setAgencyTranslations,
        languagesInSystem,
        agencyDetails: undefined,
        agencyLanguages,
        setAgencyLanguages,
    };
};

const initializingTranslationTree = (translationJson: any, type: 'cld' | 'ltd') => {
    /**
     * If there's no agencyTranslation right now in the agency,
     * then the translationJson will become the default translation tree.
     */
    return translationJson[type];

    // let node1 = { ...translationJson };
    // let node2 = { ...agencyTranslation };
    // const keys = _.uniq(Object.keys(node1).concat(Object.keys(node2)));
    // for (let key of keys) {
    //     // If the current key is locale key (en, fr, de etc.)
    //     if (Object.keys(node1).includes(key) && !Object.keys(node2).includes(key))
    //         node2[key] = node1[key];

    //     if (typeof node1[key] === 'object') {
    //         node2[key] = initializingTranslationTree(node1[key] as StaticTranslationNode, node2[key] as StaticTranslationNode)
    //     }
    // }
    // return node2;
};

export default useTranslationTable;
