import React, { useContext } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import { TranslationLocale } from '../../Models/Translations/@types';
import { TState } from '../../RootReducer';
import { TranslationContext } from './TranslationContext';
import useTranslations, { translate } from './useTranslations';

export interface WithTranslationProps {
    getTranslation: (key: string) => string;
    locale: TranslationLocale;
    changeLocale: (locale: TranslationLocale) => void;
}

// A HOC that injects the translations prop
export const withTranslations = (WrappedComponent: React.ComponentType<any>) => {
    const WithTranslation = props => {
        const { context } = useContext(AppContext);
        const { changeLocale, getTranslation, locale } = useTranslations(context);

        return (
            <WrappedComponent {...props} getTranslation={getTranslation} locale={locale} changeLocale={changeLocale} />
        );
    };

    return WithTranslation;
};
