import React from 'react'
import { createStyles, InputLabel, withStyles, WithStyles } from '@material-ui/core'
import _ from 'lodash';
import { TState } from '../../../../RootReducer';
import { OAuth } from '../../../../Features/Authentication/redux-config';
import { connect } from 'react-redux';
import { TActivityLogFilter, ActivityLogFilter } from '../../../../Features/LtdDashboard/Filters/activityLog';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import utilities from '../../../../Resources/Utils';
import { InlineDatePicker } from 'material-ui-pickers';

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

interface IStateProps {
    appliedFilters: TActivityLogFilter
    appConstants: Record<string, any>
}
interface IDispatchProps {
    applyFilter: (filter: TActivityLogFilter) => void
}



const WhenFilter: React.FC<IProps> = (props) => {
    const { appliedFilters, appConstants, classes } = props;




    const handleChange = (type: 'gte' | 'lte') => (date: Date) => {
        const filter: TActivityLogFilter = { ...appliedFilters, [type]: date === null ? null : date.toISOString() };
        props.applyFilter(filter);
    }



    return (
        <FormControl className={classes.formContainer} variant="filled" fullWidth>
            <InlineDatePicker
                keyboard
                clearable
                disableFuture
                // InputProps={{ className: classes.dateInput }}
                // InputLabelProps={{ className: classes.dateInputLabel }}
                name='gte'
                fullWidth
                mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                label="Date from"
                views={["year", "month", "day"]}
                // placeholder={inputConfig.label}
                KeyboardButtonProps={{ style: { padding: 0 } }}
                keyboardIcon={<i className="material-icons">event</i>}
                format='DD-MM-YYYY'
                // invalidDateMessage={<InvalidDateMessage />}
                value={appliedFilters.gte || null}
                onChange={handleChange('gte')}
            />

            <InlineDatePicker
                keyboard
                clearable
                disableFuture
                // InputProps={{ className: classes.dateInput }}
                // InputLabelProps={{ className: classes.dateInputLabel }}
                name='lte'
                fullWidth
                mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                label="Date to"
                views={["year", "month", "day"]}
                // placeholder={inputConfig.label}
                KeyboardButtonProps={{ style: { padding: 0 } }}
                keyboardIcon={<i className="material-icons">event</i>}
                format='DD-MM-YYYY'
                // invalidDateMessage={<InvalidDateMessage />}
                value={appliedFilters.lte || null}
                onChange={handleChange('lte')}
            />
        </FormControl>
    )
}

const mapStateToProps = (state: TState): IStateProps => ({
    appliedFilters: _.get(state.LtdDashboard, 'activityLog.filter') || {},
    appConstants: _.get(state.App, 'appConstants')
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    applyFilter: (filter: TActivityLogFilter) => dispatch(ActivityLogFilter.applyFilter(filter)),
})




const STYLES = () => createStyles({
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(WhenFilter))