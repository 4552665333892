import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, Fab, Dialog, DialogTitle, DialogContent, CircularProgress, Input, Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import THEME from '../../../Resources/Theme';
import { values } from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import _ from 'lodash';
import { TLog } from '../../../Features/LtdDashboard/@types';
import moment from 'moment';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';
import { TUser, TUserRole, ILtdPermissions } from '../../../Features/Authentication/@types';
import { Redirect } from 'react-router-dom';
import utilities from '../../../Resources/Utils';
import { getUserRole, OAuth } from '../../../Features/Authentication/redux-config';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { OApp } from '../../../RootReducer/AppReducer';
import NewUserForm, { USER_FORM_KEYS } from './NewUserForm';
import Axios, { CancelTokenSource } from 'axios';
import Entry from '../Entry';


interface IStateProps {
    pageNumber: number
    users: TUser[]
    user: TUser,
    ltdPermissions: ILtdPermissions
}

interface IDispatchProps {
    fetchUsers: (pageNumber: number, appendResult?: boolean) => void
    changeRole: (userIds, role, oldRole?) => void
    createUser: Function
    updateUser: Function
    showToast: (msg, variant) => void
    searchUsers: (formData: Record<string, any>, cancelToken) => any
}

interface IState {
    dialogOpen: boolean
    formData: Record<string, any>
    loading: boolean
    error: string
    isNewUser: boolean
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class Users extends Component<IProps, IState> {
    state: IState = {
        dialogOpen: false,
        formData: {},
        loading: false,
        error: '',
        isNewUser: true
    }

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    componentDidMount() {
        if (_.isEmpty(this.props.users) || _.size(this.props.users) < 20)
            this.props.fetchUsers(0);


    }

    handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, user: TUser) => {
        const currentRole = getUserRole(user.roles);
        this.props.changeRole([user.id], e.target.value, currentRole)
    }


    handleEdit = (user: TUser) => {
        console.log("edit user", user)
        this.setState({ error: '', formData: user, isNewUser: false })
        this.toggleAddUserDialog();
    }

    handleDelete = (user: TUser) => {
        console.log("delete user", user)
    }

    loadMoreUsers = () => {
        this.props.fetchUsers(this.props.pageNumber + 1);
    }

    handleSubmit = async () => {
        this.setState({ loading: true })
        const { formData } = this.state;
        let formToSubmit: Record<string, any> = {}
        USER_FORM_KEYS.forEach(k => formData[k] ? formToSubmit[k] = formData[k] : null);
        delete formToSubmit.repeatPassword;


        try {
            let res;
            if (this.state.isNewUser)
                res = await this.props.createUser(formToSubmit)
            else if (formData.id)
                res = await this.props.updateUser(formToSubmit, formData.id)
            this.setState({ loading: false, error: '', formData: {} });
            this.toggleAddUserDialog();
        } catch (error) {
            this.setState({ loading: false, error: _.get(error, 'response.data.error.message') });
        }
    }

    handleFormChange = (e: React.ChangeEvent<{ name: string; value: unknown }>) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } })
    }

    addUser = () => {
        this.setState({ error: '', formData: {}, isNewUser: true })
        this.toggleAddUserDialog();
    }

    handleSearchTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!this.props.searchUsers) return;
        const newValue = e.target.value;
        const formData = { ...this.state.formData, term: newValue };

        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue || _.isEmpty(newValue)) {
            if (this.props.fetchUsers)
                this.props.fetchUsers(0, false);
            this.setState({ formData: {} });
            return;
        }
        this.setState({ formData });
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        console.log("searching")

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await this.props.searchUsers(formData, this.ajaxCallHandle.token)
        } catch (error) {
            console.log('AutoSuggest Error', error); throw error
        }
    }



    toggleAddUserDialog = () => this.setState({ dialogOpen: !this.state.dialogOpen });

    render() {
        const { classes, users = [], ltdPermissions } = this.props
        const { dialogOpen = false, formData, loading, isNewUser = true } = this.state;




        const HEADER_CONFIG = [
            { label: 'Name', width: 6, component: (<Entry type="label">Name</Entry>) },
            { label: 'Partner group', width: 6, component: (<Entry type="label">Partner group</Entry>) },
            { label: 'Company', width: 7, component: (<Entry type="label">Company</Entry>) },
            { label: 'Email', width: 8, component: (<Entry type="label">Email</Entry>) },
            { label: '', width: 4, component: (<Entry type="label">&nbsp;</Entry>) },
            { label: 'Role', width: 6, component: (<Entry type="label">Role</Entry>) },
        ]

        const COLUMNS = [
            { key: 'name', type: 'text' },
            { key: 'created', type: 'date' },
            { key: 'company', type: 'text' },
            { key: 'email', type: 'text' },
            {
                key: '', type: 'action', actions: [
                    { icon: 'edit', onClick: this.handleEdit, permissionKey: 'userEdit' },
                    { icon: 'delete', onClick: this.handleDelete, permissionKey: 'userDelete' },
                ]
            },
            { key: 'partner', type: 'select' },
        ]


        const ALL_ROLES: Array<{ label: string; key: TUserRole }> = [
            { label: 'Creator', key: 'CREATOR' },
            { label: 'Supervisor', key: 'SUPERVISOR' },
            { label: 'Admin', key: 'ADMIN' },
            { label: 'User', key: 'USER' },
            { label: 'SCC User', key: 'SCC_USER' },
            { label: 'SCC Admin', key: 'SCC_ADMIN' },
            { label: 'SCC Admin', key: 'SCC_CREATOR' },
            { label: 'SCC Admin', key: 'SCC_SUPERVISOR' },
        ];

        const ROLES = _.filter(ALL_ROLES, role => _.indexOf(ltdPermissions.userChangeRoleOptions, role.key) > -1)



        if (!utilities.isAuthenticated())
            return <Redirect to="/" />

        return (
            <div className={classes.root}>
                <div className={classes.toolbar}>
                    {ltdPermissions.userCreate ? (
                        <Fab color='primary' className={classes.fab} size='small' onClick={this.addUser}>
                            <i className='material-icons' style={{ color: 'white' }}>
                                add
                            </i>
                        </Fab>
                    ) : null}
                    <div>
                        {HEADER_CONFIG.map(config => (
                            <div style={{ width: config.width * 30, minWidth: config.width * 30 }} key={config.label}>
                                {config.component}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.container}>
                    <FormControl fullWidth className={classes.formControl}>
                        {/* <InputLabel color="primary" htmlFor="user-search-1">Seach users</InputLabel> */}
                        <Input
                            autoComplete='off'
                            color='primary'
                            placeholder='Search users'
                            onChange={this.handleSearchTextChange}
                            value={_.get(this.state.formData, 'term') || ''}
                            startAdornment={<Icon>search</Icon>}
                            inputProps={{
                                id: 'user-search-1',
                                name: 'term',
                            }}
                        />
                    </FormControl>
                    {users.map(_user => (
                        <div key={_user.id} className={classes.row}>
                            {COLUMNS.map((config, i) => (
                                <div
                                    key={i}
                                    style={{
                                        width: HEADER_CONFIG[i].width * 30,
                                        minWidth: HEADER_CONFIG[i].width * 30,
                                    }}
                                    className={classes.column}
                                > 
                                    {config.type === 'text' ? <Entry>{_.get(_user, config.key)}</Entry> : null}
                                    {config.type === 'action' && config.actions
                                        ? config.actions.map(action => (
                                              <Button
                                                  disabled={!(ltdPermissions[action.permissionKey] || false)}
                                                  className={classes.actionButton}
                                                  key={action.icon}
                                                  onClick={e => action.onClick(_user)}
                                              >
                                                  <i className='material-icons'>{action.icon}</i>
                                              </Button>
                                          ))
                                        : null}

                                    {config.type === 'select' ? (
                                        <FormControl variant='filled' fullWidth>
                                            {/* <InputLabel htmlFor={`status-${i}-${log.id}`}>Status</InputLabel> */}
                                            <Select
                                                value={getUserRole(_user.roles)}
                                                onChange={e => this.handleChange(e, _user)}
                                                inputProps={{
                                                    name: '',
                                                    id: `status-${i}-${_user.id}`,
                                                }}
                                            >
                                                <MenuItem value=''>
                                                    <em>None</em>
                                                </MenuItem>
                                                {ROLES.some(obj => obj.key === getUserRole(_user.roles))
                                                    ? ROLES.map(role => (
                                                          <MenuItem key={role.key} value={role.key}>
                                                              <Typography color='primary' component='div'>
                                                                  <Entry>{role.label}</Entry>
                                                              </Typography>
                                                          </MenuItem>
                                                      ))
                                                    : [
                                                          ALL_ROLES.find(obj => obj.key === getUserRole(_user.roles)),
                                                          ...ROLES,
                                                      ].map(role => {
                                                          if (role)
                                                              return (
                                                                  <MenuItem key={role.key} value={role.key}>
                                                                      <Typography color='primary' component='div'>
                                                                          <Entry>{role.label}</Entry>
                                                                      </Typography>
                                                                  </MenuItem>
                                                              );
                                                          return null;
                                                      })}
                                                {/* {
                                                                    ROLES.map(role => (
                                                                        <MenuItem key={role.key} value={role.key}>
                                                                            <Typography color="primary" component="div">
                                                                                <Entry>
                                                                                    {role.label}
                                                                                </Entry>
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    ))
                                                                } */}
                                            </Select>
                                        </FormControl>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ))}
                    {users.length > 19 ? <Button onClick={this.loadMoreUsers}>Load more</Button> : null}
                </div>

                <Dialog
                    scroll='body'
                    open={dialogOpen}
                    onClose={this.toggleAddUserDialog}
                    PaperProps={{ className: classes.dialogRoot }}
                >
                    <NewUserForm
                        isEdit={!isNewUser}
                        loading={loading}
                        formData={formData}
                        handleFormChange={this.handleFormChange}
                        handleSubmit={this.handleSubmit}
                        error={this.state.error}
                        toggleAddUserDialog={this.toggleAddUserDialog}
                    />
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state): IStateProps => ({
    pageNumber: _.get(state.LtdDashboard, 'users.pageNumber'),
    users: _.get(state.LtdDashboard, 'users.users'),
    ..._.pick(state.Auth, ['user', 'ltdPermissions'])
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchUsers: (pageNumber, appendResult) => dispatch(OLtdDashboard.fetchUsers(pageNumber, appendResult)),
    changeRole: (userIds, role, oldRole) => dispatch(OLtdDashboard.changeUserRole(userIds, role, oldRole)),
    createUser: (formData) => dispatch(OLtdDashboard.createUser(formData)),
    updateUser: (formData, userId) => dispatch(OLtdDashboard.editUser(formData, userId)),
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant)),
    searchUsers: (formData: Record<string, any>, cancelToken) => dispatch(OLtdDashboard.searchUsers(formData, cancelToken)),
    // clearSelection: () => dispatch(OLtdDashboard.resetSelection())
})


const MAIN_CONTENT_WIDTH = 1110;
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000
    },
    formControl: {
        marginBottom: 4
    },
    toolbar: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        background: THEME.Colors.Grayscale.White,
        boxShadow: `0px 1px 3px ${fade('#000', 0.16)}`,
        '& > div': {
            height: TOOLBAR_HEIGHT,
            width: MAIN_CONTENT_WIDTH,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center'
        }
    },
    container: {
        paddingTop: TOOLBAR_HEIGHT,
        width: MAIN_CONTENT_WIDTH,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column', paddingBottom: 100,
    },
    row: {
        flex: 1,
        display: 'flex',
        borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        borderLeft: `1px dashed ${fade('#000', 0.2)}`,
        '&:first-child': {
            borderTop: `1px dashed ${fade('#000', 0.2)}`,
        }

    },
    column: {
        borderRight: `1px dashed ${fade('#000', 0.2)}`,
        padding: 6,
        boxSizing: 'border-box',
        '&:last-child': {
            background: THEME.Colors.Grayscale.White
        }
    },


    actionButton: {
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        width: 40,
        height: 40
    },
    fab: {
        position: 'absolute',
        top: 6,
        right: 20
    },
    dialogRoot: {
        width: 400,
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Users))