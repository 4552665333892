import Axios, { CancelTokenSource } from 'axios';
import { Models } from '../../Resources/Model';
import { TNode } from '../CLDDocumentTree/@types';
import _ from 'lodash';
import { groupTree } from '../CLDDocumentTree/cld-documentTree-reducer';
import utilities from '../../Resources/Utils';
import { TState } from '../../RootReducer';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import { OApp } from '../../RootReducer/AppReducer';
import { TLog } from '../LtdDashboard/@types';
import KeywordService from '../../Services/Keyword.service';
import UserService from '../../Services/User.service';
import { CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT, TCldDashboardState } from './cldDashboard-reducer';
import { RoleFilter } from './Filters/roles';

class CldDashboard extends Models {
    constructor() {
        super('keywords', {
            // TREE
            CLD_DASHBOARD_TREE_ITEM_UNSELECTED: 'CLD_DASHBOARD_TREE_ITEM_UNSELECTED',
            CLD_DASHBOARD_FETCHED_TREE_NODES: 'CLD_DASHBOARD_FETCHED_TREE_NODES',
            CLD_DASHBOARD_NEW_TREE_FETCHED: 'CLD_DASHBOARD_NEW_TREE_FETCHED',
            CLD_DASHBOARD_RESET_TREE: 'CLD_DASHBOARD_RESET_TREE',
            CLD_DASHBOARD_REMOVED_CHILDREN: 'CLD_DASHBOARD_REMOVED_CHILDREN',
            CLD_DASHBOARD_FETCHED_CHILDREN: 'CLD_DASHBOARD_FETCHED_CHILDREN',
            CLD_DASHBOARD_SET_LOADING: 'CLD_DASHBOARD_SET_LOADING',
            CLD_DASHBOARD_SET_KEYWORD_TEXT: 'CLD_DASHBOARD_SET_KEYWORD_TEXT',
            CLD_DASHBOARD_ADDED_NEW_NODE: 'CLD_DASHBOARD_ADDED_NEW_NODE',
            CLD_DASHBOARD_UPDATED_NODE: 'CLD_DASHBOARD_UPDATED_NODE',
            CLD_DASHBOARD_DELETED_NODE: 'CLD_DASHBOARD_DELETED_NODE',
            //LOGS
            CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED: 'CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED',
            CLD_DASHBOARD_LOG_STATUS_UPDATED: 'CLD_DASHBOARD_LOG_STATUS_UPDATED',
            CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED: 'CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED',
            CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER: 'CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER',
            // ROLES
            CLD_DASHBOARD_ROLES_FETCHED_USERS: 'CLD_DASHBOARD_ROLES_FETCHED_USERS',
            CLD_DASHBOARD_ROLES_SET_SEARCH_TERM: 'CLD_DASHBOARD_ROLES_SET_SEARCH_TERM',
            CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER: 'CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER',
            CLD_DASHBOARD_ROLES_UPDATE: 'CLD_DASHBOARD_ROLES_UPDATE',
            CLD_DASHBOARD_ROLES_SET_FILTER: 'CLD_DASHBOARD_ROLES_SET_FILTER',
        });
    }

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    // -------- ROLES

    fetchUsers = (searchTerm: string = '', filter: Record<string, any> = {}) => async dispatch => {
        dispatch(this.setLoading(true));
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made');
        }
        try {
            this.ajaxCallHandle = Axios.CancelToken.source();
            const { data: searchResponse } = await UserService.search(
                searchTerm,
                {
                    limit: CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT,
                    skip: 0,
                    ...filter,
                },
                { cancelToken: this.ajaxCallHandle.token }
            );

            const data: Partial<TCldDashboardState['roles']> = {
                users: searchResponse.results.map(r => r.hit),
                total: searchResponse.total,
            };
            dispatch({
                type: this.actions.CLD_DASHBOARD_ROLES_FETCHED_USERS,
                data,
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    changeRole = (action: 'add' | 'remove', ids: string[], role: string) => async (
        dispatch,
        getState: () => TState
    ) => {
        dispatch(this.setLoading(true));
        try {
            const {} = await UserService.updateRole(action, ids, role);
            dispatch({
                type: this.actions.CLD_DASHBOARD_ROLES_UPDATE,
                data: {
                    action,
                    ids,
                    role,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    setSearchTerm = (searchTerm: string) => async (dispatch, getState) => {
        const { roles } = getState().CldDashboard;
        const whereFilter = RoleFilter.getWhereFilterFromAppliedFilter(roles.filter);

        dispatch({
            type: this.actions.CLD_DASHBOARD_ROLES_SET_SEARCH_TERM,
            data: searchTerm,
        });
        dispatch(this.fetchUsers(searchTerm, whereFilter));
    };

    changePage = (page: number) => async (dispatch, getState) => {
        const { roles } = getState().CldDashboard;
        const whereFilter = RoleFilter.getWhereFilterFromAppliedFilter(roles.filter);

        dispatch({
            type: this.actions.CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER,
            data: page,
        });
        dispatch(
            this.fetchUsers(roles.searchTerm || '', {
                ...whereFilter,
                skip: page * CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT,
            })
        );
    };

    // -------------

    setKeywordtext = (value: string) => dispatch =>
        dispatch({
            type: this.actions.CLD_DASHBOARD_SET_KEYWORD_TEXT,
            data: value,
        });

    setLoading = (loading: boolean) => dispatch =>
        dispatch({
            type: this.actions.CLD_DASHBOARD_SET_LOADING,
            data: loading,
        });

    treeSearch = (term: string) => async (dispatch, getState) => {
        const newValue = term;
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            if (this.ajaxCallHandle) {
                this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
            }
            dispatch(this.resetTree());
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url: `/keywords/tree-search`,
                params: {
                    // filter: { match_phrase: { keyword_text: term } }
                    filter: {
                        where: {
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            keyword_text: term,
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            keywordGeneratedId: term,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                cancelToken: this.ajaxCallHandle.token,
            });

            const cleanedData: TNode[] = _.filter(res.data.children, child => !_.isEmpty(child));
            const groupedTree = groupTree(cleanedData, []);
            dispatch({
                type: this.actions.CLD_DASHBOARD_NEW_TREE_FETCHED,
                data: groupedTree,
            });
        } catch (error) {
            console.log('Keyword search error', error);
            throw error;
        }
    };

    resetTree = () => dispatch => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_RESET_TREE,
        });
    };

    removeChildren = parentId => (dispatch, getState) => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_REMOVED_CHILDREN,
            data: parentId,
        });
    };

    // fetchBaseLevelNodes = () => (dispatch, getState) => {
    //     const params = {
    //         filter: {
    //             where: { level: 1 }
    //         }
    //     }
    //     return new Promise((resolve, reject) => {
    //         dispatch(this.setLoading(true));
    //         dispatch(this.getItemsList(params)).then(
    //             async res1 => {
    //                 try {
    //                     const res2 = await dispatch(this.getItemsList({ filter: { where: { level: 2 } } }))
    //                     const groupedTree = groupTree(res1.data, res2.data);

    //                     dispatch({
    //                         type: this.actions.CLD_DASHBOARD_FETCHED_TREE_NODES,
    //                         data: groupedTree
    //                     })
    //                     dispatch(this.setLoading(false));
    //                 } catch (error) {
    //                     dispatch(this.setLoading(false));
    //                 }
    //             }
    //         )
    //     })
    // }

    fetchLevelNodes = (level: Number) => {
        const params = {
            filter: {
                where: { level },
            },
        };
        return utilities.request({
            url: '/nodes',
            params,
        });
    };

    fetchChildren = parentId => (dispatch, getState) => {
        const params = {
            filter: { where: { parentId } },
        };
        const { CldDashboard } = getState() as TState;
        let { itemsSelected, idsToSearch } = CldDashboard;
        dispatch(this.setLoading(true));
        return new Promise((resolve, reject) => {
            dispatch(this.getItemsList(params)).then(
                res => {
                    dispatch({
                        type: this.actions.CLD_DASHBOARD_FETCHED_CHILDREN,
                        data: {
                            children: res.data,
                            parentId,
                        },
                    });
                    dispatch(this.setLoading(false));
                    // const childrenIds = _.map(res.data, node => node.id)
                    // console.log("intes", _.size(_.intersection(itemsSelected, childrenIds)))
                    // if (_.size(_.intersection(itemsSelected, childrenIds)) === 0) {

                    //     idsToSearch = _.filter(idsToSearch, i => i.id !== parentId);
                    //     itemsSelected = _.filter(itemsSelected, id => id !== parentId);
                    //     dispatch({
                    //         type: this.actions.CLD_DASHBOARD_TREE_ITEM_UNSELECTED,
                    //         data: {
                    //             itemsSelected,
                    //             idsToSearch
                    //         }
                    //     })
                    // }
                    return resolve(res.data);
                },
                err => {
                    dispatch(this.setLoading(false));
                }
            );
        });
    };

    addNewNode = (node: Partial<TNode>) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.newNode(node);
            dispatch({
                type: this.actions.CLD_DASHBOARD_ADDED_NEW_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword created successfully`, 'success'));
            dispatch(OApp.hideLoader());
            if (data.length) return data[0];
            // return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    updateNode = (id: string, node: Partial<TNode>) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.updateNode(id, node);
            dispatch({
                type: this.actions.CLD_DASHBOARD_UPDATED_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword updated successfully`, 'success'));
            dispatch(OApp.hideLoader());
            return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    deleteNode = (id: string, moveChildrenToParent: boolean, trash: boolean = true) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.deleteNode(id, moveChildrenToParent, trash);
            dispatch({
                type: this.actions.CLD_DASHBOARD_DELETED_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword deleted successfully`, 'success'));
            dispatch(OApp.hideLoader());
            return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    // ACTIVITY LOGS
    fetchLogs = (pageNumber = 0, appendResult = true, whereFilter?: Record<string, any>) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await utilities.request({
                url: 'ActivityLogs/get-logs',
                params: {
                    filter: {
                        for: 'cld',
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        include: [{ relation: 'user' }, { relation: 'subject', scope: ['text'] }],
                        where: {
                            batchId: { exists: false },
                            // subjectType: {
                            //     inq: ['LtdDoc', 'Node']
                            // },
                            ...whereFilter,
                        },
                    },
                },
            });

            dispatch({
                type: this.actions.CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED,
                data: {
                    logs: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    updateLogStatus = (logs: TLog[], status) => async dispatch => {
        let _logsToUpdate = logs.filter(log => {
            if (log.status === status) return false;
            if (log.status !== 'draft' && status === 'draft') return false;
            return true;
        });

        const logIds = _logsToUpdate.map(l => l.id);
        if (logIds.length === 0) return;

        // if (log.status === status)
        //     return;
        // if (log.status !== 'draft' && status === 'draft')
        //     return;
        const LOG_ACTION = {
            published: `accept`,
            // 'published': 'publish',
            review: 'review',
            rejected: 'reject',
        };
        const action = LOG_ACTION[status];
        if (!action) return;
        const resp = await utilities.request({
            url: `activitylogs/${action}`,
            method: 'POST',
            params: {
                where: {
                    id: {
                        inq: logIds,
                    },
                },
            },
        });
        let logUpdate = resp.data;
        // if (_.isArray(logUpdate))
        //     logUpdate = logUpdate[0];
        // if (_.isEmpty(logUpdate))
        //     return;
        // let updateObj = { res: undefined };
        // if (logUpdate.res)
        //     updateObj.res = logUpdate.res;
        dispatch({
            type: this.actions.CLD_DASHBOARD_LOG_STATUS_UPDATED,
            data: {
                logs,
                status,
                // updateObj,
                logUpdates: logUpdate,
            },
        });
        dispatch(OApp.showToast('Status Updated successfully', 'success'));
        console.log('Update status', resp.data);
    };

    updateLogDocPath = (logId, parentIds, updateDocKey) => async dispatch => {
        const pathResp = await ONewLTDDocument.requestDocPath(parentIds);
        if (_.isEmpty(pathResp)) return;
        dispatch({
            type: this.actions.CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED,
            data: {
                logId,
                docPath: pathResp,
                updateDocKey,
            },
        });
        return pathResp;
    };
}

export const CLD_DASHBOARD_SEARCH_LIMIT = 100;
export let OCldDashboard = new CldDashboard();
