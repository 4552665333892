import { Models, utilities } from "../../Resources/Model";
import { TLtdDocFormFieldKey } from "../../Components/BatchEdit/constants";
import { TBatchEditForm } from "../../Components/BatchEdit/useBatchEdit";
import { LtdDocService } from "../../Services/LtdDocs.service";
import { TBatchEditState } from "./@types";
import { TNode } from "../DocumentsTree/@types";
import { OApp } from "../../RootReducer/AppReducer";
import _ from 'lodash';


class BatchEdit extends Models {
    constructor() {
        super('BatchEdit', {
            BATCH_EDIT_DOCUMENTS: 'BATCH_EDIT_DOCUMENTS',
            BATCH_EDIT_FORM_CHANGE: 'BATCH_EDIT_FORM_CHANGE',
            CLOSE_BATCH_EDIT: 'CLOSE_BATCH_EDIT',
            BATCH_EDIT_DOC_UPDATED: 'BATCH_EDIT_DOC_UPDATED',
            BATCH_EDIT_FORM_SAVED: 'BATCH_EDIT_FORM_SAVED',
            BATCH_CREATE_DOCUMENTS: 'BATCH_CREATE_DOCUMENTS',
            MULTI_CREATE_DOCUMENTS: 'MULTI_CREATE_DOCUMENTS',
            BATCH_CREATE_FORM_SAVED: 'BATCH_CREATE_FORM_SAVED',

            BATCH_EDIT_DOCUMENTS_PUBLISHED: 'BATCH_EDIT_DOCUMENTS_PUBLISHED',
            BATCH_EDIT_TOGGLE_DOC_SELECTION: 'BATCH_EDIT_TOGGLE_DOC_SELECTION',
            BATCH_EDIT_CLEAR_SELECTION: 'BATCH_EDIT_CLEAR_SELECTION',
            BATCH_EDIT_SELECT_ALL_DOCUMENTS: 'BATCH_EDIT_SELECT_ALL_DOCUMENTS',
            BATCH_EDIT_SET_LOADING: 'BATCH_EDIT_SET_LOADING',
        });
    }

    setLoading = (loading: boolean) => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_SET_LOADING,
        data: loading
    })


    batchPublishDocuments = (ids: string[]) => async dispatch => {
        dispatch(this.setLoading(true));
        try {
            const res = await LtdDocService.publish(ids);
            dispatch({
                type: this.actions.BATCH_EDIT_DOCUMENTS_PUBLISHED,
                data: ids
            })
            dispatch(OApp.showToast(`${ids.length} Documents(s) published`, 'success'));

        } catch (error) {

        }
        dispatch(this.setLoading(false));
    }

    toggleDocumentSelection = (id: string) => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_TOGGLE_DOC_SELECTION,
        data: id
    })

    clearDocumentSelection = () => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_CLEAR_SELECTION,
    })

    selectAllDocuments = () => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_SELECT_ALL_DOCUMENTS,
    })



    // Batch Create from CSV
    multiCreate = (fields: TLtdDocFormFieldKey[], parentNode: TNode, formData?: TBatchEditForm) => dispatch => {
        dispatch({
            type: this.actions.MULTI_CREATE_DOCUMENTS,
            data: {
                fields, formData, parentNode
            }
        })
    }


    // Batch Create - with number of documents
    batchCreate = (fields: TLtdDocFormFieldKey[], parentNode: TNode, numOfDocuments: number, formData?: TBatchEditForm,) => dispatch => {
        dispatch({
            type: this.actions.BATCH_CREATE_DOCUMENTS,
            data: {
                fields, formData, parentNode, numOfDocuments
            }
        })
    }

    batchCreateWithoutEdit = (fields: TLtdDocFormFieldKey[], parentNode: TNode, numOfDocuments: number, form: TBatchEditForm,) => async dispatch => {
        try {
            const docIds = Object.keys(form);
            const formArray = docIds.map(id => form[id]);
            if (formArray.length === 0 || !numOfDocuments) throw '';
            const { data } = await LtdDocService.batchCreate(_.pick(formArray[0], fields), parentNode.id, numOfDocuments);
            const errorIndices = data.map((d, i) => ({ ...d, index: i })).filter(d => !d.success).map(d => d.index);
            const errorIds = Object.keys(form).filter((id, i) => errorIndices.includes(i));
            const documents = data.map(d => d.result);
            dispatch({
                type: this.actions.BATCH_CREATE_FORM_SAVED,
                data: {
                    documents,
                    errorIds
                }
            })
        } catch (error) {

        }
    }



    // BATCH EDIT
    batchEdit = (docIds: string[], fields: TLtdDocFormFieldKey[]) => dispatch => {
        dispatch({
            type: this.actions.BATCH_EDIT_DOCUMENTS,
            data: {
                docIds,
                fields
            }
        })
    }
    closeEditor = () => dispatch => dispatch({ type: this.actions.CLOSE_BATCH_EDIT });



    handleFormChange = (form: TBatchEditForm) => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_FORM_CHANGE,
        data: form
    })


    docUpdated = (docId: string) => dispatch => dispatch({
        type: this.actions.BATCH_EDIT_DOC_UPDATED,
        data: docId
    })

    submitForm = (form: TBatchEditForm, isNewDocument: boolean, parentNode?: TNode, numOfDocuments?: number) => async (dispatch) => {
        if (isNewDocument) {
            if (!parentNode) return;
            if (numOfDocuments)
                await dispatch(this.submitBatchCreate(form, parentNode, numOfDocuments));
            else
                await dispatch(this.submitMultiCreate(form, parentNode));
        } else {
            await dispatch(this.submitMultiEdit(form));
        }
    }

    submitBatchCreate = (form: TBatchEditForm, parentNode: TNode, numOfDocuments: number) => async dispatch => {
        const docIds = Object.keys(form);
        const formArray = docIds.map(id => form[id]);
        if (formArray.length === 0 || !numOfDocuments) throw '';
        const { data } = await LtdDocService.batchCreate(formArray[0], parentNode.id, numOfDocuments);
        const errorIndices = data.map((d, i) => ({ ...d, index: i })).filter(d => !d.success).map(d => d.index);
        const errorIds = Object.keys(form).filter((id, i) => errorIndices.includes(i));
        const documents = data.map(d => d.result);
        dispatch({
            type: this.actions.BATCH_CREATE_FORM_SAVED,
            data: {
                documents,
                errorIds
            }
        })
    }

    submitMultiCreate = (form: TBatchEditForm, parentNode: TNode) => async dispatch => {
        const docIds = Object.keys(form);
        const formArray = docIds.map(id => form[id]);

        try {
            const { data } = await LtdDocService.multiCreate(formArray, parentNode.id);
            // let data = _d.map((d, i) => i % 2 === 0 ? ({ ...d, success: false }) : d)
            const documents = data.map(d => d.result)
            const errorIndices = data.filter(d => !d.success).map(d => d.index);
            const errorIds = Object.keys(form).filter((id, i) => errorIndices.includes(i));
            if (errorIds.length > 0) {
                setTimeout(() => {
                    dispatch(OApp.showToast(`Error in creating ${errorIds.length} document(s)`, 'error'))
                }, 1000);
            }
            dispatch({
                type: this.actions.BATCH_CREATE_FORM_SAVED,
                data: {
                    documents,
                    errorIds
                }
            })
        } catch (error) {
            throw error;
        }
    }

    submitMultiEdit = (form: TBatchEditForm) => async dispatch => {
        const docIds = Object.keys(form);
        const formArray = docIds.map(id => ({ id, data: form[id] }));

        try {
            const res = await LtdDocService.multiEdit(formArray);
            const documents = res.data.filter(resp => resp.success).map(resp => resp.result);
            const errorCount = res.data.filter(resp => !resp.success).length;
            if (errorCount > 0) {
                setTimeout(() => {
                    dispatch(OApp.showToast(`${errorCount} document(s) failed to update`, 'error'));
                }, 1000);
            }

            dispatch({
                type: this.actions.BATCH_EDIT_FORM_SAVED,
                data: documents
            })
        } catch (error) {
            throw error;
        }
    }
}


export const OBatchEdit = new BatchEdit();