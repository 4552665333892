import { TDocumentState, TSort, TSearchQueryUrlRecord } from './@types';
import { mapParentIdsToValues as MparentIdsToValues, mapvaluesToParentIds as MvaluesToParentIds } from './redux-config';
import _ from 'lodash';

export class SearchQueryModel implements TSearchQueryUrlRecord {
    idsToSearch: string[];
    itemsSelected: string[];
    currentPage: number;
    mapParentIdsToValues: Record<string, string>;
    mapvaluesToParentIds: Record<string, string>;
    formData: TDocumentState['formData'];
    recordPerPage: number;
    searchTerm: string;
    filter: Record<string, any>;
    sort: TSort;

    constructor({
        idsToSearch = [],
        itemsSelected = [],
        currentPage = 0,
        mapParentIdsToValues = MparentIdsToValues,
        mapvaluesToParentIds = MvaluesToParentIds,
        formData = {},
        recordPerPage = 10,
        searchTerm = '',
        filter = {},
        sort = 'Latest',
    }: Partial<TSearchQueryUrlRecord>) {
        this.idsToSearch = idsToSearch;
        this.currentPage = currentPage;
        this.itemsSelected = itemsSelected;
        this.mapParentIdsToValues = mapParentIdsToValues;
        this.mapvaluesToParentIds = mapvaluesToParentIds;
        this.sort = sort;
        this.formData = formData;
        this.recordPerPage = recordPerPage;
        this.searchTerm = searchTerm;
        this.filter = filter;
    }

    // isInitialState
    get isInitialState() {
        if (this.currentPage !== 0) return false;
        if (Object.keys(_.omit(this.filter, ['from', 'limit'])).length !== 0) return false;
        if (Object.keys(this.formData).length !== 0) return false;
        if (this.idsToSearch.length !== 0) return false;
        if (this.searchTerm !== '') return false;
        return true;
    }

    // stringified value
    toString(): string {
        return JSON.stringify({
            idsToSearch: this.idsToSearch,
            currentPage: this.currentPage,
            itemsSelected: this.itemsSelected,
            mapParentIdsToValues: this.mapParentIdsToValues,
            mapvaluesToParentIds: this.mapvaluesToParentIds,
            sort: this.sort,
            formData: this.formData,
            recordPerPage: this.recordPerPage,
            searchTerm: this.searchTerm,
            filter: this.filter,
        });
    }

    // encoded string getter
    get encoded() {
        return btoa(this.toString());
    }

    // Get Record<K, V> of the object
    // not the instance
    get mappedValues(): TSearchQueryUrlRecord {
        return {
            currentPage: this.currentPage,
            filter: this.filter,
            formData: this.formData,
            idsToSearch: this.idsToSearch,
            itemsSelected: this.itemsSelected,
            mapParentIdsToValues: this.mapParentIdsToValues,
            mapvaluesToParentIds: this.mapvaluesToParentIds,
            recordPerPage: this.recordPerPage,
            searchTerm: this.searchTerm,
            sort: this.sort,
        };
    }

    // As js doesn't support named-constructor
    // this is a workaround, to create a new instance from an encoded string
    static fromEncodedString(encodedString: string): SearchQueryModel {
        const {
            filter = {},
            sort = 'Latest',
            recordPerPage = 20,
            searchTerm = '',
            formData = {},
            mapvaluesToParentIds = {},
            mapParentIdsToValues = {},
            itemsSelected = [],
            idsToSearch = [],
            currentPage = 0,
        } = JSON.parse(atob(encodedString));
        return new SearchQueryModel({
            filter,
            searchTerm,
            sort,
            recordPerPage,
            formData,
            mapParentIdsToValues,
            mapvaluesToParentIds,
            idsToSearch,
            itemsSelected,
            currentPage,
        });
    }
}
