import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import EditableTree from '../../../Components/EditableTree';
import _ from 'lodash';
import { TNode } from '../../../Features/DocumentsTree/@types';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { ONewLTDDocument } from '../../../Features/AddNewLTDDocument/redux-config';
import { appendChildren, traverseTree } from '../../../Features/DocumentsTree/documentTree-reducer';
import { OApp } from '../../../RootReducer/AppReducer';
import { ILtdPermissions } from '../../../Features/Authentication/@types';
import ListContainer from './ListContainer';
import { ODocuments } from '../../../Features/LTDDocuments/redux-config';
import { TDocument, TSearchResult } from '../../../Features/LTDDocuments/@types';
import DocumentDetail from './DocumentDetail';
import { ODetail } from '../../../Features/DetailPage/redux-config';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { TLtdDashboardState } from '../../../Features/LtdDashboard/@types';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';
import BatchEditFieldSelectionSidebar from './BatchEditFieldSelectionSidebar';
import { TLtdDocFormFieldKey } from '../../../Components/BatchEdit/constants';
import { OBatchEdit } from '../../../Features/BatchEdit/redux-config';
import { TFile } from '../../../Components/FileUpload/FileInput';
import * as csvUtil from './csvUtils';
import { TBatchEditForm } from '../../../Components/BatchEdit/useBatchEdit';
import { TState } from '../../../RootReducer';
import { BOOLEAN_OPTIONS, SUBJECT } from '../../../Features/AddNewLTDDocument/@types';
import { BatchReview } from '../../../Features/BatchReview/redux-config';


interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

interface IStateProps {
    tree: Array<TNode>,
    ltdPermissions: ILtdPermissions,
    permittedNodes: Array<TNode>,
    clipboard: Record<string, any>
    selectedIds: string[]
    documents: TSearchResult[]
    appconstants: any
    ltdIncludeOptions: any
}

interface IDispatchProps {
    openNode?: (nodeId: string) => void
    closeNode?: (nodeId: string) => void
    onCheck?: (node: TNode) => void
    shiftNode?: (nodeId: string, newIndex: number, newParentId: string) => any
    openDocumentAddDialog?: (nodeId) => void
    createNode?: (parentId, text) => any
    fetchLTDTree?: Function
    deleteNode?: (nodeIds: string[], moveChildrenToParent: boolean) => any,
    hideNode?: (nodeIds: string[]) => any,
    unhideNode?: (nodeIds: string[]) => any,
    editNode?: (nodeId, text) => any
    confirmationDialog?: (message, onConfirm, onCancel) => void
    openSidebar?: (component: JSX.Element) => void
    searchDocuments?: (pageNumber: number, filter?: Record<string, any>) => any
    applyQuery?: (query: TLtdDashboardState['tree']['searchFilter']) => void
    fetchDocumentDetail?: (id: string) => any
    setSidebarTitle?: (title: string) => void,
    resetClipboard?: () => void,
    moveDocument?: (documentId: string, nodeId: string) => any,
    copyDocument?: (documentId: string, nodeId: string) => any
    clearSelection?: () => void
    batchEdit?: (docIds: string[], fields: TLtdDocFormFieldKey[]) => void
    batchReview?: (docIds) => void
    closeSidebar?: () => void
    setSelection?: (ids) => void
    batchCreateFromCsvDocuments?: (fields: TLtdDocFormFieldKey[], node: TNode, formData: Record<string, any>) => void
    batchCreateDocuments?: (fields: TLtdDocFormFieldKey[], node: TNode, numOfDocs: number, formData: Record<string, any>) => void
    batchCreateWithoutEdit?: (fields: TLtdDocFormFieldKey[], node: TNode, numOfDocs: number, formData: Record<string, any>) => void
}

interface IState {
    text: string
    anchorEl: any
    nodeId: string
    type: "new" | "edit"
    confirmationDialogOpen: boolean
}


class EditTree extends Component<IProps> {
    state = {
        text: '',
        anchorEl: null,
        nodeId: '',
        currentNode: undefined,
        currentDocument: undefined,
    }



    componentDidMount() {
        if (this.props.fetchLTDTree) {
            this.props.fetchLTDTree();
        }
    }

    compoenentDidUpdate(prevProps) {
        if (this.props.fetchLTDTree)
            this.props.fetchLTDTree();
    }



    batchEditSelectedDocuments = (docIds: string[]) => (fields: TLtdDocFormFieldKey[]) => {
        this.props.closeSidebar && this.props.closeSidebar();
        this.props.batchEdit && this.props.batchEdit(docIds, fields);
    }


    batchCreateDocuments = (node: TNode) => (fields: TLtdDocFormFieldKey[], numOfDocs: number = 1) => {
        this.props.closeSidebar && this.props.closeSidebar();
        const doc = _.get(this.props, 'clipboard.document');
        this.props.batchCreateDocuments && this.props.batchCreateDocuments(fields, node, numOfDocs, { 'temp-id': _.pick(doc, fields) });
    }

    batchCreateWithoutEditDocuments = (node: TNode) => (fields: TLtdDocFormFieldKey[], numOfDocs: number = 1) => {
        this.props.closeSidebar && this.props.closeSidebar();
        const doc = _.get(this.props, 'clipboard.document');
        this.props.batchCreateWithoutEdit && this.props.batchCreateWithoutEdit(fields, node, numOfDocs, { 'temp-id': _.pick(doc, fields) });
    }

    openBatchEditFieldSelection = () => {
        const { selectedIds } = this.props;
        this.props.openSidebar && this.props.openSidebar(<BatchEditFieldSelectionSidebar onBack={() => this.props.openSidebar!(
            <ListContainer openDocument={this.openDocument} />
        )} onDone={this.batchEditSelectedDocuments(selectedIds)} />)
        this.props.clearSelection && this.props.clearSelection();
    }

    openBatchCreateFieldsSelection = (node: TNode) => {
        this.props.openSidebar && this.props.openSidebar(<BatchEditFieldSelectionSidebar
            batchCreate={true}
            onBack={() => this.props.openSidebar!(<ListContainer openDocument={this.openDocument} />)}
            onDone={this.batchCreateDocuments(node)}
        />)
    }


    onCheck = (node: TNode) => {
        if (this.props.onCheck)
            this.props.onCheck(node);
    }

    onClick = (node: TNode) => {
    }

    onClose = (nodeId: string) => {
        if (this.props.closeNode)
            this.props.closeNode(nodeId)
    }

    onOpen = async (nodeId: string) => {

        if (!this.props.openNode)
            return;
        try {
            return this.props.openNode(nodeId)
        } catch (error) {

        }
    }

    onNodeShift = async (nodeId, newIndex, newParentId,nextPath,nextParent?:TNode) => {
        if (!this.props.shiftNode)
            return;
        const subTree = traverseTree(nextPath.slice(0,nextPath.length-1),this.props.tree);
        const targetNode = subTree[newIndex];
        if(!targetNode)
            return; //TODO implement edge case.
        const targetNodeIndex = _.indexOf(_.get(nextParent,'childrenIds'),targetNode.id);

        try {
            const res = await this.props.shiftNode(nodeId, targetNodeIndex, newParentId);
        } catch (error) {

        }
    }


    addNewDoc = (node: TNode) => {
        if (!this.props.openDocumentAddDialog || _.isEmpty(node))
            return;
        this.props.openDocumentAddDialog(node.id)
    }

    addNewNode = (node: TNode) => {
        this.setState({ nodeId: node.id, text: '' });
        const el = document.getElementById('anchor');
        if (el)
            this.setState({ anchorEl: el, type: 'new' })
    }

    editNode = (node: TNode) => {
        this.setState({ text: node.text, nodeId: node.id });
        const el = document.getElementById('anchor');
        if (el)
            this.setState({ anchorEl: el, type: 'edit' })
    }

    deleteNode = async (node: TNode, moveChildrenToParent: boolean) => {

        return await this.props.deleteNode!([node.id], moveChildrenToParent);
        // if (this.props.deleteNode && this.props.confirmationDialog) {
        //     this.props.confirmationDialog("Delete node along with its children?",
        //         () => this.props.deleteNode!(node.id),
        //         () => null
        //     )
        // }
    }

    hideNode = async (node: TNode) => {
        return await this.props.hideNode!([node.id]);
    }
    handleRestoreNode = async (node: TNode) => {
        return await this.props.unhideNode!([node.id]);
    }

    updateNode = async (nodeId, text) => {
        if (!this.props.editNode)
            return;

        try {
            return await this.props.editNode(nodeId, text);
        } catch (error) {

        }
    }

    createNewNode = async (nodeId, text) => {
        if (!this.props.createNode)
            return;

        try {
            return await this.props.createNode(nodeId, text);
        } catch (error) {


        }
    }


    onNodeClick = (node: TNode) => {
        this.setState({ currentNode: node })
        if (this.props.setSidebarTitle)
            this.props.setSidebarTitle(node.text);

        if (this.props.applyQuery)
            this.props.applyQuery({
                parentId: node.id
            });
        this.openDocumentsInNode(node);
    }


    handleBackButtonClick = () => {
        const { currentNode } = this.state;
        if (this.props.setSidebarTitle && currentNode)
            this.props.setSidebarTitle(_.get(currentNode, 'text') || '')
        if (this.props.openSidebar)
            this.props.openSidebar((
                <ListContainer openDocument={this.openDocument} />
            ))
    }

    openDocument = (document: TDocument) => {
        if (!this.props.openSidebar || !this.props.fetchDocumentDetail)
            return;

        this.setState({ currentDocument: document })
        if (this.props.setSidebarTitle)
            this.props.setSidebarTitle(document.title)
        this.props.fetchDocumentDetail(document.id)
        this.props.openSidebar((
            <DocumentDetail onBack={this.handleBackButtonClick} />
        ))
    }



    openDocumentsInNode = async (node: TNode) => {
        if (!this.props.searchDocuments || !this.props.openSidebar)
            return;

        this.props.searchDocuments(0, {
            where: {
                parentId: node.id
            }
        });
        if (this.props.openSidebar)
            this.props.openSidebar((
                <ListContainer openDocument={this.openDocument} />
            ))

    }

    handleCopyDocument = (documentId, nodeId) => {
        if (this.props.copyDocument)
            return this.props.copyDocument(documentId, nodeId);
    }
    handleMoveDocument = (documentId, nodeId) => {
        if (this.props.moveDocument)
            return this.props.moveDocument(documentId, nodeId);
    }

    handleSelectAllDocuments = () => {
        if (!this.props.setSelection) return;
        const { documents } = this.props;
        const docIds = documents.map(doc => _.get(doc, 'hit.id')).filter(x => !!x);
        this.props.setSelection(docIds)
    }

    handleCsvUpload = async (files: TFile[], node: TNode) => {
        if (!this.props.batchCreateFromCsvDocuments) return;
        if (files.length < 1) return;
        let content = (files[0].base64 || '') as string;
        const { forms, labelKeys } = await csvUtil.buildFormFormCsv(content, {
            ...this.props.appconstants, ...this.props.ltdIncludeOptions,
        });
        const _formToEdit: TBatchEditForm = {};
        forms.forEach((form, i) => {
            _formToEdit[i.toString() + '-temp-id'] = form
        })
        this.props.batchCreateFromCsvDocuments(labelKeys, node, _formToEdit)
    }


    handleBatchReview = () => {
        const { selectedIds = [] } = this.props;
        this.props.batchReview && this.props.batchReview(selectedIds);
        this.props.clearSelection && this.props.clearSelection();
        this.props.closeSidebar && this.props.closeSidebar();
    }


    render() {
        const { classes, tree = [], selectedIds = [], clearSelection = () => { }, ltdPermissions, permittedNodes, clipboard, resetClipboard } = this.props
        const documentSelectionAction = [
            { action: this.handleSelectAllDocuments, label: 'Select All', key: 'select_all' },
            { action: clearSelection, label: 'Reset Selection', key: 'reset' },
            { action: this.openBatchEditFieldSelection, label: 'Batch Edit', permission: 'batchEditDocs' },
            // { action: this.handleBatchReview, label: 'Batch Review' },
        ].filter(action => action.permission ? ltdPermissions[action.permission] : true)





        return (
            <div>
                <div id="anchor" />
                <EditableTree
                    permissions={ltdPermissions}
                    permittedNodes={permittedNodes}
                    onClose={this.onClose}
                    onOpen={this.onOpen}
                    onShiftNode={this.onNodeShift}
                    addNode={this.createNewNode}
                    updateNode={this.updateNode}
                    onCsvUpload={this.handleCsvUpload}
                    onNodeClick={this.onNodeClick}
                    addDoc={this.addNewDoc}
                    deleteNode={this.deleteNode}
                    tempDeleteNode={this.hideNode}
                    restoreNode={this.handleRestoreNode}
                    batchCreate={this.openBatchCreateFieldsSelection}
                    tree={tree}
                    clipboard={clipboard}
                    resetClipboard={resetClipboard}
                    copyDocument={this.handleCopyDocument}
                    moveDocument={this.handleMoveDocument}
                />
                {
                    <SelectedDocumentActions
                        actions={documentSelectionAction}
                        containerClass={classes.actionControlContainer}
                        docType='LtdDoc'
                        variant="ltd"
                        selectedIds={selectedIds}

                    />
                }
            </div>

        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.Auth, ['ltdPermissions']),
    ..._.pick(state.DocumentTree, ['tree', 'editing', 'clipboard']),
    ..._.pick(state.LtdDashboard, ['permittedNodes', 'selectedIds']),
    documents: _.get(state.LtdDashboard, 'tree.documents'),
    appconstants: _.get(state.App, 'appConstants.LtdDoc'),
    ltdIncludeOptions: _.get(state.App, 'ltdIncludeOptions'),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    deleteNode: (nodeIds, moveChildrenToParent) => dispatch(ODocumentTree.nodeDelete(nodeIds, moveChildrenToParent)),
    hideNode: (nodeIds) => dispatch(ODocumentTree.nodeHide(nodeIds)),
    unhideNode: (nodeIds) => dispatch(ODocumentTree.nodeUnHide(nodeIds)),
    editNode: (nodeId, text) => dispatch(ODocumentTree.nodeEdit(nodeId, text)),
    fetchLTDTree: () => dispatch(ODocumentTree.fetchBaseLevelNodes(true)),
    openNode: (parentId) => dispatch(ODocumentTree.fetchChildren(parentId, false, true)),
    closeNode: (parentId) => dispatch(ODocumentTree.removeChildren(parentId)),
    onCheck: (node) => {
        dispatch(ODocumentTree.treeItemSelect(node))
    },
    shiftNode: (nodeId, newIndex, newParentId) => dispatch(ODocumentTree.shiftNode(nodeId, newIndex, newParentId)),
    createNode: (parentId, text) => dispatch(ODocumentTree.nodeCreate(parentId, text)),
    confirmationDialog: (message, onConfirm, onCancel) => dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
    openDocumentAddDialog: (nodeId) => dispatch(ONewLTDDocument.openDocumentSelectionDialog(nodeId)),
    openSidebar: (component) => dispatch(OApp.openSidebar(component)),
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    searchDocuments: (pageNumber, filter) => dispatch(OLtdDashboard.searchDocuments(pageNumber, filter)),
    applyQuery: (query) => dispatch(OLtdDashboard.setFilter(query)),
    fetchDocumentDetail: (id) => dispatch(ODetail.openDocument(id, 'LTD')),
    setSidebarTitle: (title: string) => dispatch(OApp.setSidebarTitle(title)),
    resetClipboard: () => dispatch(ODocumentTree.resetClipboard()),
    moveDocument: (documentId, nodeId) => dispatch(ODocumentTree.moveDocument(documentId, nodeId)),
    copyDocument: (documentId, nodeId) => dispatch(ODocumentTree.copyDocument(documentId, nodeId)),
    clearSelection: () => dispatch(OLtdDashboard.resetSelection()),
    setSelection: (ids) => dispatch(OLtdDashboard.setSelection(ids)),
    batchEdit: (docIds, fields) => dispatch(OBatchEdit.batchEdit(docIds, fields)),
    batchReview: (docIds) => dispatch(BatchReview.batchReview(docIds)),
    batchCreateFromCsvDocuments: (fields, node, formData) => dispatch(OBatchEdit.multiCreate(fields, node, formData)),
    batchCreateDocuments: (fields, node, numOfDocs, formData) => dispatch(OBatchEdit.batchCreate(fields, node, numOfDocs, formData)),
    batchCreateWithoutEdit: (fields, node, numOfDocs, formData) => dispatch(OBatchEdit.batchCreate(fields, node, numOfDocs, formData)),
})

const STYLES = (theme: Theme) => createStyles({
    actionControlContainer: {
        position: 'absolute',
        right: 530,
        top: 94,
        width: 180,
        zIndex: 1000,

        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            right: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(EditTree))