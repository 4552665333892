import React, { Component } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import UserControls from './UserControls';
import _ from 'lodash';
import classNames from 'classnames';
import { TUser } from '../../Features/Authentication/@types';
import { withTranslations, WithTranslationProps } from '../../Features/Translations/withTranslations';
import { GENERAL_HELP } from '../../Features/Translations/translationKeys.cld';

export interface CldMenuProps {}

interface IProps extends WithStyles<typeof STYLES>, CldMenuProps, WithTranslationProps {}

const CldMenu: React.FC<IProps> = props => {
    const { classes, getTranslation } = props;

    return (
        <div className={classes.root}>
            <Link to='/cld-help'>
                {/* MENU */}
                <Button className={classes.menuButton}>{getTranslation(GENERAL_HELP)}</Button>
            </Link>
        </div>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            // margin: '0px 8px'
        },
        menuButton: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 24,
            },
            [theme.breakpoints.up('sm')]: {
                // color: Theme.Colors.Grayscale.White,
                color: theme.palette.common.white,
            },
            textDecoration: 'none',
        },
    });

export default withStyles(STYLES)(withTranslations(CldMenu));
