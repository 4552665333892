import config from "../../Components/NewDecision/config";
import { Models } from "../../Resources/Model";
import utilities from "../../Resources/Utils";
import { OApp } from "../../RootReducer/AppReducer";
import DecisionService from "../../Services/Decision.service";
import { ODetail } from "../DetailPage/redux-config";
import { TDocument } from "../LTDDocuments/@types";



/**
 * REDUX ACTIONS
 */
class NewDecision extends Models {
    constructor() {
        super('NewDecision', {
            OPEN_NEW_DECISION_DIALOG: 'OPEN_NEW_DECISION_DIALOG',
            CLOSE_NEW_DECISION_DIALOG: 'CLOSE_NEW_DECISION_DIALOG',
            SET_FORM_DATA: 'SET_FORM_DATA'
        });
    }


    openNewDecisionDialog = (formData, decision) => (dispatch) => {
        dispatch({
            type: this.actions.OPEN_NEW_DECISION_DIALOG,
            data: { formData, decision }
        })
    }

    closeNewDecisionDialog = () => (dispatch) => {
        dispatch({
            type: this.actions.CLOSE_NEW_DECISION_DIALOG,
        })
    }

    importDecision = (decision: TDocument) => async (dispatch) => {
        // dispatch(this.openNewDecisionDialog(config.covertLtdDocToDecision(decision), decision));
        if (!decision || !decision.id) return;
        dispatch(OApp.showLoader());
        try {
            await DecisionService.migrate(decision.id);
            dispatch(OApp.showToast('Decision imported successfully', 'success'));
        } catch (error) {
            dispatch(OApp.showToast('Decision import failed', 'error'));
        }
        dispatch(OApp.hideLoader());
    }

    setFormData = (formData) => (dispatch) => {
        dispatch({
            type: this.actions.SET_FORM_DATA,
            data: formData
        })
    }

    updateDecisionImport = (id: string, formData: Record<string, any>) => async (dispatch, getState) => {
        try {
            const { data } = await utilities.request({
                url: `/ltdDocs/${id}/update-decision-import`,
                data: formData,
                method: 'PATCH'
            })
            dispatch(ODetail.updateLtdDocumentDetail(data))
            dispatch(OApp.showToast("Decision saved successfully", "success"))
        } catch (error) {
            dispatch(OApp.showToast("Error saving decision", "error"))
        }
    }

}

export const ONewDecision = new NewDecision();
