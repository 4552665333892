import React, { Component, useState } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Tab,
    Tabs,
    Typography,
    Paper,
    Button,
    Menu,
    MenuItem,
    Input,
    Divider,
    Collapse,
    Tooltip,
    Theme,
    IconButton,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TCurrentDocument } from '../../Features/DetailPage/@types';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import classNames from 'classnames';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import { TReadingList } from '../../Features/ReadingList/@types';
import { TState } from '../../RootReducer';
import Bookmark from '../../Components/Bookmark';
import { withRouter, RouteComponentProps } from 'react-router';
import { getDownloadUrl } from '../../Features/CLDDocuments/redux-config';
import CloseButton from '../../Components/Buttons/CloseButton';
import {
    ICldPermissions,
    ILtdPermissions,
} from '../../Features/Authentication/@types';
import { ONewLTDDocument } from '../../Features/AddNewLTDDocument/redux-config';
import { LTD_TRANSLATE_RECORD_OMIT_FIELDS } from '../../Features/AddNewLTDDocument/addNewLegalFinding-reducer';
import { DOCUMENT_BASE_URL, SCC_DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import utilities from '../../Resources/Utils';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';
import { ONewDecision } from '../../Features/AddNewDecision/redux-config';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import {
    METADATA_HEADER_COPYPURL,
    CARD_PANEL_DOWNLOAD,
    CARD_PANEL_LINK_COPIED_TO_CLIPBOARD,
} from '../../Features/Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps {
    currentDocument: TCurrentDocument;
    tabValue: number;
    onMergeRecord: () => void;
    onTabChange: Function;
    onDownload?: Function;
    tabs: Array<string>;
    translateRecord: (parentNodeId, formData) => any;
    importDecision: (doc: TDocument) => void;
}

interface IDispatchProps {
    closeDocument: Function;
    showToast: Function;
    fetchReadingLists: Function;
}

interface IStateProps {
    userId: string;
    permissions: ILtdPermissions;
    cldPermission: ICldPermissions;
    document: TDocument | undefined;
}

const LeftSection = (props: Props) => {
    const { classes, tabValue, tabs, currentDocument } = props;
    const ltdDoc = /\/doc.*/i.test(window.location.pathname);
    const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
    const decision = /\/decision.*/i.test(window.location.pathname);

    return (
        <div className={classes.leftSection}>
            {_.map(tabs, (tab, i) => (
                <Button
                    key={i}
                    className={
                        i === tabValue
                            ? ltdDoc
                                ? classes.activeTab
                                : sccDoc
                                ? classes.sccActiveTab
                                : decision || tabValue === 1
                                ? classes.activeTabDecision
                                : classes.activeTabCld
                            : classes.inactiveTab
                    }
                    onClick={e => props.onTabChange(i)}
                >
                    {tab}
                </Button>
            ))}
            {/* <Button className={classes.inactiveTab} onClick={copyPurl}>COPY PURL</Button> */}
        </div>
    );
};

class _RightSection extends Component<
    Props & IStateProps & RouteComponentProps & WithTranslationProps
> {
    printPage = () => window.print();

    downloadPDF = () => {
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const document: TDocument = _.get(
            this.props,
            'currentDocument.document'
        );

        if (cldDoc) {
            const url = getDownloadUrl(document.id);
            console.log('url  to open', url);
            window.open(url, '_blank');
            return;
        }

        const requireDownloadPermission =
            _.get(document, 'requireDownloadPermission') || false;
        if (requireDownloadPermission) {
            if (this.props.onDownload) {
                this.props.onDownload();
            }
            return;
        }
        const url: string = _.get(document, 'pdfURL');
        window.open(url, '_blank');
    };

    copyPurl = () => {
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        const { currentDocument, showToast, getTranslation } = this.props;
        const slug = _.get(currentDocument, 'document.slug');
        if (!slug) return;
        const pdfUrl = sccDoc ? `${SCC_DOCUMENT_BASE_URL}${slug}/` : `${DOCUMENT_BASE_URL}${slug}/`;
        utilities.copyLink(pdfUrl);
        showToast(getTranslation(CARD_PANEL_LINK_COPIED_TO_CLIPBOARD) || 'Link copied to clipboard', 'success');
    };

    closePage = () => {
        this.props.closeDocument();
        // const { history } = this.props;
        // if (!history)
        //     return;
        // history.goBack();
    };

    importDecision = () => {
        const { document } = this.props;
        if (document) this.props.importDecision(document);
    };

    translateRecord = () => {
        const { currentDocument } = this.props;
        const parentId = _.get(currentDocument, 'document.parentId');
        const formToEdit = _.omit(
            _.get(currentDocument, 'document'),
            LTD_TRANSLATE_RECORD_OMIT_FIELDS
        );
        this.props.translateRecord(parentId, formToEdit);
    };

    render() {
        const {
            classes,
            currentDocument,
            permissions,
            cldPermission,
            getTranslation,
        } = this.props;
        const isCldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const isDecision = /\/decision.*/i.test(window.location.pathname);

        const copyRightAuthorisation =
            (_.get(currentDocument, 'document.copyRightAuthorisation') &&
                !isCldDoc) ||
            false;
        const docId =
            _.get(currentDocument, 'document.id') ||
            _.get(currentDocument, 'documentId');

        const cldUrl = getDownloadUrl(_.get(currentDocument, 'document.id'));

        return (
            <div className={classes.rightSection}>
                <Button
                    color='primary'
                    // variant='contained'
                    onClick={this.copyPurl}
                    className={classes.actionBtn}
                >
                    <Typography style={{ color: 'textPrimary' }}>
                        {getTranslation(METADATA_HEADER_COPYPURL)}
                    </Typography>
                    <i className='material-icons'>file_copy</i>
                </Button>
                {cldPermission.decisionImport ? (
                    <Button
                        onClick={this.importDecision}
                        className={classes.actionBtn}
                    >
                        <Typography color='textPrimary'>
                            Import Decision
                        </Typography>
                        <i className='material-icons'>import_export</i>
                    </Button>
                ) : null}
                {permissions.docCreate ? (
                    <Button
                        onClick={this.translateRecord}
                        className={classes.actionBtn}
                    >
                        <Typography color='textPrimary'>
                            Translate Record
                        </Typography>
                        <i className='material-icons'>translate</i>
                    </Button>
                ) : null}
                {copyRightAuthorisation ? (
                    <Button
                        onClick={this.downloadPDF}
                        className={classes.actionBtn}
                    >
                        <Typography color='textPrimary'>
                            {getTranslation(CARD_PANEL_DOWNLOAD) || 'Download'}
                        </Typography>
                        <i className='material-icons'>save_alt</i>
                    </Button>
                ) : null}
                {isCldDoc ? (
                    <Button
                        onClick={this.downloadPDF}
                        className={classes.actionBtn}
                    >
                        <Typography color='textPrimary'>
                            {getTranslation(CARD_PANEL_DOWNLOAD) || 'Download'}
                        </Typography>
                        <i className='material-icons'>save_alt</i>
                    </Button>
                ) : null}
                <Bookmark
                    textCase='title'
                    classes={{ actionBtn: classes.actionBtn }}
                    type={isDecision || isCldDoc ? 'cld' : 'ltd'}
                    docType={isCldDoc ? 'CldDoc' : 'LtdDoc'}
                    color='textPrimary'
                    id={docId}
                />
                {permissions.docMergeRecord && (
                    <Button
                        onClick={this.props.onMergeRecord}
                        className={classes.actionBtn}
                    >
                        <Typography color='textPrimary'>
                            Merge Records To This
                        </Typography>
                        <i className='material-icons'>merge_type</i>
                        {/* Merge Records To This */}
                    </Button>
                )}
            </div>
        );
    }
}

const RightSection = withRouter(_RightSection);

class Header extends Component<Props & IStateProps & WithTranslationProps> {
    closePage = () => {
        this.props.closeDocument();
    };

    render() {
        const { classes, currentDocument } = this.props;
        const document = _.get(currentDocument, 'document');
        // const { contentType = '' } = currentDocument;

        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);
        const decision = /\/decision.*/i.test(window.location.pathname);

        const title =
            ltdDoc || decision
                ? _.get(document, 'title')
                : _.get(document, 'Title');

        return (
            <>
                <div className={classes.root} style={{ top: 50 }}>
                    <Typography variant='caption' className={classes.title}>
                        {title}
                    </Typography>
                    <CloseButton onClick={this.closePage} />
                    {/* <Button className={classes.closeBtn} onClick={this.closePage}>
                        <i className="material-icons">close</i>
                    </Button> */}
                </div>
                <div className={classes.root} style={{ top: 100 }}>
                    <div className={classes.container}>
                        <Button
                            onClick={e => this.props.closeDocument()}
                            className={classes.backBtn}
                        >
                            <i className='material-icons'>chevron_left</i>
                        </Button>
                        <LeftSection {...this.props} />
                    </div>
                    <RightSection {...this.props} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    userId: _.get(state.Auth, 'user.id'),
    permissions: _.get(state.Auth, 'ltdPermissions'),
    cldPermission: _.get(state.Auth, 'cldPermissions'),
    document: _.get(state.DetailPage, 'currentDocument.document'),
});

const mapDispatchToProps = dispatch => ({
    closeDocument: () => dispatch(ODetail.closeDocument()),
    fetchReadingLists: id => dispatch(OReadingList.fetchReadingLists(id)),
    showToast: (message, variant: TToastVariant) =>
        dispatch(OApp.showToast(message, variant)),
    translateRecord: (parentNodeId, formData) =>
        dispatch(ONewLTDDocument.openPrefilledDialog(parentNodeId, formData)),
    importDecision: (doc: TDocument) =>
        dispatch(ONewDecision.importDecision(doc)),
});

export const LTD_HEADER_HEIGHT = 100;
const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            height: 50,
            display: 'flex',
            width: 'calc(100vw - 360px)',
            zIndex: 1,
            background: 'white',
            alignItems: 'center',
            padding: '0px 14px',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            boxShadow: `3px 1px  3px ${fade(THEME.Colors.Grayscale.Black, 0.3)}`,
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            // maxWidth: 540,
            // flex: 1,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            lineClamp: 2,
            WebkitLineClamp: 2,
            padding: '0px 36px',
            paddingLeft: 10,
            lineHeight: 1.2,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        leftSection: {
            display: 'flex',
            '& button': {
                minWidth: 0,
                whiteSpace: 'nowrap',
            },
        },
        rightSection: {
            display: 'flex',
            alignItems: 'center',
        },
        activeTab: {
            height: 50,
            borderRadius: 0,
            color: theme.palette.common.white,
            background: THEME.Colors.Secondary.main,
        },
        sccActiveTab: {
            height: 50,
            borderRadius: 0,
            color: theme.palette.common.white,
            background: THEME.Colors.Tertiary.main,
        },
        activeTabCld: {
            color: THEME.Colors.Third,
            // minWidth: '125px !important'
        },
        activeTabDecision: {
            color: THEME.Colors.Third,
        },
        inactiveTab: {
            height: 50,
            background: theme.palette.grey.A100,
            borderRadius: 0,
            color: THEME.Colors.TextColor,
        },
        closeBtn: {
            minWidth: 0,
            width: 40,
            border: `1px solid ${THEME.Colors.Third}`,
            borderRadius: 22,
            padding: 2,
            '& i': {
                color: THEME.Colors.Third,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        backBtn: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: 0,
            },
        },
        actionBtn: {
            textTransform: 'none',
            minWidth: 100,
            fontWeight: 400,
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
            },
            '& .material-icons': {
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            '& p': {
                fontWeight: 400,
                fontSize: 14,
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: 0,
            },
        },
        cldAction: {
            '& > a > p': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(Header)));
