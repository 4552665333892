import { PRIVACY_POLICY_ES } from '../../../Resources/constants';

const termUseEs = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="/" >Home&nbsp;»&nbsp;</a><a href="footer/terms-of-use/" >Terms of use</a></p></div><div id="getUrlText"><div "uid","uid","''"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" "uid","uid","''"><i class="glyphicon glyphicon-print"></i></a></div><br "uid","uid","''">
<!--  CONTENT ELEMENT, uid:51/text [begin] -->
    <div id="c51" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Condiciones de uso </h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext">
        El uso de este sitio web supone el acuerdo con los siguientes términos y condiciones:        <br> 
</p>
<p class="bodytext"></p><blockquote>
(a) La Corte Penal Internacional mantiene este sitio web (el "Sitio") como cortesía para quienes pudieran optar por acceder al Sitio ("Usuarios"). La información que aquí se presenta tiene fines exclusivamente informativos. La Corte Penal Internacional otorga a los Usuarios permiso para visitar el Sitio y descargar y copiar la información, documentos, metadatos y materiales (colectivamente, "Materiales") conteidos en el Sitio para su utilización sin ánimo de lucro por el Usuario, sin derecho alguno de reventa o redistribución de ellos ni de compilación o creación de trabajos derivativos a partir de ellos, con sujeción a los términos y condiciones que se relacionan abajo, y también con sujeción a las restricciones más rigurosas  que pudieran ser de aplicación a Materiales específicos contenidos en este Sitio. La Corte no acepta responsabilidad alguna respecto de la exactitud o integridad de los Materiales contenidos en este Sitio. 
<p class="bodytext">
b) Los Usuarios pueden utilizar los Materiales para fines de enseñanza, así como en obras académicas o publicaciones, siempre y cuando se indique el PURL en la <a href="https://www.legal-tools.org" target="_blank">Base de Datos de Herramientas Jurídicas de la CPI</a><a id="_anchor_1" href="typo3/#_msocom_1" name="_msoanchor_1"><u></u></a> del documento de que se trate, o, en el caso de un mayor número de documentos, el URL <a href="https://www.legal-tools.org" target="_blank">https://www.legal-tools.org</a>. En el caso de las conclusiones jurídicas contenidas en la <a href="https://www.legal-tools.org/cld">Base de Datos de Jurisprudencia de la CPI</a>, se indicará el  PURL de las conclusiones jurídicas utilizadas, o, en el caso de un mayor número de conclusiones jurídicas, el  URL <a href="https://www.legal-tools.org/cld" target="_blank">https://www.legal-tools.org/cld</a>.</p>
<p class="bodytext">
(c)
El Centro de investigación y políticas de derecho internacional (Centre for International Law Research and Policy, CILRAP) administra este Sitio para la Corte Penal Internacional. Todo el Material de este Sitio que procede de la Corte Penal Internacional aparece con sujeción a los presentes términos y condiciones.
</p>
<p class="bodytext"> (d)
A no ser que se especifique otra cosa, los hallazgos,  conclusiones o interpretaciones expresados en los Materiales contenidos en este Sitio corresponden a los diversos miembros del personal de la Corte Penal Internacional, consultores o asesores de la Corte Penal Internacional que prepararon el trabajo y no necesariamente representan las opiniones de la Corte Penal Internacional o sus Estados Partes. 
  <br><br></p></blockquote>
<p></p>
<h3>General</h3>
<p class="bodytext">
La Corte Penal Internacional se reserva el derecho exclusivo y unilateral de modificar, 
limitar o suspender cualquier aspecto del Sitio o cualquiera de los Materiales. 
La Corte Penal Internacional no estará obligada en modo alguno a tomar en consideración 
las necesidades de ningún Usuario en este sentido. 
</p>
<p class="bodytext">
La Corte Penal Internacional se reserva el derecho unilateral a denegar sin previo aviso el acceso por cualquier usuario a este Sitio o cualquier parte del mismo. 
</p>
<p class="bodytext">
Ninguna renuncia por la Corte Penal Internacional respecto de cualquier disposición de estos términos y condiciones será vinculante salvo que se manifieste por escrito firmado por su representante debidamente autorizado.  
 </p>
<h3>Notificación de derecho de autor </h3>
<p  class="bodytext">
Copyright © Corte Penal Internacional  2003-2022. Reservados todos los derechos. No se podrá utilizar, reproducir o transmitir ninguno de los materiales contenidos en este sitio web, en su totalidad o en parte, en ninguna forma ni por ningún medio, ya sea electrónico o mecánico, incluso mediante fotocopias, grabaciones o el uso de cualquier sistema de almacenamiento y recuperación de información, salvo lo dispuesto en los términos y condiciones de uso de los sitios web de la Corte Penal Internacional, sin permiso de la Corte otorgado por escrito.  Para solicitar este permiso o información adicional, sírvase ponerse en contacto con el gestor del contenido web en la Corte Penal Internacional, Sección de Documentación e Información Pública, Apartado Postal 19519, 2500 CM La Haya, Países Bajos, o en la dirección electrónica
<a href="mailto:websupport@icc-cpi.int">websupport@icc-cpi.int</a></p>

<p "uid","uid","''" class="bodytext">
Los documentos contenidos en las Herramientas Jurídicas podrían haber sido elaborados por particulares u organizaciones externos y por tanto podrían estar sujetos a derechos de autor. Los asociados externos del Proyecto tienen presentes los derechos de los propietarios del derecho de autor y solamente pondrán estos documentos a disposición del público una vez obtenida la autorización de los propietarios de los derechos de autor correspondientes. Por ello, algunos documentos contenidos en la Base de Datos de Herramientas Jurídicas de la CPI podrían estar incluidos sin un enlace activo, de manera que el texto no esté disponible. El Proyecto invita a los propietarios del derecho de autor a poner en común sus documentos por medio de esta plataforma pública universal. El Proyecto realizará esfuerzos sistemáticos durante los próximos años destinados a la obtención gradual de permisos que hagan posible poner estos documentos a disposición del público general.  
 </p>
<p "uid","uid","''" class="bodytext">
Se han tomado todas las medidas razonables para identificar a los propietarios de los derechos de autor pertinentes y obtener su autorización antes de poner a disposición del público los materiales contenidos en la Base de Datos de Herramientas Jurídicas de la CPI. 
Si usted es el propietario de un derecho de autor, o está autorizado para actuar en nombre de un propietario de derechos de autor, y considera que su derecho de autor u otros derechos conexos han sido vulnerados mediante la inclusión de material en las Herramientas Jurídicas, 
<i>,</i> sírvase ponerse en contacto con nosotros para proporcionarnos la siguiente información:  </p>
<ul type="disc"><li "uid","uid","''"><ins></ins>
identificación de la obra cuyos derechos de autor usted alega haber sido vulnerados; 
</li><li "uid","uid","''">
una descripción exacta del lugar exacto en las Herramientas Jurídicas donde está situado el material objeto de su reclamación;  </li><li "uid","uid","''">your contact details (address, telephone number, and e-mail address); 
 </li><li "uid","uid","''">
 sus datos de contacto (dirección, número de teléfono y dirección electrónica); 
  </li><li "uid","uid","''">
  una declaración en la que exponga que usted considera de buena fe que el uso cuestionado no cuenta con la autorización del propietario de los derechos de autor o de su agente, o con autorización con arreglo a la ley; 
 </li><li "uid","uid","''">
 una declaración en la que exponga que la información que se especifica arriba tal como usted la presenta es correcta y que usted es el propietario de los derechos de autor en cuestión o está autorizado para actuar en nombre de ese propietario; y su firma electrónica o física.   
 </li></ul><p class="bodytext"> 

 La anterior información debe enviarse mediante un mensaje electrónico a 
 <a href="mailto:info@cilrap.org">info@cilrap.org</a>,
 o por correo postal con la inscripción  "Legal Tools copyright claim" (Reclamación de derechos de autor, Herramientas Jurídicas) a la siguiente dirección:  International Criminal Court, Office of the Prosecutor, Legal Advisory Section, P.O. Box 19519, 2500 CM The Hague, the Netherlands.
</p>
<h3>Descargo de responsabilidades</h3>
<p class="bodytext">
Los materiales que se brindan en este Sitio se presentan en la forma en que aparecen, sin ningun tipo de garantia, ni explicita ni implicita, incluso, sin limitación, sin garantías relativas a la comerciabilidad, adaptación a un fin específico o no infracción. En particular, la Corte Penal Internacional no presenta garantía o declaración alguna relativa a la exactitud o integridad de cualquiera de esos Materiales. Periódicamente, la Corte Penal Internacional incorpora modificaciones, mejoras o actualizaciones a los Materiales de este Sitio sin efectuar una notificación al respecto. Bajo ninguna circunstancia será responsable la Corte Penal Internacional de cualquier pérdida, daño, responsabilidad o gasto que se alegue haya resultado del uso de este Sitio, incluso, entre otras cosas, cualquier falta, error, omisión, interrupción o retraso conexos. El Usuario hace uso  de este Sitio a su exclusivo riesgo. Bajo ninguna circunstancia, incluida la negligencia, se harán responsables la Corte Penal Internacional o sus afiliados de cualquier daño directo, indirecto, incidental, especial o consecuente, incluso si se hubiera notificado a la Corte Penal Internacional de la posibilidad de esos daños. 
</p>
<p class="bodytext">
Específicamente, el Usuario reconoce y conviene en que la Corte Penal Internacional no es responsable de ninguna conducta de ningún Usuario.
</p>
<p class="bodytext">
Este sitio puede contener asesoramientos, opiniones o declaraciones de varios proveedores de información. La Corte Penal Internacional no garantiza ni respalda la exactitud o fiabilidad de ningún asesoramiento, opinión, declaración u otra información proporcionada por cualquier proveedor de información, Usuario de este Sitio u otra persona o entidad.  El uso de cualquier tal asesoramiento, opinión, declaración u otra información por el Usuario también será por su cuenta y riesgo. Ni la Corte Penal Internacional ni sus afiliados, ni cualquiera de sus respectivos agentes, empleados, proveedores de información o proveedores de contenidos serán responsables ante ningún Usuario u otra persona respecto de cualquier imprecisión, error, omisión, interrupción, eliminación, defecto, modificación de o utiización de cualquiera de los contenidos, ni respecto de su oportunidad o integridad, ni serán responsables de cualquier fallo en la ejecución, virus informático o fallo en la línea de comunicación, con independencia de sus causas, ni de cualquier daño al que pudieran dar lugar. 
</p>

<p class="bodytext">
Como condición para el uso de este Sitio, el Usuario acuerda proteger a la Corte Penal Internacional y sus afiliados de todas y cualesquiera acciones, reclamaciones, pérdidas, daños, responsabilidades y gastos (incluidos los honorarios razonables de letrados) dimanantes del uso por el Usuario de este Sitio, incluso, sin limitación, cualquier reclamación que alegue hechos que, de ser verdaderos, constituirían una vulneración por el Usuario de estos términos y condiciones. Si el Usuario no está satisfecho con cualquier Material contenido en este Sitio o con cualquiera de sus términos y condiciones de uso, su único y exclusivo recurso consiste en cesar el uso del Sitio.  
</p>
<p class="bodytext">
Este Sitio puede contener enlaces o referencias a sitios web de terceros. Los sitios enlazados no están bajo el control de la Corte Penal Internacional, y la Corte Penal Internacional no es responsable de los contenidos de ningún sitio enlazado ni de ningún enlace contenido en un sitio enlazado. La Corte Penal Internacional suministra estos enlaces solamente para fines de conveniencia, y la inclusión de un enlace o referencia no implica que la Corte Penal Internacional respalde el sitio enlazado.
</p>
<p class="bodytext">
En el supuesto de que este Sitio contuviera boletines de anuncios, salas de chat, accesos a listas de correos u otros recursos para mensajes o comunicaciones (colectivamente, "Foros"), el Usuario conviene en utilizar los Foros únicamente para enviar o recibir mensajes o  materiales adecuados y que guarden relación con el Foro específico. A modo de ejemplo, pero sin limitarse a lo que sigue, el Usuario conviene en que, al utilizar un Foro, no realizará ninguna de las acciones siguientes: 
</p>
<p class="bodytext"></p><blockquote>
a) Calumniar,  insultar, ofender, acosar, amenazar o vulnerar de otro modo los derechos legales (en particular los derechos relacionados con la intimidad o la publicidad) de terceros; 
     <br><br> 
     b) Publicar, difundir, distribuir o diseminar ningún material o información difamatoria, ofensiva, obscena, indecente o ilegal;
      <br><br> 
      (c) 
      Cargar o adjuntar archivos que contengan programas informáticos u otros materiales protegidos por la legislación de la propiedad intelectual (o por derechos de intimidad o publicidad) a no ser que los correspondientes derechos sean propiedad del Usuario o estén bajo su control o que este haya recibido todos los consentimientos correspondientes según sean requeridos por la ley; 
        <br><br> 
        (d)Cargar o adjuntar archivos que contengan viruses, archivos corruptos u otros programas similares que puedan perjudicar el funcionamiento de la computadora de un tercero;
          <br><br> 
          (e)
          Eliminar cualquier atribución del autor, notificación legal o designaciones o  calificaciones del propietario de cualquier archivo que se haya cargado.
             <br><br>
        (f) Falsificar el origen o la fuente de los programas informáticos u otro material contenido en un archivo que se cargue; 
          <br><br>
           (g)
           Anunciar u ofrecer a la venta cualquier bien o servicio, o realizar o transmitir encuestas, concursos o cartas en cadena, o descargar cualquier archivo cargado por otro usuario de un Foro que el Usuario sabe, o deberia razonablemente saber, no se puede distribuir legalmente por ese medio.
              </blockquote>
<p></p>
<p class="bodytext">
El Usuario reconoce que todos los Foros o grupos de debate constituyen comunicaciones públicas, no privadas. Asimismo, el Usuario reconoce que las conversaciones, notificaciones, conferencias, correos electrónicos u otras comunicaciones de otros Usuarios no están respaldadas por la Corte Penal Internacional, y que no se considerará que esas comunicaciones han sido revisadas, evauadas o aprobadas por la Corte Penal Internacional. La Corte Penal Internacional se reserva el derecho a eliminar, por cualquier motivo y sin previo aviso, cualquier contenido de los Foros recibido de los Usuarios, incluso, sin limitarse a los mismos, los correos electrónicos y las notificaciones en los boletines de anuncios.  
     <br><br>
</p>
<h3>Preservación de inmunidades </h3>
<p class="bodytext">
Ninguna de estas condiciones constituye o puede ser considerada una limitación o una renuncia a los privilegios e inmunidades de la Corte Penal Internacional, que se reservan de manera específica. 
  <br><br>
</p>
<h3>Política de privacidad  </h3>
<p class="bodytext">${PRIVACY_POLICY_ES}</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:51/text [end] -->
    </div>`;

export default termUseEs;
