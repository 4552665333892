const usePurlEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="purl/" >PURL&nbsp;»&nbsp;</a><a href="purl/how-to-use-the-purls-in-the-icc-legal-tools-database/" >How to use the PURLs in the ICC Legal Tools Database?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:248/text [begin] -->
    <div id="c248" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">How to use the PURLs in the ICC Legal Tools Database?</h1></div>
    <!--  Header: [end] -->			
    <!--  Text: [begin] -->
        <p class="bodytext">More than 162,000 legal sources in international criminal law are freely available in the ICC Legal Tools Database (‘LTD’). They include all public ICC documents (decisions, filings and transcripts in the Court’s cases) as well as decisions and documents of other international tribunals, human rights bodies and national courts, the entire preparatory works of ICC law, national legislation, and academic publications in international criminal law.
</p>
<p class="bodytext">Each document in the LTD is assigned a permanent Internet address (a ‘Persistent URL’, known as ‘PURL’) which will not be changed. When you make the PURL of a document a hyperlink in your text, you enable readers to access the cited document directly. Making such hyperlinks is easy and requires less than 30 seconds. The public benefit is significant in that you give readers access to many more legal sources than the one you may be working on yourself. This is therefore a practise that can transform the dissemination of international criminal law. 
</p>
<p class="bodytext"><i><b>How do I find the PURL of the relevant document?</b></i>
</p>
<p class="bodytext">You can find any document either by using the search engine on the top-left menu or by browsing through the folders of the tree structure on the bottom-left menu of the LTD. Regardless of how you chose to navigate through the LTD, you can copy the PURL of the desired document by clicking on the “COPY PURL” button on its document card on the result page. Alternatively, click on the link of the desired document. This will lead you to the metadata page of the document concerned which informs you about the title, date created, source, composition of chamber etc. The first line on the metadata page provides you with the PURL address formula. It always ends with a slash (for example, <a href="https://www.legal-tools.org/doc/ea422e/" target="_blank">http://www.legal-tools.org/doc/ea422e/</a>). Copy the PURL address and paste it into your document. You should only use the PURL reference available on the metadata page of the document concerned or the one obtained by clicking the “COPY PURL” button. You should not copy the link to the metadata page from the browser address bar, nor should you click on the button “Download” and copy the link of the target document from the browser address bar. 
</p>
<p class="bodytext"><i><b>How do I produce the hyperlink in my draft?</b></i>
</p>
<p class="bodytext">To integrate the PURL as a hyperlink into the draft text you are preparing, please follow these three steps:<br>1.	Mark the text element in your draft that you wish to hyperlink. <br>2. Hit “Control” + “K”. A new small window will pop up.<br>3.	 Insert the PURL in the address line at the bottom of the small window by hitting “Control” + “V”. Then hit “Ok”.
</p>
<p class="bodytext"><i><b>How do I remove a hyperlink?</b></i>
</p>
<p class="bodytext">To remove the hyperlink, please mark again the text element that you wish to de-link from the PURL. Hit “Control” + “K” after which the small window will pop up again. You will see on that window that the hyperlink is mentioned in the address line at the bottom of the window. On the right hand side you will see the button “Remove link”. Click on it and the hyperlink is removed. 
</p>
<p class="bodytext"><i><b>What do I do in case a document is not available in the ICC Legal Tools Database?</b></i>
</p>
<p class="bodytext">In case you do not find a particular document, please write an email to Mr. Devasheesh Bais (Deputy Coordinator, ICC Legal Tools Project) at <a href="mailto:bais@cilrap.org">bais@cilrap.org</a>&nbsp;(please always copy <a href="mailto:info@cilrap.org">info@cilrap.org</a>).</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:248/text [end] -->
    </div>`;

export default usePurlEn;
