import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import { OAuth } from '../../Features/Authentication/redux-config';
import THEME from '../../Resources/Theme';
import { OApp } from '../../RootReducer/AppReducer';


interface IStateProps { }

interface IDispatchProps {
    showToast: (msg, variant) => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps<{ token: string }> { }

class ResetPassword extends Component<IProps, IState> {
    state = {
        error: '',
        loading: false,
        successMessage: '',
        user: {
            id: ''
        },
        formData: {
            password: ''
        }
    }
    componentDidMount() {
        this.setState({ loading: true })
        OAuth.requestAppUser(this.props.match.params.token).then(
            user => {
                console.log("user", user)
                this.setState({ loading: false, user })
            },
        );

        this.setState({ loading: false })
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.password) {
                return false;
            }
            return true;
        });
    }

    handleChange = (e) => this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } })

    submit = async () => {
        const { user, formData } = this.state;
        if (_.isEmpty(user.id) || _.isEmpty(formData.password)) {
            this.props.showToast('Something went wrong!', 'error');
            return;
        }

        try {
            this.setState({ loading: true, successMessage: '', error: '' })
            const res = await OAuth.updateUser(user.id, _.omit(formData, 'repeatPassword'));
            console.log("updated", res);
            this.setState({ loading: false, successMessage: 'Password changed successfully. Please log in with your new password' })

        } catch (error) {
            this.setState({ loading: false, successMessage: '', error: error })

        }
    }

    render() {
        const { classes } = this.props;
        const { formData, error, loading, successMessage } = this.state;


        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <Typography className={classes.errorMessage}>{error}</Typography>
                    <Typography className={classes.successMessage}>{successMessage}</Typography>

                    <ValidatorForm onSubmit={this.submit}>
                        <Typography variant="headline" color="primary">Change password</Typography>
                        <TextValidator
                            fullWidth
                            label='New password'
                            name="password"
                            type="password"
                            validators={['required']}
                            errorMessages={['Required']}
                            value={_.get(formData, 'password') || ''}
                            onChange={this.handleChange}
                        />
                        <TextValidator
                            fullWidth
                            label='Repeat Password'
                            type="password"
                            name="repeatPassword"
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={['Required', 'Password does not match']}
                            value={_.get(formData, 'repeatPassword') || ''}
                            onChange={this.handleChange}
                        />
                        <Button variant="contained" fullWidth color="secondary" type="submit" style={{ color: 'white' }}>
                            {loading ? <CircularProgress size={32} /> : 'Change password'}
                        </Button>
                    </ValidatorForm>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    showToast: (msg, variant) => dispatch(OApp.showToast(msg, variant))
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        height: `calc(100vh - 50px)`,
        overflow: 'auto',

    },
    container: {
        margin: '80px auto',
        width: 440,
        background: 'white',
        borderRadius: 8,
        padding: '20px 40px',
        '&  > form > button': {
            margin: '16px 0px'
        },
        '& > form > *': {
            margin: '6px 0px',
        }
    },
    errorMessage: {
        color: THEME.Colors.Error,
        marginTop: 10
    },
    successMessage: {
        color: THEME.Colors.Success,
        marginTop: 10
    }
})

export default withRouter(withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));