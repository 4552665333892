import { OCldDashboard } from "../redux-config"
import moment from 'moment';
import { debug } from "console";
import { actionFilterActions } from "../../../screens/CLDDashboard/Log/Filter/ActionFilter";



export interface TActivityLogFilter {
    action?: string
    gte?: string
    lte?: string
    type?: string
    node?: string
    by?: string
    userId?: string
    status?: string
}



export const DEFAULT_ACTIVITY_LOG_FILTER: TActivityLogFilter = {
    action: 'all',
}


const DATE_FORMAT = 'YYYY-MM-DD'

const FILTER_KEYS: (keyof TActivityLogFilter)[] = ['action', 'by', 'node', 'status', 'type', 'gte', 'lte', 'userId'];
const FILTER_TYPE_MAPPING = {
    Folder: 'Node',
    Document: 'LtdDoc'
}
export const ActivityLogFilter = {

    applyFilter: (filter: TActivityLogFilter) => async dispatch => {
        dispatch(ActivityLogFilter.setFilter(filter))
        const whereFilter = ActivityLogFilter.getWhereFilterFromAppliedFilter(filter);
        dispatch(OCldDashboard.fetchLogs(0, false, whereFilter));
    },

    setFilter: (filter: TActivityLogFilter) => async dispatch => {
        dispatch({
            type: OCldDashboard.actions.CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER,
            data: filter
        })
    },

    resetFilter: () => async dispatch => {
        dispatch({
            type: OCldDashboard.actions.CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER,
            data: { ...DEFAULT_ACTIVITY_LOG_FILTER }
        })
    },

    getWhereFilterFromAppliedFilter: (filter: TActivityLogFilter) => {
        let whereFilter: Record<string, any> = {};
        filter = { ...DEFAULT_ACTIVITY_LOG_FILTER, ...filter };
        FILTER_KEYS.forEach(k => {
            if (filter[k] && filter[k] === 'all') {
                // if (k === 'action')
                //     whereFilter.action = { inq: actionFilterActions.map(a => a.value) }
                delete filter[k];
            }
        })


        if (filter.action)
            whereFilter.action = { inq: [filter.action] }
        if (filter.by)
            whereFilter.by = filter.by
        if (filter.userId)
            whereFilter.userId = filter.userId
        if (filter.status)
            whereFilter.status = filter.status
        if (filter.type)
            whereFilter.subjectType = filter.type
        // whereFilter.subjectType = FILTER_TYPE_MAPPING[filter.type]

        if (filter.gte && filter.lte)
            whereFilter.created = { between: [moment(filter.gte).format(DATE_FORMAT), moment(filter.lte).add(1, 'd').format(DATE_FORMAT)] };
        else {
            if (filter.lte)
                whereFilter.created = { lte: moment(filter.lte).format(DATE_FORMAT) }
            if (filter.gte)
                whereFilter.created = { gte: moment(filter.gte).format(DATE_FORMAT) }
        }
        return whereFilter
    },

    getCSVWhereFilterFromAppliedFilter: (filter: TActivityLogFilter) => {
        let whereFilter: Record<string, any> = {};
        FILTER_KEYS.forEach(k => {
            if (filter[k] && filter[k] === 'all') {
                // if (k === 'action')
                //     whereFilter.action = { inq: actionFilterActions.map(a => a.value) }
                delete filter[k];
            }
        })

        if (filter.action)
            whereFilter.action = { inq: [filter.action] }
        if (filter.by)
            whereFilter.by = filter.by
        if (filter.userId)
            whereFilter.userId = filter.userId
        if (filter.status)
            whereFilter.status = filter.status
        if (filter.type)
            whereFilter.subjectType = filter.type

        if (filter.gte && filter.lte)
            whereFilter.created = { '$gte': moment(filter.gte).format(DATE_FORMAT), '$lte': moment(filter.lte).add(1, 'd').format(DATE_FORMAT) };
        else {
            if (filter.lte)
                whereFilter.created = { '$lte': moment(filter.lte).format(DATE_FORMAT) }
            if (filter.gte)
                whereFilter.created = { '$gte': moment(filter.gte).format(DATE_FORMAT) }
        }
        // IF the filter is empty; restict csv  to only 1 month
        if (Object.keys(filter).length === 0) {
            whereFilter.created = {
                '$gte': moment().subtract(1, 'M').toISOString()
            }
        }


        whereFilter.batchId = { '$exists': false };


        return whereFilter
    },
}