import { TDocument } from '../../Features/LTDDocuments/@types';
import get from 'lodash/get';
import { TDecisionData } from '../../Features/AddNewDecision/@types';

export default {
    fields: [
        'referenceNumber',
        'fullDocumentNumber',
        'interlocutoryAppeal',
        'name',
        'dateOfDocument',
        'title',
        'fileSize',
        'id',
        'uri',
        'isLatest',
        'itemType',
        'path',
        'url',
    ],

    ltdToDecisionKeyMapping: {
        documentSourceEn: 'source',
        name: 'documentName',
        title: 'title',
        pageNumber: 'numberOfPages',
        fullDocumentNumber: 'externalId',
        languageEn: 'languages[0].name',
        phaseOfCaseEn: 'phaseOfCases[0].title',
        confidentiality: 'confidentiality',
    },

    formConfig: {
        title: {
            label: 'Title of decision',
            type: 'multiline',
            transformer: value => value,
            key: 'title',
        },
        fullDocumentNumber: {
            label: 'Document number',
            type: 'text',
            key: 'fullDocumentNumber',
            transformer: value => value,
        },
        hasLegalFinding: {
            label: 'Legal Findings?',
            type: 'component',
            key: 'Legal Findings?',
            transformer: value => value,
        },
        documentTypeEn: {
            label: 'Document type',
            type: 'text',
            key: 'documentTypeEn',
            transformer: value => value,
        },
        typeOfHearingEn: {
            label: 'Type of hearing',
            type: 'text',
            key: 'typeOfHearingEn',
            transformer: value => value,
        },
        pageNumber: {
            label: 'Page number',
            type: 'number',
            key: 'pageNumber',
            transformer: value => {
                try {
                    return Number(value);
                } catch (error) {
                    return value;
                }
            },
        },
        fileSize: {
            label: 'File size',
            type: 'number',
            key: 'fileSize',
            transformer: value => {
                try {
                    return Number(value);
                } catch (error) {
                    return value;
                }
            },
        },
        id: {
            label: 'ID',
            type: 'text',
            key: 'id',
            transformer: value => value,
        },
        uri: {
            label: 'URI',
            type: 'text',
            key: 'uri',
            transformer: value => value,
        },
        // isLatest: {
        //     label: 'Is latest',
        //     type: 'text',
        //     key: 'isLatest',
        //     transformer: value => value,
        // },
        // itemType: {
        //     label: 'Item type',
        //     type: 'text',
        //     key: 'itemType',
        //     transformer: value => value,
        // },
        path: {
            label: 'Path',
            type: 'select',
            transformer: value => value,
            key: 'path',
            options: ['CourtRecords', 'RelatedRecords'],
        },
        confidentiality: {
            label: 'Confidentiality',
            type: 'select',
            transformer: value => value,
            key: 'confidentiality',
            options: ['public', 'confidential'],
        },
    },

    covertLtdDocToDecision(doc: TDocument) {
        const keys = Object.keys(this.ltdToDecisionKeyMapping);
        const decision: TDecisionData = {};
        keys.forEach(key => {
            const ltdKey = this.ltdToDecisionKeyMapping[key];
            const value = get(doc, ltdKey);
            if (value) {
                decision[key] = value;
            }
        });
        return { ...decision, ...doc._decision };
    },
};

// const DATA = [
//     {
//         label: 'Title of decision',
//         value: get(data, 'cld.title') || get(data, 'title') || '',
//         tooltip: ''
//     },
//     {
//         label: 'Source',
//         value: get(data, 'cld.documentSourceEn') || get(data, 'source') || '',
//         tooltip: ''
//     },
//     {
//         label: 'Situation/case',
//         value: getSituationCase(data),
//         tooltip: ''
//     },
//     {
//         label: 'Date',
//         value: moment(get(data, 'dateCreated') || '').format('DD MMMM YYYY'),
//     },
//     {
//         label: 'Document number',
//         value: get(data, 'cld.fullDocumentNumber') || get(data, 'Document Number') || '',
//         tooltip: 'The unique identifier of the decision in the format given by the ICC'
//     },
//     {
//         label: 'Phase of case',
//         value: get(data, 'cld.phaseOfCaseEn') || get(data, 'phaseOfCases[0].title') || '',
//         tooltip: 'Phase of the proceedings during which the decision was issued'
//     },
//     {
//         label: 'Type of decision',
//         value: get(data, 'cld.typeOfDecisionEn') || '',
//         tooltip: 'The type of the decision according to its content and/or source'
//     },
//     {
//         label: 'Judges',
//         value: (get(data, 'cldJudges') || []).join(', '),
//         tooltip: 'TComposition of the Chamber that issued the resource contained in the CLD'
//     },
//     {
//         label: 'Number of pages',
//         value: get(find(metadata, { name: 'Number of pages' }), 'val') || '',
//         tooltip: ''
//     },
//     {
//         label: 'Language',
//         value: get(data, 'cld.languageEn') || get(data, 'languages[0].name') || '',
//         tooltip: ''
//     },
//     {
//         label: 'Permanent URL',
//         value: get(data, 'pdfURL') || '',
//         tooltip: ''
//     },
// ]
