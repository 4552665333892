import _ from 'lodash';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import moment from 'moment';

export const getMetadataConfig = (document: TDocument): { label: string; key: string; value: string }[] => {
    const documentUrl = `${DOCUMENT_BASE_URL}${document.slug}`;
    if (!document.documentUrl) document.documentUrl = documentUrl;

    let metadataConfig: Array<{ key: string; label: string; keyAlt?: string }>;

    switch (document.contentType) {
        case 'academic_writing':
        case 'judicial_document': {
            metadataConfig = CONFIG[document.contentType];
            break;
        }
        default:
            metadataConfig = CONFIG['other'];
    }

    if (_.get(document, 'source') === _.get(document, 'organisation'))
        metadataConfig = metadataConfig.filter(config => config.key !== 'organisation');

    let metadata: { label: string; key: string; value: string }[] = [];
    _.forEach(metadataConfig, config => {
        if (_.get(document, config.key)) {
            if (config.key === 'dateCreated')
                metadata.push({ ...config, value: moment(_.get(document, config.key)).format('DD.MM.YYYY') });
            else metadata.push({ ...config, value: _.get(document, config.key) });
        } else if (config.keyAlt) {
            if (config.keyAlt === 'dateCreated')
                metadata.push({ ...config, value: moment(_.get(document, config.keyAlt)).format('DD.MM.YYYY') });
            else metadata.push({ ...config, value: _.get(document, config.keyAlt) });
        }
    });

    return metadata;
};

const CONFIG = {
    academic_writing: [
        { label: 'Date', key: 'dateCreated_str', keyAlt: 'dateCreated' },
        { label: 'Author/Editor', key: 'author' },
        { label: 'Publisher', key: 'publisher' },
        { label: 'Persistent URL', key: 'documentUrl' },
    ],
    judicial_document: [
        { label: 'Date', key: 'dateCreated_str', keyAlt: 'dateCreated' },
        { label: 'Case name', key: 'caseName_en', keyAlt: 'caseName' },
        { label: 'Organisation / State of source', key: 'organisation' },
        { label: 'Source', key: 'source' },
        { label: 'Persistent URL', key: 'documentUrl' },
    ],
    other: [
        { label: 'Date', key: 'dateCreated_str', keyAlt: 'dateCreated' },
        { label: 'Organisation / State of source', key: 'organisation' },
        { label: 'Source', key: 'source' },
        { label: 'Persistent URL', key: 'documentUrl' },
    ],
};
