import React, { Component, Fragment, useEffect, useState } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Button, FormControl, InputLabel, Input, Select, MenuItem, Typography, TextField, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { TNewLegalFindingState } from '../../Features/AddNewLegalFinding/@types';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import Tree from '../Tree';
import { TNode } from '../../Features/CLDDocumentTree/@types';
import { OCLDDocumentTree } from '../../Features/CLDDocumentTree/redux-config';
import { AxiosPromise } from 'axios';
import { getNodesOfLevel } from '../../Features/CLDDocumentTree/cld-documentTree-reducer';
import SearchWithSuggestion from '../SearchWithSuggestion';
import { OApp } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_SUBMIT } from '../../Features/Translations/translationKeys.cld';

interface IStateProps {
    NewLegalFinding: TNewLegalFindingState
    itemsSelected: Array<string>,
    tree: Array<TNode>,
}

interface IDispatchProps {
    close?: () => void
    onFormSave?: (key: string, value: any) => void
    openNode?: Function,
    closeNode?: Function,
    onCheck?: Function,
    resetTree?: Function,
    searchTree?: Function,
    clearSelection?: () => void
    selectAll?: () => void
    showToast?: (msg: string) => void
}

interface IState {
    formData: any
    formErrors: any
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, WithTranslationProps {

}


const CONFIG = {
    keywords: {
        title: 'ADDING NEW KEYWORD',
        formFields: []
    },
    appeal: {
        key: 'Appeal',
        title: 'EDIT RESULTS OF APPEALS',
        formFields: [
            {
                label: 'Appeal type', key: 'AppealType', required: true, type: 'select', options: [
                    { label: 'None', key: '' },
                    { label: 'Amends', key: 'Amends' },
                    { label: 'Amended by', key: 'Amended by' },
                    { label: 'Confirms', key: 'Confirms' },
                    { label: 'Confirmed by', key: 'Confirmed by' },
                    { label: 'Remands', key: 'Remands' },
                    { label: 'Remanded by', key: 'Remanded by' },
                    { label: 'Reverses', key: 'Reverses' },
                    { label: 'Reversed by', key: 'Reversed by' },
                ]
            },
            {
                label: 'Decision (document number)', required: true, key: 'Appeal', type: 'search',
                suggestionUrl: '/clddocs/autocomplete',
                params: (text) => ({ term: text, field: ['Document Number'] }),
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number'])
            },
            {
                label: 'Paragraph(s)', key: 'AppealPara', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
            {
                label: 'Page(s)', key: 'AppealPage', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
        ]
    },
    decisions: {
        key: 'Related Decision',
        title: 'EDIT RELATED DECISIONS',
        formFields: [
            {
                label: 'Relation type', key: 'Relation Type', required: true, type: 'select', options: [
                    { key: '', label: 'None' },
                    { key: 'Applies', label: 'Applies' },
                    { key: 'Cites', label: 'Cites' },
                    { key: 'Rejects', label: 'Rejects' }
                ]
            },
            {
                label: 'Decision (document number)', key: 'Related Decision', required: true, type: 'search',
                suggestionUrl: '/clddocs/autocomplete',
                params: (text) => ({ term: text, field: ['Document Number'] }),
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number'])
            },
            {
                label: 'Paragraph(s)', key: 'Related Para', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
            {
                label: 'Page(s)', key: 'Related Pages', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
        ]
    },
    opinions: {
        key: 'Opinions',
        title: 'EDIT SEPARATE OR DISSENTING OPINION',
        formFields: [
            {
                label: 'Opinion type', key: 'OpinionType', required: true, type: 'select', options: [
                    { key: '', label: 'None' },
                    { key: 'Separate', label: 'Separate' },
                    { key: 'Dissenting', label: 'Dissenting' },
                    { key: 'Majority', label: 'Majority' },
                ]
            },
            {
                label: 'Decision (document number)', key: 'Opinion', required: true, type: 'search',
                suggestionUrl: '/clddocs/autocomplete',
                params: (text) => ({ term: text, field: ['Document Number'] }),
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number'])
            },
            {
                label: 'Paragraph(s)', key: 'OpinionPara', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
            {
                label: 'Page(s)', key: 'OpinionPage', type: 'text',
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: utilities.validatePageParaFormat
            },
        ]
    },
}

class EditFields extends Component<IProps, IState> {
    state: IState = {
        formData: {},
        formErrors: {}
    }


    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (!prevProps.NewLegalFinding.editFields.open &&
            this.props.NewLegalFinding.editFields.open
        ) {

            if (this.props.NewLegalFinding.editFields.fieldIndex! === -1) {
                this.setState({ formData: {} });
                return;
            }

            const { section = 'appeal', fieldIndex = 0 } = this.props.NewLegalFinding.editFields;
            const { legalFinding } = this.props.NewLegalFinding;

            if (_.isEmpty(legalFinding))
                return;

            let formData = {};
            const key = CONFIG[section].key;
            if (_.isUndefined(key))
                return;

            CONFIG[section].formFields.forEach(field => {
                formData[field.key] = _.get(legalFinding![key][fieldIndex], field.key) || ''
            })

            this.setState({ formData });
        }
    }


    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
    }

    handleSuggestionSelect = ({ name, value }) => {
        this.setState({ formData: { ...this.state.formData, [name]: value } })
    }

    handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
    }


    onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // const { formData } = this.state;
        // if (_.isEmpty(this.state.formData))
        //     return;

        const { editFields } = this.props.NewLegalFinding;
        if (!this.validateInput()) return;

        const section = _.get(editFields, 'section') || '';
        const { fieldIndex = -1 } = editFields;
        // switch (editFields.section) {
        // case 'appeal': {
        const formFieldKey = CONFIG[section].key;
        const legalFinding = _.get(this.props.NewLegalFinding, 'legalFinding') || {};
        let form: Array<any> = [...(legalFinding[formFieldKey] || [])];
        if (fieldIndex === -1) {
            if (_.isEmpty(form))
                form = [this.state.formData];
            else
                form.push(this.state.formData);
        } else {
            form.splice(fieldIndex, 1, this.state.formData);
        }

        if (_.isFunction(this.props.onFormSave)) {
            this.props.onFormSave(formFieldKey, form);
            if (this.props.showToast)
                this.props.showToast(`${formFieldKey} added successfully. Please save to commit changes.`);
        }
        // break;
        // }
        // case 'opinions': {

        // }
        // default: return;
        // }
    }

    validateInput = () => {
        const { editFields } = this.props.NewLegalFinding;
        const FORM_CONFIG = CONFIG[editFields.section!]
        const requiredFields = (_.get(FORM_CONFIG, 'formFields') || []).filter(field => field.required)
        let valid = true
        let formErrors = {}
        _.forEach(requiredFields, field => {
            const key = field.key
            if (!this.state.formData[key]) {
                formErrors = { ...formErrors, [key]: 'Required' }
                valid = false
            }
        })
        const validationsInputs = (_.get(FORM_CONFIG, 'formFields') || []).filter(field => field.validationFunc)
        _.forEach(validationsInputs, field => {
            const { key, errorText, validationFunc } = field;
            if (!validationFunc(this.state.formData[key])) {
                formErrors = { ...formErrors, [key]: errorText }
                valid = false
            }
        })
        this.setState({ formErrors })
        return valid;
    }


    handleBlur = (inputConfig) => () => {
        if (inputConfig.required) {
            if (!this.state.formData[inputConfig.key])
                this.setState({ formErrors: { ...this.state.formErrors, [inputConfig.key]: 'Required' } })
            else
                this.setState({ formErrors: { ...this.state.formErrors, [inputConfig.key]: '' } })
        }
        if (inputConfig.validationFunc) {
            if (!inputConfig.validationFunc(this.state.formData[inputConfig.key])) {
                this.setState({ formErrors: { ...this.state.formErrors, [inputConfig.key]: inputConfig.errorText || '' } })
            } else {
                this.setState({ formErrors: { ...this.state.formErrors, [inputConfig.key]: '' } })

            }
        }
    }

    render() {
        const { classes, NewLegalFinding, getTranslation } = this.props;
        const { formData, formErrors } = this.state;
        const { editFields } = NewLegalFinding;
        if (_.isEmpty(editFields.section))
            return <div />

        const FORM_CONFIG = CONFIG[editFields.section!]

        return (
            <div className={classes.root}>
                <div className={classes.label}>
                    <Typography>{FORM_CONFIG.title}</Typography>
                    <Button onClick={this.props.close}>
                        <i className="material-icons">close</i>
                    </Button>
                </div>
                {
                    editFields!.section === 'keywords' ?
                        <Fragment>
                            <KeywordSearch {...this.props} />
                            <KeywordTree {...this.props} />
                        </Fragment>
                        :
                        <form className={classes.formContainer} onSubmit={this.onFormSubmit}>
                            {
                                FORM_CONFIG.formFields.map((input, i) => {
                                    if (input.type === 'text')
                                        return (
                                            <FormControl className={classes.formControl} key={i} fullWidth>
                                                <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                                <Input
                                                    onBlur={this.handleBlur(input)}
                                                    id={input.key}
                                                    value={formData[input.key] || ''}
                                                    name={input.key}
                                                    placeholder={input.hintText}
                                                    error={input.validationFunc && formData[input.key] ? !input.validationFunc(formData[input.key] || '') : false}
                                                    onChange={this.handleChange}
                                                />
                                                <Typography color="error">{formErrors[input.key]}</Typography>
                                            </FormControl>
                                        );
                                    else if (input.type === 'select')
                                        return (
                                            <FormControl key={i} className={classes.formControl} fullWidth>
                                                <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                                <Select onBlur={this.handleBlur(input)} value={formData[input.key] || ''} onChange={this.handleSelect} inputProps={{ id: input.key, name: input.key }}>
                                                    {
                                                        input.options!.map((option, i) => (
                                                            <MenuItem key={i} value={option.key}>{option.label}</MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                                <Typography color="error">{formErrors[input.key]}</Typography>
                                            </FormControl>
                                        );
                                    else if (input.type === 'search')
                                        return (
                                            <FormControl key={i} className={classes.formControl}>
                                                <SearchWithSuggestion
                                                    value={formData[input.key]}
                                                    errorText={formErrors[input.key]}
                                                    formKey={input.key}
                                                    label={input.label}
                                                    onBlur={this.handleBlur(input)}
                                                    onChange={this.handleSuggestionSelect}
                                                    suggestionUrl={input.suggestionUrl}
                                                    params={input.params}
                                                    handleSuggestions={input.handleSuggestions}
                                                />
                                            </FormControl>
                                        )
                                    else
                                        return (
                                            <div />
                                        )
                                }
                                )

                            }

                            <Button type="submit" style={{ marginTop: 32 }} fullWidth color="primary" variant="outlined">{getTranslation(GENERAL_SUBMIT).toUpperCase()}</Button>

                        </form>
                }

            </div>
        )
    }
}





class KeywordSearch extends Component<IProps> {
    state = {
        formData: {
            keyword: ''
        }
    }

    resetTree = () => {
        this.setState({ formData: { keyword: '' } });
        if (_.isFunction(this.props.resetTree))
            this.props.resetTree()
    }

    handleKeywordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const keyword = e.target.value;
        const { formData } = this.state;
        this.setState({ formData: { keyword } });
        // if (_.isEmpty(keyword) && this.props.resetTree)
        //     this.props.resetTree();
        if (_.isFunction(this.props.searchTree)) {
            this.props.searchTree!(keyword)
        }
    }

    clearSelection = () => {
        if (!this.props.clearSelection) return;

        this.props.clearSelection()
    }

    selectAll = () => {
        if (this.props.selectAll)
            this.props.selectAll();
    }


    render() {
        const { classes, itemsSelected = [] } = this.props;
        const { formData } = this.state;

        return (
            <>
                <div className={classes.searchBar}>
                    <i className="material-icons">search</i>
                    <TextField
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        placeholder="Enter keyword"
                        name='keyword'
                        // onKeyPress={this.handleKeyPress}
                        onChange={this.handleKeywordChange}
                        value={_.get(formData, 'keyword') || ''}
                    />
                    {
                        !_.isEmpty(_.get(formData, 'keyword')) &&
                        <Button className={classes.clear} onClick={e => this.resetTree()}>
                            <i className="material-icons">close</i>
                        </Button>
                    }
                </div>
                <div className="flex-row align-center space-between" style={{ color: THEME.Colors.Third, borderBottom: '1px dashed grey' }}>
                    <Button onClick={this.selectAll} variant="text" color="inherit">
                        <Typography color="inherit" variant="caption">SELECT ALL</Typography>
                    </Button>
                    {
                        itemsSelected.length > 0 ?
                            <Button onClick={this.clearSelection} variant="text" color="inherit">
                                <Typography color="inherit" variant="caption">CLEAR ALL</Typography>
                            </Button> : null
                    }

                    <Button variant="text" style={{ color: THEME.Colors.Third }} onClick={this.resetTree}>
                        <Typography color="inherit" variant="caption">COLLAPSE KEYWORD TREE</Typography>
                    </Button>
                </div>
            </>
        )
    }
}



class KeywordTree extends Component<IProps> {
    state = {
        loading: false,
        idsToExclude: []
    }

    componentDidMount() {
        getNodesOfLevel(1).then(
            res => this.setState({ idsToExclude: res })
        )
    }

    onTreeContentClick = (node: TNode) => {
    }

    onCheck = (node: TNode) => {
        if (_.isFunction(this.props.onCheck))
            this.props.onCheck(node);
    }

    handleSubmit = async () => {
        this.setState({ loading: true })
        try {
            const { itemsSelected } = this.props;
            const { idsToExclude } = this.state;
            const idsForKeywords = _.difference(itemsSelected, idsToExclude);
            if (_.isEmpty(idsForKeywords))
                return;

            const keywords = await ONewLegalFinding.getKeywordFromSelection(idsForKeywords);
            if (keywords && this.props.onFormSave) {
                const legalFinding = _.get(this.props.NewLegalFinding, 'legalFinding') || {};
                let formData: Array<any> = [...(legalFinding['Keyword'] || [])];
                this.props.onFormSave('Keyword', [...formData, ...(keywords['keywords'] || [])]);
                this.props.clearSelection!()
            }

            this.setState({ loading: false })

        } catch (error) {
            this.setState({ loading: false })

        }
    }

    onCollapse = () => {
        if (_.isFunction(this.props.resetTree))
            this.props.resetTree();
    }

    render() {
        const { classes, tree, itemsSelected = [] } = this.props;
        const { loading = false } = this.state;





        return (
            <Fragment>
                <div className={classes.documentTree}>
                    <Tree
                        disableLevel={1}
                        checkDisabled={(node) => _.isEmpty(node.keywordGeneratedId)}
                        color={THEME.Colors.Third}
                        textKey="keyword_text"
                        onOpen={this.props.openNode!}
                        onClose={this.props.closeNode!}
                        onClick={this.onTreeContentClick}
                        disableCheck={false}
                        checked={itemsSelected}
                        // onCollapse={this.props.resetTree}
                        tree={tree}
                        onCheck={this.onCheck}
                        collapseText="collapse keyword tree"
                    />
                </div>
                {
                    !_.isEmpty(itemsSelected) &&
                    <Button color="primary" disabled={loading} onClick={this.handleSubmit} className={classes.treeSubmitBtn} variant='contained' fullWidth>
                        {
                            loading ? <CircularProgress /> : 'submit'
                        }
                    </Button>
                }
            </Fragment>
        )
    }


}







const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state, 'NewLegalFinding'),
    ..._.pick(state.CLDDocumentTree, ['tree', 'itemsSelected']),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    close: () => dispatch(ONewLegalFinding.closeEditSection()),
    onFormSave: (key: string, value: any) => dispatch(ONewLegalFinding.editLegalFinding(key, value)),
    openNode: (parentId) => dispatch(OCLDDocumentTree.fetchChildren(parentId)),
    closeNode: (parentId) => dispatch(OCLDDocumentTree.removeChildren(parentId)),
    onCheck: (node) => dispatch(OCLDDocumentTree.treeItemSelect(node)),
    resetTree: () => dispatch(OCLDDocumentTree.resetTree()),
    searchTree: (term) => dispatch(OCLDDocumentTree.treeSearch(term)),
    clearSelection: () => dispatch(OCLDDocumentTree.clearSelection()),
    showToast: (msg: string) => dispatch(OApp.showToast(msg, 'success')),
    selectAll: () => dispatch(OCLDDocumentTree.treeSelectALlItems(true))

})

const STYLES = (theme: Theme) => createStyles({
    root: {
        // padding: 8,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
    },
    label: {
        padding: '2px 18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& button': {
            width: 40,
            height: 40,
            borderRadius: 20,
            padding: 0,
            minWidth: 0,
            minHeight: 0
        },
        '& p': {
            fontWeight: 500
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 40px',
    },
    formControl: {
        margin: '8px 0px',
    },
    documentTree: {
        margin: '0 auto',
        overflowY: 'scroll',
        // width: '100%',
        // padding: '10px 22px 22px 240px'

    },
    treeSubmitBtn: {
        position: 'absolute',
        bottom: 0,
        width: 400,
        right: 0,
        borderRadius: 0
    },
    searchBar: {
        height: 60,
        display: 'flex',
        alignItems: 'center',
        padding: '6px 16px',
        background: THEME.Colors.Grayscale.Grey5,
    },
    clear: {
        borderRadius: '50%',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        // height: 'auto',
        width: 25,
        height: 22,
        background: 'gray',
        '& i': {
            margin: 0,
            color: 'white',
            fontSize: 14,
            padding: 4,
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(EditFields)))