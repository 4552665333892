import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Dialog, DialogTitle, DialogContent, Typography, IconButton, Icon } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

export interface SimpleDialogProps extends WithStyles<typeof STYLES> {
    headerText?: string
    containerWidth?: number
    open: boolean
    handleClose?: () => void
}

const SimpleDialog: FC<SimpleDialogProps> = (props) => {
    const { classes, headerText = '', open = false, containerWidth = 420 } = props;

    return (
        <Dialog disableBackdropClick scroll="body" open={open} PaperProps={{ style: { width: containerWidth } }} classes={{ paper: classes.root }} onClose={props.handleClose}>
            <div className={classes.dialogTitle}>
                <Typography color="inherit">{headerText}</Typography>
                <div style={{ flex: 1 }} />
                {props.handleClose ? (
                    <IconButton style={{ padding: 4 }} color="inherit" onClick={props.handleClose}><Icon>close</Icon></IconButton>
                ) : null}
            </div>
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        // width: 420,
        maxWidth: undefined
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '10px 14px',
        '& > p': {
            fontSize: '20px'
        },

    }
});

export default withStyles(STYLES)(SimpleDialog);