import { Models } from '../../Resources/Model';
import _ from 'lodash';
import { TSort, TChip, TDocument, TFacetKeys } from './@types';
import { TListHeader } from './documents-reducer';
import { defaultDate } from '../../screens/LTD/LeftNav';
import { HISTORY } from '../../screens/RootLayout';
import { TNode } from '../DocumentsTree/@types';
import { ODocumentTree } from '../DocumentsTree/redux-config';
import { OLtdDashboard } from '../LtdDashboard/redux-config';
import { getAllChildren, findNode } from '../DocumentsTree/documentTree-reducer';
import utilities from '../../Resources/Utils';
import moment from 'moment';
import Axios from 'axios';
import { LTD_INCLUDES } from '../DetailPage/redux-config';
import { TState } from '../../RootReducer';
import Config from '../../Resources/Config';
import { SearchQueryModel } from './searchQueryModel';
import { ShortUrlService } from '../../Services/ShortUrl.service';
import { OApp } from '../../RootReducer/AppReducer';
import { translate } from '../Translations/useTranslations';

import {
    CARD_PANEL_SEARCH_RESULTLINK_COPIED,
    FILTER_COLLECTION,
    FILTER_CONTENT_TYPE,
    FILTER_JUDICAL_DOCUMENT,
    FILTER_LANGUAGE,
    FILTER_ORGANIZATION_STATE,
    FILTER_PHASE_OF_CASE,
    FILTER_SOURCE,
    FILTER_SOURCE_TYPE,
    LISTING_HEADER_LATEST,
    LISTING_HEADER_OLDEST,
    LISTING_HEADER_RELEVANCE,
} from '../Translations/translationKeys.ltd';

export const DOCUMENT_BASE_URL = Config.get('DOCUMENT_BASE_URL');
export const SCC_DOCUMENT_BASE_URL = Config.get('SCC_DOCUMENT_BASE_URL');

export const HOME_URL = () => {
    let path = _.get(_.split(window.location.pathname, '/'), '[1]');

    if (path === 'scc' || path === 'sccdoc') path = 'scc';

    if (path === 'ltd' || path === 'doc' || path === 'en') path = '';

    if (path === 'clddoc' || path === 'decision' || path === 'readinglist') path = 'cld';

    return {
        path: '/' + path,
        name: _.toUpper(path),
    };
};

export const STATIC_FILTER = {
    restrictSpanQueryConversion: true,
    highlight: {
        pre_tags: ['<em>'],
        post_tags: ['</em>'],
        fields: {
            title: {
                order: 'score',
            },
            'docPages.text': {
                order: 'score',
                number_of_fragments: 30,
                fragment_size: 300,
            },
            caseName_en: {},
            externalId: {},
        },
    },
};

export const DOCUMENT_STATUS_KEY = 'documentStatus';

export const FACETS = [
    { key: DOCUMENT_STATUS_KEY, id: DOCUMENT_STATUS_KEY, label: 'Document status', onScc: false },
    {
        key: 'collection',
        id: 'collection',
        label: 'Collection',
        useResolver: true,
        translationKey: FILTER_COLLECTION,
        onScc: false,
    },
    {
        key: 'contentType',
        id: 'contentType',
        label: 'Content Type',
        useResolver: true,
        translationKey: FILTER_CONTENT_TYPE,
        onScc: false,
    },
    {
        key: 'sourceTypes.title',
        id: 'sourceTypes',
        label: 'Source Type',
        useResolver: true,
        translationKey: FILTER_SOURCE_TYPE,
        onScc: false,
    },
    {
        key: 'organisation',
        id: 'organisation',
        label: 'Organization / State',
        translationKey: FILTER_ORGANIZATION_STATE,
        onScc: false,
    },
    { key: 'source', id: 'source', label: 'Source', translationKey: FILTER_SOURCE, onScc: true },
    {
        key: 'phaseOfCases.title',
        id: 'phaseOfCases',
        label: 'Phase of case',
        useResolver: true,
        translationKey: FILTER_PHASE_OF_CASE,
        onScc: true,
    },
    {
        key: 'judicialDocumentType',
        id: 'judicialDocumentType',
        label: 'Judicial document',
        useResolver: true,
        translationKey: FILTER_JUDICAL_DOCUMENT,
        onScc: true,
    },
    { key: 'languages.name', id: 'languages', label: 'Language', translationKey: FILTER_LANGUAGE, onScc: true },
];

export const FACET_KEY_VALUE_MAPPING = {
    Published: { key: 'isPublished', value: true },
    Unpublished: { key: 'isPublished', value: false },
    Confidential: { key: 'confidentiality', value: 'confidential' },
    Public: { key: 'confidentiality', value: 'public' },
    Deleted: { key: 'deleted', value: true },
};

export const DOCUMENT_STATUS_FACETS = [
    { key: 'Published', searchKey: 'isPublished', value: true },
    { key: 'Unpublished', searchKey: 'isPublished', value: false },
    { key: 'Confidential', searchKey: 'confidentiality', value: 'confidential' },
    { key: 'Public', searchKey: 'confidentiality', value: 'public' },
    { key: 'Deleted', searchKey: 'deleted', value: true },
];

export const SORTS = {
    Relevance: {},
    Latest: {
        dateCreated: {
            order: 'desc',
        },
    },
    Oldest: {
        dateCreated: {
            order: 'asc',
        },
    },
    RecentlyModified: {
        dateCreated: {
            order: 'desc',
        },
    },
};

export const SORT_TYPES = [
    { key: 'Relevance', label: 'Relevance', translationKey: LISTING_HEADER_RELEVANCE },
    // { key: 'RecentlyModified', label: 'Recently Modified' },
    { key: 'Latest', label: 'Latest-change', translationKey: LISTING_HEADER_LATEST },
    { key: 'Oldest', label: 'Oldest', translationKey: LISTING_HEADER_OLDEST },
];

const keyToIgnore = ['dateCreated', 'parentId'];

export let mapParentIdsToValues = {};
export let mapvaluesToParentIds = {};

export const setTreeIdValueMapping = (
    _mapParentIdsToValues: Record<string, any>,
    _mapvaluesToParentIds: Record<string, any>
) => {
    mapParentIdsToValues = { ...mapParentIdsToValues, ..._mapParentIdsToValues };
    mapvaluesToParentIds = { ...mapvaluesToParentIds, ..._mapvaluesToParentIds };
};

export const mapValueAndId = (node: TNode) => {
    mapParentIdsToValues[node.id] = node.text;
    mapvaluesToParentIds[node.text] = node.id;
};

export const getChipFromId = (id): TChip => {
    return {
        name: mapParentIdsToValues[id],
        filterType: 'where',
        key: 'parentIds',
        value: id,
    };
};

export const LTD_KEY_LABEL = {
    uid: 'Database Record Number',
    documentUrl: 'Persistent URL',
    title: 'Title',
    title_en: 'Translated title (EN)',
    title_fr: 'Translated title (FR)',
    shortTitle: 'Short title',
    externalId: 'External identifier',
    contentType: 'Content type',
    source: 'Source',
    sourceTypes: 'Source type',
    organisation: 'Organisation / State of source',
    relatedOrganisation: 'Related organisation / state',
    orgaisationJudicialBody: 'Organisation / State of judicial body',
    dateCreated: 'Date created',
    entryIntoForce: 'Entry into force',
    languages: 'Language(s)',
    isOriginalLanguage: 'Original language',
    isAuthoritativeLanguage: 'Authoritative language',
    abstract: 'Abstract',
    caseName_en: 'Case name (ICC naming convention - EN)',
    caseName_fr: 'Case name (ICC naming convention - FR)',
    caseName: 'Case name (ICC naming convention)',
    alternative: 'Alternative and / or short case title',
    alternative_en: 'Alternative and / or short case title (EN)',
    alternative_fr: 'Alternative and / or short case title (FR)',
    caseNumber: 'Case/Document number',
    relatedCases: 'Related case',
    judicialDocuments: 'Key judicial document',
    phaseOfCases: 'Phase of case',
    chamberComposition: 'Composition of chamber',
    presidingJudge: 'Presiding judge',
    placeCourt: 'Place of court',
    courtType: 'Type of court',
    accusedDefendant: 'Accused / Defendant',
    prosecutorClaimant: "Prosecutor's team / Claimant",
    counselForDefence: 'Counsel for defence',
    participatingStates: 'Participating states',
    victimParticipation: 'Victims participation',
    victimsCounsel: 'Counsel for victims',
    amicusCuriae: 'Amicus curiae',
    isCourtRecord: 'Is court record',
    iccSituations: 'ICC situation name',
    interlocutory: 'Interlocutory appeal no.',
    resourceCitation_en: 'Resource citation (EN)',
    resourceCitation_fr: 'Resource citation (FR)',
    accessDate: 'Date accessed / downloaded',
    numberOfPages: 'Number of pages',
    documentType: 'Document type',
    responsiblePartner: 'Responsible partner',
    publishDate: 'Date published in Legal Tools',
    registerDate: 'Date registered',
    documentOrigin: 'Origin',
    documentOriginUrl: 'URL',
    subjectLevel1: 'Subject',
    preparatoryPhase: 'Phase of preparatory works',
    author: 'Author/Editor',
    publisher: 'Publisher',
    judicialDocumentType: 'Judicial document type',
    resolvedRelatedDocs: 'Related documents',
    // relatedDocs: 'Related documents'
};

export const FIELD_MAPPING = [
    {
        key: 'oldRef.uid',
        labelKey: 'uid',
    },
    {
        key: 'documentUrl',
    },
    {
        key: 'title',
    },
    {
        key: 'title_en',
    },
    {
        key: 'title_fr',
    },
    {
        key: 'shortTitle',
    },
    {
        key: 'externalId',
    },
    {
        key: 'contentType',
        constKey: 'contentType',
    },
    {
        key: 'source',
    },
    {
        key: 'sourceTypes',
        arrayMapKey: 'title',
    },
    {
        key: 'organisation',
    },
    {
        key: 'relatedOrganisation',
    },
    {
        key: 'orgaisationJudicialBody',
    },
    {
        key: 'dateCreated',
        isDate: true,
    },
    {
        key: 'entryIntoForce',
        isDate: true,
    },
    {
        key: 'languages',
        arrayMapKey: 'name',
    },
    {
        key: 'isOriginalLanguage',
        isBoolean: true,
    },
    {
        key: 'isAuthoritativeLanguage',
        isBoolean: true,
    },
    {
        key: 'abstract',
    },
    {
        key: 'caseName_en',
    },
    {
        key: 'caseName_fr',
    },
    {
        key: 'caseName',
    },
    {
        key: 'alternative',
    },
    {
        key: 'alternative_en',
    },
    {
        key: 'alternative_fr',
    },
    {
        key: 'caseNumber',
    },
    {
        key: 'relatedCases',
    },
    {
        key: 'judicialDocuments',
        arrayMapKey: 'title',
    },
    {
        key: 'phaseOfCases',
        arrayMapKey: 'title',
    },
    // {
    //     key: 'keywords', arrayMapKey: 'keyword_text'
    // },
    {
        key: 'chamberComposition',
    },
    {
        key: 'presidingJudge',
    },
    {
        key: 'placeCourt',
    },
    {
        key: 'courtType',
        constKey: 'courtType',
    },
    {
        key: 'accusedDefendant',
    },
    {
        key: 'prosecutorClaimant',
    },
    {
        key: 'counselForDefence',
    },
    {
        key: 'participatingStates',
        isBoolean: true,
    },
    {
        key: 'victimParticipation',
        isBoolean: true,
    },
    {
        key: 'victimsCounsel',
    },
    {
        key: 'amicusCuriae',
    },
    {
        key: 'isCourtRecord',
        isBoolean: true,
    },
    {
        key: 'iccSituations',
        arrayMapKey: 'title',
    },
    {
        key: 'interlocutory',
    },
    {
        key: 'resourceCitation_en',
    },
    {
        key: 'resourceCitation_fr',
    },
    {
        key: 'accessDate',
        isDate: true,
    },
    {
        key: 'numberOfPages',
    },
    {
        key: 'documentType',
        constKey: 'documentType',
    },
    {
        key: 'responsiblePartner',
        constKey: 'partner',
    },

    {
        key: 'publishDate',
        isDate: true,
    },
    {
        key: 'registerDate',
        isDate: true,
    },
    {
        key: 'documentOrigin.source',
        labelKey: 'documentOrigin',
        constKey: 'documentOrigin',
    },
    // {
    //     key: 'documentOrigin', constKey: 'documentOrigin'
    // },
    {
        key: 'judicialDocumentType',
        constKey: 'judicialDocumentType',
    },
    {
        key: 'documentOrigin.url',
        labelKey: 'documentOriginUrl',
        valueTransformers: [encodeURI],
    },
    // {
    //     key: 'documentOriginUrl'
    // },
    {
        key: 'subjectLevel1',
    },
    {
        key: 'preparatoryPhase',
        constKey: 'preparatoryPhase',
    },
    {
        key: 'author',
    },
    {
        key: 'publisher',
    },
];

const UNAUTHENTICATED_INCLUDES = ['languages', 'iccSituations'];

export const includeValueOptions = async (isAuthenticated: boolean = false) => {
    const _FIELD_MAPPING = FIELD_MAPPING.filter(f =>
        isAuthenticated ? true : UNAUTHENTICATED_INCLUDES.includes(f.key)
    );
    const urls = _FIELD_MAPPING.filter(mapping => mapping.arrayMapKey).map(field => field.key);
    const promises = _.map(urls, url => Axios.get(url));

    let options: Record<string, any> = {};

    await Promise.all(promises).then(res => {
        _FIELD_MAPPING
            .filter(mapping => mapping.arrayMapKey)
            .forEach((field, i) => {
                options[field.key] = _.map(res[i].data, option => ({
                    name: _.get(option, field.arrayMapKey!),
                    value: option.id,
                    key: option.id,
                }));
            });
    });

    if (options.languages)
        options.languages = (options.languages || []).sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    return options;
};

export const setLTDMetadata = (doc: TDocument, appConstants: any) => {
    const metadata: Array<{ name: string; val: any }> = [];

    FIELD_MAPPING.forEach(config => {
        if (
            config.key !== 'documentUrl' &&
            config.key !== 'dateCreated' &&
            (_.isUndefined(_.get(doc, config.key)) || _.get(doc, config.key) === 'NULL')
        )
            return;

        let val;
        if (config.key === 'documentUrl') val = DOCUMENT_BASE_URL + (_.get(doc, 'slug') || _.get(doc, 'id')) + '/';

        if (config.constKey)
            val = utilities.findValues(_.get(_.get(appConstants, 'LtdDoc'), config.constKey), _.get(doc, config.key));

        if (config.arrayMapKey) val = _.map(_.get(doc, config.key), config.arrayMapKey);

        if (config.isDate) val = moment.parseZone(_.get(doc, config.key)).format('DD.MM.YYYY');

        if (config.isBoolean) val = _.get(doc, config.key) ? 'Yes' : 'No';

        if (config.key === 'dateCreated')
            val = _.get(doc, 'dateCreated_str') || moment.parseZone(_.get(doc, config.key)).format('DD.MM.YYYY');

        if (!val) val = _.get(doc, config.key);

        if (_.isArray(val) && _.isEmpty(val)) return;

        if (!_.isUndefined(val)) {
            val = config.valueTransformers
                ? config.valueTransformers.reduce((val, transformer) => transformer(val), val)
                : val;
            metadata.push({
                name: _.get(LTD_KEY_LABEL, config.labelKey ? config.labelKey : config.key),
                val: _.isArray(val) ? val.join(', ') : val,
            });
        }
    });

    return metadata;
};

export const shouldShowRelevance = filter => {
    return !_.isEmpty(_.get(filter, 'match_phrase')) || !_.isEmpty(_.get(_.omit(filter, 'where.parentIds'), 'where'));
};

export const getChipList = (searchFilter, searchTerm) => {
    let chips: Array<TChip> = [];
    const where = _.get(searchFilter, 'where');
    const should = _.get(searchFilter, 'should') || [];
    const match_phrase = _.get(searchFilter, 'match_phrase');
    const term: TChip = {
        name: searchTerm,
        filterType: 'term',
        key: 'term',
    };
    if (_.isEmpty(where) && _.isEmpty(match_phrase) && _.isEmpty(searchTerm) && _.isEmpty(should)) return [];

    const DOCUMENT_STATUS_FACET_KEYS = DOCUMENT_STATUS_FACETS.map(item => item.searchKey);

    const documentStatusQueries = _.get(should, '[0]') || [];
    _.forEach(documentStatusQueries, q => {
        _.forEach(q, (v, k) => {
            if (DOCUMENT_STATUS_FACET_KEYS.indexOf(k) > -1) {
                const item = _.find(DOCUMENT_STATUS_FACETS, { searchKey: k, value: v });
                if (item)
                    chips.push({
                        name: _.get(item, 'key'),
                        filterType: 'should',
                        key: _.get(item, 'key'),
                    });
            }
        });
    });

    _.forEach(where, (values, key) => {
        if (key !== 'sort') {
            if (DOCUMENT_STATUS_FACET_KEYS.indexOf(key) > -1) {
                // chips.push({ filterType: 'where', name: key, key, value: values })
            } else if (key === 'dateCreated') {
                if (values['gte'] !== defaultDate.dateFrom || values['lte'] !== defaultDate.dateTo)
                    chips.push({
                        filterType: 'where',
                        name: `${moment(values['gte']).format('DD.MM.YYYY')} - ${moment(values['lte']).format(
                            'DD.MM.YYYY'
                        )}`,
                        key,
                    });
            } else if (key === 'parentIds') {
                _.forEach(values, id => chips.push(getChipFromId(id)));
            } else _.forEach(values, value => chips.push({ filterType: 'where', name: value, key, value }));
        }
    });
    _.forEach(match_phrase, (value, key) => {
        chips.push({ filterType: 'match_phrase', value, key, name: value });
    });

    if (_.isEmpty(searchTerm)) return chips;
    return [term, ...chips];
};

const RELEVEANCE_SORT_KEYS = ['term', 'dateCreated', 'title', 'caseName_en', 'externalId.search'];

export const getSortFromChipList = (chipList: TChip[], sort: TSort, overrideRelevance: boolean): TSort => {
    if (overrideRelevance) return sort;

    const isRelevance = _.findIndex(chipList, chip => RELEVEANCE_SORT_KEYS.indexOf(chip.key) > -1) > -1;
    if (isRelevance) return 'Relevance';
    else return sort;

    if (_.findIndex(chipList, chip => RELEVEANCE_SORT_KEYS.indexOf(chip.key) > -1)) return 'Latest';
    else return 'Relevance';
};

const MATCH_FILTER_KEY_LIST = ['title', 'caseName_en', 'externalId.search', 'externalId.keyword'];

const FILTER_KEYS = ['title', 'caseName_en', 'externalId.search', 'externalId.keyword', 'dateCreated'];

export const getSearchType = key => {
    const DOCUMENT_STATUS_FACET_KEYS = DOCUMENT_STATUS_FACETS.map(item => item.key);
    if (DOCUMENT_STATUS_FACET_KEYS.indexOf(key) > -1) return 'should';
    return _.indexOf(MATCH_FILTER_KEY_LIST, key) > -1 ? 'match_phrase' : key === 'term' ? 'term' : 'where';
};

export const buildFilterFromFormData = (formData: Record<string, any>, isExactSearch: boolean = false) => {
    let range;
    if (!_.isEmpty(_.get(formData, 'gte')) && !_.isEmpty(_.get(formData, 'lte')))
        range = {
            dateCreated: {
                type: 'range',
                gte: new Date(_.get(formData, 'gte')).getTime(),
                lte: new Date(_.get(formData, 'lte')).getTime(),
            },
        };

    formData = { ..._.omit(formData, ['gte', 'lte']), ...range };
    formData = _.omit(formData, 'term');

    let query = { match_phrase: {}, where: {} };
    if (!_.isEmpty(formData)) {
        _.forEach(formData, (value, key) => {
            if (key === 'dateCreated' || isExactSearch) query = { ...query, where: { ...query.where, [key]: value } };
            else query = { ...query, match_phrase: { ...query.match_phrase, [key]: value } };
        });
    }

    return query;
};

export class Documents extends Models {
    initialized = false;
    constructor() {
        super('ltddocs', {
            APPLY_FILTER: 'APPLY_FILTER',
            SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
            APPLY_SORT: 'APPLY_SORT',
            SEARCHING_DOCUMENTS: 'SEARCHING_DOCUMENTS',
            SEARCH_COMPLETED: 'SEARCH_COMPLETED',
            SET_LIST_HEADER: 'SET_LIST_HEADER',
            SET_CHIP_LIST: 'SET_CHIP_LIST',
            REMOVE_CHIP: 'REMOVE_CHIP',
            APPLY_SEARCH_TERM: 'APPLY_SEARCH_TERM',
            SET_SHOW_MATCHED_TEXT: 'SET_SHOW_MATCHED_TEXT',
            LTD_TOGGLE_DOCUMENT_SELECTION: 'LTD_TOGGLE_DOCUMENT_SELECTION',
            LTD_CLEAR_DOCUMENT_SELECTION: 'LTD_CLEAR_DOCUMENT_SELECTION',
            LTD_SELECT_ALL_DOCUMENTS: 'LTD_SELECT_ALL_DOCUMENTS',
            LTD_APPLY_USER_SORT: 'LTD_APPLY_USER_SORT',
            LTD_RESET_ALL_FILTERS: 'LTD_RESET_ALL_FILTERS',
            LTD_SET_FORM_DATA: 'LTD_SET_FORM_DATA',
            LTD_REPLACE_FORM_DATA: 'LTD_REPLACE_FORM_DATA',
            LTD_CLEAR_FORM_DATA: 'LTD_CLEAR_FORM_DATA',
            LTD_SET_RECORD_PER_PAGE: 'LTD_SET_RECORD_PER_PAGE',
            LTD_APPLY_FACET: 'LTD_APPLY_FACET',
            LTD_REMOVE_FACET: 'LTD_REMOVE_FACET',
            LTD_RESET_FACETS: 'LTD_RESET_FACETS',
            LTD_DOCS_RESTORED: 'LTD_DOCS_RESTORED',
            INIT_QUERY_PARAMS: 'INIT_QUERY_PARAMS',
        });

        this.actions.MODEL_ENTITY_RECEIVED = 'SEARCH_SUCCESS';
    }

    entities = ['search'];

    setFormData = (key: string, value: any) => dispatch =>
        dispatch({
            type: this.actions.LTD_SET_FORM_DATA,
            data: {
                key,
                value,
            },
        });

    clearForm = () => dispatch =>
        dispatch({
            type: this.actions.LTD_CLEAR_FORM_DATA,
        });

    onDropdowChange = (sort: TSort) => dispatch =>
        dispatch({
            type: this.actions.LTD_APPLY_USER_SORT,
            data: {
                sort,
            },
        });

    resetFilters = (variant: 'ltd' | 'scc' = 'ltd') => dispatch => {
        dispatch({
            type: this.actions.LTD_RESET_ALL_FILTERS,
        });

        dispatch(this.searchDocuments(undefined, undefined, undefined, variant));
    };

    // MAINLIST DOCUMENTS CHECK CONTROL
    toggleSelect = (id: string) => dispatch => {
        dispatch({
            type: this.actions.LTD_TOGGLE_DOCUMENT_SELECTION,
            data: id,
        });
    };

    clearSelection = () => dispatch => {
        dispatch({
            type: this.actions.LTD_CLEAR_DOCUMENT_SELECTION,
        });
    };

    selectAll = (_ids?: string[]) => (dispatch, getState) => {
        if (_.isArray(_ids) && !_.isEmpty(_ids)) {
            dispatch({
                type: this.actions.LTD_SELECT_ALL_DOCUMENTS,
                data: _ids,
            });
            return;
        }
        const state: TState = getState();
        const { Documents } = state;
        const ids = Documents.documents.map(doc => doc.hit.id);

        dispatch({
            type: this.actions.LTD_SELECT_ALL_DOCUMENTS,
            data: ids,
        });
    };

    applySort = (sort: TSort) => (dispatch, getState) => {
        const { Documents } = getState();
        let filter = _.get(Documents, 'filter') || {};
        // let newFilter = { ...filter, sort: SORTS[sort] };
        // if (_.isEmpty(_.get(newFilter, 'sort')))
        //     newFilter = _.omit(newFilter.where, 'sort');
        dispatch({
            type: this.actions.APPLY_SORT,
            data: {
                // filter: newFilter,
                filter,
                sort,
            },
        });
    };

    setChiplist = (list: Array<TChip>) => dispatch =>
        dispatch({
            type: this.actions.SET_CHIP_LIST,
            data: list,
        });

    // Set Header Text for Document Listing
    setListHeader = (label: TListHeader) => dispatch => {
        dispatch({
            type: this.actions.SET_LIST_HEADER,
            data: label,
        });
    };

    removeChip = (key, value, variant: 'ltd' | 'scc' = 'ltd') => async (dispatch, getState) => {
        const { Documents, DocumentTree } = getState();
        const { itemsSelected, idsToSearch, tree } = DocumentTree;

        const searchType = getSearchType(key);

        let filter = _.get(Documents, 'filter') || {};
        let should = _.get(filter, 'should') || {};
        let selectedFacets = _.get(Documents, 'selectedFacets') || {};
        let newFacetValue: Array<any> = [];
        if (key === 'parentIds') {
            const node: TNode = await findNode(value);
            const idsToRemove = node.childrenIds || [];

            const _idsToSearch = _.filter(idsToSearch, id => id !== value);
            dispatch({
                type: ODocumentTree.actions.TREE_ITEM_UNSELECTED,
                data: {
                    itemsSelected: _.isEmpty(_idsToSearch) ? [] : _.difference(itemsSelected, [...idsToRemove, value]),
                    idsToSearch: _idsToSearch,
                },
            });
        }

        const DOCUMENT_STATUS_FACET_KEYS = DOCUMENT_STATUS_FACETS.map(item => item.key);
        if (searchType === 'should') {
            const _item: any = _.find(DOCUMENT_STATUS_FACETS, { key: key });
            let _or = _.get(should, '[0]') || [];
            if (_item) {
                const toRemove = { [_item.searchKey]: _item.value };
                _or = _.remove(_or, toRemove);
            }

            if (DOCUMENT_STATUS_FACET_KEYS.indexOf(key) > -1) {
                // const _item = _.find(DOCUMENT_STATUS_FACETS, { searchKey: _.get(FACET_KEY_VALUE_MAPPING[key], 'key') });
                dispatch(this.removeFacet(DOCUMENT_STATUS_KEY, key));
            }
        } else if (searchType === 'term') {
            dispatch(this.applySearchTerm(''));
        } else if (searchType === 'match_phrase') {
            let field = _.get(filter.where, key);
            if (_.isEmpty(field)) filter = { ...filter, match_phrase: { ..._.omit(filter.match_phrase, key) } };
            else {
                const newFields = _.filter(field, val => val !== value);
                if (_.isEmpty(newFields)) filter = { ...filter, where: { ..._.omit(filter.where, key) } };
                else filter = { ...filter, where: { ...filter.where, [key]: newFields } };
            }
        } else {
            let field = _.get(filter.where, key);
            if (_.isEmpty(value)) {
                filter = { ...filter, where: { ..._.omit(filter.where, key) } };
            } else {
                const newFields = _.filter(field, val => val !== value);
                if (_.isEmpty(newFields)) filter = { ...filter, where: { ..._.omit(filter.where, key) } };
                else filter = { ...filter, where: { ...filter.where, [key]: newFields } };
            }

            // if the chip is from facets, remove from the selectedFacets object
            const FACET_KEYS = FACETS.map(facet => facet.key);
            if (FACET_KEYS.indexOf(key) > -1) dispatch(this.removeFacet(key, value));
        }

        if (_.isEmpty(filter.where)) filter = _.omit(filter, 'where');
        if (_.isEmpty(filter.match_phrase)) filter = _.omit(filter, 'match_phrase');

        dispatch({
            type: this.actions.APPLY_FILTER,
            data: filter,
        });

        if (shouldShowRelevance(filter)) dispatch(this.applySort('Relevance'));
        else dispatch(this.applySort('Latest'));

        dispatch(this.searchDocuments(0, true, undefined, variant));
    };

    // removeFacet = (key, value) => async (dispatch, getState) => {

    // }

    removeFacet = (key, value) => dispatch => {
        if (_.isEmpty(value)) {
            dispatch({
                type: this.actions.LTD_RESET_FACETS,
                data: key,
            });
            // dispatch(this.removeChip(key, value))
            return;
        }
        dispatch({
            type: this.actions.LTD_REMOVE_FACET,
            data: {
                key,
                value,
            },
        });
    };

    applyFacetFilter = () => (dispatch, getState) => {
        const { Documents } = getState();
        let selectedFacets = _.get(Documents, 'selectedFacets') || {};
        let filter = _.get(Documents, 'filter') || {};
        _.forEach(selectedFacets, (value, key) => {
            if (key === DOCUMENT_STATUS_KEY) {
                let should: Array<any> = [];
                _.forEach(value, _key => {
                    should.push({ [FACET_KEY_VALUE_MAPPING[_key].key]: FACET_KEY_VALUE_MAPPING[_key].value });
                });
                filter.should = [should];
                // _.forEach(value, _key => {
                //     filter = { ...filter, where: { ...filter.where, [FACET_KEY_VALUE_MAPPING[_key].key]: FACET_KEY_VALUE_MAPPING[_key].value } }
                // })
            } else {
                filter = { ...filter, where: { ...filter.where, [key]: value } };
            }
            dispatch({
                type: this.actions.APPLY_FILTER,
                data: filter,
            });
        });
    };

    applyFacet = (key, value) => (dispatch, getState) => {
        dispatch({
            type: this.actions.LTD_APPLY_FACET,
            data: {
                key,
                value,
            },
        });

        // if (key === DOCUMENT_STATUS_KEY)
        //     return;

        // const { Documents } = getState();
        // let filter = _.get(Documents, 'filter') || {};
        // let field = _.get(filter.where, key);
        // if (_.isEmpty(field))
        //     filter = { ...filter, where: { ...filter.where, [key]: [value] } }
        // else {
        //     field.push(value)
        //     filter = { ...filter, where: { ...filter.where, [key]: field } }
        // }

        // dispatch({
        //     type: this.actions.APPLY_FILTER,
        //     data: filter
        // })
    };

    applyQuery = query => (dispatch, getState) => {
        const { Documents } = getState();
        let filter = _.get(Documents, 'filter') || {};

        if (_.isBoolean(query)) query = buildFilterFromFormData(_.get(Documents, 'formData'), query);

        _.forEach(FILTER_KEYS, key => {
            filter.where = { ..._.omit(filter.where, key) };
            filter.match_phrase = { ..._.omit(filter.match_phrase, key) };
        });

        filter = {
            ...filter,
            match_phrase: { ...filter.match_phrase, ..._.get(query, 'match_phrase') },
            where: { ...filter.where, ..._.get(query, 'where') },
        };

        let newFilter: any = {
            where: {},
            match_phrase: {},
        };

        _.forEach(filter.where, (value, key) => {
            if (!_.isEmpty(value)) {
                if (key === 'dateCreated') newFilter.where[key] = value;
                else if (!_.isArray(value)) newFilter.where[key] = [value];
                else newFilter.where[key] = value;
            }
        });

        _.forEach(filter.match_phrase, (value, key) => {
            if (!_.isEmpty(value)) newFilter.match_phrase[key] = value;
        });

        if (_.isEmpty(newFilter.where)) newFilter = _.omit(newFilter, 'where');
        if (_.isEmpty(newFilter.match_phrase)) newFilter = _.omit(newFilter, 'match_phrase');

        if (_.get(newFilter, 'where.dateCreated') && _.isEmpty(_.get(filter, 'where.dateCreated')))
            newFilter = { ...newFilter, where: { ..._.omit(newFilter.where, 'dateCreated') } };

        // if (shouldShowRelevance(newFilter))
        //     dispatch(this.applySort("Relevance"));

        dispatch({
            type: this.actions.APPLY_FILTER,
            data: newFilter,
        });
    };

    applyFilter = _filter => (dispatch, getState) => {
        const { Documents } = getState();
        let filter = _.get(Documents, 'filter') || {};
        let newFilter = {};
        newFilter = { ...filter, where: { ...filter.where, ..._filter } };
        dispatch({
            type: this.actions.APPLY_FILTER,
            data: newFilter,
        });
    };

    applySearchTerm = text => dispatch => {
        dispatch({
            type: this.actions.APPLY_SEARCH_TERM,
            data: text,
        });
        if (!_.isEmpty(text)) dispatch(this.applySort('Relevance'));
    };

    showMatchedText = (shouldShow: boolean) => dispatch =>
        dispatch({
            type: this.actions.SET_SHOW_MATCHED_TEXT,
            data: shouldShow,
        });

    setRecordPerPage = (recordPerPage: number) => dispatch =>
        dispatch({
            type: this.actions.LTD_SET_RECORD_PER_PAGE,
            data: recordPerPage,
        });

    getSearchDocsParams = (state, pageNumber = 0, isTreeSearch = false) => {
        const { Documents, DocumentTree } = state;
        const recordPerPage = _.get(Documents, 'recordPerPage') || 10;

        const startFrom = pageNumber * recordPerPage;
        const searchTerm = _.get(Documents, 'searchTerm') || '';
        let searchFilter = _.get(Documents, 'filter') || {};
        let sorting: TSort = _.get(Documents, 'sort');
        let formData = _.get(Documents, 'formData');

        if (!isTreeSearch) {
            searchFilter = {
                ...searchFilter,
                from: startFrom,
                limit: recordPerPage,
                where: _.omit(_.get(searchFilter, 'where'), 'parentId'),
            };
        } else {
            searchFilter = { ...searchFilter, from: startFrom, limit: recordPerPage };
        }

        if (_.isEmpty(searchFilter.where)) searchFilter = _.omit(searchFilter, 'where');

        const isSearchFieldsEmpty =
            _.isEmpty(searchFilter.match_phrase) &&
            _.isEmpty(searchFilter.where) &&
            _.isEmpty(searchTerm) &&
            (sorting === 'Latest' || sorting === 'Relevance');
        if (isSearchFieldsEmpty && sorting === 'Relevance') {
            searchFilter = { ...searchFilter, sort: SORTS['Latest'] };
        }
        const chipList = getChipList(searchFilter, searchTerm);
        // const sortVal = getSortFromChipList(chipList, Documents.userSort, Documents.overrideRelevance)
        const sortVal = this.initialized
            ? getSortFromChipList(chipList, Documents.userSort, Documents.overrideRelevance)
            : sorting;
        const sort = SORTS[sortVal];
        this.initialized = true;

        // check for exact search.
        if (!_.isEmpty(_.get(searchFilter, 'match_phrase["externalId.search"]'))) {
            const val = searchFilter.match_phrase['externalId.search'];
            // if value is wrapped with quotes, remove them and make exact search
            if (val.startsWith('"') && val.endsWith('"')) {
                const key = 'externalId.keyword';
                searchFilter.match_phrase = _.omit(searchFilter.match_phrase, 'externalId.search');
                searchFilter.match_phrase[key] = val.substring(1, val.length - 1);
            }
        }
        return {
            searchTerm,
            searchFilter,
            recordPerPage,
            formData,
            sort,
            isSearchFieldsEmpty,
            pageNumber,
            chipList,
            sortVal,
        };
    };

    shareQuery = () => async (dispatch, getState) => {
        const { Documents, DocumentTree } = getState();
        let sorting: TSort = _.get(Documents, 'sort');
        const currentPage = Documents.currentPage || 0;
        const { itemsSelected, idsToSearch } = _.pick(DocumentTree, ['idsToSearch', 'itemsSelected']) || 10;
        const { formData, recordPerPage, searchFilter, searchTerm } = this.getSearchDocsParams(
            getState(),
            currentPage,
            false
        );
        const {
            Translations: { staticTranslation },
        } = getState() as TState;
        const searchQueryUrl = new SearchQueryModel({
            idsToSearch,
            currentPage,
            itemsSelected,
            mapParentIdsToValues,
            mapvaluesToParentIds,
            formData,
            recordPerPage,
            searchTerm,
            filter: searchFilter,
            sort: sorting,
        });

        try {
            const res = await ShortUrlService.generate(searchQueryUrl.encoded);
            const url = `${window.location.origin}?search=${res.data.short}`;
            utilities.copyLink(url);
            dispatch(
                OApp.showToast(
                    translate(staticTranslation, CARD_PANEL_SEARCH_RESULTLINK_COPIED, utilities.getLocale(), 'ltd') ||
                        'Search result link copied to clipboard',
                    'success'
                )
            );
            // dispatch(OApp.showToast('Search result link copied to clipboard', 'success'));
        } catch (error) {}
    };

    // Search for documents
    // pageNumber: pagination page number
    // isTreeSearch: boolean value -> true if the search is from document tree/facets
    //                                false if the search is from input fields
    searchDocuments = (pageNumber = 0, isTreeSearch = false, shouldRedirect = true, variant: 'ltd' | 'scc' = 'ltd') => (
        dispatch,
        getState
    ) => {
        const { Documents, DocumentTree } = getState();
        const { itemsSelected, idsToSearch } = _.pick(DocumentTree, ['idsToSearch', 'itemsSelected']) || 10;
        const {
            isSearchFieldsEmpty,
            formData,
            recordPerPage,
            chipList,
            sortVal,
            searchFilter,
            sort,
            searchTerm,
        } = this.getSearchDocsParams(getState(), pageNumber, isTreeSearch);
        let sorting: TSort = _.get(Documents, 'sort');

        const searchQueryUrl = new SearchQueryModel({
            idsToSearch,
            currentPage: pageNumber,
            itemsSelected,
            mapParentIdsToValues,
            mapvaluesToParentIds,
            formData,
            recordPerPage,
            searchTerm,
            filter: searchFilter,
            sort: sorting,
        });
        const redirectUrl = HOME_URL().path;
        // const redirectUrl = searchQueryUrl.isInitialState ? HOME_URL().path : `${HOME_URL().path}?search=${searchQueryUrl.encoded}`;
        HISTORY.push(redirectUrl);

        if (isSearchFieldsEmpty && sorting === 'Relevance') {
            dispatch(this.applySort('Latest'));
        }

        if (isSearchFieldsEmpty) dispatch(this.setListHeader('LATEST'));
        else {
            dispatch(this.setListHeader('RESULTS'));
        }
        dispatch({
            type: this.actions.SET_CURRENT_PAGE,
            data: pageNumber,
        });

        dispatch({
            type: this.actions.SEARCHING_DOCUMENTS,
        });

        dispatch({
            type: this.actions.SET_CHIP_LIST,
            data: chipList,
        });

        dispatch(this.applySort(sortVal));

        return new Promise((resolve, reject) => {
            dispatch(
                this.getModelEntity(this.entities[0], {
                    filter: {
                        ...STATIC_FILTER,
                        ...searchFilter,
                        sort,
                        include: LTD_INCLUDES,
                    },
                    org: variant === 'scc' ? 'SCC' : '',
                    term: searchTerm,
                })
            ).then(
                res => {
                    dispatch({ type: this.actions.SEARCH_COMPLETED });
                    if (shouldRedirect) {
                        HISTORY.push(redirectUrl);
                    }
                    return resolve(res);
                },
                err => {
                    dispatch({ type: this.actions.SEARCH_COMPLETED });
                    reject(err);
                }
            );
        });
    };

    exportCsv = () => async (dispatch, getState) => {
        const { searchFilter, searchTerm, sort } = this.getSearchDocsParams(getState(), 0, true);
        let queryFilter = {
            ...STATIC_FILTER,
            ...searchFilter,
            sort,
            include: LTD_INCLUDES,
        };
        const csvUrl = `${Config.get('API_URL')}ltddocs/csv`;
        OLtdDashboard.getCSV(
            csvUrl,
            {
                filter: queryFilter,
                term: searchTerm,
                sort,
                access_token: utilities.getAccessToken(),
            },
            'ltd-export'
        );
    };

    restoreDocuments = (docIds: string[]) => async dispatch => {
        const resp = await utilities.request({
            url: '/ltddocs/restore',
            method: 'POST',
            params: {
                ids: docIds,
            },
        });
        const count = _.get(resp, 'data[0].count') || 0;
        if (count > 0) {
            dispatch({
                type: this.actions.LTD_DOCS_RESTORED,
                data: {
                    docIds,
                },
            });
        }
    };
}

export let ODocuments = new Documents();
