const tableResponsibilitiesFr = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/table-of-responsibilities/" >Table of responsibilities</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:269/header [begin] -->
    <div id="c269" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        Responsabilités de chaque partenaire extérieur :
        </h1></div>
    <!--  Header: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:269/header [end] -->

<!--  CONTENT ELEMENT, uid:331/html [begin] -->
    <div id="c331" class="csc-default">
    <!--  Raw HTML content: [begin] -->
        <div class="WordSection1">
        <object style="width: 100%; height: 90vh;" data="/table-of-responsibilities.pdf">
        <p>Your web browser doesn't have a PDF plugin.
            Instead you can <a href="/table-of-responsibilities.pdf">click here to
            download the PDF file.</a></p>
        </object>
</div>
    <!--  Raw HTML content: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:331/html [end] -->
    </div>`;
export default tableResponsibilitiesFr;
