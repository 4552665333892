import { TAction } from "../../Resources/Model";
import { ODocumentTree } from "./redux-config";
import { Reducer } from "redux";
import { TDocumentTreeState, TNode } from "./@types";
import _ from "lodash";
import { mapValueAndId, ODocuments } from '../LTDDocuments/redux-config'
import utilities from '../../Resources/Utils';
import { node } from "../../../node_modules/@types/prop-types";
import { OLtdDashboard } from "../LtdDashboard/redux-config";


const initialState = {
    tree: [],
    initialTree: [],
    itemsSelected: [],
    idsToSearch: [],
    clipboard: {},
    fetchingNodes: false,
};


// export const filterContentNodes = (tree: Array<TNode>) => {
//     const _tree = [...tree];
//     _.forEach(_tree, node => {
//         if (node.children && !_.isEmpty(node.children)) {
//             node.expanded = true;
//             node.children = setExpandedFlag(node.children);
//         }
//     })
//     return _tree;
// }


export const setExpandedFlag = (tree: Array<TNode>) => {
    const _tree = [...tree];
    _.forEach(_tree, node => {
        if (node.children && !_.isEmpty(node.children)) {
            node.expanded = true;
            node.children = setExpandedFlag(node.children);
        }
    })

    return _.filter(_tree, node => node.nodeType !== 'CONTENT');
}





export const orderNodes = (unorderedList: TNode[], orderIds: string[]) => {
    const childrenOrder = orderIds || [];
    let orderedChildren: TNode[] = []
    _.forEach(childrenOrder, id => {
        const _node = _.find(unorderedList, { id });
        if (_node)
            orderedChildren.push(_node);
    })
    return orderedChildren;
}


export const appendChildren = (tree: Array<TNode>, children: Array<TNode>, parentId: string, replace = true) => {
    let _tree = [...tree];
    _.forEach(_tree, node => {
        if (!_.isEmpty(node.children)) {
            appendChildren(node.children as Array<TNode>, children, parentId, replace)
        }
        if (node.id === parentId) {
            const childrenOrder = node.childrenIds || [];
            let orderedChildren = orderNodes(children || [], childrenOrder)
            node.children = replace ? orderedChildren : [...(node.children || []), ...children];
            node.menuChildren = node.children.length;
        }
    })
    return _tree;
}
export const updateTree = (tree: Array<TNode>, children: Array<TNode>, parentId: string) => {
    let _tree = [...tree];
    _.forEach(_tree, node => {
        if (node.id === parentId) {
            node.children = children; return _tree
        }
        if (!_.isEmpty(node.children)) {
            return appendChildren(node.children as Array<TNode>, children, parentId)
        }
    })
    return _tree;
}



const removeChildren = (tree: Array<TNode>, parentId: string) => {
    let _tree = [...tree];
    _.forEach(_tree, node => {
        if (node.id === parentId) {
            node.children = [];
            return node;
        }
        if (!_.isEmpty(node.children)) {
            node.children = removeChildren(node.children as Array<TNode>, parentId);
        }
    })
    return _tree;
}




export const getAllChildren = (node: TNode, children: Array<TNode> = []) => {
    let _children = children
    _.forEach(node.children, (child: TNode) => {
        _children.push(child);
        if (child.children)
            _children = getAllChildren(child, _children);
    })
    return _children;
}

export const getParent = (id: string, tree: Array<TNode>) => {

    for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        // console.log("node1", node)
        if (node.childrenIds && _.findIndex(node.childrenIds, childId => childId === id) >= 0) {
            // console.log("node", node);
            return node;
        }
        else if (node.children && node.children.length) {
            let parent = getParent(id, node.children);
            if (parent) {
                // console.log("parent", parent);
                return parent;
            }
        }
    }
    // console.log("undefined", undefined);
}
export const getAllSiblings = async (node: TNode) => {
    if (node.level) {
        const res = await utilities.request({
            url: '/nodes',
            params: {
                filter: { where: { parentId: node.parentId } }
            }
        });
        return res.data
    }
    return [];
}


export const findNode = async (id: string) => {

    const res = await utilities.request({
        url: '/nodes',
        params: {
            filter: { where: { id } }
        }
    })
    return res.data[0];
}


export const getAllAncestors = (tree: Array<TNode>, root: Array<TNode>, id: string, ancestors: Array<TNode> = []) => {
    let _ancestors = getAncestors(tree, root, id, ancestors);
    return _.filter(_ancestors, node => node.id !== id);
}

export const getAncestors = (tree: Array<TNode>, root: Array<TNode>, id: string, ancestors: Array<TNode> = []) => {
    let _ancestors = ancestors;
    _.forEach(root, (node: TNode) => {
        if (node.id === id) {
            _ancestors.push(node);
            if (node.parentId)
                _ancestors = getAncestors(tree, tree, node.parentId, _ancestors);
        }
        else if (node.children)
            _ancestors = getAncestors(tree, node.children, id, _ancestors);
    })
    return _ancestors;
}




export const traverseTree = (path: string[], tree: TNode[]) => {
    let currTree = tree;
    path.forEach(id => {
        const node = _.find(currTree, { id });
        if (!node)
            return false;
        currTree = node.children ? node.children : [];
    })
    return currTree;
}



export const DocumentTree: Reducer<TDocumentTreeState> = (state = initialState, action: TAction) => {
    const {
        FETCHED_TREE_NODES,
        FETCHED_CHILDREN,
        REMOVED_CHILDREN,
        RESET_TREE,
        TREE_ITEM_SELECTED,
        TREE_ITEM_UNSELECTED,
        LTD_NODE_CREATED,
        LTD_NODE_UPDATED,
        LTD_TREE_EXPANDED_WITH_ID,
        SET_DOC_IN_CLIPBOARD,
        RESET_CLIPBOARD,
        LTD_NEW_TREE_FETCHED,
        FETCHING_NODES_TREE,
        NODES_FETCHED_WITH_CHILDREN
    } = ODocumentTree.actions;

    const {
        LTD_PERMITTED_NODES_FETCHED,
    } = OLtdDashboard.actions;

    const {
        LTD_RESET_ALL_FILTERS,
        INIT_QUERY_PARAMS
    } = ODocuments.actions

    // NOTE ----------------------------------------------------------------------------------------------------
    // itemsSelected: Array<nodeIds>  -> list of nodes which is to be shown as checked in UI                    |
    // idsToSearch: Array<nodeIds>    -> Same as [itemsToSearch] except that, it stores only the parentIds.     |
    //----------------------------------------------------------------------------------------------------------
    switch (action.type) {
        case LTD_NODE_UPDATED: {
            // const currTree = traverseTree(_.get(action.data, 'parentIds'), state.tree);
            // const newChildren = utilities.updateItemList(currTree, action.data, 'UPDATE');
            // const tree = updateTree(state.tree, newChildren, _.get(action.data, 'parentId'))

            // return { ...state, tree: [...tree] }
            return state;
        }
        case LTD_NODE_CREATED: {
            // const newTree = appendChildren(state.tree, [action.data.children] || [], action.data.parentId, false);
            // console.log('New tree', newTree);
            // return { ...state, tree: _.cloneDeep(newTree) };
            return state;
        }
        case TREE_ITEM_UNSELECTED:
        case TREE_ITEM_SELECTED:
            return { ...state, itemsSelected: action.data.itemsSelected, idsToSearch: action.data.idsToSearch };

        case RESET_TREE: {
            return { ...state, tree: _.cloneDeep(state.initialTree) };
        }
        case REMOVED_CHILDREN: {
            return { ...state, tree: removeChildren(state.tree, action.data) };
        }
        case LTD_NEW_TREE_FETCHED: {
            return { ...state, tree: action.data };
        }
        case FETCHED_CHILDREN: {
            let itemsSelected = state.itemsSelected;
            // If the parent is already present in [itemsSelected],
            // make the children as checked. i.e put all the childrenIds in [itemsSelected]s
            // const children = _.filter(action.data.children, child => child.nodeType === 'MENU');
            // if (_.indexOf(state.itemsSelected, action.data.parentId) > -1)
            //     itemsSelected = [...itemsSelected, ..._.map(children, node => node.id)]
            if (_.findIndex(itemsSelected, id => id === action.data.parentId) >= 0) {
                let menuNodes = _.filter(action.data.children, child => child.nodeType === 'MENU');
                let ids = _.map(menuNodes, 'id');
                itemsSelected = [...itemsSelected, ...ids];
                _.forEach(menuNodes, mapValueAndId);
            }

            return {
                ...state,
                tree: appendChildren(state.tree, action.data.children, action.data.parentId),
                itemsSelected,
            };
        }
        case FETCHED_TREE_NODES: {
            // here we store a copy of initial tree just in case the user wants to collapse/reset the tree
            return { ...state, tree: action.data, initialTree: _.cloneDeep(action.data) };
        }
        case FETCHING_NODES_TREE: {
            return { ...state, fetchingNodes : true };
        }
        case NODES_FETCHED_WITH_CHILDREN: {
            return { ...state, fetchingNodes: false };
        }
        case LTD_TREE_EXPANDED_WITH_ID: {
            return { ...state, tree: action.data };
        }
        case LTD_PERMITTED_NODES_FETCHED: {
            if (_.isEmpty(state.tree)) return state;
            let tree = _.clone(state.tree);
            ODocumentTree.assignNodesStatus(tree, action.data);
            return { ...state, tree };
        }
        case SET_DOC_IN_CLIPBOARD: {
            if (_.isEmpty(_.get(action, 'data.document')) || !_.get(action, 'data.action')) return state;
            return { ...state, clipboard: action.data };
        }
        case RESET_CLIPBOARD:
            return { ...state, clipboard: {} };
        case LTD_RESET_ALL_FILTERS: {
            return { ...state, idsToSearch: [], itemsSelected: [] };
        }
        case INIT_QUERY_PARAMS: {
            return {
                ...state,
                idsToSearch: _.get(action.data, 'idsToSearch') || [],
                itemsSelected: _.get(action.data, 'itemsSelected') || [],
            };
        }
        default:
            return { ...state };
    }
}