import { Reducer } from 'redux';
import TranslationModel from '../../Models/Translations';
import { TAction } from '../../Resources/Model';
import { OTranslation } from './redux-config';
import translations from './translations.json';

export interface TTranslationsState {
    staticTranslation: Record<string, any>;
}

const initialState: TTranslationsState = {
    staticTranslation: {},
};

export const Translations: Reducer<TTranslationsState, TAction> = (state = initialState, action) => {
    const { GET_STATIC_TRANSLATIONS, PATCH_STATIC_TRANSLATIONS } = OTranslation.actions;
    switch (action.type) {
        case GET_STATIC_TRANSLATIONS:
            return {
                ...state,
                staticTranslation: TranslationModel.mergeTrees(translations, action.data),
            };
        case PATCH_STATIC_TRANSLATIONS:
            return { ...state, staticTranslation: action.data };
        default:
            return state;
    }
};
