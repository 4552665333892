const coordinationFr = `
<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/background/" >Background</a></p></div>
<div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree">
<i class="glyphicon glyphicon-collapse-down"></i></div> 
<a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i>
</a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
<div id="c258" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
Coordination, membres du CMN et partenaires institutionnels
</h1></div>
<!--  Header: [end] -->			
<!--  Text: [begin] -->
<p class="bodytext"><span class="text_">
Le Centre for International Law Research and Policy 
(</span><a href="https://www.cilrap.org/purpose/" class="text_1">CILRAP</a><span class="text_">) 
gère la base de données des Outils juridiques de la CPI pour la Cour pénale internationale conformément à un accord décennal conclu entre la Cour et CILRAP le 9 mars 2021. 
 </span><a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> est directeur de CILRAP et coordonnateur du projet d’Outils juridiques. <a href="https://www.cilrap.org/bais/" target="_blank">Devasheesh Bais</a> en est le coordonnateur adjoint.
  </p>
<p class="bodytext"><span class="text_">
CILRAP a déployé une équipe composée de plus de 40 membres du CMN pour aider à la mise à jour et à la maintenance de la base de données des Outils juridiques. Les membres du CMN et les partenaires institutionnels mentionnés ci-dessous contribuent bénévolement à la base 
</span><i>de données des</i><span class="text_">&nbsp;
Outils juridiques en tant que bien public numérique. Les membres du CMN constituent un groupe de personnes diverses qui contribuent à la base de données à plus long terme, en vue de servir l’intérêt public. Une liste des membres - actuels et anciens - du CMN se trouve </span><a target="_blank" href="https://www.casematrixnetwork.org/about-us/cmn-fellows/" class="text_3">ici</a>
  <span class="text_">.
  Celles et ceux qui souhaitent devenir membres du CMN pour apporter leur contribution peuvent envoyer un courriel avec leur CV mis à jour en pièce jointe à <a href="mailto:info@cilrap.org">info@cilrap.org</a>. </span>
   Les candidats sont sélectionnés selon les critères suivants :
    (a) précision, 
    (b) motivation à s’engager dans la durée, et
     (c)  intérêt en matière de transfert de connaissances et de renforcement des capacités.
      </span></p>
<p class="bodytext">
Vous trouverez ci-dessous certains des accords de coopération qui ont été conclus entre la Cour et des partenaires externes du projet d’Outils juridiques de la CPI. Un roulement s’effectue naturellement au sein de ce groupe de partenaires institutionnels. Parmi les anciens partenaires, on compte le Centre norvégien pour les droits de l’homme, le T.M.C. Asser Institute et TRIAL. Ces dernières années, les membres du CMN ont assumé une part plus importante des tâches de gestion de la base de données des Outils juridiques de la CPI et de mise à jour de son contenu. L’équipe de coordination est reconnaissante des efforts déployés par les partenaires extérieurs et les membres du CMN.
</p>
<p class="bodytext">
<div style="margin-bottom:8px;">
<a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/051221_Intention_Agreement_Marburg__countersigned.pdf" class="text_3">051221_Intention_Agreement_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf" class="text_3">071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090720_LT_CA_Korea_University__countersigned_.pdf" class="text_3">090720_LT_CA_Korea_University__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090724_LT_CA_Nottingham__countersigned_.pdf" class="text_3">090724_LT_CA_Nottingham__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/091217_LT_CA_WRHWLI_countersigned.pdf" class="text_3">091217_LT_CA_WRHWLI_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100325_LT_CA_Goettingen__countersigned.pdf" class="text_3">100325_LT_CA_Goettingen__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf" class="text_3">100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100921_LT_CA_Salzburg_countersigned.pdf" class="text_3">100921_LT_CA_Salzburg_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110117_LT_CA_University_of_Richmond.pdf" class="text_3">110117_LT_CA_University_of_Richmond.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110610_LT_CA_Canadian_partners.pdf" class="text_3">110610_LT_CA_Canadian_partners.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111017_LT_Co-operation_Agreement_CEEI.pdf" class="text_3">111017_LT_Co-operation_Agreement_CEEI.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111025_LT_Co-operation_Agreement_UTDT.pdf" class="text_3">111025_LT_Co-operation_Agreement_UTDT.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/140916_Co-operation_Agreement_Jindal_Global_University.pdf" class="text_3">140916_Co-operation_Agreement_Jindal_Global_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150424_LTP_Agreement_Ain_Shams_countersigned.pdf" class="text_3">150424_LTP_Agreement_Ain_Shams_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150513_Agreement_UKZN-UCT__countersigned.pdf" class="text_3">150513_Agreement_UKZN-UCT__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf" class="text_3">150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Nottingham.pdf" class="text_3">150918_LT_CA_Nottingham.pdf</a></div>
<div style="margin-bottom:8px;"><a lang="nb" target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Stanford_University.pdf" class="text_3">150918_LT_CA_Stanford_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150921_LT_CA_NUS_Faculty_of_Law.pdf" class="text_3">150921_LT_CA_NUS_Faculty_of_Law.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151007_LT_CA_PKU_Law_School__countersigned.pdf" class="text_3">151007_LT_CA_PKU_Law_School__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_Canadian_partners__countersigned.pdf" class="text_3">151019_LT_CA_Canadian_partners__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_ULB.pdf" class="text_3">151019_LT_CA_ULB.pdf</a></div>
</p>

<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
</div>
`;
export default coordinationFr;
