import { OLtdDashboard } from "../redux-config"
import moment from 'moment';



export interface TImporterLogFilter {
    importer?: string
    gte?: string
    lte?: string

}



export const DEFAULT_IMPORTER_LOG_FILTER: TImporterLogFilter = {

}

const DATE_FORMAT = 'YYYY-MM-DD'


const FILTER_KEYS: (keyof TImporterLogFilter)[] = ['importer', 'gte', 'lte'];

export const ImporterLogFilter = {

    applyFilter: (filter: TImporterLogFilter) => async dispatch => {
        dispatch(ImporterLogFilter.setFilter(filter))
        const whereFilter = ImporterLogFilter.getWhereFilterFromAppliedFilter(filter);
        dispatch(OLtdDashboard.fetchImporterLogs(0, false, whereFilter));
    },

    setFilter: (filter: TImporterLogFilter) => async dispatch => {
        dispatch({
            type: OLtdDashboard.actions.LTD_IMPORTER_LOGS_SET_FILTER,
            data: filter
        })
    },

    resetFilter: () => async dispatch => {
        dispatch({
            type: OLtdDashboard.actions.LTD_IMPORTER_LOGS_SET_FILTER,
            data: { ...DEFAULT_IMPORTER_LOG_FILTER }
        })
    },

    getWhereFilterFromAppliedFilter: (filter: TImporterLogFilter) => {
        let whereFilter: Record<string, any> = {};

        FILTER_KEYS.forEach(k => {
            if (filter[k] && filter[k] === 'all')
                delete filter[k];
        })


        if (filter.importer)
            whereFilter.importer = filter.importer


        if (filter.gte && filter.lte)
            whereFilter.created = { between: [moment(filter.gte).format(DATE_FORMAT), moment(filter.lte).add(1, 'd').format(DATE_FORMAT)] };

        else {
            if (filter.lte)
                whereFilter.created = { lte: moment(filter.lte).format(DATE_FORMAT) }
            if (filter.gte)
                whereFilter.created = { gte: moment(filter.gte).format(DATE_FORMAT) }
        }
        return whereFilter
    },

    getCSVWhereFilterFromAppliedFilter: (filter: TImporterLogFilter) => {
        let whereFilter: Record<string, any> = {};
        FILTER_KEYS.forEach(k => {
            if (filter[k] && filter[k] === 'all')
                delete filter[k];
        })


        if (filter.importer)
            whereFilter.importer = filter.importer


        if (filter.gte && filter.lte)
            whereFilter.created = { '$gte': moment(filter.gte).format(DATE_FORMAT), '$lte': moment(filter.lte).add(1, 'd').format(DATE_FORMAT) };
        else {
            if (filter.lte)
                whereFilter.created = { '$lte': moment(filter.lte).format(DATE_FORMAT) }
            if (filter.gte)
                whereFilter.created = { '$gte': moment(filter.gte).format(DATE_FORMAT) }
        }
        // IF the filter is empty; restict csv  to only 1 month
        if (Object.keys(filter).length === 0) {
            whereFilter.created = {
                '$gte': moment().subtract(1, 'M').format(DATE_FORMAT)
            }
        }
        return whereFilter
    },
}
