import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk'
import rootReducer from './RootReducer'

import config from './Resources/Config';
import { AppContextProvider } from './Contexts/AppContext';
import { ThemeContextProvider } from './Contexts/ThemeContext';
import { TranslationContextProvider } from './Features/Translations/TranslationContext';





if (!config.get('CONSOLE_LOGGING')) {
    console.log = () => { }
}
const middleware = (config.get('REDUX_LOGGER') ? applyMiddleware(thunk, logger) : applyMiddleware(thunk))
const rxStore = createStore(
    rootReducer,
    middleware
)




ReactDOM.render(
    <Provider store={rxStore} >
        <TranslationContextProvider>
            <AppContextProvider>
                <ThemeContextProvider>
                    <App />
                </ThemeContextProvider>
            </AppContextProvider>
        </TranslationContextProvider>
    </Provider>,
    document.getElementById('root')
);
