import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, Grid, LinearProgress, Select, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import Theme from '../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Folder from './Folder';
import { TReadingListDetail, TReadingListSort } from '../../Features/ReadingList/@types';
import Document from './Document';
import utilities from '../../Resources/Utils';
import CLDListItem from '../../Components/CLDList/ListItem';
import CLDDecisionItem from '../../Components/CLDList/DecisionListItem';
import LTDListItem from '../../Components/LTDList/ListItem';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { OApp } from '../../RootReducer/AppReducer';



interface Props extends WithStyles<typeof STYLES>, RouteComponentProps, IDispatchProps { }

interface IDispatchProps {
    fetchDocuments: (id: string, order?: TReadingListSort) => Promise<any>
    removeFromReadingList: (listId: string, docId: string) => void
    confirmationDialog: (message: string, onConfirm: () => void, onCancel: () => void) => void
}

interface IStateProps {
    currentReadingList: Array<TReadingListDetail>
}



const SORT_CONFIG: Array<{ label: string, order: string }> = [
    { label: "Recently added to reading list", order: "created-rev" },
    { label: "Recently modified", order: "doc.updated" },
    { label: "Newest", order: "doc.dateCreated-rev" },
    { label: "Oldest", order: "doc.dateCreated" },
]

// const SORT_CONFIG: Array<{ label: string, order: TReadingListSort }> = [
//     { label: "Recently added to reading list", order: "created DESC" },
//     { label: "Recently modified", order: "doc.updated DESC" },
//     { label: "Newest", order: "doc.dateCreated DESC" },
//     { label: "Oldest", order: "doc.dateCreated ASC" },
// ]



class DocumentsList extends Component<Props & IStateProps> {
    state = {
        title: '',
        sortKey: 'created-rev',
        loading: false
    }

    componentDidMount = async () => {
        const readingListId = _.get(this.props.match, 'params.id');

        this.setState({ loading: true });
        await this.props.fetchDocuments(readingListId, 'created DESC');
        this.setState({ loading: false });

        utilities.request({
            url: `/readinglists/${readingListId}`
        }).then(
            res => this.setState({ title: _.get(res.data, 'name') || '' })
        )
    }

    removeFromList = (details: TReadingListDetail) => {
        if (_.isEmpty(details) && _.isEmpty(details.listId))
            return;
        this.props.confirmationDialog(
            'Remove from reading list?',
            () => this.props.removeFromReadingList(details.listId, details.id),
            () => null
        );
    }


    handleChange = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        const sortKey = event.target.value
        this.setState({ sortKey });
    }
    // handleChange = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
    //     const sortOrder = event.target.value as TReadingListSort
    //     const readingListId = _.get(this.props.match, 'params.id');
    //     this.props.fetchDocuments(readingListId, sortOrder);
    //     this.setState({ sortOrder });
    // }


    render() {
        const { classes } = this.props;
        const { sortKey, loading = false } = this.state;
        const readingListId = _.get(this.props.match, 'params.id');
        const currentReadingList: Array<TReadingListDetail> = _.sortBy(this.props.currentReadingList, _.split(sortKey, '-')[0]);
        const reverse = _.split(sortKey, '-').length > 1;

        console.log("reading list", currentReadingList)
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div style={{ flex: '1 1 0' }} />
                    {/* <Typography>{_.upperCase(this.state.title)}</Typography> */}
                    <div className="align-center">
                        <Typography>Sorted By:&nbsp;</Typography>
                        <Select
                            disableUnderline
                            value={this.state.sortKey}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'sort',
                                id: 'sort'
                            }}
                        >

                            {
                                _.map(SORT_CONFIG, (sortObj, i) => (
                                    <MenuItem key={i} value={sortObj.order}>
                                        <Typography> {sortObj.label}</Typography>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                </div>

                {
                    loading ?
                        <LinearProgress color="secondary" style={{ width: '100%' }} /> :
                        <div className={classes.readingListContainer}>
                            <Grid container className={classes.grid}>
                                <Typography variant="headline" color="primary" style={{ marginBottom: 10 }} gutterBottom>{this.state.title}</Typography>
                                {

                                    _.map(reverse ? _.reverse(currentReadingList) : currentReadingList, details => (
                                        <Grid key={details.id} className={classes.item} item md={12}>
                                            {/* <Document details={details} /> */}
                                            {
                                                details.doc ?
                                                    (details.docType === 'CldDoc' ?
                                                        <CLDListItem item={{ hit: (details.doc as TCLDDocument) }} onRemoveFromReadingList={() => this.removeFromList(details)} readingListItem={true} /> :
                                                        ((details.type && details.type === 'ltd') ?
                                                            <LTDListItem item={{ hit: (details.doc as TDocument) }} onRemoveFromReadingList={() => this.removeFromList(details)} readingListItem={true} /> :
                                                            <CLDDecisionItem item={{ hit: (details.doc as TCLDDocument) }} onRemoveFromReadingList={() => this.removeFromList(details)} readingListItem={true} />
                                                        )) : null
                                            }
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            {
                                _.isEmpty(currentReadingList) ?
                                    <Typography align="center" variant="h6">Reading list empty.</Typography> : null

                            }
                        </div>
                }



            </div>
        )
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.ReadingList, 'currentReadingList')
})

const mapDispatchToProps = dispatch => ({
    fetchDocuments: async (id, order?: TReadingListSort) => dispatch(OReadingList.fetchDocumentsInReadingList(id, order)),
    removeFromReadingList: (listId: string, docId: string) => dispatch(OReadingList.removeFromReadingList(listId, docId)),
    confirmationDialog: (message: string, onConfirm, onCancel) => dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel))
})

const STYLES = theme => createStyles({
    root: {

    },
    header: {
        height: 50,
        display: 'flex',
        padding: '0px 15px',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: Theme.Colors.Fourth,
        boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.16)}`,
        '& p': {
            color: 'white',
        },
        '& > div': {
            display: 'flex'
        },

    },
    readingListContainer: {
        boxSizing: 'border-box',
        padding: '50px 0px',
        height: 'calc(100vh - 50px)',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            // width: '100vw',
            paddingBottom: 150,
        }
    },
    grid: {
        width: 800,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            '& > h5': {
                marginLeft: 12
            }
        }
    },
    item: {
        marginBottom: 24
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DocumentsList))