import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    DialogTitle,
    Typography,
    Button,
    Checkbox,
    CircularProgress,
    Theme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import DialogHeader from './DialogHeader';
import { OAuth } from './redux-config';
import { TDialogVariant } from './@types';
import { Link } from 'react-router-dom';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';
import {
    AUTHENTICATION_ALREADY_A_REGISTERED_USER,

} from '../Translations/translationKeys.cld';
import {
    MENU_LIST_SIGN_UP, GENERAL_EMAIL,
    GENERAL_LOGIN,
    GENERAL_NAME,
    GENERAL_PASSWORD,
    GENERAL_REPEAT_PASSWORD,
    GENERAL_SUBMIT,
    MENU_LIST_EMAIL,
    MENU_LIST_LOGIN,
    TERM_TEXT_I_AGREE,
    TERM_AND_CONDESION_TEXT,
    TERM_AND_TEXT,
    TERM_PRIVACY_POLICY_TEXT,
    TERM_BY_REGISTER_TEXT,
    TERM_DOCUMENT_CONTAIN_TEAM_TEXT,
} from '../Translations/translationKeys.ltd';


interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    WithTranslationProps { }

interface IDispatchProps {
    openAuthDialog: Function;
    submit: Function;
    closeDialog: Function;
}

class Signup extends Component<Props> {
    state = {
        loading: false,
        checked: false,
        error: '',
        formData: {
            password: '',
        },
    };

    componentDidMount = () => {
        ValidatorForm.addValidationRule('isPasswordMatch', value => {
            if (value !== this.state.formData.password) {
                return false;
            }
            return true;
        });
    };

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    login = () => {
        this.props.openAuthDialog('login');
    };

    submit = () => {
        if (this.state.checked) {
            this.setState({ loading: true });
            this.props
                .submit(_.omit(this.state.formData, 'repeatPassword'))
                .then(
                    res => {
                        this.setState({ loading: false, error: '' });
                        this.props.openAuthDialog('login');
                    },
                    err => {
                        this.setState({
                            loading: false,
                            error: _.get(err, 'response.data.error.message'),
                        });
                    }
                );
        } else {
            this.setState({ error: 'Please accept the Terms to proceed' });
        }
    };

    handleCheck = (e, checked) => {
        this.setState({ checked });
    };
    render() {
        const { classes, getTranslation } = this.props;
        const { formData, checked, error, loading } = this.state;
        return (
            <div className={classes.root}>
                <DialogHeader title={getTranslation(MENU_LIST_SIGN_UP) || 'SignUp'} />
                <div className={classes.formContent}>
                    <Typography className={classes.errorMessage}>
                        {error}
                    </Typography>
                    <ValidatorForm onSubmit={this.submit}>
                        <TextValidator
                            fullWidth
                            label={getTranslation(GENERAL_NAME) || 'Name'}
                            name='name'
                            validators={['required']}
                            errorMessages={['Required']}
                            value={_.get(formData, 'name') || ''}
                            onChange={this.handleChange}
                        />
                        <TextValidator
                            fullWidth
                            label={getTranslation(MENU_LIST_EMAIL)}
                            name='email'
                            validators={['required', 'isEmail']}
                            errorMessages={['Required', 'Incorrect Email']}
                            value={_.get(formData, 'email') || ''}
                            onChange={this.handleChange}
                        />
                        <TextValidator
                            fullWidth
                            label={
                                getTranslation(GENERAL_PASSWORD) || 'Password'
                            }
                            name='password'
                            type='password'
                            validators={['required']}
                            errorMessages={['Required']}
                            value={_.get(formData, 'password') || ''}
                            onChange={this.handleChange}
                        />
                        <TextValidator
                            fullWidth
                            label={
                                getTranslation(GENERAL_REPEAT_PASSWORD) ||
                                'Repeat Password'
                            }
                            type='password'
                            name='repeatPassword'
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={[
                                'Required',
                                'Password does not match',
                            ]}
                            value={_.get(formData, 'repeatPassword') || ''}
                            onChange={this.handleChange}
                        />

                        <div className={classes.agreement}>
                            <Checkbox
                                color='primary'
                                checked={checked}
                                onChange={this.handleCheck}
                            />
                            <Typography variant='caption'>
                                {/* I agree to the&nbsp; */}
                                {getTranslation(TERM_TEXT_I_AGREE)}
                                {' '}
                                <Link
                                    to={'/footer/terms-of-use'}
                                    onClick={e => this.props.closeDialog()}
                                    className={classes.clickable}
                                >
                                    {getTranslation(TERM_AND_CONDESION_TEXT)}
                                    {' '}
                                    {/* Terms &amp; Conditions */}
                                </Link>
                                {getTranslation(TERM_AND_TEXT)}
                                {/* &nbsp;and&nbsp; */}
                                <Link
                                    to={'/privacy'}
                                    onClick={e => this.props.closeDialog()}
                                    className={classes.clickable}
                                >
                                    {/* Privacy Policy */}
                                    {getTranslation(TERM_PRIVACY_POLICY_TEXT)}
                                    {' '}
                                </Link>
                                {/* . By registering an ICC Legal Tools Database
                                (‘LTD’) user-account, you consent to the LTD
                                storing your name and e- mail address for the
                                purpose of technically supporting your account,
                                until you request that your account be deleted
                                (by simply sending an e-mail to the LTD
                                Co-ordination Team at{' '} */}
                                {getTranslation(TERM_BY_REGISTER_TEXT)}  {' '}
                                <a
                                    className={classes.clickable}
                                    href='mailto:info@cilrap.org'
                                >
                                    info@cilrap.org
                                </a>
                                {/* . Only the LTD Co-ordination Team may see the
                                name and e-mail address of registered users.
                                Questions (including corrections) concerning
                                your LTD user- account can be addressed to{' '} */}
                                {getTranslation(TERM_DOCUMENT_CONTAIN_TEAM_TEXT)}
                                <a
                                    className={classes.clickable}
                                    href='mailto:info@cilrap.org'
                                >
                                    info@cilrap.org
                                </a>
                                .
                            </Typography>
                        </div>

                        <Button fullWidth color='primary' type='submit'>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                getTranslation(GENERAL_SUBMIT)
                            )}
                        </Button>
                    </ValidatorForm>

                    <div className={classes.footer}>
                        <Typography variant='caption'>
                            {getTranslation(
                                AUTHENTICATION_ALREADY_A_REGISTERED_USER
                            )}
                        </Typography>
                        <Typography
                            variant='caption'
                            onClick={this.login}
                            className={classes.clickable}
                        >
                            {getTranslation(MENU_LIST_LOGIN).toUpperCase()}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    openAuthDialog: (variant: TDialogVariant) =>
        dispatch(OAuth.openAuthDialog(variant)),
    closeDialog: () => dispatch(OAuth.closeAuthDialog()),
    submit: formData => dispatch(OAuth.submitSignup(formData)),
});

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: 500,
            },
            paddingBottom: 30,
        },
        header: {
            height: 44,
            display: 'flex',
            background: theme.palette.primary.main,
            alignItems: 'center',
            paddingLeft: 15,
            justifyContent: 'space-between',
            '& h6': {
                textTransform: 'uppercase',
                color: THEME.Colors.Grayscale.White,
            },
            '& i': {
                color: THEME.Colors.Grayscale.White,
            },
        },
        formContent: {
            padding: '0px 50px',
            '& form': {
                marginTop: 8,
            },
            '& > form > div': {
                margin: '10px 0px',
            },
            '& button': {
                background: theme.palette.primary.main,
                color: 'white',
                transition: '200ms linear',
                '&:hover': {
                    background: theme.palette.primary.light,
                },
            },
        },
        agreement: {
            display: 'flex',
            alignItems: 'flex-start',
            '& > span': {
                padding: 0,
                marginRight: 6,
            },
        },
        footer: {
            marginTop: 26,
            display: 'flex',
            justifyContent: 'space-between',
        },
        clickable: {
            textDecoration: 'none',
            display: 'inline-block',
            color: theme.palette.primary.dark,
            cursor: 'pointer',
        },
        errorMessage: {
            color: THEME.Colors.Error,
            marginTop: 10,
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(Signup)));
