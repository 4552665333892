import React, { Component, Fragment } from 'react'
import { createStyles, WithStyles, withStyles, Paper, Typography, Checkbox, Button, Collapse, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TCLDDocument, TMetadata, TSearchResult } from '../../Features/CLDDocuments/@types';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import { ODocuments, DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../Resources/Theme';
import { OApp, TToastVariant, TAppState } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { HISTORY } from '../../screens/RootLayout';
import Bookmark from '../Bookmark';
import { TReadingList } from '../../Features/ReadingList/@types';
import Text from '../Text';
import { TState } from '../../RootReducer/index';
import { value } from 'popmotion';
import moment from 'moment';
import classNames from 'classnames';
import { APPEAL_CONFIG, OCLDDocuments, getDecisionMetaDetail, LIST_ITEM_COLOR } from '../../Features/CLDDocuments/redux-config';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { getSearchResultHighlightConfig, orderHighlightByFields } from './listingUtils';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { CARD_COPY_URL, GENERAL_DOWNLOAD, CARD_METADATA, CARD_REMOVE_FROM_LIST, CARD_SEE_FULL, TOAST_LINK_COPIED_TO_CLIPBOARD } from '../../Features/Translations/translationKeys.cld';
import { CldField } from '../../Services/CldFields.types';



interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {
    readingListItem?: boolean
    item: TSearchResult
    itemNumber?: number
    onRemoveFromReadingList?: () => void
}

interface IStateProps {
    showMatchedText: boolean,
    isDecisionSearch?: boolean,
    checkedIds: Array<string>
    cldFields: CldField[]
}

interface IDispatchProps {
    openDocument: (id: string, closeRedirect?: string) => void,
    showToast: Function,
    toggleCheck: (id: string) => void
}

// const labels = [
//     { name: 'Date created', key: "Date created" },
//     { name: 'Language(s)', key: 'metadata_language_s' },
//     { name: 'External identifier', key: 'metadata_source' },
//     { name: 'Persistent URL', key: 'metadata_persistent_url' },
// ]

const labels = [
    "Date created",
    "Language(s)",
    "Source",
    "Persistent URL",

]


type TAction = 'COPY URL' | 'SEE FULL' | 'DOWNLOAD' | 'READ LATER' | 'METADATA' | 'REMOVE FROM LIST'

const actions: Array<TAction> = [
    'COPY URL',
    'SEE FULL',
    'METADATA',
    'DOWNLOAD',
    'READ LATER',
]
const readingListActions: Array<TAction> = [
    'COPY URL',
    'SEE FULL',
    'METADATA',
    // 'DOWNLOAD',
    'REMOVE FROM LIST'
]

class DecisionListItem extends Component<Props & IStateProps> {


    openDocument = () => {
        const doc = this.props.item.hit;
        const closeRedirect = this.props.readingListItem ? window.location.pathname : undefined
        if (doc) {
            const id = doc.slug || doc.id
            this.props.openDocument(id, closeRedirect);
            HISTORY.push(`/decision/${id}/`);
        }
    }

    getTranslatedLabel = (action: TAction) => {
        const { getTranslation } = this.props;
        switch (action) {
            case 'COPY URL': return getTranslation(CARD_COPY_URL);
            case 'DOWNLOAD': return getTranslation(GENERAL_DOWNLOAD);
            case 'METADATA': return getTranslation(CARD_METADATA);
            case 'READ LATER': return getTranslation(CARD_METADATA);
            case 'REMOVE FROM LIST': return getTranslation(CARD_REMOVE_FROM_LIST);
            case 'SEE FULL': return getTranslation(CARD_SEE_FULL);
            default: return action
        }
    }


    actionClicked = (action: TAction) => {
        const { item, isDecisionSearch = false, readingListItem = false, getTranslation } = this.props;
        const document = isDecisionSearch ? item.hit as TDocument : item.hit as TCLDDocument;
        const pdfUrl = _.get(document, 'pdfURL') || `${DOCUMENT_BASE_URL}${document.slug}/pdf`;
        const closeRedirect = readingListItem ? window.location.pathname : undefined


        switch (action) {
            case "COPY URL": {
                if (_.isEmpty(document))
                    return;
                utilities.copyLink(pdfUrl)
                this.props.showToast(getTranslation(TOAST_LINK_COPIED_TO_CLIPBOARD) || "Link copied to clipboard", "success");
                break;
            }
            case "DOWNLOAD": {
                if (_.isEmpty(document))
                    return;
                window.open(_.get(document, 'orignalPdfURL'), "_blank")
                break;
            }
            case "READ LATER": {
                break;
            }
            case "SEE FULL": {
                // const id = !_.isEmpty(document.ltdDoc) ? document.ltdDoc.slug : ''
                const id = document.slug || document.id
                this.props.openDocument(id, closeRedirect);
                HISTORY.push(`/decision/${id}/`);
                break;
            }
            case "METADATA": {
                // const id = !_.isEmpty(document.ltdDoc) ? document.ltdDoc.slug : ''
                const id = document.slug || document.id
                this.props.openDocument(id);
                HISTORY.push(`/decision/${id}/`);
                // HISTORY.push(`/decision/${id}/metadata`);
                break;
            }
            case "REMOVE FROM LIST": {
                if (!_.isFunction(this.props.onRemoveFromReadingList))
                    return;
                this.props.onRemoveFromReadingList();

            }
            default: return;
        }
    }



    render() {
        const { classes, item, showMatchedText = false, cldFields, isDecisionSearch = false, itemNumber, checkedIds = [], readingListItem = false } = this.props;
        const document = isDecisionSearch ? item.hit as TDocument : item.hit as TCLDDocument;


        const metadetail = getDecisionMetaDetail(document, cldFields)
        const documentTitle = _.get(document, 'externalId');



        if (_.isEmpty(item))
            return <div />


        return (
            <Paper elevation={0} className={classes.root}>
                <div className={classes.tagSection}>
                    <div className={classes.documentId}>
                        <Typography className="mx-1">{itemNumber}</Typography>
                        {
                            (document && !readingListItem) ?
                                <Checkbox color="primary" checked={checkedIds.indexOf(document.id) > -1} onChange={() => this.props.toggleCheck(document.id)} /> : null
                        }

                        <Tooltip title="The unique identifier of the decision in the format given by the ICC">
                            <Typography className={classes.documentNumber} variant="caption">{'Document number ' + (documentTitle || '')}</Typography>
                        </Tooltip>

                    </div>
                    <Tag document={document} />
                </div>

                {
                    !_.isEmpty(item.highlight) &&
                    <div onClick={this.openDocument} className={classes.link}>
                        <Preview classes={classes} item={item} />
                    </div>
                }

                <div className={classNames(classes.leading, 'my-1')}>
                    <div onClick={this.openDocument} className={classes.link} >
                        <Typography color={!_.isEmpty(item.highlight) ? 'textPrimary' : "inherit"} className={classes.title}>
                            {_.get(document, '_decision.title') || _.get(document, 'title') || ''}
                        </Typography>
                    </div>
                </div>


                <Typography className="my-2">{metadetail}</Typography>

                <div className={classes.footerContainer}>
                    {
                        true ? (
                            document ?
                                <Typography style={{ color: Theme.Colors.Fourth }}>{_.get(document, 'numberOfPages')} pages</Typography> : <div>&nbsp;</div>
                        ) : (
                            _.get(document, 'Importance01') ?
                                <Typography style={{ color: Theme.Colors.Fourth }}>{_.get(document, 'Importance01')} importance</Typography>
                                : <div>&nbsp;</div>
                        )
                    }

                    <div className={classes.actionContainer}>
                        {
                            _.map(readingListItem ? readingListActions : actions, action => {
                                if (action === "READ LATER")
                                    return <Bookmark type="cld" docType="LtdDoc" classes={{ actionBtn: classes.actionBtn }} color="primary" key={_.uniqueId('bookmark')} variant="caption" id={document.id} />
                                else if (action === "COPY URL" || action === "DOWNLOAD")
                                    return (
                                        <Button variant="text" color="primary" className={classes.actionBtn} key={_.uniqueId('btn')} onClick={e => this.actionClicked(action)}>
                                            {this.getTranslatedLabel(action)}
                                        </Button>
                                    )
                                else
                                    return (
                                        <Button className={classes.actionBtn} variant="text" color="primary" key={_.uniqueId('btn')} onClick={e => this.actionClicked(action)}>
                                            {this.getTranslatedLabel(action)}
                                        </Button>
                                    )
                            })
                        }
                    </div>
                </div>
            </Paper>
        )
    }
}




const _Tag = ({ classes, document = {}, type = 'tag' }) => {
    const appeal = _.get(document, 'Appeal[0]');
    if (!appeal)
        return <div />

    const { AppealType } = appeal;
    const tag = _.find(APPEAL_CONFIG, config => config.value === AppealType);
    if (!tag)
        return <div />


    if (type === 'tag')
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" style={{ color: Theme.Colors.TextLight, fontSize: 10 }}>{tag.text}</Typography>
                <div style={{
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    marginLeft: 10,
                    background: tag.color
                }} />
            </div>
        )
    else
        return (
            <Typography component="span" variant="caption" style={{
                color: 'white',
                lineHeight: 1,
                padding: 2,
                margin: '0px 2px',
                background: tag.color,
                display: 'inline-block'
            }}>{tag.text}</Typography>
        )
}








interface PreviewProps {
    item: TSearchResult
}
class Preview extends Component<WithStyles<typeof STYLES> & PreviewProps> {
    state = {
        showPreview: true
    }



    togglePreview = () => {
        this.setState({ showPreview: !this.state.showPreview });
    }

    getHighlight = (h: string) => {
        const conf = getSearchResultHighlightConfig(_.get(this.props.item, 'hit'), h, 'title')
        const pre = (<div>{conf.pre}</div>)
        const post = (<div>{conf.end}</div>)

        return (
            <>
                {pre}
                <Typography dangerouslySetInnerHTML={{ __html: h }} />
                {post}
            </>
        )
    }


    render() {
        const { classes, item } = this.props;
        const { highlight = [] } = item;
        const { showPreview } = this.state;
        // let highlights: Array<string> = [];
        // _.forEach(highlight, (value, key) => {
        //     _.forEach(value, text => (
        //         highlights.push(text)
        //     ))
        // });
        const highlights = orderHighlightByFields(item.highlight);

        return (
            <div>
                {/* <Button style={this.style.button} onClick={this.togglePreview}>
                    <i style={{
                        color: 'white',
                        transition: '200ms linear',
                        transform: showPreview ? 'rotateZ(180deg)' : ''
                    }}
                        className="material-icons">
                        keyboard_arrow_down
                    </i>
                </Button> */}
                <Collapse in={showPreview}>
                    <div className={classes.previewContainer}>
                        {
                            _.map(highlights, h => (
                                <Typography className={classes.previewText} key={_.uniqueId('-highlight')} component="div" >
                                    {this.getHighlight(h)}
                                </Typography>
                            ))
                        }
                    </div>
                </Collapse>
                <Divider />
            </div>
        )
    }
}





const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Documents, ['showMatchedText']),
    ..._.pick(state.CLDDocuments, ['isDecisionSearch']),
    checkedIds: _.get(state.CLDDocuments, 'selected'),
    cldFields: state.CldFields.cldFields
})

const mapDispatchToProps = dispatch => ({
    openDocument: (id, closeRedirect) => dispatch(ODetail.openDocument(id, 'LTD', true, closeRedirect)),
    showToast: (message, variant: TToastVariant) => dispatch(OApp.showToast(message, variant)),
    toggleCheck: (id: string) => dispatch(OCLDDocuments.toggleSelect(id)),
})

const STYLES = theme => createStyles({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
        padding: '10px 32px 0px 32px',
        margin: '5px 0px',
        transition: '200ms linear',
        '&:hover': {
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.3)}`
        }
    },
    deleteButton: {

    },
    tagSection: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: -8,
        justifyContent: 'space-between'
    },
    documentId: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        left: -26,
        '& span': { padding: 0 },

    },
    documentNumber: {
        background: `${LIST_ITEM_COLOR.decision} !important`,
        color: 'white',
        fontWeight: 500,
        padding: '0px 2px'
    },
    primary: {
        color: Theme.Colors.Secondary.main
    },
    extraPreview: {

    },
    listItem: {
        display: 'flex',
        '& p': {
            fontSize: 12
        }
    },
    title: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.4,
        color: Theme.Colors.Third
    },
    leading: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            padding: 0,
            marginRight: 4
        }
    },
    label: {
        fontWeight: 500
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer'
    },
    previewContainer: {
        // paddingBottom: 16,
        margin: '4px 0px',
        '& span.search-highlight': {
            background: Theme.Colors.Highlight,
        },
        '& > div': {
            fontSize: 14,
            fontWeight: 500,
            margin: '4px 0px'
        }
    },
    actionBtn: {
        color: Theme.Colors.Third,
    },
    actionContainer: {
        marginTop: 8,
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            fontSize: 12,
            transition: '300ms linear',
            borderRadius: 0,
            '&:hover': {
                background: 'white',
                boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.2)}`
            },
            '& span': {
                fontWeight: 500,
                cursor: 'pointer',
                transition: '500ms linear',
                // color: Theme.Colors.Third,
                '&:hover': {
                    // color: Theme.Colors.Third
                },
            }
        },
    },
    footerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    previewText: {
        transition: '200ms linear',
        textAlign: 'justify',
        '&>div, &>p': {
            fontWeight: 400,
            lineHeight: 1.2,
            display: 'inline'
        },
        '&:hover': {
            background: fade(Theme.Colors.Fourth, 0.1)
        },
        '& em': {
            background: Theme.Colors.Highlight,
        }
    }
})


export const Tag = withStyles(STYLES)(_Tag);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(DecisionListItem)))