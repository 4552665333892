import React, { FC, useEffect } from 'react';
import { createStyles, TextField, Theme as MuiTheme, withStyles, WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import {
    CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT,
    TCldDashboardState,
} from '../../../Features/CldDashboard/cldDashboard-reducer';
import FullscreenLoader from '../../../Components/FullscreenLoader';
import DashboardUserCard from '../../../Components/DashboardUserCard';
import { TState } from '../../../RootReducer';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../../Resources/Theme';
import { TUser } from '../../../Features/Authentication/@types';
import CldRoleFilter from './Filter/CldRoleFilter';

export interface RolesProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {}

interface IStateProps {
    loading: boolean;
    roles: TCldDashboardState['roles'];
}

interface IDispatchProps {
    fetchUsers: () => Promise<void>;
    setSearchTerm: (term: string) => void;
    changePage: (pageNumber: number) => void;
    changeRole: (action: 'add' | 'remove', userId: string, role: string) => void;
}

const Roles: FC<RolesProps> = props => {
    const { classes, fetchUsers, loading, roles, setSearchTerm, changePage, changeRole } = props;
    const { users, total, pageNumber, searchTerm } = roles;
    const hasMore = total > pageNumber * CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT;

    useEffect(() => {
        fetchUsers();
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchTerm(value);
    };

    const clearSearchTerm = () => setSearchTerm('');

    const onPageChange = (pageNumber: number) => {
        changePage(pageNumber - 1);
    };

    const handleRoleChange = (user: TUser) => (role: string, isChecked: boolean) => {
        changeRole(isChecked ? 'add' : 'remove', user.id, role);
    };

    return (
        <div className={classes.root}>
            {loading ? <FullscreenLoader /> : null}
            <div className={classes.filterBar}>
                <TextField
                    className={classes.textField}
                    onChange={onChange}
                    value={searchTerm}
                    placeholder='Search user'
                    InputProps={{
                        endAdornment: searchTerm ? (
                            <i onClick={clearSearchTerm} className={classNames('material-icons', classes.iconBtn)}>
                                clear
                            </i>
                        ) : null,
                    }}
                />
                <CldRoleFilter />
            </div>
            {users.map(user => (
                <div key={user.id} className='my-2'>
                    <DashboardUserCard onRoleChange={handleRoleChange(user)} user={user} />
                </div>
            ))}

            {hasMore ? (
                <div className={classes.pagination}>
                    <Pagination
                        activePage={pageNumber + 1}
                        itemsCountPerPage={CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT}
                        innerClass={classes.paginationContainer}
                        activeClass={classes.activePageIndicator}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={onPageChange}
                    />
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: TState) => ({
    loading: state.CldDashboard.loading,
    roles: state.CldDashboard.roles,
});

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => dispatch(OCldDashboard.fetchUsers()),
    setSearchTerm: (term: string) => dispatch(OCldDashboard.setSearchTerm(term)),
    changePage: (pageNumber: number) => dispatch(OCldDashboard.changePage(pageNumber)),
    changeRole: (action: 'add' | 'remove', userId: string, role: string) =>
        dispatch(OCldDashboard.changeRole(action, [userId], role)),
});

const STYLES = (theme: MuiTheme) =>
    createStyles({
        root: {
            maxWidth: 900,
            margin: '0 auto',
            padding: '40px 0',
            paddingBottom: 100,
        },
        filterBar: {
            display: 'flex',
            alignItems: 'baseline',
            '& div': {
                marginRight: 20,
                width: 'fit-content',
            },
            '& div:last-child': {
                marginRight: 0,
            },
        },
        iconBtn: {
            cursor: 'pointer',
        },
        textField: {
            width: 300,
        },
        pagination: {
            margin: '30px 0px',
            paddingBottom: 10,
            fontFamily: 'Roboto',
            '& ul': {
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                display: 'flex',
                paddingLeft: 15,
                paddingRight: 15,
            },
            '& li': {
                display: 'inline-block',
                margin: '0px 4px',
                padding: '2px 8px',
                cursor: 'pointer',
                // height: 30,
                '& i': {
                    position: 'relative',
                    top: 3,
                },
                transition: '300ms linear',
                '&:hover': {
                    background: fade(Theme.Colors.Fourth, 0.5),
                    color: 'white',
                },
            },
        },
        paginationContainer: {
            margin: '0 auto',
            width: 'fit-content',
            '& a:focus': {
                outline: 'none',
            },
            '& a': {
                textDecoration: 'none',
                color: Theme.Colors.TextColor,
            },
        },
        activePageIndicator: {
            background: Theme.Colors.Third,
            color: 'white',
            '& a': {
                color: 'white',
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Roles));
