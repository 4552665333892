import React, { FC, useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import TableScreen, { TableScreenProps } from '../../../Components/Dashboard/TableScreen';
import FullscreenLoader from '../../../Components/FullscreenLoader';

export interface ScreenProps<T> extends Omit<TableScreenProps, 'classes' | 'rows'>, WithStyles<typeof STYLES> {
    getData: () => Promise<T[]>;
    deps?: any[];
}


function Screen<T>(props: ScreenProps<T>) {
    const { getData, deps, ...tableScreenProps } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T[]>([]);

    useEffect(() => {
        getData().then(setData).finally(() => setLoading(false));
    }, [deps]);

    return (
        <>
            {loading && <FullscreenLoader />}
            <TableScreen {...tableScreenProps} rows={data} />
        </>

    )
}

const STYLES = (theme: Theme) => createStyles({

});

export default withStyles(STYLES)(Screen);