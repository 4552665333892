import { TAction, Models } from '../../Resources/Model';
import { Reducer } from 'redux';
import utilities from '../../Resources/Utils';
import { OAuth } from '../../Features/Authentication/redux-config';
import _ from 'lodash';
import { CldField } from '../../Services/CldFields.types';
import { CldFieldService, CLD_FIELDS_STATIC } from '../../Services/CldFields.service';

export interface TCldFieldState {
    cldFields: CldField[];
    groupedCldFields: Record<string, CldField[]>;
}

export class CldFieldClass extends Models {
    constructor() {
        super('cldField', {
            SET_CLD_FIELDS: 'SET_CLD_FIELDS',
            ADD_CLD_FIELD: 'ADD_CLD_FIELD',
            UPDATE_CLD_FIELD: 'UPDATE_CLD_FIELD',
            DELETE_CLD_FIELD: 'DELETE_CLD_FIELD',
        });
    }

    getCldFields = () => async dispatch => {
        try {
            const { data } = await CldFieldService.getAll();
            dispatch({
                type: this.actions.SET_CLD_FIELDS,
                data: [...data, ...CldFieldService.getStaticStaticFields()],
            });
        } catch (error) {}
    };

    addCldField = (cldField: Partial<CldField>) => async dispatch => {
        try {
            const { data } = await CldFieldService.newCldField(cldField);
            dispatch({
                type: this.actions.ADD_CLD_FIELD,
                data: { ...cldField, ...data },
            });
        } catch (error) {}
    };

    updateCldField = (id: string, cldField: Partial<CldField>) => async dispatch => {
        try {
            const { data } = await CldFieldService.updateCldField(id, cldField);
            dispatch({
                type: this.actions.UPDATE_CLD_FIELD,
                data: { ...cldField, ...data },
            });
        } catch (error) {}
    };

    deleteCldField = (id: string) => async dispatch => {
        try {
            await CldFieldService.deleteCldField(id);
            dispatch({
                type: this.actions.DELETE_CLD_FIELD,
                data: id,
            });
        } catch (error) {}
    };
}

export const OCldField = new CldFieldClass();

const initalState: TCldFieldState = {
    groupedCldFields: {},
    cldFields: [],
};

export const CldFieldsReducer: Reducer<TCldFieldState> = (state = initalState, action: TAction): TCldFieldState => {
    const { SET_CLD_FIELDS, ADD_CLD_FIELD, UPDATE_CLD_FIELD, DELETE_CLD_FIELD } = OCldField.actions;

    switch (action.type) {
        case ADD_CLD_FIELD: {
            const cldFields = [...state.cldFields, action.data];
            return { ...state, cldFields, groupedCldFields: _.groupBy(cldFields, 'type') };
        }
        case UPDATE_CLD_FIELD: {
            const cldFields = state.cldFields.map(cldField =>
                cldField.id === action.data.id ? { ...cldField, ...action.data } : cldField
            );
            return { ...state, cldFields, groupedCldFields: _.groupBy(cldFields, 'type') };
        }
        case DELETE_CLD_FIELD: {
            const cldFields = state.cldFields.filter(cldField => cldField.id !== action.data);
            return { ...state, cldFields, groupedCldFields: _.groupBy(cldFields, 'type') };
        }
        case SET_CLD_FIELDS: {
            return { ...state, cldFields: action.data, groupedCldFields: _.groupBy(action.data, 'type') };
        }

        default:
            return state;
    }
};
