const contactUsEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
	<div id="c258" class="csc-default">
	<!--  Header: [begin] -->
		<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Contact us</h1></div>
	<!--  Header: [end] -->
		
	<!--  Text: [begin] -->
		<p  class="bodytext">The ICC Legal Tools Database (‘LTD’) can be contacted at <a href="mailto:info@cilrap.org">info@cilrap.org</a>. </p>
		<p  class="bodytext">You may use the “Report Issue” button at the top-right corner of the LTD menu which gives you options to directly:</p>
		<ul>
			<li>report an error or suggest an improvement;</li>
			<li>suggest addition of a document to the LTD; or,</li>
			<li>suggest correction to the metadata of an existing LTD record.</li>
		</ul>
	<!--  Text: [end] -->
		</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
	</div>`;
export default contactUsEn;
