const legalToolAdvisoryEs = `<div id="content" class="content">
<div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a>
<a href="status-of-work-on-the-tools/" >
Status of work on the Tools&nbsp;»&nbsp;
</a><a href="status-of-work-on-the-tools/ltac/" >
LTAC
</a></p></div><div id="getUrlText">
<div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree">
<i class="glyphicon glyphicon-collapse-down"></i></div> 
<a href="javascript:window.print();" style="float:right;">
<i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:256/text [begin] -->
    <div id="c256" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        Comité Asesor sobre Herramientas Jurídicas
        </h1></div>
    <!--  Header: [end] -->  
    <!--  Text: [begin] -->
        <p class="bodytext"> 
            Se ha creado un Comité Asesor sobre Herramientas
             Jurídicas de la Corte en su conjunto (el LTAC), integrado por miembros de todos los
              órganos de la Corte, para evaluar las necesidades de los usuarios pertinentes, 
              tanto de la propia Corte como de otros participantes que actúan ante la Corte, 
              como son los abogados de la defensa y de las víctimas. El Comité ha elaborado el 
              Manual de Metadatos así como las categorías de palabras clave que sirven de base para 
              la operación de la Base de Datos de Herramientas Jurídicas de la CPI. 
           </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:256/text [end] -->
    </div>`;
export default legalToolAdvisoryEs;
