import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, FormControl, Button, CircularProgress, LinearProgress } from '@material-ui/core';
import SimpleDialog from '../SimpleDialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { MENU_LIST_EMAIL, MENU_LIST_ENTER, MENU_LIST_NAME, MENU_LIST_PLEASE_DESCRIBE, MENU_LIST_REPORT_ERROR } from '../../Features/Translations/translationKeys.ltd';

export interface ReportErrorProps extends WithStyles<typeof STYLES> {
    open: boolean
    handleClose: () => void
    handleSubmitForm: (form: Partial<TReportErrorForm>) => Promise<void>
}
export interface TReportErrorForm {
    name: string
    email: string
    description: string
}

const ReportError: FC<ReportErrorProps & WithTranslationProps> = (props) => {
    const { classes, open = false, getTranslation } = props;
    const [formData, setFormData] = useState<Partial<TReportErrorForm>>({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async () => {
        setLoading(true)
        await props.handleSubmitForm(formData);
        setLoading(false)
    }

    return (
        <SimpleDialog open={open} handleClose={props.handleClose} headerText={getTranslation(MENU_LIST_REPORT_ERROR) || "Report an error or suggest an improvement."} >
            <ValidatorForm onSubmit={handleSubmit}>
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_NAME) || 'Name'}
                    name="name"
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'name') || ''}
                    onChange={handleChange}
                />
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_EMAIL) || 'E-mail'}
                    name="email"
                    validators={['required', 'isEmail']}
                    errorMessages={['Required', 'Incorrect Email']}
                    value={_.get(formData, 'email') || ''}
                    onChange={handleChange}
                />
                <TextValidator
                    multiline
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_PLEASE_DESCRIBE) || 'Please describe the error or the suggestion.'}
                    name="description"
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'description') || ''}
                    onChange={handleChange}
                />
                <Button className={classes.button} type="submit" fullWidth variant="contained" color="primary">
                    {loading ? <CircularProgress /> : getTranslation(MENU_LIST_ENTER) || 'ENTER'}
                </Button>
            </ValidatorForm>
        </SimpleDialog >
    )
}

const STYLES = (theme: Theme) => createStyles({
    button: {
        marginTop: 18
    }
});

export default withStyles(STYLES)(withTranslations(ReportError));