const usePurlFr = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="purl/" >PURL&nbsp;»&nbsp;</a><a href="purl/how-to-use-the-purls-in-the-icc-legal-tools-database/" >How to use the PURLs in the ICC Legal Tools Database?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:248/text [begin] -->
    <div id="c248" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        Comment se servir des PURL dans la base de données des Outils juridiques de la CPI ?        </h1></div>
    <!--  Header: [end] -->			
    <!--  Text: [begin] -->
        <p class="bodytext">
        Plus de 162 000 sources juridiques de droit international pénal sont disponibles gratuitement dans la base de données des Outils juridiques de la CPI. On y retrouve tous les documents publics de la CPI (décisions, documents déposés et transcriptions des affaires devant la Cour), ainsi que des décisions et des documents d’autres tribunaux internationaux, d'organismes de protection des droits de l’homme et de juridictions nationales, l’ensemble des travaux préparatoires du droit de la CPI, les législations nationales et la doctrine dans le domaine du droit international pénal.
</p>
<p class="bodytext">
Chaque document de la base de données des Outils juridiques se voit attribuer une adresse internet permanente (une « URL permanente », c'est-à-dire une PURL) qui ne changera pas. Lorsque vous créez un hyperlien pour la PURL d’un document dans votre texte, vous permettez aux lecteurs d’accéder directement au document cité. La création de ces hyperliens est facile et prend moins de 30 secondes. Pour le public, cela présente un avantage appréciable, vos lecteurs ayant la possibilité d’accéder à une manne de sources juridiques en plus de celles sur lesquelles vous travaillez vous-même. Il s'agit donc d'une pratique qui peut transformer la diffusion du droit international pénal.
</p>
<p class="bodytext"><i><b>
Comment puis-je trouver la PURL d’un document donné ? </b></i>
</p>
<p class="bodytext">
Dans la base de données, vous pouvez trouver tout document à l'aide du moteur de recherche situé dans le menu en haut à gauche ou de l’arborescence des dossiers dans le menu en bas à gauche. Quelle que soit l'option de recherche utilisée, vous pouvez copier la PURL du document de votre choix en cliquant sur le bouton « COPIER LA PURL » dans la fiche du document sur la page des résultats. Vous pouvez également cliquer sur le lien du document souhaité. Cela vous mènera à la page des métadonnées du document concerné, qui vous donne notamment le titre, la date de création, la source du document et la composition de la chambre. La première ligne de la page des métadonnées vous donne l’adresse PURL, qui se termine toujours par une barre oblique 
(par exemple, <a href="https://www.legal-tools.org/doc/ea422e/" target="_blank">http://www.legal-tools.org/doc/ea422e/</a>). 
Copiez cette adresse et collez-la dans votre document. Utilisez uniquement la PURL qui apparaît sur la page des métadonnées du document concerné ou celle obtenue en cliquant sur le bouton « COPIER LA PURL ». Prenez soin de ne pas copier le lien vers la page des métadonnées depuis la barre d’adresse du navigateur, ni de cliquer sur le bouton « Télécharger » et de copier le lien du document recherché depuis la barre d’adresse du navigateur.
</p>
<p class="bodytext"><i><b>
Comment puis-je créer un hyperlien dans un document en cours de rédaction ?
</b></i>
</p>
<p class="bodytext">
Pour intégrer une URL permanente sous forme d’hyperlien dans un document en cours de rédaction, veuillez suivre ces trois étapes :
<br>1. Dans votre document, sélectionner l’élément texte pour lequel vous souhaitez créer un hyperlien.
 <br>2. Appuyer sur « Control » + « K ». Une nouvelle petite fenêtre s'ouvre.
 <br>3. Insérer la PURL dans la ligne de l’adresse en bas de la petite fenêtre en appuyant sur « Control » + « V ». Cliquer ensuite sur « OK ».
</p>
<p class="bodytext"><i><b>Comment puis-je supprimer un hyperlien ?</b></i>
</p>
<p class="bodytext">
Pour supprimer un hyperlien, veuillez à nouveau sélectionner l’élément texte pour lequel vous souhaitez supprimer le lien vers la PURL. Appuyez sur « Control » + « K ». La petite fenêtre apparaîtra alors de nouveau. Vous verrez que l’hyperlien est indiqué dans la ligne d’adresse au bas de la fenêtre. Cliquez sur le bouton « Supprimer le lien » qui se trouve à droite. L’hyperlien est supprimé. 
</p>
<p class="bodytext"><i><b>
Comment faire si un document n’est pas disponible dans la base de données des Outils juridiques de la CPI ?</b></i>
</p>    
<p class="bodytext">
Si vous ne trouvez pas un document particulier, veuillez envoyer un courriel à M. Devasheesh Bais (coordonnateur adjoint, projet d’Outils juridiques de la CPI) à <a href="mailto:info@cilrap.org">bais@cilrap.org</a> (en mettant toujours en copie <a href="mailto:info@cilrap.org">info@cilrap.org</a>).</p>
    <!--  Text: [end] -->
        </div>
    <!--  CONTENT ELEMENT, uid:248/text [end] -->
    </div>`;

export default usePurlFr;
