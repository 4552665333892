import { TNode } from '../DocumentsTree/@types';
import {
    GENERAL_NO,
    GENERAL_YES,
    METADATA_ACQUITTAL,
    METADATA_CONVICTION,
    METADATA_CONVICTION_PARTIAL,
    METADATA_INTERNATIONAL_CRIMINAL_LAW,
    METADATA_INTERNATIONAL_HUMANITARIAN_LAW,
    METADATA_INTERNATIONAL_HUMAN_RIGHTS_LAW,
    METADATA_LAW_OF_INTERNATIONAL_ORGANISATION,
    METADATA_LAW_OF_THE_SEA,
    METADATA_NEW_TRIAL_ORDERED,
    METADATA_NOT_APPLICABLE,
    METADATA_NOT_AVAILABLE,
    METADATA_OTHER_PUBLIC,
    METADATA_PEACE_AGREEMENTS,
    METADATA_REVISION_ALLOWED,
    SURVEY_OTHER,
} from '../Translations/translationKeys.ltd';

export type TLTDDocEditMode = 'doc' | 'draft';
export interface TNewLTDDocumentState {
    isDocumentModified: boolean;
    editMode: TLTDDocEditMode;
    open: boolean;
    includeIdOptions: Record<string, any>;
    documentTypeSelectionDialog: boolean;
    section: TEditSection;
    parentNodeId?: string;
    isNewDocument: boolean;
    additionalData?: any;
    // editFields: TEditFields
    formData: Record<string, any>;
    formUpdates?: Record<string, any>;
    loading: boolean;
    docPath?: Array<TNode> | undefined;
}

// export type TEditFields = {
//     open: boolean
//     fieldIndex?: number
//     section?: TEditSection
// }

export const EXTENDED_METADATA_IN = ['icc_preparatory_works', 'judicial_document', 'judicial_summary'];
export const BOOLEAN_OPTIONS = [
    { name: 'Yes', value: true, translationKey: GENERAL_YES },
    { name: 'No', value: false, translationKey: GENERAL_NO },
];

export const CONTENT_TYPE = [
    { name: 'Academic writing', value: 'Academic writing' },
    { name: 'Domestic legal instrument', value: 'Domestic legal instrument' },
    { name: 'ICC Preparatory works', value: 'ICC Preparatory works' },
    { name: 'Implementing legislation', value: 'Implementing legislation' },
    { name: 'International legal instrument', value: 'International legal instrument' },
    { name: 'Judicial document', value: 'Judicial document' },
    { name: 'News', value: 'News' },
    { name: 'Publications', value: 'Publications' },
    { name: 'Website', value: 'Website' },
    { name: 'Judicial Summary', value: 'Judicial Summary' },
];
export const SOURCE_TYPE = [
    { name: 'Judicial body', value: 'Judicial body' },
    { name: 'Prosecution service', value: 'Prosecution service' },
    { name: 'Defence service', value: 'Defence service' },
    { name: 'State (legislative branch)', value: 'State (legislative branch)' },
    { name: 'State (executive branch)', value: 'State (executive branch)' },
    { name: 'International organisation', value: 'International organisation' },
    { name: 'International diplomatic conference', value: 'International diplomatic conference' },
    { name: 'Non-governmental organisation', value: 'Non-governmental organisation' },
    { name: 'Publicist', value: 'Publicist' },
    { name: 'Court reporter', value: 'Court reporter' },
    { name: 'Press', value: 'Press' },
    { name: 'Participating victim', value: 'Participating victim' },
    { name: 'Other', value: 'Other' },
    { name: 'Unknown', value: 'Unknown' },
];
export const LANGUAGES = [{ name: 'Other', value: 'Other' }];

export const JUDICIAL_DOCUMENT = [];

export const OUTCOME_OF_TRIAL = [
    { name: 'Acquittal (full)', value: 'Acquittal (full)', transltionKey: METADATA_ACQUITTAL },
    { name: 'Conviction (full)', value: 'Conviction (full)', translationKey: METADATA_CONVICTION },
    { name: 'Conviction (partial)', value: 'Conviction (partial)', translationKey: METADATA_CONVICTION_PARTIAL },
    { name: 'New trial ordered', value: 'New trial ordered', translationkey: METADATA_NEW_TRIAL_ORDERED },
    { name: 'Revision allowed', value: 'Revision allowed', translationkey: METADATA_REVISION_ALLOWED },
    { name: 'Other', value: 'Other', translationKey: SURVEY_OTHER },
    { name: 'Not available', value: 'Not available', translationkey: METADATA_NOT_AVAILABLE },
    { name: 'Not applicable', value: 'Not applicable', translationkey: METADATA_NOT_APPLICABLE },
];

export const PHASE_OF_CASE = [
    { name: 'Pre-Trial', value: 'Pre-Trial' },
    { name: 'Trial', value: 'Trial' },
    { name: 'Interlocutory appeal', value: 'Interlocutory appeal' },
    { name: 'Appeal', value: 'Appeal' },
    { name: 'Review', value: 'Review' },
    { name: 'Revision', value: 'Revision' },
    { name: 'Admissibility', value: 'Admissibility' },
    { name: 'Merits', value: 'Merits' },
    { name: '[Remedy/compensation] / [Satisfaction]', value: '[Remedy/compensation] / [Satisfaction]' },
    { name: 'Advisory opinion', value: 'Advisory opinion' },
    { name: 'Interpretation', value: 'Interpretation' },
    { name: 'Striking out', value: 'Striking out' },
    { name: 'Friendly settlement', value: 'Friendly settlement' },
    { name: 'Not applicable', value: 'Not applicable' },
    { name: 'Not available', value: 'Not available' },
    { name: 'To be determined', value: 'To be determined' },
];

export const TYPE_OF_JUDICIAL_DOCUMENT = [
    { name: 'Arrest Warrant/ Indictment', value: 'Arrest Warrant/ Indictment' },
    { name: 'Submission', value: 'Submission' },
    { name: 'Direction and Order', value: 'Direction and Order' },
    { name: 'Decision', value: 'Decision' },
    { name: 'Judgment', value: 'Judgment' },
    { name: 'Transcripts', value: 'Transcripts' },
    { name: 'Other Court Documents', value: 'Other Court Documents' },
];

export const TYPE_OF_COURT = [
    { name: 'Ordinary jurisdiction', value: 'Ordinary jurisdiction' },
    { name: 'Military jurisdiction', value: 'Military jurisdiction' },
    { name: 'International(ised) jurisdiction', value: 'International(ised) jurisdiction' },
    { name: 'Not available', value: 'Not available' },
    { name: 'Not applicable', value: 'Not applicable' },
];

export const ICC_SITUATION_NAME = [
    {
        name: 'Situation in the Democratic Republic of the Congo',
        value: 'Situation in the Democratic Republic of the Congo',
    },
    { name: 'Situation in the Central African Republic', value: 'Situation in the Central African Republic' },
    { name: 'Situation in Darfur/Sudan', value: 'Situation in Darfur/Sudan' },
    { name: 'Situation in Uganda', value: 'Situation in Uganda' },
    { name: 'Situation in the Republic of Kenya', value: 'Situation in the Republic of Kenya' },
    { name: 'Situation in Libya', value: 'Situation in Libya' },
    { name: "Situation in the Republic of Côte d'Ivoire", value: "Situation in the Republic of Côte d'Ivoire" },
    { name: 'Situation in the Republic of Mali', value: 'Situation in the Republic of Mali' },
    { name: 'Situation in the Central African Republic II', value: 'Situation in the Central African Republic II' },
    {
        name:
            'Situation on Registered Vessels of the Union of the Comoros, the Hellenic Republic and the Kingdom of Cambodia',
        value:
            'Situation on Registered Vessels of the Union of the Comoros, the Hellenic Republic and the Kingdom of Cambodia',
    },
    { name: 'Situation in Georgia', value: 'Situation in Georgia' },
    {
        name: 'Situation in the Islamic Republic of Afghanistan',
        value: 'Situation in the Islamic Republic of Afghanistan',
    },
    { name: 'Situation in the Republic of Burundi', value: 'Situation in the Republic of Burundi' },
    {
        name: 'Situation in the People´s Republic of Bangladesh/Republic of the Union of Myanmar',
        value: 'Situation in the People´s Republic of Bangladesh/Republic of the Union of Myanmar',
    },
];

export const LEVEL_OF_CONFIDENTIALITY = [
    { name: 'confidential', value: 'confidential' },
    { name: 'public', value: 'public' },
];

export const IMPORTANCE = [
    { name: 'least important', value: 'least important' },
    { name: 'less important', value: 'less important' },
    { name: 'important', value: 'important' },
    { name: 'most important', value: 'most important' },
];

export const SUBJECT = [
    {
        name: 'International criminal law',
        value: 'International criminal law',
        translationkey: METADATA_INTERNATIONAL_CRIMINAL_LAW,
    },
    {
        name: 'International human rights law',
        value: 'International human rights law',
        translationkey: METADATA_INTERNATIONAL_HUMAN_RIGHTS_LAW,
    },
    {
        name: 'International humanitarian law',
        value: 'International humanitarian law',
        translationkey: METADATA_INTERNATIONAL_HUMANITARIAN_LAW,
    },
    {
        name: 'Other public international law',
        value: 'Other public international law',
        translationkey: METADATA_OTHER_PUBLIC,
    },
    {
        name: 'Law of international organisations',
        value: 'Law of international organisations',
        translationkey: METADATA_LAW_OF_INTERNATIONAL_ORGANISATION,
    },
    {
        name: 'Law of the sea',
        value: 'Law of the sea',
        translationkey: METADATA_LAW_OF_THE_SEA,
    },
    {
        name: 'Peace agreements',
        value: 'Peace agreements',
        translationkey: METADATA_PEACE_AGREEMENTS,
    },
    {
        name: 'Not applicable',
        value: 'Not applicable',
        translationkey: METADATA_NOT_APPLICABLE,
    },
];

export const PHASE_OF_PREPARATORY = [
    { name: 'Early Initiatives', value: 'Early Initiatives' },
    { name: 'International Law Commission', value: 'International Law Commission' },
    { name: 'Ad Hoc Committee', value: 'Ad Hoc Committee' },
    { name: 'Preparatory Committee', value: 'Preparatory Committee' },
    { name: 'Rome Conference', value: 'Rome Conference' },
    { name: 'Preparatory Commission', value: 'Preparatory Commission' },
    { name: 'Preparatory works (Genocide Convention)', value: 'Preparatory works (Genocide Convention)' },
];

export const DOCUMENT_TYPE = [
    { name: 'Microsoft Word', value: 'Microsoft Word' },
    { name: 'Adobe PDF', value: 'Adobe PDF' },
    { name: 'application/ octet-stream', value: 'application/ octet-stream' },
    { name: 'text/html', value: 'text/html' },
    { name: 'text/plain', value: 'text/plain' },
    { name: 'text/rtf', value: 'text/rtf' },
    { name: 'text/x-c; image/jpeg', value: 'text/x-c; image/jpeg' },
    { name: 'image/png', value: 'image/png' },
    { name: 'image/gif', value: 'image/gif' },
    { name: 'image/tiff', value: 'image/tiff' },
    { name: 'Windows Media Video (video/x-ms-wmv)', value: 'Windows Media Video (video/x-ms-wmv)' },
    { name: 'message/rfc822', value: 'message/rfc822' },
    { name: 'application/x-empty', value: 'application/x-empty' },
];

export const RESPONSIBLE_PARTNER = [
    { name: 'LTD Coordination Team', value: 'LTD Coordination Team' },
    { name: 'CMN Fellows', value: 'CMN Fellows' },
    { name: 'Handa Center', value: 'Handa Center' },
    { name: 'Stanford University', value: 'Stanford University' },
    { name: 'Canadian partners', value: 'Canadian partners' },
    {
        name: 'Central and Eastern European Initiative for International Criminal Law and Human Rights',
        value: 'Central and Eastern European Initiative for International Criminal Law and Human Rights',
    },
    { name: 'University of KwaZulu-Natal', value: 'University of KwaZulu-Natal' },
    {
        name: 'International Research and Documentation Centre for War Crimes Trials (ICWC)',
        value: 'International Research and Documentation Centre for War Crimes Trials (ICWC)',
    },
    { name: 'University of Nottingham', value: 'University of Nottingham' },
    { name: 'University of Richmond', value: 'University of Richmond' },
    { name: 'University Torcuato Di Tella', value: 'University Torcuato Di Tella' },
    { name: 'Whitney R. Harris World Law Institute', value: 'Whitney R. Harris World Law Institute' },
    { name: 'Ain Shams University Law School (ASU)', value: 'Ain Shams University Law School (ASU)' },
    { name: 'Aoyama Gakuin University', value: 'Aoyama Gakuin University' },
    { name: 'Jindal Global University', value: 'Jindal Global University' },
    { name: 'University of Cape Town', value: 'University of Cape Town' },
    { name: 'Belgian Partners', value: 'Belgian Partners' },
    { name: 'HiiL / Asser Institute', value: 'HiiL / Asser Institute' },
    { name: 'Norwegian Centre for Human Rights', value: 'Norwegian Centre for Human Rights' },
    { name: 'TRIAL', value: 'TRIAL' },
];

export const ORIGIN = [
    { name: 'Internet download', value: 'Internet download' },
    { name: 'Scanned document', value: 'Scanned document' },
    { name: 'Live internet link', value: 'Live internet link' },
    { name: 'Download from authoritative link', value: 'Download from authoritative link' },
];

export type TEditSection =
    | 'basic-information'
    | 'relations'
    | 'judicial-documents'
    | 'icc-documents'
    | 'biblography'
    | 'quality-control';
