import utilities from "../Resources/Utils";


class DecisionService {

    static migrate(docId: string) {
        return utilities.request<void>({
            url: `/decisions/migrate`,
            method: 'POST',
            data: {
                where: {
                    id: docId
                }
            }
        })
    }
}

export default DecisionService;