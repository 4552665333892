import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import { TNode } from '../../Features/DocumentsTree/@types';
import THEME from '../../Resources/Theme';


interface IState { }
interface IProps extends WithStyles<typeof STYLES> {
    docPath: Array<TNode>
}

class LTDBreadcrumbs extends Component<IProps, IState> {

    render() {
        const { classes, docPath = [] } = this.props;
        return (
            <ul className={classes.breadcrumbsList}>
                {
                    docPath.map((node, i) => {
                        return (
                            <li key={`breadcrumb_${node.id}`}>
                                <a>{node.text}</a>
                                {
                                    (i !== docPath.length - 1) &&
                                    <i className="material-icons">arrow_right</i>
                                }
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
}

const STYLES = (theme) => createStyles({
    breadcrumbsList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        '& > li': {
            display: 'inline-block',
            lineHeight: '12px',
            fontSize: 12,
            color: THEME.Colors.TextLight,
            '& > .material-icons': {
                position: 'relative',
                top: 8
            }
        }
    }
})

export default withStyles(STYLES)(LTDBreadcrumbs)