import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Fab } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import _ from 'lodash';
// import classNames from 'classnames';
import { TSidebar, OApp } from '../../RootReducer/AppReducer';
import { fade } from '@material-ui/core/styles/colorManipulator';


interface IStateProps {
    sidebar: TSidebar
}

interface IDispatchProps {
    closeSidebar?: () => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class Sidebar extends Component<IProps, IState> {

    close = () => {
        if (this.props.closeSidebar)
            this.props.closeSidebar();
    }

    render() {
        const { classes, sidebar } = this.props
        return (
            <div className={classes.root} style={{ width: !sidebar.open ? 0 : 'inherit' }}>
                {sidebar.component}
            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.App, ['sidebar'])
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    closeSidebar: () => dispatch(OApp.closeSidebar())
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        right: 0,
        background: 'white',
        zIndex: 1400,
        top: 0,
        bottom: 0,
        overflowY: 'auto',
        boxShadow: `2px 0px 8px ${fade('#000', 0.3)}`,
        transition: '200ms ease-out'
    },
    closeBtn: {
        boxShadow: 'none',
        background: 'white',
        position: 'absolute',
        top: 4,
        right: 8
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Sidebar))