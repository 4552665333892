const coordinationRu = `
<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
<div id="c258" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
<h1 class="csc-firstHeader">Координация, стипендиаты CMN и институциональные партнеры</h1></div>
<!--  Header: [end] -->			
<!--  Text: [begin] -->
<p class="bodytext"><span class="text_">
    Центр исследований и политики в области международного права (</span><a href="https://www.cilrap.org/purpose/" class="text_1">CILRAP</a><span class="text_">) 
    управляет базой данных Юридические инструменты МУС (LTD) для Международного уголовного суда в соответствии с 10-летним соглашением между Судом и CILRAP, заключенным 9 марта 2021 года.
    </span> <a href="https://www.cilrap.org/bergsmo/" target="_blank"> Мортен Бергсмо</a> 
    является директором CILRAP и координатором проекта Юридические инструменты МУС.
    <a href="https://www.cilrap.org/bais/" target="_blank">Девашиш Байс</a>
    является заместителем координатора проекта Юридические инструменты МУС. </p>
    <p class="bodytext"><span class="text_">
CILRAP создал команду из более чем 40  стипендиатов CMN для помощи в обновлении контента и обслуживании LTD. И стипендиаты CMN, и институциональные партнеры, описанные ниже, вносят безвозмездный вклад в развитие LTD как цифрового общественного блага. Стипендиаты CMN — это разнообразная группа людей, которые вносят свой вклад в LTD на долгосрочной основе, следуя интересам служения обществу. Список действующих и бывших стипендиатов CMN доступен
    </span><a target="_blank" href="https://www.casematrixnetwork.org/about-us/cmn-fellows/" class="text_3">здесь</a>. Те, кто заинтересован в том, чтобы внести свой вклад в качестве члена CMN, могут отправить сообщение электронной почты с обновленной биографией на адрес <a href="mailto:info@cilrap.org">info@cilrap.org</a>.
Кандидаты отбираются на основе
 (a)точности, 
 (b) мотивации к постоянному участию и 
  (c)заинтересованности в передаче знаний и развитии потенциала.
  </span>
  </p>
<p class="bodytext">
    Ниже приведены некоторые соглашения о сотрудничестве между МУС и внешними партнерами проекта Юридические инструменты МУС. В группе таких институциональных партнеров происходит естественная ротация. Среди бывших партнеров Норвежский центр по правам человека, T.M.C. Институт Ассера и TRIAL. В последние годы стипендиаты CMN взяли на себя больше работы по поддержанию и обновлению содержимого базы данных Юридические инструменты МУС.
     Координационная группа благодарна за усилия внешних партнеров и стипендиатов CMN.
  </p>
<p class="bodytext">
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/051221_Intention_Agreement_Marburg__countersigned.pdf" class="text_3">051221_Intention_Agreement_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf" class="text_3">071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090720_LT_CA_Korea_University__countersigned_.pdf" class="text_3">090720_LT_CA_Korea_University__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090724_LT_CA_Nottingham__countersigned_.pdf" class="text_3">090724_LT_CA_Nottingham__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/091217_LT_CA_WRHWLI_countersigned.pdf" class="text_3">091217_LT_CA_WRHWLI_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100325_LT_CA_Goettingen__countersigned.pdf" class="text_3">100325_LT_CA_Goettingen__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf" class="text_3">100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100921_LT_CA_Salzburg_countersigned.pdf" class="text_3">100921_LT_CA_Salzburg_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110117_LT_CA_University_of_Richmond.pdf" class="text_3">110117_LT_CA_University_of_Richmond.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110610_LT_CA_Canadian_partners.pdf" class="text_3">110610_LT_CA_Canadian_partners.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111017_LT_Co-operation_Agreement_CEEI.pdf" class="text_3">111017_LT_Co-operation_Agreement_CEEI.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111025_LT_Co-operation_Agreement_UTDT.pdf" class="text_3">111025_LT_Co-operation_Agreement_UTDT.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/140916_Co-operation_Agreement_Jindal_Global_University.pdf" class="text_3">140916_Co-operation_Agreement_Jindal_Global_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150424_LTP_Agreement_Ain_Shams_countersigned.pdf" class="text_3">150424_LTP_Agreement_Ain_Shams_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150513_Agreement_UKZN-UCT__countersigned.pdf" class="text_3">150513_Agreement_UKZN-UCT__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf" class="text_3">150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Nottingham.pdf" class="text_3">150918_LT_CA_Nottingham.pdf</a></div>
<div style="margin-bottom:8px;"><a lang="nb" target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Stanford_University.pdf" class="text_3">150918_LT_CA_Stanford_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150921_LT_CA_NUS_Faculty_of_Law.pdf" class="text_3">150921_LT_CA_NUS_Faculty_of_Law.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151007_LT_CA_PKU_Law_School__countersigned.pdf" class="text_3">151007_LT_CA_PKU_Law_School__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_Canadian_partners__countersigned.pdf" class="text_3">151019_LT_CA_Canadian_partners__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_ULB.pdf" class="text_3">151019_LT_CA_ULB.pdf</a></div>
</p>

<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
</div>
`;
export default coordinationRu;
