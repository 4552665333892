const coordinationEn = `
<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
<div id="c258" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Co-ordination, CMN Fellows, and institutional partners</h1></div>
<!--  Header: [end] -->			
<!--  Text: [begin] -->
<p class="bodytext"><span class="text_">The Centre for International Law Research and Policy (</span><a href="https://www.cilrap.org/purpose/" class="text_1">CILRAP</a><span class="text_">) administers the ICC Legal Tools Database (LTD) for the International Criminal Court, pursuant to a 10-year Agreement between the Court and CILRAP entered into on 9 March 2021. </span> <a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> is the Director of CILRAP and the Co-ordinator of the Legal Tools Project. <a href="https://www.cilrap.org/bais/" target="_blank">Devasheesh Bais</a> is the Deputy Co-ordinator of the Legal Tools Project.</p>
<p class="bodytext"><span class="text_">CILRAP has deployed a team of more than 40 of its CMN Fellows to assist with the content-updating and -maintenance of the LTD. Both the CMN Fellows and the institutional partners described below make </span><i>pro bono</i><span class="text_">&nbsp;contributions towards the LTD as a digital public good. The CMN Fellows are a diverse group of individuals who contribute to the LTD on a longer-term basis, in the spirit of public-interest service. A list of current and former CMN Fellows is available&nbsp;</span><a target="_blank" href="https://www.casematrixnetwork.org/about-us/cmn-fellows/" class="text_3">here</a><span class="text_">. Those interested in contributing as a CMN Fellow may send an e-mail message with an updated&nbsp;</span><i>curriculum vitae</i><span class="text_">&nbsp;to 
 <a href="mailto:info@cilrap.org">info@cilrap.org</a>.
 Candidates are selected on the basis of (a) precision, (b) motivation to have an ongoing involvement, and (c) interest in knowledge-transfer and capacity development. </span></p>
<p class="bodytext">Below are some co-operation agreements between the ICC and external partners of the ICC Legal Tools Project. There is natural rotation in the group of such institutional partners. Former partners include the Norwegian Centre for Human Rights, the T.M.C. Asser Institute and TRIAL. In recent years, the CMN Fellows&nbsp;have shouldered more of the work to maintain and update the content of the ICC Legal Tools Database. The Co-ordination Team is grateful for the efforts of the external partners and the CMN Fellows.</p>
<p class="bodytext">
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/051221_Intention_Agreement_Marburg__countersigned.pdf" class="text_3">051221_Intention_Agreement_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf" class="text_3">071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090720_LT_CA_Korea_University__countersigned_.pdf" class="text_3">090720_LT_CA_Korea_University__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090724_LT_CA_Nottingham__countersigned_.pdf" class="text_3">090724_LT_CA_Nottingham__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/091217_LT_CA_WRHWLI_countersigned.pdf" class="text_3">091217_LT_CA_WRHWLI_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100325_LT_CA_Goettingen__countersigned.pdf" class="text_3">100325_LT_CA_Goettingen__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf" class="text_3">100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100921_LT_CA_Salzburg_countersigned.pdf" class="text_3">100921_LT_CA_Salzburg_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110117_LT_CA_University_of_Richmond.pdf" class="text_3">110117_LT_CA_University_of_Richmond.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110610_LT_CA_Canadian_partners.pdf" class="text_3">110610_LT_CA_Canadian_partners.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111017_LT_Co-operation_Agreement_CEEI.pdf" class="text_3">111017_LT_Co-operation_Agreement_CEEI.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111025_LT_Co-operation_Agreement_UTDT.pdf" class="text_3">111025_LT_Co-operation_Agreement_UTDT.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/140916_Co-operation_Agreement_Jindal_Global_University.pdf" class="text_3">140916_Co-operation_Agreement_Jindal_Global_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150424_LTP_Agreement_Ain_Shams_countersigned.pdf" class="text_3">150424_LTP_Agreement_Ain_Shams_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150513_Agreement_UKZN-UCT__countersigned.pdf" class="text_3">150513_Agreement_UKZN-UCT__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf" class="text_3">150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Nottingham.pdf" class="text_3">150918_LT_CA_Nottingham.pdf</a></div>
<div style="margin-bottom:8px;"><a lang="nb" target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Stanford_University.pdf" class="text_3">150918_LT_CA_Stanford_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150921_LT_CA_NUS_Faculty_of_Law.pdf" class="text_3">150921_LT_CA_NUS_Faculty_of_Law.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151007_LT_CA_PKU_Law_School__countersigned.pdf" class="text_3">151007_LT_CA_PKU_Law_School__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_Canadian_partners__countersigned.pdf" class="text_3">151019_LT_CA_Canadian_partners__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_ULB.pdf" class="text_3">151019_LT_CA_ULB.pdf</a></div>
</p>

<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
</div>
`;
export default coordinationEn;
