import { OCldDashboard } from '../redux-config';

export interface TRoleFilter {
    role?: string;
}

export const DEFAULT_ROLES_FILTER: TRoleFilter = {
    role: 'all',
};

export const RoleFilter = {
    applyFilter: (filter: TRoleFilter) => async (dispatch, getState) => {
        const { roles } = getState().CldDashboard;
        dispatch(RoleFilter.setFilter(filter));
        const whereFilter = RoleFilter.getWhereFilterFromAppliedFilter(filter);
        dispatch(OCldDashboard.fetchUsers(roles.searchTerm, whereFilter));
    },

    setFilter: (filter: TRoleFilter) => async dispatch => {
        dispatch({
            type: OCldDashboard.actions.CLD_DASHBOARD_ROLES_SET_FILTER,
            data: filter,
        });
    },

    resetFilter: () => async dispatch => {
        dispatch({
            type: OCldDashboard.actions.CLD_DASHBOARD_ROLES_SET_FILTER,
            data: { ...DEFAULT_ROLES_FILTER },
        });
    },

    getWhereFilterFromAppliedFilter: (filter: TRoleFilter) => {
        let whereFilter: Record<string, any> = {};
        filter = { ...DEFAULT_ROLES_FILTER, ...filter };
        //  {"match": {"roles.name.keyword":"CLD_ADMIN"}
        if (filter.role && filter.role !== 'all') {
            whereFilter = {
                match: {
                    'roles.name.keyword': filter.role,
                },
            };
        }
        return whereFilter;

        // if (filter.action) whereFilter.action = { inq: [filter.action] };
        // if (filter.by) whereFilter.by = filter.by;
        // if (filter.userId) whereFilter.userId = filter.userId;
        // if (filter.status) whereFilter.status = filter.status;
        // if (filter.type) whereFilter.subjectType = filter.type;
        // // whereFilter.subjectType = FILTER_TYPE_MAPPING[filter.type]

        // if (filter.gte && filter.lte)
        //     whereFilter.created = {
        //         between: [
        //             moment(filter.gte).format(DATE_FORMAT),
        //             moment(filter.lte)
        //                 .add(1, 'd')
        //                 .format(DATE_FORMAT),
        //         ],
        //     };
        // else {
        //     if (filter.lte) whereFilter.created = { lte: moment(filter.lte).format(DATE_FORMAT) };
        //     if (filter.gte) whereFilter.created = { gte: moment(filter.gte).format(DATE_FORMAT) };
        // }
        // return whereFilter;
    },
};
