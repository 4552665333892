import { Reducer } from "redux";
import { TReadingListState } from "./@types";
import { OReadingList } from "./redux-config";
import { TAction } from "../../Resources/Model";
import utilities from "../../Resources/Utils";
import { OAuth } from "../Authentication/redux-config";
import _ from 'lodash';


const initialState: TReadingListState = {
    readingList: undefined,
    currentReadingList: []
}


export const ReadingList: Reducer<TReadingListState> = (state = initialState, action: TAction) => {
    const {
        SINGLE_ITEM_ENTITY_RECEIVED,
        MODEL_ENTITY_ITEM_POST_SUCCESS,
        FETCHED_DOCUMENTS_IN_READING_LIST,
        FETCHING_ITEMS_IN_READING_LIST,
        DELETED_READING_LIST,
        UPDATED_READING_LIST,
        DOCUMENT_REMOVED_FROM_READING_LIST
    } = OReadingList.actions;

    const { USER_LOGGED_OUT } = OAuth.actions;

    switch (action.type) {
        case DOCUMENT_REMOVED_FROM_READING_LIST: {
            return { ...state, currentReadingList: state.currentReadingList.filter(doc => doc.id !== action.data) }
        }
        case UPDATED_READING_LIST: {
            return { ...state, readingList: utilities.updateItemList((state.readingList || []), action.data, 'UPDATE') }
        }
        case DELETED_READING_LIST: {
            return { ...state, readingList: _.filter(state.readingList, r => r.id !== action.data) }
        }
        case USER_LOGGED_OUT: {
            return { ...state, readingList: [], currentReadingList: [] }
        }
        case FETCHING_ITEMS_IN_READING_LIST: {
            return { ...state, currentReadingList: [] }
        }
        case FETCHED_DOCUMENTS_IN_READING_LIST: {
            return { ...state, currentReadingList: action.data }
        }
        case SINGLE_ITEM_ENTITY_RECEIVED: {
            return { ...state, readingList: action.data }
        }
        case MODEL_ENTITY_ITEM_POST_SUCCESS: {
            let { readingList } = state;
            return { ...state, readingList: utilities.updateItemList((readingList || []), action.data, 'ADD') }
        }
        default: return { ...state }
    }
}