import _ from 'lodash';



export const getSearchResultHighlightConfig = (item: any, highlight: string, key: string = 'ltdDoc.title') => {
    let pre = '...'
    let end = '...';

    const regex1 = new RegExp("<span class='search-highlight'>", 'ig')
    const regex2 = new RegExp("</span>", 'ig')
    const _highlight = highlight.replace(regex1, '').replace(regex2, '');
    const _compareText: string = _.get(item, key);
    if (_highlight === _compareText) {
        pre = end = ''
    } else if (_compareText) {
        if (_.startsWith(_compareText, _highlight))
            pre = '';
        if (_.endsWith(_compareText, _highlight))
            end = ''
    }

    // debugger
    return {
        pre,
        end
    }
}


export const orderHighlightByFields = (highlights: Record<string, string[]>, priorityArray: string[] = ['ltdDoc.title', 'title']) => {
    const keys = Object.keys(highlights);
    if (!highlights || keys.length === 0) return [];

    let highlightTexts: string[] = [];
    priorityArray.forEach(k => {
        if (highlights[k])
            highlightTexts = [...highlightTexts, ...highlights[k]]
    });
    keys.forEach(k => {
        if (priorityArray.indexOf(k) === -1)
            highlightTexts = [...highlightTexts, ...highlights[k]]
    })
    return highlightTexts;
}