import { Models } from '../../Resources/Model';
import { TDocumentTreeState, TNode } from './@types';
import _ from 'lodash';
import {
    getAllChildren,
    getAllSiblings,
    getAllAncestors,
    findNode,
    getParent,
    groupTree,
    selectAllNodes,
} from './cld-documentTree-reducer';
import { mapValueAndId, OCLDDocuments, mapParentIdsToValues, mapvaluesToParentIds } from '../CLDDocuments/redux-config';
import utilities from '../../Resources/Utils';
import Axios, { CancelTokenSource } from 'axios';

export const getKeywordSearchKeyWithLocale = () => {
    const locale = utilities.getLocale() || 'en';
    return `keyword_text__${locale}`;
};
class CLDDocumentTree extends Models {
    constructor() {
        super('keywords', {
            CLD_FETCHED_TREE_NODES: 'CLD_FETCHED_TREE_NODES',
            CLD_FETCHED_CHILDREN: 'CLD_FETCHED_CHILDREN',
            CLD_REMOVED_CHILDREN: 'CLD_REMOVED_CHILDREN',
            CLD_RESET_TREE: 'CLD_RESET_TREE',
            CLD_TREE_ITEM_SELECTED: 'CLD_TREE_ITEM_SELECTED',
            CLD_TREE_ITEM_UNSELECTED: 'CLD_TREE_ITEM_UNSELECTED',
            CLD_NEW_TREE_FETCHED: 'CLD_NEW_TREE_FETCHED',
            CLD_TREE_CLEAR_SELECTION: 'CLD_TREE_CLEAR_SELECTION',
        });
    }

    clearSelection = () => dispatch => {
        dispatch({
            type: this.actions.CLD_TREE_CLEAR_SELECTION,
        });
    };

    treeSelectALlItems = (isKeywordSelection: boolean = false) => (dispatch, getState) => {
        const DocumentTree: TDocumentTreeState = getState().CLDDocumentTree;
        // let idsToSearch = DocumentTree.idsToSearch || [];
        const tree = DocumentTree.tree || [];
        const selectedNodes = selectAllNodes(tree, isKeywordSelection);
        _.forEach(selectAllNodes, node => mapValueAndId(node));

        const itemsSelected = selectedNodes.map(node => node.id);
        const idsToSearch = selectedNodes.map(node => ({
            id: node.id,
            includeChildren: _.isEmpty(node.children),
        }));

        dispatch({
            type: this.actions.CLD_TREE_ITEM_SELECTED,
            data: {
                itemsSelected,
                idsToSearch,
            },
        });
    };

    treeItemSelect = (node: TNode) => async (dispatch, getState) => {
        mapValueAndId(node);
        const DocumentTree: TDocumentTreeState = getState().CLDDocumentTree;
        let itemsSelected = DocumentTree.itemsSelected || [];
        let idsToSearch = DocumentTree.idsToSearch || [];
        const tree = DocumentTree.tree || [];
        // const initialSelection = idsToSearch;

        if (_.indexOf(itemsSelected, node.id) > -1) {
            itemsSelected = _.filter(itemsSelected, id => id !== node.id);
            idsToSearch = _.filter(idsToSearch, i => i.id !== node.id);

            dispatch({
                type: this.actions.CLD_TREE_ITEM_UNSELECTED,
                data: {
                    itemsSelected,
                    idsToSearch,
                },
            });
            // dispatch(OCLDDocuments.applyQuery({
            //     where: { parentIds: itemsSelected }
            // }));
        } else {
            itemsSelected = _.union(itemsSelected, [node.id]);
            idsToSearch.push({
                id: node.id,
                includeChildren: _.isEmpty(node.children),
            });

            dispatch({
                type: this.actions.CLD_TREE_ITEM_SELECTED,
                data: {
                    itemsSelected,
                    idsToSearch,
                },
            });
        }
    };

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    treeSearch = (term: string) => async (dispatch, getState) => {
        const newValue = term;
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            if (this.ajaxCallHandle) {
                this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
            }
            dispatch(this.resetTree());
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url: `/keywords/tree-search`,
                params: {
                    // filter: { match_phrase: { keyword_text: term } }
                    filter: {
                        where: {
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            [getKeywordSearchKeyWithLocale()]: term,
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            keywordGeneratedId: term,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                cancelToken: this.ajaxCallHandle.token,
            });

            const cleanedData: TNode[] = _.filter(res.data.children, child => !_.isEmpty(child));
            const groupedTree = groupTree(cleanedData, []);
            dispatch({
                type: this.actions.CLD_NEW_TREE_FETCHED,
                data: groupedTree,
            });
        } catch (error) {}
    };

    // treeItemSelect = (node: TNode) => async (dispatch, getState) => {
    //     const DocumentTree: TDocumentTreeState = getState().CLDDocumentTree;
    //     let itemsSelected = DocumentTree.itemsSelected || [];
    //     console.log("tree item select");
    //     if (_.indexOf(itemsSelected, node.id) > -1) {
    //         const newSelection = _.filter(itemsSelected, id => id !== node.id);
    //         dispatch({
    //             type: this.actions.CLD_TREE_ITEM_SELECTED,
    //             data: { itemsSelected: newSelection }
    //         })
    //     }
    //     else {
    //         dispatch({
    //             type: this.actions.CLD_TREE_ITEM_UNSELECTED,
    //             data: { itemsSelected: [...itemsSelected, node.id] }
    //         })
    //     }
    // }

    resetTree = () => dispatch => {
        dispatch({
            type: this.actions.CLD_RESET_TREE,
        });
    };

    removeChildren = parentId => (dispatch, getState) => {
        dispatch({
            type: this.actions.CLD_REMOVED_CHILDREN,
            data: parentId,
        });
    };

    fetchBaseLevelNodes = () => (dispatch, getState) => {
        const params = {
            filter: {
                where: { level: 1 },
            },
        };
        return new Promise((resolve, reject) => {
            dispatch(this.getItemsList(params)).then(async res1 => {
                try {
                    const res2 = await dispatch(this.getItemsList({ filter: { where: { level: 2 } } }));
                    const groupedTree = groupTree(res1.data, res2.data);

                    dispatch({
                        type: this.actions.CLD_FETCHED_TREE_NODES,
                        data: groupedTree,
                    });
                } catch (error) {}
            });
        });
    };

    fetchLevelNodes = (level: Number) => {
        const params = {
            filter: {
                where: { level },
            },
        };
        return utilities.request({
            url: '/nodes',
            params,
        });
    };

    fetchChildren = parentId => (dispatch, getState) => {
        const params = {
            filter: { where: { parentId } },
        };
        const { CLDDocumentTree } = getState();
        let { itemsSelected, idsToSearch } = CLDDocumentTree;
        return new Promise((resolve, reject) => {
            dispatch(this.getItemsList(params)).then(res => {
                dispatch({
                    type: this.actions.CLD_FETCHED_CHILDREN,
                    data: {
                        children: res.data,
                        parentId,
                    },
                });
                const childrenIds = _.map(res.data, node => node.id);
                if (_.size(_.intersection(itemsSelected, childrenIds)) === 0) {
                    idsToSearch = _.filter(idsToSearch, i => i.id !== parentId);
                    itemsSelected = _.filter(itemsSelected, id => id !== parentId);
                    dispatch({
                        type: this.actions.CLD_TREE_ITEM_UNSELECTED,
                        data: {
                            itemsSelected,
                            idsToSearch,
                        },
                    });
                }
            });
        });
    };
}

export let OCLDDocumentTree = new CLDDocumentTree();
