import { useContext, useEffect } from 'react';
import utilities from '../../Resources/Utils';
import { TranslationContext } from './TranslationContext';
import { AppContext } from '../../Contexts/AppContext';

function useLocaleInit() {
    const { context } = useContext(AppContext);
    const { changeLocale } = useContext(TranslationContext);
    useEffect(() => {
        const siteWideLocale = utilities.getLocale();
        const isSccVisited = utilities.isSccVisited();
        if (context === 'scc' && !isSccVisited) {
            changeLocale('fr');
            utilities.setIsSccVisited();
            return;
        } else if (siteWideLocale) changeLocale(siteWideLocale);
        else changeLocale('en');
    }, [context]);
}

export default useLocaleInit;
