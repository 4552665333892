import { Reducer } from "redux";
import { TDocument } from "../LTDDocuments/@types";
import { TDecisionData } from "./@types";
import { ONewDecision } from "./redux-config";


export interface TAddNewDecisionState {
    open: boolean;
    formData: TDecisionData
    decision?: TDocument
    isFormDirty: boolean
}


const initialState: TAddNewDecisionState = {
    open: false,
    formData: {},
    isFormDirty: false,
}

export const NewDecision: Reducer<TAddNewDecisionState> = (state = initialState, action): TAddNewDecisionState => {
    const {
        OPEN_NEW_DECISION_DIALOG,
        CLOSE_NEW_DECISION_DIALOG,
        SET_FORM_DATA
    } = ONewDecision.actions;
    switch (action.type) {
        case SET_FORM_DATA:
            return { ...state, formData: action.data, isFormDirty: true }
        case OPEN_NEW_DECISION_DIALOG:
            return { ...state, open: true, formData: action.data.formData, decision: action.data.decision, isFormDirty: true };
        case CLOSE_NEW_DECISION_DIALOG:
            return { ...state, open: false, formData: {}, decision: undefined, isFormDirty: false };
        default:
            return state;
    }
}