import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Chip } from '@material-ui/core'
import classNames from 'classnames';
import _ from 'lodash'
import { ChipProps } from '@material-ui/core/Chip';
import { WithTranslationProps, withTranslations } from '../Features/Translations/withTranslations';

interface IProps extends WithStyles<typeof STYLES> {
    title: string
    formkey: string
    options: { name: string, value: any, translationKey: string }[]
    onChange: (key: string, value: string) => void
    value: any
    multiple?: boolean
    color?: ChipProps['color']
}

class ChipSelect extends Component<IProps & WithTranslationProps> {

    render() {
        const { classes, title, options, value, multiple = false, formkey, color = "primary", getTranslation } = this.props
        return (
            <div>
                <Typography>{title}</Typography>
                {
                    options.map(option => {
                        const selected = multiple ? (_.includes(value, option.value) ? true : false) : value === option.value ? true : false
                        return (
                            <Chip className={classNames(classes.chipItem, selected ? classes.selected : '')}
                                onClick={e => this.props.onChange(formkey, option.value)}
                                key={option.name}
                                label={getTranslation(option.translationKey) || option.name}
                                color={selected ? color : "default"}
                            />
                        )
                    })
                }
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    chipItem: {
        marginRight: 6,
    },
    selected: {
        color: theme.palette.common.white
    }
})

export default withStyles(STYLES)(withTranslations(ChipSelect))