import { TAction, Models } from '../Resources/Model';
import { Reducer } from 'redux';
import utilities from '../Resources/Utils';
import { OAuth } from '../Features/Authentication/redux-config';
import _ from 'lodash';
import { OReadingList } from '../Features/ReadingList/redux-config';
import { includeValueOptions } from '../Features/LTDDocuments/redux-config';
import { BOOLEAN_OPTIONS, SUBJECT } from '../Features/AddNewLTDDocument/@types';
import { OTranslation } from '../Features/Translations/redux-config';
import { CldField } from '../Services/CldFields.types';
import { CldFieldService } from '../Services/CldFields.service';
import { OCldField } from '../Features/CldField/redux-config';
import Axios from 'axios';

export interface TAppState {
    appInit: boolean;
    loading: boolean;
    appConstants: any;
    currentUrl: string;
    ltdIncludeOptions: Record<string, any>;
    toast: TToast;
    confirmationDialog: TConfirmationDialog;
    sidebar: TSidebar;
    awardBanner: boolean;
}

export interface TSidebar {
    open: boolean;
    component: JSX.Element | null;
    title: string;
}

export type TToastVariant = 'success' | 'error';

export interface TToast {
    open: boolean;
    message: string;
    variant: TToastVariant;
}

export interface TConfirmationDialog {
    open: boolean;
    message: string;
    actions: {
        onConfirm: Function | undefined;
        onCancel: Function | undefined;
    };
}

export class AppClass extends Models {
    constructor() {
        super('app', {
            SET_CURRENT_URL: 'SET_CURRENT_URL',
            SHOW_TOAST_MESSAGE: 'SHOW_TOAST_MESSAGE',
            CLOSE_TOAST: 'CLOSE_TOAST',
            SHOW_CONFIRMATION_DIALOG: 'SHOW_CONFIRMATION_DIALOG',
            CLOSE_CONFIRMATION_DIALOG: 'CLOSE_CONFIRMATION_DIALOG',
            SET_APP_CONSTANTS: 'SET_APP_CONSTANTS',
            LTD_INCLUDE_OPTIONS_FETCHED: 'LTD_INCLUDE_OPTIONS_FETCHED',
            APP_INITIALIZED: 'APP_INITIALIZED',

            SIDEBAR_OPEN: 'SIDEBAR_OPEN',
            SIDEBAR_CLOSE: 'SIDEBAR_CLOSE',
            SET_SIDEBAR_TITLE: 'SET_SIDEBAR_TITLE',

            SET_SHOW_LOADER: 'SET_SHOW_LOADER',

            HIDE_AWARD_BANNER: 'HIDE_AWARD_BANNER',
        });
    }

    showLoader = () => dispatch => {
        dispatch({
            type: this.actions.SET_SHOW_LOADER,
            data: true,
        });
    };

    hideLoader = () => dispatch => {
        dispatch({
            type: this.actions.SET_SHOW_LOADER,
            data: false,
        });
    };

    setSidebarTitle = (title: string) => dispatch =>
        dispatch({
            type: this.actions.SET_SIDEBAR_TITLE,
            data: title,
        });

    openSidebar = (component: JSX.Element, title?: string) => dispatch => {
        if (title)
            dispatch({
                type: this.actions.SET_SIDEBAR_TITLE,
                data: title,
            });
        dispatch({
            type: this.actions.SIDEBAR_OPEN,
            data: component,
        });
    };

    closeSidebar = () => dispatch =>
        dispatch({
            type: this.actions.SIDEBAR_CLOSE,
        });

    getAppConstants = () => async dispatch => {
        try {
            const res = await utilities.request({
                url: '/appmodels/constants',
            });
            dispatch({
                type: this.actions.SET_APP_CONSTANTS,
                data: res.data,
            });
            return res;
        } catch (error) {
            throw error;
        }
    };

    setCurrentUrl = url => dispatch => {
        dispatch({
            type: this.actions.SET_CURRENT_URL,
            data: url,
        });
    };

    showToast = (message: string, variant: TToastVariant) => dispatch => {
        dispatch({
            type: this.actions.SHOW_TOAST_MESSAGE,
            data: { message, variant },
        });
    };

    closeToast = () => dispatch => {
        dispatch({
            type: this.actions.CLOSE_TOAST,
        });
    };

    showConfirmationDialog = (message: string, onConfirm, onCancel) => dispatch =>
        dispatch({
            type: this.actions.SHOW_CONFIRMATION_DIALOG,
            data: {
                message,
                actions: {
                    onConfirm,
                    onCancel,
                },
            },
        });

    closeConfirmationDialog = () => dispatch =>
        dispatch({
            type: this.actions.CLOSE_CONFIRMATION_DIALOG,
        });

    getLtdIncludeOptions = (isAuthenticated: boolean = false) => async dispatch => {
        try {
            const options = await includeValueOptions(isAuthenticated);
            dispatch({
                type: this.actions.LTD_INCLUDE_OPTIONS_FETCHED,
                data: {
                    ...options,
                    booleanOption: BOOLEAN_OPTIONS,
                    subject: SUBJECT,
                },
            });
        } catch (error) {}
    };

    hideAwardBanner = () => dispatch =>
        dispatch({
            type: this.actions.HIDE_AWARD_BANNER,
        });

    init = () => async dispatch => {
        const locale = utilities.getLocale();
        Axios.defaults.headers.common['locale'] = locale || 'en';

        try {
            let promises = [dispatch(OApp.getAppConstants()), dispatch(OCldField.getCldFields())];
            if (utilities.isAuthenticated() && !!utilities.getUserId()) {
                promises.push(
                    dispatch(
                        OAuth.getItem(utilities.getUserId(), {
                            filter: { include: [{ relation: 'roles', scope: ['name'] }] },
                        })
                    )
                );
                promises.push(dispatch(OReadingList.fetchReadingLists(utilities.getUserId())));
                dispatch(OApp.getLtdIncludeOptions(true));
            } else {
                dispatch(OApp.getLtdIncludeOptions());
            }
            await Promise.all(promises);
            dispatch({ type: this.actions.APP_INITIALIZED });
            dispatch(OTranslation.getStaticTranslations());
        } catch (error) {
            if (_.get(error, 'response.status') === 401) {
                dispatch({ type: this.actions.APP_INITIALIZED });
                utilities.clearCookies();
            }
            throw error;
        }
    };
}

export const OApp = new AppClass();

const initalState: TAppState = {
    currentUrl: '',
    loading: false,
    appInit: false,
    appConstants: {},
    ltdIncludeOptions: {},
    toast: {
        open: false,
        message: '',
        variant: 'success' as TToastVariant,
    },
    confirmationDialog: {
        open: false,
        message: '',
        actions: {
            onCancel: undefined,
            onConfirm: undefined,
        },
    },
    sidebar: {
        open: false,
        component: null,
        title: '',
    },
    awardBanner: true,
};

export const App: Reducer<TAppState> = (state = initalState, action: TAction): TAppState => {
    const {
        SET_CURRENT_URL,
        SHOW_TOAST_MESSAGE,
        CLOSE_TOAST,
        SHOW_CONFIRMATION_DIALOG,
        CLOSE_CONFIRMATION_DIALOG,
        SET_APP_CONSTANTS,
        APP_INITIALIZED,
        SIDEBAR_CLOSE,
        SIDEBAR_OPEN,
        SET_SIDEBAR_TITLE,
        LTD_INCLUDE_OPTIONS_FETCHED,
        SET_FORM_EDITOR,
        SET_SHOW_LOADER,
        HIDE_AWARD_BANNER,
    } = OApp.actions;

    switch (action.type) {
        case SET_SHOW_LOADER:
            return { ...state, loading: action.data };
        case LTD_INCLUDE_OPTIONS_FETCHED: {
            return { ...state, ltdIncludeOptions: action.data };
        }
        case APP_INITIALIZED: {
            return { ...state, appInit: true };
        }
        case SET_APP_CONSTANTS: {
            return { ...state, appConstants: action.data };
        }
        case SIDEBAR_CLOSE: {
            return { ...state, sidebar: { ...state.sidebar, open: false, component: null } };
        }
        case SET_SIDEBAR_TITLE: {
            return { ...state, sidebar: { ...state.sidebar, title: action.data } };
        }
        case SIDEBAR_OPEN: {
            return { ...state, sidebar: { ...state.sidebar, open: true, component: action.data } };
        }
        case SHOW_CONFIRMATION_DIALOG: {
            return {
                ...state,
                confirmationDialog: { open: true, message: action.data.message, actions: action.data.actions },
            };
        }
        case CLOSE_CONFIRMATION_DIALOG: {
            return {
                ...state,
                confirmationDialog: {
                    open: false,
                    message: '',
                    actions: { onCancel: undefined, onConfirm: undefined },
                },
            };
        }
        case CLOSE_TOAST: {
            return { ...state, toast: { ...state.toast, open: false, message: '' } };
        }
        case SHOW_TOAST_MESSAGE: {
            return { ...state, toast: { open: true, message: action.data.message, variant: action.data.variant } };
        }
        case SET_CURRENT_URL: {
            return { ...state, currentUrl: action.data };
        }
        case HIDE_AWARD_BANNER: {
            return { ...state, awardBanner: false };
        }
        default:
            return state;
    }
};
