import React, { FC } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Log from "./Log";
import utilities from "../../Resources/Utils";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import THEME from "../../Resources/Theme";
import _ from "lodash";
import { ICldPermissions, TUser } from "../../Features/Authentication/@types";
import FullscreenLoader from "../../Components/FullscreenLoader";
import StaticTranslation from "./StaticTranslation";
import Keywords from "./Keywords";
import { TState } from "../../RootReducer";
import Cms from "./Cms";
import MetaTag from "../../Components/MetaTag";
import Roles from "./Roles";

export interface CLDDashboardProps
  extends WithStyles<typeof STYLES>,
  IStateProps,
  IDispatchProps,
  RouteComponentProps { }

interface IStateProps {
  cldPermissions: ICldPermissions;
  user: TUser;
  loading: boolean;
}

interface IDispatchProps { }

export type TCLDDashboardTabKey = "log" | "static-translation" | 'cms' | 'keyword-tree' | 'roles';

export const CLD_DASHBOARD_ROUTES: {
  path: string;
  /**
   * @author Adnan Husain
   * @description
   * Added this key since in some cases the redirect path maybe simple ,
   * but it's route might have some extra handling.
   * This will take priority if provided.
   * */
  route?: string;
  component: any;
  name: string;
  key: TCLDDashboardTabKey;
  exact?: boolean;
}[] = [
    {
      path: "/cld-dashboard/static-translation/",
      route: "/cld-dashboard/static-translation/:path?",
      component: StaticTranslation,
      name: "STATIC TRANSLATION",
      key: "static-translation",
      exact: false,
    },
    {
      path: "/cld-dashboard/keywords",
      component: Keywords,
      name: "KEYWORDS",
      key: "keyword-tree",
    },
    { path: "/cld-dashboard/log", component: Log, name: "LOGS", key: "log" },
    { path: "/cld-dashboard/cms", component: Cms, name: "CMS", key: "cms", exact: false },
    { path: "/cld-dashboard/roles", component: Roles, name: "Roles", key: "roles", exact: false },
  ];

const CLDDashboard: FC<CLDDashboardProps> = (props) => {
  const { classes, cldPermissions, location, loading = false } = props;

  const _DASHBOARD_ROUTES = _.filter(
    CLD_DASHBOARD_ROUTES,
    (option) => _.indexOf(cldPermissions.dashboardTabs, option.key) > -1
  );

  if (!utilities.isAuthenticated()) {
    return <Redirect to="/cld" />;
  }
  return (
    <div className={classes.root}>
      <MetaTag title='Dashboard' />
      <Switch>
        <Route
          exact
          key={"/cld-dashboard"}
          path={"/cld-dashboard"}
          render={() => <Redirect to="/cld-dashboard/static-translation" />}
        />
        {_DASHBOARD_ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.route || route.path}
            exact={route.exact !== undefined ? route.exact : true}
            component={route.component}
          />
        ))}
      </Switch>
      {loading ? <FullscreenLoader color="primary" /> : null}
    </div>
  );
};

const mapStateToProps = (state: TState) => ({
  ..._.pick(state.Auth, ["cldPermissions", "user"]),
  ..._.pick(state.CldDashboard, "loading"),
});

const mapDispatchToProps = (dispatch) => ({});

const STYLES = (theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      paddingTop: 50,
      background: THEME.Colors.Grayscale.Grey5,
      left: 0,
      right: 0,
      top: 0,
      zIndex: 2,
      position: "absolute",
      overflowY: "auto",
    },
  });

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(CLDDashboard))
);
