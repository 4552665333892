import React, { createContext, useEffect, useState } from 'react';
import utilities from '../../Resources/Utils';
import { TState } from '../../RootReducer';
import { connect } from 'react-redux';
import Axios from 'axios';
import { OCldField } from '../CldField/redux-config';


export type TranslationLocale = 'en' | 'fr' | 'es';

export interface ITranslationContext {
    locale: TranslationLocale;
    changeLocale: (locale: TranslationLocale) => void;
    staticTranslation: Record<string, any>;
}



export const TranslationContext = createContext<ITranslationContext>({
    locale: 'en',
    changeLocale: () => { },
    staticTranslation: {}
})


const _TranslationContextProvider: React.FC<any> = ({
    staticTranslation,
    fetchCldFields,
    children
}) => {
    const [locale, setLocale] = useState<TranslationLocale>('en');

    useEffect(() => {
        Axios.defaults.headers.common['locale'] = locale || 'en';
        Axios.defaults.headers.common['primaryLocale'] = 'en';
        // fetchCldFields();
    }, [locale])

    const changeLocale = (locale: TranslationLocale) => {
        setLocale(locale);
        utilities.setLocale(locale);
    }

    return (
        <TranslationContext.Provider value={{ locale, changeLocale, staticTranslation }}>
            {children}
        </TranslationContext.Provider>
    )
}

const mapStateToProps = (state: TState) => ({
    staticTranslation: state.Translations.staticTranslation
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchCldFields: () => dispatch(OCldField.getCldFields())
});

export const TranslationContextProvider = connect(mapStateToProps, mapDispatchToProps)(_TranslationContextProvider);
