const purlFr = `<div id="content" class="content">
    <div id="breadcrumbs">
        <p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="purl/">PURL</a></p>
    </div>
    <div id="getUrlText">
        <div
            style="cursor:pointer;float:right;"
            title="Collapse tree"
            id="collapseTree"
        >
            <i class="glyphicon glyphicon-collapse-down"></i>
        </div>
        <a href="javascript:window.print();" style="float:right;"
            ><i class="glyphicon glyphicon-print"></i
        ></a>
    </div>
    <br style="clear:both;" />
    <!--  CONTENT ELEMENT, uid:147/text [begin] -->
    <div id="c147" class="csc-default">
        <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
            <h1 class="csc-firstHeader">URL permanentes (PURL)</h1>
        </div>
        <!--  Header: [end] -->

        <!--  Text: [begin] -->
        <p class="bodytext">
            La base de données des Outils juridiques de la CPI se sert d’URL permanentes (PURL). Ainsi, l’URL ou l’adresse internet de chaque document de la base de données est permanente et ne changera pas. Autrement dit, en tant qu’utilisateur, vous pouvez renvoyer aux documents de la base de données en citant leur PURL dans le corps du texte (ou dans les notes de bas de page). Si vous insérez une PURL en tant qu'hyperlien vers le document en question, ce lien vers les Outils juridiques ne sera jamais rompu. 
        </p>
        <p class="bodytext">
            En utilisant ces PURL en hyperlien vers les documents juridiques enregistrés dans la partie publique et libre d'accès de la base de données des Outils juridiques, vous contribuez à bâtir un espace partagé de références et de sources juridiques qui ne cesse de s'enrichir. Ce nouvel espace public dans le domaine du droit international pénal permet d'ouvrir l'accès aux informations juridiques au plus grand nombre et de mettre tous les praticiens en la matière sur un pied d’égalité. Ainsi, le projet d’Outils juridiques concourt au renforcement des capacités, en particulier au niveau national.
        </p>
        <p class="bodytext">
            Le 30 mai 2012, la Chambre d’appel de la CPI a commencé à utiliser dans ses décisions des PURL vers la base de données des Outils juridiques.<a href="https://www.legal-tools.org/en/doc/9813bb/" target="_blank"> L’arrêt Katanga du 7 mars 2014</a> contient des hyperliens vers 538 sources juridiques enregistrées dans les Outils juridiques. Depuis, nombre de décisions de la CPI présentent de tels hyperliens, et dans le cadre d’un accord de dix ans conclu en mars 2021 entre la CPI et le Centre for International Law Research and Policy (CILRAP, qui gère la base de données des Outils juridiques pour la CPI), la Cour s’est engagée à insérer, dans la mesure du possible, « des hyperliens dans ses jugements, arrêts et autres décisions et documents publics pertinents, au moyen de PURL de la base de données ». Le projet d’Outils juridiques encourage les tribunaux, gouvernements, organisations non gouvernementales, chercheurs, maisons d’édition et autres entités à inclure en hyperlien des références vers la PURL des documents des Outils juridiques, chaque fois que cela est possible. 
        </p>
        <ul>
            <li>
                <a
                    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/161111_LTD_leaflet__Fourth_Edition.pdf"
                    target="_blank"
                    >
                    161116 Building a public commons of legal sources in international criminal law, 4e édition</a
                >
            </li>
        </ul>
        <!--  Text: [end] -->
    </div>
    <!--  CONTENT ELEMENT, uid:147/text [end] -->
</div>`;

export default purlFr;
