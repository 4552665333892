import {
    createStyles,
    Dialog,
    MuiThemeProvider,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import cldTheme from '../../MuiTheme/cld.theme';
import THEME from '../../Resources/Theme';
import { TState } from '../../RootReducer';
import DecisionForm from './DecisionForm';
import DialogTitle from './DialogTitle';
import get from 'lodash/get';
import { TDecisionData } from '../../Features/AddNewDecision/@types';
import { ONewDecision } from '../../Features/AddNewDecision/redux-config';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';
import { TCldFieldState } from '../../Features/CldField/redux-config';

interface NewDecisionDialogProps
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    IStateProps { }

interface IDispatchProps {
    closeDialog: () => void;
    handleFormChange: (data: TDecisionData) => void;
    saveForm: (id: string, data: Record<string, any>) => Promise<void>;
}

interface IStateProps {
    open: boolean;
    isFormDirty: boolean;
    decision?: TDocument;
    formData: TDecisionData;
    CldFields: TCldFieldState;
    appConstants: any;
}

const NewDecisionDialog: React.FC<NewDecisionDialogProps> = props => {
    const {
        classes,
        open,
        decision,
        isFormDirty,
        formData,
        closeDialog,
        handleFormChange,
        saveForm,
    } = props;


    const handleSave = async () => {
        if (!decision) return;
        await saveForm(decision.id, formData);
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        closeDialog();
    };

    return (
        <ThemeProvider>
            <Dialog
                fullScreen
                open={open || false}
                PaperProps={{ className: classes.root }}
                classes={{ container: classes.dialogContainer }}
            >
                <DialogTitle
                    handleClose={handleCloseDialog}
                    classes={{ dialogTitle: classes.title }}
                    isFormDirty={isFormDirty}
                    title='Import Decision'
                    handleSave={handleSave}
                />
                <DecisionForm
                    formData={formData}
                    handleChange={handleFormChange}
                    CldFields={props.CldFields}
                    appConstants={props.appConstants}
                />
            </Dialog>
        </ThemeProvider>
    );
};

const mapStateToProps = (state: TState): IStateProps => ({
    open: get(state.NewDecision, 'open', false),
    formData: get(state.NewDecision, 'formData', {}),
    decision: get(state.NewDecision, 'decision'),
    isFormDirty: get(state.NewDecision, 'isFormDirty', false),
    CldFields: state.CldFields || {},
    appConstants: get(state.App, 'appConstants', {}),
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(ONewDecision.closeNewDecisionDialog()),
    handleFormChange: (data: TDecisionData) =>
        dispatch(ONewDecision.setFormData(data)),
    saveForm: async (id: string, form: Record<string, any>) =>
        dispatch(ONewDecision.updateDecisionImport(id, form)),
});

const ThemeProvider: React.FC = props => {
    return (
        <MuiThemeProvider theme={cldTheme}>{props.children}</MuiThemeProvider>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            background: THEME.Colors.Grayscale.Grey5,
            inset: 0,
            margin: 0,
            maxHeight: '100vh',
            borderRadius: 0,
            maxWidth: 'inherit',
        },
        title: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
        },
        dialogContainer: {},
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(NewDecisionDialog));
