import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Button, Popover, Fade, Paper, Typography, FormControl, InputLabel, Input } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import THEME from '../../../Resources/Theme';
import Group from './Group';
import _ from 'lodash'
import { TUser, ILtdPermissions } from '../../../Features/Authentication/@types';
import { Redirect } from 'react-router-dom';
import { TGroup } from '../../../Features/LtdDashboard/@types';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import utilities from '../../../Resources/Utils';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { TNode } from '../../../Features/DocumentsTree/@types';


interface IStateProps {
    user: TUser
    ltdPermissions: ILtdPermissions
    groups: TGroup[],
    pageNumber: number,
    tree: Array<TNode>
}

interface IDispatchProps {
    fetchGroups: (pageNumber: number) => void
    createGroup: (formData: Record<string, any>) => void
    fetchLTDTree?: Function
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class Partners extends Component<IProps, IState> {
    state = {
        formData: {
            name: ''
        },
        anchorEl: null
    }

    componentDidMount() {
        if (_.isEmpty(this.props.tree) && this.props.fetchLTDTree)
            this.props.fetchLTDTree();
        if (_.isEmpty(this.props.groups))
            this.props.fetchGroups(0)
    }

    handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ formData: { name: e.target.value } });
    }

    handlePopper = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
    }


    handleSubmit = () => {
        const { formData } = this.state;
        this.props.createGroup(formData);
        this.setState({ anchorEl: null })
    }



    render() {
        const { classes, user, groups, ltdPermissions } = this.props
        const { anchorEl, formData } = this.state;

        if (!utilities.isAuthenticated())
            return <Redirect to="/" />

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className="flex-row justify-center">
                        {
                            ltdPermissions.groupCreate ?
                                <Button aria-describedby='create-new-group' onClick={this.handlePopper} className={classes.addButton} variant="contained" color="primary">
                                    <i className="material-icons">add</i>
                                </Button> : null
                        }
                    </div>
                    <div className={classes.listContainer}>
                        {
                            groups.map(group => (
                                <Group key={group.id} data={group} />
                            ))
                        }
                    </div>
                </div>
                <Popover
                    id='create-new-group'
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handlePopper}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Fade timeout={350}>
                        <Paper className={classes.popperContent} style={{ opacity: 1 }}>
                            <div className="flex-row space-between">
                                <Typography variant="h6">Create new group</Typography>
                                <Button onClick={this.handlePopper} className={classes.closePopperBtn}>
                                    <i className="material-icons">close</i>
                                </Button>
                            </div>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={'popper'}>Name</InputLabel>
                                <Input
                                    autoComplete="off"
                                    inputProps={{ id: 'popper' }}
                                    onChange={this.handleEdit}
                                    value={formData.name || ''}
                                />
                            </FormControl>
                            <Button color="primary" variant="outlined" className="my-2" onClick={this.handleSubmit} fullWidth>
                                'Create'
                                </Button>
                        </Paper>
                    </Fade>
                </Popover>
            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.Auth, ['user', 'ltdPermissions']),
    groups: _.get(state.LtdDashboard, 'group.groups'),
    pageNumber: _.get(state.LtdDashboard, 'group.pageNumber'),
    ..._.pick(state.DocumentTree, ['tree']),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchGroups: (pageNumber: number) => dispatch(OLtdDashboard.fetchGroups(pageNumber)),
    createGroup: (formData: Record<string, any>) => dispatch(OLtdDashboard.createGroup(formData)),
    fetchLTDTree: () => dispatch(ODocumentTree.fetchBaseLevelNodes()),
})

const MAIN_CONTENT_WIDTH = 1000;
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000
    },

    container: {
        width: MAIN_CONTENT_WIDTH,
        margin: '0 auto',
        paddingBottom: 100,
        display: 'flex',
        flexDirection: 'column',
    },
    addButton: {
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        width: 45,
        height: 45,
        borderRadius: 25,
        marginTop: 20,
        '& i': {
            color: 'white'
        }
    },
    listContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 30,
    },
    popperContent: {
        minWidth: 300,
        minHeight: 80,
        padding: 20,
    },
    popperButton: {
        position: 'absolute',
        right: 0,
        bottom: 10,
        color: 'white',
        minHeight: 0,
        minWidth: 0,
        height: 25,
        width: 25,
        borderRadius: 20,
        padding: 0
    },
    closePopperBtn: {
        marginLeft: 8,
        color: 'white',
        background: 'black',
        minHeight: 0,
        minWidth: 0,
        height: 30,
        width: 30,
        borderRadius: 20,
        padding: 0
    },
    // popper: {
    //     zIndex: 1800,
    //     position: 'absolute',
    //     top: 0
    // },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Partners))