import React, { Component } from 'react';
import _ from 'lodash';


interface IProps {
    onDone: (files: TFile[]) => void
    id: string
    encoding?: string
    multiple?: boolean
    accept: React.InputHTMLAttributes<HTMLInputElement>['accept']
    style?: React.InputHTMLAttributes<HTMLInputElement>['style']
    readAs?: keyof Pick<FileReader, 'readAsDataURL' | 'readAsBinaryString' | 'readAsText'>;
}

export interface TFile {
    name: string
    type: string
    size: number | string
    base64: string | ArrayBuffer | null
    file: any;
}

export default class FileInput extends Component<IProps> {

    handleChange = (e) => {
        let files = e.target.files;
        let allFiles: any = [];
        _.each(files, file => {
            let reader = new FileReader();
            reader.onload = event => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };
                allFiles.push(fileInfo);
                if (allFiles.length === files.length) {
                    if (_.isFunction(this.props.onDone))
                        this.props.onDone(allFiles);
                }
            }
            if (this.props.readAs === 'readAsText')
                reader[this.props.readAs](file, this.props.encoding || 'utf-8');
            else
                reader[this.props.readAs || 'readAsDataURL'](file);
            // reader.readAsDataURL(file);
        });
    }

    removeValue = (e: any) => {
        e.target.value = null
    }

    render() {
        const { multiple = false } = this.props;
        return (
            <input
                type="file"
                id={this.props.id}
                onChange={this.handleChange}
                onClick={this.removeValue}
                multiple={multiple}
                accept={this.props.accept}
                style={this.props.style}
            />
        )
    }
}
