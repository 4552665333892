import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, DialogContent, DialogTitle, Fab, Typography, Button, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


interface IProps extends WithStyles<typeof STYLES> {
    handleSubmit: () => void
    toggleAddUserDialog: () => void
    error: string
    formData: Record<string, any>
    handleFormChange: (e: React.ChangeEvent<{ name: string, value: unknown }>) => void
    loading: boolean
    isEdit: boolean
}


export const USER_FORM_KEYS = ['name', 'email', 'password', 'repeatPassword', 'company'];


class NewUserForm extends Component<IProps> {
    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (!value) return true;
            if (value !== this.props.formData.password) {
                return false;
            }
            return true;
        });
    }


    render() {
        const { classes, formData = {}, loading = false, isEdit = false } = this.props
        return (
            <div>
                <DialogTitle>{isEdit ? 'Update user' : 'Create new user'} </DialogTitle>
                <DialogContent>
                    <Fab size="small" className={classes.dialogCloseBtn} onClick={this.props.toggleAddUserDialog}>
                        <i className="material-icons">close</i>
                    </Fab>
                    <Typography color="error">{this.props.error}</Typography>
                    <ValidatorForm onSubmit={this.props.handleSubmit}>
                        <TextValidator
                            fullWidth
                            autoComplete="off"
                            label='Name'
                            className="my-2"
                            name="name"
                            validators={['required']}
                            errorMessages={['Required']}
                            value={_.get(formData, 'name') || ''}
                            onChange={this.props.handleFormChange}
                        />
                        <TextValidator
                            fullWidth
                            label='Email'
                            className="my-2"
                            name="email"
                            validators={['required', 'isEmail']}
                            errorMessages={['Required', 'Incorrect Email']}
                            value={_.get(formData, 'email') || ''}
                            onChange={this.props.handleFormChange}
                        />
                        {
                            !isEdit || true ?
                                <>
                                    <TextValidator
                                        autoComplete="off"
                                        fullWidth
                                        className="my-2"
                                        label='Password'
                                        name="password"
                                        type="password"
                                        validators={isEdit ? [] : ['required']}
                                        errorMessages={['Required']}
                                        value={_.get(formData, 'password') || ''}
                                        onChange={this.props.handleFormChange}
                                    />
                                    <TextValidator
                                        className="my-2"
                                        fullWidth
                                        autoComplete="off"
                                        label='Repeat Password'
                                        type="password"
                                        name="repeatPassword"
                                        validators={isEdit ? ['isPasswordMatch'] : ['required', 'isPasswordMatch']}
                                        errorMessages={isEdit ? ['Password does not match'] : ['Required', 'Password does not match']}
                                        value={_.get(formData, 'repeatPassword') || ''}
                                        onChange={this.props.handleFormChange}
                                    />
                                </> : null
                        }


                        <TextValidator
                            className="my-2"
                            fullWidth
                            label='Company'
                            name="company"
                            value={_.get(formData, 'company') || ''}
                            onChange={this.props.handleFormChange}
                        />
                        <Button className={classes.submitBtn} fullWidth variant="contained" color="primary" type="submit">
                            {loading ? <CircularProgress /> : isEdit ? 'update user' : 'create user'}
                        </Button>
                    </ValidatorForm>
                </DialogContent>
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    submitBtn: {
        color: 'white',
        margin: '20px 0px'
    },
    dialogCloseBtn: {
        boxShadow: 'none',
        background: theme.palette.common.white,
        position: 'absolute',
        top: 10,
        right: 10
    }
})

export default withStyles(STYLES)(NewUserForm)