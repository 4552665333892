import React, { FC } from 'react';
import { createStyles, Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Theme, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Add, Delete, Edit } from '@material-ui/icons';

export interface TableScreenProps<T = any> extends WithStyles<typeof STYLES> {
    headers: string[];
    rows: T[];
    renderRow: (row: T) => JSX.Element;
    handleEdit?: (row: T) => void;
    handleDelete?: (row: T) => void;
    handleAdd?: () => void
}




function TableScreen<T>(props: TableScreenProps<T>) {
    const { classes, headers, renderRow, rows, handleDelete, handleEdit, handleAdd } = props;

    return (
        <>
            <Table >
                <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell className={classes.action}>Actions</TableCell>
                        {headers.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r, i) => (
                        <TableRow key={i}>
                            <TableCell className={classes.action}>
                                {handleEdit &&
                                    <IconButton onClick={() => handleEdit(r)}><Edit fontSize='small' /></IconButton>
                                }
                                {handleDelete &&
                                    <IconButton onClick={() => handleDelete(r)}><Delete fontSize='small' color='error' /></IconButton>
                                }
                            </TableCell>
                            {renderRow(r)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {handleAdd && (
                <Fab color='primary' onClick={handleAdd} className={classes.add}>
                    <Add />
                </Fab>
            )}
        </>
    )
}

const STYLES = (theme: Theme) => createStyles({
    head: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'white',
    },
    action: {
        width: 100,
        backgroundColor: theme.palette.grey[200],
        position: 'sticky',
        left: 250,
    },
    add: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    }
});

export default withStyles(STYLES)(TableScreen);