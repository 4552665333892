import React, { Component, Ref } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme,
    Button,
    Grid,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Input,
    Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactQuill from 'react-quill'; // Typescript
import THEME from '../../Resources/Theme';
import _ from 'lodash';
import 'react-quill/dist/quill.snow.css'; // ES6
import { SectionLabel } from '.';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import SearchWithSuggestion from '../SearchWithSuggestion';
import utilities from '../../Resources/Utils';
import Axios, { CancelTokenSource } from 'axios';
import { TState } from '../../RootReducer';
import { toTitleCase } from './utils';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';

interface IProps extends WithStyles<typeof STYLES> {
    legalFinding: TCLDDocument | undefined;
    isNewLegalFinding: boolean;
    onEdit: (key: string, value: any) => void;

    formData?: any;
    formErrors?: TState['NewLegalFinding']['formError'];
}

interface State {
    // formData: any,
    error: any;
    textDescription: string;
}

// let reactQuill;
// let quill

const UndoRedo = props => {
    const { classes } = props;
    // if (reactQuill && !quill)
    //     quill = reactQuill.getEditor();

    // const undoAction = () => {
    //     if (!quill) return;
    //     quill.history.undo();
    // }

    // const redoAction = () => {
    //     // if (!reactQuill)
    //     //     return;
    //     // let quill = reactQuill.getEditor();
    //     if (!quill) return;
    //     quill.history.redo();
    // }

    return (
        <div id='toolbar'>
            {/* <select className="ql-size">
                <option value="small"></option>
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
            </select> */}
            <Button onClick={props.undo} className={classes.undoRedoBtn}>
                <i className='material-icons'>undo</i>
            </Button>
            <Button onClick={props.redo} className={classes.undoRedoBtn}>
                <i className='material-icons'>redo</i>
            </Button>
            <button className='ql-script' value='sub'></button>
            <button className='ql-script' value='super'></button>
            <button className='ql-direction' value='rtl'></button>
            <button className='ql-list' value='bullet'></button>
            <button className='ql-list' value='ordered'></button>
            <button className='ql-indent' value='-1'></button>
            <button className='ql-indent' value='+1'></button>
            <select className='ql-align' />
            <select className='ql-color' />
            <select className='ql-background' />
            <button className='ql-bold'></button>
            <button className='ql-italic'></button>
            <button className='ql-strike'></button>
            <button className='ql-underline'></button>
            <button onClick={e => props.changeCase('upper')} className={classes.changeCaseBtn}>
                <b>ABC</b>
            </button>
            <button onClick={e => props.changeCase('lower')} className={classes.changeCaseBtn}>
                <b>abc</b>
            </button>
            <button onClick={e => props.changeCase('title')} className={classes.changeCaseBtn}>
                <b>Abc</b>
            </button>
            <button className='ql-clean' />
        </div>
    );
};

const MODULES = {
    history: {
        delay: 100,
        maxStack: 200,
        userOnly: false,
    },
    toolbar: {
        container: '#toolbar',
    },
    keyboard: {
        bindings: {
            'list autofill': {
                key: ' ',
                collapsed: true,
                format: { list: false }, // ...on an line that's not already a list
                prefix: /^-$/, // ...following a '-' character
                offset: 1,
                handler: (range, context) => {
                    return true;
                },
            },
        },
    },
    // toolbar: [
    //     [{ 'header': [1, 2, false] }],
    //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //     [{ 'indent': '-1' }, { 'indent': '+1' }],
    //     [{ 'script': 'sub' }, { 'script': 'super' }],
    //     [{ 'color': [] }],
    //     [{ 'align': [] }],
    //     [{ 'direction': 'rtl' }],
    //     ['clean'],
    // ],
};

const FORMATS = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'indent',
    'link',
    'image',
    'color',
    'script',
    'font',
    'align',
    'direction',
    'size',
    'list',
    'blockquote',
    'code-block',
];

let prevOperation: string = '';
let prevIndex: number = -1;
let prevLength: number = 0;
let reactQuill;
class Finding extends Component<IProps, State> {
    state = {
        // formData: {
        //     legalFinding: ''
        // },
        textDescription: '',
        error: {},
    };

    initialSetup = true;

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    componentDidMount() {
        if (this.props.legalFinding) {
            this.setState({
                // formData: {
                //     legalFinding: utilities.formatLegalFinding(this.props.legalFinding.legalFinding || ''),
                //     'Page(s)_': this.props.legalFinding['Page(s)_'] || '',
                //     'Para(s)_': this.props.legalFinding['Para(s)_'] || '',
                //     Finding: this.props.legalFinding.Finding,
                //     editorCopy: this.props.legalFinding.editorCopy,
                //     Importance01: this.props.legalFinding.Importance01 || ''
                // },
                textDescription: utilities.formatLegalFinding(this.props.legalFinding.legalFinding || ''),
            });
        }
    }

    handleTextEntryChange = (content: string, delta) => {
        // at initial render, this is called
        // handling to prevent the call from setting the form data;
        if (this.initialSetup) {
            this.initialSetup = false;
            return;
        }
        // this.setState({ textDescription: content });
        // this.props.onEdit('legalFinding', content);
        this.changeValue('legalFinding', content);
    };

    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.changeValue(e.target.name, e.target.value);
    };

    handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.changeValue(e.target.name, e.target.value);
    };

    changeValue = (key: string, value: any) => {
        if (key === 'Finding') value = parseInt(value);
        // this.setState({ formData: { ...this.state.formData, [key]: value } });
        this.props.onEdit(key, value);
        if (key === 'Finding') {
            this.isValidFindingNumber(value, key);
            if (this.props.legalFinding) {
                this.props.onEdit('ID', `${this.props.legalFinding['Document Number']}_${value}`);
            }
        }
    };

    handleSuggestChange = ({ name, value }) => {
        this.changeValue(name, value);
    };

    isValidFormat = (value: string) => {
        return utilities.validatePageParaFormat(value);
    };

    isValidFindingNumber = async (value: string, key: string) => {
        const { error } = this.state;
        const { formData } = this.props;
        if (_.isEmpty(value.toString())) {
            this.setState({ error: { ...error, [key]: false } });
            if (this.ajaxCallHandle) {
                this.ajaxCallHandle.cancel('cancelled');
            }
            return;
        }
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + value);
        }
        this.ajaxCallHandle = Axios.CancelToken.source();
        try {
            const res = await Axios.request({
                url: '/clddocs',
                params: {
                    filter: {
                        where: {
                            'Document Number': this.props.legalFinding
                                ? this.props.legalFinding['Document Number']
                                : '',
                            Finding: parseInt(value),
                            deleted: false,
                            cldFieldLanguageId: formData.cldFieldLanguageId,
                        },
                    },
                },
                cancelToken: this.ajaxCallHandle.token,
            });
            const ownFinding =
                _.size(res.data) === 1 ? _.get(res.data[0], 'id') === _.get(this.props, 'legalFinding.id') : false;
            if (!_.isEmpty(res.data) && !ownFinding) this.setState({ error: { ...this.state.error, [key]: true } });
            else this.setState({ error: { ...this.state.error, [key]: false } });
        } catch (error) {
            this.setState({ error: { ...this.state.error, [key]: false } });
        }
    };

    hanldeUndo = () => {
        if (!reactQuill) return;
        const quill = reactQuill.getEditor();
        setTimeout(function() {
            quill.history.undo();
        }, 1000);
    };

    handleRedo = () => {
        if (!reactQuill) return;
        const quill = reactQuill.getEditor();
        setTimeout(function() {
            quill.history.redo();
        }, 1000);
    };

    changeCase = (type: 'upper' | 'lower' | 'camel' | 'title') => {
        if (!reactQuill) return;
        const quill = reactQuill.getEditor();

        if (!quill) return;
        const selection = quill.getSelection();

        if (!selection) return;
        const { index = 0, length = 0 } = quill.getSelection();
        if (prevIndex === index && prevLength === length && prevOperation === type) return;

        prevIndex = index;
        prevLength = length;
        prevOperation = type;

        const text: string = quill.getText(index, length);
        quill.deleteText(index, length);

        let formattedText = text;
        if (type === 'upper') formattedText = formattedText.toUpperCase();
        else if (type === 'lower') formattedText = formattedText.toLowerCase();
        else if (type === 'camel') formattedText = _.camelCase(formattedText);
        else if (type === 'title') formattedText = toTitleCase(formattedText);
        // else if (type === 'title') formattedText = _.startCase(formattedText);

        toTitleCase(formattedText);

        quill.insertText(index, formattedText);
        quill.setSelection(index, length);
    };

    handleDateChange = ({ target }) => {
        const { value, name } = target;
        this.changeValue(name, value);
    };

    render() {
        const { classes, isNewLegalFinding, formErrors = {} } = this.props;
        const { formData = {} } = this.props;

        const disabled = !_.get(formData, 'Legal Findings?');
        // const disabled = _.get(formData, 'Legal Findings?') === 'No'

        const INPUTS = [
            {
                label: 'Date created',
                key: 'dateCreated',
                type: 'date',
                // onChange: this.handleDateChange,
                disabled: true,
            },
            {
                label: 'Date modified',
                key: 'dateModified',
                type: 'date',
                onChange: this.handleDateChange,
            },
            {
                label: 'Editor',
                key: 'editorCopy',
                type: 'text',
                disabled,
            },
            {
                label: 'Copy legal finding(s) from',
                key: 'CopyLegalFinding',
                // key: 'Copy legal findings(s) from',
                searchKey: 'Document Number',
                type: 'search',
                disabled,
                params: text => ({ term: text, field: ['Document Number'] }),
                suggestionUrl: '/clddocs/autocomplete',
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
            },
            {
                label: 'Para(s)/line(s)',
                key: 'Para(s)_',
                type: 'text',
                disabled,
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: this.isValidFormat,
            },
            {
                label: 'Page(s)',
                key: 'Page(s)_',
                type: 'text',
                disabled,
                hintText: 'format 0000; 0000-0000',
                errorText: 'format 0000; 0000-0000',
                validationFunc: this.isValidFormat,
            },
            {
                label: 'Legal finding number',
                key: 'Finding',
                type: 'text',
                disabled,
                // validationFunc: this.isValidFindingNumber,
                // errorText: 'This finding number already exists'
            },
            {
                label: 'Level of importance',
                key: 'Importance01',
                type: 'select',
                disabled,
                options: [
                    { label: 'Select', key: '' },
                    { label: 'High', key: 'High' },
                    { label: 'Medium', key: 'Medium' },
                    { label: 'Low', key: 'Low' },
                ],
            },
        ];

        return (
            <div className={classes.root} id='finding'>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <SectionLabel title='LEGAL FINDING' />
                    </div>

                    <Grid container spacing={24} className={classes.content}>
                        {INPUTS.map((input, i) => {
                            const error = input.validationFunc
                                ? !input.validationFunc(formData[input.key] || '')
                                : false;
                            if (input.type === 'text')
                                return (
                                    <Grid item md={6} key={i}>
                                        <FormControl id={input.key} className={classes.formControl} key={i} fullWidth>
                                            <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                            <Input
                                                id={input.key}
                                                autoComplete='off'
                                                disabled={input.disabled || false}
                                                value={formData[input.key] || ''}
                                                name={input.key}
                                                error={error}
                                                placeholder={input.hintText ? input.hintText : ''}
                                                onChange={this.handleChange}
                                            />
                                            <Typography color='error'>{formErrors[input.key]}</Typography>
                                        </FormControl>
                                        {input.key === 'Finding' && this.state.error[input.key] ? (
                                            <div className={classes.errorText}>
                                                <Typography variant='caption' color='error'>
                                                    The same legal finding number already exists for this decision
                                                </Typography>
                                            </div>
                                        ) : null}
                                        {error && input.errorText ? (
                                            <div className={classes.errorText}>
                                                <Typography variant='caption' color='error'>
                                                    {input.errorText}
                                                </Typography>
                                            </div>
                                        ) : null}
                                    </Grid>
                                );
                            else if (input.type === 'select')
                                return (
                                    <Grid item md={6} key={i}>
                                        <FormControl
                                            id={input.key}
                                            disabled={input.disabled || false}
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InputLabel htmlFor={input.key}>{input.label}</InputLabel>
                                            <Select
                                                value={formData[input.key] || ''}
                                                onChange={this.handleSelect}
                                                inputProps={{ id: input.key, name: input.key }}
                                            >
                                                {input.options!.map((option, i) => (
                                                    <MenuItem key={i} value={option.key}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Typography color='error'>{formErrors[input.key]}</Typography>
                                        </FormControl>
                                    </Grid>
                                );
                            else if (input.type === 'search')
                                return (
                                    <Grid id={input.key} item md={6} key={i}>
                                        <SearchWithSuggestion
                                            classes={{ root: classes.formControl }}
                                            label={input.label}
                                            params={input.params}
                                            disabled={!!input.disabled}
                                            formKey={input.key}
                                            searchKey={input.searchKey}
                                            value={formData[input.key] || ''}
                                            onChange={this.handleSuggestChange}
                                            handleSuggestions={input.handleSuggestions}
                                            suggestionUrl={input.suggestionUrl!}
                                        />
                                        <Typography color='error'>{formErrors[input.key]}</Typography>
                                    </Grid>
                                );
                            else if (input.type === 'date')
                                return (
                                    <Grid item md={6} key={i}>
                                        <FormControl
                                            id={input.key}
                                            disabled={input.disabled || false}
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InlineDatePicker
                                                disabled={input.disabled}
                                                keyboard
                                                clearable
                                                disableFuture
                                                minDate={new Date('01/02/1800')}
                                                // InputProps={{ className: classes.dateInput }}
                                                // InputLabelProps={{ className: classes.dateInputLabel }}
                                                name={input.key}
                                                fullWidth
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                label={input.label}
                                                views={['year', 'month', 'day']}
                                                // placeholder={inputConfig.label}
                                                maxDate={new Date()}
                                                keyboardIcon={<i className='material-icons'>event</i>}
                                                format='DD/MM/YYYY'
                                                // invalidDateMessage={<InvalidDateMessage />}
                                                value={formData[input.key] ? formData[input.key] : null}
                                                // value={formData[input.key] ? moment(formData[input.key], 'DD/MM/YYYY') : null}
                                                onChange={date => {
                                                    if (date && input.onChange)
                                                        input.onChange({
                                                            target: {
                                                                value: moment(date).toISOString(),
                                                                name: input.key,
                                                            },
                                                        });
                                                    else if (input.onChange)
                                                        input.onChange({ target: { value: null, name: input.key } });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                );
                            else return <div />;
                        })}
                    </Grid>

                    <Typography id={'legalFinding'}>Please paste as plain text</Typography>
                    <UndoRedo
                        classes={classes}
                        undo={this.hanldeUndo}
                        redo={this.handleRedo}
                        changeCase={this.changeCase}
                    />
                    <ReactQuill
                        ref={ref => {
                            if (!reactQuill) {
                                reactQuill = ref;
                            }
                        }}
                        className={classes.textEditor}
                        modules={MODULES}
                        formats={FORMATS}
                        value={formData.legalFinding || ''}
                        onChange={this.handleTextEntryChange}
                    />
                    <Typography color='error'>{formErrors.legalFinding}</Typography>
                </div>
            </div>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            padding: '40px 0px 60px 0px',
            borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        },
        title: {
            marginBottom: 16,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        content: {
            background: 'white',
            padding: '20px 30px',
            maxWidth: 680,
            borderRadius: 4,
            margin: '0px 0px 24px 0px',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: 680,
            margin: '0 auto',
        },
        formControl: {
            margin: '8px 0px',
        },
        errorText: {
            position: 'relative',
        },
        textEditor: {
            '& .ql-toolbar': {
                borderWidth: 0,
                background: THEME.Colors.Grayscale.Grey4,
            },
            '& .ql-container': {
                '& .ql-editor': {
                    textAlign: 'justify',
                },
                fontSize: 16,
                height: 300,
                background: 'white',
                borderRadius: '0px 0px 6px 6px',
                borderWidth: 0,
            },
        },
        undoRedoBtn: {
            padding: '0px 8px',
            minHeight: 0,
            minWidth: 0,
            '& i': {
                position: 'relative',
                top: -2,
                left: -3,
            },
        },
        changeCaseBtn: {
            padding: '0px 8px',
            minHeight: 0,
            margin: '0px 4px',
            width: '30px !important',
            minWidth: 0,
            '& i': {
                // position: 'relative',
                // top: -2,
                // left: -3
            },
        },
        saveBtn: {
            background: THEME.Colors.Third,
            position: 'absolute',
            bottom: -20,
            right: 12,
        },
    });

export default withStyles(STYLES)(Finding);
