import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, IconButton } from '@material-ui/core'
import Theme from '../../Resources/Theme';
import { connect } from 'react-redux'
// import ClearIcon from '@material-ui/icons/Clear';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import _ from 'lodash'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TDocument } from '../../Features/LTDDocuments/@types';

import { ODetail } from '../../Features/DetailPage/redux-config';
import { TMetadata } from '../../Features/LTDDocuments/@types';
import utilities from '../../Resources/Utils';
import Config from '../../Resources/Config';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';

interface Props extends WithStyles<typeof STYLES> {
    data: TDocument,
    onClose: Function,
    open: boolean
}
interface IStateProps {
    metadata: Array<TMetadata>,
    size: number
}

interface IDispatchProps {
    updateDocument: Function
}
class MetadataForm extends Component<Props & IDispatchProps, IStateProps, WithTranslationProps> {


    state = {
        metadata: [] as Array<TMetadata>,
        size: 0
    }
    componentWillReceiveProps(props: Props) {
        console.log("props", props);
        this.state.metadata = [...props.data.metadata];
        this.state.size = props.data.metadata.length;
    }
    handleSubmit = async () => {
        // await utilities.request({
        //     url:Config.get('API_URL') + `ltddocs/${this.props.data.id}`,
        //     method:"PATCH",
        //     data:{
        //         metadata: this.state.metadata
        //     }
        // });

        this.props.updateDocument(this.props.data.id, { metadata: this.state.metadata })
        this.props.onClose();
    }
    addItem = () => {

        let metadata = this.state.metadata;
        metadata.push({
            name: "",
            val: ""
        });
        this.setState({ metadata });

    }
    clearItem = (index) => {

        let metadata = this.state.metadata;
        metadata.splice(index, 1)
        this.setState({ metadata });

    }

    handleChange = (event, index: number, key: string) => {
        let metadata = this.state.metadata;

        metadata[index][key] = event.target.value;

        this.setState({ metadata });
    }

    render() {
        const { classes} = this.props;

        let metadata = this.state.metadata || [];

        console.log("state", this.state)

        return (
            <Dialog
                className={classes.root}
                open={this.props.open || false}
                onClose={e => this.props.onClose!()}
                scroll="body"
            >
                <Button
                    className={classes.closeButton}
                    onClick={e => this.props.onClose!()}
                >
                    <i className="material-icons">clear</i>
                </Button>
                <DialogTitle className={classes.title}>Edit Meta Data</DialogTitle>
                <DialogContent className={classes.content}>
                    <ValidatorForm
                        className={classes.form}
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        {
                            _.map(metadata, (md, index) => ([
                                <TextValidator
                                    key="name"
                                    className={classes.input_name}
                                    onChange={(e) => this.handleChange(e, index, 'name')}
                                    disabled={Boolean((index < this.state.size) && md.name)}
                                    value={_.get(md, 'name')}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />,
                                <TextValidator
                                    key="val"
                                    className={classes.input_val}
                                    onChange={(e) => this.handleChange(e, index, 'val')}
                                    value={_.get(md, 'val')}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />,
                                <Button
                                    key="clear"
                                    className={classes.clearButton}
                                    onClick={() => this.clearItem(index)}
                                >
                                    <i className="material-icons" style={{ fontSize: 20 }} >clear</i>
                                </Button>,
                                <br
                                    key="br"
                                />
                            ]))
                        }
                        <Button
                            type="button"
                            className={classes.addItemButton}
                            onClick={this.addItem}
                        >Add Item</Button>

                        <Button type="submit" className={classes.submitButton}>Submit</Button>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}



const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    updateDocument: (id, data) => dispatch(ODetail.updateDocument(id, data))
})

const STYLES = theme => createStyles({
    root: {
        minWidth: 500
    },
    title: {
        padding: "20",
        textAlign: "center"
    },
    content: {
        padding: "20",
        minWidth: 800

    },
    form: {
        width: "100%"
    },
    input_name: {
        margin: "2%",
        width: "40%"
    },
    input_val: {
        margin: "2%",
        width: "50%"
    },
    addItemButton: {
        backgroundColor: Theme.Colors.Primary.main,
        color: Theme.Colors.Grayscale.White,
        fontSize: 14,
        left: "40%",
        margin: "2%"
    },
    submitButton: {
        backgroundColor: Theme.Colors.Primary.main,
        color: Theme.Colors.Grayscale.White,
        fontSize: 14,
        float: "right",
        margin: "2%"
    },
    closeButton: {
        float: "right",
        right: 0,
        position: "absolute"
    },
    clearButton: {
        float: "right",
        right: 0,
        fontSize: 10,
        position: "absolute"
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(MetadataForm)))