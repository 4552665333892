import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, Button, Menu, MenuItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../Resources/Theme';
import { TReadingList } from '../../Features/ReadingList/@types';
import { Link } from 'react-router-dom';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { MENU_LIST_DELETE, MENU_LIST_RENAME, METADATA_DECISION, METADATA_LEGAL_FINDINGS } from '../../Features/Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES> {
    details: TReadingList
    onDelete: (id: string) => void
    onRename: (id: string, formData) => void
}


interface IFolder {
    anchorEl: EventTarget | null
    isRenaming: boolean
    formData: {
        name: string
    }
}
class Folder extends Component<Props & IFolder & WithTranslationProps> {
    state = {
        anchorEl: null,
        isRenaming: false,
        formData: {
            name: this.props.details.name || ''
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }


    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleDelete = () => {
        this.props.onDelete(this.props.details.id);
        this.handleClose();
    }

    handleRename = () => {
        this.setState({ isRenaming: true, anchorEl: null });
    }

    handleSubmit = () => {
        this.props.onRename(this.props.details.id, this.state.formData);
        this.setState({ isRenaming: false });
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleKeyPress = (e) => {
        if (e.keyCode === 27)
            this.cancel();
    }

    cancel = () => {
        this.setState({ isRenaming: false, formData: { name: this.props.details.name } })
    }

    render() {
        const { classes, details, getTranslation } = this.props;
        const { anchorEl, isRenaming, formData } = this.state;

        const url = `/readinglist/${details.id}`;


        return (
            <div className={classes.root}>
                {
                    !isRenaming ?
                        <Link className={classes.link} to={url}>
                            <Typography className={classes.name}>{details.name}</Typography>
                            <Typography variant="caption" className={classes.subtitle}>
                                {details.LtdDocCount ? details.LtdDocCount : 0}{getTranslation(METADATA_DECISION) || 'Decision(s)'}<span className={classes.dot} /> {details.CldDocCount ? details.CldDocCount : 0}
                                {getTranslation(METADATA_LEGAL_FINDINGS) || 'Legal Finding(s)'}
                            </Typography>
                        </Link> :
                        <div>
                            <div className={classes.editContainer}>
                                <TextField
                                    value={formData.name}
                                    onChange={e => this.setState({ formData: { name: e.target.value } })}
                                    onKeyPress={this.handleKeyPress}
                                />
                                <Button onClick={this.handleSubmit}>
                                    <i className="material-icons save">check_circle_outline</i>
                                </Button>
                                <Button onClick={this.cancel}>
                                    <i className="material-icons cancel">clear</i>
                                </Button>
                            </div>
                            {/* <Typography variant="caption" className={classes.subtitle}>20 Decisions <span className={classes.dot} /> 12 Legal Findings</Typography> */}
                        </div>
                }
                <div className={classes.footer}>
                    <Typography variant="overline">{moment(details.created).format('DD MMM, YYYY')}</Typography>
                    <Button aria-controls="folder-menu" aria-haspopup="true" onClick={this.handleClick}>
                        <i className="material-icons">more_vert</i>
                    </Button>
                    <Menu
                        id="folder-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem dense onClick={this.handleRename}>
                            <ListItemIcon><i className="material-icons">edit</i></ListItemIcon>
                            <ListItemText>{getTranslation(MENU_LIST_RENAME) || 'Rename'}</ListItemText>
                        </MenuItem>
                        <MenuItem dense onClick={this.handleDelete}>
                            <ListItemIcon><i className="material-icons">{getTranslation(MENU_LIST_DELETE) || 'delete'}</i></ListItemIcon>
                            <ListItemText>{getTranslation(MENU_LIST_DELETE) || 'Delete'}</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = theme => createStyles({
    root: {
        width: 250,
        padding: '14px 12px',
        display: 'flex',
        flexDirection: 'column',
        margin: '12px 10px',
        background: 'white',
        transition: '200ms linear',

        '&:hover': {
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.2)}`
        },
        [theme.breakpoints.down('sm')]: {
            width: '85vw',
            margin: '10px auto',
        }
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    name: {
        fontWeight: 500,
        color: Theme.Colors.Secondary.main
        // color: Theme.Colors.Third
    },
    subtitle: {
        fontWeight: 500,
        color: Theme.Colors.Fourth
    },
    editContainer: {
        display: 'flex',
        // justifyContent: 'space-between',
        '& button': {
            margin: 0,
            padding: 0,
            minWidth: 0,
            minHeight: 0,
            height: 40,
            width: 40,
            '& .save': {
                color: Theme.Colors.Green
            },
            '& .cancel': {
                color: Theme.Colors.Error
            },
        },
        '& > div': {
            [theme.breakpoints.down('sm')]: {
                flex: 1
            }
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            minWidth: 0,
            padding: 0,
            color: Theme.Colors.Grayscale.Grey4
        }
    },
    dot: {
        display: 'inline-block',
        margin: '0px 4px',
        width: 8,
        height: 8,
        borderRadius: 5,
        background: 'rgba(0,0,0,0.3)'
    }
})

export default withTranslations(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Folder)))