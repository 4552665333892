import React from 'react'
import { createStyles, InputLabel, Theme, withStyles, WithStyles } from '@material-ui/core'
import _ from 'lodash';
import { TState } from '../../../../RootReducer';
import { OAuth } from '../../../../Features/Authentication/redux-config';
import { connect } from 'react-redux';
import { TActivityLogFilter, ActivityLogFilter } from '../../../../Features/LtdDashboard/Filters/activityLog';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import utilities from '../../../../Resources/Utils';
import { BatchEditLogFilter } from '../../../../Features/LtdDashboard/Filters/batchEditLog';

interface IProps extends IStateProps, IDispatchProps, WithStyles<typeof STYLES> { }

interface IStateProps {
    appliedFilters: TActivityLogFilter
    appConstants: Record<string, any>
}
interface IDispatchProps {
    applyFilter: (filter: TActivityLogFilter) => void
}


type SelectOption = {
    name: string
    value: TActivityLogFilter['by']
}

const ByFilter: React.FC<IProps> = (props) => {
    const { appliedFilters, appConstants, classes } = props;


    const handleChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        const filter: TActivityLogFilter = { ...appliedFilters, by: event.target.value as SelectOption['value'] };
        props.applyFilter(filter);

    }


    const SELECT_OPTIONS: Array<SelectOption> = [
        { name: 'All', value: 'all' },
        { name: 'ICCImport Script', value: 'ICCImport Script' },
        { name: 'STLImport Script', value: 'STLImport Script' },
        { name: 'Admin', value: 'user' },
    ]

    return (
        <FormControl variant="filled" fullWidth>
            <InputLabel classes={{ shrink: classes.shrink }} htmlFor={`applied-action-filter`}>By</InputLabel>
            <Select
                value={appliedFilters.by || 'all'}
                onChange={handleChange}
                inputProps={{
                    name: '',
                    id: `applied-action-filter`,
                }}
            >
                {
                    _.map(SELECT_OPTIONS, item => (
                        <MenuItem key={`${item.value}-action-type`} value={item.value}>{item.name}</MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}

const mapStateToProps = (state: TState): IStateProps => ({
    appliedFilters: _.get(state.LtdDashboard, 'batchEditLog.filter') || {},
    appConstants: _.get(state.App, 'appConstants')
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    applyFilter: (filter: TActivityLogFilter) => dispatch(BatchEditLogFilter.applyFilter(filter)),
})




const STYLES = (theme: Theme) => createStyles({
    shrink: {
        transform: "translate(0px, 10px) scale(0.75) !important",
        fontWeight: theme.typography.fontWeightMedium
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(ByFilter))