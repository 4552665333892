import _ from 'lodash';
import { TState } from "../../RootReducer";
import { TCLDDocument } from "../../Features/CLDDocuments/@types";

const INPUT_KEYS: Array<keyof TCLDDocument> = ['Confidentiality', 'situationCase', 'Decision Type', 'progress', 'editorCopy', 'legalFinding'];

export const ScrollToError = (formError: TState['NewLegalFinding']['formError']) => {
    if (Object.keys(formError).length === 0) return;

    let key: string | undefined = undefined;
    INPUT_KEYS.forEach(k => {
        if ((formError || {})[k] && !key) key = k
    });
    const el = document.getElementById(key || '-');
    if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
    }
}



// Para(s)/line(s)*
// o   Page(s)*
// o   Legal finding number
// o   Level of importance
// o   Keywords (not a single keyword has been selected)

const INPUT_PROGRESS_DEPENDENCIES: Partial<Record<keyof TCLDDocument, string>> = {
    'Page(s)_': 'Page(s)',
    'Para(s)_': 'Para(s)/line(s)',
    'Finding': 'Legal finding number',
    'Importance01': 'Level of importance',
    'Keyword': 'Keywords (not a single keyword has been selected)'
};

export type ProgressValidation = { isValid: boolean, missing: typeof INPUT_PROGRESS_DEPENDENCIES }

export const validateProgress = (formData: Partial<TCLDDocument>): ProgressValidation => {

    let FIELDS = Object.keys(INPUT_PROGRESS_DEPENDENCIES);

    if (!formData.progress) return { isValid: true, missing: {} };
    if (!formData['Legal Findings?']) {
        FIELDS = FIELDS.filter(f => f !== 'Page(s)_' && f !== 'Para(s)_' && f !== 'Importance01' && f !== 'Keyword')
    }
    let missing: Partial<Record<keyof TCLDDocument, string>> = {}
    let isValid = false;
    switch (formData.progress) {
        case 'Input in progress':
        case 'Input completed': {
            FIELDS.forEach(f => {
                if (!formData[f] || formData[f].length === 0) missing[f] = INPUT_PROGRESS_DEPENDENCIES[f]
            })
            isValid = true
            break;
        }
        case 'Reviewed': {
            FIELDS.forEach(f => {
                if (!formData[f] || formData[f].length === 0) missing[f] = INPUT_PROGRESS_DEPENDENCIES[f]
            })
            isValid = Object.keys(missing).length === 0;
            break;
        }
        case 'Finalised': {
            FIELDS.forEach(f => {
                if (!formData[f] || formData[f].length === 0) missing[f] = INPUT_PROGRESS_DEPENDENCIES[f]
            })
            // ONLY ONE OF 'para or page' has to be present.
            if (!missing['Page(s)_']) missing = _.omit(missing, 'Para(s)_');
            if (!missing['Para(s)_']) missing = _.omit(missing, 'Page(s)_');
            isValid = Object.keys(missing).length === 0;
        }
    }
    return { isValid, missing }
}



export const toTitleCase = (s: string) => s.split(" ").map(w => _.capitalize(w)).join(' ');