import { useContext } from 'react';
import get from 'lodash/get';
import { TranslationContext } from './TranslationContext';
import { TranslationLocale } from '../../Models/Translations/@types';

function useTranslations(type: 'ltd' | 'cld' | 'scc' = 'cld') {
    const { locale, changeLocale, staticTranslation } = useContext(TranslationContext);

    const getTranslation = (key: string) => {
        return translate(staticTranslation, key, locale, type);
    };

    return { getTranslation, locale, changeLocale };
}

export const translate = (
    translationJSON: any,
    key: string,
    locale: TranslationLocale,
    type: 'cld' | 'ltd' | 'scc' = 'cld'
): string => {
    const translation = get(translationJSON, `${type === 'scc' ? 'ltd' : type}.${key}`);
    if (!translation) return '';
    return !!translation && translation[locale] ? translation[locale] : translation['en'];
};
export default useTranslations;
