import {
  InputBase,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TranslationLocale } from "../../Models/Translations/@types";
import React, { FC, Fragment } from "react";
import { TABLE_CELL_WIDTH } from "./TranslationTable";

export interface TranslationListRowProps extends WithStyles<typeof STYLES> {
  locales: TranslationLocale[];
  pathFromRoot: string;
  translations: { lang: TranslationLocale; value: string }[];
  handleChange: (
    pathFromRoot: string,
    lang: TranslationLocale,
    value: string
  ) => void;
}

const TranslationListRow: FC<TranslationListRowProps> = (
  props: TranslationListRowProps
) => {
  const {
    pathFromRoot,
    translations,
    handleChange,
    locales = [],
    classes,
  } = props;

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.borderRight} />
        {locales.map((l) => {
          const value =
            (translations.find((t) => t.lang === l) || { value: "" }).value ||
            "";
          return (
            <Fragment key={l}>
              <div className={classes.nameCell}>
                <InputBase
                  fullWidth
                  multiline
                  inputProps={{
                    "aria-label": "naked input",
                    className: classes.input,
                  }}
                  classes={{
                    input: classes.input,
                  }}
                  className={classes.muiInput}
                  value={value}
                  onChange={(event) => {
                    handleChange(pathFromRoot, l, event.target.value);
                  }}
                />
              </div>
              <div className={classes.borderRight} />
            </Fragment>
          );
        })}
      </div>
      <div className={classes.borderBottom} />
    </Fragment>
  );
};

const STYLES = (theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      display: "flex",
      minHeight: 50,
    },
    nameCell: {
      width: TABLE_CELL_WIDTH,
      display: "flex",
      alignItems: "center",
    },
    muiInput: {
      padding: 0,
    },
    input: {
      paddingLeft: "1rem",
      transition: "150ms",
      "&:focus": { backgroundColor: "#fff" },
      "&:active": { backgroundColor: "#fff" },
      "&:hover": { backgroundColor: "#fff" },
      padding: "6px 0px 7px",
      boxSizing: 'border-box',
      "& textarea": {
        padding: "6px 0px 7px",
      },
      width: TABLE_CELL_WIDTH,
      ...theme.typography.overline,
      lineHeight: 1.5,
      textTransform: 'none'
    },
    borderRight: {
      height: "inherit",
      backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 75%, #707070 25%)`,
      backgroundPosition: "right",
      backgroundSize: "1px 5px",
      backgroundRepeat: "repeat-y",
      width: 1,
      "&:after": {
        display: "table",
        clear: "both",
        content: "' '",
      },
    },
    borderBottom: {
      width: "100%",
      backgroundImage: `linear-gradient(to right, #707070 33%, rgba(255,255,255,0) 0%)`,
      backgroundPosition: "bottom",
      backgroundSize: "5px 1px",
      backgroundRepeat: "repeat-x",
      minHeight: 1,
      "&:after": {
        display: "table",
        clear: "both",
        content: "' '",
      },
    },
  });
};

export default withStyles(STYLES)(TranslationListRow);
