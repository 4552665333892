import utilities from "../Resources/Utils";
import { TLtdDocFileUploadResponse, TMultiCreateResponse, TMultiEditResponse, TBatchCreateResponse, TIssueSuggestionType } from "./LtdDocs.types";
import { TBatchEditForm } from "../Components/BatchEdit/useBatchEdit";
import { TDocument } from "../Features/LTDDocuments/@types";
import _ from 'lodash';


export class LtdDocService {
    static uploadFile = (file: any) => utilities.uploadFile<TLtdDocFileUploadResponse>(
        '/ltddocs/upload',
        file,
        file.name,
        'doc'
    );

    static multiEdit = (form: any) => utilities.request<TMultiEditResponse[]>({
        url: `ltddocs/multi-edit`,
        data: form,
        method: 'PATCH'
    })

    static multiCreate = (forms: Array<Partial<TDocument>>, parentNodeId: string) => utilities.request<TMultiCreateResponse[]>({
        url: `ltddocs/multi-create`,
        data: forms,
        method: 'POST',
        params: { parentNodeId }
    })

    static batchCreate = (form: Partial<TDocument>, parentNodeId: string, noOfDocuments: number) => utilities.request<TBatchCreateResponse[]>({
        url: `ltddocs/batch-create`,
        data: form,
        method: 'POST',
        params: { parentNodeId, noOfDocuments }
    })


    static publish = (ids: string[]) => utilities.request<TDocument[]>({
        url: 'ltddocs/publish',
        method: 'post',
        data: { ids, publish: true }
    })

    static fetchDocuments = (ids: string[], filter?: Record<string, any>) => utilities.request<TDocument[]>({
        url: `ltddocs`,
        params: {
            filter: {
                ...filter,
                where: {
                    id: { inq: ids },
                    ..._.get(filter, 'where')
                },
            }
        }
    })
}

