import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Button, Typography, Fab, FormControl, Input, TextField } from '@material-ui/core';
import BatchEditFieldSelection from '../../../Components/BatchEdit/BatchEditFieldSelection';
import { TLtdDocFormFieldKey, getLtdDocumentFormFieldKeys } from '../../../Components/BatchEdit/constants';
import { OApp } from '../../../RootReducer/AppReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dark } from '@material-ui/core/styles/createPalette';
import { darken } from '@material-ui/core/styles/colorManipulator';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';
import { WithTranslationProps, withTranslations } from '../../../Features/Translations/withTranslations';
import { LISTING_HEADER_RESET } from '../../../Features/Translations/translationKeys.ltd';

export interface BatchEditFieldSelectionSidebarProps extends WithStyles<typeof STYLES>, IDispatchProps {
    onDone: (fields: TLtdDocFormFieldKey[], numCopies?: number) => void
    onBack: () => void
    batchCreate?: boolean
}

interface IDispatchProps {
    closeSidebar: () => void
}


const BatchEditFieldSelectionSidebar: FC<BatchEditFieldSelectionSidebarProps & WithTranslationProps> = (props) => {
    const { classes, batchCreate = false, getTranslation } = props;
    const actionButtonText = batchCreate ? 'Batch Create' : 'Batch Edit';
    const [selectedFields, setSelectedFields] = useState<TLtdDocFormFieldKey[]>([])
    const [numOfCopies, setNumOfCopies] = useState(1);


    const handleFieldSelect = (field: TLtdDocFormFieldKey) => {
        if (selectedFields.includes(field))
            setSelectedFields(selectedFields.filter(f => f !== field));
        else
            setSelectedFields([...selectedFields, field])
    }



    const handleSubmit = () => {
        // title field is a required field for our Batch Editor,
        // should be automatically included even if the user doesn't select it from selection;
        if (batchCreate) {
            props.onDone(_.union(['title'], selectedFields), numOfCopies);
        } else {
            // isPublished status is required to show the user if the document is published.
            // This field is configured to be non editable;
            // Separate action to publish the document is provided in the editor
            props.onDone(_.union(['title', 'isPublished'], selectedFields));
        }
    }


    const resetSelection = () => {
        setSelectedFields([]);
    }


    const handleBack = () => {
        props.onBack()
    }

    const clearAll = () => {
        setSelectedFields([]);
    }

    const selectAll = () => {
        setSelectedFields(getLtdDocumentFormFieldKeys());
    }



    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>
                    <Fab size="small" onClick={handleBack}><i className="material-icons">chevron_left</i></Fab>
                    <Typography>Select fields you want to edit</Typography>
                </div>
                <Fab size="small" onClick={props.closeSidebar}>
                    <i className="material-icons">close</i>
                </Fab>

            </div>
            <div className={classes.content}>
                {batchCreate ? (
                    <FormControl className={classes.textInput} fullWidth>
                        <TextField
                            type="number"
                            label="Enter number of copies you want to create"
                            value={numOfCopies}
                            onChange={e => setNumOfCopies(parseInt(e.target.value))}
                        />
                    </FormControl>
                ) : null}
                <BatchEditFieldSelection stickyLabelPos={batchCreate ? 98 : 51} selectedFieldKeys={selectedFields} handleFieldSelect={handleFieldSelect} />
            </div>

            <div className={classes.selectionActionContainer}>
                <SelectedDocumentActions
                    containerClass={classes.selectionAction}
                    selectedIds={selectedFields}
                    actions={[
                        { label: 'Select All', action: selectAll },
                        { label: 'Clear Selection', action: clearAll },
                    ]}
                    docType='LtdDoc'
                    variant='ltd'
                />
                {/*                 
                <Button onClick={selectAll} className={classes.selectAllButton}>Select All</Button>
                <Button onClick={clearAll} className={classes.selectAllButton}>Clear Selection</Button> */}
            </div>
            <div className={classes.actionContainer}>
                <Button fullWidth variant="contained" color="primary" onClick={resetSelection}>{getTranslation(LISTING_HEADER_RESET) || 'Reset Selection'}</Button>
                <Button fullWidth variant="contained" onClick={handleSubmit} color="primary">{actionButtonText}</Button>
            </div>
        </div>
    )
}


const CONTAINER_WIDTH = 520;

const mapDispatchToProps = (dispatch) => ({
    closeSidebar: () => dispatch(OApp.closeSidebar())
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        width: CONTAINER_WIDTH,
    },
    textInput: {
        position: 'sticky',
        background: theme.palette.common.white,
        zIndex: 2,
        top: 51,
        padding: '0px 16px',
        boxSizing: 'border-box',
    },
    selectAllButton: {
        fontSize: 12,
        backgroundColor: '#bd5a20',

        color: 'white',
        '&:hover': {
            backgroundColor: darken('#bd5a20', 0.3)
        }
    },
    header: {
        // display: 'flex',
        // alignItems: 'center',
        // height: 50,
        // justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        // backgroundColor: theme.palette.grey[100],
        // zIndex: 1,
        // position: 'fixed',
        width: CONTAINER_WIDTH,
        // right: 0,
        display: 'flex',
        height: 51,
        zIndex: 2,
        alignItems: 'center',
        '& p': {
            color: 'white'
        },
        background: theme.palette.grey['100'],
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    content: {
        position: 'relative',
        width: CONTAINER_WIDTH,
    },
    actionContainer: {
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        '& > button': {
            borderRadius: 0
        }
    },
    selectionActionContainer: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: 100,
        width: 200,
        right: CONTAINER_WIDTH
    },
    selectionAction: {
        boxShadow: `0px 3px 6px rgba(0,0,0, 0.2)`,
        // transform: 'rotateX(70deg)',
        transition: '400ms ease-out',
        '&:before': {
            content: "''",
            width: 20,
            height: 20,
            zIndex: -1,
            position: 'absolute',
            top: 7,
            right: -2,
            transform: 'rotate(45deg)',

        },
        '& button > span': {
            justifyContent: 'flex-start'
        }
    },
});

export default connect(null, mapDispatchToProps)(withStyles(STYLES)(withTranslations(BatchEditFieldSelectionSidebar)));