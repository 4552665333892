import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, Tooltip, IconButton, Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import THEME from '../../../Resources/Theme';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import _ from 'lodash';
import { TLog } from '../../../Features/LtdDashboard/@types';
import SelectedDocumentActions from '../../../Components/SelectedDocumentActions';
import { TUser, ILtdPermissions } from '../../../Features/Authentication/@types';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import utilities from '../../../Resources/Utils';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { ODetail } from '../../../Features/DetailPage/redux-config';
import { OApp } from '../../../RootReducer/AppReducer';
import { getEntry, getLogActions } from './log-config';
import DocumentDetail from './DocumentDetail';
import NodeTree from './NodeTree';
import { TNode } from '../../../Features/DocumentsTree/@types';
import ActionFilter from './Filter/ActionFilter';
import { TActivityLogFilter, ActivityLogFilter } from '../../../Features/CldDashboard/Filters/activityLog';
import StatusFilter from './Filter/StatusFilter';
import TypeFilter from './Filter/TypeFilter';
import ByFilter from './Filter/ByFilter';
import WhenFilter from './Filter/WhenFilter';
import Entry from '../Entry';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import { TState } from '../../../RootReducer';
import { HISTORY } from '../../RootLayout';


interface IStateProps {
    pageNumber: number
    logs: TLog[]
    selectedIds: string[]
    user: TUser,
    logStatus: any,
    permissions: ILtdPermissions
    appliedFilter: TActivityLogFilter
    appConstants: any
}

interface IDispatchProps {
    fetchLogs: (pageNumber: number, appendResult?: boolean, whereFilter?: Record<string, any>) => void
    onSelect: (id: string) => void
    clearSelection: () => void
    expandTreeWithId: (ids: string[]) => any
    openDocument: (id) => any,
    updateLogStatus: (logs: TLog[], status) => any,
    openSidebar?: (component: JSX.Element) => void
    downloadLogsCSV: (whereFilter?: Record<string, any>) => void
    setSelection: (ids: string[]) => void
}

interface IState {
    selectedIds: string[]
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps { }

interface ILogEntryStateProps {
    log: TLog,
    statuses: any,
    appConstants: any
    permissions: ILtdPermissions,
    updateStatus: (log, status) => any,
    openSidebar?: (component: JSX.Element) => void
}
interface ILogEntryProps extends WithStyles<typeof STYLES>, ILogEntryStateProps { }

const ENTRY_COLUMNS = ['action', 'when', 'type', 'text', 'by'];
// const ENTRY_COLUMNS = ['action', 'when', 'type', 'text', 'by', 'status'];
const StatusSelect = ({ log, value, menuList, updateStatus }) => {
    return (
        <FormControl variant="filled" fullWidth>
            {/* <InputLabel htmlFor={`status-${i}-${log.id}`}>Status</InputLabel> */}
            <Select
                value={value}
                onChange={e => updateStatus([log], e.target.value)}
                inputProps={{
                    name: '',
                    id: `status-${log.id}`,
                }}
            >
                {
                    _.map(menuList, item => (
                        <MenuItem key={`${log.id}_status_menu_item_${item.value}`} value={item.value}>{item.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
const entryColumnMapping = (entry) => {
    let columnEntry = {};
    _.each(ENTRY_COLUMNS, col => {
        if (col === 'text')
            columnEntry[col] = entry.urlText || '-';
        else
            columnEntry[col] = (entry[col] || '-');
    });
    return columnEntry;
}
const getDocumentByLog = (log) => {
    if (log.action === 'create')
        return _.get(log, 'subject') || _.get(log, 'req.doc');
    if (log.action === 'edit') {
        return { ..._.get(log, 'subject'), ..._.get(log, 'req.doc') }
    }
    if (log.action === 'reject') {
        return { ..._.get(log, 'prevVersion'), ..._.get(log, 'req.doc') };
    }
    if (log.action === 'publish') {
        // return { ..._.get(log, 'prevVersion'), ..._.get(log, 'req.doc') };
        return _.get(log, 'subject');
    }
    if (log.action === 'permanentDelete')
        return _.get(log, 'prevVersion');
    if (log.action === 'copy')
        return _.get(log, 'res.ltdDoc') || _.get(log, 'subject');
    return _.get(log, 'subject');
}

class LogEntry extends Component<ILogEntryProps, IState> {
    state = {
        selectedIds: [] as string[]
    }
    getDocPathIds = (log) => {
        let parentIds = log.parentIds;
        if (log.action === 'move' || log.action === 'copy') {
            const newParent = log.newParent;
            if (_.isEmpty(newParent))
                return;
            parentIds = [...newParent.parentIds, newParent.id];
        }
        return parentIds;

    }
    openDocument = (logData) => {
        const document = getDocumentByLog(logData);
        if (_.isEmpty(document))
            return;
        const pathIds = this.getDocPathIds(logData);
        const displayNav = (logData.action !== 'copy') || !!_.get(logData, 'res.ltdDoc.id')
        // const displayNav = (logData.action !== 'copy' && logData.action !== 'create') || !!_.get(logData, 'res.ltdDoc.id')
        // this.setDocumentPath(logData, document);
        if (!this.props.openSidebar)
            return;
        this.props.openSidebar((
            <DocumentDetail document={document} title={document.title} log={logData} pathIds={pathIds} displayNavigation={displayNav} />
        ))
    }
    openFolder = (logData) => {
        const parentIds = _.get(logData, 'parentIds') || [];

        let node = (_.get(logData, 'subject') || _.get(logData, 'req.node')) || _.get(logData, 'req') as TNode;
        if (logData.action === 'delete')
            node = _.get(logData, 'prevVersion');
        if (!this.props.openSidebar)
            return;
        this.props.openSidebar((
            <NodeTree nodeIds={parentIds} node={node} title="Node Tree" />
        ))
    }
    handleDocumentClick = () => {
        const { log } = this.props;
        // this.props.
        if (!log.subject) return;
        if (log.subjectType === 'LtdDoc')
            HISTORY.push(`/decision/${log.subject.slug}`);
        if (log.subjectType === 'CldDoc')
            HISTORY.push(`/clddoc/${log.subjectId}`);
    }
    handleNodeClick = () => {
        const { log } = this.props;
        this.openFolder(log);
    }
    handleActivityLogClick = () => {
        const { log } = this.props;
        const logData = { ...log.subject, user: log.user, action: log.action, created: log.created };
        if (logData.subjectType === 'LtdDoc')
            this.openDocument(logData);
        else if (logData.subjectType === 'Node')
            this.openFolder(logData);
    }
    handleEntryClick = () => {
        const { log } = this.props;
        if (log.subjectType === 'LtdDoc' || log.subjectType === 'CldDoc')
            this.handleDocumentClick();
        // else if (log.subjectType === 'Node')
        //     this.handleNodeClick();
        // else if (log.subjectType === 'Activitylog') {
        //     this.handleActivityLogClick();
        // }
    }


    render() {
        const { log, classes, statuses, permissions, updateStatus, appConstants } = this.props;
        const { selectedIds } = this.state;
        const entry = getEntry(log, appConstants);
        if (_.isEmpty(entry))
            return <div />;
        const colEntry = entryColumnMapping(entry);
        const actions = getLogActions(log, permissions);
        const statusOptions = utilities.findValues(statuses, actions, 'value', 'name', true);
        return (
            <>


                {
                    _.map(ENTRY_COLUMNS, col => (
                        <div key={`${log.id}_${col}`} className={classNames('column-size', classes.column)}>

                            {
                                (col !== 'status' && col !== 'text') &&
                                (<Entry>{colEntry[col]}</Entry>)
                            }
                            {
                                (col === 'status') &&
                                (<StatusSelect log={log} value={colEntry[col]} menuList={statusOptions} updateStatus={updateStatus} />)
                            }
                            {
                                (col === 'text') &&
                                ((<Typography className="pointer" component="a" onClick={this.handleEntryClick} >
                                    <Entry>{colEntry[col]}</Entry>
                                </Typography>))
                            }

                        </div>
                    ))
                }

            </>
        )
    }
}
class Log extends Component<IProps, IState> {

    componentDidMount() {
        if (_.isEmpty(this.props.logs))
            this.props.fetchLogs(0, false, ActivityLogFilter.getWhereFilterFromAppliedFilter({ action: 'all' }));
    }

    handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, logId: string) => {
    }


    handleUrlClick = async (log: TLog) => {
        try {
            switch (log.subjectType) {
                case 'Node': {
                    await this.props.expandTreeWithId([log.subjectId]);
                    this.props.history.push('/ltd-dashboard/tree-management');
                    return;
                }
                case 'LtdDoc': {
                    if (_.isEmpty(log.subject)) return;
                    const id = !_.isEmpty(log.subject.slug) ? log.subject.slug : log.subject.id;
                    if (!id)
                        return;
                    this.props.openDocument(id);
                    this.props.history.push(`/doc/${id}/`)
                }
            }
        } catch (error) {

        }
    }


    handleDownload = () => {
        const whereFilter = ActivityLogFilter.getCSVWhereFilterFromAppliedFilter(this.props.appliedFilter);
        this.props.downloadLogsCSV(whereFilter);
    }

    getDownloadButton = () => {

        return (
            <Tooltip title="Download CSV">
                <IconButton onClick={this.handleDownload} className="mx-2">
                    <Icon>file_download</Icon>
                </IconButton>
            </Tooltip>
        )
    }


    loadMore = () => {
        const whereFilter = ActivityLogFilter.getWhereFilterFromAppliedFilter(this.props.appliedFilter);
        this.props.fetchLogs(this.props.pageNumber + 1, true, whereFilter);
    }

    handlePublishselectedLogs = () => {
        const { selectedIds, logs } = this.props;
        const logsToUpdate = logs.filter(log => selectedIds.includes(log.id));
        this.props.updateLogStatus(logsToUpdate, 'published');
        this.props.clearSelection();
    }


    handleSelect = (id: string) => (e, checked: boolean) => {
        this.props.onSelect(id);
    }

    handleSelectAll = (isAllSelected: boolean) => () => {
        const { logs } = this.props;
        if (isAllSelected)
            this.props.setSelection([]);
        else
            this.props.setSelection(logs.map(l => l.id));
    }


    render() {
        const { classes, logs, selectedIds = [], user, logStatus, permissions, updateLogStatus, openSidebar } = this.props

        const HEADER_CONFIG = [
            { label: 'Action', width: 4, component: (<ActionFilter />) },
            // { label: 'Action', width: 4, component: (<Typography>Action</Typography>) },
            { label: 'When', width: 6, component: (<WhenFilter />) },
            { label: 'Type', width: 3, component: (<TypeFilter />) },
            { label: 'Node/Record URL', width: 8, component: (<Entry type="label">Node/Record URL</Entry>) },
            { label: 'By', width: 5, component: (<ByFilter />) },
            // { label: 'Status', width: 5, component: (<StatusFilter />) },
        ]




        const ACTIONS = [
            { label: 'Reset selection', action: this.props.clearSelection },
            { label: 'Accept', action: this.handlePublishselectedLogs },
        ]


        if (!utilities.isAuthenticated())
            return <Redirect to="/" />


        const isAllSelected = logs.length === selectedIds.length && selectedIds.length > 0;
        const shouldShowCheckbox = permissions.docMultiPublish;


        return (
            <div className={shouldShowCheckbox ? classes.root : classes.rootAlt}>
                <div className={classes.toolbar}>
                    <div>
                        {shouldShowCheckbox ? (
                            <div className={classNames(classes.checkboxContainer)}>
                                <Checkbox
                                    color="primary"
                                    onChange={this.handleSelectAll(isAllSelected)}
                                    checked={isAllSelected}
                                    className={classes.checkbox}
                                />
                            </div>
                        ) : null}
                        {
                            HEADER_CONFIG.map(config => (
                                <div key={config.label} className={classNames(classes.colHeader, "column-size")} >
                                    {config.component}
                                </div>
                            ))
                        }
                        <div className={classes.downloadButton}>
                            {this.getDownloadButton()}
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.container)}>
                    {
                        logs.map(log => (
                            <div key={log.id} className={classNames(classes.row, classes.root)}>
                                {shouldShowCheckbox ? (
                                    <div className={classNames(classes.checkboxContainer)}>
                                        <Checkbox
                                            color="primary"
                                            onChange={this.handleSelect(log.id)}
                                            checked={selectedIds.indexOf(log.id) > -1}
                                            className={classes.checkbox}
                                        />
                                    </div>
                                ) : null}
                                <LogEntry
                                    appConstants={this.props.appConstants}
                                    log={log} classes={classes} statuses={logStatus}
                                    permissions={permissions}
                                    updateStatus={updateLogStatus}
                                    openSidebar={openSidebar}
                                />
                            </div>))
                    }
                    {
                        logs.length > 19 ?
                            <Button onClick={this.loadMore} >
                                Load more
                            </Button> : null
                    }
                </div>

                <SelectedDocumentActions
                    variant="ltd"
                    classes={{ actionControlContainer: classes.actionContainer }}
                    selectedIds={selectedIds}
                    docType="LtdDoc"
                    actions={ACTIONS}
                />
            </div>
        )

    }
}

const mapStateToProps = (state: TState): IStateProps => ({
    pageNumber: _.get(state.CldDashboard, 'activityLog.pageNumber'),
    selectedIds: _.get(state.CldDashboard, 'selectedIds'),
    logs: _.get(state.CldDashboard, 'activityLog.logs'),
    user: _.get(state.Auth, 'user'),
    logStatus: _.get(state.App, 'appConstants.ActivityLog.status'),
    appConstants: _.get(state.App, 'appConstants'),
    permissions: _.get(state.Auth, 'ltdPermissions'),
    appliedFilter: _.get(state.CldDashboard, 'activityLog.filter')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchLogs: (pageNumber, appendResult, whereFilter) => dispatch(OCldDashboard.fetchLogs(pageNumber, appendResult, whereFilter)),
    onSelect: (id: string) => dispatch(OLtdDashboard.toggleCheck(id)),
    setSelection: (ids: string[]) => dispatch(OLtdDashboard.setSelection(ids)),
    clearSelection: () => dispatch(OLtdDashboard.resetSelection()),
    expandTreeWithId: (ids: string[]) => dispatch(ODocumentTree.expandTreeWithIds(ids)),
    openDocument: (id) => dispatch(ODetail.openDocument(id, 'CLD')),
    updateLogStatus: (logs, status) => dispatch(OCldDashboard.updateLogStatus(logs, status)),
    openSidebar: (component) => dispatch(OApp.openSidebar(component)),
    downloadLogsCSV: (whereFilter) => dispatch(OLtdDashboard.downloadActivityLogsCSV(whereFilter, 'activity-log'))
})


const DELTA = 900;
// const DELTA = 1110;
const BORDER_STYLE = `1px dashed ${fade('#000', 0.2)}`;
const CHECKBOX_CELL_WIDTH = 44;
const MAIN_CONTENT_WIDTH = window.outerWidth - 100 + CHECKBOX_CELL_WIDTH;
const TOOLBAR_HEIGHT = 50;
const STYLES = (theme: Theme) => createStyles({
    root: {
        // height: 2000
        '& .column-size': {
            textAlign: 'left',
            '&:nth-child(2)': {
                width: 100 * MAIN_CONTENT_WIDTH / DELTA //Action
            },
            '&:nth-child(3)': {
                width: 190 * MAIN_CONTENT_WIDTH / DELTA//When
            },
            '&:nth-child(4)': {
                width: 100 * MAIN_CONTENT_WIDTH / DELTA // Type
            },
            '&:nth-child(5)': {
                width: 370 * MAIN_CONTENT_WIDTH / DELTA // Record url
            },
            '&:nth-child(6)': {
                width: 180 * MAIN_CONTENT_WIDTH / DELTA // By
            },
            // '&:nth-child(7)': {
            //     width: 170 * MAIN_CONTENT_WIDTH / DELTA //Status
            // }
        }
    },
    rootAlt: {
        // height: 2000
        '& .column-size': {
            textAlign: 'left',
            '&:nth-child(1)': {
                width: 100 * MAIN_CONTENT_WIDTH / DELTA //Action
            },
            '&:nth-child(2)': {
                width: 190 * MAIN_CONTENT_WIDTH / DELTA//When
            },
            '&:nth-child(3)': {
                width: 100 * MAIN_CONTENT_WIDTH / DELTA // Type
            },
            '&:nth-child(4)': {
                width: 370 * MAIN_CONTENT_WIDTH / DELTA // Record url
            },
            '&:nth-child(5)': {
                width: 180 * MAIN_CONTENT_WIDTH / DELTA // By
            },
            // '&:nth-child(6)': {
            //     width: 170 * MAIN_CONTENT_WIDTH / DELTA //Status
            // }
        }
    },
    colHeader: {
        padding: '0px 4px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderLeft: BORDER_STYLE,
        '&:nth-child(6)': {
            borderRight: BORDER_STYLE,
        }
    },
    toolbar: {
        // position: 'fixed',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        background: THEME.Colors.Grayscale.White,
        boxShadow: `0px 1px 3px ${fade('#000', 0.16)}`,
        '& > div': {
            height: TOOLBAR_HEIGHT,
            width: MAIN_CONTENT_WIDTH,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        // '& > div:first-child': {
        //     height: TOOLBAR_HEIGHT,
        //     width: MAIN_CONTENT_WIDTH ,
        // }

    },
    downloadButton: {
        position: 'absolute',
        right: -16,
    },
    container: {
        position: 'relative',
        // paddingTop: TOOLBAR_HEIGHT,
        width: MAIN_CONTENT_WIDTH,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 100,
    },
    row: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        borderBottom: BORDER_STYLE,
        borderLeft: BORDER_STYLE,

    },
    column: {
        borderRight: BORDER_STYLE,
        padding: 6,
        boxSizing: 'border-box',
        '&:nth-child(4)': {
            textAlign: 'left'
        },
        '&:last-child': {
            background: THEME.Colors.Grayscale.White
        }
    },
    checkbox: {
        padding: 0,
        minHeight: 0,
        minWidth: 0
    },
    actionContainer: {
        top: 140,
        right: -35,
        position: 'fixed',
        bottom: 'auto'
    },
    checkboxContainer: {
        // position: 'absolute',
        // zIndex: 1,
        // left: -40
        boxSizing: 'border-box',
        borderRight: BORDER_STYLE,
        width: CHECKBOX_CELL_WIDTH,
        minWidth: CHECKBOX_CELL_WIDTH,

    }

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Log))