export default {
    // Colors
    Colors: {
        Primary: {
            main: '#003366',
            dark: '#002040',
            light: '#00468C',
        },
        // Primary: {
        //     main: '#47B4A8',
        //     dark: '#388F85',
        //     light: '#56DBCC'
        // },
        Secondary: {
            main: '#47B4A8',
            dark: '#388F85',
            light: '#56DBCC',
        },
        Tertiary: {
            main: '#005aa9',
            dark: '#d30d33',
            light: '#56DBCC',
        },
        // Third: '#388F85',
        Third: '#6699CC',
        Fourth: '#908588',
        Success: '#718C05',
        Green: '#429D3E',
        Error: '#CB423F',
        Highlight: '#E5CB3E',

        Grayscale: {
            Black: '#000000',
            Grey1: '#262626',
            Grey2: '#4D4D4D',
            Grey3: '#808080',
            Grey4: '#D9D9D9',
            Grey5: '#F7F7F7',
            Grey6: '#F0F0F0',
            Grey7: '#403F41',
            White: '#FFFFFF',
        },
        TextLight: '#707070',
        TextColor: '#505050',
    },
};