import { TBatchEditState } from "./@types";
import { OBatchEdit } from "./redux-config";
import _ from 'lodash';

const initialState: TBatchEditState = {
    docIds: [],
    formFields: [],
    errorIds: [],
    publishedDocIds: [],
    selectedDocIds: [],
    editorOpen: false,
    loading: false,
    isNewDocument: false,
    batchEditForm: {},
    batchCreateForm: {},
    formModified: false,
    modifiedDocs: []
}





export const BatchEditReducer = (state = initialState, action): TBatchEditState => {
    const {
        BATCH_EDIT_DOCUMENTS,
        CLOSE_BATCH_EDIT,
        BATCH_EDIT_FORM_CHANGE,
        BATCH_EDIT_FORM_SAVED,
        BATCH_EDIT_DOC_UPDATED,
        BATCH_CREATE_DOCUMENTS,
        MULTI_CREATE_DOCUMENTS,
        BATCH_CREATE_FORM_SAVED,
        BATCH_EDIT_SET_LOADING,
        BATCH_EDIT_DOCUMENTS_PUBLISHED,
        BATCH_EDIT_TOGGLE_DOC_SELECTION,
        BATCH_EDIT_CLEAR_SELECTION,
        BATCH_EDIT_SELECT_ALL_DOCUMENTS
    } = OBatchEdit.actions;

    switch (action.type) {
        case BATCH_EDIT_CLEAR_SELECTION: {
            return { ...state, selectedDocIds: [] }
        }
        case BATCH_EDIT_SELECT_ALL_DOCUMENTS: {
            return { ...state, selectedDocIds: [...state.docIds] }
        }
        case BATCH_EDIT_TOGGLE_DOC_SELECTION: {
            const docId = action.data;
            const updatedSelection = state.selectedDocIds.includes(docId) ? state.selectedDocIds.filter(i => i !== docId) : [...state.selectedDocIds, docId];
            return { ...state, selectedDocIds: updatedSelection };
        }
        case BATCH_EDIT_DOCUMENTS_PUBLISHED: {
            let _form = state.isNewDocument ? { ...state.batchCreateForm } : { ...state.batchEditForm };
            _.forEach(action.data, id => {
                if (_form[id]) _form[id].isPublished = true;
            })
            if (state.isNewDocument)
                return { ...state, batchCreateForm: _form }
            else
                return { ...state, batchEditForm: _form }
        }
        case BATCH_EDIT_SET_LOADING: {
            return { ...state, loading: action.data }
        }
        case BATCH_CREATE_FORM_SAVED: {
            const { errorIds } = action.data
            const _successIds = Object.keys(state.batchCreateForm).filter(id => !errorIds.includes(id))
            return { ...state, formModified: false, docIds: [], batchCreateForm: _.omit(state.batchCreateForm, _successIds), errorIds }
        }
        case MULTI_CREATE_DOCUMENTS: {
            return { ...state, parentNode: _.get(action.data, 'parentNode'), numOfDocuments: undefined, formModified: true, isNewDocument: true, editorOpen: true, batchCreateForm: _.get(action.data, 'formData'), formFields: _.get(action.data, 'fields') }
        }
        case BATCH_CREATE_DOCUMENTS: {
            return { ...state, parentNode: _.get(action.data, 'parentNode'), numOfDocuments: _.get(action.data, 'numOfDocuments'), formModified: true, isNewDocument: true, editorOpen: true, batchCreateForm: _.get(action.data, 'formData'), formFields: _.get(action.data, 'fields') }
        }
        case BATCH_EDIT_DOC_UPDATED: {
            return { ...state, formModified: true, modifiedDocs: _.union(state.modifiedDocs, [action.data]) }
        }
        case BATCH_EDIT_FORM_SAVED: {
            return { ...state, formModified: false, modifiedDocs: [] }
        }
        case BATCH_EDIT_FORM_CHANGE: {
            if (state.isNewDocument)
                return { ...state, batchCreateForm: action.data }
            return { ...state, batchEditForm: action.data }
        }
        case BATCH_EDIT_DOCUMENTS: {
            return { ...state, parentNode: undefined, formModified: false, docIds: action.data.docIds, formFields: action.data.fields, editorOpen: true }
        }
        case CLOSE_BATCH_EDIT: {
            return { ...initialState }
        }
        default: return { ...state }
    }
}