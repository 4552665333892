import React, { Component, useState } from 'react'
import { createStyles, WithStyles, withStyles, Tab, Tabs, Typography, Paper, Button, Menu, MenuItem, Input, Divider, Collapse, Tooltip, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TCurrentDocument } from '../../Features/DetailPage/@types';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import classNames from 'classnames';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import { TReadingList } from '../../Features/ReadingList/@types';
import { TState } from '../../RootReducer';
import Bookmark from '../../Components/Bookmark';
import { withRouter, RouteComponentProps } from 'react-router';
import { getDownloadUrl } from '../../Features/CLDDocuments/redux-config';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_DOWNLOAD } from '../../Features/Translations/translationKeys.cld';


interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {
    currentDocument: TCurrentDocument,
    tabValue: number,
    onTabChange: Function,
    onDownload?: Function
    tabs: Array<string>
}

interface IDispatchProps {
    closeDocument: Function,
    fetchReadingLists: Function,
}

interface IStateProps {
    userId: string,
}


const LeftSection = (props) => {
    const { classes, tabValue, tabs } = props;
    const ltdDoc = /\/doc.*/i.test(window.location.pathname);
    const decision = /\/decision.*/i.test(window.location.pathname);

    return (
        <div className={classes.leftSection}>
            {
                _.map(tabs, (tab, i) =>
                    <Button key={i} className={
                        i === tabValue ?
                            (ltdDoc ? classes.activeTab : (decision || tabValue === 1) ? classes.activeTabDecision : classes.activeTabCld)
                            : classes.inactiveTab
                    } onClick={e => props.onTabChange(i)}>{tab}</Button>
                )
            }
        </div>
    )
}



class _RightSection extends Component<Props & IStateProps & RouteComponentProps & WithTranslationProps>{

    printPage = () => window.print()

    downloadPDF = () => {
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const document: TDocument = _.get(this.props, 'currentDocument.document');

        if (cldDoc) {
            const url = getDownloadUrl(document.id);
            window.open(url, '_blank');
            return;
        }

        const requireDownloadPermission = _.get(document, 'requireDownloadPermission') || false;
        if (requireDownloadPermission) {
            if (this.props.onDownload) {
                this.props.onDownload();
            }
            return;
        }
        const url: string = _.get(document, 'pdfURL');
        window.open(url, '_blank');
    }


    closePage = () => {
        this.props.closeDocument();
        // const { history } = this.props;
        // if (!history)
        //     return;
        // history.goBack();
    }

    render() {
        const { classes, currentDocument, getTranslation } = this.props;
        const isCldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const isDecision = /\/decision.*/i.test(window.location.pathname);

        const copyRightAuthorisation = (_.get(currentDocument, 'document.copyRightAuthorisation') && !isCldDoc) || false;
        const docId = _.get(currentDocument, 'document.id') || _.get(currentDocument, 'documentId');

        const cldUrl = getDownloadUrl(_.get(currentDocument, 'document.id'))

        return (
            <div className={classes.rightSection}>
                {
                    copyRightAuthorisation ?
                        <Button onClick={this.downloadPDF} className={classes.actionBtn}>
                            <Typography color="textPrimary">{getTranslation(GENERAL_DOWNLOAD).toUpperCase()}</Typography>
                            <i className="material-icons">save_alt</i>
                        </Button> : null

                }
                {
                    isCldDoc ?
                        <Button onClick={this.downloadPDF} className={classes.actionBtn}>
                            <Typography color="textPrimary">{getTranslation(GENERAL_DOWNLOAD).toUpperCase()}</Typography>
                            <i className="material-icons">save_alt</i>
                        </Button> : null

                }
                <Bookmark classes={{ actionBtn: classes.actionBtn }} type={isDecision || isCldDoc ? 'cld' : 'ltd'} docType={isCldDoc ? 'CldDoc' : 'LtdDoc'} color="textPrimary" id={docId} />
                <Button className={classes.closeBtn} onClick={this.closePage}>
                    <i className="material-icons">close</i>
                </Button>
            </div>
        )
    }

}

const RightSection = withRouter(_RightSection)




class Header extends Component<Props & IStateProps> {

    render() {
        const { classes, currentDocument } = this.props;
        const document = _.get(currentDocument, 'document');
        // const { contentType = '' } = currentDocument;
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);
        const decision = /\/decision.*/i.test(window.location.pathname);

        const title = (ltdDoc || decision) ? _.get(document, 'title') : _.get(document, 'Title');


        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <Button onClick={e => this.props.closeDocument()} className={classes.backBtn}>
                        <i className="material-icons">chevron_left</i>
                    </Button>
                    <LeftSection {...this.props} />
                    <Typography variant="caption" className={classes.title}>
                        {_.truncate(title, { length: 180 })}
                    </Typography>
                </div>
                <RightSection {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = (state: TState) => ({
    userId: _.get(state.Auth, 'user.id'),
})

const mapDispatchToProps = dispatch => ({
    closeDocument: () => dispatch(ODetail.closeDocument()),
    fetchReadingLists: (id) => dispatch(OReadingList.fetchReadingLists(id)),
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        height: 48,
        display: 'flex',
        width: 'calc(100vw - 360px)',
        zIndex: 1,
        background: 'white',
        alignItems: 'center',
        padding: '0px 14px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        boxShadow: `3px 1px  3px ${fade(THEME.Colors.Grayscale.Black, 0.3)}`,
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        }
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        // maxWidth: 540,
        // flex: 1,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        lineClamp: 1,
        WebkitLineClamp: 1,
        padding: '0px 36px',
        lineHeight: 1.2,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    leftSection: {
        display: 'flex',
        '& button': {
            minWidth: 0,
            whiteSpace: 'nowrap'
        }
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',

    },
    activeTab: {
        color: THEME.Colors.Secondary.main
    },
    activeTabCld: {
        color: THEME.Colors.Third,
        // minWidth: '125px !important'
    },
    activeTabDecision: {
        color: THEME.Colors.Third
    },
    inactiveTab: {
        color: THEME.Colors.TextColor
    },
    closeBtn: {
        minWidth: 0,
        width: 40,
        border: `1px solid ${THEME.Colors.Third}`,
        borderRadius: 22,
        padding: 2,
        '& i': {
            color: THEME.Colors.Third
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    backBtn: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        }
    },
    actionBtn: {
        minWidth: 100,
        fontWeight: 400,
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
            fontSize: 14
        },
        '& .material-icons': {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            }
        },
        '& p': {
            fontWeight: 400,
            fontSize: 14,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        }

    },
    cldAction: {
        '& > a > p': {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        },
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(Header)))


