const projectBackgroundFr = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
    <div id="c258" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Historique du projet</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p  class="bodytext">
        Les Outils juridiques de la CPI ont été élaborés par la Section des avis juridiques du Bureau du Procureur de la CPI entre 2003 et 2005 ; depuis 2006, ils sont sous la responsabilité du directeur du Centre for International Law Research and Policy  
          (<a href="https://www.cilrap.org/purpose/" target="_blank">CILRAP</a>),&nbsp;
          <a href="https://www.cilrap.org/bergsmo/">Morten Bergsmo</a> 
          (qui a conçu et supervisé le projet et ses différentes composantes en sa qualité de chef de la Section des avis juridiques entre 2003 et 2005, puis en tant que coordonnateur de projet).
             </p>
        <p class="bodytext">
        En 2005, la CPI a créé un Groupe consultatif d’experts sur les Outils juridiques de la CPI, regroupant d’éminents spécialistes en informatique et en droit pour identifier et valider les efforts de perfectionnement technique et stratégique des Outils juridiques. Le groupe est composé des personnes suivantes : 
          <br/> le Professeur 
          <a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> 
          (Norway); le Professeur <a href="http://herberger.jura.uni-sb.de/herberger/" target="_blank">
          Maximilian Herberger</a>,
          Université de la Sarre (Allemagne) ; le Professeur Anja Oskamp, Université libre d’Amsterdam (Pays-Bas) ; le Professeur <a href="https://www.gu.se/en/about/find-staff/ulfpetrusson" target="_blank">Ulf Petrusson</a>
            (Université de Göteborg); le Professeur  <a href="https://people.cs.kuleuven.be/~sien.moens/" target="_blank">
            Marie-Francine (Sien) Moens</a>, Université catholique de Louvain (Belgique); 
             le Professeur Richard V. De Mulder, Université Erasmus de Rotterdam (Pays-Bas) ; et le Professeur <a href="https://www.eui.eu/people?id=giovanni-sartor" target="_blank">Giovanni Sartor</a>,
              Institut universitaire européen (Italie). Le regretté Professeur 
                <a href="https://en.wikipedia.org/wiki/Jon_Bing" target="_blank">Jon Bing</a> fut le premier président du groupe. La session inaugurale du groupe a eu lieu au siège provisoire de la CPI à La Haye le 7 décembre 2005.
                </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
    </div>`;
export default projectBackgroundFr;
