import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, LinearProgress } from '@material-ui/core'
import FileInput from './FileInput'
import { fade } from '@material-ui/core/styles/colorManipulator';
import _ from 'lodash';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { MENU_LIST_ATTACH_FILE } from '../../Features/Translations/translationKeys.ltd';



interface IProps extends WithStyles<typeof STYLES> {
    id: string
    label: string
    formData: Record<string, any>
    formKey: string
    pdfNameKey?: string
    accept?: string
    uploadButtonText?: string
    onUpload: (file: any) => Promise<any>
}

class FileUpload extends Component<IProps & WithTranslationProps> {
    state = {
        loading: false
    }

    getFiles = async (files) => {
        if (!_.isArray(files))
            return;

        if (files.length > 0) {
            try {
                this.setState({ loading: true })
                await this.props.onUpload(_.get(files[0], 'file'));
                this.setState({ loading: false })
            } catch (error) {
                this.setState({ loading: false })
            }
        }
    }

    render() {
        const { classes, pdfNameKey = '', id, label, uploadButtonText = "Attach file", formData, formKey, accept = "application/pdf, image/*", getTranslation } = this.props
        return (
            <div className={classes.root}>
                <div className="flex-row space-between align-center">
                    <Typography className={classes.label}>{label}</Typography>
                    <label htmlFor={id}>
                        <Typography style={{ cursor: 'pointer' }} color="primary">{getTranslation(MENU_LIST_ATTACH_FILE)}</Typography>
                    </label>
                </div>
                <div className={classes.container}>
                    {
                        this.state.loading ? <LinearProgress color="primary" /> : null
                    }
                    {
                        _.get(formData, formKey) ?
                            <a className={classes.item} href={formData[formKey]} target="_blank">
                                {formData[pdfNameKey] ? formData[pdfNameKey] : 'PDF'}
                            </a> : null
                    }
                </div>


                <FileInput
                    accept={accept}
                    multiple={false}
                    onDone={(file) => this.getFiles(file)}
                    id={id}
                    style={{ display: 'none' }}
                />

            </div >

        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        paddingTop: 16,
    },
    container: {

    },
    label: {
        color: fade('#000', 0.57),
        fontSize: 16
    },
    item: {
        color: theme.palette.text.primary,
        display: 'flex',
        textDecoration: 'none',
        flex: 1,
        background: '#F0F0F0',
        borderRadius: 4,
        padding: 4,
        margin: '4px 0px',
    }
})

export default withStyles(STYLES)(withTranslations(FileUpload))