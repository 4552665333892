const iccLegalToolsEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="what-are-the-icc-legal-tools/" >What are the ICC Legal Tools?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:8/textpic [begin] -->
    <div id="c8" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">What are the ICC Legal Tools? </h1></div>
    <!--  Header: [end] -->
        
    <!--  Image block: [begin] -->
        <div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">The ICC Legal Tools are the leading information services on international criminal law. They equip users with legal information, digests and an application to work more effectively with core international crimes cases (involving war crimes, crimes against humanity, genocide or aggression). By being freely available in the public commons, the Tools equalize access to international criminal law information, thus empowering practitioners and levelling preconditions for criminal justice in both richer and materially less resourceful countries. The Tools are a significant contribution towards national capacity-development in criminal justice for core international crimes. 
</p>
<p class="bodytext">The open access Tools comprise the ICC Legal Tools Database (including the ICC Case Law Database), together with legal research and reference tools developed by lawyers with expertise in international criminal law and justice: the ICC Case Matrix, the Elements Digest, and the Means of Proof Digest. Text in these tools or in the ICC Legal Tools Database does not necessarily represent views of the ICC, any of its Organs or any participant in proceedings before the ICC or any of the ICC States Parties.</p>
<p class="bodytext">The ICC Legal Tools Database contains several collections:</p>
    <!--  Text: [end] -->
        </div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:8/textpic [end] -->  
<!--  CONTENT ELEMENT, uid:283/text [begin] -->
    <div id="c283" class="csc-default">
    <!--  Text: [begin] -->
        <ul><li><b>ICC Documents</b>: basic legal documents, public Court records, Assembly of States Parties documents, Trust Fund for Victims documents, and the ICC preparatory works and Statute amendments (that include more than 9,000 documents related to the negotiation of the ICC Statute, Rules of Procedure and Evidence, and Elements of Crime document, as well as documents related to amendments to the ICC Statute); </li><li><b>International Legal Instruments</b>: a collection of key treaties; </li><li><b>Other International Law Decisions and Documents</b>: documents of the International Court of Justice, International Law Commission, UN General Assembly and UN Security Council, and other UN and regional bodies; </li><li><b>Human Rights Law Decisions and Documents</b>: decisions of the European Court of Human Rights and Inter-American Court of Human Rights, and documents of the African Commission on Human and Peoples' Rights, and the UN human rights system; </li><li><b>Other International(ised) Criminal Jurisdictions</b>: basic legal documents and public court records of the international(ised) criminal jurisdictions; </li><li><b>National Criminal Jurisdictions</b>: basic documents on the legal system, criminal laws and procedures, and national implementing legislation and cases on core international crimes; </li><li><b>Publications</b>: publications relevant to work on international criminal law, international human rights law, and international humanitarian law; </li><li><b>United Nations War Crimes Commission</b>: the public part of the archive of the UN War Crimes Commission, included case-related documents; </li><li><b>International(ised) Fact-Finding Mandates</b>: documents on international(ised) fact-finding mandates. </li></ul>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:283/text [end] -->
    
<!--  CONTENT ELEMENT, uid:284/textpic [begin] -->
    <div id="c284" class="csc-default">
    <!--  Image block: [begin] -->
        <div class="csc-textpic csc-textpic-center csc-textpic-below"><div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">The ICC Case Law Database contains legal findings from the judgments, decisions and orders issued by the ICC. The ICC Case Matrix is a law-driven case management and legal information application representing significant innovation in how to approach criminal cases. The in-depth evidence analysis charts used in several core international crimes jurisdictions are based on the logic and idea of the ICC Case Matrix. The Elements Digest and Means of Proof Digest functionalities of the ICC Case Matrix give access to case law broken down according to the elements of crime and their proof structures. Ralph Hecksteden and Morten Bergsmo were awarded the 2008 Dieter Meurer Prize for Legal Informatics for making the ICC Case Matrix. Online access is provided to different versions of the Case Matrix at <a href="http://www.casematrixnetwork.org/icc-case-matrix/" target="_blank">www.casematrixnetwork.org/icc-case-matrix/</a>, where you find more information.
</p>
<p class="bodytext">Films about the ICC Legal Tools and basic guidance on their use are linked to below. The following brochures on the ICC Legal Tools and the ICC Case Matrix are available for download:</p><ul><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220321%20LTD-CLD%20leaflet-final.pdf" target="_blank">220321 Leaflet on the ICC Legal Tools</a></li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220406-LTD-CLD-Leaflet-FR.pdf" target="_blank">220406 Leaflet on the ICC Legal Tools (French)</a></li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220403-LTD-CLD+Leaflet-ES.pdf" target="_blank">220403 Leaflet on the ICC Legal Tools (Spanish)</a></li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220706-LTD-CLD-Leaflet-AR.pdf" target="_blank">220420 Leaflet on the ICC Legal Tools (Arabic)</a></li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151117_LTD_leaflet__Second_Edition.pdf" target="_blank">151117 Leaflet on the ICC Legal Tools</a></li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Case_Matrix-ENG-FRA-2010-Print.pdf" target="_blank">Case Matrix</a></li><li><a href="https://www.legal-tools.org/training-and-contact/tutorial-film/" target="_blank">Tutorial films on the ICC Legal Tools Database</a></li></ul>
    <!--  Text: [end] -->
        </div></div><div class="csc-textpic-clear"><!-- --></div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:284/textpic [end] -->
    </div>`;

export default iccLegalToolsEn;
