import { Models } from '../../Resources/Model';
import _ from 'lodash';
import utilities from '../../Resources/Utils';
import { TState } from '../../RootReducer';
import { OApp } from '../../RootReducer/AppReducer';
import { TUserRole } from '../Authentication/@types';
import Axios from 'axios';
import { ODocumentTree } from '../DocumentsTree/redux-config';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import moment from 'moment';
import qs from 'querystring';
import { ODocuments, SORTS, STATIC_FILTER } from '../LTDDocuments/redux-config';
import { LTD_INCLUDES } from '../DetailPage/redux-config';
import { LtdDocService } from '../../Services/LtdDocs.service';
import { SuggestionsService } from '../../Services/Suggestions.service';
import { TLog } from './@types';

class LtdDashboard extends Models {
    constructor() {
        super('dashboard', {
            LTD_DASHBOARD_SELECT_ID: 'LTD_DASHBOARD_SELECT_ID',
            LTD_DASHBOARD_RESET_SELECTION: 'LTD_DASHBOARD_RESET_SELECTION',
            LTD_DASHBOARD_SET_SELECTIONS: 'LTD_DASHBOARD_SET_SELECTIONS',

            LTD_LOG_ACTIVITIES_FETCHED: 'LTD_LOG_ACTIVITIES_FETCHED',
            LTD_LOG_ACTIVITY_DOC_PATH_UPDATED: 'LTD_LOG_ACTIVITY_DOC_PATH_UPDATED',
            LTD_ACTIVITY_LOGS_SET_FILTER: 'LTD_ACTIVITY_LOGS_SET_FILTER',

            LTD_LOG_STATUS_UPDATED: 'LTD_LOG_STATUS_UPDATED',

            LTD_DASHBOARD_DOCUMENTS_FETCHED: 'LTD_DASHBOARD_DOCUMENTS_FETCHED',

            LTD_GROUPS_PARTNERS_FETCHED: 'LTD_GROUPS_PARTNERS_FETCHED',
            LTD_NEW_GROUP_CREATED: 'LTD_NEW_GROUP_CREATED',
            LTD_GROUP_EDIT_SUCCESS: 'LTD_GROUP_EDIT_SUCCESS',
            LTD_GROUP_DELETED: 'LTD_GROUP_DELETED',
            LTD_GROUP_DETAIL_FETCHED: 'LTD_GROUP_DETAIL_FETCHED',
            LTD_USER_ADDED_TO_GROUP: 'LTD_USER_ADDED_TO_GROUP',

            LTD_DASHBOARD_USERS_FETCHED: 'LTD_DASHBOARD_USERS_FETCHED',
            LTD_CREATE_USER: 'LTD_CREATE_USER',
            LTD_UPDATE_USER: 'LTD_UPDATE_USER',
            LTD_DASHBOARD_USER_ROLE_UPDATED: 'LTD_DASHBOARD_USER_ROLE_UPDATED',
            LTD_DASHBOARD_USERS_SEARCH_RESULT_FETCHED: 'LTD_DASHBOARD_USERS_SEARCH_RESULT_FETCHED',

            LTD_FETCH_IMPORTER_LOGS: 'LTD_FETCH_IMPORTER_LOGS',
            LTD_IMPORTER_LOGS_SET_FILTER: 'LTD_IMPORTER_LOGS_SET_FILTER',
            LTD_DASHBOARD_DOCUMENT_SEARCHING: 'LTD_DASHBOARD_DOCUMENT_SEARCHING',
            LTD_DASHBOARD_SET_DOCUMENTS_FILTER: 'LTD_DASHBOARD_SET_DOCUMENTS_FILTER',

            LTD_BATCH_EDIT_LOGS_SET_FILTER: 'LTD_BATCH_EDIT_LOGS_SET_FILTER',
            BATCH_EDIT_LOG_FETCHED: 'BATCH_EDIT_LOG_FETCHED',

            LTD_PERMITTED_NODES_FETCHED: 'LTD_PERMITTED_NODES_FETCHED',
            LTD_SET_DASHBOARD_LOADING: 'LTD_SET_DASHBOARD_LOADING',

            LTD_DASHBOARD_FETCH_SUGGESTIONS: 'LTD_DASHBOARD_FETCH_SUGGESTIONS',
        });
    }

    setLoading = (data: boolean) => dispatch =>
        dispatch({
            type: this.actions.LTD_SET_DASHBOARD_LOADING,
            data,
        });

    // ---------- Suggestions
    fetchSuggestions = (pageNumber: number = 0, appendResult = true) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await SuggestionsService.getSuggestions({
                filter: {
                    from: startFrom,
                    limit: itemLimit,
                    skip: startFrom,
                    order: 'created DESC',
                    where: {
                        type: 'docAddition',
                    },
                },
            });
            dispatch({
                type: this.actions.LTD_DASHBOARD_FETCH_SUGGESTIONS,
                data: {
                    suggestions: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    // ------------- importer logs
    fetchImporterLogs = (
        pageNumber: number = 0,
        appendResult = true,
        whereFilter?: Record<string, any>
    ) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await utilities.request({
                url: '/importerlogs',
                params: {
                    filter: {
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        where: whereFilter,
                    },
                },
            });
            dispatch({
                type: this.actions.LTD_FETCH_IMPORTER_LOGS,
                data: {
                    logs: res.data,
                    pageNumber,
                },
            });
            dispatch(this.setLoading(false));
        } catch (error) {
            dispatch(this.setLoading(false));
            throw error;
        }
    };

    // CSV DOWNLOAD UTIL
    getCSV = async (url: string, params: Record<string, any>, filename: string = 'log') => {
        try {
            const res = await utilities.request({
                url,
                responseType: 'blob',
                params,
            });
            const href = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${filename}-${moment().toISOString()}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (error) {}
    };

    // ---- DOWNLOAD IMPORTER LOG CSV
    downloadImporterLogsCSV = (
        whereFilter?: Record<string, any>,
        filename: string = 'importer-log'
    ) => async dispatch => {
        this.getCSV(
            'importerlogs/csv',
            {
                where: {
                    ...whereFilter,
                },
            },
            filename
        );
    };

    // ---- DOWNLOAD  ACTIVITY LOGS CSV
    downloadActivityLogsCSV = (whereFilter?: Record<string, any>, filename: string = 'log') => async dispatch => {
        this.getCSV(
            'activitylogs/csv',
            {
                where: {
                    ...whereFilter,
                },
            },
            filename
        );
    };

    toggleCheck = (id: string) => (dispatch, getState) => {
        const { LtdDashboard } = getState() as TState;
        const { selectedIds } = LtdDashboard;

        // if already selected, deselect it by removing from selectedIds list
        let newSelected: string[] = [...selectedIds];
        if (_.indexOf(selectedIds, id) > -1) newSelected = newSelected.filter(_id => _id !== id);
        else newSelected.push(id);
        dispatch({
            type: this.actions.LTD_DASHBOARD_SELECT_ID,
            data: newSelected,
        });
    };

    resetSelection = () => dispatch =>
        dispatch({
            type: this.actions.LTD_DASHBOARD_RESET_SELECTION,
        });

    setSelection = (ids: string[]) => dispatch =>
        dispatch({
            type: this.actions.LTD_DASHBOARD_SET_SELECTIONS,
            data: ids,
        });

    // BATCH EDIT LOG
    fetchBatchEditLogs = (pageNumber = 0, appendResult = true, whereFilter?: Record<string, any>) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await utilities.request({
                url: 'ActivityLogs/get-logs',
                params: {
                    filter: {
                        for: 'ltd',
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        include: [{ relation: 'user' }, { relation: 'subject', scope: ['text'] }],
                        where: {
                            batchId: { exists: true },
                            // subjectType: {
                            //     inq: ['LtdDoc', 'Node']
                            // },
                            ...whereFilter,
                        },
                    },
                },
            });

            dispatch({
                type: this.actions.BATCH_EDIT_LOG_FETCHED,
                data: {
                    logs: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    // ACTIVITY LOGS
    fetchLogs = (pageNumber = 0, appendResult = true, whereFilter?: Record<string, any>) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await utilities.request({
                url: 'ActivityLogs/get-logs',
                params: {
                    filter: {
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        include: [{ relation: 'user' }, { relation: 'subject', scope: ['text'] }],
                        where: {
                            batchId: { exists: false },
                            subjectType: {
                                inq: ['LtdDoc', 'Node'],
                            },
                            ...whereFilter,
                        },
                    },
                },
            });

            dispatch({
                type: this.actions.LTD_LOG_ACTIVITIES_FETCHED,
                data: {
                    logs: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    updateLogStatus = (logs: TLog[], status) => async dispatch => {
        let _logsToUpdate = logs.filter(log => {
            if (log.status === status) return false;
            if (log.status !== 'draft' && status === 'draft') return false;
            return true;
        });

        const logIds = _logsToUpdate.map(l => l.id);
        if (logIds.length === 0) return;

        // if (log.status === status)
        //     return;
        // if (log.status !== 'draft' && status === 'draft')
        //     return;
        const LOG_ACTION = {
            published: `accept`,
            // 'published': 'publish',
            review: 'review',
            rejected: 'reject',
        };
        const action = LOG_ACTION[status];
        if (!action) return;
        const resp = await utilities.request({
            url: `activitylogs/${action}`,
            method: 'POST',
            params: {
                where: {
                    id: {
                        inq: logIds,
                    },
                },
            },
        });
        let logUpdate = resp.data;
        // if (_.isArray(logUpdate))
        //     logUpdate = logUpdate[0];
        // if (_.isEmpty(logUpdate))
        //     return;
        // let updateObj = { res: undefined };
        // if (logUpdate.res)
        //     updateObj.res = logUpdate.res;
        dispatch({
            type: this.actions.LTD_LOG_STATUS_UPDATED,
            data: {
                logs,
                status,
                // updateObj,
                logUpdates: logUpdate,
            },
        });
        dispatch(OApp.showToast('Status Updated successfully', 'success'));
    };

    updateLogDocPath = (logId, parentIds, updateDocKey) => async dispatch => {
        const pathResp = await ONewLTDDocument.requestDocPath(parentIds);
        if (_.isEmpty(pathResp)) return;
        dispatch({
            type: this.actions.LTD_LOG_ACTIVITY_DOC_PATH_UPDATED,
            data: {
                logId,
                docPath: pathResp,
                updateDocKey,
            },
        });
        return pathResp;
    };

    /// PARTNER - GROUPS -------------------------------------------------
    fetchGroupDetail = groupId => async dispatch => {
        try {
            const res = await utilities.request({
                url: `groups/${groupId}`,
                params: {
                    filter: {
                        include: [
                            {
                                relation: 'assignedNodes',
                                scope: ['text'],
                            },
                            {
                                relation: 'users',
                            },
                        ],
                    },
                },
            });
            dispatch({
                type: this.actions.LTD_GROUP_DETAIL_FETCHED,
                data: res.data,
            });
        } catch (error) {}
    };

    fetchGroups = (pageNumber = 0) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'groups',
                params: {
                    filter: {
                        order: 'created DESC',
                    },
                },
            });
            dispatch({
                type: this.actions.LTD_GROUPS_PARTNERS_FETCHED,
                data: {
                    groups: res.data,
                    pageNumber,
                },
            });
        } catch (error) {}
    };

    createGroup = (data: Record<string, any>) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'groups',
                method: 'POST',
                data,
            });
            dispatch({
                type: this.actions.LTD_NEW_GROUP_CREATED,
                data: res.data,
            });
            dispatch(OApp.showToast(`New group ${_.get(data, 'name') || ''} created`, 'success'));
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
        }
    };

    addUsersToGroup = (userIds: string[], groupId: string) => async dispatch => {
        try {
            const formData = { userIds };
            await dispatch(this.editGroup(formData, groupId));
            dispatch({
                type: this.actions.LTD_USER_ADDED_TO_GROUP,
                data: {
                    groupId,
                    userIds,
                },
            });
        } catch (error) {}
    };

    searchUsers = (params: Record<string, any>, cancelToken?: any) => async dispatch => {
        try {
            const res = await Axios.request({
                url: `users/search`,
                params,
                cancelToken: cancelToken,
            });

            const users = _.map(res.data.results, res => res.hit);
            dispatch({
                type: this.actions.LTD_DASHBOARD_USERS_FETCHED,
                data: {
                    users,
                    pageNumber: 0,
                    appendResult: false,
                },
            });
            return users;
        } catch (error) {
            throw error;
        }
    };

    editGroup = (data: Record<string, any>, groupId: string) => async dispatch => {
        try {
            const res = await utilities.request({
                url: `groups/${groupId}`,
                method: 'PATCH',
                data,
            });

            dispatch({
                type: this.actions.LTD_GROUP_EDIT_SUCCESS,
                data: res.data,
            });

            dispatch(OApp.showToast(`Changes saved successfully`, 'success'));
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
        }
    };

    deleteGroup = (groupId: string) => async dispatch => {
        try {
            const res = await utilities.request({
                url: `groups/${groupId}`,
                method: 'DELETE',
            });
            dispatch({
                type: this.actions.LTD_GROUP_DELETED,
                data: groupId,
            });
            dispatch(OApp.showToast('Group deleted', 'success'));
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
        }
    };

    /// ----- USERS ---------------------
    createUser = (formData: Record<string, any>) => async dispatch => {
        try {
            const res = await utilities.request({
                url: 'users',
                method: 'POST',
                data: formData,
            });
            dispatch(OApp.showToast('User created successfully', 'success'));
            dispatch({
                type: this.actions.LTD_CREATE_USER,
                data: res.data,
            });
            return res.data;
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
            throw error;
        }
    };
    editUser = (formData: Record<string, any>, userId: string) => async dispatch => {
        try {
            const res = await utilities.request({
                url: `users/${userId}`,
                method: 'PATCH',
                data: formData,
            });
            dispatch(OApp.showToast('User updated successfully', 'success'));
            dispatch({
                type: this.actions.LTD_UPDATE_USER,
                data: res.data,
            });
            return res.data;
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
            throw error;
        }
    };

    fetchUsers = (pageNumber = 0, appendResult: boolean = true, variant: 'ltd' | 'scc' = 'ltd') => async dispatch => {
        try {
            const itemLimit = 20;
            const startFrom = pageNumber * itemLimit;
            const res = await utilities.request({
                url: variant === 'scc' ? 'groups/org/SCC/users' : 'users',
                params: {
                    filter: {
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        include: [{ relation: 'roles', scope: ['name', 'id'] }],
                    },
                },
            });
            dispatch({
                type: this.actions.LTD_DASHBOARD_USERS_FETCHED,
                data: {
                    users: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
    };

    changeUserRole = (userIds: string[], role: TUserRole, oldRole?: TUserRole) => async dispatch => {
        try {
            // remove role first
            if (oldRole && oldRole !== 'USER') {
                const res1 = await utilities.request({
                    url: 'users/role/remove',
                    method: 'POST',
                    data: {
                        ids: userIds,
                        role: oldRole,
                    },
                });
            }

            // assign new role
            if (!_.isEmpty(role)) {
                const res2 = await utilities.request({
                    url: 'users/role/add',
                    method: 'POST',
                    data: {
                        ids: userIds,
                        role,
                    },
                });
            }

            dispatch({
                type: this.actions.LTD_DASHBOARD_USER_ROLE_UPDATED,
                data: {
                    userIds,
                    role,
                },
            });
            dispatch(OApp.showToast('Role changed successfully', 'success'));
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
        }
    };

    getPermittedNodes = userId => async (dispatch, getState) => {
        const { Auth } = getState();
        const res = await utilities.request({
            url: '/Permissions/permitted-entities',
            params: {
                scopeModel: 'assignedNodes',
                userId: userId || _.get(Auth, 'user.id'),
            },
        });

        dispatch({
            type: this.actions.LTD_PERMITTED_NODES_FETCHED,
            data: res.data,
        });
    };

    // FOLDER VIEW - TREE SEARCH
    setFilter = (filter: Record<string, any>) => dispatch => {
        dispatch({
            type: this.actions.LTD_DASHBOARD_SET_DOCUMENTS_FILTER,
            data: filter,
        });
    };

    searchDocuments = (pageNumber = 0, filter?: Record<string, any>) => async (dispatch, getState) => {
        const state = getState() as TState;
        dispatch({ type: this.actions.LTD_DASHBOARD_DOCUMENT_SEARCHING });
        const res = await utilities.request({
            url: `ltddocs/search`,
            params: {
                ...STATIC_FILTER,
                filter: {
                    from: pageNumber * LTD_DASHBOARD_SEARCH_LIMIT,
                    limit: LTD_DASHBOARD_SEARCH_LIMIT,
                    ...filter,
                    sort: SORTS['Latest'],
                    include: LTD_INCLUDES,
                },
            },
        });
        const total = _.get(res.data, 'total');
        const documents = _.get(res.data, 'results');
        dispatch({
            type: this.actions.LTD_DASHBOARD_DOCUMENTS_FETCHED,
            data: {
                documents,
                pageNumber,
                total,
                appConstants: state.Documents.appConstants,
            },
        });
    };
}
export const LTD_DASHBOARD_SEARCH_LIMIT = 100;
export let OLtdDashboard = new LtdDashboard();
