import React, { useState, useEffect, useRef } from 'react'
import {
    createStyles, Theme, withStyles, WithStyles,
    FormControl, InputLabel, Select,
    MenuItem, Checkbox, ListItemText, TextField, Input, Icon
} from '@material-ui/core';
import _ from 'lodash';


interface SearchSelectProps extends WithStyles<typeof STYLES> {
    color?: 'primary' | 'secondary'
    id: string
    label: string
    disabled?: boolean
    name: string
    value: any
    disableUnderline?: boolean
    multiple?: boolean
    options?: any
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}


let key = '';
let timeoutHandler: NodeJS.Timeout

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
    const { color = 'primary', disableUnderline = false, id, label, disabled = false, name, value, multiple = false, options = [], classes } = props;
    const [filteredOptions, setFilteredOptions] = useState<typeof options>(options)

    const inputRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef])

    useEffect(() => {
        setFilteredOptions(options);
    }, [options])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // key = key + event.key;
        // clearTimeout(timeoutHandler);
        // timeoutHandler = setTimeout(() => {
        //     key = ''
        // }, 1500);
        // handleFocus(key);
    }



    const handleSelectInputChange = (e: any) => {
        if (_.isEmpty(_.filter(e.target.value, v => !_.isUndefined(v))))
            return;
        if (_.isArray(e.target.value)) {
            e.target.value = _.filter(e.target.value, v => !!v);
        }
        props.onChange(e)
    }

    const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        // handleFocus(e.target.value)
        const _filteredOptions = _.filter(options, o => _.lowerCase(o.name).includes(_.lowerCase(value)));
        setFilteredOptions(_filteredOptions);
    }


    const renderValue = (selectedValues: any | undefined, options: any) => {
        if (!selectedValues)
            return "";

        if (!_.isArray(selectedValues))
            return selectedValues;

        const values: any[] = [];
        selectedValues.forEach(selected => {
            const val = _.get(_.find(options, { value: selected }), 'name')
            if (val)
                values.push(val);
        })
        return values.join(', ')
    }


    return (
        <FormControl onKeyPress={handleKeyPress}>
            {!!label ? (
                <InputLabel color={color} htmlFor={id}>{label}</InputLabel>
            ) : null}
            <Select
                disabled={disabled}
                color={color}
                multiple={multiple}
                renderValue={selected => renderValue(selected, options)}
                onChange={handleSelectInputChange}
                disableUnderline={disableUnderline}
                inputProps={{
                    id,
                    name
                }}
                value={value}
            >
                <TextField inputRef={inputRef} InputProps={{
                    startAdornment: <Icon>search</Icon>
                }} className={classes.stickyTextField} fullWidth onChange={handleTextInput} />
                {
                    filteredOptions.map((o, i) => (
                        <MenuItem id={`menuItem-${_.lowerCase(o.name)}`} key={i} value={o.value} >
                            <Checkbox color={color} checked={multiple ? _.indexOf((value || []), o.value) > -1 : value === o.value} />
                            <ListItemText primary={o.name} />
                        </MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}

const STYLES = (theme: Theme) => createStyles({
    stickyTextField: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        padding: '4px 24px 0px 24px',
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        outline: 'none'
    }
})

export default withStyles(STYLES)(SearchSelect)