const projectBackgroundZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
    <div id="c258" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">项目背景</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p  class="bodytext">
国际刑事法院法律工具系统最初由其检察官办公室的法律咨询处（LAS）于2003-2005年间开发。该项目自2006年始由国际法研究与政策中心
          (<a href="https://www.cilrap.org/purpose/" target="_blank">CILRAP</a>),&nbsp;主任莫藤•伯格斯默
          <a href="https://www.cilrap.org/bergsmo/"> Morten Bergsmo</a> 
          领导（伯格斯默作为LAS的负责人在2003-05年间构想并指导了该项目及其各分支部分的发展，后作为项目协调人继续负责该项目）
             </p>
        <p class="bodytext">
      2005年成立的法律工具项目顾问团由法律信息领域的领袖专家组成，为项目的技术和策略性发展提供建议。成员包括：
           <br/> 
           <a href="https://www.cilrap.org/bergsmo/" target="_blank">莫藤•伯格斯默</a> 
          （Morten Bergsmo）教授（挪威）
           <a href="http://herberger.jura.uni-sb.de/herberger/" target="_blank">马克西米利安•荷尔伯格</a> （Maximilian Herberger）教授，萨尔伦大学（德国）；安雅•奥斯坎普（Anja Oskamp）教授，阿姆斯特丹自由大学（荷兰）         
    <a href="https://www.gu.se/en/about/find-staff/ulfpetrusson" target="_blank">乌尔夫•佩特鲁松</a>
            （Ulf Petrusson）教授，哥德堡大学（瑞典）
               <a href="https://people.cs.kuleuven.be/~sien.moens/" target="_blank">玛丽-弗兰西恩（西耶恩）•莫恩斯</a> （Marie-Francine (Sien) Moens）教授，鲁汶天主教大学（比利时）；理查德•V•德•穆尔德（Richard V. De Mulder）教授，鹿特丹伊拉斯姆斯大学（荷兰）；
                 <a href="https://www.eui.eu/people?id=giovanni-sartor" target="_blank">乔万尼•萨尔托</a> （Giovanni Sartor）教授，欧洲大学研究院（意大利）。已故
                   <a href="https://en.wikipedia.org/wiki/Jon_Bing" target="_blank">约翰•宾</a>
                  （Jon Bing）教授是该委员会的第一任主席。委员会的第一次会议于2005年12月7日在国际刑事法院的海牙临时处所举行。
                     </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
    </div>`;
export default projectBackgroundZh;
