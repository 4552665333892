import { OAdvancedSearch } from "./redux-config";
import { Reducer } from "redux";
import { TAdvancedSearchState } from "./@types";
import { object } from "prop-types";


const LTDQueries = [
    {
        queryType: 'allContent',
        operation: 'AND',
        _in: ''
    },
]


const CLDQueries = [
    {
        queryType: 'legalFinding',
        operation: 'AND',
    },
]



const initalState: TAdvancedSearchState = {
    open: false,
    type: 'LTD',
    buildTermFromQueries: false,
    operator: 'AND',
    queries: [...LTDQueries]
}

export const AdvancedSearch: Reducer<TAdvancedSearchState> = (state = initalState, action): TAdvancedSearchState => {
    const {
        OPEN_ADVANCED_SEARCH_DIALOG,
        CLOSE_ADVANCED_SEARCH_DIALOG,
        SET_ADVANCED_SEARCH_QUERIES,
        SET_CLD_ADVANCED_SEARCH_OPERATOR,
        SET_BUILD_TERM_FROM_QUERY
    } = OAdvancedSearch.actions;

    switch (action.type) {
        case SET_BUILD_TERM_FROM_QUERY: {
            return { ...state, buildTermFromQueries: action.data }
        }
        case SET_CLD_ADVANCED_SEARCH_OPERATOR: {
            return { ...state, operator: action.data }
        }
        case SET_ADVANCED_SEARCH_QUERIES: {
            return { ...state, queries: action.data }
        }

        case OPEN_ADVANCED_SEARCH_DIALOG: {
            // return { ...state, open: true, type: action.data, queries: action.data === 'LTD' ? [...LTDQueries] : [...CLDQueries] };
            return { ...state, open: true, type: action.data, queries: LTDQueries };
        }
        case CLOSE_ADVANCED_SEARCH_DIALOG: {
            return { ...state, open: false };
        }
        default: return state;
    }
}