import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    LinearProgress,
    Theme as MuiTheme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TDocument } from '../../Features/LTDDocuments/@types';
import Theme from '../../Resources/Theme';
import Text from '../../Components/Text';
// import EditIcon from '@material-ui/icons/Edit';
import { TUser, ILtdPermissions } from '../../Features/Authentication/@types';
import MetadataForm from './MetadataForm';
import { ONewLTDDocument } from '../../Features/AddNewLTDDocument/redux-config';
import { setLTDMetadata, DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import { getCLDUserRole, getUserRole } from '../../Features/Authentication/redux-config';
import LTDBreadCrumbs from '../../Components/Breadcrumbs/LTDBreadcrumbs';
import LTDMergeRecord from './LTDMergeRecord';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { withTranslations, WithTranslationProps } from '../../Features/Translations/withTranslations';
import { getMetaData } from '../../Features/Translations/ltdMetadataTranslation';
import { METADATA_PANNEL_TEXT, METADATA_RELATED_RESOURCE } from '../../Features/Translations/translationKeys.ltd';
import { getMetaDataValues } from '../../Features/Translations/ltdMetadataValuesTranslation';

interface Props extends WithStyles<typeof STYLES>, WithTranslationProps {
    data: TDocument;
    isDisplayNav?: boolean;
    onEditDoc?: (doc: TDocument) => void;
    variant?: 'scc' | 'ltd';
}
interface IStateProps {
    ltdPermissions: ILtdPermissions;
}

interface IDispatchProps {
    editDocument: (docId: string, doc: TDocument) => void;
    publishDoc: (docId, shouldPublish) => Promise<any>;
    mergeRecord: (id, url) => Promise<void>;
    unMergeRecord: (id, url) => Promise<void>;
}

class LTDMetadata extends Component<Props & IStateProps & IDispatchProps & WithTranslationProps> {
    state = {
        editing: false,
        publishing: false,
    };
    handleMergeRecords = async (purl: string) => {
        const { data } = this.props;
        if (data) await this.props.mergeRecord(data.id, purl);
    };
    handleUnMergeRecords = async (purl: string) => {
        const { data } = this.props;
        if (data) await this.props.unMergeRecord(data.id, purl);
    };

    handleEdit = event => {
        // this.setState({ editing: true })
        const { data, onEditDoc } = this.props;
        if (onEditDoc) onEditDoc(data);
        else this.props.editDocument(data.id, data);
    };

    handlePublish = async () => {
        const { data } = this.props;
        const isPublished = data.isPublished || false;
        try {
            this.setState({ publishing: true });
            await this.props.publishDoc(data.id, !isPublished);
            this.setState({ publishing: false });
        } catch (error) {
            this.setState({ publishing: false });
        }
    };

    getMetadataLabel = (label: string) => {
        const { getTranslation } = this.props;
        return getMetaData(label, getTranslation);
    };

    getMetadataValues = (value: string) => {
        return this.getMetadataLabel(value);
    };

    render() {
        const { classes, data, ltdPermissions, isDisplayNav = true, getTranslation, variant = 'ltd' } = this.props;

        const metadata = _.get(data, 'metadata');
        if (_.isEmpty(metadata)) return <div />;
        return (
            <div className={classes.root}>
                <div className={classes.breadcrumbsContainer}>
                    {!_.isEmpty(_.get(data, 'docPath')) && <LTDBreadCrumbs docPath={_.get(data, 'docPath') || []} />}
                </div>
                <div className={classes.contentContainer}>
                    <Typography variant='caption' className={classes.title}>
                        {getTranslation(METADATA_PANNEL_TEXT) || 'metadata'}
                    </Typography>

                    {isDisplayNav && data.isAssigned && (
                        <div className={classes.actionContainer}>
                            {ltdPermissions.docPublish ? (
                                <Button
                                    className='mx-3'
                                    onClick={this.handlePublish}
                                    variant='outlined'
                                    color='primary'
                                >
                                    {this.state.publishing ? '...' : data.isPublished ? 'Unpublish' : 'publish'}
                                </Button>
                            ) : null}
                            {ltdPermissions.docEdit ? (
                                <Button
                                    color='primary'
                                    className={classes.editButton}
                                    onClick={this.handleEdit}
                                    variant='outlined'
                                >
                                    <i className='material-icons'>edit</i>
                                </Button>
                            ) : null}
                        </div>
                    )}

                    {_.map(metadata, (_metadata, i) => (
                        <div key={i} className={classes.listItemContainer}>
                            <Typography align='right' variant='caption' className={classes.label}>
                                {this.getMetadataLabel(_metadata.name)}:
                            </Typography>
                            <Text
                                variant='caption'
                                linkColor={variant === 'scc' ? Theme.Colors.Tertiary.main : Theme.Colors.Secondary.main}
                                className={classes.detail}
                            >
                                {this.getMetadataValues(_metadata.val)}
                            </Text>
                        </div>
                    ))}
                    <RelatedDocs data={data} classes={classes} getTranslation={getTranslation} />
                    <MetadataForm
                        data={data}
                        open={this.state.editing}
                        onClose={() => {
                            this.setState({ editing: false });
                        }}
                    />
                </div>
                {(data.proxySlugs || []).length > 0 && ltdPermissions.docMergeRecord && (
                    <div className={classes.contentContainer}>
                        <Typography variant='caption' className={classes.title}>
                            RECORDS MERGED WITH THIS ONE
                        </Typography>
                        <LTDMergeRecord
                            onClose={() => {}}
                            onUnmerge={this.handleUnMergeRecords}
                            onMerge={this.handleMergeRecords}
                            title=''
                            proxySlugs={data.proxySlugs}
                            disableAdd={true}
                            // document={data}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function RelatedDocs(props: { data: TDocument; classes: any; getTranslation }) {
    const { data, classes, getTranslation } = props;
    const { resolvedRelatedDocs = [] } = data;

    if (resolvedRelatedDocs.length === 0) return <div />;

    return (
        <div className={classes.listItemContainer}>
            <Typography align='right' variant='caption' className={classes.label}>
                {getTranslation(METADATA_RELATED_RESOURCE) || ' Related documents:'}
            </Typography>
            <div className={classes.detail}>
                {resolvedRelatedDocs.map((doc, i) => (
                    <div key={doc.id} className={classes.relatedDocs}>
                        <Text variant='caption' linkColor={Theme.Colors.Secondary.main}>
                            {doc.title}
                        </Text>
                        <Text variant='caption' linkColor={Theme.Colors.Secondary.main}>
                            {DOCUMENT_BASE_URL + (doc.slug || doc.id)}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    ..._.pick(state.Auth, ['ltdPermissions']),
});

const mapDispatchToProps = dispatch => ({
    editDocument: (docId: string, doc: TDocument) => dispatch(ONewLTDDocument.editLTDDocument(docId, doc)),
    publishDoc: async (docId, shouldPublish) => dispatch(ONewLTDDocument.publishLTDDoc(docId, shouldPublish)),
    mergeRecord: (id, url) => dispatch(ODetail.mergeLTDRecords(id, url)),
    unMergeRecord: (id, url) => dispatch(ODetail.unMergeLTDRecords(id, url)),
});

const STYLES = (theme: MuiTheme) =>
    createStyles({
        root: {
            boxSizing: 'border-box',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            [theme.breakpoints.up('sm')]: {
                width: 680,
                margin: '0 auto',
            },
        },
        contentContainer: {
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                padding: '50px 24px',
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 4,
                margin: '20px auto',
                padding: '20px 40px',
                background: 'white',
            },
        },
        listItemContainer: {
            display: 'flex',
            marginBottom: 8,
            alignItems: 'flex-start',
        },
        title: {
            color: 'white',
            background: Theme.Colors.Fourth,
            position: 'relative',
            display: 'inline-block',
            top: -30,
            padding: 1,
            lineHeight: 1,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        actionContainer: {
            position: 'absolute',
            top: -20,
            right: 20,
            '& button': {
                background: 'white',
            },
            [theme.breakpoints.down('sm')]: {
                position: 'inherit',
            },
        },
        editButton: {
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            height: 30,
            width: 30,
            borderRadius: 15,
        },
        label: {
            flex: 2,
            marginRight: 20,
            color: theme.palette.primary.main,
        },
        detail: {
            flex: 4,
        },
        breadcrumbsContainer: {
            padding: 20,
            boxSizing: 'border-box',
            minHeight: 70,
        },
        relatedDocs: {
            marginBottom: 8,
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(LTDMetadata)));
