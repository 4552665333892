import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, LinearProgress, Typography, Fab } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import _ from 'lodash';
import THEME from '../../../Resources/Theme';
import { TNode } from '../../../Features/DocumentsTree/@types';
import { OApp } from '../../../RootReducer/AppReducer';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import Tree from '../../../Components/Tree';
import { fn } from 'moment';

interface IStateProps {
    /* document?: TDocument
    title: string */
    appConstants: any
}

interface IDispatchProps {
    closeSidebar?: () => void
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
    nodeIds: Array<string>,
    node: TNode,
    title: string
}
interface IState {
    treeNodes?: Array<TNode>,
    currentNodeId?: string
}

class NodeTree extends Component<IProps, IState> {
    state = {
        treeNodes: [],

    }
    setLastNode = (treeNodes, parentIds) => {

        const { node } = this.props;
        if (!node.nodeType)
            node.nodeType = 'MENU';
        let parentNode;
        _.each(parentIds, id => {
            const fNode = _.find(treeNodes, { id: id }) as TNode;
            if (!fNode)
                return true;
            parentNode = fNode;
            treeNodes = fNode.children || [];
        });
        if (parentNode) {
            if (!parentNode.children)
                parentNode.children = [];
            parentNode.children.push({ ...node, menuChildren: 0 });
        }
    }
    initTreeNodes = async (nodeIds) => {
        if (_.isEmpty(nodeIds))
            return;
        const requiredParentIds = nodeIds.slice(1);
        if (_.isEmpty(requiredParentIds)) {
            console.warn('Could not determine required ids', requiredParentIds, nodeIds);
            return;
        }
        this.setState({ treeNodes: [] });
        const resp = await ODocumentTree.createTreeByIds([...requiredParentIds]);
        this.setLastNode([resp], requiredParentIds);

        this.setState({ treeNodes: [resp] });
    }
    componentDidMount = () => {
        this.initTreeNodes(this.props.nodeIds);
    }
    componentDidUpdate(prevProps, prevState) {
        if (_.isEmpty(this.props.node))
            return;
        if (this.props.node.id === prevProps.node.id)
            return;
        this.initTreeNodes(this.props.nodeIds);
    }
    closeSidebar = () => {
        if (this.props.closeSidebar)
            this.props.closeSidebar();
    }

    render() {
        const { classes, title } = this.props;
        const { treeNodes } = this.state;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div>
                        <Typography>{_.truncate(title, { length: 60 })}</Typography>
                    </div>
                    <Fab size="small" onClick={this.closeSidebar}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
                <div className={classes.treeContainer}>
                    {
                        (_.isEmpty(treeNodes)) &&
                        <LinearProgress color="secondary" />
                    }

                    <Tree
                        tree={treeNodes}
                        onOpen={() => { }}
                        onClose={() => { }}
                        onClick={() => { }}
                    >

                    </Tree>
                </div>
            </div>
        )
    }


}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.App, 'appConstants')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    closeSidebar: () => dispatch(OApp.closeSidebar())
})

const CONTAINER_WIDTH = 520;
const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box',
        background: THEME.Colors.Grayscale.Grey5
    },
    header: {
        position: 'fixed',
        width: CONTAINER_WIDTH,
        right: 0,
        display: 'flex',
        height: 51,
        zIndex: 1,
        paddingLeft: 20,
        boxSizing: 'border-box',
        alignItems: 'center',
        '& p': {
            color: 'white'
        },
        background: THEME.Colors.Grayscale.Grey1,
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    treeContainer: {
        marginTop: 100,
        padding: '0 20px'
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(NodeTree))