import React, { useEffect, useState } from 'react'
import { TLtdDocFormFieldKey, FORM_FIELD_INPUT_TYPE_MAPPING } from './constants'
import { fetchDocument } from '../../Features/DetailPage/redux-config';
import { TDocument } from '../../Features/LTDDocuments/@types';
import _ from 'lodash';
import { CellProps } from './Cell';
import ChipSelect from '../ChipSelect';
import { BOOLEAN_OPTIONS, PHASE_OF_PREPARATORY, SUBJECT, OUTCOME_OF_TRIAL, ORIGIN, RESPONSIBLE_PARTNER, DOCUMENT_TYPE, TYPE_OF_JUDICIAL_DOCUMENT, IMPORTANCE } from '../../Features/AddNewLTDDocument/@types';
import moment from 'moment';
import { stringify } from 'querystring';
import SearchAdd from '../SearchAdd';
import DragReorderList from '../DragReorderList';
import FileUpload from '../FileUpload';
import PurlResource from '../PurlResource';
import { includeValueOptions } from '../../Features/LTDDocuments/redux-config';
import { orderByFeaturedList, LTD_FEATURED_LANGUAGES } from '../../Features/AddNewLTDDocument/addNewLegalFinding-reducer';
import { LtdDocService } from '../../Services/LtdDocs.service';
import { Typography } from '@material-ui/core';


export type TBatchEditForm = Record<string, Partial<TDocument>>

export interface UseBatchEditConfig {
    errorIds: string[]
    formData: TBatchEditForm,
    docIds: string[],
    isNewDocument?: boolean
    formFields: TLtdDocFormFieldKey[],
    onFormChange: (form: TBatchEditForm) => void,
    onDocModified?: (docId: string) => void,
    appConstants?: Record<string, any>,
    includeIdOptions?: Record<string, any>
}

export const useBatchEdit = (config: UseBatchEditConfig) => {
    const { docIds, formData, formFields, onFormChange, appConstants = {}, onDocModified, isNewDocument = false, includeIdOptions = {} } = config;
    // const [formData, setFormData] = useState<TBatchEditForm>({})
    const [loading, setLoading] = useState(false);
    const [suggestionList, setSuggestionList] = useState<Record<string, Record<string, any[]>>>({})
    // const [includeIdOptions, setIncludeIdOptions] = useState<Record<string, any>>({})

    useEffect(() => {
        if (!isNewDocument && Object.keys(formData).length === 0)
            fetchDocs();
        // fetchIncludeOptions();
    }, [])

    // const fetchIncludeOptions = async () => {
    //     try {
    //         const options = await includeValueOptions();
    //         setIncludeIdOptions(options);
    //     } catch (error) {

    //     }
    // }

    const fetchDocs = async () => {
        setLoading(true);
        try {
            const res = await LtdDocService.fetchDocuments(docIds, { fields: [...formFields, 'id'] });
            const docs: TDocument[] = res.data;
            setupForm(docs);
        } catch (error) {

        }
        setLoading(false)
    }



    const setupForm = (docs: TDocument[]) => {
        let _formData: typeof formData = {};
        docs.forEach(doc => {
            _formData[doc.id] = _.pick(doc, formFields);
        })
        onFormChange(_formData);
    }

    const handleChange = (docId: string) => (e: any) => {
        const { name, value } = e.target;
        let _doc = formData[docId];
        _doc = _.set(_doc, name, value);
        if (name === 'dateCreated') {
            _doc = _.set(_doc, 'dateCreated_str', moment(value, 'YYYY-MM-DD').format(DATE_CREATED_STR_FORMAT))
        }
        handleFormChange(docId, _doc);
    }

    const handleSelect = (docId: string) => (key: string, value: any) => {
        let _doc = formData[docId];
        _doc = _.set(_doc, key, value);
        handleFormChange(docId, _doc);
    }

    const handleSuggestionSelect = (docId: string, formKey: string) => ({ name, value }) => {

        const list = [...(_.get(suggestionList[docId], formKey) || []), value]
        setSuggestionList({ ...suggestionList, [docId]: { ...(suggestionList[docId] || []), [formKey]: list } });
        let _doc = formData[docId];
        _doc = _.set(_doc, name, list.map(item => item.id));
        handleFormChange(docId, _doc);
    }

    const onReorder = (docId: string, formKey: string) => (newList: any) => {
        // setSuggestionList({ ...suggestionList, [formKey]: newList });
        setSuggestionList({ ...suggestionList, [docId]: { ...(suggestionList[docId] || []), [formKey]: newList } });
        let _doc = formData[docId];
        _doc = _.set(_doc, formKey, newList.map(item => item.id));
        handleFormChange(docId, _doc);
    }

    const handleRemoveItem = (docId: string, formKey: string) => (index: number) => {
        const newList = _.filter(_.get(suggestionList[docId], formKey) || [], (item, i) => i !== index);
        setSuggestionList({ ...suggestionList, [docId]: { ...(suggestionList[docId] || []), [formKey]: newList } });
        // setSuggestionList({ ...suggestionList, [formKey]: newList });
        let _doc = formData[docId];
        _doc = _.set(_doc, formKey, newList.map(item => item.id));
        handleFormChange(docId, _doc);
    }

    const handleSuggestion = (docId: string) => ({ name, value }) => {
        let _doc = formData[docId];
        _doc = _.set(_doc, name, value);
        handleFormChange(docId, _doc);
    }

    const handleFormChange = (docId: string, form: Partial<TDocument>) => {
        if (onDocModified) onDocModified(docId)
        onFormChange({ ...formData, [docId]: form });
    }

    const onFileUpload = (docId: string) => async (file) => {
        try {
            const res = await LtdDocService.uploadFile(file);
            let _doc = formData[docId];
            _doc = _.set(_doc, 'documentFilePath', res.data.documentFilePath);
            _doc = _.set(_doc, 'orignalPdfURL', res.data.orignalPdfURL);
            _doc = _.set(_doc, 'documentName', res.data.documentName);
            handleFormChange(docId, _doc);
        } catch (error) {
            throw error
        }
    }



    // CONFIGURATION --------
    const getCellProps = (docId: string): Record<TLtdDocFormFieldKey, Omit<CellProps, 'classes'>> => ({
        isPublished: {
            formData: formData[docId],
            displayValue: '',
            inputConfig: {
                key: 'isPublished',
                label: 'Published?',
                onChange: () => null,
                type: FORM_FIELD_INPUT_TYPE_MAPPING.isPublished,
                component: <Typography>{getStringValue(_.get(formData[docId], 'isPublished') || '', 'boolean', BOOLEAN_OPTIONS)}</Typography>
            }
        },
        abstract: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'abstract') || '',
            inputConfig: {
                key: 'abstract',
                label: 'Abstract',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.abstract,
            }
        },
        approximateDate: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'approximateDate') || '', 'date'),
            inputConfig: {
                key: 'approximateDate',
                label: 'Approximate date',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.approximateDate
            }
        },
        contentType: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'contentType') || ''], 'array', _.get(appConstants, 'contentType')),
            inputConfig: {
                key: 'contentType',
                label: 'Content Type',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.contentType,
                options: _.get(appConstants, 'contentType') || []
            },
        },
        dateCreated: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'dateCreated') || '', 'date'),
            inputConfig: {
                key: 'dateCreated',
                label: 'Date created',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.dateCreated
            }
        },
        entryIntoForce: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'entryIntoForce') || '', 'date'),
            inputConfig: {
                key: 'entryIntoForce',
                label: 'Entry into force',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.entryIntoForce
            }
        },
        expiryDate: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'expiryDate') || '', 'date'),
            inputConfig: {
                key: 'expiryDate',
                label: 'Date of expiry',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.expiryDate
            }
        },
        externalId: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'externalId') || '',
            inputConfig: {
                key: 'externalId',
                label: 'External identifier',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.externalId
            }
        },
        isAuthoritativeLanguage: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'isAuthoritativeLanguage'), 'boolean'),
            inputConfig: {
                key: 'isAuthoritativeLanguage',
                label: 'Authoritative language',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.isAuthoritativeLanguage,
                component: (
                    <ChipSelect formkey="isAuthoritativeLanguage" value={_.get(formData[docId], 'isAuthoritativeLanguage') || false} title='Authoritative language' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                )
            }
        },


        isOriginalLanguage: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'isOriginalLanguage'), 'boolean'),
            inputConfig: {
                key: 'isOriginalLanguage',
                label: 'Original language',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.isOriginalLanguage,
                component: (
                    <ChipSelect formkey="isOriginalLanguage" value={_.get(formData[docId], 'isOriginalLanguage') || false} title='Original language' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                )
            }
        },
        languageIds: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'languageIds'), 'array', _.get(includeIdOptions, 'languages') || []),
            inputConfig: {
                key: 'languageIds',
                label: '',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.languageIds,
                options: orderByFeaturedList<{ name: string, value: string }>(_.get(includeIdOptions, 'languages') || [], LTD_FEATURED_LANGUAGES)
            }
        },
        notes: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'notes') || '',
            inputConfig: {
                key: 'notes',
                label: 'Notes',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.notes
            }
        },
        organisation: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'organisation') || '',
            inputConfig: {
                key: 'organisation',
                label: 'Organisation / State of Source',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.organisation,
                component: (
                    <SearchAdd
                        disableUnderline={true}
                        label=""
                        formKey="organisation"
                        key="organisation"
                        value={_.get(formData[docId], 'organisation') || ''}
                        onChange={handleSuggestion(docId)}
                        suggestionUrl="ltddocs/autocomplete"
                        params={(term) => ({ term, field: 'organisation' })}
                        handleSuggestions={(data) => data.map(val => val['organisation'])}
                    />
                )
            }
        },
        shortTitle: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'shortTitle') || '',
            inputConfig: {
                key: 'shortTitle',
                label: 'Short title',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.shortTitle
            }
        },
        shortTitle_en: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'shortTitle_en') || '',
            inputConfig: {
                key: 'shortTitle_en',
                label: 'Short title (EN)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.shortTitle_en
            }
        },
        shortTitle_fr: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'shortTitle_fr') || '',
            inputConfig: {
                key: 'shortTitle_fr',
                label: 'Short title (FR)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.shortTitle_fr
            }
        },
        source: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'source') || '',
            inputConfig: {
                key: 'source',
                label: '',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.source,
                component: (
                    <SearchAdd
                        value={_.get(formData[docId], 'source') || ''}
                        label=""
                        disableUnderline={true}
                        formKey="source"
                        key="source"
                        onChange={handleSuggestion(docId)}
                        suggestionUrl="ltddocs/autocomplete"
                        params={(term) => ({ term, field: 'source' })}
                        handleSuggestions={(data) => data.map(val => val['source'])}
                    />
                )
            }
        },
        sourceTypeIds: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'sourceTypeIds')], 'array', _.get(includeIdOptions, 'sourceTypes') || []),
            inputConfig: {
                key: 'sourceTypeIds',
                label: 'Source type',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.sourceTypeIds,
                options: _.get(includeIdOptions, 'sourceTypes') || []
            }
        },
        sourceUrl: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'sourceUrl') || '',
            inputConfig: {
                key: 'sourceUrl',
                label: 'Source URL',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.sourceUrl
            }
        },
        title: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'title') || '',
            inputConfig: {
                key: 'title',
                label: 'Title',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.title
            }
        },
        title_en: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'title_en') || '',
            inputConfig: {
                key: 'title_en',
                label: 'Translated title (EN)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.title_en
            }
        },
        title_fr: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'title_fr') || '',
            inputConfig: {
                key: 'title_fr',
                label: 'Translated title (FR)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.title_fr
            }
        },

        author: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'author') || '',
            inputConfig: {
                key: 'author',
                label: 'Author/Editor',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.author
            }
        },
        publisher: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'publisher') || '',
            inputConfig: {
                key: 'publisher',
                label: 'Publisher',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.publisher
            }
        },
        confidentiality: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'confidentiality') || ''], 'array', _.get(appConstants, 'confidentiality') || []),
            inputConfig: {
                key: 'confidentiality',
                label: 'Level of confidentiality',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.confidentiality,
                options: _.get(appConstants, 'confidentiality') || []
            }
        },
        importance: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'importance') || ''], 'array', _.get(appConstants, 'importance') || IMPORTANCE),
            inputConfig: {
                key: 'importance',
                label: 'Importance',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.importance,
                options: _.get(appConstants, 'importance') || IMPORTANCE
            }
        },
        copyRightAuthorisation: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'copyRightAuthorisation'), 'boolean'),
            inputConfig: {
                key: 'copyRightAuthorisation',
                label: 'Copyright authorisation',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.copyRightAuthorisation,
                component: (
                    <ChipSelect formkey="copyRightAuthorisation" value={_.get(formData[docId], 'copyRightAuthorisation')} title='Copyright authorisation' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                )
            }
        },
        disableIndexing: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'disableIndexing'), 'boolean'),
            inputConfig: {
                key: 'disableIndexing',
                label: 'Exclude from search engine indexation',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.disableIndexing,
                component: (
                    <ChipSelect formkey="disableIndexing" value={_.get(formData[docId], 'disableIndexing')} title='Exclude from search engine indexation' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                )
            }
        },
        preparatoryPhase: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'preparatoryPhase') || ''], 'array', _.get(appConstants, 'preparatoryPhase') || PHASE_OF_PREPARATORY),
            inputConfig: {
                key: 'preparatoryPhase',
                label: 'Phase of preparatory works',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.preparatoryPhase,
                options: _.get(appConstants, 'preparatoryPhase') || PHASE_OF_PREPARATORY
            }
        },
        resourceCitation_en: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'resourceCitation_en') || '',
            inputConfig: {
                key: 'resourceCitation_en',
                label: 'Resource citation (English)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.resourceCitation_en
            }
        },
        resourceCitation_fr: {
            formData: formData[docId],
            displayValue: _.get(formData[docId], 'resourceCitation_fr') || '',
            inputConfig: {
                key: 'resourceCitation_fr',
                label: 'Resource citation (French)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.resourceCitation_fr
            }
        },
        keywordIds: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'keywordIds'), 'array'),
            inputConfig: {
                key: 'keywordIds',
                label: 'Keywords',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.keywordIds,
                component: (
                    <div className="my-3">
                        <SearchAdd
                            objectValue
                            disableAdd
                            disableUnderline={true}
                            searchKey='keywords'
                            label=""
                            formKey="keywordIds"
                            key="keywordIds"
                            onSelect={handleSuggestionSelect(docId, 'keywordIds')}
                            onChange={() => { }}
                            suggestionUrl="ltdkeywords/autocomplete"
                            params={(val) => ({ term: val, field: 'title' })}
                            handleSuggestions={(data) => data.map(val => ({ name: val['title'], id: val['id'] }))}
                        />
                        <DragReorderList
                            id={"keywords" + docId}
                            list={_.get(suggestionList[docId], 'keywordIds') || []}
                            onReorder={onReorder(docId, 'keywordIds')}
                            onRemove={handleRemoveItem(docId, 'keywordIds')}
                        />
                    </div>
                )
            }
        },
        ifri_internal: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'ifri_internal'), 'string'),
            inputConfig: {
                key: 'ifri_internal',
                label: 'IfRI-Internal',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.ifri_internal
            }
        },
        "Subject (Level 2)": {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], "Subject (Level 2)") || ''], 'array', SUBJECT),
            inputConfig: {
                key: "Subject (Level 2)",
                label: "Subject (Level 2)",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Subject (Level 2)"],
                options: SUBJECT
            }
        },

        "Subject (Level 3)": {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], "Subject (Level 3)") || ''], 'array', SUBJECT),
            inputConfig: {
                key: "Subject (Level 3)",
                label: "Subject (Level 3)",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Subject (Level 3)"],
                options: SUBJECT
            }
        },
        "Subject (Level 4)": {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], "Subject (Level 4)") || ''], 'array', SUBJECT),
            inputConfig: {
                key: "Subject (Level 4)",
                label: "Subject (Level 4)",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Subject (Level 4)"],
                options: SUBJECT
            }
        },
        Subject: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'Subject') || ''], 'array', SUBJECT),
            inputConfig: {
                key: 'Subject',
                label: 'Subject',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.Subject,
                options: SUBJECT
            }
        },
        interlocutory: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'interlocutory'), 'string'),
            inputConfig: {
                key: 'interlocutory',
                label: 'Interlocutory appeal no.',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.interlocutory,
            }
        },
        iccSituationIds: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'iccSituationIds')], 'array', _.get(includeIdOptions, 'iccSituations')),
            inputConfig: {
                key: 'iccSituationIds',
                label: 'ICC situation name',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.iccSituationIds,
                options: _.get(includeIdOptions, 'iccSituations')
            }
        },
        isCourtRecord: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'isCourtRecord'), 'boolean'),
            inputConfig: {
                key: 'isCourtRecord',
                label: 'Is court record',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.isCourtRecord,
                component: (
                    <div className="flex-row space-between">
                        <ChipSelect formkey="isCourtRecord" value={_.get(formData[docId], 'isCourtRecord')} title='Is court record' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                    </div>
                )
            }
        },
        "Alias of accused’s name": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "Alias of accused’s name"), 'string'),
            inputConfig: {
                key: "Alias of accused’s name",
                label: "Alias of accused’s name",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Alias of accused’s name"],
            }
        },
        accusedDefendant: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'accusedDefendant'), 'string'),
            inputConfig: {
                key: 'accusedDefendant',
                label: 'Accused / Defendant',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.accusedDefendant,
            }
        },
        alternative: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'alternative'), 'string'),
            inputConfig: {
                key: 'alternative',
                label: 'Alternative and / or short case title',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.alternative,
            }
        },
        alternative_en: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'alternative_en'), 'string'),
            inputConfig: {
                key: 'alternative_en',
                label: 'Alternative and / or short case title (EN)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.alternative_en,
            }
        },
        alternative_fr: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'alternative_fr'), 'string'),
            inputConfig: {
                key: 'alternative_fr',
                label: 'Alternative and / or short case title (FR)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.alternative_fr,
            }
        },
        amicusCuriae: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'amicusCuriae'), 'string'),
            inputConfig: {
                key: 'amicusCuriae',
                label: 'Amicus curiae',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.amicusCuriae,
            }
        },
        caseName: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'caseName'), 'string'),
            inputConfig: {
                key: 'caseName',
                label: 'Case name (ICC naming convention)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.caseName,
            }
        },
        caseName_en: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'caseName_en'), 'string'),
            inputConfig: {
                key: 'caseName_en',
                label: 'Case name (ICC naming convention - EN)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.caseName_en,
            }
        },
        caseName_fr: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'caseName_fr'), 'string'),
            inputConfig: {
                key: 'caseName_fr',
                label: 'Case name (ICC naming convention - FR)',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.caseName_fr,
            }
        },
        caseNumber: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'caseNumber'), 'string'),
            inputConfig: {
                key: 'caseNumber',
                label: 'Case/Document number',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.caseNumber,
            }
        },
        chamberComposition: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'chamberComposition'), 'string'),
            inputConfig: {
                key: 'chamberComposition',
                label: 'Composition of chamber',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.chamberComposition,
            }
        },
        counselForDefence: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'counselForDefence'), 'string'),
            inputConfig: {
                key: 'counselForDefence',
                label: 'Counsel for defence',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.counselForDefence,
            }
        },
        courtType: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'courtType'), 'string'),
            inputConfig: {
                key: 'courtType',
                label: 'Type of court',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.courtType,
                options: _.get(appConstants, 'courtType') || []
            }
        },
        judicialDocumentIds: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'judicialDocumentIds'), 'array', _.get(includeIdOptions, 'judicialDocuments')),
            inputConfig: {
                key: 'judicialDocumentIds',
                label: 'Key judicial document',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.judicialDocumentIds,
                options: _.get(includeIdOptions, 'judicialDocuments') || []
            }
        },
        judicialDocumentType: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'judicialDocumentType')], 'array', _.get(appConstants, 'judicialDocumentType') || TYPE_OF_JUDICIAL_DOCUMENT),
            inputConfig: {
                key: 'judicialDocumentType',
                label: 'Type of judicial document',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.judicialDocumentType,
                options: _.get(appConstants, 'judicialDocumentType') || TYPE_OF_JUDICIAL_DOCUMENT
            }
        },
        participatingStates: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'participatingStates'), 'string'),
            inputConfig: {
                key: 'participatingStates',
                label: '',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.participatingStates,
                component: (
                    <ChipSelect formkey="participatingStates" value={formData['participatingStates']} title='Participating states' onChange={handleSelect} options={BOOLEAN_OPTIONS} />
                )
            }
        },
        phaseOfCaseIds: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'phaseOfCaseIds')], 'array', _.get(includeIdOptions, 'phaseOfCases')),
            inputConfig: {
                key: 'phaseOfCaseIds',
                label: 'Phase of case',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.phaseOfCaseIds,
                options: _.get(includeIdOptions, 'phaseOfCases') || []
            }
        },
        placeCourt: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'placeCourt'), 'string'),
            inputConfig: {
                key: 'placeCourt',
                label: 'Place of court',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.placeCourt,
            }
        },
        presidingJudge: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'presidingJudge'), 'string'),
            inputConfig: {
                key: 'presidingJudge',
                label: 'Presiding judge',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.presidingJudge,
            }
        },
        prosecutorClaimant: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'prosecutorClaimant'), 'string'),
            inputConfig: {
                key: 'prosecutorClaimant',
                label: 'Prosecutor’s team / Claimant',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.prosecutorClaimant,
            }
        },
        relatedCases: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'relatedCases'), 'string'),
            inputConfig: {
                key: 'relatedCases',
                label: 'IRelated case number',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.relatedCases,
            }
        },
        trailOutcome: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'trailOutcome'), 'string'),
            inputConfig: {
                isFetch: true,
                key: 'trailOutcome',
                label: 'Outcome of trial',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.trailOutcome,
                options: _.get(appConstants, 'trialOutcome') || OUTCOME_OF_TRIAL
            }
        },
        victimParticipation: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'victimParticipation'), 'boolean'),
            inputConfig: {
                key: 'victimParticipation',
                label: '',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.victimParticipation,
                component: (
                    <ChipSelect formkey="victimParticipation" value={formData['victimParticipation']} title='Victims participation' onChange={handleSelect(docId)} options={BOOLEAN_OPTIONS} />
                )
            }
        },
        victimsCounsel: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'victimsCounsel'), 'string'),
            inputConfig: {
                key: 'victimsCounsel',
                label: 'Counsel for victims',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.victimsCounsel,
            }
        },

        // Quality Control
        "Display as": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "Display as"), 'string'),
            inputConfig: {
                key: "Display as",
                label: "Display as",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Display as"],
            }
        },
        "Metadata collected by": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "Metadata collected by"), 'string'),
            inputConfig: {
                key: "Metadata collected by",
                label: "Metadata collected by",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Metadata collected by"],
            }
        },
        "Metadata finalised on": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "Metadata finalised on"), 'date'),
            inputConfig: {
                key: "Metadata finalised on",
                label: "Metadata finalised on",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Metadata finalised on"],
            }
        },
        "orignalPdfURL": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'documentName'), 'string'),
            inputConfig: {
                key: "orignalPdfURL",
                label: "Resource / File",
                onChange: () => { },
                type: FORM_FIELD_INPUT_TYPE_MAPPING["orignalPdfURL"],
                component: (
                    <FileUpload
                        accept="application/pdf"
                        formData={formData[docId]}
                        formKey="orignalPdfURL"
                        onUpload={onFileUpload(docId)}
                        label="Resource / File"
                        id={`resource-file-${docId}`}
                    />
                )
            }
        },
        "documentOrigin.source": {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], "documentOrigin.source")], 'array', _.get(appConstants, 'documentOrigin') || ORIGIN),
            inputConfig: {
                key: "documentOrigin.source",
                label: 'Origin',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["documentOrigin.source"],
                options: _.get(appConstants, 'documentOrigin') || ORIGIN
            }
        },
        "documentOrigin.url": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "documentOrigin.url"), 'string'),
            inputConfig: {
                key: "documentOrigin.url",
                label: 'URL',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["documentOrigin.url"],
            }
        },
        fileUrl: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "fileUrl"), 'string'),
            inputConfig: {
                key: "fileUrl",
                label: 'File URL',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["fileUrl"],
            }
        },
        accessDate: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'accessDate'), 'date'),
            inputConfig: {
                key: 'accessDate',
                label: 'Date accessed/ downloaded',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.accessDate,
            }
        },
        documentType: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'documentType')], 'array', _.get(appConstants, 'documentType') || DOCUMENT_TYPE),
            inputConfig: {
                key: 'documentType',
                label: 'Document type',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.documentType,
                options: _.get(appConstants, 'documentType') || DOCUMENT_TYPE
            }
        },
        numberOfPages: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'numberOfPages'), 'string'),
            inputConfig: {
                key: 'numberOfPages',
                label: 'Number of pages',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.numberOfPages,
            }
        },
        publishDate: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'publishDate'), 'date'),
            inputConfig: {
                key: 'publishDate',
                label: 'Date published in the Legal Tools',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.publishDate,
            }
        },
        registerDate: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'registerDate'), 'date'),
            inputConfig: {
                key: 'registerDate',
                label: 'Date registered',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.registerDate,
            }
        },
        responsiblePartner: {
            formData: formData[docId],
            displayValue: getStringValue([_.get(formData[docId], 'responsiblePartner')], 'string', _.get(appConstants, 'partner') || RESPONSIBLE_PARTNER),
            inputConfig: {
                key: 'responsiblePartner',
                label: 'Responsible partner',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.responsiblePartner,
                options: _.get(appConstants, 'partner') || RESPONSIBLE_PARTNER
            }
        },

        "ICC case(s) cited": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "ICC case(s) cited"), 'string'),
            inputConfig: {
                key: "ICC case(s) cited",
                label: "ICC case(s) cited",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["ICC case(s) cited"],
            }
        },
        "Legislation cited": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "Legislation cited"), 'string'),
            inputConfig: {
                key: "Legislation cited",
                label: "Legislation cited",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["Legislation cited"],
            }
        },
        "orgaisationJudicialBody": {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "orgaisationJudicialBody"), 'string'),
            inputConfig: {
                key: "orgaisationJudicialBody",
                label: "Organisation/ State of judicial body",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["orgaisationJudicialBody"],
                component: (
                    <SearchAdd
                        disableUnderline={true}
                        label=""
                        formKey="orgaisationJudicialBody"
                        key="orgaisationJudicialBody"
                        value={getStringValue(_.get(formData[docId], "orgaisationJudicialBody"), 'string')}
                        onChange={handleSuggestion(docId)}
                        suggestionUrl="ltddocs/autocomplete"
                        params={(term) => ({ term, field: 'orgaisationJudicialBody' })}
                        handleSuggestions={(data) => data.map(val => val['orgaisationJudicialBody'])}
                    />
                )
            }
        },
        relatedDocIds: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "relatedDocIds"), 'array'),
            inputConfig: {
                key: "Related resource",
                label: "Related resource",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["relatedDocIds"],
                component: (
                    <div className="my-3">
                        <PurlResource
                            formKey="relatedDocIds"
                            label="Related resource"
                            placeholder="PURL of the document"
                            onSelect={handleSuggestionSelect(docId, 'relatedDocIds')}
                        />
                        <DragReorderList
                            id={"purl-relatedResources" + docId}
                            list={_.get(suggestionList[docId], 'relatedDocIds') || []}
                            onReorder={onReorder(docId, 'relatedDocIds')}
                            onRemove={handleRemoveItem(docId, 'relatedDocIds')}
                        />
                    </div>
                )
            }
        },
        relatedPurls: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], "relatedPurls"), 'array'),
            inputConfig: {
                key: "Related resource",
                label: "Related resource",
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING["relatedPurls"]
            }
        },
        relatedOrganisation: {
            formData: formData[docId],
            displayValue: getStringValue(_.get(formData[docId], 'relatedOrganisation'), 'string'),
            inputConfig: {
                key: 'relatedOrganisation',
                label: 'Related organisation/ State',
                onChange: handleChange(docId),
                type: FORM_FIELD_INPUT_TYPE_MAPPING.relatedOrganisation,
                component: (
                    <SearchAdd
                        disableUnderline={true}
                        label=""
                        formKey="relatedOrganisation"
                        key="relatedOrganisation"
                        value={getStringValue(_.get(formData[docId], 'relatedOrganisation'), 'string')}
                        onChange={handleSuggestion(docId)}
                        suggestionUrl="ltddocs/autocomplete"
                        params={(term) => ({ term, field: 'relatedOrganisation' })}
                        handleSuggestions={(data) => data.map(val => val['relatedOrganisation'])}
                    />
                )
            }
        }
    })
    return { formData, handleFormChange, getCellProps, loading }
}




export const DATE_CREATED_STR_FORMAT = 'DD.MM.YYYY'
export const LTD_DOC_DATE_FORMAT = 'DD-MM-YYYY'
export const getStringValue = (value: any, type: 'string' | 'boolean' | 'array' | 'date', resolver?: any[], resolveKey: string = 'value', resolveLabel: string = 'name') => {
    switch (type) {
        case 'array':
            const labels = (value || []).map(id => {
                const resolvedObj = _.find((resolver || []), { [resolveKey]: id });
                // console.log("resolver", resolver, resolvedObj)
                return _.get(resolvedObj, resolveLabel) || id
            })
            return (labels || []).join(', ');
        case 'boolean': return value ? 'Yes' : 'No';
        case 'string': return value || '';
        case 'date':
            return value ? moment(value).format(LTD_DOC_DATE_FORMAT) : ''
    }
}