const legalToolAdvisoryZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/ltac/" >LTAC</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:256/text [begin] -->
    <div id="c256" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">法律工具顾问委员会</h1></div>
    <!--  Header: [end] -->
        
    <!--  Text: [begin] -->
        <p class="bodytext"> 
            法院内部的法律工具顾问委员会的成员来自法院所有机构及包括辩护律师和被害人律师在内的其他法院程序参与者。委员会评估法院内部用户的需求，并已经制定了法律工具数据库运行的所需的《元数据手册》和关键词分类系统。
           </p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:256/text [end] -->
    </div>`;
export default legalToolAdvisoryZh;
