import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button } from '@material-ui/core'

interface IProps extends WithStyles<typeof STYLES> {
    onAgree: () => void
    onCancel?: () => void
}

class Agreement extends Component<IProps> {

    render() {
        const { classes } = this.props
        // const URL = `${window.location.protocol}//${window.location.host}/footer/terms-of-use`;

        return (
            <div className={classes.root}>
                <Typography gutterBottom variant="h6">Terms of use</Typography>
                <Typography>
                    By clicking on "Agree", you accept the <a href={"/footer/terms-of-use"} target="_blank">Terms of use of the Legal Tools Database.</a>
                </Typography>
                <div className={classes.actionContainer}>
                    <div />
                    {
                        this.props.onCancel ?
                            <Button onClick={this.props.onCancel}>Cancel</Button> : null
                    }
                    <Button onClick={this.props.onAgree}>Agree</Button>
                </div>
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        maxWidth: 400,
        margin: '0 auto'
    },
    actionContainer: {
        marginTop: 60,
        display: 'flex',
        '& div': {
            flex: '1 1 0'
        }
    }
})

export default withStyles(STYLES)(Agreement)