import React, { ComponentType, FC } from 'react';
import classNames from 'classnames';
import { createStyles, Drawer, MenuItem, Theme, withStyles, WithStyles } from '@material-ui/core';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';

export interface DashboardProps extends WithStyles<typeof STYLES> {
    basePath: string
    navs: DashboardMenuItem[]
}

export interface DashboardMenuItem {
    name: string;
    path: string;
    key: string;
    component: JSX.Element
    exact?: boolean
}

const Dashboard: FC<DashboardProps> = (props) => {
    const { classes, basePath, navs } = props;
    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.primaryPanelPaper,
                }}
                open={true}
            >
                <div className={classes.primaryPanelUpperSection} >
                    {
                        navs.map((menu, i) => {
                            return (
                                <NavLink className={classes.navlink} activeClassName={classes.active} key={menu.path} to={basePath + '/' + menu.path}>
                                    {menu.name}
                                </NavLink>
                            );
                        })
                    }
                </div>
            </Drawer>
            <div className={classes.dashboardContent}>
                <Switch>
                    {navs.length ? (
                        <Route exact={true} path={`${basePath}`} render={() => <Redirect to={`${basePath}/${navs[0].path}`} />} />
                    ) : null}
                    {
                        navs.map(nav => {
                            return <Route key={nav.path} exact={nav.exact} path={`${basePath}/${nav.path}`} render={() => nav.component} />
                        })
                    }
                </Switch>
            </div>
        </div>
    )
}


const PRIMARY_DRAWER_WIDTH = 250;

const STYLES = (theme: Theme) => createStyles({
    root: {
        paddingBottom: '100px'
    },
    primaryPanelPaper: {
        backgroundColor: theme.palette.grey['100'],
        width: PRIMARY_DRAWER_WIDTH,
        marginTop: 50,
        '& > div li': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '32px',
        }
    },
    primaryPanelUpperSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    navlink: {
        ...theme.typography.body2,
        padding: '16px',
        textDecoration: 'none',
        '&:visited': {
            color: 'inherit',
        }
    },
    active: {
        background: fade(theme.palette.primary.main, 0.4),
    },
    dashboardContent: {
        width: '100%',
        minHeight: 'calc(100vh - 50px)',
        marginLeft: PRIMARY_DRAWER_WIDTH,
    }
});

export default withStyles(STYLES)(Dashboard);