import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import FilterSearch from './FilterSearch';
import { ODocuments } from '../../Features/LTDDocuments/redux-config';
import { TState } from '../../RootReducer';
import { OCLDDocuments } from '../../Features/CLDDocuments/redux-config';
import { OAdvancedSearch } from '../../Features/AdvancedSearch/redux-config';
import { TutorialFilms } from '../../Features/TutorialFilm/tutorialFilm';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import { SEARCH_PANEL_SEARCH_INPUT_PLACEHOLDER } from '../../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, WithTranslationProps {
    filters?: Array<any>;
    onSubmit: Function;
    additionalSearch?: boolean;
    type: 'LTD' | 'CLD' | 'SCC';
}

interface IDispatchProps {
    showMatchedText: Function;
    clearSearchTerm: Function;
    setDecisionSearch: Function;
    setCLDForm: (key: string, val: string) => void;
    setLTDForm: (key: string, val: string) => void;
    setBuildTermFromQuery: (flag: boolean) => void;
}

interface IStateProps {
    searchTerm: string;
    cldSearchTerm: string;
    CLDForm: any;
    LTDForm: any;
}

class Search extends Component<Props & IDispatchProps & IStateProps> {
    state = {
        formData: {},
        animateSearch: false,
    };

    handleChange = e => {
        this.props.setBuildTermFromQuery(false);

        if (this.props.type === 'CLD')
            this.props.setCLDForm(e.target.name, e.target.value);
        else this.props.setLTDForm(e.target.name, e.target.value);
    };

    componentDidUpdate = (prevProps, prevState) => {
        // if (this.props.type === 'CLD')
        //     return;
        // if (prevProps.searchTerm !== this.props.searchTerm)
        //     this.setState({ formData: { term: this.props.searchTerm } })
    };

    handleKeyPress = e => {
        const formData =
            this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;

        if (e.charCode === 13) {
            this.props.showMatchedText(true);
            this.props.setDecisionSearch(false);
            this.props.onSubmit(formData);
        }
    };

    clear = () => {
        // if (this.props.type === 'CLD')
        //     return;
        // this.setState({ formData: {} })
    };

    onSubmit = (formData, isExactSearch = false) => {
        const form =
            this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;
        const newFormData = _.omit({ ...form, ...formData }, ['gte', 'lte']);

        this.props.onSubmit(newFormData, isExactSearch);
    };

    clearTerm = () => {
        if (this.props.type === 'CLD') {
            this.props.setCLDForm('term', '');
            const form = { ...this.props.CLDForm, term: '' };
            this.props.onSubmit(form, false);
        } else {
            this.props.setLTDForm('term', '');
            // this.props.clearSearchTerm();
        }
    };

    render() {
        const {
            classes,
            filters,
            getTranslation,
            additionalSearch = false,
            type = 'LTD',
        } = this.props;
        const { animateSearch } = this.state;
        const formData =
            type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;

        return (
            <div className={classes.root}>
                <div
                    onMouseEnter={e => this.setState({ animateSearch: true })}
                    onMouseLeave={e => this.setState({ animateSearch: false })}
                    className={classes.searchBar}
                >
                    {/* <span style={{ transform: animateSearch ? 'scale(1.2)' : '' }} className={classes.searchIcon}>
                        <i className="material-icons">search</i>
                    </span> */}
                    <TextField
                        autoComplete='off'
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        placeholder={
                            getTranslation(
                                SEARCH_PANEL_SEARCH_INPUT_PLACEHOLDER
                            ) || 'Search'
                        }
                        name='term'
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleChange}
                        value={_.get(formData, 'term') || ''}
                    />
                    <div className={type === 'LTD' ? 'mx-2' : ''}>
                        {!_.isEmpty(_.get(formData, 'term')) ? (
                            <Button
                                className={classes.clear}
                                onClick={e => this.clearTerm()}
                            >
                                <i className='material-icons'>close</i>
                            </Button>
                        ) : (
                            <span
                                style={{
                                    transform: animateSearch
                                        ? 'scale(1.2)'
                                        : '',
                                }}
                                className={classes.searchIcon}
                            >
                                <i className='material-icons'>search</i>
                            </span>
                        )}
                    </div>
                    {type === 'LTD' && TutorialFilms()['tutorial-film-3']}
                </div>

                <div className={classes.filterContainer}>
                    <FilterSearch
                        type={type}
                        onClear={this.clear}
                        filters={filters}
                        additionalSearch={additionalSearch}
                        onSubmit={this.onSubmit}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Documents, ['searchTerm']),
    cldSearchTerm: _.get(state.CLDDocuments, 'searchTerm'),
    CLDForm: _.get(state.CLDDocuments, ['formData']),
    LTDForm: _.get(state.Documents, ['formData']),
});

const mapDispatchToProps = dispatch => ({
    showMatchedText: shouldShow =>
        dispatch(ODocuments.showMatchedText(shouldShow)),
    clearSearchTerm: () => dispatch(ODocuments.applySearchTerm('')),
    setDecisionSearch: isDecisionSearch =>
        dispatch(OCLDDocuments.setDecisionSearch(isDecisionSearch)),
    setCLDForm: (key: string, val: any) =>
        dispatch(OCLDDocuments.setFormData(key, val)),
    setLTDForm: (key: string, val: any) =>
        dispatch(ODocuments.setFormData(key, val)),
    setBuildTermFromQuery: (flag: boolean) =>
        dispatch(OAdvancedSearch.setBuildTermFromQuery(flag)),
});

const STYLES = theme =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        searchBar: {
            height: 60,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 16px',
            background: THEME.Colors.Grayscale.Grey5,
            '& i': {
                marginRight: 8,
            },
        },
        filterContainer: {
            padding: '12px 20px',
            [theme.breakpoints.down('sm')]: {
                padidng: 12,
            },
        },
        searchIcon: {
            transition: '200ms linear',
        },
        clear: {
            borderRadius: '50%',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            // height: 'auto',
            width: 25,
            height: 22,
            background: 'gray',
            '& i': {
                margin: 0,
                color: 'white',
                fontSize: 14,
                padding: 4,
            },
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(Search)));
