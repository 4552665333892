import { Reducer } from "redux";
import { TResponsiveState } from "./@types";
import { OResponsive } from "./redux-config";
import { TAction } from "../../Resources/Model";
import utilities from "../../Resources/Utils";
import { OAuth } from "../Authentication/redux-config";
import _ from 'lodash';


const initialState: TResponsiveState = {
    ltd: {
        dialogOpen: false,
        dialogFor: 'filter'
    },
    cld: {
        dialogOpen: false,
        dialogFor: 'search',
    }
}


export const Responsive: Reducer<TResponsiveState> = (state = initialState, action: TAction) => {
    const {
        LTD_RESPONSIVE_DIALOG_OPEN,
        CLD_RESPONSIVE_DIALOG_OPEN,
        RESPONSIVE_DIALOG_CLOSE,
    } = OResponsive.actions;


    switch (action.type) {
        case CLD_RESPONSIVE_DIALOG_OPEN: {
            return { ...state, cld: { dialogOpen: true, dialogFor: action.data || 'search' } }
        }
        case LTD_RESPONSIVE_DIALOG_OPEN: {
            return { ...state, ltd: { dialogOpen: true, dialogFor: action.data || 'filter' } }
        }
        case RESPONSIVE_DIALOG_CLOSE: {
            return { ...state, ltd: { dialogOpen: false, dialogFor: 'filter' }, cld: { dialogFor: 'search', dialogOpen: false } }
        }
        default: return { ...state }
    }
}