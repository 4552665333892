import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles, IconButton, Icon } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { fade } from '@material-ui/core/styles/colorManipulator';

export interface CloseButtonProps extends Omit<IconButtonProps, 'classes'>, WithStyles<typeof STYLES> {

}

const CloseButton: FC<CloseButtonProps> = (props) => {
    const { classes, children, ...iconbuttonProps } = props;

    return (
        <IconButton className={classes.root} {...iconbuttonProps}>
            <Icon fontSize="inherit">close</Icon>
        </IconButton>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.grey.A200,
        color: theme.palette.common.white,
        width: 24,
        height: 24,
        fontSize: 14,
        '&:hover': {
            backgroundColor: fade(theme.palette.grey.A200, 0.8),
        }
    }
});

export default withStyles(STYLES)(CloseButton);