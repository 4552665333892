import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import Cell from './Cell';
import { LTD_DOCUMENT_FORM_FIELDS, TLtdDocFormFieldKey } from './constants';
import _ from 'lodash';
import { useBatchEdit, TBatchEditForm, UseBatchEditConfig } from './useBatchEdit';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { TBatchEditState } from '../../Features/BatchEdit/@types';
import THEME from '../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import FullscreenLoader from '../FullscreenLoader';


export interface BatchEditProps extends WithStyles<typeof STYLES>, UseBatchEditConfig {
    // docIds: string[]
    // formFields: TLtdDocFormFieldKey[]
    // formData: TBatchEditState['batchEditForm'] | TBatchEditState['batchCreateForm']
    // onChange: (form: TBatchEditForm) => void
    // onDocUpdate?: (docId: string) => void
    // appConstants: any
    selectedDocIds?: string[]
    reviewMode?: boolean
    onSelectAll?: () => void
    onDisselectAll?: () => void
    onSelectDocument?: (docId: string) => void
}


const BatchEdit: FC<BatchEditProps> = (props) => {
    const { classes,
        reviewMode = false,
        docIds = [],
        formFields = [],
        includeIdOptions = {},
        formData = {},
        appConstants = {},
        onDocModified,
        onFormChange,
        errorIds,
        selectedDocIds = [],
        onDisselectAll = () => { },
        onSelectAll = () => { },
        onSelectDocument = () => { },
        isNewDocument = false
    } = props;


    const fields = LTD_DOCUMENT_FORM_FIELDS.filter(f => formFields.includes(f.key));
    let CONTAINER_WIDTH = (fields.length + 1) * CELL_WIDTH;
    CONTAINER_WIDTH = isNewDocument ? CONTAINER_WIDTH : CONTAINER_WIDTH + CHECKBOX_CELL_WIDTH;

    const { getCellProps, loading } = useBatchEdit({
        errorIds,
        formData,
        docIds,
        formFields,
        onFormChange,
        includeIdOptions,
        onDocModified,
        appConstants
    });

    const ids = isNewDocument ? Object.keys(formData) : docIds;


    const handleSelectAll = (e, isChecked: boolean) => {
        isChecked ? onSelectAll() : onDisselectAll();
    }

    const handleSelectDoc = (docId: string) => (e, isChecked: boolean) => {
        onSelectDocument(docId);
    }

    const isAllSelected = ids.length === selectedDocIds.length && selectedDocIds.length > 0;

    return (
        <div className={classes.root}>
            <div style={{ width: CONTAINER_WIDTH }} className={classNames(classes.header, classes.row)}>
                <div className={classNames(classes.leadingCol, classes.row)}>
                    {!isNewDocument ? (
                        <div className={classNames(classes.cell, classes.checkboxCell)} >
                            <Checkbox color="primary" checked={isAllSelected} onChange={handleSelectAll} />
                        </div>
                    ) : null}
                    <div className={classNames(classes.cell, classes.stickyTitle)}>
                        <Typography>Title</Typography>
                    </div>
                </div>
                {fields.map((f, i) => (
                    <Typography className={classNames(classes.cell, fields.length === i + 1 ? classes.lastEntry : '')} key={i}>{f.label}</Typography>
                ))}
            </div>
            <div style={{ width: CONTAINER_WIDTH }} className={classes.content}>
                {ids.map((docId, j) => formData[docId] ? (
                    <div key={docId} className={classNames(classes.row, ids.length === j + 1 ? classes.lastRow : '', errorIds.includes(docId) ? classes.error : '')}>
                        <div className={classNames(classes.leadingCol, classes.row)}>
                            {!isNewDocument ? (
                                <div className={classNames(classes.cell, classes.checkboxCell)}>
                                    <Checkbox color="primary" checked={selectedDocIds.includes(docId)} onChange={handleSelectDoc(docId)} />
                                    {formData[docId].isPublished ? (
                                        <Typography className={classes.publishedStatus}>Published</Typography>
                                    ) : null
                                    }
                                </div>
                            ) : null}
                            <div className={classNames(classes.cell, classes.stickyTitle)}>
                                <Typography>{formData[docId].title || ''}</Typography>
                            </div>
                        </div>
                        {fields.map((field, i) => (
                            <div
                                className={classNames(classes.cell, fields.length === i + 1 ? classes.lastEntry : '')}
                                key={i}
                            >
                                <Cell {...getCellProps(docId)[field.key]} />
                            </div>
                        ))}
                    </div>
                ) : null)}
            </div>
            {loading ? <FullscreenLoader /> : null}
        </div>
    )
}

const CELL_WIDTH = 240;
const CHECKBOX_CELL_WIDTH = 44

const STYLES = (theme: Theme) => createStyles({
    root: {
        // width: 20 * 200,
        width: '100vw',
        overflow: 'auto',
        height: '100vh'
    },
    header: {
        zIndex: 2,
        backgroundColor: theme.palette.grey.A200,
        position: 'sticky',
        top: 0,
        color: theme.palette.common.white,
        '& > p': {
            boxSizing: 'border-box',
            padding: '10px 8px',
        }
    },
    content: {

    },
    row: {
        display: 'flex',
        // width: 20 * CELL_WIDTH,
        alignItems: 'inherit',
        borderTop: `1px dashed grey`,
    },
    lastRow: {
        borderBottom: `1px dashed grey`,
    },
    cell: {
        width: CELL_WIDTH,
        // minHeight: 40,
        // height: 40,
        borderLeft: `1px dashed grey`,
        color: 'inherit',
    },
    lastEntry: {
        borderRight: `1px dashed grey`,
    },
    error: {
        border: `2px solid ${THEME.Colors.Error}`
    },
    checkboxCell: {
        width: CHECKBOX_CELL_WIDTH
    },

    leadingCol: {
        // width: 44,
        position: 'sticky',
        left: 0,
        backgroundColor: theme.palette.common.white,
        zIndex: 1,
        boxShadow: `0px 3px 6px ${fade('#000', 0.35)}`
    },
    stickyTitle: {
        padding: 8,
    },
    publishedStatus: {
        color: THEME.Colors.Success,
        textOrientation: 'mixed',
        padding: '8px 0px 0px 0px',
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
        margin: 'auto'
    }
});



export default withStyles(STYLES)(BatchEdit);