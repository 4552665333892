import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { TUTORIAL_FILM_URLS } from '../../Resources/constants';
import { MENU_LIST_TUTORIAL } from '../Translations/translationKeys.ltd';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';

const _TutorialFilm: React.FC<{
    className?: string;
    link: string;
    icon: string;
    text?: boolean;
} & WithTranslationProps> = props => {
    return props.text ? (
        <Link
            className={classNames(props.className, 'only-desktop')}
            to={props.link}
        >
            <Typography variant='body2' style={{ fontWeight: 500, lineHeight: 1.2 }}>
                {props.getTranslation(MENU_LIST_TUTORIAL) || 'TUTORIALS'}
            </Typography>
        </Link>
    ) : (
        <a
            className={classNames(props.className, 'only-desktop')}
            href={props.link}
            target='_blank'
        >
            <img src={`/icons/${props.icon}`} style={{ height: '12px', marginLeft: '12px' }} />
        </a>
    );
};

const TutorialFilm = withTranslations(_TutorialFilm);

export const TutorialFilms = () => {
    const isScc = /\/scc.*/i.test(window.location.pathname);

    if (isScc) return {};

    return {
        'tutorial-film-1': (
            <TutorialFilm text icon='LTD-Camera-icon-white.png' link='/tutorials' className='tutorial-film-1' />
        ),
        'tutorial-film-2': (
            <TutorialFilm icon='LTD-Camera-icon.png' link={TUTORIAL_FILM_URLS[9]} className='tutorial-film-2' />
        ),
        'tutorial-film-3': (
            <TutorialFilm icon='LTD-Camera-icon.png' link={TUTORIAL_FILM_URLS[6]} className='tutorial-film-3' />
        ),
        'tutorial-film-4': (
            <TutorialFilm icon='LTD-Camera-icon.png' link={TUTORIAL_FILM_URLS[5]} className='tutorial-film-4' />
        ),
        'tutorial-film-5': (
            <TutorialFilm icon='LTD-Camera-icon.png' link={TUTORIAL_FILM_URLS[0]} className='tutorial-film-5' />
        ),
        'tutorial-film-6': (
            <TutorialFilm icon='LTD-Camera-icon.png' link={TUTORIAL_FILM_URLS[7]} className='tutorial-film-6' />
        ),
    };
};
