import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles, CircularProgress } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';

export interface FullscreenLoaderProps extends Omit<CircularProgressProps, 'classes'>, WithStyles<typeof STYLES> {
}

const FullscreenLoader: FC<FullscreenLoaderProps> = (props) => {
    const { classes, ...loaderProps } = props;

    return (
        <div className={classes.root}>
            <CircularProgress {...loaderProps} />
        </div>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        zIndex: theme.zIndex.modal + 100,
        left: 0,
        top: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: fade(theme.palette.grey['A200'], 0.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default withStyles(STYLES)(FullscreenLoader);