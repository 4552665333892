import utilities from '../Resources/Utils';
import { CldField } from './CldFields.types';

export const CLD_FIELDS_STATIC = [
    // DECISION ASSESSMENT
    {
        type: 'legalFindings',
        name: 'With Legal Findings',
        id: 'true',
        name__en: 'With Legal Findings',
        name__fr: 'Avec conclusions juridiques',
        name__es: 'Con Conclusiones Jurídicas',
    },
    {
        type: 'legalFindings',
        name: 'Without Legal Findings',
        id: 'false',
        name__en: 'Without Legal Findings',
        name__fr: 'Sans conclusions juridiques',
        name__es: 'Sin Conclusiones Jurídicas',
    },
    {
        type: 'legalFindings',
        name: 'Not Analysed',
        id: 'NA',
        name__en: 'Not Analysed',
        name__fr: 'Non analyse',
        name__es: 'No Analizada',
    },
];

export class CldFieldService {
    static getStaticStaticFields() {
        const locale = utilities.getLocale();
        const fields = CLD_FIELDS_STATIC.map(field => ({ ...field, name: field[`name__${locale}`] }));
        return fields;
    }

    static getAll() {
        return utilities.request<CldField[]>({
            url: `/CldFields`,
        });
    }

    static updateCldField(id: string, cldField: Partial<CldField>) {
        return utilities.request<CldField>({
            url: `/CldFields/${id}`,
            method: 'PATCH',
            data: cldField,
        });
    }

    static newCldField(cldField: Partial<CldField>) {
        return utilities.request<CldField>({
            url: `/CldFields`,
            method: 'POST',
            data: cldField,
        });
    }

    static deleteCldField(id: string) {
        return utilities.request<void>({
            url: `/CldFields/${id}`,
            method: 'DELETE',
        });
    }

    static getCldFieldsForType(cldFields: CldField[], type: string) {
        return cldFields.filter(cldField => cldField.type === type);
    }

    static resolveCldField(cldFields: CldField[], id: string) {
        const field = cldFields.find(cldField => cldField.id === id);
        if (field) return field.name;
        return '';
    }
}
