import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button } from '@material-ui/core';
import Reorder, {
    reorder,
} from 'react-reorder';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

interface IProps extends WithStyles<typeof STYLES> {
    id: string
    list: any
    onReorder: (newList: any) => void
    onRemove?: (index: number) => void
}

class DragReorderList extends Component<IProps> {


    onReorder = (event: any, previousIndex: number, nextIndex: number, fromId: string, toId: string) => {
        const newList = reorder(this.props.list, previousIndex, nextIndex)
        this.props.onReorder(newList)
    }




    render() {
        const { classes, list = [], id } = this.props
        // const id = _.uniqueId('drag-reorder');
        return (
            <div className={classes.root}>
                <Reorder
                    reorderId={id} // Unique ID that is used internally to track this list (required)
                    reorderGroup={id + "reorder-group"} // A group ID that allows items to be dragged between lists of the same group (optional)
                    component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                    placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                    draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                    lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                    holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                    touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                    mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                    onReorder={this.onReorder} // Callback when an item is dropped (you will need this to update your state)
                    autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                    disabled={false} // Disable reordering (optional), defaults to false
                    disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                    placeholder={
                        <div className={classes.customPlaceholder} /> // Custom placeholder element (optional), defaults to clone of dragged element
                    }
                >
                    {
                        list.map((item, i) => (
                            <div className={classes.listItem} key={item.name}>
                                <Typography variant="caption" style={{ marginRight: 10 }}>{i}</Typography>
                                <Typography variant="caption" className={classes.listItemText}>{item.name}</Typography>
                                <div className={classes.actionContainer}>
                                    <Button variant="contained" onClick={e => this.props.onRemove ? this.props.onRemove(i) : null}>
                                        <i className="material-icons">close</i>
                                    </Button>
                                </div>
                            </div>
                        ))
                    }
                </Reorder>
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        margin: '20px 0px',

    },
    customPlaceholder: {
        background: fade('#000', 0.2),
        height: 30,
        borderRadius: 4,
    },

    listItem: {
        margin: '8px 0px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& span': {
            minHeight: 30,
            display: 'flex',
            alignItems: 'center'
        }
    },
    listItemText: {
        flex: '1 1 0',
        paddingLeft: 8,
        background: THEME.Colors.Grayscale.Grey6,
        borderRadius: 4,
        paddingRight: 20,
    },
    actionContainer: {
        position: 'absolute',
        right: 5,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',

        '& button': {
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            height: 16,
            width: 16,
            borderRadius: 8,
            background: THEME.Colors.TextLight,
            '&:hover': {
                background: THEME.Colors.Error,
            },
            '& > span': {
                minHeight: 0
            },
            '& i': {
                fontSize: 12,
                color: 'white'
            }
        }
    }
})

export default withStyles(STYLES)(DragReorderList)