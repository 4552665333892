import { createMuiTheme } from '@material-ui/core/styles';
import THEME from '../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../Resources/Theme';

export default createMuiTheme({
    palette: {
        primary: {
            light: THEME.Colors.Secondary.light,
            main: THEME.Colors.Tertiary.main,
            dark: THEME.Colors.Tertiary.dark,
            contrastText: '',
        },
        secondary: {
            light: THEME.Colors.Primary.light,
            main: THEME.Colors.Tertiary.main,
            dark: THEME.Colors.Tertiary.dark,
            contrastText: '',
        },
        grey: {
            A200: '#403F41',
            '100': '#707070',
        },
        // error: {
        //     light: '',
        //     main: '',
        //     dark: '',
        //     contrastText: ''
        // },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Helvetica Neue, Helvetica, Arial',
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        h1: {
            fontSize: 96,
            color: Theme.Colors.TextColor,
        },
        h2: {
            fontSize: 60,
            color: Theme.Colors.TextColor,
        },
        h3: {
            fontSize: 48,
            color: Theme.Colors.TextColor,
        },
        h4: {
            fontSize: 34,
            color: Theme.Colors.TextColor,
        },
        h5: {
            fontSize: 24,
            color: Theme.Colors.TextColor,
        },
        h6: {
            fontSize: 20,
            color: Theme.Colors.TextColor,
        },
        subtitle1: {
            fontSize: 16,
            color: Theme.Colors.TextColor,
        },
        subtitle2: {
            fontSize: 14,
            color: Theme.Colors.TextColor,
        },
        body1: {
            color: Theme.Colors.TextColor,
            fontSize: 16,
        },
        body2: {
            color: Theme.Colors.TextColor,
            fontSize: 14,
        },
        caption: {
            color: Theme.Colors.TextColor,
            fontSize: 12,
        },
        overline: {
            color: Theme.Colors.TextColor,
            fontSize: 10,
        },
    },
    overrides: {
        MuiSnackbar: {
            root: {
                zIndex: 1600,
            },
        },
        MuiButton: {
            textPrimary: {
                color: Theme.Colors.Tertiary.main,
                '&:hover': {
                    color: Theme.Colors.Tertiary.dark,
                },
            },
            containedPrimary: {
                color: 'white',
            },
            root: {
                height: 40,
                transition: '200ms linear',
                fontWeight: 500,
            },
            raised: {},
            outlined: {},
            contained: {},
            disabled: {},
            flat: {},
            extendedFab: {},
            text: {},
        },
        MuiListItem: {
            root: {
                transition: '200ms linear',
            },
        },
        MuiInput: {},
        MuiInputBase: {
            input: {},
        },
        MuiInputLabel: {
            animated: {},
        },
        MuiDialog: {
            container: {
                background: fade(Theme.Colors.Fourth, 0.8),
            },
            paperWidthSm: {
                maxWidth: 'fit-content',
            },
        },
        MuiAppBar: {
            root: {},
        },
        MuiSvgIcon: {
            root: {
                transition: '200ms linear',
            },
        },
        MuiChip: {
            root: {
                transition: '220ms linear',
            },
            label: {},
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: Theme.Colors.Tertiary.dark,
            },
        },
    },
});