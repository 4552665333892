import { TUTORIAL_FILM_LEXSITUS_URLS } from "../../../Resources/constants";
const lexsitusRu=`
<h1>Lexsitus</h1>
<p><a href="https://cilrap-lexsitus.org/" target="_blank">Лекситус</a> (‘Lexsitus’) — это интернет-платформа с открытым доступом, дополняющая базу данных юридических инструментов МУС (‘LTD’). В то время как LTD помогает вам найти документы, имеющие отношение к международному уголовному праву, Вы можете использовать Lexsitus, чтобы получить бесплатный онлайн-доступ к:</p>
<ul>
<li>
постоянно обновляемому комментарию объемом более 2000 книжных страниц, охватывающему каждое положение Статута МУС и Правил процедуры и доказывания МУС;  </li>
<li>
двум сборникам выдержек из судебной практики по элементам основных международных преступлений и средствам доказывания по этим элементам;
</li>
<li>
подготовительным работам, оносящимся к Статуту МУС и поправкам к нему; и
</li>
<li>видеотеке из более чем 230 лекций о положениях Статута МУС и общих принципах международного уголовного права.</li>
</ul>  

<p>
Все ресурсы Lexsitus структурированы на уровне статей Статута МУС (поэтому Вы можете получить доступ к информации через статьи в корешке Lexsitus на левом поле или через меню). Кроме того, ресурсы Lexsitus связаны гиперссылками с LTD (поэтому Lexsitus является примером того, как базы данных и другие онлайн-сервисы могут основываться на LTD).
</p> 
<p>
Недавнее <a href="https://mailchi.mp/cilrap/ps-9298146?e=eace4a0eba" target="_blank"> объявление о выпуске Lexsitus 2.0. отмечает</a>, что комментарий доступен на английском, арабском и французском языках; субтитры к видео доступны на английском, арабском, французском и персидском языках; и дайджест элементов преступлений (‘Elements Digest’) на английском, арабском, французском и испанском языках — как отмечено <a href="https://cilrap-lexsitus.org/en/introduction" target="_blank">в этом фильме о Lexsitus 2.0. прокурором МУС Каримом А.А. Ханом</a>.  
</p>
<p>
Lexsitus сертифицирован Альянсом цифровых общественных товаров как цифровое общественное благо. Он был создан Мортеном Бергсмо, а его координатором является Антонио Анготти (с которым можно связаться по адресу <a href="mailto:angotti@cilrap.org">angotti@cilrap.org</a> по вопросам касательно Lexsitus). 
</p>

<p>
Ниже вы найдете несколько учебных пособий по использованию услуг Lexsitus, которые связаны гиперссылками с LTD.
</p> 
<div class="grid"> 
${TUTORIAL_FILM_LEXSITUS_URLS.map(
    (url, i) => {
        i += 19;
        return `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i}.png" style="width: 100%;" alt="Tutorial ${i}" />
        </a>
        `;
    }
).join('')}

</div>
`;
export default lexsitusRu;