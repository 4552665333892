import axios from 'axios';
import { TNode } from '../Features/CLDDocumentTree/@types';
import { TranslatedKeywordResponse } from './Keyword.types';

class KeywordService {
    static newNode(keyword: Partial<TNode>) {
        return axios.request<TNode[]>({
            url: `/keywords/create`,
            method: 'POST',
            data: [keyword],
        });
    }

    static updateNode(id: string, data: Partial<TNode>) {
        return axios.request({
            url: `/keywords/${id}`,
            method: 'PATCH',
            data,
        });
    }

    static deleteNode(id: string, adjustChildrenInParent: boolean = true, backup: boolean = true) {
        return axios.request({
            url: `/keywords/delete`,
            method: 'DELETE',
            data: {
                where: { id },
                adjustChildrenInParent,
                backup,
            },
        });
    }

    static getTranslatedKeywords(ids: string[], cldFieldId: string) {
        return axios.request<TranslatedKeywordResponse>({
            url: `Keywords/transalated-keyword`,
            params: {
                ids,
                cldFieldId,
            },
        });
    }
}

export default KeywordService;
