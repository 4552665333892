import { TBatchReviewState } from "./@types";
import { LtdDocService } from "../../Services/LtdDocs.service";
import { OApp } from "../../RootReducer/AppReducer";



export class BatchReview {
    static actions = {
        BATCH_REVIEW_DIALOG_OPEN: 'BATCH_REVIEW_DIALOG_OPEN',
        BATCH_REVIEW_DIALOG_CLOSE: 'BATCH_REVIEW_DIALOG_CLOSE',
        BATCH_REVIEW_TOGGLE_DOC_SELECTION: 'BATCH_REVIEW_TOGGLE_DOC_SELECTION',
        BATCH_REVIEW_CLEAR_SELECTION: 'BATCH_REVIEW_CLEAR_SELECTION',
        BATCH_REVIEW_SELECT_ALL_DOCUMENTS: 'BATCH_REVIEW_SELECT_ALL_DOCUMENTS',
        BATCH_REVIEW_SET_FORM: 'BATCH_REVIEW_SET_FORM',
        BATCH_REVIEW_SET_DOCUMENT_SELECTION: 'BATCH_REVIEW_SET_DOCUMENT_SELECTION',
        BATCH_REVIEW_SET_LOADING: 'BATCH_REVIEW_SET_LOADING',
        BATCH_REVIEW_DOCUMENTS_PUBLISHED: 'BATCH_REVIEW_DOCUMENTS_PUBLISHED',
    };


    static setLoading = (loading: boolean) => dispatch => dispatch({
        type: BatchReview.actions.BATCH_REVIEW_SET_LOADING,
        data: loading
    })

    static batchReview = (docIds: string[]) => dispatch => {
        dispatch({
            type: BatchReview.actions.BATCH_REVIEW_DIALOG_OPEN,
            data: docIds
        })
    }



    static batchPublishDocuments = (ids: string[]) => async dispatch => {
        dispatch(BatchReview.setLoading(true));
        try {
            const res = await LtdDocService.publish(ids);
            dispatch({
                type: BatchReview.actions.BATCH_REVIEW_DOCUMENTS_PUBLISHED,
                data: ids
            })
            dispatch(OApp.showToast(`${ids.length} Documents(s) published`, 'success'));

        } catch (error) {

        }
        dispatch(BatchReview.setLoading(false));
    }

    static toggleDocumentSelection = (id: string) => dispatch => dispatch({
        type: BatchReview.actions.BATCH_REVIEW_TOGGLE_DOC_SELECTION,
        data: id
    })

    static clearDocumentSelection = () => dispatch => dispatch({
        type: BatchReview.actions.BATCH_REVIEW_CLEAR_SELECTION,
    })

    static selectAllDocuments = () => dispatch => dispatch({
        type: BatchReview.actions.BATCH_REVIEW_SELECT_ALL_DOCUMENTS,
    })

    static handleFormChange = (form: TBatchReviewState['form']) => dispatch => {
        dispatch({
            type: BatchReview.actions.BATCH_REVIEW_SET_FORM,
            data: form
        })
    }



    static closeDialog = () => dispatch => dispatch({ type: BatchReview.actions.BATCH_REVIEW_DIALOG_CLOSE });
}