import { Reducer } from 'redux';
import { TDocumentState } from './@types';
import { TAction } from '../../Resources/Model';
import {
    ODocuments,
    setLTDMetadata,
    DOCUMENT_STATUS_KEY,
    DOCUMENT_STATUS_FACETS,
    mapParentIdsToValues,
    setTreeIdValueMapping,
} from './redux-config';
import _ from 'lodash';
import { OApp } from '../../RootReducer/AppReducer';
import { removeIdFromSelection } from '../CLDDocuments/cld-documents-reducer';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import { OAuth } from '../Authentication/redux-config';

export type TListHeader = 'RESULTS' | 'LATEST';

const initialState: TDocumentState = {
    appConstants: {},
    total: 0,
    documents: [],
    filter: {},
    selected: [],
    selectedFacets: {},
    searchTerm: '',
    listHeader: 'LATEST',
    formData: {},
    chipList: [],
    facets: Object,
    sort: 'Latest',
    userSort: 'Latest',
    currentPage: 0,
    recordPerPage: 20,
    searching: true,
    showMatchedText: false,
    overrideRelevance: false,
};

const resetValues: Partial<TDocumentState> = {
    selected: [],
    filter: {},
    searchTerm: '',
    listHeader: 'LATEST',
    chipList: [],
    facets: Object,
    sort: 'Latest',
    userSort: 'Latest',
    showMatchedText: false,
    overrideRelevance: false,
    formData: {},
    selectedFacets: {},
};

export const Documents: Reducer<TDocumentState> = (state = initialState, action: TAction): TDocumentState => {
    const {
        MODEL_ENTITY_RECEIVED,
        APPLY_FILTER,
        SET_CURRENT_PAGE,
        APPLY_SORT,
        SEARCHING_DOCUMENTS,
        SEARCH_COMPLETED,
        SET_LIST_HEADER,
        SET_CHIP_LIST,
        APPLY_SEARCH_TERM,
        SET_SHOW_MATCHED_TEXT,
        LTD_TOGGLE_DOCUMENT_SELECTION,
        LTD_SELECT_ALL_DOCUMENTS,
        LTD_CLEAR_DOCUMENT_SELECTION,
        LTD_APPLY_USER_SORT,
        LTD_RESET_ALL_FILTERS,
        LTD_SET_FORM_DATA,
        LTD_CLEAR_FORM_DATA,
        LTD_SET_RECORD_PER_PAGE,
        LTD_APPLY_FACET,
        LTD_REMOVE_FACET,
        LTD_RESET_FACETS,
        LTD_DOCS_RESTORED,
        LTD_REPLACE_FORM_DATA,
        INIT_QUERY_PARAMS,
    } = ODocuments.actions;

    const { USER_LOGGED_IN } = OAuth.actions;

    const { LTD_DOCUMENTS_DELETED, LTD_DOC_PUBLISH } = ONewLTDDocument.actions;

    const { SET_APP_CONSTANTS } = OApp.actions;

    switch (action.type) {
        case LTD_DOC_PUBLISH: {
            const doc = action.data;
            const index = _.findIndex(state.documents, item => _.get(item, 'hit.id') === doc.id);
            if (index === -1) return { ...state };
            let documents = [...state.documents];
            documents[index] = { ...documents[index], hit: { ...documents[index].hit, ...action.data } };
            return { ...state, documents };
        }
        case LTD_DOCUMENTS_DELETED: {
            const { ids, permanently }: { ids: string[]; permanently: boolean } = action.data;
            if (!permanently) return { ...state };
            return {
                ...state,
                total: state.total ? state.total - ids.length : 0,
                documents: state.documents.filter(item => _.indexOf(ids, _.get(item, 'hit.id')) === -1),
            };
        }
        case LTD_TOGGLE_DOCUMENT_SELECTION: {
            if (action.data) {
                const id = action.data;
                const selected =
                    state.selected.indexOf(id) > -1
                        ? removeIdFromSelection(state.selected, id)
                        : [...state.selected, id];
                return { ...state, selected };
            }
            return { ...state };
        }
        case LTD_SELECT_ALL_DOCUMENTS: {
            return { ...state, selected: action.data };
        }
        case LTD_CLEAR_DOCUMENT_SELECTION: {
            return { ...state, selected: [] };
        }
        case SET_APP_CONSTANTS: {
            return { ...state, appConstants: action.data };
        }
        case APPLY_SEARCH_TERM: {
            return { ...state, searchTerm: action.data };
        }
        case SET_CHIP_LIST: {
            return { ...state, chipList: action.data };
        }
        case SET_LIST_HEADER: {
            return { ...state, listHeader: action.data };
        }
        case SEARCHING_DOCUMENTS: {
            return { ...state, searching: true };
        }
        case SEARCH_COMPLETED: {
            return { ...state, searching: false };
        }
        case SET_CURRENT_PAGE: {
            return { ...state, currentPage: action.data };
        }
        case APPLY_FILTER: {
            return { ...state, filter: action.data, overrideRelevance: false };
        }
        case APPLY_SORT: {
            return { ...state, sort: action.data.sort };
            // return { ...state, filter: action.data.filter, sort: action.data.sort }
        }
        case LTD_RESET_FACETS: {
            return { ...state, selectedFacets: { ...state.selectedFacets, [action.data]: [] } };
        }
        case LTD_REMOVE_FACET: {
            const newList = _.filter(
                _.get(state.selectedFacets, action.data.key),
                value => value !== action.data.value
            );
            return { ...state, selectedFacets: { ...state.selectedFacets, [action.data.key]: newList } };
        }
        case LTD_APPLY_FACET: {
            return {
                ...state,
                selectedFacets: {
                    ...state.selectedFacets,
                    [action.data.key]: _.union(_.get(state.selectedFacets, action.data.key), [action.data.value]),
                },
            };
        }

        case LTD_APPLY_USER_SORT: {
            return {
                ...state,
                sort: action.data.sort,
                userSort: action.data.sort,
                overrideRelevance: state.sort === 'Relevance',
            };
        }

        // case USER_LOGGED_IN: {
        //     console.log("DOCUMENT STATUS", DOCUMENT_STATUS_FACETS)
        //     return { ...state, total: _.get(action.data, 'total'), facets: { ..._.get(state, 'facets'), [DOCUMENT_STATUS_KEY]: DOCUMENT_STATUS_FACETS } }
        // }

        case MODEL_ENTITY_RECEIVED: {
            const documents = _.map(_.get(action.data, 'results'), doc => ({
                ...doc,
                hit: { ...doc.hit, metadata: setLTDMetadata(_.get(doc, 'hit'), state.appConstants) },
            }));
            return {
                ...state,
                total: _.get(action.data, 'total'),
                documents,
                facets: { ..._.get(action.data, 'facets'), [DOCUMENT_STATUS_KEY]: DOCUMENT_STATUS_FACETS },
            };
        }
        case SET_SHOW_MATCHED_TEXT: {
            return { ...state, showMatchedText: action.data };
        }
        case LTD_CLEAR_FORM_DATA: {
            return { ...state, formData: {}, searchTerm: '' };
        }
        case LTD_SET_FORM_DATA: {
            return { ...state, formData: { ...state.formData, [action.data.key]: action.data.value } };
        }
        case LTD_REPLACE_FORM_DATA:
            return { ...state, formData: action.data };
        case LTD_SET_RECORD_PER_PAGE: {
            return { ...state, recordPerPage: action.data };
        }
        case LTD_RESET_ALL_FILTERS: {
            return { ...state, ...resetValues, filter: {} };
        }
        case LTD_DOCS_RESTORED: {
            const docIds = _.get(action, 'data.docIds');
            if (_.isEmpty(docIds)) return state;
            let documents = [...state.documents];
            documents = _.map(documents, doc => {
                if (_.indexOf(docIds, doc.hit.id) > -1) doc = { ...doc, hit: { ...doc.hit, deleted: false } };
                return doc;
            });
            return { ...state, documents };
        }

        case INIT_QUERY_PARAMS: {
            setTreeIdValueMapping(
                _.get(action.data, 'mapParentIdsToValues'),
                _.get(action.data, 'mapvaluesToParentIds')
            );
            return {
                ...state,
                currentPage: _.get(action.data, 'currentPage') || 0,
                formData: { ..._.get(action.data, 'formData') },
                selectedFacets: _.get(action.data, 'filter.where'),
                sort: action.data.sort,
                filter: action.data.filter,
                recordPerPage: action.data.recordPerPage,
                searchTerm: action.data.searchTerm,
            };
        }

        default:
            return { ...state };
    }
};
