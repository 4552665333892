const purlZh = `<div id="content" class="content">
    <div id="breadcrumbs">
        <p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="purl/">PURL</a></p>
    </div>
    <div id="getUrlText">
        <div
            style="cursor:pointer;float:right;"
            title="Collapse tree"
            id="collapseTree"
        >
            <i class="glyphicon glyphicon-collapse-down"></i>
        </div>
        <a href="javascript:window.print();" style="float:right;"
            ><i class="glyphicon glyphicon-print"></i
        ></a>
    </div>
    <br style="clear:both;" />
    <!--  CONTENT ELEMENT, uid:147/text [begin] -->
    <div id="c147" class="csc-default">
        <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
            <h1 class="csc-firstHeader">永久链接（PURLs）</h1>
        </div>
        <!--  Header: [end] -->

        <!--  Text: [begin] -->
        <p class="bodytext">
        国际刑事法院法律工具数据库（LTD）使用永久链接（PURLs）。数据库中所有文件的地址和链接都是永久不变的。用户可在文件正文（或者脚注）中通过永久链接引用数据库文件。永久链接用于超链接后，该超链接也将不会破坏。
        </p>
        <p class="bodytext">
           将免费、公开数据库中的法律文件的永久链接用于超链接有助于创造一个不断扩大的法律文件和资源的公共空间。作为国际刑法领域的公共空间，数据库让本领域的法律信息获取更加平等，从而使从业者的竞争环境更加公平。在这个意义上法律工具项目有助于国家层面的能力建设。
        </p>
        <p class="bodytext">
           2012年5月30日，国际刑事法院上诉庭开始在其决定中使用数据库中的永久链接 <a href="https://www.legal-tools.org/en/doc/9813bb/" target="_blank">2014年3月7日的卡汤加判决</a> 以超链接形式引用了数据库中的538个法律文件。从那以后法院的很多决定以同样的超链接形式引用了数据库文件。2021年3月，国际刑事法院与国际法研究与政策中心（CILRAP，该组织为国际刑事法院管理法律工具系统）签订了十年合作协议。在该协议中，法院承诺尽可能地“在其判决和其他公开决定和文件中以超链接的形式引用数据库中的永久链接”。法律工具项目鼓励各法院、政府、非政府组织、学者、出版社等组织和个人尽可能地使用数据库文件的超链接。
        </p>
        <ul>
            <li>
                <a
                    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/161111_LTD_leaflet__Fourth_Edition.pdf"
                    target="_blank"
                    >
                   建立国际刑法领域的公共空间，第四版
                    </a
                >
            </li>
        </ul>
        <!--  Text: [end] -->
    </div>
    <!--  CONTENT ELEMENT, uid:147/text [end] -->
</div>`;

export default purlZh;
