import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Система документации и
расследований (I-DOC)</h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    Система документации и
расследований (I-DOC) — это
цифровая база данных для
работы с фактами, имеющими
отношение к серьезным
нарушениям международного
уголовного права или прав
человека, включая
документацию, расследование,
анализ, подготовку дела,
судебное разбирательство и
последующиева процесса. В то
время как База данных
Международного Уоловного
Суда Legal Tools Database (LTD) и
Lexsitus предоставляют
пользователям доступ к
соответствующим правовым
источникам и юридической
информации, необходимой для
понимания международных
преступлений, I-DOC является
инструментом для практиков,
позволяющим планировать и
проводить документацию и
следственную работу на основе
знаний, которые редоставляются
с помощью LTD и Lexsitus.

<br/><br/>
I-DOC предлагает возможности
систематизировать большие
объемы документации и
доказательств, уголовных происшествий и контекстуальных
фактов, подозреваемых и
вовлеченных организаций, жертв
и свидетелей, охраняемой
собственности и объектов, а
также других соответствующих
фактических обстоятельств. Это
помогает обеспечить
необходимую точность и
качество во всех рабочих
процессах.

<br/><br/>

Структура базы данных
обеспечивает эффективный
обзор этих информационных
категорий, а также возможность
искать и анализировать
различные закономерности и
связи. Пользователи могут
создавать уголовные дела и дела
по правам человека на основе
этих информационных
категорий, в том числе
использовать матрицу для
анализа дел на основе правовых
требований преступлений,
предусмотренных Римским
статутом МУС.

<br/><br/>
Концепцию этой базы данных
разработал Илья Утмелидзе,
опираясь в частности на базу
данных Case Matrix МУС и
продолжающего процесса его
концептуализации, а также
сотрудничая с группой экспертов.
Утмелидзе возглавляет отдел
CMN CILRAP, с ним можно
связаться по адресу <a href="mailto:utmelidze@cilrap.org">utmelidze@cilrap.org</a>.
    </p>

    <div class="grid">
         <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-1/" target="_blank">
            <img src="/images/i-doc-1.png" style="width: 100%;" alt="How to use the I-Doc system" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-2/" target="_blank">
            <img src="/images/i-doc-2.png" style="width: 100%;" alt="How to build international criminal law cases in I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-3/" target="_blank">
            <img src="/images/i-doc-3.png" style="width: 100%;" alt="How to customize I-DOC" />
        </a>
    </div>

    </div>`;

export default tutorialFilmEn;
