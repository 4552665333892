import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Tabs,
    Tab,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Input,
    FormControl,
    TextField,
    Typography,
    Slide,
    Dialog,
    Fab,
    DialogContent,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Search from '../../Components/Search';
import Tree from '../../Components/Tree';
import { OCLDDocumentTree } from '../../Features/CLDDocumentTree/redux-config';
import { TNode } from '../../Features/CLDDocumentTree/@types';
import Theme from '../../Resources/Theme';
import classNames from 'classnames';
import moment from 'moment';
import { OCLDDocuments } from '../../Features/CLDDocuments/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TUser, ICldPermissions } from '../../Features/Authentication/@types';
import { selectAllNodes } from '../../Features/CLDDocumentTree/cld-documentTree-reducer';
import { getCLDUserRole } from '../../Features/Authentication/redux-config';
import { OAdvancedSearch } from '../../Features/AdvancedSearch/redux-config';
import { TResponsiveState, TCLDDialogType } from '../../Features/Responsive/@types';
import { OResponsive } from '../../Features/Responsive/redux-config';
import { buildFilterFromFormData } from '../../Features/CLDDocuments/redux-config';
import { TSort } from '../../Features/CLDDocuments/@types';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    SEARCH_PANEL_ADVANCED_SEARCH_BUTTON,
    SEARCH_PANEL_DOCUMENT_NUMBER_INPUT_PLACEHOLDER,
    SEARCH_PANEL_KEYWORD_SEARCH_TAB,
    SEARCH_PANEL_LEGAL_FINDING_ID_INPUT_PLACEHOLDER,
    SEARCH_PANEL_TEXT_SEARCH_TAB,
    SEARCH_PANEL_TYPE_OF_DECISION_INPUT_PLACEHOLDER,
    GENERAL_SELECT_ALL,
    KEYWORD_TREE_TAB_COLLAPSE_KEYWORD_TREE_BUTTON,
    GENERAL_CLEAR_SELECTION,
    GENERAL_SUBMIT,
    GENERAL_SELECTED,
    KEYWORD_TREE_TAB_SEARCH_INPUT_PLACEHOLDER,
    GENERAL_AND,
    GENERAL_OR,
    GENERAL_NOT,
} from '../../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {}

interface IDispatchProps {
    openNode: Function;
    closeNode: Function;
    resetTree: Function;
    onCheck: Function;
    searchDocuments: Function;
    searchTree: Function;
    applySearchTerm: Function;
    applyQuery: Function;
    applyOperation: Function;
    setDecisionSearch: Function;
    openNewLegalFindingDialog: Function;
    selectAll: () => void;
    clearSelection: () => void;
    openAdvancedSearchDialog: () => void;
    closeResponsiveDialog: () => void;
    setSidebartab: (tab: number) => void;
    setKeywordtext: (text: string) => void;
    setHighlighterConfig: (config: string) => void;
    applySort: (sort: TSort) => void;
}

interface IStateProps {
    sidebarTab: number;
    user: TUser;
    highlighterConfig: string;
    itemsSelected: Array<string>;
    tree: Array<TNode>;
    isDecisionSearch: boolean;
    cldPermissions: ICldPermissions;
    formData: Record<string, any>;
    keywordText: string;
    responsive: TResponsiveState['cld'];
}

export const searchFilters = [
    {
        label: 'Document number',
        translationKey: SEARCH_PANEL_DOCUMENT_NUMBER_INPUT_PLACEHOLDER,
        name: 'Document Number',
        ltdDocKey: 'externalId',
        type: 'search',
    },
    {
        label: 'Type of decision',
        translationKey: SEARCH_PANEL_TYPE_OF_DECISION_INPUT_PLACEHOLDER,
        name: 'ltdDoc.cld.typeOfDecisionEn',
        options: [
            // { label: 'None', key: '' },
            { label: 'Decision', key: 'Decision' },
            { label: 'Order', key: 'Order' },
            {
                label: 'Decision 15 RS - authorisation of investigation',
                key: 'Decision 15 RS - authorisation of investigation',
            },
            {
                label: 'Decision 18-19 RS - jurisdiction or admissibility',
                key: 'Decision 18-19 RS - jurisdiction or admissibility',
            },
            {
                label: 'Decision 53.3 RS - review Prosecutor decision not to proceed',
                key: 'Decision 53.3 RS - review Prosecutor decision not to proceed',
            },
            {
                label: 'Decision 58 RS - warrant of arrest/summons to appear',
                key: 'Decision 58 RS - warrant of arrest/summons to appear',
            },
            {
                label: 'Decision 61 RS - confirmation of charges',
                key: 'Decision 61 RS - confirmation of charges',
            },
            {
                label: 'Decision 74 RS - conviction or acquittal',
                key: 'Decision 74 RS - conviction or acquittal',
            },
            {
                label: 'Decision/Order 75 RS - reparations',
                key: 'Decision/Order 75 RS - reparations',
            },
            {
                label: 'Decision 76 RS - sentence',
                key: 'Decision 76 RS - sentence',
            },
            {
                label: 'Appeal Judgment 81 RS - conviction, acquittal, or sentence',
                key: 'Appeal Judgment 81 RS - conviction, acquittal, or sentence',
            },
            {
                label: 'Appeal Judgment 82 RS - interlocutory appeals',
                key: 'Appeal Judgment 82 RS - interlocutory appeals',
            },
            {
                label: 'Appeal Judgment 82.4 RS - reparations',
                key: 'Appeal Judgment 82.4 RS - reparations',
            },
            {
                label: 'Decision 84 RS - revision of conviction or sentence',
                key: 'Decision 84 RS - revision of conviction or sentence',
            },
            {
                label: 'Decision 110 RS - review reduction of sentence',
                key: 'Decision 110 RS - review reduction of sentence',
            },
        ],
    },
    {
        label: 'Legal finding ID',
        translationKey: SEARCH_PANEL_LEGAL_FINDING_ID_INPUT_PLACEHOLDER,
        name: 'ID',
    },
];

function Transition(props) {
    return <Slide direction='up' style={{ transformOrigin: '100% 0% 0' }} in={true} {...props} timeout={380} />;
}

class LeftNav extends Component<Props & IStateProps> {
    state = {
        tabValue: 0,
        operation: 'OR',
        highlightSetterDialogOpen: false,
    };

    submit = (formData, isExactSearch = false) => {
        if (!_.isUndefined(formData.term)) {
            this.props.applySearchTerm(formData.term);
            formData = _.omit(formData, 'term');
        }

        let query = { match_phrase: {}, where: {} };
        if (!_.isEmpty(formData)) {
            _.forEach(formData, (value, key) => {
                if (
                    key === 'created' ||
                    key === 'dateCreated' ||
                    key === 'ltdDoc.dateCreated' ||
                    key === 'ID' ||
                    isExactSearch
                ) {
                    query = {
                        ...query,
                        where: { ...query.where, [key]: value },
                    };
                } else if (value !== '' && key !== 'lte' && key !== 'gte')
                    query = {
                        ...query,
                        match_phrase: { ...query.match_phrase, [key]: value },
                    };
            });
            // _.forEach(formData, (value, key) => {
            //     if (key !== 'metadata_date_created')
            //         query.match = { [key]: value }
            // })
            const dateCreated = {
                type: 'range',
                gte: new Date(_.get(formData, 'gte')).getTime(),
                lte: new Date(_.get(formData, 'lte')).getTime(),
            };
            if (formData && formData.lte && formData.gte) {
                query.where = { ...query.where, dateCreated };
            }
        }
        this.props.applyQuery(query, isExactSearch);
        this.props.closeResponsiveDialog();
        this.props.searchDocuments(0, false, false);
    };

    handleTabChange = (e, value) => {
        this.props.setSidebartab(value);
        // this.setState({ tabValue: value });
    };

    onTreeContentClick = (root: TNode) => {
        // this.props.applyQuery({
        //     where: { parentIds: [root.id] }
        // });
        // mapValueAndId(root);
        // this.props.showMatchedText(false);
        // this.props.searchDocuments(0, true);
    };

    onCheck = (node: TNode) => {
        // console.log("node", node);
        this.props.onCheck(node);
    };

    onCollapse = () => {
        this.props.resetTree();
    };

    selectAll = () => {
        this.props.selectAll();
    };

    submitOperation = () => {
        const { operation } = this.state;
        this.props.applyOperation(operation);
        this.props.setDecisionSearch(false);
        this.props.closeResponsiveDialog();
        this.props.searchDocuments(0, false, true, true);
    };

    handleRadioButton = (e, value) => {
        this.setState({ operation: value });
    };
    handleClearSelection = () => {
        this.props.clearSelection();
    };

    handleNewLegalFinding = () => {
        this.props.openNewLegalFindingDialog();
    };

    handleAdvancedSearch = () => {
        this.props.openAdvancedSearchDialog();
    };

    getDevViews = () => {
        return (
            <div>
                <Button onClick={e => this.setState({ highlightSetterDialogOpen: true })}>Set Highlighter</Button>
                <Dialog
                    open={this.state.highlightSetterDialogOpen}
                    scroll='paper'
                    PaperProps={{ style: { minWidth: 500, minHeight: 200 } }}
                >
                    <DialogContent>
                        <Button
                            onClick={e =>
                                this.setState({
                                    highlightSetterDialogOpen: false,
                                })
                            }
                        >
                            close
                        </Button>
                        <div>
                            <div
                                style={{
                                    margin: '20px 0px',
                                    maxHeight: 300,
                                    overflow: 'auto',
                                    border: `1px solid grey`,
                                }}
                            >
                                <TextField
                                    label='Set highlighter config'
                                    value={this.props.highlighterConfig}
                                    fullWidth
                                    multiline
                                    onChange={e => this.props.setHighlighterConfig(e.target.value)}
                                />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    render() {
        const {
            classes,
            itemsSelected = [],
            tree = [],
            isDecisionSearch = false,
            user,
            cldPermissions,
            responsive,
            sidebarTab = 0,
            highlighterConfig = '',
            getTranslation,
        } = this.props;
        const { dialogFor, dialogOpen = false } = responsive;

        const { operation } = this.state;

        return (
            <div className={classes.root}>
                {cldPermissions.docCreate && (
                    <Button className={classes.newLegalFinding} variant='fab' mini onClick={this.handleNewLegalFinding}>
                        <i className='material-icons'>add</i>
                    </Button>
                )}
                <Tabs value={sidebarTab} onChange={this.handleTabChange} indicatorColor={Theme.Colors.Third}>
                    <Tab
                        label={getTranslation(SEARCH_PANEL_TEXT_SEARCH_TAB)}
                        classes={{
                            root: classes.tab,
                            selected: classes.selected,
                        }}
                    />
                    {!isDecisionSearch && (
                        <Tab
                            label={getTranslation(SEARCH_PANEL_KEYWORD_SEARCH_TAB)}
                            classes={{
                                root: classes.tab,
                                selected: classes.selected,
                            }}
                        />
                    )}
                </Tabs>

                {sidebarTab === 0 ? (
                    <>
                        <Button onClick={this.handleAdvancedSearch} fullWidth>
                            {getTranslation(SEARCH_PANEL_ADVANCED_SEARCH_BUTTON)}
                        </Button>
                        <Search type='CLD' filters={searchFilters} additionalSearch={true} onSubmit={this.submit} />
                        {/* {this.getDevViews()} */}
                    </>
                ) : (
                    <div className={classes.content}>
                        <div className={classes.stickyContainer}>
                            <KeywordSearch {...this.props} />
                            <div
                                className={classNames(classes.control, 'flex-row space-between align-center')}
                                style={{
                                    background: 'white',
                                    color: Theme.Colors.Third,
                                }}
                            >
                                <Button onClick={this.selectAll} variant='text' color='inherit'>
                                    <Typography color='inherit' variant='caption'>
                                        {getTranslation(GENERAL_SELECT_ALL).toUpperCase()}
                                    </Typography>
                                </Button>
                                <Button onClick={this.onCollapse} color='inherit' variant='text'>
                                    <Typography color='inherit' variant='caption'>
                                        {getTranslation(KEYWORD_TREE_TAB_COLLAPSE_KEYWORD_TREE_BUTTON).toUpperCase()}
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                        <div className={classes.documentTree}>
                            <Tree
                                classes={{
                                    container: classes.treeContainer,
                                    node: classes.treeNode,
                                    highlightContainer: classes.treeHighlightContainer,
                                    content: classes.treeContent,
                                }}
                                extendedKeywordTree={cldPermissions.extendedKeywordTree}
                                color={Theme.Colors.Third}
                                textKey='keyword_text'
                                fallbackTextKeys={['keyword_text__en']}
                                variant='cld'
                                // onCollapse={this.onCollapse}
                                onOpen={this.props.openNode}
                                onClose={this.props.closeNode}
                                onClick={this.onTreeContentClick}
                                disableCheck={false}
                                checked={itemsSelected}
                                tree={tree}
                                onCheck={this.onCheck}
                                // collapseText="collapse keyword tree"
                            />
                        </div>
                    </div>
                )}

                {itemsSelected.length > 0 && sidebarTab === 1 && (
                    <div className={classes.filterOperation}>
                        <div className='flex-row'>
                            <RadioGroup
                                aria-label='Operation'
                                name='operation'
                                className={classes.group}
                                value={operation}
                                onChange={this.handleRadioButton}
                            >
                                <FormControlLabel
                                    value='AND'
                                    control={<Radio />}
                                    label={getTranslation(GENERAL_AND) || 'AND'}
                                />
                                <FormControlLabel
                                    value='OR'
                                    control={<Radio />}
                                    label={getTranslation(GENERAL_OR) || 'OR'}
                                />
                                <FormControlLabel
                                    value='NOT'
                                    control={<Radio />}
                                    label={getTranslation(GENERAL_NOT) || 'NOT'}
                                />
                            </RadioGroup>
                            <Button variant='contained' onClick={this.submitOperation} className={classes.submitbtn}>
                                {getTranslation(GENERAL_SUBMIT).toUpperCase()}
                            </Button>
                        </div>
                        <div className={classNames(classes.control, 'flex-row space-between align-center')}>
                            <Typography className='mx-1' color='inherit'>
                                {itemsSelected.length + ' ' + getTranslation(GENERAL_SELECTED)}
                            </Typography>
                            <Button variant='text' color='inherit' onClick={this.handleClearSelection}>
                                {getTranslation(GENERAL_CLEAR_SELECTION)}
                            </Button>
                        </div>
                    </div>
                )}

                {/** components below are exclusive for mobile devices < 600px  */}

                <Dialog
                    scroll='paper'
                    className={classes.filterDialog}
                    open={dialogOpen && (dialogFor === 'keyword' || dialogFor === 'search')}
                    fullScreen
                >
                    <div className={classes.dialogHeader}>
                        <Typography color='inherit'>{_.upperCase(dialogFor)}</Typography>
                        <Fab size='small' onClick={e => this.props.closeResponsiveDialog()}>
                            <i className='material-icons'>close</i>
                        </Fab>
                    </div>
                    <div className={classes.dialogContainer}>
                        {dialogFor === 'keyword' ? (
                            <div className={classes.content}>
                                <div className={classes.stickyContainer}>
                                    <KeywordSearch {...this.props} />
                                    <div
                                        className={classNames(classes.control, 'flex-row space-between align-center')}
                                        style={{
                                            background: 'white',
                                            color: Theme.Colors.Third,
                                        }}
                                    >
                                        <Button onClick={this.selectAll} variant='text' color='inherit'>
                                            <Typography color='inherit' variant='caption'>
                                                {getTranslation(GENERAL_SELECT_ALL).toUpperCase()}
                                            </Typography>
                                        </Button>
                                        <Button onClick={this.onCollapse} color='inherit' variant='text'>
                                            <Typography color='inherit' variant='caption'>
                                                {getTranslation(
                                                    KEYWORD_TREE_TAB_COLLAPSE_KEYWORD_TREE_BUTTON
                                                ).toUpperCase()}
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                                <div className={classes.documentTree}>
                                    <Tree
                                        extendedKeywordTree={cldPermissions.extendedKeywordTree}
                                        color={Theme.Colors.Third}
                                        textKey='keyword_text'
                                        fallbackTextKeys={['keyword_text__en']}
                                        onOpen={this.props.openNode}
                                        onClose={this.props.closeNode}
                                        onClick={this.onTreeContentClick}
                                        disableCheck={false}
                                        checked={itemsSelected}
                                        tree={tree}
                                        onCheck={this.onCheck}
                                    />
                                </div>
                                {itemsSelected.length > 0 ? (
                                    <div className={classes.filterOperation}>
                                        <div className='flex-row'>
                                            <RadioGroup
                                                aria-label='Operation'
                                                name='operation'
                                                className={classes.group}
                                                value={operation}
                                                onChange={this.handleRadioButton}
                                            >
                                                <FormControlLabel value='AND' control={<Radio />} label='AND' />
                                                <FormControlLabel value='OR' control={<Radio />} label='OR' />
                                                <FormControlLabel value='NOT' control={<Radio />} label='NOT' />
                                            </RadioGroup>
                                            <Button
                                                variant='contained'
                                                onClick={this.submitOperation}
                                                className={classes.submitbtn}
                                            >
                                                {getTranslation(GENERAL_SUBMIT).toUpperCase()}
                                            </Button>
                                        </div>
                                        <div
                                            className={classNames(
                                                classes.control,
                                                'flex-row space-between align-center'
                                            )}
                                        >
                                            <Typography className='mx-1' color='inherit'>
                                                {itemsSelected.length + ' ' + getTranslation(GENERAL_SELECTED)}
                                            </Typography>
                                            <Button variant='text' color='inherit' onClick={this.handleClearSelection}>
                                                {getTranslation(GENERAL_CLEAR_SELECTION)}
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {dialogFor === 'search' ? (
                            <>
                                <Button onClick={this.handleAdvancedSearch} fullWidth>
                                    {getTranslation(SEARCH_PANEL_ADVANCED_SEARCH_BUTTON)}
                                </Button>
                                <Search
                                    type='CLD'
                                    filters={searchFilters}
                                    additionalSearch={true}
                                    onSubmit={this.submit}
                                />
                            </>
                        ) : null}
                    </div>
                </Dialog>
            </div>
        );
    }
}

class KeywordSearch extends Component<Props & IDispatchProps & IStateProps & WithTranslationProps> {
    state = {
        formData: {
            keyword: '',
        },
    };

    resetTree = () => {
        this.props.setKeywordtext('');
        this.props.resetTree();
    };

    handleKeywordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { formData } = this.state;
        const { value } = e.target;
        // this.setState({ formData: { keyword: value } });
        this.props.setKeywordtext(value);
        this.props.searchTree(value);
    };
    render() {
        const { classes, keywordText, getTranslation } = this.props;

        return (
            <div className={classes.searchBar}>
                <TextField
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    placeholder={getTranslation(KEYWORD_TREE_TAB_SEARCH_INPUT_PLACEHOLDER)}
                    name='keyword'
                    // onKeyPress={this.handleKeyPress}
                    onChange={this.handleKeywordChange}
                    value={keywordText || ''}
                />
                {!_.isEmpty(keywordText) && (
                    <Button className={classes.clear} onClick={e => this.resetTree()}>
                        <i className='material-icons'>close</i>
                    </Button>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.CLDDocumentTree, ['tree', 'itemsSelected']),
    ..._.pick(state.CLDDocuments, ['isDecisionSearch', 'formData', 'sidebarTab', 'keywordText', 'highlighterConfig']),
    ..._.pick(state.Auth, ['user']),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
    responsive: _.get(state.Responsive, 'cld'),
});

const mapDispatchToProps = dispatch => ({
    openNode: parentId => dispatch(OCLDDocumentTree.fetchChildren(parentId)),
    closeNode: parentId => dispatch(OCLDDocumentTree.removeChildren(parentId)),
    resetTree: () => dispatch(OCLDDocumentTree.resetTree()),
    clearSelection: () => dispatch(OCLDDocumentTree.clearSelection()),
    onCheck: node => dispatch(OCLDDocumentTree.treeItemSelect(node)),
    applySort: sort => dispatch(OCLDDocuments.applySort(sort)),
    selectAll: () => dispatch(OCLDDocumentTree.treeSelectALlItems()),
    searchDocuments: (pageNumber, treeSearch, preservePrevSearch, keywordSearch) =>
        dispatch(OCLDDocuments.searchDocuments(0, treeSearch, preservePrevSearch, keywordSearch)),
    searchTree: term => dispatch(OCLDDocumentTree.treeSearch(term)),
    applyQuery: (filter, isExactSearch) => dispatch(OCLDDocuments.applyQuery(filter, isExactSearch)),
    applySearchTerm: text => dispatch(OCLDDocuments.applySearchTerm(text)),
    applyOperation: opr => dispatch(OCLDDocuments.applyOperation(opr)),
    setDecisionSearch: isDecisionSearch => dispatch(OCLDDocuments.setDecisionSearch(isDecisionSearch)),
    openNewLegalFindingDialog: () => dispatch(ONewLegalFinding.openDecisionSelectionDialog()),
    openAdvancedSearchDialog: () => dispatch(OAdvancedSearch.openDialog('CLD')),
    closeResponsiveDialog: () => dispatch(OResponsive.closeDialog()),
    setSidebartab: (tab: number) => dispatch(OCLDDocuments.setSidebarTab(tab)),
    setKeywordtext: (text: string) => dispatch(OCLDDocuments.setKeywordText(text)),
    setHighlighterConfig: (config: string) => dispatch(OCLDDocuments.setHighlighter(config)),
});

const STYLES = theme =>
    createStyles({
        root: {
            height: '100%',
            position: 'relative',
        },
        content: {
            height: '100%',
            overflowY: 'auto',
            position: 'relative',
        },
        control: {
            color: 'white',
            borderBottom: `1px dashed ${Theme.Colors.Grayscale.Grey3}`,
            padding: '0px 8px',
        },
        documentTree: {
            // width: 324,
            padding: '0px 10px 0px 16px',
            paddingBottom: 100,
            paddingTop: 60,
            margin: '26px auto',
        },
        stickyContainer: {
            position: 'fixed',
            width: 360,
            // left: 0,
            zIndex: 1,
            // right: 0,
        },
        treeNode: {
            paddingRight: 0,
            // marginBlock: '4px',
        },
        treeHighlightContainer: {
            paddingRight: 38,
        },
        tab: {
            flex: 1,
            color: 'white',
            maxWidth: 'inherit',
            background: fade(Theme.Colors.Third, 0.4),
        },
        selected: {
            background: Theme.Colors.Third,
        },
        filterOperation: {
            position: 'fixed',
            bottom: 0,
            width: 360,
            left: 0,
            height: 100,
            padding: 8,
            boxSizing: 'border-box',
            // display: 'flex',
            background: Theme.Colors.Fourth,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        group: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            paddingLeft: 8,
            '& span': {
                color: 'white',
            },
        },
        searchBar: {
            height: 60,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 16px',
            background: Theme.Colors.Grayscale.Grey5,
            // '& i': {
            //     marginRight: 8
            // }
        },
        newLegalFinding: {
            position: 'absolute',
            zIndex: 1,
            right: -18,
            bottom: 140,
            background: Theme.Colors.Third,
            '& i': {
                color: 'white',
            },
            '&:hover': {
                background: fade(Theme.Colors.Third, 0.8),
            },
        },
        submitbtn: {
            width: 100,
            height: 52,
            borderRadius: 0,
            background: Theme.Colors.Third,
            color: 'white',
            '&:hover': {
                background: Theme.Colors.Primary.light,
            },
        },
        clear: {
            borderRadius: '50%',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            // height: 'auto',
            width: 25,
            height: 22,
            background: 'gray',
            '& i': {
                margin: 0,
                color: 'white',
                fontSize: 14,
                padding: 4,
            },
        },
        filterDialog: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            width: '100vw',
            top: 0,
            bottom: 0,
            overflow: 'scroll',
        },
        dialogHeader: {
            // width: '100%',
            height: 50,
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            background: Theme.Colors.Primary.main,
            display: 'flex',
            justifyContent: 'space-between',
            color: 'white',
            alignItems: 'center',
            paddingLeft: 10,
            '& i': {
                color: 'white',
            },
            '& button': {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        dialogContainer: {
            marginTop: 50,
        },
        treeContainer: {
            width: '100%',
        },
        treeContent: {
            '& > button': {
                // padding: 0,
                // marginTop: 8,
            },
            '& > span': {
                // width: 20,
                lineHeight: 1.3,
                // marginTop: 5,
                // marginLeft: 4,
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(LeftNav)));
