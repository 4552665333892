const contactUsRu = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
	<div id="c258" class="csc-default">
	<!--  Header: [begin] -->
		<div class="csc-header csc-header-n1">
        <h1 class="csc-firstHeader">Связаться с нами</h1></div>
	<!--  Header: [end] -->
		
	<!--  Text: [begin] -->
		<p  class="bodytext">
        С базой данных Юридических инструментов МУС («LTD») можно связаться по адресу <a href="mailto:info@cilrap.org">info@cilrap.org</a>. </p>
		<p  class="bodytext">
        Вы можете использовать кнопку «Сообщить о проблеме» в правом верхнем углу меню LTD, которая дает вам возможность напрямую:</p>
		<ul>
			<li>сообщить об ошибке или предложить улучшение;</li>
			<li>предложить добавить документ в LTD; или,</li>
			<li>предложить внести исправления в метаданные существующей записи LTD.</li>
		</ul>
	<!--  Text: [end] -->
		</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
	</div>`;
export default contactUsRu;
