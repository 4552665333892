import _ from 'lodash';
import axios from 'axios';
import Config from './Config';
import { Dispatch } from 'redux';

// These are the methods by which a request is hitted.
export type TMethod = 'GET' | 'POST' | 'UPDATE' | 'DELETE' | 'PUT' | 'PATCH';

axios.defaults.baseURL = Config.get('API_URL');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const utilities = {
    request: (config: { url: string; params?: object; method?: TMethod; data?: object }) => {
        return axios.request(config);
    },
};

type Actions = {
    FETCHING_LIST: string;
    LIST_RECEIVED: string;

    FETCHING_SINGLE_ITEM: string;
    SINGLE_ITEM_RECEIVED: string;

    FETCHING_ENTITY_OF_SINGLE_ITEM: string;
    SINGLE_ITEM_ENTITY_RECEIVED: string;

    POSTING_MODEL_ENTITY: string;
    MODEL_ENTITY_POST_SUCCESS: string;

    FETCHING_MODEL_ENTITY: string;
    MODEL_ENTITY_RECEIVED: string;
};

export interface TAction {
    type: string;
    data?: any;
    entity?: string;
    additionalDispatchData?: any;
}

export class Models {
    constructor(modelName: string, customActions = {}, customActionTypes = {}) {
        this.modelName = modelName;
        this.modelCaps = _.toUpper(modelName);
        this.actions = {
            // modelname e.g. destinations/
            FETCHING_LIST: `FETCHING_${this.modelCaps}_LIST`,
            LIST_RECEIVED: `${this.modelCaps}_LIST_RECEIVED`,

            // modelname/id e.g. destinations/1209fja0jdvHjdns12
            FETCHING_SINGLE_ITEM: `FETCHING_SINGLE_${this.modelCaps}_ITEM`,
            SINGLE_ITEM_RECEIVED: `SINGLE_${this.modelCaps}_ITEM_RECEIVED`,

            POSTING_SINGLE_ITEM: `POSTING_${this.modelCaps}`,
            SINGLE_ITEM_POST_SUCCESS: `${this.modelCaps}_SUCCESSFULLY_POSTED`,

            // modelname/id/entityname e.g. destinations/asdkadsadasdasdkwpwr/reviews
            FETCHING_ENTITY_OF_SINGLE_ITEM: `FETCHING_${this.modelCaps}_SINGLE_ENTITY`,
            SINGLE_ITEM_ENTITY_RECEIVED: `${this.modelCaps}_SINGLE_ENTITY_RECEIVED`,

            // POST:modelname/entityname e.g. users/login
            POSTING_MODEL_ENTITY: `POSTING_${this.modelCaps}_ENTITY`,
            MODEL_ENTITY_POST_SUCCESS: `${this.modelCaps}_ENTITY_POST_SUCCESS`,

            UPDATING_MODEL_ENTITY_BY_ITEM: `UPDATING_${this.modelCaps}_ENTITY_BY_ITEM`,
            MODEL_ENTITY_UPDATE_BY_ITEM_SUCCESS: `${this.modelCaps}_ENTITY_BY_UPDATE_SUCCESS`,

            // modelname/entity e.g. users/me
            FETCHING_MODEL_ENTITY: `FETCHING_${this.modelCaps}_ENTITY`,
            MODEL_ENTITY_RECEIVED: `${this.modelCaps}_ENTITY_RECIEVED`,

            MODEL_ENTITY_ITEM_POST_SUCCESS: `${this.modelCaps}_ENTITY_ITEM_POST_SUCCESS`,
            POSTING_MODEL_ENTITY_ITEM: `POSTING_${this.modelCaps}_ENTITY_ITEM`,

            // Override the values of pre-defined actions for a particular object
            ...customActions,
        };
        this.customActionTypes = customActionTypes;
    }

    readonly modelName: string;
    readonly modelCaps: string;
    protected customActionTypes: object;
    public actions: any;

    // This will fetch all the list of a single item
    getItemsList = (filter?: object, additionalDispatchData?: object) => {
        return (dispatch: Function) => {
            dispatch({ type: this.actions.FETCHING_LIST });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: this.modelName,
                        params: filter,
                    })
                    .then(
                        res => {
                            dispatch({ type: this.actions.LIST_RECEIVED, data: res.data, additionalDispatchData });
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        }
                    );
            });
        };
    };

    // This will fetch the single item by ID of a single item
    getItem(id: string | number, filter?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            dispatch({ type: this.actions.FETCHING_SINGLE_ITEM });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: `/${this.modelName}/${id}`,
                        params: filter,
                    })
                    .then(
                        res => {
                            dispatch({
                                type: this.actions.SINGLE_ITEM_RECEIVED,
                                data: res.data,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        }
                    );
            });
        };
    }

    postItem(data?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            this.actions.POSTING_SINGLE_ITEM = `POST_REQUESTING_${this.modelCaps}`;
            this.actions.SINGLE_ITEM_POST_SUCCESS = `${this.modelCaps}_POST_SUCCESS`;
            dispatch({ type: this.actions.POSTING_SINGLE_ITEM });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        method: 'POST',
                        url: `${this.modelName}`,
                        data: data,
                    })
                    .then(
                        res => {
                            dispatch({ type: this.actions.SINGLE_ITEM_POST_SUCCESS, data: res.data });
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        }
                    );
            });
        };
    }

    getEntityByItem(id: number | string, entity: string, filter?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            this.actions.FETCHING_ENTITY_OF_SINGLE_ITEM = `FETCHING_${_.toUpper(entity)}_OF_SINGLE_${this.modelCaps}`;
            this.actions.SINGLE_ITEM_ENTITY_RECEIVED = `${_.toUpper(entity)}_OF_SINGLE_${this.modelCaps}_RECEIVED`;
            dispatch({ type: this.actions.FETCHING_ENTITY_OF_SINGLE_ITEM });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: `${this.modelName}/${id}/${entity}`,
                        params: filter,
                    })
                    .then(
                        res => {
                            dispatch({
                                type: this.actions.SINGLE_ITEM_ENTITY_RECEIVED,
                                entity,
                                data: res.data,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            console.log('Error', err);
                            reject(err);
                        }
                    );
            });
        };
    }

    putEntityByItem(id: string, entity: string, data?: object, additionalDispatchData?: object) {
        return (dispatch: Dispatch<any>) => {
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: `${this.modelName}/${id}/${entity}`,
                        data,
                        method: 'PUT',
                    })
                    .then(
                        res => {
                            this.actions.MODEL_ENTITY_UPDATE_BY_ITEM_SUCCESS = `${this.modelCaps}_${_.toUpper(
                                entity
                            )}_UPDATE_BY_ITEM_SUCCESS`;
                            dispatch({
                                type: this.actions.MODEL_ENTITY_UPDATE_BY_ITEM_SUCCESS,
                                data: res.data,
                                entity,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            console.log('Error', err);
                            reject(err);
                        }
                    );
            });
        };
    }

    getModelEntity(entity: string, filter?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            this.actions.FETCHING_MODEL_ENTITY = `FETCHING_${this.modelCaps}_${_.toUpper(entity)}`;
            this.actions.MODEL_ENTITY_RECEIVED = `${this.modelCaps}_${_.toUpper(entity)}_RECEIVED`;
            dispatch({ type: this.actions.FETCHING_MODEL_ENTITY });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: `${this.modelName}/${entity}`,
                        params: filter,
                    })
                    .then(
                        res => {
                            dispatch({
                                type: this.actions.MODEL_ENTITY_RECEIVED,
                                data: res.data,
                                entity,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            console.log('Error', err);
                            reject(err);
                        }
                    );
            });
        };
    }

    postModelEntityByItem(id: string, entity: string, data?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            this.actions.POSTING_MODEL_ENTITY_ITEM = `REQUESTING_${this.modelCaps}_${_.toUpper(entity)}`;
            this.actions.MODEL_ENTITY_ITEM_POST_SUCCESS = `${this.modelCaps}_${_.toUpper(entity)}_SUCCESS`;
            dispatch({ type: this.actions.POSTING_MODEL_ENTITY });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        method: 'POST',
                        url: `${this.modelName}/${id}/${entity}`,
                        data: data,
                    })
                    .then(
                        res => {
                            dispatch({
                                type: this.actions.MODEL_ENTITY_ITEM_POST_SUCCESS,
                                data: res.data,
                                entity,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            console.log('Error', err);
                            reject(err);
                        }
                    );
            });
        };
    }

    postModelEntity(entity: string, data?: object, additionalDispatchData?: object) {
        return (dispatch: Function) => {
            this.actions.POSTING_MODEL_ENTITY = `REQUESTING_${this.modelCaps}_${_.toUpper(entity)}`;
            this.actions.MODEL_ENTITY_POST_SUCCESS = `${this.modelCaps}_${_.toUpper(entity)}_SUCCESS`;
            dispatch({ type: this.actions.POSTING_MODEL_ENTITY });
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        method: 'POST',
                        url: `${this.modelName}/${entity}`,
                        data: data,
                    })
                    .then(
                        res => {
                            dispatch({
                                type: this.actions.MODEL_ENTITY_POST_SUCCESS,
                                data: res.data,
                                entity,
                                additionalDispatchData,
                            });
                            resolve(res);
                        },
                        err => {
                            console.log('Error', err);
                            reject(err);
                        }
                    );
            });
        };
    }
}
