import React, { FC, useState, useEffect, useMemo } from 'react';
import { createStyles, TableCell, Theme, withStyles, WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Dashboard, { DashboardMenuItem } from '../../../Components/Dashboard';
import TabbedScreenContainer from '../../../Components/Dashboard/TabbedScreenContainer';
import DefaultScreen from '../../../Components/Dashboard/DefaultScreen';
import utilities from '../../../Resources/Utils';
import TableScreen from '../../../Components/Dashboard/TableScreen';
import Screen from './Screen';
import CmsForm from './CmsForm';
import { TState } from '../../../RootReducer';
import _ from 'lodash';
import { NameValuePair } from '../../../Models/global.types';
import { CldField } from '../../../Services/CldFields.types';
import { OCldField } from '../../../Features/CldField/redux-config';
import { OApp } from '../../../RootReducer/AppReducer';

export interface CldCMSProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {}

interface IStateProps {
    cldFieldConstants: NameValuePair[];
    groupedCldFields: Record<string, CldField[]>;
}

interface IDispatchProps {
    addCldField: (data: any) => Promise<void>;
    updateCldField: (id: string, data: any) => Promise<void>;
    deleteCldField: (id: string) => Promise<void>;
    showConfirmation: (message: string, onConfirm: () => void) => void;
}

const BASE_PATH = '/cld-dashboard/cms';

const headers = ['English', 'French', 'Spanish'];

const rows = [
    { en: 'Hello', fr: 'Bonjour', de: 'Hallo', ch: '你好' },
    { en: 'Goodbye', fr: 'Au revoir', de: 'Tschüss', ch: '再见' },
    { en: 'How are you?', fr: 'Comment allez-vous?', de: 'Wie geht es Ihnen?', ch: '你好吗？' },
    { en: 'I am fine', fr: '', de: 'Ich bin gut', ch: '我很好' },
    { en: 'I am good', fr: '', de: 'Ich bin gut', ch: '我很好' },
    { en: 'I am bad', fr: 'Je vais mal', de: '', ch: '我很好' },
];

const mockApi = () => new Promise<any[]>(resolve => setTimeout(() => resolve(rows), 1000));

interface FormState {
    open: boolean;
    screen: string;
    formData: any;
    title: string;
}

const defaultFormState: FormState = {
    open: false,
    screen: '',
    formData: {},
    title: 'New Source Type',
};

const sanitizer = (data: Partial<CldField>) => {
    const { id, created, type, updated, ...rest } = data;
    return rest;
};

const isFormValid = (data: Partial<CldField>) => {
    // check if at-least one field is present
    return Object.entries(data).some(([key, value]) => value !== '');
};

const CldCMS: FC<CldCMSProps> = props => {
    const {
        classes,
        cldFieldConstants,
        groupedCldFields,
        addCldField,
        updateCldField,
        deleteCldField,
        showConfirmation,
    } = props;
    const [formState, setFormState] = useState<FormState>({
        ...defaultFormState,
    });

    const renderRow = (row: CldField) => {
        return (
            <>
                <TableCell>{row['name__en']}</TableCell>
                <TableCell>{row['name__fr']}</TableCell>
                <TableCell>{row['name__es']}</TableCell>
            </>
        );
    };

    const handleEdit = (key: string) => (data: any) => {
        const title = `Edit ${utilities.resolveValue(cldFieldConstants, key)}`;
        setFormState(prev => ({ ...prev, title, formData: data, screen: key, open: true }));
    };

    const handleDelete = (key: string) => (row: CldField) => {
        if (!row.id) return;
        showConfirmation(`Are you sure you want to delete?`, () => {
            deleteCldField(row.id);
        });
    };

    const handleAdd = (key: string) => () => {
        const title = `New ${utilities.resolveValue(cldFieldConstants, key)}`;
        setFormState(prev => ({ ...defaultFormState, title, screen: key, open: true }));
    };

    const getData = (key: string) => async () => {
        return groupedCldFields[key] || [];
    };

    const handleSubmit = async (data: Partial<CldField>) => {
        const sanitizedData = sanitizer(data);
        if (!isFormValid(sanitizedData)) return;

        if (data.id) {
            // update
            await updateCldField(data.id, { ...sanitizedData, type: data.type });
        } else {
            // create
            await addCldField({ ...sanitizedData, type: formState.screen });
        }
        handleClose();
    };

    const handleClose = () => setFormState(prev => ({ ...prev, open: false }));

    const MENU_ITEM_NAV_LINKS: DashboardMenuItem[] = useMemo(() => {
        return cldFieldConstants.map(({ name, value: key }) => {
            const path = key;
            return {
                name,
                key,
                path,
                component: (
                    <Screen
                        deps={[groupedCldFields, cldFieldConstants]}
                        getData={getData(key)}
                        handleAdd={handleAdd(key)}
                        handleDelete={handleDelete(key)}
                        handleEdit={handleEdit(key)}
                        headers={headers}
                        renderRow={renderRow}
                    />
                ),
            };
        });
    }, [cldFieldConstants, groupedCldFields]);
    // const MENU_ITEM_NAV_LINKS: DashboardMenuItem[] = [
    //     { name: 'Source Types', path: 'source-types', key: ScreenKey.SOURCE_TYPE, component: <Screen getData={getData(ScreenKey.SOURCE_TYPE)} handleAdd={handleAdd(ScreenKey.SOURCE_TYPE)} handleDelete={handleDelete(ScreenKey.SOURCE_TYPE)} handleEdit={handleEdit(ScreenKey.SOURCE_TYPE)} headers={headers} renderRow={renderRow} /> },
    //     { name: 'Menu 1', path: 'asd2', key: ScreenKey.ASD, component: <Screen getData={getData(ScreenKey.ASD)} handleAdd={handleAdd(ScreenKey.ASD)} handleDelete={handleDelete(ScreenKey.ASD)} handleEdit={handleEdit(ScreenKey.ASD)} headers={headers} renderRow={renderRow} /> },
    // ];

    return (
        <div>
            <Dashboard navs={MENU_ITEM_NAV_LINKS} basePath={BASE_PATH} />
            <CmsForm
                open={formState.open}
                title={formState.title}
                onSubmit={handleSubmit}
                handleClose={handleClose}
                initialData={formState.formData}
                fields={[
                    { label: 'English', key: 'name__en' },
                    { label: 'French', key: 'name__fr' },
                    { label: 'Spanish', key: 'name__es' },
                ]}
            />
        </div>
    );
};

const mapStateToProps = (state: TState) => ({
    cldFieldConstants: _.get(state.App.appConstants, 'CldField.type') || [],
    groupedCldFields: state.CldFields.groupedCldFields,
});

const mapDispatchToProps = dispatch => ({
    addCldField: (data: any) => dispatch(OCldField.addCldField(data)),
    updateCldField: (id: string, data: any) => dispatch(OCldField.updateCldField(id, data)),
    deleteCldField: (id: string) => dispatch(OCldField.deleteCldField(id)),
    showConfirmation: (message: string, onConfirm: () => void) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, () => {})),
});

const STYLES = (theme: Theme) => createStyles({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(CldCMS));
