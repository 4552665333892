import React, { useState } from 'react';
import { Button, CircularProgress, createStyles, Modal, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import THEME from '../../Resources/Theme';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_SAVE } from '../../Features/Translations/translationKeys.cld';



export interface DialogTitleProps extends WithStyles<typeof STYLES>, WithTranslationProps {
    isFormDirty: boolean;
    title: string
    handleSave: () => Promise<void>
    handleClose: () => void
}

const DialogTitle: React.FC<DialogTitleProps> = (props) => {
    const { classes, isFormDirty, title, handleSave, handleClose, getTranslation } = props;

    const saveHandler = useAsyncTask(handleSave);


    const closeDialog = () => {
        handleClose()
        // if (props.isFormDirty) {
        //     props.confirmationDialog("Exit without saving changes?", props.closeDialog, () => null);
        // }
        // else
        //     props.closeDialog();
    }



    const saveChanges = () => {

        // if (!props.validateForm()) {
        //     return;
        // }

        // const progressValidation = validateProgress(formData);
        // setProgressValidation(progressValidation);
        // if (Object.keys(progressValidation.missing).length > 0) setModalOpen(true);
        // if (!progressValidation.isValid) return;

        // if (props.isNewLegalFinding) {
        //     props.createNewLegalFinding();
        //     return;
        // }

        // const id = props.legalFinding ? props.legalFinding.id : '';
        // if (_.isEmpty(id) && !props.isNewLegalFinding)
        //     return;
        // props.saveChanges(id);
    }

    return (
        <div className={classes.dialogTitle}>
            <Button onClick={closeDialog}>
                <i className="material-icons">close</i>
            </Button>
            <Typography style={{ flex: '1 1 0' }}>
                {title}
            </Typography>
            {
                isFormDirty &&
                <Button onClick={saveHandler.run} className={classes.saveBtn}>{
                    saveHandler.status === 'PROCESSING' ? <CircularProgress size={20} /> : getTranslation(GENERAL_SAVE)
                }</Button>
            }

        </div>
    )
}


const STYLES = (theme: Theme) => createStyles({
    dialogTitle: {
        width: '100%',
        background: THEME.Colors.Primary.main,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.shadows[1],
        '& i,  & p': {
            color: 'white'
        }
    },
    saveBtn: {
        background: THEME.Colors.Green,
        color: 'white',
        borderRadius: 0,
        width: 120,
        height: 45,
        '&:hover': {
            background: lighten(THEME.Colors.Green, 0.2)
        }
    },
})

export default withStyles(STYLES)(withTranslations(DialogTitle));
