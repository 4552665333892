import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    FormControl,
    TextField,
    Button,
    ClickAwayListener,
    Card,
    List,
    ListItem,
    Paper,
    Collapse,
    Typography,
    Select,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InlineDatePicker } from 'material-ui-pickers';

import THEME from '../../Resources/Theme';
import moment from 'moment';
import { defaultDate } from '../../screens/LTD/LeftNav';
import utilities from '../../Resources/Utils';
import { ODocuments } from '../../Features/LTDDocuments/redux-config';
import { OCLDDocuments } from '../../Features/CLDDocuments/redux-config';
import Axios, { CancelTokenSource } from 'axios';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    SEARCH_PANEL_DATE_FROM_INPUT_PLACEHOLDER,
    SEARCH_PANEL_DATE_TO_INPUT_PLACEHOLDER,
    SEARCH_PANEL_SEARCH_DECISION_BUTTON,
    SEARCH_PANEL_SEARCH_LEGAL_FINDING_BUTTON,
} from '../../Features/Translations/translationKeys.cld';
import {
    SEARCH_PANEL_FIELDS,
    SEARCH_PANEL_SUBMIT_BUTTON,
    SEARCH_PANEL_THIS_DATE_BUTTON,
} from '../../Features/Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, WithTranslationProps {
    filters?: Array<{
        name: string;
        label: string;
        type?: 'search';
        translationKey?: string;
        options?: Array<{ label: string; key: string }>;
    }>;
    additionalSearch?: boolean;
    type: 'LTD' | 'CLD';
    onSubmit: Function;
    onClear?: Function;
    CLDForm: any;
    LTDForm: any;
}

interface IDispatchProps {
    showMatchedText: Function;
    setDecisionSearch: Function;
    setCLDFormData: (key: string, value: any) => void;
    clearCLDForm: () => void;
    setLTDFormData: (key: string, value: any) => void;
    clearLTDForm: () => void;
}

export const CLD_DATE_FORMAT_DELIMITER = '/';
export const LTD_DATE_FORMAT_DELIMITER = '.';

// const dateFormatDelimiter = type === 'CLD' ? CLD_DATE_FORMAT_DELIMITER : LTD_DATE_FORMAT_DELIMITER;
// const formData =
//     type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;

// const dateFormat = `DD${dateFormatDelimiter}MM${dateFormatDelimiter}YYYY`
// const dateMask = [/[0-9]/, /[0-9]/, dateFormatDelimiter, /[0-9]/, /[0-9]/, dateFormatDelimiter, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
function getDateFormat(type: 'LTD' | 'CLD') {
    const dateFormatDelimiter = type === 'CLD' ? CLD_DATE_FORMAT_DELIMITER : LTD_DATE_FORMAT_DELIMITER;
    return `DD${dateFormatDelimiter}MM${dateFormatDelimiter}YYYY`;
}

class FilterSearch extends Component<Props & IDispatchProps> {
    state = {
        currentTarget: null,
        exactSearch: false,
        suggestions: [],
        formData: {
            // gte: defaultDate.dateFrom,
            // lte: defaultDate.dateTo
        },
    };
    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;

    clearField = () => {
        if (this.props.type === 'LTD') this.props.clearLTDForm();
        else this.props.clearCLDForm();

        if (_.isFunction(this.props.onClear)) this.props.onClear();
    };

    clear = (fieldName: string) => {
        if (this.props.type === 'CLD') this.props.setCLDFormData(fieldName, '');
        else this.props.setLTDFormData(fieldName, '');
    };

    handleChange = e => {
        if (
            e.target.name === 'title' ||
            e.target.name === 'caseName_en' ||
            // || e.target.name === 'externalId'
            e.target.name === 'Document Number'
        )
            this.getSuggestions(e);
        if (this.props.type === 'CLD') this.props.setCLDFormData(e.target.name, e.target.value);
        else this.props.setLTDFormData(e.target.name, e.target.value);
        this.setState({ exactSearch: false });
    };

    handleDateChange = (name, value) => {
        const formData = this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;
        const dateFormat = getDateFormat(this.props.type);
        if (this.props.type === 'LTD') {
            // this.props.setLTDFormData(
            //     'gte',
            //     moment(
            //         _.get(formData, 'gte') ||
            //         // moment(value).subtract('months', 1),
            //         moment(value).startOf('D'),
            //         dateFormat
            //     )
            // );
            // this.props.setLTDFormData(
            //     'lte',
            //     moment(_.get(formData, 'lte') || new Date(), dateFormat).endOf('D')
            // );
            this.props.setLTDFormData(name, moment(value, dateFormat));
        } else {
            // this.props.setCLDFormData(
            //     'gte',
            //     moment(
            //         _.get(formData, 'gte') ||
            //         moment(value).subtract('months', 1),
            //         dateFormat
            //     )
            // );
            // this.props.setCLDFormData(
            //     'lte',
            //     moment(_.get(formData, 'lte') || new Date(), dateFormat)
            // );
            this.props.setCLDFormData(name, moment(value, dateFormat));
        }
    };

    getCLDSuggestions = async e => {
        let params;
        const url = '/clddocs/autocomplete';

        this.timeoutHandle && clearTimeout(this.timeoutHandle);

        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made');
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        params = {
            term: e.target.value,
            field: [e.target.name],
        };

        this.setState({ currentTarget: e.target.name });
        const currentTarget = e.target.name;

        try {
            const res = await Axios.request({
                url,
                params,
                cancelToken: this.ajaxCallHandle.token,
            });
            const suggestions = _.map(res.data, item => item[currentTarget]);
            this.setState({ suggestions });
        } catch (error) {
            throw error;
        }

        // utilities.request({
        //     url,
        //     params: { filter }
        // }).then(
        //     res => {
        //         const suggestions = _.map(res.data, item => item[currentTarget]);
        //         this.setState({ suggestions });
        //     }

        // )
    };

    getSuggestions = async e => {
        e.persist();
        if (this.props.type === 'CLD') return this.getCLDSuggestions(e);

        const params = {
            term: e.target.value,
            field: e.target.name,
        };

        if (_.isEmpty(e.target.value)) return;
        this.setState({ currentTarget: e.target.name });
        const currentTarget = e.target.name === 'title' ? 'metadata_title' : e.target.name;

        // const url = 'ltddocs/search';
        const url = 'ltddocs/autocomplete';

        this.timeoutHandle && clearTimeout(this.timeoutHandle);

        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => { }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made');
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url,
                params,
                cancelToken: this.ajaxCallHandle.token,
            });
            const suggestions = _.map(res.data, item => item[e.target.name]);
            this.setState({ suggestions });
        } catch (error) { }
    };

    handleDatePrefill = async () => {
        const formData = this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;
        const dateFormat = getDateFormat(this.props.type);
        if (_.isEmpty(_.get(formData, 'gte')) && _.isEmpty(_.get(formData, 'lte'))) return;
        if (this.props.type === 'LTD') {
            this.props.setLTDFormData(
                'gte',
                moment(_.get(formData, 'gte') || moment(_.get(formData, 'lte')).subtract('months', 1), dateFormat)
            );
            this.props.setLTDFormData('lte', moment(_.get(formData, 'lte') || new Date(), dateFormat).endOf('D'));
        } else {
            this.props.setCLDFormData(
                'gte',
                moment(_.get(formData, 'gte') || moment(_.get(formData, 'lte')).subtract('months', 1), dateFormat)
            );
            this.props.setCLDFormData('lte', moment(_.get(formData, 'lte') || new Date(), dateFormat));
        }
        // This is important
        await utilities.delay(20);
    };

    handleKeyPress = e => {
        if (e.charCode !== 13) return;
        this.submit();
    };

    handleKeyDown = (e, key) => {
        if (e.charCode !== 13) return;
        const format = getDateFormat(this.props.type);
        const date = moment(e.target.value, format, true);
        if (date.isValid()) {
            const formData = this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;
            this.handleDateChange(key, date);
            switch (key) {
                case 'lte': {
                    const gte = _.get(formData, 'gte');
                    if (_.isEmpty(gte)) break;
                    else if (date.isBefore(gte)) return;
                    break;
                }
                case 'gte': {
                    const lte = _.get(formData, 'lte');
                    if (_.isEmpty(lte)) break;
                    else if (lte.isBefore(date)) return;
                    break;
                }
            }
            setTimeout(() => {
                this.submit();
            }, 150);
        }
    };

    shoudShowClear = () => {
        if (this.props.type === 'CLD') return false;

        const formData = this.props.type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;
        const { filters } = this.props;

        let isFormChanged = false;
        _.forEach(filters, filter => {
            if (!_.isEmpty(_.get(formData, filter.name))) isFormChanged = true;
        });
        if (!_.isEmpty(formData)) isFormChanged = true;
        // if (formData.gte !== defaultDate.dateFrom)
        //     isFormChanged = true;
        // if (formData.lte !== defaultDate.dateTo)
        //     isFormChanged = true;
        return isFormChanged;
    };

    submit = async (shouldPrefillDate: boolean = true) => {
        if (shouldPrefillDate) await this.handleDatePrefill();

        const isLTD = this.props.type === 'LTD';
        const formData = isLTD ? this.props.LTDForm : this.props.CLDForm;
        // const key = isLTD ? 'created' : 'dateCreated';
        const key = 'dateCreated';

        let range = {};
        if (!_.isEmpty(_.get(formData, 'gte')) && !_.isEmpty(_.get(formData, 'lte')))
            range = {
                [key]: {
                    type: 'range',
                    gte: new Date(_.get(formData, 'gte')).getTime(),
                    lte: new Date(_.get(formData, 'lte')).getTime(),
                },
            };
        this.props.showMatchedText(false);
        let newFormData = { ..._.omit(formData, ['gte', 'lte']), ...range };
        this.props.onSubmit(newFormData, this.state.exactSearch);
    };

    closeSuggestion = () => {
        this.setState({ currentTarget: null });
    };

    handleSuggestionSelect = (key: string, value: string) => {
        if (this.props.type === 'LTD') this.props.setLTDFormData(key, value);
        else this.props.setCLDFormData(key, value);

        this.setState({
            currentTarget: null,
            exactSearch: true,
        });
    };

    searchThisDate = () => {
        const { LTDForm: formData, type } = this.props;
        const dateFormat = getDateFormat(this.props.type);
        if (type === 'CLD') return;

        if (_.get(formData, 'gte')) {
            this.props.setLTDFormData('lte', moment(_.get(formData, 'gte'), dateFormat).endOf('D'));
        } else if (_.get(formData, 'lte')) {
            this.props.setLTDFormData('gte', moment(_.get(formData, 'lte'), dateFormat).startOf('D'));
        } else {
            // this.props.setLTDFormData(
            //     'lte',
            //     moment(new Date(), dateFormat).endOf('D')
            // );
            // this.props.setLTDFormData(
            //     'gte',
            //     moment(new Date(), dateFormat).startOf('D')
            // );
            return;
        }
        this.submit(false);
    };

    getSuggestionList = (suggestions, entity) => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={this.closeSuggestion}>
                <Paper className={classes.suggestions}>
                    <List>
                        {_.map(suggestions, suggestion => (
                            <ListItem
                                key={_.uniqueId('suggestions-')}
                                className={classes.suggestionItem}
                                dense
                                onClick={e => {
                                    this.handleSuggestionSelect(entity, suggestion);
                                }}
                            >
                                {suggestion}
                            </ListItem>
                        ))}
                    </List>
                    <div className={classes.clearSuggestion} onClick={this.closeSuggestion}>
                        <Typography>CLOSE SUGGESTIONS</Typography>
                    </div>
                </Paper>
            </ClickAwayListener>
        );
    };

    searchDecisions = () => {
        this.props.setDecisionSearch(true);
        this.submit();
    };

    searchLegalFindings = () => {
        this.props.setDecisionSearch(false);
        this.submit();
    };

    render() {
        const { getTranslation, classes, filters = [], additionalSearch = false, type = 'LTD' } = this.props;
        const { suggestions, currentTarget } = this.state;
        const dateFormatDelimiter = type === 'CLD' ? CLD_DATE_FORMAT_DELIMITER : LTD_DATE_FORMAT_DELIMITER;
        const formData = type === 'LTD' ? this.props.LTDForm : this.props.CLDForm;

        const dateFormat = getDateFormat(type);
        const dateMask = [
            /[0-9]/,
            /[0-9]/,
            dateFormatDelimiter,
            /[0-9]/,
            /[0-9]/,
            dateFormatDelimiter,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ];

        return (
            <div className={classes.root}>
                {_.map(
                    filters,
                    (filter, i) =>
                        filter.options ? (
                            <FormControl key={i} fullWidth className={classes.formItem}>
                                <div className={classes.rowAlt}>
                                    <Select
                                        classes={{
                                            selectMenu: classes.selectMenu,
                                        }}
                                        disableUnderline
                                        fullWidth
                                        displayEmpty
                                        value={_.get(formData, filter.name) || ''}
                                        name={filter.name}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value=''>
                                            <Typography style={{ opacity: 0.5 }}>
                                                {filter.translationKey
                                                    ? getTranslation(filter.translationKey)
                                                    : filter.label}
                                            </Typography>
                                        </MenuItem>
                                        {filter.options.map(option => (
                                            <MenuItem dense key={option.key} value={option.key}>
                                                <Typography>{option.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </FormControl>
                        ) : (
                            <FormControl key={i} fullWidth className={classes.formItem}>
                                <div className={classes.row}>
                                    <TextField
                                        autoComplete='off'
                                        InputProps={{
                                            disableUnderline: true,
                                            className: classes.filterInput,
                                        }}
                                        InputLabelProps={{
                                            className: classes.inputLabel,
                                            placeholder: classes.inputLabel,
                                        }}
                                        placeholder={
                                            filter.translationKey ? getTranslation(filter.translationKey) : filter.label
                                        }
                                        name={filter.name}
                                        onKeyPress={this.handleKeyPress}
                                        onChange={this.handleChange}
                                        value={_.get(formData, filter.name) || ''}
                                    />
                                    {filter.type === 'search' &&
                                        (!_.isEmpty(_.get(formData, filter.name)) ? (
                                            <Button
                                                variant='text'
                                                className={classes.clearField}
                                                onClick={e => this.clear(filter.name)}
                                            >
                                                <i className='material-icons'>close</i>
                                            </Button>
                                        ) : (
                                            <span className={classes.clearField}>
                                                <i className='material-icons'>search</i>
                                            </span>
                                        ))}
                                </div>

                                {!_.isEmpty(suggestions) &&
                                    currentTarget === filter.name &&
                                    this.getSuggestionList(suggestions, filter.name)}
                            </FormControl>
                        )
                    // <div className={classes.clearField}>
                    //     <i className="material-icons">close</i>
                    // </div>
                )}

                <div className={classes.dateField}>
                    <FormControl fullWidth className={classes.formItem}>
                        <InlineDatePicker
                            className={classes.datePicker}
                            disableFuture
                            InputProps={{
                                disableUnderline: true,
                                className: classes.filterInput,
                            }}
                            InputLabelProps={{ className: classes.inputLabel }}
                            autoOk
                            name='gte'
                            mask={dateMask}
                            keyboard
                            fullWidth
                            disableOpenOnEnter
                            onKeyPress={e => this.handleKeyDown(e, 'gte')}
                            views={['year', 'month', 'day']}
                            placeholder={getTranslation(SEARCH_PANEL_DATE_FROM_INPUT_PLACEHOLDER)}
                            minDate={type === 'CLD' ? '07/05/2004' : '01/01/1900'}
                            maxDate={_.get(formData, 'lte') || new Date()}
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format={dateFormat}
                            invalidDateMessage={<InvalidDateMessage type={type} />}
                            value={_.get(formData, 'gte') || null}
                            onChange={date => this.handleDateChange('gte', date.startOf('day').toDate())}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formItem}>
                        <InlineDatePicker
                            className={classes.datePicker}
                            disableFuture
                            InputProps={{
                                disableUnderline: true,
                                className: classes.filterInput,
                            }}
                            InputLabelProps={{ className: classes.inputLabel }}
                            autoOk
                            name='lte'
                            disableOpenOnEnter
                            keyboard
                            fullWidth
                            mask={dateMask}
                            onKeyPress={e => this.handleKeyDown(e, 'lte')}
                            views={['year', 'month', 'day']}
                            invalidDateMessage={<InvalidDateMessage type={type} />}
                            minDate={_.get(formData, 'gte') || defaultDate.dateFrom}
                            maxDate={new Date()}
                            placeholder={getTranslation(SEARCH_PANEL_DATE_TO_INPUT_PLACEHOLDER)}
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format={dateFormat}
                            value={_.get(formData, 'lte') || null}
                            onChange={date => this.handleDateChange('lte', date.endOf('day').toDate())}
                        />
                    </FormControl>
                </div>
                {type === 'LTD' && (
                    <Button color='primary' className={classes.searchDateBtn} onClick={this.searchThisDate}>
                        {getTranslation(SEARCH_PANEL_THIS_DATE_BUTTON)}
                    </Button>
                )}

                <Collapse in={this.shoudShowClear()}>
                    <div className={classes.submit}>
                        <Button color='inherit' variant='text' onClick={this.clearField}>
                            {getTranslation(SEARCH_PANEL_FIELDS || 'CLEAR FIELDS')}
                        </Button>
                    </div>
                </Collapse>

                <div className={classes.submit}>
                    <Button
                        fullWidth
                        color='primary'
                        className={additionalSearch ? classes.buttonAlt : undefined}
                        variant='outlined'
                        onClick={this.searchLegalFindings}
                    >
                        {additionalSearch
                            ? getTranslation(SEARCH_PANEL_SEARCH_LEGAL_FINDING_BUTTON)
                            : getTranslation(SEARCH_PANEL_SUBMIT_BUTTON) || 'SUBMIT'}
                    </Button>
                    {additionalSearch && (
                        // <Tooltip title={formData.ID ? "Please note that a legal finding ID is a valid parameter to search only for legal findings, not for decisions" : ''}>
                        <Button className={classes.buttonAlt} onClick={this.searchDecisions} variant='text'>
                            {getTranslation(SEARCH_PANEL_SEARCH_DECISION_BUTTON)}
                        </Button>
                    )
                        // </Tooltip>
                    }
                </div>
            </div>
        );
    }
}

function InvalidDateMessage(props) {
    const { type = 'CLD' } = props;
    return type === 'CLD' ? <Typography>Format: dd/mm/yyyy</Typography> : <Typography>Format: dd.mm.yyyy</Typography>;
}

const mapStateToProps = state => ({
    CLDForm: _.get(state.CLDDocuments, 'formData'),
    LTDForm: _.get(state.Documents, 'formData'),
});

const mapDispatchToProps = dispatch => ({
    showMatchedText: shouldShow => dispatch(ODocuments.showMatchedText(shouldShow)),
    setDecisionSearch: isDecisionSearch => dispatch(OCLDDocuments.setDecisionSearch(isDecisionSearch)),
    setCLDFormData: (key: string, value: any) => dispatch(OCLDDocuments.setFormData(key, value)),
    setLTDFormData: (key: string, value: any) => dispatch(ODocuments.setFormData(key, value)),
    clearCLDForm: () => dispatch(OCLDDocuments.clearForm()),
    clearLTDForm: () => dispatch(ODocuments.clearForm()),
});

const STYLES = theme =>
    createStyles({
        root: {
            padding: 0,
        },
        filterInput: {
            position: 'relative',
            padding: '0px 10px',
            fontSize: 14,
        },
        inputLabel: {
            padding: '0px 10px',
            fontWeight: 500,
        },
        datePicker: {
            width: 250,
            '& input': {
                width: 250,
            },
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&>div': {
                width: '85%',
            },
        },
        rowAlt: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&>div': {
                padding: '0px 10px',
            },
        },
        formItem: {
            background: THEME.Colors.Grayscale.Grey5,
            marginBottom: 14,
            height: 40,
            // display: 'flex',
            '& > *': {
                // display: 'inline-block'
            },
            justifyContent: 'center',
            '& > div > p': {
                position: 'absolute',
                bottom: -18,
            },
            '& button': {
                // position: 'absolute',
                // right: 20
            },
        },
        clearField: {
            position: 'absolute',
            right: 0,
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            height: 'auto',
            background: 'gray',
            borderRadius: '50%',
            '& i': {
                color: 'white',
                fontSize: 14,
                padding: 4,
            },
        },

        selectMenu: {
            whiteSpace: 'pre-wrap',
            '& p': {
                lineHeight: 1.3,
            },
        },
        dateField: {
            display: 'flex',
            justifyContent: 'space-between',
            '& div': {
                width: 155,
            },
        },
        submit: {
            '& button': {
                marginTop: 8,
            },
        },
        buttonAlt: {
            color: THEME.Colors.Third,
            borderColor: THEME.Colors.Third,
            marginLeft: 'auto',
            display: 'flex',
            fontSize: 12,
        },
        suggestions: {
            position: 'absolute',
            top: 40,
            zIndex: 1000,
            width: 'inherit',
            maxHeight: 300,
            overflow: 'auto',
        },
        clearSuggestion: {
            cursor: 'pointer',
            position: 'sticky',
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: THEME.Colors.Grayscale.Grey4,
            bottom: 0,
            left: 0,
            right: 0,
        },
        suggestionItem: {
            cursor: 'pointer',
            fontSize: 12,
            transition: '240ms ease-in',
            '&:hover': {
                background: THEME.Colors.Fourth,
                color: 'white',
            },
        },
        searchDateBtn: {
            textTransform: 'inherit',
            marginTop: -12,
            padding: 0,
            fontSize: 12,
            textDecoration: 'underline',
            '&:hover': {
                background: 'none',
                textDecoration: 'underline',
            }
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(FilterSearch)));
function SEARCH_PANEL_SUBMIT_BUTTONUBMIT_BUTTON(SEARCH_PANEL_SUBMIT_BUTTONUBMIT_BUTTON: any): React.ReactNode {
    throw new Error('Function not implemented.');
}
