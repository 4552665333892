import React, { FC } from 'react';
import {
    Card,
    Checkbox,
    createStyles,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { TUser } from '../../Features/Authentication/@types';
import { doesRoleInclude, getCLDUserRole } from '../../Features/Authentication/redux-config';

export interface DashboardUserCardProps extends WithStyles<typeof STYLES> {
    user: TUser;
    onRoleChange: (role: string, isChecked: boolean) => void;
}

const DashboardUserCard: FC<DashboardUserCardProps> = props => {
    const { user, classes, onRoleChange } = props;

    const onChange = (event: React.ChangeEvent<{ value: unknown }>, checked: boolean) => {
        onRoleChange(event.target.value as string, checked);
    };

    return (
        <Card className={classes.root}>
            <div>
                <Typography>{user.name}</Typography>
                <Typography variant='caption'>{user.email}</Typography>
            </div>
            <div>
                <FormControlLabel
                    label='Admin'
                    control={
                        <Checkbox
                            color='primary'
                            checked={doesRoleInclude(user.roles, 'CLD_ADMIN')}
                            onChange={onChange}
                            value='CLD_ADMIN'
                        />
                    }
                />
                <FormControlLabel
                    label='Creator'
                    control={
                        <Checkbox
                            color='primary'
                            checked={doesRoleInclude(user.roles, 'CLD_CREATOR')}
                            onChange={onChange}
                            value='CLD_CREATOR'
                        />
                    }
                />
                <FormControlLabel
                    label='Supervisor'
                    control={
                        <Checkbox
                            color='primary'
                            checked={doesRoleInclude(user.roles, 'CLD_SUPERVISOR')}
                            onChange={onChange}
                            value='CLD_SUPERVISOR'
                        />
                    }
                />
            </div>
        </Card>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            padding: '16px 20px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        label: {
            marginRight: '16px',
        },
    });

export default withStyles(STYLES)(DashboardUserCard);
