import { Models } from "../../Resources/Model";
import axios from 'axios';
import { TranslationsService } from "../../Services/Translations.service";
import { OApp } from "../../RootReducer/AppReducer";





class Translation extends Models {
    constructor() {
        super('Translation', {
            GET_STATIC_TRANSLATIONS: 'GET_STATIC_TRANSLATIONS',
            PATCH_STATIC_TRANSLATIONS: 'PATCH_STATIC_TRANSLATIONS',
        })
    }

    getStaticTranslations = () => async dispatch => {
        const { data } = await TranslationsService.getStaticTranslations();
        dispatch({
            type: this.actions.GET_STATIC_TRANSLATIONS,
            data: data[0] || {}
        });
    }

    patchStaticTranslation = (id: string, data: any) => async dispatch => {
        const res = await TranslationsService.patchStaticTranslation(id, data);
        dispatch({
            type: this.actions.PATCH_STATIC_TRANSLATIONS,
            data: res.data
        });
        dispatch(OApp.showToast('Translations updated', 'success'))
    }
}

export let OTranslation = new Translation();