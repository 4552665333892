import React, { Component } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Button, Typography, Checkbox } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../Resources/Theme';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { SectionLabel } from '.';
import _ from 'lodash';
import utilities from '../../Resources/Utils';
import { TranslatedKeywordResponse } from '../../Services/Keyword.types';

interface IProps extends WithStyles<typeof STYLES> {
    legalFinding: TCLDDocument | undefined;
    onEdit: (index?: number) => void;
    formData: Record<string, any>;
    translateKeywords?: (keywordIds: string[], lang: string) => Promise<any>;
    onDelete: (
        index: number | number[],
        key: 'Keyword' | 'Appeal' | 'Opinions' | 'Related Decision'
    ) => Promise<boolean>;
}

class Keywords extends Component<IProps> {
    state = {
        keywords: [],
        selected: [],
    };

    componentDidMount() {
        if (this.props.legalFinding) this.setState({ keywords: this.props.legalFinding.Keyword || [] });
    }

    componentDidUpdate(prevProps) {
        if (this.props.legalFinding && this.props.legalFinding !== prevProps.legalFinding) {
            this.setState({ keywords: this.props.legalFinding.Keyword || [] });
        }
    }

    deleteSelected = async (ids: number[]) => {
        const isSuccess = await this.props.onDelete(ids, 'Keyword');
        if (isSuccess) this.setState({ selected: [] });
    };

    translateKeywords = async () => {
        if (!this.props.translateKeywords) return;
        const lang = _.get(this.props, 'formData.cldFieldLanguageId');
        if (!lang) return;
        const keywordIds: string[] = _.get(this.props, 'legalFinding.keywordIds') || [];
        if (!keywordIds.length) return;
        await this.props.translateKeywords(keywordIds, lang);
        // this.setState({ keywords: resp.transalated || this.state.keywords });
    };

    clearSelection = () => this.setState({ selected: [] });

    selectAll = () => {
        const { keywords = [] } = this.state;
        this.setState({ selected: _.map(keywords, (_, i) => i) });
    };

    toggleSelect = (index: number) => {
        const { selected } = this.state;
        if (_.indexOf(selected, index) > -1) this.setState({ selected: _.filter(selected, i => i !== index) });
        else this.setState({ selected: [...selected, index] });
    };

    render() {
        const { classes } = this.props;
        const { keywords, selected = [] } = this.state;

        return (
            <div id='keywords' className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <SectionLabel title='KEYWORDS' />
                        <div>
                            {selected.length > 0 ? (
                                <>
                                    <Button
                                        color='primary'
                                        variant='text'
                                        style={{ marginRight: 4 }}
                                        onClick={e => this.deleteSelected(selected)}
                                    >
                                        Delete selected
                                    </Button>
                                    <Button
                                        color='primary'
                                        variant='text'
                                        style={{ marginRight: 4 }}
                                        onClick={this.clearSelection}
                                    >
                                        Clear selection
                                    </Button>
                                </>
                            ) : null}
                            {keywords.length > 0 ? (
                                <>
                                    {this.props.translateKeywords ? (
                                        <Button
                                            color='primary'
                                            variant='text'
                                            style={{ marginRight: 4 }}
                                            onClick={e => this.translateKeywords()}
                                        >
                                            Translate
                                        </Button>
                                    ) : null}
                                    <Button
                                        color='primary'
                                        variant='text'
                                        style={{ marginRight: 4 }}
                                        onClick={this.selectAll}
                                    >
                                        Select all
                                    </Button>
                                </>
                            ) : null}
                            <Button onClick={e => this.props.onEdit()} variant='outlined' color='primary'>
                                ADD NEW
                            </Button>
                        </div>
                    </div>
                    <div className={classes.content}>
                        {keywords.map((item, i) => (
                            <KeywordItem
                                checkedIds={selected}
                                key={i}
                                index={i}
                                keyword={item}
                                {...this.props}
                                onCheck={this.toggleSelect}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

interface KeywordItemProps {
    index: number;
    checkedIds: number[];
    onCheck: (index: number) => void;
    keyword: string;
}
const KeywordItem: React.FC<KeywordItemProps & IProps> = props => {
    const { classes, checkedIds = [] } = props;

    return (
        <div className={classes.keywordItem}>
            <div className='flex-row align-start'>
                <div className='flex-row align-start'>
                    <Typography variant='caption' style={{ marginRight: 8 }}>
                        {props.index + 1}
                    </Typography>
                    <Checkbox
                        style={{ padding: '0px 4px' }}
                        onChange={e => props.onCheck(props.index)}
                        checked={_.indexOf(checkedIds, props.index) > -1}
                    />
                </div>
                <Typography variant='caption' style={{ flex: 1 }}>
                    {props.keyword}
                </Typography>
            </div>
            <Button className={classes.deleteButton} onClick={e => props.onDelete(props.index, 'Keyword')}>
                <i className='material-icons'>delete_outline</i>
            </Button>
        </div>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            padding: '40px 0px',
            borderBottom: `1px dashed ${fade('#000', 0.2)}`,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: 680,
            margin: '0 auto',
            // height: 400
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        keywordItem: {
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            // height: 46,
            padding: '12px 15px',
            margin: '12px 0px',
            borderRadius: 4,
            justifyContent: 'space-between',
        },
        deleteButton: {
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            border: `1px solid ${THEME.Colors.Third}`,
            '& i': {
                color: THEME.Colors.Third,
            },
        },
        content: {},
    });

export default withStyles(STYLES)(Keywords);
