import { Reducer } from 'redux';
import { TDetailPageState } from './@types';
import { ODetail } from './redux-config';
import { ONewLegalFinding } from '../AddNewLegalFinding/redux-config';
import _ from 'lodash';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import { setLTDMetadata, HOME_URL } from '../LTDDocuments/redux-config';

const initialState: TDetailPageState = {
    open: false,
    currentDocument: {},
};

export const DetailPage: Reducer<TDetailPageState> = (state = initialState, action): TDetailPageState => {
    const {
        SET_CURRENT_DOCUMENT,
        REMOVE_CURRENT_DOCUMENT,
        OPEN_DOCUMENT,
        CLOSE_DOCUMENT,
        SET_LOADING_DOCUMENT_DETAIL,
        UPDATE_DOCUMENT_ASSIGNED_STATUS,
        DOCUMENT_PATH_RECIEVED,
        LTD_DOCUMENT_RECORDS_UNMERGED,
        LTD_DOCUMENT_RECORDS_MERGED,
        UPDATE_LTD_DOCUMENT_DETAIL,
    } = ODetail.actions;
    const { SAVE_LEGAL_FINDING_CHANGES } = ONewLegalFinding.actions;

    const { LTD_DOCUMENT_CHANGES_SAVED, LTD_DOC_PUBLISH } = ONewLTDDocument.actions;

    switch (action.type) {
        case UPDATE_LTD_DOCUMENT_DETAIL: {
            if (
                action.data.slug === _.get(state.currentDocument, 'documentId') ||
                action.data.id === _.get(state.currentDocument, 'documentId')
            ) {
                return {
                    ...state,
                    currentDocument: {
                        ...state.currentDocument,
                        document: { ...(_.get(state, 'currentDocument.document') || {}), ...action.data },
                    },
                };
            }
            return { ...state };
        }
        case LTD_DOCUMENT_RECORDS_UNMERGED:
        case LTD_DOCUMENT_RECORDS_MERGED: {
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    document: {
                        ..._.get(state.currentDocument, 'document'),
                        proxySlugs: _.get(action.data, 'proxySlugs'),
                    },
                },
            };
        }
        case LTD_DOC_PUBLISH: {
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    document: { ...(_.get(state, 'currentDocument.document') || {}), ...action.data },
                },
            };
        }
        case SET_LOADING_DOCUMENT_DETAIL: {
            return {
                ...state,
                currentDocument: { ...state.currentDocument, documentId: action.data, document: undefined },
            };
        }
        case SAVE_LEGAL_FINDING_CHANGES: {
            if (
                !_.isEmpty(state.currentDocument) &&
                !action.data.isNewLegalFinding &&
                _.get(state.currentDocument, 'document.id') === action.data.id
            )
                return {
                    ...state,
                    currentDocument: {
                        ...state.currentDocument,
                        document: { ..._.get(state.currentDocument, 'document'), ...action.data.data },
                    },
                };
            else return { ...state };
        }
        case LTD_DOCUMENT_CHANGES_SAVED: {
            const doc = _.get(action.data, 'doc');
            if (_.get(state.currentDocument, 'document.id') !== _.get(doc, 'id') || _.isEmpty(doc)) return { ...state };

            const document = { ...doc, metadata: setLTDMetadata(doc, _.get(action, 'data.appConstants')) };
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    document: { ..._.get(state, 'currentDocument.document'), ...document },
                },
            };
        }
        case SET_CURRENT_DOCUMENT: {
            const closeRedirect = _.get(action, 'data.closeRedirect') || HOME_URL().path;
            return { ...state, currentDocument: { ...action.data, closeRedirect } };
        }
        case UPDATE_DOCUMENT_ASSIGNED_STATUS: {
            if (_.get(state.currentDocument, 'document.id') !== _.get(action, 'data.docId')) return state;
            const document = _.get(state.currentDocument, 'document') || {};
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    document: { ...document, isAssigned: action.data.isAssigned },
                },
            };
        }
        case DOCUMENT_PATH_RECIEVED: {
            if (_.get(state.currentDocument, 'document.id') !== _.get(action, 'data.docId')) return state;
            const document = _.get(state.currentDocument, 'document') || {};
            return {
                ...state,
                currentDocument: { ...state.currentDocument, document: { ...document, docPath: action.data.docPath } },
            };
        }
        case REMOVE_CURRENT_DOCUMENT: {
            return { ...state, currentDocument: {} };
        }
        case OPEN_DOCUMENT: {
            return { ...state, open: true };
        }
        case CLOSE_DOCUMENT: {
            return { ...state, open: false };
        }
        default:
            return { ...state };
    }
};
