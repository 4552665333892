import { Models } from "../../Resources/Model";
import utilities from "../../Resources/Utils";
import { OApp } from "../../RootReducer/AppReducer";
import _ from 'lodash';
import { TLTDDialogType, TCLDDialogType } from "../Responsive/@types";




class Responsive extends Models {
    constructor() {
        super('Responsive', {
            LTD_RESPONSIVE_DIALOG_OPEN: 'LTD_RESPONSIVE_DIALOG_OPEN',
            CLD_RESPONSIVE_DIALOG_OPEN: 'CLD_RESPONSIVE_DIALOG_OPEN',
            RESPONSIVE_DIALOG_CLOSE: 'RESPONSIVE_DIALOG_CLOSE',
        });
    }


    openDialog = (dialogFor: TLTDDialogType) => dispatch => dispatch({
        type: this.actions.LTD_RESPONSIVE_DIALOG_OPEN,
        data: dialogFor
    })

    openCLDDialog = (dialogFor: TCLDDialogType) => dispatch => dispatch({
        type: this.actions.CLD_RESPONSIVE_DIALOG_OPEN,
        data: dialogFor
    })

    closeDialog = () => dispatch => dispatch({
        type: this.actions.RESPONSIVE_DIALOG_CLOSE,
    })


}


export let OResponsive = new Responsive();