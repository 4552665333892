import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, Button, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import THEME from '../../Resources/Theme';
import { OAuth } from './redux-config';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps {
    title: string
}

interface IDispatchProps {
    closeDialog?: Function
}

class DialogHeader extends Component<Props> {

    render() {
        const { classes } = this.props
        return (
            <div className={classes.header}>
                <Typography variant="h6">{this.props.title}</Typography>
                <Button variant="text" onClick={e => this.props.closeDialog!()}>
                    <i className="material-icons">close</i>
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OAuth.closeAuthDialog()),
})

const STYLES = (theme: Theme) => createStyles({
    header: {
        height: 44,
        display: 'flex',
        background: theme.palette.primary.main,
        alignItems: 'center',
        paddingLeft: 15,
        justifyContent: 'space-between',
        '& h6': {
            textTransform: 'uppercase',
            color: THEME.Colors.Grayscale.White
        },
        '& i': {
            color: THEME.Colors.Grayscale.White
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DialogHeader))