/**
 * This file has custom made function utilities which can be used anywhere in the code
 */
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import CONFIG from './Config';
import _, { ListIterator } from 'lodash';
import { NameValuePair } from '../Models/global.types';

axios.defaults.baseURL = CONFIG.get('API_URL');
axios.defaults.headers.post['Content-Type'] = 'application/json';
const cookies = new Cookies();
const IMAGE_KIT_URL = 'https://mithya.imagekit.io/stayuncle/';
export const setRequestAuthHeader = (accessToken: string) => {
    axios.defaults.headers.common['Authorization'] = accessToken;
};
let userAccessToken = cookies.get('access_token');
// console.log('User access token', userAccessToken);
if (userAccessToken) setRequestAuthHeader(userAccessToken);
let currentUser: object;
let utilities = {
    getErrorText(error: any) {
        const _error = _.get(error, 'response.data.error.message') || 'Something went wrong!';
        return _error;
    },
    formatLegalFinding: data => {
        const pattern = /\n/g;
        const _data = _.replace(_.trim(data, '\n'), pattern, '<br/>');
        const pattern2 = /\t/g;
        const _data2 = _.replace(_data, pattern2, '&nbsp;&nbsp;&nbsp;&nbsp;');
        return _data2;
    },
    validatePageParaFormat: (value: string) => {
        if (!value || _.isEmpty(value)) return true;
        const values = value.split(';').map(s => s.trim());
        const regex1 = /^\d\d\d\d-\d\d\d\d$/i;
        const regex2 = /^\d\d\d\d$/i;
        const isValid = values
            .map(v => regex1.test(v) || regex2.test(v))
            .reduce((prevVal, curVal, i, _) => prevVal && curVal);
        return isValid;
    },
    isAdmin: user => {
        if (user && user.roles) return _.includes(user.roles, 'ADMIN');
        return false;
    },
    delay: (ms: number) => {
        return new Promise(res =>
            setTimeout(() => {
                res(true);
            }, ms)
        );
    },
    copyLink: (value: string) => {
        // const el = document.createElement('textarea');
        // el.value = url
        // el.setAttribute('readonly', '');
        // el.style.position = 'absolute';
        // el.style.left = '-9999px';
        // document.body.appendChild(el);
        // el.select();
        // document.execCommand('copy');
        // document.body.removeChild(el);
        if (window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText)
            window.navigator.clipboard.writeText(value);
        else {
            const el = document.createElement('textarea');
            el.value = value;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    },
    findValues: (
        superSet: Array<Object>,
        subSet: string | Array<string>,
        findKey: string = 'value',
        mapKey: string = 'name',
        isReturnItem: Boolean = false
    ) => {
        // findKey = findKey || 'value';
        // mapKey = mapKey || 'name';
        if (_.isString(subSet)) subSet = [subSet];
        let filteredValues = _.filter(superSet, (item: any) => {
            return _.indexOf(subSet, item[findKey]) !== -1;
        });
        if (isReturnItem) return filteredValues;
        return _.map(filteredValues, mapKey);
    },
    multiUpdateList: <T = any>(listToUpdate: T[], updates: T[], compareKey: string = 'id') => {
        const updatesKey = updates.map(item => _.get(item, compareKey));
        const updatedList = listToUpdate.map(item => {
            if (updatesKey.includes(_.get(item, compareKey))) {
                // const updateItem = _.find(updates, { [compareKey]: item[compareKey] });
                const updateItem = _.find(
                    updates,
                    updateItem => _.get(updateItem, compareKey) === _.get(item, compareKey)
                );
                debugger;
                return { ...item, ...updateItem };
            } else return item;
        });
        return updatedList;
    },
    updateItemList: (list: Array<any>, item: any, action: string) => {
        list = list || [];
        let newList = list.slice();
        let itemIndex;
        if (action === 'UPDATE') {
            itemIndex = _.findIndex(newList, { id: item.id });
            if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
            return newList;
        } else if (action === 'ADD') {
            newList.unshift(item);
            return newList;
        } else if (action === 'DELETE') {
            console.log('Deleting item', item);
            console.log(_.remove(newList, { id: item.id }));
            return newList;
        }
        return newList;
    },
    getCurrentUser: () => {
        const currentUserId = utilities.getUserId();
        if (_.isEmpty(currentUser) && currentUserId) {
            return new Promise((resolve, reject) => {
                utilities
                    .request({
                        url: `users/${currentUserId}`,
                        params: {
                            filter: {
                                include: ['city'],
                            },
                        },
                    })
                    .then(
                        res => {
                            currentUser = res.data;
                            resolve(currentUser);
                        },
                        err => reject(err)
                    );
            });
        } else if (!_.isEmpty(currentUser)) return Promise.resolve(currentUser);
        return Promise.reject();
    },
    resolveValue: (list: NameValuePair[], value: string) => {
        const item = _.find(list, { value });
        return item ? item.name : value;
    },
    saveUser: (accessToken, userId) => {
        if (!accessToken) return;
        userId = userId || '';
        cookies.set('access_token', accessToken, {
            path: '/',
        });
        cookies.set('userId', userId, {
            path: '/',
        });
        setRequestAuthHeader(accessToken);
        // utilities.getCurrentUser();
    },
    setLocale: (locale: string) => {
        cookies.set('locale', locale, {
            path: '/',
        });
    },
    getLocale: () => {
        return cookies.get('locale') || 'en';
    },
    setIsSccVisited: () => {
        cookies.set('isSccVisited', true, {
            path: '/',
        });
    },
    isSccVisited: () => {
        return cookies.get('isSccVisited');
    },
    clearCookies: () => {
        cookies.remove('access_token');
        cookies.remove('userId');
        cookies.remove('locale');
        setRequestAuthHeader('');
    },
    referral: referral => {
        if (!referral) {
            return cookies.get('referral');
        }
        cookies.set('referral', referral);
    },
    removeReferral: () => {
        cookies.remove('referral');
    },
    isAuthenticated: () => {
        return !!cookies.get('access_token');
    },
    getUserId: () => {
        return cookies.get('userId');
    },
    getAccessToken: () => {
        return cookies.get('access_token');
    },
    logoutUser: async () => {
        cookies.remove('access_token', { path: '/' });
        cookies.remove('userId', { path: '/' });
        try {
            const res = utilities.request({
                url: '/users/logout',
                method: 'post',
            });
            window.location.reload();
            return res;
        } catch (error) {
            throw error;
        }
    },
    sequentialPromises: (items, asyncFunc, onEachItem) => {
        if (!_.isArray(items) || !_.isFunction(asyncFunc)) return Promise.reject('No item or method provided');
        return items.reduce(
            (previous, current) =>
                previous.then(accumulator =>
                    asyncFunc(current).then(result => {
                        if (_.isFunction(onEachItem)) onEachItem(current, result);
                        return accumulator.concat(result);
                    })
                ),
            Promise.resolve([])
        );
    },
    request: <T = any>(config: AxiosRequestConfig) => {
        return axios.request<T>(config);
    },
    requestAll: (configs, callback) => {
        if (!_.isArray(configs)) configs = [configs];
        let requests = _.map(configs, config => {
            return utilities.request(config);
        });
        return axios.all(requests);
    },
    spreadReqResponses: axios.spread,
    uploadFile: <T = any>(url, file, fileName?, fileKeyName?) => {
        console.log('my files', file);

        var formData = new FormData();
        if (_.isArray(file)) {
            _.map(file, (value, key) => {
                formData.append(String(key), value);
            });
        } else {
            formData.append(fileKeyName || 'file', file);
            if (fileName) formData.append('name', fileName);
        }

        return axios.post<T>(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    getPictureUrl: (picture, width, height) => {
        if (!picture) return;
        let urlPrefix: string;

        urlPrefix = picture.ik_url;
        let transformationAttr = ['q-60'];
        if (width > 0) transformationAttr.push(`w-${width}`);
        if (height > 0) transformationAttr.push(`h-${height}`);
        urlPrefix += `?tr=${transformationAttr.join(',')}`;
        /* if (width > 0)
            urlPrefix += 'tr:w-' + width;
        if (height > 0)
            urlPrefix += ',h-' + height;
        urlPrefix += ',q-60';
        urlPrefix += picture.imagePath; */

        // console.log('imageUrl: ' + urlPrefix);
        return urlPrefix;
    },
    compileString: (string, valueObj) => {
        _.map(valueObj, (value, key) => {
            let regex = new RegExp('\\$\\{' + key + '}', 'g');
            string = string.replace(regex, value);
        });

        return string;
    },
    analytics: {
        pageView: url => {
            if (!url) return;
            // if (window.ga) {
            //     window.ga('send', 'pageview', url);
            // }
        },
        ecommerce: (type, data) => {
            // window.ga(type, data);
            // window.ga('ecommerce:send');
            // console.log(type, data)
        },

        ecommerceCheckout: (type1, type2, data) => {
            // window.ga(type1, type2, data);
            // window.ga('send', 'pageview');
        },

        event: data => {
            if (!data.action || !data.category) return;
            // if (window.ga) {
            //     window.ga('send', 'event', data.cateogry, data.action, data.label);
            //     // console.log('my analytics request', 'send', 'event', data.category, data.action, data.label)
            // }
        },
    },
};

export default utilities;
