import { OAuth } from "./redux-config";
import { Reducer } from "redux";
import { TAuthState, TDialogVariant, TUser } from "./@types";
import { object } from "prop-types";
import _ from 'lodash'
import { getUserPermissions, DEFAULT_CLD_PERMISSIONS, DEFAULT_LTD_PERMISSIONS, DEFAULT_SCC_PERMISSIONS } from "./Permissions";
import { utilities } from "../../Resources/Model";


const USER = {
    name: '',
    email: '',
    id: '',
    roles: [],
}

const initalState: TAuthState = {
    user: USER,
    authDialog: {
        open: false,
        variant: '' as TDialogVariant,
    },
    cldPermissions: DEFAULT_CLD_PERMISSIONS,
    ltdPermissions: DEFAULT_LTD_PERMISSIONS,
    sccPermissions: DEFAULT_SCC_PERMISSIONS,
};
export const Auth: Reducer<TAuthState> = (state = initalState, action): TAuthState => {
    const {
        OPEN_AUTH_DIALOG,
        CLOSE_AUTH_DIALOG,
        SINGLE_ITEM_RECEIVED,
        USER_LOGGED_OUT,
        LTD_ASSIGNED_NODES_FETCHED,
        USER_DETAIL_UPDATED
    } = OAuth.actions;

    switch (action.type) {
        case USER_DETAIL_UPDATED: {
            return { ...state, user: { ...state.user, ...action.data } }
        }
        case USER_LOGGED_OUT: {
            return {
                ...state,
                user: USER,
                ltdPermissions: DEFAULT_LTD_PERMISSIONS,
                cldPermissions: DEFAULT_CLD_PERMISSIONS,
                sccPermissions: DEFAULT_SCC_PERMISSIONS,
            };
        }
        case SINGLE_ITEM_RECEIVED: {
            const user: TUser = {
                ...action.data,
                roles: (_.get(action.data, 'roles') || []).map(role => role.name),
            }
            return { ...state, user, ...getUserPermissions(user) }
        }
        case OPEN_AUTH_DIALOG: {
            return { ...state, authDialog: { open: true, variant: action.data } }
        }
        case CLOSE_AUTH_DIALOG: {
            return { ...state, authDialog: initalState.authDialog }
        }
        default: return state;
    }
}