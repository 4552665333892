import React, { Component, Fragment } from 'react'
import {
    createStyles, WithStyles, InputLabel, withStyles, DialogTitle, Typography, Button, CircularProgress, TextField,
    FormControl, MenuItem, Select, FormHelperText, Fab
} from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from '../../Resources/Theme';
import { TState } from '../../RootReducer';
import { TCLDAvdancedSearchOperator } from './@types';
import { OAdvancedSearch } from './redux-config';


interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
    // onClick: Function,
    isLastEntry?: boolean,
    onChange: Function,
    addQuery: () => void
    removeQuery: (index: number) => void
    query: object,
    index: number
}

interface IStateProps {
    facets: object
    operator: TCLDAvdancedSearchOperator
}
interface IDispatchProps {
    setOperator: (value: TCLDAvdancedSearchOperator) => void
}









class QueryBuilder extends Component<Props> {
    state = {
        formData: {
            queryType: 'allContent',
            operation: 'AND',
            _in: ''
        },
    }

    queryTypes = [
        { name: 'Full text', value: 'allContent' },
        { name: 'Title', value: 'Title' },
        { name: 'Situation name', value: 'Situation name' },
        { name: 'Case name', value: 'Case name' },
        { name: 'Document number', value: 'Document number' },
    ]

    operations = [
        { name: 'and', value: 'AND' },
        { name: 'or', value: 'OR' },
        { name: 'not', value: 'NOT' },
    ]


    iccSituations = [
        { name: 'The Republic of Kenya', value: 'Situation in the Republic Of Kenya' },
        { name: 'Libyan Arab Jamahiriya', value: 'Situation in libyan Arab Jamahiriya' },
        { name: 'The Republic of Cote d\\\'Ivoire', value: 'Situation in the Republic of Cote d\\\'Ivoire' },
        { name: 'Darfur, Sudan', value: 'Situation in Darfur, Sudan' },
        { name: 'The Central African Republic', value: 'Situation in the Central African Republic' },
        { name: 'The Central African Republic II', value: 'Situation in the Central African Republic II' },
        { name: 'The Democratic Republic of the Congo', value: 'Situation in the Democratic Republic of the Congo' },
        { name: 'The Republic of Mali', value: 'Situation in the Republic of Mali' },
        { name: 'Uganda', value: 'Situation in Uganda' },
        { name: 'Registered Vessels of the Union of the Comoros', value: 'Situation in registered Vessels of the Union of the Comoros' },
        { name: 'Libya', value: 'Situation in Libya' },
    ]


    contentTypes: Array<any> = [
    ]

    operatorOptions: TCLDAvdancedSearchOperator[] = [
        'AND',
        'OR',
        'NOT'
    ]



    componentDidMount = () => {
        this.setState({ formData: { ...this.state.formData, ...this.props.query } })
        if (!_.isEmpty(this.props.facets)) {
            _.map(_.get(this.props.facets, 'metadata_content_type'), _contentType => {
                this.contentTypes.push({ name: _contentType.key, value: _contentType.key })
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.query !== this.props.query)
            this.setState({ formData: this.props.query });
    }

    handleKeyPress = () => {

    }

    handleChange = (e) => {
        const formData = { ...this.state.formData, [e.target.name]: e.target.value };
        this.setState({ formData });
        this.props.onChange(this.props.index, formData);
    }

    changeOperator = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.setOperator(event.target.value as TCLDAvdancedSearchOperator);
    }

    getGetOperatorSelector = () => {
        const { classes, index, operator } = this.props;
        if (!index) return null;
        return (
            <div className={classes.operatorSelect}>
                {
                    index === 1 ?
                        <Select
                            disableUnderline
                            value={operator}
                            onChange={this.changeOperator}
                        >
                            {this.operatorOptions.map(o => (
                                <MenuItem key={o} value={o}>{o}</MenuItem>
                            ))}
                        </Select> :
                        <Typography>{operator}</Typography>
                }
            </div>
        )
    }




    render() {
        const { classes, isLastEntry, facets, index, operator } = this.props;
        const { formData } = this.state;
        const { queryType = 'allContent', operation, _in } = formData;

        return (
            <>

                <div className={classes.root}>

                    <div className={classes.mainContainer}>
                        {this.getGetOperatorSelector()}
                        <FormControl className={classes.selectControl}>
                            <Select
                                value={queryType || 'allContent'}
                                onChange={this.handleChange}
                                name="queryType"
                            // disableUnderline
                            >
                                {
                                    _.map(this.queryTypes, type => (
                                        <MenuItem key={type.value} value={type.value}>{type.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <div className={classes.queryContainer}>

                            {
                                (queryType !== 'lt_contenttype' && queryType !== 'situation') ?
                                    <FormControl className={classes.inputControl}>
                                        <Typography variant="subtitle1">Query</Typography>
                                        <TextField
                                            fullWidth
                                            // InputProps={{ disableUnderline: true }}
                                            // placeholder="Search"
                                            name='query1'
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                            value={_.get(formData, 'query1') || ''}
                                        />
                                    </FormControl>
                                    :
                                    <FormControl className={classes.selectControl}>
                                        <Select
                                            value={_in || ''}
                                            displayEmpty
                                            className={classes.selectAlt}
                                            onChange={this.handleChange}
                                            name="_in"
                                        >
                                            {
                                                _.map(queryType === 'situation' ? this.iccSituations : this.contentTypes, type => (
                                                    <MenuItem key={type.value} value={type.value}>{type.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                            }


                            {
                                (queryType === 'allContent' || queryType === 'title') &&
                                <Fragment>
                                    <FormControl className={classes.inputControl}>
                                        <Select
                                            style={{ marginRight: 8 }}
                                            value={operation || 'AND'}
                                            onChange={this.handleChange}
                                            name="operation"
                                        >
                                            {
                                                _.map(this.operations, type => (
                                                    <MenuItem key={type.value} value={type.value}>{type.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <TextField
                                            fullWidth
                                            name='query2'
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                            value={_.get(formData, 'query2') || ''}
                                        />
                                    </FormControl>
                                </Fragment>
                            }



                            {
                                queryType === 'allContent' && operation !== 'OR' &&
                                <FormControl className={classes.inputControl}>
                                    <Typography align="right" variant="subtitle1" className={classes.proximityLabel}>Proximity of words</Typography>
                                    <TextField
                                        className={classes.proximity}
                                        name='proximity'
                                        type="number"
                                        onKeyPress={this.handleKeyPress}
                                        onChange={this.handleChange}
                                        value={_.get(formData, 'proximity') || ''}
                                    />
                                </FormControl>
                            }
                        </div>
                    </div>
                    {
                        isLastEntry && index === 0 ?
                            null :
                            <Fab className={classes.fabRemove} color="secondary" size="small" onClick={e => this.props.removeQuery(index)}>
                                <i className="material-icons">remove</i>
                            </Fab>
                    }
                </div>
                {
                    isLastEntry ?
                        <Fab className={classes.fab} color="secondary" size="small" onClick={this.props.addQuery}>
                            <i className="material-icons">add</i>
                        </Fab> : null
                }
            </>
        )
    }
}

const mapStateToProps = (state: TState): IStateProps => ({
    facets: _.get(state.Documents, 'facets'),
    operator: _.get(state.AdvancedSearch, 'operator')
})

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    setOperator: (value) => dispatch(OAdvancedSearch.setOperator(value)),
})

const STYLES = theme => createStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column'
        }
    },
    operatorSelect: {
        width: 60,
        border: `2px solid ${Theme.Colors.Primary.light}`,
        borderRadius: 20,
        height: 32,
        minWidth: 60,
        paddingLeft: 6,
        paddingRight: 4,
        '& p': {
            lineHeight: '32px',
            color: 'black',
            fontSize: 16,
        }
        // background: Theme.Colors.Fourth
    },
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: 4,
        boxSizing: 'border-box',
        marginLeft: 16,
        [theme.breakpoints.down('sm')]: {
            border: `1px solid ${Theme.Colors.Secondary.main}`,
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            width: '95%'
        },
    },
    fab: {
        background: 'transparent',
        margin: '20px 0px 0px 24px',
        minWidth: 40,
        '&:hover': {
            color: 'white'
        }
    },
    fabRemove: {
        background: 'transparent',
        marginRight: 8,
        minWidth: 40,
        marginLeft: 9,
        '&:hover': {
            color: 'white'
        }
    },
    selectControl: {
        width: 240,
        margin: '0px 8px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    proximityLabel: {
        [theme.breakpoints.up('sm')]: {
            flex: 2
        },
    },
    inputControl: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0px 8px',
        '& h6': {
            marginRight: 6,
            fontWeight: 500
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    queryContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100vw',
            '& > div': {
                margin: 8
            }
        }
    },
    selectAlt: {
        width: 280
    },
    proximity: {
        // width: 60,
        [theme.breakpoints.up('sm')]: {
            flex: 1
        },
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(QueryBuilder))