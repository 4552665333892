import React, { FC, createRef, useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, ClickAwayListener, Dialog, DialogContent } from '@material-ui/core';
import classNames from 'classnames';
import InputTypes, { InputTypesProps } from './InputTypes';
import _ from 'lodash';
import utilities from '../../Resources/Utils';
import './input-styles.css';


export interface CellProps extends Omit<InputTypesProps, 'classes'>, WithStyles<typeof STYLES> {
    displayValue?: string
}

const Cell: FC<CellProps> = (props) => {
    const { classes, inputConfig, formData, displayValue } = props;
    const ref = createRef<HTMLDivElement>();
    const [focused, setFocused] = useState(false);
    const [isInputMode, setIsInputMode] = useState(false);


    // document.body.addEventListener('keydown', async (ev) => {
    //     if (focused && !isInputMode) {
    //         // if (ev.keyCode === 32) {
    //         //     setIsInputMode(true);
    //         //     console.log("expand")
    //         // }
    //         if ((ev.metaKey || ev.ctrlKey) && ev.keyCode === 67) {   // ctrl + c
    //             console.log("copy value");
    //             navigator.clipboard.writeText(formData[inputConfig.key] || '')
    //         }
    //         else if ((ev.metaKey || ev.ctrlKey) && ev.keyCode === 86) {    // ctrl + v
    //             console.log("paste value");
    //             const _clipboardData = await navigator.clipboard.readText();
    //             inputConfig.onChange({ target: { name: inputConfig.key, value: _clipboardData } })
    //         }
    //     }

    // })

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.addEventListener('click', (ev) => {
                setFocused(true);
            });
            ref.current.addEventListener('dblclick', (ev) => {
                setIsInputMode(true);
            })

        }
    }, [])

    const handleClickAway = () => {
        if (isInputMode) return;
        focused && setFocused(false);
    }


    return (
        <div ref={ref} className={classNames(classes.root)}>
            <InputTypes formData={formData} inputConfig={inputConfig} />
        </div>
    )


    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <>
                <div ref={ref} className={classNames(classes.root, focused ? classes.highlight : '')}>
                    {_.truncate(displayValue, { length: 60 })}
                </div>
                <Dialog scroll="body" classes={{ paperScrollBody: classes.dialogContainer }} open={isInputMode} onClose={() => setIsInputMode(false)}>
                    <DialogContent>
                        <InputTypes formData={formData} inputConfig={inputConfig} />
                    </DialogContent>
                </Dialog>
            </>
        </ClickAwayListener >
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        cursor: 'pointer',
        // boxSizing: 'border-box',
        padding: '8px',
        minHeight: 40,
    },
    highlight: {
        border: `1px solid blue`
    },
    dialogContainer: {
        width: 500,
        maxWidth: 'unset',
    }
});

export default withStyles(STYLES)(Cell);