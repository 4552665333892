import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Fab, CircularProgress, LinearProgress } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import _ from 'lodash';
import THEME from '../../../Resources/Theme';
import { TSearchResult, TDocument } from '../../../Features/LTDDocuments/@types';
import { OApp } from '../../../RootReducer/AppReducer';
import LTDMetadata from '../../DetailPage/LTDMetadata';
import { setLTDMetadata, DOCUMENT_BASE_URL } from '../../../Features/LTDDocuments/redux-config';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { TLog } from '../../../Features/LtdDashboard/@types';


interface IStateProps {
    /* document?: TDocument
    title: string */
    appConstants: any
}

interface IDispatchProps {
    closeSidebar?: () => void,
    updateDocPath?: (logId: string, parentIds: string[], updateDocPathKey?: string) => any
}

interface IState {
    document?: TDocument
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
    onBack?: () => void,
    document?: TDocument,
    title?: string,
    log?: TLog,
    pathIds?: string[],
    docPathInLog?: string,
    displayNavigation?: boolean
}

class DocumentDetail extends Component<IProps, IState> {
    state = {
        document: {} as TDocument
    }
    setDocPath = async () => {
        const { log, pathIds, docPathInLog } = this.props;
        const { document } = this.state;
        if (!log || !pathIds || !_.isEmpty(_.get(document, 'docPath')))
            return;
        if (!this.props.updateDocPath)
            return;
        const docPath = await this.props.updateDocPath(log.id, pathIds, docPathInLog);
        if (_.isEmpty(docPath))
            return;
        this.setState({ document: { ...this.state.document, docPath: docPath } });
    }
    componentDidMount() {
        const { document } = this.props;
        this.setState({ document });
        this.setDocPath();
    }
    componentDidUpdate(prevProps, prevState) {
        const currentDoc = this.props.document;
        const prevDoc = prevProps.document;
        if (_.get(currentDoc, 'id') === _.get(prevDoc, 'id'))
            return;
        this.setState({ document: currentDoc }, () => {
            this.setDocPath();
        });


    }


    handleBackButton = () => {
        if (!this.props.onBack)
            return;
        this.props.onBack();
    }

    closeSidebar = () => {
        if (this.props.closeSidebar)
            this.props.closeSidebar();
    }

    render() {
        const { classes, title, appConstants, displayNavigation = true } = this.props;
        let { document } = this.state;
        if (!document || !document.title)
            return <div />;
        let metadata = setLTDMetadata(document, appConstants);
        if (!document.slug) {
            _.remove(metadata, { name: 'Persistent URL' });
        }

        document.metadata = metadata;
        document.isAssigned = true;
        const documentSlug = (document.slug) ? `${DOCUMENT_BASE_URL}${(document.slug || document.id)}` : null;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div>
                        <Fab size="small" onClick={this.handleBackButton}><i className="material-icons">chevron_left</i></Fab>
                        <Typography>{_.truncate(title, { length: 60 })}</Typography>
                    </div>
                    <Fab size="small" onClick={this.closeSidebar}>
                        <i className="material-icons">close</i>
                    </Fab>
                </div>
                <div className={classes.listContainer}>
                    <div className={classes.actionContainer}>
                        {
                            documentSlug &&
                            (<Button href={documentSlug} target="_blank">View Original</Button>)
                        }
                    </div>
                    {
                        document ?
                            <LTDMetadata classes={{ root: classes.metadataContainer }} data={document} isDisplayNav={displayNavigation} /> :
                            <LinearProgress color="secondary" />
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.App, 'appConstants')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    updateDocPath: (logId, parentIds, updateDocPathKey) => dispatch(OLtdDashboard.updateLogDocPath(logId, parentIds, updateDocPathKey))
})


const CONTAINER_WIDTH = 520;

const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box',
        background: THEME.Colors.Grayscale.Grey5
    },
    header: {
        position: 'fixed',
        width: CONTAINER_WIDTH,
        right: 0,
        display: 'flex',
        height: 51,
        zIndex: 1,
        alignItems: 'center',
        '& p': {
            color: 'white'
        },
        background: THEME.Colors.Grayscale.Grey1,
        '& > div:first-child': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            boxShadow: 'none',
            background: 'transparent',
            '&:hover': {
                background: 'black'
            }
        },
        '& i': {
            color: 'white'
        }
    },
    actionContainer: {
        marginTop: 10,
        textAlign: 'right',
        padding: '0 20px'
    },
    listContainer: {
        marginTop: 51,
        // padding: '20px 40px',

    },
    metadataContainer: {
        width: CONTAINER_WIDTH,
        boxSizing: 'border-box'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DocumentDetail))