const contactUsEs = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
	<div id="c258" class="csc-default">
	<!--  Header: [begin] -->
		<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Contáctenos</h1></div>
	<!--  Header: [end] -->
		
	<!--  Text: [begin] -->
		<p  class="bodytext">
        Se puede contactar con la Base de Datos de Herramientas Jurídicas de la CPI (‘LTD’) en la dirección 
        <a href="mailto:info@cilrap.org">info@cilrap.org</a>. </p>
		<p  class="bodytext">
        Se puede utilizar el botón  “Report Issue” (Informar sobre una cuestión) en la parte superior derecha del menú de la LTD para optar directamente por lo siguiente: 
         </p>
		<ul>
			<li>informar de un error o sugerir una mejora; </li>
			<li> sugerir la incorporación de un documento a la LTD;</li>
			<li>o sugerir una corrección de los metadatos de un registro existente en la LTD.</li>
		</ul>
	<!--  Text: [end] -->
		</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
	</div>`;
export default contactUsEs;
