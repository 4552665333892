import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Typography, Button, Fab } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import THEME from '../../Resources/Theme';
import PageContent from './page-content';
import LTDFooter from '../../Components/Footer/LTDFooter';
import MetaTag from '../../Components/MetaTag';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';

interface IProps extends WithStyles<typeof STYLES>, RouteComponentProps<any>, WithTranslationProps { }


class StaticPage extends Component<IProps> {

    handleClose = () => {
        if (this.props.history.length > 0)
            this.props.history.goBack();
        else
            this.props.history.push('/');
    }

    render() {
        const { classes, locale } = this.props
        const pathName = this.props.location.pathname.replace(/\/$/, "");
        const htmlContent = _.get(PageContent, `${pathName}.content-${locale}`) || _.get(PageContent, `${pathName}.content`) || '<div/>';
        const pageTitle = _.get(PageContent, `${pathName}.title-${locale}`) || _.get(PageContent, `${pathName}.title`) || ''

        return (
            <div className={classes.root}>
                <MetaTag title={pageTitle} />
                <div className={classes.container}>
                    <Fab size="small" className={classes.closeButton} onClick={this.handleClose}>
                        <i className="material-icons">close</i>
                    </Fab>
                    {/* <iframe src={htmlContent} /> */}
                    <Typography component="div" gutterBottom variant="body1" dangerouslySetInnerHTML={{ __html: htmlContent }}></Typography>
                    <LTDFooter />
                </div>
            </div>
        )
    }
}



function getPageData() {

}



const STYLES = (theme: Theme) => createStyles({
    root: {
        height: '100vh',
        paddingTop: 50,
        background: THEME.Colors.Grayscale.Grey5,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 2,
        position: 'absolute',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: 24,
            },
            '& p, & li, & td': {
                fontSize: 12
            },
        }
    },
    container: {
        padding: '0px 20px',
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        boxShadow: `0px 3px 6px ${fade(theme.palette.common.black, 0.14)}`,
        '& #breadcrumbs,#getUrlText': {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            width: 1000,
            position: 'relative',
            margin: '0 auto 160px auto',
            borderRadius: 4,
            padding: '80px 100px',
        }
    },
    ul: {
        '& li': {
            margin: '0 4px'
        },
        '& li > p': {
            display: 'inline'
        },
        '& li > p:nth-child(1)': {
            marginRight: 6
        }
    },
    closeButton: {
        position: 'absolute',
        right: 20,
        top: 20,
        boxShadow: 'none',
        color: THEME.Colors.Grayscale.Black,
        background: THEME.Colors.Grayscale.White,
        [theme.breakpoints.down('sm')]: {
            top: 52,
            right: 0
        }
    }
})

export default withStyles(STYLES)(withTranslations(StaticPage))