import React, { useState, useContext, useEffect } from 'react'
import { ThemeContext } from '../Contexts/ThemeContext';
import { AppContext } from '../Contexts/AppContext/index';

export type useThemerConfig = {}

export const useThemer = (config?: useThemerConfig) => {
    const { setAppTheme } = useContext(ThemeContext);
    const { context } = useContext(AppContext);

    useEffect(() => {
        setAppTheme(context)
    }, [context])
}