import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, ClickAwayListener, Paper, List, ListItem, Typography, FormControl, Input, Button, InputLabel, Popper, Fade, Popover } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import utilities from '../../Resources/Utils';
import THEME from '../../Resources/Theme';
import Axios, { CancelTokenSource } from 'axios';




interface Props extends WithStyles<typeof STYLES> {
    objectValue?: boolean
    disableAdd?: boolean
    disabled?: boolean
    suggestionUrl: string
    label: string
    placeholder?: string
    disableUnderline?: boolean
    searchKey?: string
    formKey: string
    value?: string
    onChange: ({ name: string, value: any }) => void
    onSelect?: ({ name: string, value: any }) => void
    filter?: (value: string) => any
    params?: (value: string) => any
    handleSuggestions?: (data: any) => Array<any>
}


interface IOwnProps {
    currentTarget: null | string
    suggestions: Array<any>
    formData: any
    newValue: string
    anchorEl: (EventTarget & HTMLElement) | null
}
class SearchWithSuggestion extends Component<Props, IOwnProps> {
    state: IOwnProps = {
        newValue: '',
        currentTarget: null,
        suggestions: [],
        formData: {
        },
        anchorEl: null
    }

    timeoutHandle?: NodeJS.Timeout;
    ajaxCallHandle?: CancelTokenSource;
    REQUEST_DELAY = 2000;


    componentDidMount() {
        this.setState({ formData: { [this.props.formKey]: this.props.value || '' } })
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ formData: { [this.props.formKey]: this.props.value || '' } })
        }
    }

    getSuggestions = async (e) => {
        this.setState({ currentTarget: e.target.name });

        const newValue = e.target.value;
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            // Clearing out the suggestions list if the input is cleared
            this.setState({ suggestions: [] });
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }


        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const params = this.props.params ? this.props.params(e.target.value) : {
                filter: {
                    match: {
                        [this.props.searchKey || e.target.name]: e.target.value
                    }
                }
            }
            const res = await Axios.request({
                url: this.props.suggestionUrl,
                params,
                cancelToken: this.ajaxCallHandle.token
            });

            let suggestions = res.data;
            if (_.isFunction(this.props.handleSuggestions))
                suggestions = this.props.handleSuggestions(res.data)


            this.setState({ suggestions });

        } catch (error) {
        }
    }


    closeSuggestion = () => {
        this.setState({ currentTarget: null });
    }


    onSelectSuggestion = (suggestion: string) => {
        const { formKey, objectValue = false } = this.props;
        this.setState({
            currentTarget: null, formData: { ...this.state.formData, [formKey]: objectValue ? _.get(suggestion, 'name') : suggestion },
        })
        this.props.onChange({ name: formKey, value: suggestion });
        if (this.props.onSelect)
            this.props.onSelect({ name: formKey, value: suggestion })
    }


    getSuggestionList = () => {
        const { classes, formKey, objectValue = false } = this.props;
        const { suggestions = [] } = this.state;
        return (
            <ClickAwayListener onClickAway={this.closeSuggestion}>
                <Paper className={classes.suggestions}>
                    <List>
                        {
                            _.map(suggestions, suggestion => (
                                <ListItem key={_.uniqueId('suggestions-')} className={classes.suggestionItem} dense onClick={e => this.onSelectSuggestion(suggestion)}>
                                    {objectValue ? _.get(suggestion, 'name') : suggestion}
                                </ListItem>
                            ))
                        }
                    </List>
                    <div className={classes.clearSuggestion} onClick={this.closeSuggestion}>
                        <Typography>CLOSE SUGGESTIONS</Typography>
                    </div>
                </Paper>
            </ClickAwayListener>
        )
    }


    handlePopper = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
    }



    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
        this.props.onChange({ name: e.target.name, value: e.target.value });
        this.getSuggestions(e)
    }


    clear = (e) => {
        if (this.props.disabled)
            return;
        this.setState({ formData: { ...this.state.formData, [this.props.formKey]: '' } });
        this.props.onChange({ name: this.props.formKey, value: '' })
    }


    handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newValue: e.target.value });
    }

    createNewField = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [this.props.formKey]: this.state.newValue
            }
        })
        this.props.onChange({ name: this.props.formKey, value: this.state.newValue })
        if (this.props.onSelect)
            this.props.onSelect({ name: this.props.formKey, value: this.state.newValue })
        this.handlePopper(e);
    }


    render() {
        const { classes, formKey, label, placeholder, disabled = false, disableAdd = false, disableUnderline = false } = this.props;
        const { formData, suggestions, currentTarget, anchorEl, newValue } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'popper' : undefined;

        return (
            <FormControl className={classes.root} fullWidth margin={disableUnderline ? 'none' : 'normal'}>
                <div className={classes.row}>
                    {label ? (
                        <InputLabel className={classes.label} htmlFor={formKey}>{label}</InputLabel>
                    ) : null}
                    <Input
                        autoComplete="off"
                        inputProps={{ className: classes.input }}
                        disabled={disabled}
                        disableUnderline={disableUnderline}
                        id={formKey}
                        placeholder={placeholder || ''}
                        name={formKey}
                        onChange={this.handleChange}
                        value={_.get(formData, formKey) || ''}
                    />
                    {
                        !_.isEmpty(_.get(formData, formKey)) ?
                            <Button variant="text" className={classes.clearField} onClick={this.clear}>
                                <i className="material-icons">close</i>
                            </Button> :
                            <Button variant="text" className={classes.clearField}>
                                <i className="material-icons">search</i>
                            </Button>

                    }
                </div>


                {
                    !_.isEmpty(suggestions) && currentTarget === formKey &&
                    this.getSuggestionList()
                }
                {
                    !disableAdd ?
                        <div>
                            <Button aria-describedby={id} onClick={this.handlePopper} color="primary" variant="contained" className={classes.popperButton}>
                                <i className="material-icons">add</i>
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handlePopper}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Fade timeout={350}>
                                    <Paper className={classes.popperContent} style={{ opacity: 1 }}>
                                        <div className="flex-row space-between">
                                            <Typography variant="h6">Add new {label}</Typography>
                                            <Button onClick={this.handlePopper} className={classes.closePopperBtn}>
                                                <i className="material-icons">close</i>
                                            </Button>
                                        </div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor={'popper' + formKey}>{label}</InputLabel>
                                            <Input
                                                autoComplete="off"
                                                inputProps={{ id: 'popper' + formKey }}
                                                onChange={this.handleEdit}
                                                value={newValue}
                                            />
                                        </FormControl>
                                        <Button color="primary" variant="outlined" className="my-2" onClick={this.createNewField} fullWidth>add</Button>
                                    </Paper>
                                </Fade>
                            </Popover>
                        </div> : null
                }

            </FormControl>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = theme => createStyles({
    filterInput: {
        position: 'relative',
        padding: '0px 10px',
        fontSize: 14,
    },
    inputLabel: {
        padding: '0px 10px',
        fontWeight: 500
    },
    label: {
        paddingLeft: 28,
        width: '80%',
        top: -8
    },
    input: {
        paddingLeft: 28
    },
    popperContent: {
        minWidth: 300,
        minHeight: 80,
        padding: 20,
    },
    popperButton: {
        position: 'absolute',
        right: 0,
        bottom: 10,
        color: 'white',
        minHeight: 0,
        minWidth: 0,
        height: 25,
        width: 25,
        borderRadius: 20,
        padding: 0
    },
    closePopperBtn: {
        marginLeft: 8,
        color: 'white',
        background: 'black',
        minHeight: 0,
        minWidth: 0,
        height: 30,
        width: 30,
        borderRadius: 20,
        padding: 0
    },
    popper: {
        zIndex: 1800,
        position: 'absolute',
        top: 0
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&>div': {
            width: '100%',
            paddingRight: 50
        }
    },
    root: {
        // background: THEME.Colors.Grayscale.Grey5,
        // marginBottom: 14,
        // height: 40,
        // display: 'flex',
        // justifyContent: 'center',
        // '& > div > p': {
        //     position: 'absolute',
        //     bottom: -18
        // },

    },
    clearField: {
        position: 'absolute',
        left: 0,
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        height: 'auto',
        background: 'gray',
        borderRadius: '50%',
        '& i': {
            color: 'white',
            fontSize: 14,
            padding: 4,
        }
    },
    suggestions: {
        position: 'absolute',
        top: 40,
        zIndex: 1000,
        width: 'inherit',
        maxHeight: 300,
        overflow: 'auto',
    },
    clearSuggestion: {
        cursor: 'pointer',
        position: 'sticky',
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: THEME.Colors.Grayscale.Grey4,
        bottom: 0,
        left: 0,
        right: 0
    },
    suggestionItem: {
        cursor: 'pointer',
        fontSize: 12,
        transition: '240ms ease-in',
        '&:hover': {
            background: THEME.Colors.Fourth,
            color: 'white'
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(SearchWithSuggestion))