import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, CircularProgress, Typography } from '@material-ui/core'
import { connect } from 'react-redux';
import _ from 'lodash'
import PDFObject from 'pdfobject';
import { fade } from '@material-ui/core/styles/colorManipulator';
import PDFViewer from '../Components/PdfViewer';
import PDFJsBackend from '../Components/PdfViewer/backend/pdfjs';
import { WIDE_VARIANT } from './NewLegalFinding';


interface Props extends WithStyles<typeof STYLES> {
    url?: string
    documentUrl: string
}

class PdfContent extends Component<Props> {
    pdfObject = PDFObject;

    componentDidMount() {
        const el = document.getElementById('container');
        if (el && !_.isEmpty(this.props.documentUrl))
            this.pdfObject.embed(this.props.documentUrl, '#container', { pdfOpenParams: { view: 'FitH' } })
    }

    componentDidUpdate(prevProps) {
        // const el = document.getElementById('container');
        // if (el && !_.isEmpty(this.props.documentUrl))
        //     this.pdfObject.embed(this.props.documentUrl, '#container')
    }



    render() {
        const { classes, documentUrl } = this.props
        const height = window.innerHeight - (WIDE_VARIANT ? 70 : 124);

        const isHttps = /^https.*/.test(documentUrl);
        let _url = isHttps ? documentUrl : documentUrl.replace('http', 'https');


        if (!this.pdfObject.supportsPDFs)
            return (
                <>
                    <div className={classes.root}>
                        <div className={classes.mainContainer}>
                            <Typography>
                                This browser does not support inline PDFs. Please download the PDF to view it.
                        </Typography>
                            <a href={_url} target="_blank">Download PDF</a>
                        </div>
                    </div>
                    <div className={classes.rootMobile}>
                        <a href={_url} target="_blank">View PDF</a>
                    </div>
                </>
            )

        // PDFObject.embed(documentUrl, '#container')
        return (
            <div className={classes.root}>
                <div id="container" style={{ width: "100%", height }} >
                    {/* <PDFViewer
                    backend={PDFJsBackend}
                    src={documentUrl}
                /> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = theme => createStyles({
    root: {
        position: 'relative',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        '& embed': {
            zIndex: 1
        },
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    rootMobile: {
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        textAlign: 'center',
        marginTop: 50,
        '& a': {
            padding: '10px 20px',
            background: fade('#000', 0.2),
            borderRadius: 4
        }
    },
    loader: {
        position: 'absolute',
        top: 300,
        display: 'flex',
        alignItems: 'center',
        zIndex: 0
    },

    mainContainer: {
        width: 720,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: '80vw'
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(PdfContent))