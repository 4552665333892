const coordinationEs = `
<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
<div id="c258" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
Coordinación, Becarios de CMN y asociados institucionales </h1></div>
<!--  Header: [end] -->			
<!--  Text: [begin] -->
<p class="bodytext"><span class="text_">
El Centro de investigación y políticas de derecho internacional  (Centre for International Law Research and Policy, </span><a href="https://www.cilrap.org/purpose/" class="text_1">CILRAP</a><span class="text_">) 
administra la Base de Datos de Herramientas Jurídicas (LTD) de la CPI en nombre de la Corte Penal Internacional, con arreglo a un Acuerdo de 10 años de duración formalizado entre la Corte y el  CILRAP  el 9 de marzo de 2021. 
 </span> <a href="https://www.cilrap.org/bergsmo/" target="_blank">Morten Bergsmo</a> 
 es el Director del CILRAP y Coordinador del Proyecto de Herramientas Jurídicas. <a href="https://www.cilrap.org/bais/" target="_blank">Devasheesh Bais</a> 
 es el Coordinador adjunto del Proyecto de Herramientas Jurídicas. 
 </p>
<p class="bodytext"><span class="text_">
El CILRAP ha asignado a un equipo de más de 40 de sus Becarios de CMN para prestar asistencia en la actualización y el mantenimiento del contenido de la LTD. Both the CMN Fellows and the institutional partners described below make 
Tanto los Becarios de CMN como los asociados institucionales que se describen a continuación realizan sus aportaciones a la LTD
</span><i>pro bono</i><span class="text_"> en consideración de su naturaleza de bien digital del dominio público.
 Los Becarios de CMN son un grupo diverso de personas que contribuyen a la LTD a más largo plazo, en un espíritu de servicio al interés del público. <a target="_blank" href="https://www.casematrixnetwork.org/about-us/cmn-fellows/" class="text_3">Aquí</a> aparece una lista de los Becarios de CMN actuales y anteriores.
  Los interesados en efectuar una aportación en calidad de Becarios de CMN  pueden enviar un mensaje electrónico adjuntando un <i>curriculum vitae</i> actualizado a
   <span class="text_"> <a href="mailto:info@cilrap.org">info@cilrap.org</a>.
   Los candidatos son seleccionados atendiendo a los criterios siguientes:
        (a) precisión, 
     (b)  motivación respecto de una participación permanente; y 
     (c) interés en la transferencia de conocimientos y el desarrollo de capacidades. </span></p>
<p class="bodytext">
A continuación se reflejan algunos acuerdos de cooperación entre la CPI y asociados externos del Proyecto de Herramientas Jurídicas de la CPI. El grupo de estos asociados internacionales experimenta una rotación natural. Entre los asociados previos se cuentan el Centro Noruego de Derechos Humanos, el Instituto T.M.C. Asser y TRIAL. En años recientes, los Becarios de CMN se han hecho cargo de un mayor volumen de  trabajo de mantenimiento y actualización de los contenidos de la Base de Datos de Herramientas Jurídicas de la CPI. El Equipo de Coordinación agradece los esfuerzos realizados por los asociados externos y los Becarios de CMN.  </p>
<p class="bodytext">
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/051221_Intention_Agreement_Marburg__countersigned.pdf" class="text_3">051221_Intention_Agreement_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf" class="text_3">071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090720_LT_CA_Korea_University__countersigned_.pdf" class="text_3">090720_LT_CA_Korea_University__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090724_LT_CA_Nottingham__countersigned_.pdf" class="text_3">090724_LT_CA_Nottingham__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/091217_LT_CA_WRHWLI_countersigned.pdf" class="text_3">091217_LT_CA_WRHWLI_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100325_LT_CA_Goettingen__countersigned.pdf" class="text_3">100325_LT_CA_Goettingen__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf" class="text_3">100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100921_LT_CA_Salzburg_countersigned.pdf" class="text_3">100921_LT_CA_Salzburg_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110117_LT_CA_University_of_Richmond.pdf" class="text_3">110117_LT_CA_University_of_Richmond.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110610_LT_CA_Canadian_partners.pdf" class="text_3">110610_LT_CA_Canadian_partners.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111017_LT_Co-operation_Agreement_CEEI.pdf" class="text_3">111017_LT_Co-operation_Agreement_CEEI.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111025_LT_Co-operation_Agreement_UTDT.pdf" class="text_3">111025_LT_Co-operation_Agreement_UTDT.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/140916_Co-operation_Agreement_Jindal_Global_University.pdf" class="text_3">140916_Co-operation_Agreement_Jindal_Global_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150424_LTP_Agreement_Ain_Shams_countersigned.pdf" class="text_3">150424_LTP_Agreement_Ain_Shams_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150513_Agreement_UKZN-UCT__countersigned.pdf" class="text_3">150513_Agreement_UKZN-UCT__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf" class="text_3">150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Nottingham.pdf" class="text_3">150918_LT_CA_Nottingham.pdf</a></div>
<div style="margin-bottom:8px;"><a lang="nb" target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Stanford_University.pdf" class="text_3">150918_LT_CA_Stanford_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150921_LT_CA_NUS_Faculty_of_Law.pdf" class="text_3">150921_LT_CA_NUS_Faculty_of_Law.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151007_LT_CA_PKU_Law_School__countersigned.pdf" class="text_3">151007_LT_CA_PKU_Law_School__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_Canadian_partners__countersigned.pdf" class="text_3">151019_LT_CA_Canadian_partners__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_ULB.pdf" class="text_3">151019_LT_CA_ULB.pdf</a></div>
</p>

<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
</div>
`;
export default coordinationEs;
