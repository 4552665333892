import { TIssueSuggestionType } from "./LtdDocs.types"
import utilities from "../Resources/Utils"

export class SuggestionsService {
    static postSuggestion = (form: any, type: TIssueSuggestionType) => utilities.request({
        url: `suggestions`,
        method: 'POST',
        data: { ...form, type }
    })

    static getSuggestions = (params: Record<string, any>) => utilities.request({
        url: 'suggestions',
        params
    })

}