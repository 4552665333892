import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, Button, Menu, MenuItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../../Resources/Theme';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { TGroup } from '../../../Features/LtdDashboard/@types';
import { ONewLTDDocument } from '../../../Features/AddNewLTDDocument/redux-config';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { OApp } from '../../../RootReducer/AppReducer';
import { ILtdPermissions } from '../../../Features/Authentication/@types';
import NodeTreeContainer from './NodeTreeContainer';
import UserListContainer from './UserListContainer';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps, IStateProps, RouteComponentProps {
    data: TGroup
}

interface IDispatchProps {
    updateGroup: (formData: Record<string, any>, groupId: string) => void
    deleteGroup: (groupId: string) => void
    confirmationDialog: (msg, onAccept, onCancel) => void
    fetchGroupDetail: (groupId: string) => any
    openSidebar: (component: JSX.Element) => void
    fetchUsers: (pageNumber) => any
}

interface IStateProps {
    ltdPermissions: ILtdPermissions
}

interface IFolder {
    anchorEl: EventTarget | null
    isRenaming: boolean
    formData: {
        name: string
    }
}
class Group extends Component<Props, IFolder> {
    state = {
        anchorEl: null,
        isRenaming: false,
        formData: {
            name: ''
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }


    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleDelete = () => {
        this.props.confirmationDialog("Are you sure you want to delete this group?",
            () => this.props.deleteGroup(this.props.data.id),
            () => null
        )
        this.handleClose();
    }

    handleAddMember = async () => {
        this.setState({ anchorEl: null });
        await Promise.all([this.props.fetchUsers(0), this.props.fetchGroupDetail(this.props.data.id)])
        this.props.openSidebar((
            <UserListContainer />
        ))
    }

    handleAddNode = async () => {
        this.setState({ anchorEl: null })
        await this.props.fetchGroupDetail(this.props.data.id);
        this.props.openSidebar((
            <NodeTreeContainer />
        ))
    }

    handleRename = () => {
        this.setState({ isRenaming: true, anchorEl: null, formData: { name: this.props.data.name } });
    }

    handleSubmit = () => {
        this.props.updateGroup(this.state.formData, this.props.data.id);
        this.setState({ isRenaming: false, formData: { name: '' } });
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleKeyPress = (e) => {
        if (e.keyCode === 27)
            this.cancel();
    }

    handleNavigation = () => {
        this.props.history.push(`partners/${this.props.data.id}`)
    }

    cancel = () => {
        this.setState({ isRenaming: false, formData: { name: '' } })
    }

    render() {
        const { classes, data, ltdPermissions } = this.props;
        const { anchorEl, isRenaming, formData } = this.state;

        const url = `/ltd-dashboard/partners/${data.id}`;


        const MENU_CONFIG = [
            { title: 'View', onClick: this.handleNavigation, },
            { title: 'Edit', onClick: this.handleRename, permissionKey: 'groupEdit' },
            // { title: 'Delete', onClick: this.handleDelete, permissionKey: 'groupDelete' },
            { title: 'Add new member', onClick: this.handleAddMember, permissionKey: 'groupAddUser' },
            { title: 'Assign node', onClick: this.handleAddNode, permissionKey: 'groupAddNode' },
        ]

        const _MENU_CONFIG = MENU_CONFIG.filter(config => config.permissionKey ? (ltdPermissions[config.permissionKey] || false) : true)

        return (
            <div className={classes.root}>
                {
                    !isRenaming ?
                        <Link className={classes.link} to={url}>
                            <Typography className={classes.name}>{data.name}</Typography>
                            <Typography variant="caption" className={classes.subtitle}>
                                {(_.get(data, 'userIds') || []).length + ' members'}
                            </Typography>
                        </Link> :
                        <div>
                            <div className={classes.editContainer}>
                                <TextField
                                    value={formData.name}
                                    onChange={e => this.setState({ formData: { name: e.target.value } })}
                                    onKeyPress={this.handleKeyPress}
                                />
                                <Button onClick={this.handleSubmit}>
                                    <i className="material-icons save">check_circle_outline</i>
                                </Button>
                                <Button onClick={this.cancel}>
                                    <i className="material-icons cancel">clear</i>
                                </Button>
                            </div>
                        </div>
                }
                <div className={classes.footer}>
                    <Typography variant="overline">{moment(data.created).format('DD MMM, YYYY')}</Typography>
                    <Button aria-controls="folder-menu" aria-haspopup="true" onClick={this.handleClick}>
                        <i className="material-icons" style={{ color: Theme.Colors.Secondary.main }}>more_vert</i>
                    </Button>
                    <Menu
                        id="folder-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        {
                            _MENU_CONFIG.map(config => (
                                <MenuItem key={config.title} dense onClick={config.onClick}>
                                    <ListItemText>{config.title}</ListItemText>
                                </MenuItem>

                            ))
                        }
                    </Menu>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.Auth, ['ltdPermissions'])
})

const mapDispatchToProps = dispatch => ({
    fetchUsers: (pageNumber) => dispatch(OLtdDashboard.fetchUsers(pageNumber)),
    fetchGroupDetail: (groupId: string) => dispatch(OLtdDashboard.fetchGroupDetail(groupId)),
    updateGroup: (formData: Record<string, any>, groupId: string) => dispatch(OLtdDashboard.editGroup(formData, groupId)),
    deleteGroup: (groupId: string) => dispatch(OLtdDashboard.deleteGroup(groupId)),
    openSidebar: (component) => dispatch(OApp.openSidebar(component)),
    confirmationDialog: (msg, onAccept, onCancel) => dispatch(OApp.showConfirmationDialog(msg, onAccept, onCancel))
})

const STYLES = theme => createStyles({
    root: {
        width: 230,
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        margin: '12px 10px',
        background: 'white',
        boxSizing: 'border-box',
        transition: '200ms linear',

        '&:hover': {
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.2)}`
        }
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    name: {
        fontWeight: 500,
        // color: Theme.Colors.Third
    },
    subtitle: {
        fontWeight: 500,
        color: Theme.Colors.Fourth
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            margin: 0,
            padding: 0,
            minWidth: 0,
            minHeight: 0,
            height: 40,
            width: 40,
            '& .save': {
                color: Theme.Colors.Green
            },
            '& .cancel': {
                color: Theme.Colors.Error
            }
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            minWidth: 0,
            padding: 0,
            color: Theme.Colors.Grayscale.Grey4
        }
    },
    dot: {
        display: 'inline-block',
        margin: '0px 4px',
        width: 8,
        height: 8,
        borderRadius: 5,
        background: 'rgba(0,0,0,0.3)'
    }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Group)));