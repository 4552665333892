import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Dialog, DialogContent, Slide, Fade, Zoom, Grow, DialogTitle, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TState } from '../RootReducer';
import { OApp, TConfirmationDialog } from '../RootReducer/AppReducer';
import { IS_MOBILE } from '../App';
import { WithTranslationProps, withTranslations } from '../Features/Translations/withTranslations';
import { GENERAL_NO, GENERAL_YES } from '../Features/Translations/translationKeys.cld';
import { GENERAL_LOG_OUT_TITLE } from '../Features/Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, WithTranslationProps {
}

interface IStateProps {
    confirmationDialog: TConfirmationDialog
}


interface IDispatchProps {
    closeDialog: Function
}


function Transition(props) {
    return <Slide direction="left" style={{ transformOrigin: '100% 0% 0' }} in={true} {...props} timeout={380} />;
}


class ConfirmationDialog extends Component<Props> {


    onConfirm = () => {
        const { confirmationDialog } = this.props;
        if (!_.isUndefined(confirmationDialog.actions.onConfirm))
            confirmationDialog.actions.onConfirm();

        this.props.closeDialog();
    }

    onCancel = () => {
        const { confirmationDialog } = this.props;
        if (!_.isUndefined(confirmationDialog.actions.onCancel))
            confirmationDialog.actions.onCancel();

        this.props.closeDialog();
    }


    render() {
        const { classes, confirmationDialog, getTranslation } = this.props;


        return (
            <Dialog
                fullScreen={IS_MOBILE}
                fullWidth={IS_MOBILE}
                TransitionComponent={Transition}
                open={_.get(confirmationDialog, 'open') || false}
                onClose={e => this.props.closeDialog!()}
                PaperProps={{ className: classes.paper }}
                scroll="body"
            >
                <DialogTitle>{getTranslation(GENERAL_LOG_OUT_TITLE) || 'Confirm action'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmationDialog.message}</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Button onClick={this.onConfirm}>{getTranslation(GENERAL_YES)}</Button>
                    <Button variant="contained" color="primary" onClick={this.onCancel}>{getTranslation(GENERAL_NO)}</Button>
                </DialogActions>

            </Dialog>
        )
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.App, 'confirmationDialog')
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OApp.closeConfirmationDialog())
})

const STYLES = theme => createStyles({
    paper: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
            maxWidth: 600
        }
    },
    actionContainer: {
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(ConfirmationDialog)))