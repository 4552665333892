import { ONewLTDDocument } from "./redux-config";
import { Reducer } from "redux";
import { TNewLTDDocumentState } from './@types';
import _ from 'lodash';
import { TDocument } from "../LTDDocuments/@types";

const initalState: TNewLTDDocumentState = {
    documentTypeSelectionDialog: false,
    isDocumentModified: false,
    open: false,
    editMode: 'doc',
    includeIdOptions: {},
    section: 'basic-information',
    isNewDocument: true,
    formData: {},
    loading: false,
    formUpdates: {},

}

export const LTD_DOC_SUGGESTION_PARENT_NODE = '5f214af7361be51da488db58';
export const LTD_TRANSLATE_RECORD_OMIT_FIELDS: (keyof TDocument)[] = ['id', 'title', 'languages', 'languageIds', 'externalId', 'created', 'updated', 'slug', 'docPath', 'metadata'];
export const LTD_FEATURED_LANGUAGES = ['English', "French"];

export function orderByFeaturedList<T = any>(list: T[], featuredList: Array<string>, compareKey = 'name') {
    const featured = list.filter(item => featuredList.indexOf(item[compareKey]) > -1);
    const rest = list.filter(item => featuredList.indexOf(item[compareKey]) === -1);
    return [...featured, ...rest]
}

export const NewLTDDocument: Reducer<TNewLTDDocumentState> = (state = initalState, action): TNewLTDDocumentState => {
    const {
        OPEN_NEW_LTD_DOCUMENT_DIALOG,
        CLOSE_NEW_LTD_DOCUMENT_DIALOG,
        SET_LTD_FORM_FIELD,
        OPEN_LTD_DOCUMENT_SELECTION_DIALOG,
        CLOSE_LTD_DOCUMENT_SELECTION_DIALOG,
        EDIT_LTD_DOCUMENT,
        SET_INCLUDE_FIELD_ID_OPTIONS,
        LTD_DOCUMENT_CHANGES_SAVED,
        NEW_LTD_DOCUMENT_CREATED,
        LTD_NEW_RESOURCE_UPLOADED,
        UNSET_LTD_FORM_FIELD,
        LTD_DOC_PATH_RECEIVED,
        OPEN_NEW_LTD_PREFILLED_DOCUMENT_DIALOG
    } = ONewLTDDocument.actions;

    switch (action.type) {
        case OPEN_NEW_LTD_PREFILLED_DOCUMENT_DIALOG: {
            return {
                ...state,
                parentNodeId: _.get(action.data, 'parentNodeId'),
                formData: _.get(action.data, 'formData'),
                formUpdates: _.get(action.data, 'formData'),
                isDocumentModified: true,
                open: true, isNewDocument: true, docPath: undefined
            }
        }
        case UNSET_LTD_FORM_FIELD: {
            return { ...state, formData: _.omit(state.formData, action.data), isDocumentModified: true };
        }
        case LTD_NEW_RESOURCE_UPLOADED: {
            const formData = { ...state.formData, orignalPdfURL: action.data.orignalPdfURL, documentFilePath: action.data.documentFilePath, documentName: action.data.documentName };
            const formUpdates = { ...state.formUpdates, orignalPdfURL: action.data.orignalPdfURL, documentFilePath: action.data.documentFilePath, documentName: action.data.documentName };
            return { ...state, formData: formData, formUpdates, isDocumentModified: true }
        }
        case NEW_LTD_DOCUMENT_CREATED: {
            const updatedForm = { ...state.formData, id: _.get(action, 'data.id') }

            return { ...state, additionalData: action.data.additionalData, editMode: action.data.editMode, isDocumentModified: false, formData: { ...updatedForm }, formUpdates: { ...updatedForm } };
        }
        case LTD_DOCUMENT_CHANGES_SAVED: {
            return { ...state, isDocumentModified: false };
        }
        case SET_INCLUDE_FIELD_ID_OPTIONS: {
            return { ...state, includeIdOptions: action.data }
        }
        case EDIT_LTD_DOCUMENT: {
            return { ...state, additionalData: action.data.additionalData, formData: action.data.doc, editMode: action.data.editMode, formUpdates: {}, open: true, isNewDocument: false, docPath: undefined }
        }
        case SET_LTD_FORM_FIELD: {
            return { ...state, formData: { ...state.formData, [action.data.key]: action.data.value }, formUpdates: { ...state.formUpdates, [action.data.key]: action.data.value }, isDocumentModified: true }
        }
        case OPEN_NEW_LTD_DOCUMENT_DIALOG: {
            return { ...state, open: true, isNewDocument: true, docPath: undefined }
        }
        case CLOSE_NEW_LTD_DOCUMENT_DIALOG: {
            return { ...initalState }
        }
        case OPEN_LTD_DOCUMENT_SELECTION_DIALOG: {
            return { ...state, documentTypeSelectionDialog: true, parentNodeId: action.data }
        }
        case CLOSE_LTD_DOCUMENT_SELECTION_DIALOG: {
            return { ...state, documentTypeSelectionDialog: false }
        }
        case LTD_DOC_PATH_RECEIVED: {
            const docPath = _.get(action, 'data.docPath');
            if (_.isEmpty(docPath))
                return state;
            return { ...state, docPath };
        }

        default: return state;
    }
}