const usePurlEs = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="purl/" >PURL&nbsp;»&nbsp;</a><a href="purl/how-to-use-the-purls-in-the-icc-legal-tools-database/" >How to use the PURLs in the ICC Legal Tools Database?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:248/text [begin] -->
    <div id="c248" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        ¿ Cómo utilizar los PURL en la Base de Datos Jurídicos de la CPI ?
        </h1></div>
    <!--  Header: [end] -->			
    <!--  Text: [begin] -->
        <p class="bodytext">
        Son más de 162.000 las fuentes jurídicas de derecho penal internacional  que están disponibles libremente en la Base de Datos de Herramientas Jurídicas de la CPI (LTD). Incluyen todos los documentos públicos de la CPI (decisiones, presentaciones y transcripciones correspondientes a las causas ante la Corte), así como decisiones o documentos de otros tribunales internacionales, órganos de derechos humanos o cortes nacionales, la totalidad de los trabajos preparatorios correspondientes a la legislación de la CPI, legislación nacional o publicaciones académicas de derecho penal internacional.  
</p>
<p class="bodytext">
Cada documento de la LTD tiene asignada una dirección permanente de Internet ('URL Persistente', o 'PURL'), que no se modificará. Al crear un hipervínculo que enlace su texto con el PURL de un documento, el usuario permite a los lectores acceder directamente al documento citado. La creación de estos hipervínculos es fácil: no lleva ni 30 segundos. El beneficio público es considerable, ya que su creación permite a los lectores un acceso a un número mucho mayor de fuentes jurídicas que la fuente con la que se pudiera estar trabajando. Por consiguiente, esta es una práctica que puede transformar la diseminación del derecho penal internacional.  
</p>
<p class="bodytext"><i><b>
¿Cómo puedo encontrar el PURL del documento en cuestión? </b></i>
</p>
<p class="bodytext">
Se puede hallar cualquier documento ya sea mediante el buscador de la parte superior izquierda del menú o mediante la navegación a través de las carpetas de las estructuras de árbol en el menú de la parte inferior izquierda de la LTD. Con independencia de la manerae por navegar por la LTD, se puede copiar el PURL del documento deseado haciendo clic en el botón  “COPY PURL” en la sección de identificación del documento de la página de resultados. Como alternativa, se puede hacer clic sobre el enlace del documento deseado. Esta acción llevará a la página de metadatos del documento en cuestión, que contiene información sobre el título, la fecha de creación, la fuente, la composición de la sala, etc. En la primera línea de la página de metadatos aparece la fórmula de la dirección PURL, que siempre acaba con una barra oblicua
 (por ejemplo, <a href="https://www.legal-tools.org/doc/ea422e/" target="_blank">http://www.legal-tools.org/doc/ea422e/</a>).
 Copie la dirección PURL y péguela en su documento. Solamente se debe utilizar la referencia
  PURL que aparece en la página de metadatos del documento en cuestión  o la obtenida haciendo 
  clic en el botón "COPY PURL". No se debe copiar el enlace con la página de metadatos desde la 
  barra de direcciones del navegador, ni hacer clic en el botón "Descargar" y copiar el enlace del
   documento deseado de la barra de dirección del navegador.
    </p>
<p class="bodytext"><i><b>
    ¿Cómo puedo crear el hipervínculo en mi borrador?
</b></i>
</p>
<p class="bodytext">
Para integrar el PURL como un hipervínculo en el borrador de texto que se esté elaborando, se han de seguir los tres pasos siguientes:  
 <br>1.	Marcar el elemento de texto de su borrador donde se desea introducir un hipervínculo.
  <br>2.  Pulsar “Control” + “K”. Aparecerá una pequeña ventana nueva. 
 <br>3. Introducir el PURL en la línea de dirección en la parte inferior de la pequeña ventana pulsando “Control” + “V”. A continuación, pulsar “Ok”.
</p>
<p class="bodytext"><i><b>
¿Cómo se elimina un hipervínculo? 
</b></i>
</p>
<p class="bodytext">
Para eliminar el hipervínculo, se debe volver a marcar el elemento de texto del que se desea eliminar el enlace al PURL. Pulse  “Control” + “K”, tras lo cual aparecerá nuevamente la pequeña ventana. Verá que en esa ventana se menciona el hipervínculo en la línea de dirección en la parte inferior de la ventana. En la parte de la derecha verá el botón  “Remove link” (Eliminar enlace). Haga clic en ese botón para eliminar el hipervínculo. 
</p>
<p class="bodytext"><i><b>
¿Qué hago si un documento no está disponible en la Base de Datos de Herramientas Jurídicas de la CPI? 
</b></i>
</p>
<p class="bodytext">
Si no encontrara un documento concreto, sírvase enviar un correo electrónico al Sr. Devasheesh Bais (Coordinador adjunto, Proyecto de Herramientas Jurídicas de la CPI) a la dirección 
<a href="mailto:bais@cilrap.org">bais@cilrap.org</a>&nbsp;(con copia en todos los casos  
    <a href="mailto:info@cilrap.org">info@cilrap.org</a>).</p>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:248/text [end] -->
    </div>`;

export default usePurlEs;
