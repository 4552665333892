import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme,
    FormControl,
    InputLabel,
    Input,
    Select,
    MenuItem,
    TextField,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import _ from 'lodash';
import SearchSelect from '../SearchSelect';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import SearchWithSuggestion from '../SearchWithSuggestion';

export interface IFormConfig {
    flex?: number;
    label: string;
    rows?: string;
    params?: any;
    key: string;
    id?: string;
    searchKey?: string;
    suggestionUrl?: string;
    type: 'text' | 'number' | 'multiline' | 'password' | 'select' | 'date' | 'component' | 'multiselect' | 'search';
    options?: Array<{
        name: string;
        value: string;
        translationkey?: string;
    }>;
    handleSuggestions?: any;
    disabled?: boolean;
    componentProps?: any;
    component?: JSX.Element;
    onChange: (e: any) => void;
    translationKey?: string;
    isFetch?: boolean;
}

interface IProps extends WithStyles<typeof STYLES>, WithTranslationProps {
    formItems: IFormConfig[][];
    formData: Record<string, any>;
}

class FormGroup extends Component<IProps> {
    onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {};

    renderValue = (selectedValues: any | undefined, options: any) => {
        if (!selectedValues) return '';

        if (!_.isArray(selectedValues)) return selectedValues;

        const values: any[] = [];
        selectedValues.forEach(selected => {
            const val = _.get(_.find(options, { value: selected }), 'name');
            if (val) values.push(val);
        });
        return values.join(', ');
    };

    renderLabel = (config: IFormConfig) => {
        if (config.translationKey) {
            //
            return this.props.getTranslation(config.translationKey);
        }
        return config.label;
    };

    render() {
        const { classes, formItems, formData = {} } = this.props;
        return (
            <div className={classes.root}>
                {formItems.map((row, i) => (
                    <div key={i} className='flex-row align-end' style={{ minHeight: 80 }}>
                        {row.map(inputConfig => (
                            <FormControl
                                className={classes.formControl}
                                style={{ flex: inputConfig.flex ? inputConfig.flex : 1 }}
                                key={inputConfig.key}
                                fullWidth
                            >
                                {inputConfig.type === 'text' ||
                                inputConfig.type === 'password' ||
                                inputConfig.type === 'number' ? (
                                    <>
                                        <InputLabel
                                            color='primary'
                                            htmlFor={inputConfig.id ? inputConfig.id : inputConfig.key}
                                        >
                                            {this.renderLabel(inputConfig)}
                                        </InputLabel>
                                        <Input
                                            {...inputConfig.componentProps}
                                            disabled={inputConfig.disabled}
                                            color='primary'
                                            type={inputConfig.type}
                                            onChange={inputConfig.onChange}
                                            value={_.get(formData, inputConfig.key) || ''}
                                            inputProps={{
                                                id: inputConfig.id ? inputConfig.id : inputConfig.key,
                                                name: inputConfig.key,
                                            }}
                                        />
                                    </>
                                ) : null}
                                {inputConfig.type === 'multiline' ? (
                                    <TextField
                                        {...inputConfig.componentProps}
                                        color='primary'
                                        disabled={inputConfig.disabled}
                                        id={inputConfig.id ? inputConfig.id : inputConfig.key}
                                        label={this.renderLabel(inputConfig)}
                                        multiline
                                        name={inputConfig.key}
                                        onChange={inputConfig.onChange}
                                        rowsMax='4'
                                        rows={inputConfig.rows ? inputConfig.rows : '1'}
                                        value={formData[inputConfig.key] || ''}
                                        margin='normal'
                                        classes={{ marginNormal: classes.multilineWrapper }}
                                        InputProps={{ disableUnderline: false, className: classes.multiLine }}
                                        // className={classes.multiLine}
                                    />
                                ) : null}
                                {inputConfig.type === 'select' ? (
                                    <>
                                        <InputLabel
                                            color='primary'
                                            htmlFor={inputConfig.id ? inputConfig.id : inputConfig.key}
                                        >
                                            {this.renderLabel(inputConfig)}
                                        </InputLabel>
                                        <Select
                                            {...inputConfig.componentProps}
                                            disabled={inputConfig.disabled}
                                            color='primary'
                                            onChange={inputConfig.onChange}
                                            value={_.get(formData, inputConfig.key) || ''}
                                            inputProps={{
                                                id: inputConfig.id ? inputConfig.id : inputConfig.key,
                                                name: inputConfig.key,
                                            }}
                                        >
                                            {inputConfig.options!.map((option, i) => (
                                                <MenuItem key={i} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                ) : null}
                                {inputConfig.type === 'multiselect' ? (
                                    <>
                                        {/* <InputLabel color="primary" htmlFor={inputConfig.id ? inputConfig.id : inputConfig.key}>{this.renderLabel(inputConfig)}</InputLabel>
                                                        <Select
                                                            onKeyDown={this.onKeyDown}
                                                            disabled={inputConfig.disabled}
                                                            color="primary"
                                                            multiple
                                                            renderValue={selected => this.renderValue(selected, inputConfig.options)}
                                                            onChange={inputConfig.onChange}
                                                            inputProps={{
                                                                id: inputConfig.id ? inputConfig.id : inputConfig.key,
                                                                name: inputConfig.key
                                                            }}
                                                            value={formData[inputConfig.key] || []}
                                                        >
                                                            {
                                                                inputConfig.options!.map((option, i) => (
                                                                    <MenuItem key={i} value={option.value}>
                                                                        <Checkbox checked={_.indexOf((formData[inputConfig.key] || []), option.value) > -1} />
                                                                        <ListItemText primary={option.name} />
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select> */}
                                        <SearchSelect
                                            value={formData[inputConfig.key] || []}
                                            multiple={true}
                                            options={inputConfig.options || []}
                                            id={inputConfig.id ? inputConfig.id : inputConfig.key}
                                            name={inputConfig.key}
                                            label={this.renderLabel(inputConfig)}
                                            onChange={inputConfig.onChange}
                                        />
                                    </>
                                ) : null}
                                {inputConfig.type === 'date' ? (
                                    <InlineDatePicker
                                        keyboard
                                        clearable
                                        disableFuture
                                        minDate={new Date('01-02-1670')}
                                        InputProps={{ className: classes.dateInput }}
                                        InputLabelProps={{ className: classes.dateInputLabel }}
                                        name={inputConfig.key}
                                        fullWidth
                                        mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        label={this.renderLabel(inputConfig)}
                                        views={['year', 'month', 'day']}
                                        // placeholder={this.renderLabel(inputConfig)}
                                        maxDate={new Date()}
                                        keyboardIcon={<i className='material-icons'>event</i>}
                                        format='DD-MM-YYYY'
                                        {...inputConfig.componentProps}
                                        // invalidDateMessage={<InvalidDateMessage />}
                                        value={
                                            formData[inputConfig.key]
                                                ? moment(formData[inputConfig.key], 'YYYY-MM-DD')
                                                : null
                                        }
                                        onChange={date => {
                                            if (date)
                                                inputConfig.onChange({
                                                    target: {
                                                        value: moment(date).format('YYYY-MM-DD'),
                                                        name: inputConfig.key,
                                                    },
                                                });
                                            else
                                                inputConfig.onChange({
                                                    target: { value: null, name: inputConfig.key },
                                                });
                                        }}
                                    />
                                ) : null}
                                {inputConfig.type === 'search' ? (
                                    <SearchWithSuggestion
                                        params={inputConfig.params}
                                        // classes={{ root: classes.formControl }}
                                        label={inputConfig.label}
                                        disabled={!!inputConfig.disabled}
                                        formKey={inputConfig.key}
                                        searchKey={inputConfig.searchKey ? inputConfig.searchKey : inputConfig.key}
                                        value={formData[inputConfig.key] || ''}
                                        onChange={inputConfig.onChange}
                                        handleSuggestions={inputConfig.handleSuggestions}
                                        suggestionUrl={inputConfig.suggestionUrl!}
                                    />
                                ) : null}
                                {inputConfig.type === 'component' ? inputConfig.component : null}
                            </FormControl>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        formControl: {
            margin: '6px 16px',
            flex: 1,
        },
        multilineWrapper: {
            '& label': {
                // padding: 4
            },
        },
        multiLine: {
            borderRadius: 4,
            // padding: 4,
            // border: `1px solid ${fade('#000', 0.4)}`
        },
        dateInput: {
            // margin: '6px 0px'
        },
        dateInputLabel: {},
    });

export default withStyles(STYLES)(withTranslations(FormGroup));
