import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { TDecisionData } from '../../Features/AddNewDecision/@types';
import { BOOLEAN_OPTIONS } from '../../Features/AddNewLTDDocument/@types';
import { TCldFieldState } from '../../Features/CldField/redux-config';
import ChipSelect from '../ChipSelect';
import _ from 'lodash';
import FormGroup, { IFormConfig } from '../FormGroup';
import config from './config';

interface DecisionFormProps extends WithStyles<typeof STYLES> {
    formData: TDecisionData;
    handleChange: (formData: TDecisionData) => void;
    CldFields: TCldFieldState;
    appConstants: any;
}

const DecisionForm: React.FC<DecisionFormProps> = props => {
    const { classes, formData, handleChange, CldFields, appConstants = { CldField: {} } } = props;

    const onChange = e => {
        const { name, value } = e.target;
        handleFormChange(name, value);
    };

    const handleFormChange = (name: string, value: any) => {
        handleChange({
            ...formData,
            [name]:
                config.formConfig[name] && config.formConfig[name].transformer
                    ? config.formConfig[name].transformer(value)
                    : value,
        });
    };

    const handleSuggestChange = ({ name, value }) => {
        handleFormChange(name, value);
    };

    const getOptions = (config: any) => {
        if (!config.options) return [];
        return config.options.map(item => ({ name: item, value: item }));
    };

    const formItems: IFormConfig[][] = [
        [
            {
                key: config.formConfig.title.key,
                label: config.formConfig.title.label,
                type: config.formConfig.title.type as IFormConfig['type'],
                options: getOptions(config.formConfig.title),
                onChange,
            },
        ],
        ...Object.keys(CldFields.groupedCldFields).map(field => [
            {
                key: `${field}Id`,
                label: appConstants.CldField.type.find(t => t.value === field).name,
                type: 'select' as IFormConfig['type'],
                options: CldFields.groupedCldFields[field].map(cldField => ({
                    name: cldField.name || cldField.name__en,
                    value: cldField.id,
                })),
                onChange,
                //CldFields.groupedCldFields[field]
            },
        ]),
        // [
        //     {
        //         key: config.formConfig.typeOfDecisionEn.key,
        //         label: config.formConfig.typeOfDecisionEn.label,
        //         type: config.formConfig.typeOfDecisionEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.typeOfDecisionEn),
        //         onChange,
        //     },

        //     {
        //         key: config.formConfig.documentSourceEn.key,
        //         label: config.formConfig.documentSourceEn.label,
        //         type: config.formConfig.documentSourceEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.documentSourceEn),
        //         onChange,
        //     },
        // ],
        [
            {
                key: config.formConfig.fullDocumentNumber.key,
                label: config.formConfig.fullDocumentNumber.label,
                type: config.formConfig.fullDocumentNumber.type as IFormConfig['type'],
                options: getOptions(config.formConfig.fullDocumentNumber),
                onChange,
            },
            // {
            //     key: config.formConfig.languageEn.key,
            //     label: config.formConfig.languageEn.label,
            //     type: config.formConfig.languageEn.type as IFormConfig['type'],
            //     options: getOptions(config.formConfig.languageEn),
            //     onChange,
            // },
        ],

        // [
        //     {
        //         key: config.formConfig.phaseOfCaseEn.key,
        //         label: config.formConfig.phaseOfCaseEn.label,
        //         type: config.formConfig.phaseOfCaseEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.phaseOfCaseEn),
        //         onChange,
        //     },
        // ],
        // [
        //     {
        //         key: config.formConfig.situationNameEn.key,
        //         label: config.formConfig.situationNameEn.label,
        //         type: config.formConfig.situationNameEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.situationNameEn),
        //         onChange,
        //     },
        //     {
        //         key: config.formConfig.situationNameCardEn.key,
        //         label: config.formConfig.situationNameCardEn.label,
        //         type: config.formConfig.situationNameCardEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.situationNameCardEn),
        //         onChange,
        //     },
        //     {
        //         key: config.formConfig.situationNameFilterEn.key,
        //         label: config.formConfig.situationNameFilterEn.label,
        //         type: config.formConfig.situationNameFilterEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.situationNameFilterEn),
        //         onChange,
        //     },
        // ],
        // [
        //     {
        //         key: config.formConfig.caseNameEn.key,
        //         label: config.formConfig.caseNameEn.label,
        //         type: config.formConfig.caseNameEn.type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.caseNameEn),
        //         onChange,
        //     },
        //     {
        //         key: config.formConfig.caseNameCardEn.key,
        //         label: config.formConfig.caseNameCardEn.label,
        //         type: config.formConfig.caseNameCardEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.caseNameCardEn),
        //         onChange,
        //     },
        //     {
        //         key: config.formConfig.caseNameFilterEn.key,
        //         label: config.formConfig.caseNameFilterEn.label,
        //         type: config.formConfig.caseNameFilterEn
        //             .type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.caseNameFilterEn),
        //         onChange,
        //     },
        // ],
        // [
        // {
        //     key: config.formConfig.documentTypeEn.key,
        //     label: config.formConfig.documentTypeEn.label,
        //     type: config.formConfig.documentTypeEn
        //         .type as IFormConfig['type'],
        //     options: getOptions(config.formConfig.documentTypeEn),
        //     onChange,
        // },
        // {
        //     key: config.formConfig.typeOfHearingEn.key,
        //     label: config.formConfig.typeOfHearingEn.label,
        //     type: config.formConfig.typeOfHearingEn
        //         .type as IFormConfig['type'],
        //     options: getOptions(config.formConfig.typeOfHearingEn),
        //     onChange,
        // },
        // ],
        [
            {
                key: config.formConfig.pageNumber.key,
                label: config.formConfig.pageNumber.label,
                type: config.formConfig.pageNumber.type as IFormConfig['type'],
                options: getOptions(config.formConfig.pageNumber),
                onChange,
            },
            {
                key: config.formConfig.fileSize.key,
                label: config.formConfig.fileSize.label,
                type: config.formConfig.fileSize.type as IFormConfig['type'],
                options: getOptions(config.formConfig.fileSize),
                onChange,
            },
        ],
        [
            {
                label: config.formConfig.hasLegalFinding.label,
                key: config.formConfig.hasLegalFinding.key,
                type: 'component',
                onChange: () => {},
                component: (
                    <div className='flex-row space-between'>
                        <ChipSelect
                            formkey='Legal Findings?'
                            value={formData['Legal Findings?']}
                            title={'Legal Findings?'}
                            onChange={handleFormChange}
                            options={BOOLEAN_OPTIONS}
                        />
                    </div>
                ),
            },
        ],
        [
            {
                label: 'Appeal relationship?',
                key: 'Applicable Appeal',
                type: 'select',
                options: [
                    { name: '', value: '' },
                    { name: 'Appeals judgment', value: 'Appeals judgment' },
                    { name: 'Impugned decision', value: 'Impugned decision' },
                    // { label: 'Majority Decision', key: 'Majority Decision' },
                    // { label: 'Separate/Dissenting Opinion', key: 'SeparateDissenting Opinion' },
                ],
                onChange,
            },
            {
                label: 'Majority/Dissenting relationship',
                key: 'majorityDissentingRelationship',
                type: 'select',
                options: [
                    { name: '', value: '' },
                    { name: 'Majority', value: 'Majority' },
                    { name: 'Dissenting', value: 'Dissenting' },
                ],
                onChange,
            },
        ],
        [
            {
                label: 'Appeal relationship document number',
                key: 'Appeal Doc ID',
                searchKey: 'Document Number',
                type: 'search',
                suggestionUrl: '/clddocs/autocomplete',
                params: text => ({ term: text, field: ['Document Number'] }),
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
                onChange: handleSuggestChange,
            },
            {
                label: 'Majority/Dissenting relationship document number',
                key: 'majorityDissentingRelationshipDocId',
                searchKey: 'Document Number',
                type: 'search',
                suggestionUrl: '/clddocs/autocomplete',
                params: text => ({ term: text, field: ['Document Number'] }),
                handleSuggestions: (data: any) => _.map(data, item => item['Document Number']),
                onChange: handleSuggestChange,
            },
        ],
        [
            {
                key: config.formConfig.id.key,
                label: config.formConfig.id.label,
                type: config.formConfig.id.type as IFormConfig['type'],
                options: getOptions(config.formConfig.id),
                onChange,
            },
            {
                key: config.formConfig.uri.key,
                label: config.formConfig.uri.label,
                type: config.formConfig.uri.type as IFormConfig['type'],
                options: getOptions(config.formConfig.uri),
                onChange,
            },
        ],
        [
            {
                key: config.formConfig.confidentiality.key,
                label: config.formConfig.confidentiality.label,
                type: config.formConfig.confidentiality.type as IFormConfig['type'],
                options: getOptions(config.formConfig.confidentiality),
                onChange,
            },
        ],
        // [
        // {
        //     key: config.formConfig.presidencyReviewEn.key,
        //     label: config.formConfig.presidencyReviewEn.label,
        //     type: config.formConfig.presidencyReviewEn
        //         .type as IFormConfig['type'],
        //     options: getOptions(config.formConfig.presidencyReviewEn),
        //     onChange,
        // },
        // {
        //     key: config.formConfig.relatedToEn.key,
        //     label: config.formConfig.relatedToEn.label,
        //     type: config.formConfig.relatedToEn.type as IFormConfig['type'],
        //     options: getOptions(config.formConfig.relatedToEn),
        //     onChange,
        // },
        // ],
        // [
        //     {
        //         label: '',
        //         key: config.formConfig.isLatest.key,
        //         type: 'component',
        //         onChange: onChange,
        //         component: (
        //             <ChipSelect
        //                 formkey={config.formConfig.isLatest.key}
        //                 value={formData[config.formConfig.isLatest.key]}
        //                 title={config.formConfig.isLatest.label}
        //                 onChange={handleFormChange}
        //                 options={BOOLEAN_OPTIONS}
        //             />
        //         ),
        //     },
        //     {
        //         key: config.formConfig.itemType.key,
        //         label: config.formConfig.itemType.label,
        //         type: config.formConfig.itemType.type as IFormConfig['type'],
        //         options: getOptions(config.formConfig.itemType),
        //         onChange,
        //     },
        // ],
    ];

    return (
        <div className={classes.root}>
            <FormGroup formData={formData} formItems={formItems} />
        </div>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            width: 900,
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#fff',
        },
        dialogContainer: {},
    });

export default withStyles(STYLES)(DecisionForm);
