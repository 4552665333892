import { Models } from "../../Resources/Model";
import utilities from "../../Resources/Utils";
import _ from 'lodash';
import { TAdvancedSearchQueryType, TCLDAvdancedSearchOperator } from './@types';
import { OCLDDocuments, CLD_DECISION_FACETS, CLDFACETS } from "../CLDDocuments/redux-config";



export type IAdvancedSearchCLDQueryKey = 'Document number' | 'Case name' | 'Situation name' | 'Title' | 'allContent'




const formatDocumentNumber = (str: string) => {
    let x = str.match(/(Document number) ?: ?(.*)?\)/g);
    if (!x) return str;
    let matchedText = x.length > 0 ? x[0] : undefined;
    if (!matchedText) return str;
    let replacement = matchedText.replace(/\//g, '\\\/');
    return str.replace(/(Document number) ?: ?(.*)?\)/g, replacement);
}

// const formatCaseName = (str: string) => {
//     let x = str.match(/(Case name) ?: ?(.*)?\)/g);
//     if (!x) return str;
//     let matchedText = x.length > 0 ? x[0] : undefined;
//     if (!matchedText) return str;
//     let replacement = matchedText.replace(/"/g, '\\"');
//     return str.replace(/(Case name) ?: ?(.*)?\)/g, replacement);
// }

const cleanupQuotes = (str: string) => {
    return str.replace(/[”“]/ig, '"')
}

const removeRepeatedQuotes = (str: string) => str.replace(/""/g, '"');

const preprocessSearchTerm = (term: string) => {
    // term = cleanupQuotes(term);
    term = formatDocumentNumber(term);
    // term = formatCaseName(term);
    return term;
}

export const LegalFindingQueryMapping: Record<IAdvancedSearchCLDQueryKey, string> = {
    'Document number': 'ltdDoc.externalId',
    'Case name': 'ltdDoc.cld.caseNameFilterEn',
    'Situation name': 'ltdDoc.cld.situationNameFilterEn',
    'Title': 'ltdDoc.title',
    'allContent': 'legalFinding'
}


export const DecisionQueryMapping: Record<IAdvancedSearchCLDQueryKey, string> = {
    'Document number': 'externalId',
    'Case name': 'cld.caseNameFilterEn',
    'Situation name': 'cld.situationNameFilterEn',
    'Title': 'title',
    'allContent': 'docPages.text'
    // 'allContent': 'allContent'
}

export class AdvancedSearch extends Models {
    constructor() {
        super('AdvancedSearch', {
            OPEN_ADVANCED_SEARCH_DIALOG: 'OPEN_ADVANCED_SEARCH_DIALOG',
            CLOSE_ADVANCED_SEARCH_DIALOG: 'CLOSE_ADVANCED_SEARCH_DIALOG',
            SET_ADVANCED_SEARCH_QUERIES: 'SET_ADVANCED_SEARCH_QUERIES',
            SET_CLD_ADVANCED_SEARCH_OPERATOR: 'SET_CLD_ADVANCED_SEARCH_OPERATOR',
            SET_BUILD_TERM_FROM_QUERY: 'SET_BUILD_TERM_FROM_QUERY',
        });
    }

    setBuildTermFromQuery = (flag: boolean) => dispatch => dispatch({
        type: this.actions.SET_BUILD_TERM_FROM_QUERY,
        data: flag
    })

    setOperator = (operator: TCLDAvdancedSearchOperator) => dispatch =>
        dispatch({
            type: this.actions.SET_CLD_ADVANCED_SEARCH_OPERATOR,
            data: operator
        })

    closeDialog = () => dispatch => dispatch({
        type: this.actions.CLOSE_ADVANCED_SEARCH_DIALOG
    })

    openDialog = (type: 'LTD' | 'CLD' = 'LTD') => dispatch => dispatch({
        type: this.actions.OPEN_ADVANCED_SEARCH_DIALOG,
        data: type
    })


    removeCaseFilterForSituation = (isDecisionSearch: boolean = false) => dispatch => {
        const facets = isDecisionSearch ? CLD_DECISION_FACETS : CLDFACETS;
        const facetsAlt = isDecisionSearch ? CLDFACETS : CLD_DECISION_FACETS;

        const item = _.find(facets, { label: 'Case' });
        if (!item) return;
        const KEY = item.key
        const VALUE = 'None (Situation only)'

        const itemToRemove = _.find(facetsAlt, { label: 'Case' });
        if (itemToRemove)
            dispatch(OCLDDocuments.removeChip({
                filterType: 'facets',
                key: itemToRemove.key,
                name: VALUE,
                value: VALUE
            }, false))



        dispatch(OCLDDocuments.removeChip({
            filterType: 'facets',
            key: KEY,
            name: VALUE,
            value: VALUE
        }, false))
    }
    applyCaseFilterForSituation = (isDecisionSearch: boolean = false) => dispatch => {
        const facets = isDecisionSearch ? CLD_DECISION_FACETS : CLDFACETS;
        const facetsAlt = isDecisionSearch ? CLDFACETS : CLD_DECISION_FACETS;
        const item = _.find(facets, { label: 'Case' });
        const VALUE = 'None (Situation only)'
        if (!item) return;
        const itemToRemove = _.find(facetsAlt, { label: 'Case' });
        if (itemToRemove)
            dispatch(OCLDDocuments.removeChip({
                filterType: 'facets',
                key: itemToRemove.key,
                name: VALUE,
                value: VALUE
            }, false))

        const KEY = item.key
        dispatch(OCLDDocuments.applyFacet(KEY, VALUE))
    }


    handleSituationCaseSearch = (searchFilter: any, searchTerm: string, isDecisionSearch: boolean) => {
        const facets = isDecisionSearch ? CLD_DECISION_FACETS : CLDFACETS;
        const item = _.find(facets, { label: 'Case' });
        if (!item) return searchFilter;
        const KEY = item.key
        const VALUE = 'None (Situation only)'

        const hasSituationFilter = /\(Situation name:/g.test(searchTerm);

        if (hasSituationFilter) {
            let _filter = {
                ...searchFilter,
                where: {
                    ...(_.get(searchFilter, 'where') || {}),
                    [KEY]: [...(_.get(searchFilter, `where[${KEY}]`) || []), VALUE]
                }
            }
            return _filter;
        }
        else if (searchTerm && !hasSituationFilter) {
            let _filter = {
                ...searchFilter,
                where: {
                    ...(_.get(searchFilter, 'where') || {}),
                    [KEY]: [...(_.get(searchFilter, `where.${KEY}`) || []).filter((x: string) => x !== VALUE)]
                }
            }
            return _filter;
        }

        return searchFilter;
    }

    formatSearchTerm = (searchTerm: string, isDecisionSearch: boolean) => {
        // const temp = searchTerm.split(":");
        let newTerm = searchTerm;

        newTerm = preprocessSearchTerm(newTerm);

        Object.keys(LegalFindingQueryMapping).forEach(key => {
            const regex = new RegExp(key, 'g')
            if (isDecisionSearch)
                newTerm = newTerm.replace(regex, DecisionQueryMapping[key])
            else
                newTerm = newTerm.replace(regex, LegalFindingQueryMapping[key])
        })
        return newTerm;
    }

    buildCLDQuery = (queries: Array<any>) => (dispatch, getState) => {
        const { CLDDocuments, AdvancedSearch } = getState();
        const operator: TCLDAvdancedSearchOperator = _.get(AdvancedSearch, 'operator') || 'AND';
        const isDecisionSearch = _.get(CLDDocuments, 'isDecisionSearch') || false;

        let hasSituationCaseQuery = false;
        let hasCaseQuery = false;

        dispatch({
            type: this.actions.SET_ADVANCED_SEARCH_QUERIES,
            data: queries
        });
        let queryString = "";

        queries = queries.filter(q => q.query1 || q.query2);
        _.forEach(queries, (query, index) => {
            let _query = "(";
            if (index)
                _query = ` ${operator} (`

            const key: IAdvancedSearchCLDQueryKey = query.queryType;
            const q1 = query.query1 ? cleanupQuotes(query.query1) : undefined;
            const q2 = query.query2 ? cleanupQuotes(query.query2) : undefined;
            switch (key) {
                case "allContent": {
                    if (!_.isEmpty(query.proximity))
                        switch (query.operation) {
                            case 'NOT':
                                _query += `${key}: "${q1} ${q2}"!~${query.proximity}`
                                break;
                            case 'AND':
                                _query += `${key}: "${q1} ${q2}"~${query.proximity}`
                                break;
                            case 'OR':
                                _query += `${key}: "${q1}" OR "${q2}"`;
                                // _query += `${key}: "${q1}" OR "${q2}"~${query.proximity}`;
                                break;
                        }
                    else {
                        if (!_.isEmpty(q1))
                            _query += `${key}: (${q1}`;
                        // _query += `${key}: ("${q1}"`;
                        if (!_.isEmpty(q2))
                            _query += ` ${query.operation} ${q2})`;
                        // _query += ` ${query.operation} "${q2}")`;
                        else
                            _query += ')'
                        // _query += ` ${query.operation} ${key}: "${query.query2}"`;
                    }
                    break;
                }
                case "Document number":
                    _query += `${key}: ${q1}`;
                    break;
                case "Title":
                    _query += `${key}: (${q1})`;
                    break;
                case "Case name":
                    hasCaseQuery = true;
                    if (!/^".*"$/g.test(q1 || '')) {
                        _query += `${key}: "${q1}"~5`;
                    } else {
                        _query += `${key}: ${q1}~5`;
                    }
                    // _query += `${key}: "${query.query1}" ~5`;
                    break;
                case "Situation name": {
                    hasSituationCaseQuery = true
                    if (!/^".*"$/g.test(q1 || '')) {
                        _query += `${key}: ${q1}`;
                        // _query += `${key}: "${q1}"`;
                    } else {
                        _query += `${key}: ${q1}`;
                    }
                    break;
                }

            }
            _query += ')'
            queryString += _query;
        })
        // dispatch(OAdvancedSearch.handleSituationCaseSearch(queryString, isDecisionSearch));
        if (hasSituationCaseQuery && !hasCaseQuery) {
            dispatch(this.applyCaseFilterForSituation(isDecisionSearch))
        } else {
            dispatch(this.removeCaseFilterForSituation(isDecisionSearch))
        }
        // if (queries.length === 1) {
        //     queryString = _.trim(queryString, '(')
        //     queryString = _.trim(queryString, ')')
        // }
        return removeRepeatedQuotes(queryString);
    }



    // LTD query Builder

    buildQuery = (queries) => dispatch => {
        dispatch({
            type: this.actions.SET_ADVANCED_SEARCH_QUERIES,
            data: queries
        })


        let queryString = "";

        _.forEach(queries, (query, index) => {
            let _query = "";
            if (index)
                _query = " AND "

            const key = query.queryType as TAdvancedSearchQueryType
            switch (key) {
                case "allContent": {
                    // if (!_.isEmpty(query.proximity))
                    //     _query += `${query.queryType}: "${query.query1} ${query.query2}"~${query.proximity} `
                    // else {
                    //     if (!_.isEmpty(query.query1))
                    //         _query += `${query.queryType}: "${query.query1}"`;
                    //     if (!_.isEmpty(query.query2))
                    //         _query += ` ${query.operation} ${query.queryType}: "${query.query2}"`;
                    // }
                    if (!_.isEmpty(query.proximity))
                        switch (query.operation) {
                            case 'NOT':
                                _query += `${key}: "${query.query1} ${query.query2}"~1000000 NOT "${query.query1} ${query.query2}"~${query.proximity}`
                                break;
                            case 'AND':
                                _query += `${key}: "${query.query1} ${query.query2}"~${query.proximity}`
                                break;
                            case 'OR':
                                _query += `${key}: "${query.query1}" OR "${query.query2}"`;
                        }
                    else {
                        if (!_.isEmpty(query.query1))
                            _query += `${key}: "${query.query1}"`;
                        if (!_.isEmpty(query.query2))
                            _query += ` ${query.operation} ${key}: "${query.query2}"`;
                    }
                    break;
                }
                case "casenumber":
                case "externalId":
                case "caseName_en": {
                    if (!_.isEmpty(query.query1))
                        _query += `${query.queryType}: "${query.query1}"`;
                    break;
                }

                case "lt_contenttype": {
                    _query += `${query.queryType}: "${query._in}"`;
                    break;
                }
                case "iccSituation": {
                    _query += `iccSituations.title: "${query._in}"`;
                    // _query += `${query.queryType}: "${query._in}"`;
                    break;
                }
                case "title": {
                    if (!_.isEmpty(query.query1))
                        _query += `${query.queryType}: "${query.query1}"`;
                    if (!_.isEmpty(query.query2))
                        _query += ` ${query.operation} ${query.queryType}: "${query.query2}"`;
                }
            }

            queryString += _query;
        })
        return queryString;
    }
}


export const OAdvancedSearch = new AdvancedSearch();