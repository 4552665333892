const purlEs = `<div id="content" class="content">
    <div id="breadcrumbs">
        <p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="purl/">PURL</a></p>
    </div>
    <div id="getUrlText">
        <div
            style="cursor:pointer;float:right;"
            title="Collapse tree"
            id="collapseTree"
        >
            <i class="glyphicon glyphicon-collapse-down"></i>
        </div>
        <a href="javascript:window.print();" style="float:right;"
            ><i class="glyphicon glyphicon-print"></i
        ></a>
    </div>
    <br style="clear:both;" />
    <!--  CONTENT ELEMENT, uid:147/text [begin] -->
    <div id="c147" class="csc-default">
        <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1">
            <h1 class="csc-firstHeader">URL persistentes  (PURL)</h1>
        </div>
        <!--  Header: [end] -->

        <!--  Text: [begin] -->
        <p class="bodytext">
        La Base de Datos de Herramientas Jurídicas de la CPI (LTD) utiliza URL persistentes. Esto significa que la URL o dirección en la Internet de cada documento contenido en la LTD es permanente y no se modificará. En otras palabras, el usuario podrá hacer referencia a los documentos en la LTD mediante la inclusión de su PURL en su texto (o sus notas). Si enlaza el PURL con el documento en cuestión mediante un hipervínculo, el Proyecto de Herramientas Jurídicas nunca eliminará ese enlace.         </p>
        <p class="bodytext">
        Al utilizar estos PURL enlazados mediante un hipervículo a los documentos juridicos de la LTD libre y pública, el usuario contribuye a la construcción de un espacio común de referencias y fuentes jurídicas en continuo crecimiento. La LTD, puesto que presta sus servicios como bien común emergente en el derecho penal internacional, aporta igualdad de acceso a la información jurídica y establece un marco de igualdad entre quienes desarrollan sus actividades en esta esfera del derecho. Por tanto, el Proyecto de Herramientas Jurídicas contribuye al desarrollo de capacidades, especialmente en el plano nacional.
        </p>
        <p class="bodytext">
        El 30 de mayo de 2012, la Sala de Apelaciones de la CPI inició el uso de PURL de la LTD en sus decisiones.             <a href="https://www.legal-tools.org/en/doc/9813bb/" target="_blank"
                >La sentencia en el juicio Katanga con fecha de 7 de marzo de 2014</a
            >
            a CPI utilizan estos hipervínculos; mediante un Acuerdo de 10 años entre la CPI y el Centre for International Law Research and Policy (CILRAP, que se hace cargo de administrar la LTD para la CPI) fechado en marzo de 2021 la Corte se comprometió a crear hipervínculos para enlazar sus fallos u otros documentos o decisiones públicas pertinentes con los URL persistentes de la LTD, en la medida de lo posible. El Proyecto de Herramientas Jurídicas alienta a los tribunales, gobiernos, ONG, académicos, publicistas u otros a incluir referencias a los PURL con hipervínculos de enlace con la LTD siempre que ello sea factible.        
        </p>
        <ul>
            <li>
                <a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/161111_LTD_leaflet__Fourth_Edition.pdf" target="_blank">161116 Creación de un bien común de fuentes jurídicas en derecho penal internacional, cuarta edición</a>
            </li>
        </ul>
        <!--  Text: [end] -->
    </div>
    <!--  CONTENT ELEMENT, uid:147/text [end] -->
</div>`;

export default purlEs;
