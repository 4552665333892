import React, { FC, useMemo, useState } from "react";
import {
  createStyles,
  Paper,
  TableCell,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import TranslationJsonTree from "../../../Features/Translations/TranslationJsonTree";
import TranslationTable from "../../../Features/Translations/TranslationTable";
import { TState } from "../../../RootReducer";
import _ from 'lodash';
import { OTranslation } from "../../../Features/Translations/redux-config";
import TranslationJSON from '../../../Features/Translations/translations.json';
import TranslationModel from "../../../Models/Translations";
import Dashboard, { DashboardMenuItem } from "../../../Components/Dashboard";
import Screen from "./Screen";
import CmsForm from "./CmsForm";

export interface StaticTranslationProps
  extends WithStyles<typeof STYLES>,
  IStateProps,
  IDispatchProps,
  RouteComponentProps<{ path: string; id: string }> { }

interface IStateProps {
  staticTranslation: Record<string, any>
}

interface IDispatchProps {
  updateStaticTranslation: (id: string, data: Record<string, any>) => Promise<void>
}


const headers = [
  'English', 'French', "Spanish", "Chinese", "Russian"
]

const BASE_PATH = '/ltd-dashboard/static-translation';



interface FormState {
  open: boolean;
  screen: string
  formData: any
  title: string
}



const defaultFormState: FormState = {
  open: false,
  screen: '',
  formData: {},
  title: 'Edit'
}



function updateData(staticTranslation: Record<string, any>, key: string, data: Record<string, any>) {
  const ltd = staticTranslation.ltd || {};
  const fieldKey = data.key
  delete data.key
  ltd[key][fieldKey] = data
  staticTranslation.ltd = ltd
  return staticTranslation
}


const StaticTranslation: FC<StaticTranslationProps> = (props) => {
  const { match, history, location, classes, staticTranslation, updateStaticTranslation } = props;
  const [formState, setFormState] = useState<FormState>({
    ...defaultFormState
  });

  const data = staticTranslation && staticTranslation.ltd ? staticTranslation.ltd : TranslationJSON.ltd;
  const handleNodePress = (path: string) => {
    // if (history)
    //   history.push(`/ltd-dashboard/static-translation/${path}`);
    window.location.replace(`${window.location.origin}/ltd-dashboard/static-translation/${path}`)
  };


  const handleSubmit = async (data: Record<string, any>) => {
    const updatedTranslation = updateData(staticTranslation, formState.screen, data)
    if (!updatedTranslation || !updatedTranslation.id) return;
    await updateStaticTranslation(updatedTranslation.id, { ltd: updatedTranslation.ltd });
  }


  const handleSubmit_Old = async (data: Record<string, any>) => {
    if (!staticTranslation || !staticTranslation.id) return;
    await updateStaticTranslation(staticTranslation.id, { ltd: data });
  }


  const handleEdit = (key: string) => (data: any) => {
    const title = `Edit`
    setFormState(prev => ({ ...prev, title, formData: data, screen: key, open: true }))
  }


  const renderRow = (row: any) => {
    return (
      <>
        <TableCell>{row['en']}</TableCell>
        <TableCell>{row['fr']}</TableCell>
        <TableCell>{row['es']}</TableCell>
        <TableCell>{row['zh']}</TableCell>
        <TableCell>{row['ru']}</TableCell>
      </>
    )
  }
  const getData = (key: string) => async () => {
    const entry = data[key];
    if (!entry) return [];
    return Object.entries<any>(entry).map(([key, value]) => {
      if (key === 'label') return null;
      return { ...value, key }
    }).filter(x => !!x);
  }
  const handleClose = () => setFormState(prev => ({ ...prev, open: false }))



  const MENU_ITEM_NAV_LINKS: DashboardMenuItem[] = useMemo(() => {

    return Object.entries<any>(data).map(([key, value]) => {
      const path = key;
      const name = value.label;
      return {
        name, key, path,
        component: <Screen
          deps={[staticTranslation]}
          getData={getData(key)}
          handleEdit={handleEdit(key)}
          headers={headers}
          renderRow={renderRow}
        />
      }
    }).filter(i => !!i)
  }, [staticTranslation])

  // return (
  //   <div>
  //     <Dashboard
  //       navs={MENU_ITEM_NAV_LINKS}
  //       basePath={BASE_PATH}
  //     />
  //     <CmsForm
  //       open={formState.open}
  //       title={formState.title}
  //       onSubmit={handleSubmit}
  //       handleClose={handleClose}
  //       initialData={formState.formData}
  //       fields={[
  //         { label: 'English', key: 'en' },
  //         { label: 'French', key: 'fr' },
  //       ]}
  //     />
  //   </div>
  // )

  console.log("path s", location.pathname)

  if(location.pathname === BASE_PATH) 
    return <Redirect to={`${BASE_PATH}/general`} />
    
  return (
    <div>
      <Paper className={classes.treeContainer}>
        <TranslationJsonTree
          translationJSON={TranslationModel.mergeTrees(TranslationJSON['ltd'], staticTranslation.ltd)}
          selectedNode={match.params.path}
          onNodeClick={handleNodePress}
        />
      </Paper>
      <div className={classes.tableContainer}>
        <TranslationTable
          translationJSON={TranslationModel.mergeTrees(TranslationJSON['ltd'], staticTranslation.ltd)}
          nodePath={match.params.path}
          handleSubmit={handleSubmit_Old}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: TState) => ({
  ..._.pick(state.Translations, 'staticTranslation')
});

const mapDispatchToProps = (dispatch) => ({
  updateStaticTranslation: (id: string, data: Record<string, any>) => dispatch(OTranslation.patchStaticTranslation(id, data))
});

const STYLES = (theme: Theme) =>
  createStyles({
    treeContainer: {
      position: "fixed",
      left: 0,
      top: "50px",
      bottom: 0,
      width: "250px",
      overflow: "auto",
    },
    tableContainer: {
      position: "fixed",
      left: "250px",
      top: "50px",
      bottom: 0,
      right: 0,
      overflow: "auto",
    },
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(STYLES)(withRouter(StaticTranslation)));
