import React, { FC, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles, FormControl, Button, CircularProgress, LinearProgress, Typography, Divider } from '@material-ui/core';
import SimpleDialog from '../SimpleDialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import FormGroup, { IFormConfig } from '../FormGroup';
import SearchAdd from '../SearchAdd';
import { orderByFeaturedList, LTD_FEATURED_LANGUAGES } from '../../Features/AddNewLTDDocument/addNewLegalFinding-reducer';
import FileUpload from '../FileUpload';
import { TFile } from '../FileUpload/FileInput';
import { LtdDocService } from '../../Services/LtdDocs.service';
import { withTranslations, WithTranslationProps } from '../../Features/Translations/withTranslations';
import { MENU_LIST_ATTACH_FILE, MENU_LIST_COMMENTS, MENU_LIST_COMMENTS_IF_ANY, MENU_LIST_DATE_CREATED, MENU_LIST_EMAIL, MENU_LIST_EMAIL_ADDRESS, MENU_LIST_ENTER, MENU_LIST_FULLNAME, MENU_LIST_FULL_NAME_ENABLE, MENU_LIST_LANGUAGE, MENU_LIST_ORGANISATION_STATE, MENU_LIST_PLEASE_FILL, MENU_LIST_RESOURCE_FILE, MENU_LIST_SUGGEST_ADDITION, MENU_LIST_TITLE, MENU_LIST_TRANSLATED_TITLE, MENU_LIST_URL, MENU_LIST_YOU_CAN_SUGGEST } from '../../Features/Translations/translationKeys.ltd';


export interface SuggestDocAdditionProps extends WithStyles<typeof STYLES> {
    open: boolean
    handleClose: () => void
    includeIdOptions: Record<string, any>
    handleSubmitForm: (form: Partial<TSuggestDocAdditionForm>) => Promise<void>
}

export interface TSuggestDocAdditionForm {
    userName: string
    userEmail: string
    userComment: string
    title: string
    title_en: string
    organisation: string
    languageIds: string
    dateCreated: string
    orignalPdfURL: string
    documentFilePath: string
    documentName: string
    url: string
}

const SuggestDocAddition: FC<SuggestDocAdditionProps & WithTranslationProps> = (props) => {
    const { classes, open = false, includeIdOptions = {}, getTranslation } = props;
    const [formData, setFormData] = useState<Partial<TSuggestDocAdditionForm>>({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist && e.persist();
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async () => {
        setLoading(true)
        await props.handleSubmitForm(formData);
        setLoading(false)
    }

    const handleSuggestion = ({ name, value }) => {
        let _doc = formData;
        _doc = _.set(formData, name, value);
        setFormData(_doc);
    }

    const onFileUpload = async (file: TFile[]) => {
        try {
            const res = await LtdDocService.uploadFile(file);
            let _doc = formData;
            _doc = _.set(_doc, 'documentFilePath', res.data.documentFilePath);
            _doc = _.set(_doc, 'orignalPdfURL', res.data.orignalPdfURL);
            _doc = _.set(_doc, 'documentName', res.data.documentName);
            setFormData(_doc);
        } catch (error) {
            throw error
        }
    }


    const LTD_DOC_SUGGEST_FIELDS: IFormConfig[][] = [
        [{ label: getTranslation(MENU_LIST_TITLE) || 'Title', key: 'title', onChange: handleChange, type: 'text' }],
        [{ label: getTranslation(MENU_LIST_TRANSLATED_TITLE) || 'Translated title (EN)', key: 'title_en', onChange: handleChange, type: 'text' }],
        [{
            label: '', key: 'organisation', onChange: handleChange, type: 'component', component: <SearchAdd
                label={getTranslation(MENU_LIST_ORGANISATION_STATE) || "Organisation / State of Source"}
                formKey="organisation"
                key="organisation"
                value={_.get(formData, 'organisation') || ''}
                onChange={handleSuggestion}
                suggestionUrl="ltddocs/autocomplete"
                params={(term) => ({ term, field: 'organisation' })}
                handleSuggestions={(data) => data.map(val => val['organisation'])}
            />
        }],
        [{ label: getTranslation(MENU_LIST_LANGUAGE) || 'Language(s)', key: 'languageIds', onChange: handleChange, type: 'multiselect', options: orderByFeaturedList<{ name: string, value: string }>(_.get(includeIdOptions, 'languages') || [], LTD_FEATURED_LANGUAGES) }],
        [{
            label: getTranslation(MENU_LIST_DATE_CREATED) || 'Date created', key: 'dateCreated',
            onChange: handleChange, type: 'date',
            componentProps: {
                views: ["year", "month", "day"],
                KeyboardButtonProps: { className: classes.datepickerIcon },
                PopoverProps: { PaperProps: { classes: { root: classes.datePicker } } }
            }
        }],
        [{
            key: "orignalPdfURL",
            label: getTranslation(MENU_LIST_RESOURCE_FILE) || "Resource / File"
            ,
            onChange: () => { },
            type: 'component',
            component: (
                <FileUpload
                    pdfNameKey="documentName"
                    accept="application/pdf"
                    formData={formData}
                    formKey="orignalPdfURL"
                    onUpload={onFileUpload}
                    label={getTranslation(MENU_LIST_RESOURCE_FILE) || "Resource / File"}
                    id="resource-file"
                />
            )
        }],
        [{ label: getTranslation(MENU_LIST_URL) || 'URL', key: 'url', type: 'text', onChange: handleChange }]
    ]

    return (
        <SimpleDialog open={open} containerWidth={500} handleClose={props.handleClose} headerText={getTranslation(MENU_LIST_SUGGEST_ADDITION) || "Suggest addition of a document to the LTD."}>
            <Typography gutterBottom className="my-2" >{getTranslation(MENU_LIST_YOU_CAN_SUGGEST) || 'You can suggest addition of a document to the LTD with the help of this form. Please fill the form to the extent you can (all fields are optional).'}</Typography>

            <ValidatorForm onSubmit={handleSubmit}>
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_FULLNAME) || 'Full name'}
                    name="userName"
                    validators={['required']}
                    errorMessages={['Required']}
                    value={_.get(formData, 'userName') || ''}
                    onChange={handleChange}
                />
                <Typography variant="caption">{getTranslation(MENU_LIST_FULL_NAME_ENABLE) || "Full name (to enable the LTD Co-ordination Team to contact you regarding this suggestion)."}</Typography>
                <TextValidator
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_EMAIL) || 'E-mail'}
                    name="userEmail"
                    validators={['required', 'isEmail']}
                    errorMessages={['Required', 'Incorrect Email']}
                    value={_.get(formData, 'userEmail') || ''}
                    onChange={handleChange}
                />
                <Typography variant="caption">{getTranslation(MENU_LIST_EMAIL_ADDRESS) || "E-mail address (to enable the LTD Co-ordination Team to contact you regarding this suggestion)."}</Typography>

                <TextValidator
                    multiline
                    fullWidth
                    className="my-2"
                    label={getTranslation(MENU_LIST_COMMENTS) || 'Comments'}
                    name="userComment"
                    value={_.get(formData, 'userComment') || ''}
                    onChange={handleChange}
                />
                <Typography variant="caption">{getTranslation(MENU_LIST_COMMENTS_IF_ANY) || "Comments (if any for the LTD Co-ordination Team who will review this suggestion)."}</Typography>
                <div className="my-4" />
                <Divider />
                <div className="my-4" />



                <Typography>{getTranslation(MENU_LIST_PLEASE_FILL) || "Please fill in the metadata of the suggested document to the extent you can. You can upload the document using the Resource / File field."}</Typography>
                <FormGroup formData={formData} formItems={LTD_DOC_SUGGEST_FIELDS} />



                <Button className={classes.button} type="submit" fullWidth variant="contained" color="primary">
                    {loading ? <CircularProgress /> : getTranslation(MENU_LIST_ENTER) || 'ENTER'}
                </Button>
            </ValidatorForm>
        </SimpleDialog>
    )
}

const STYLES = (theme: Theme) => createStyles({
    button: {
        marginTop: 18
    },
    datepickerIcon: {
        padding: 2
    },
    datePicker: {
        width: 310,
        '& div > h4': {
            display: 'none'
        },
        '& > div:first-child': {
            height: 40
        }
    }
});

export default withStyles(STYLES)(withTranslations(SuggestDocAddition));