import { Reducer } from 'redux';
import { TAction } from '../../Resources/Model';
import { SearchObj, TNode } from '../CLDDocumentTree/@types';
import _ from 'lodash';
import { OCldDashboard } from './redux-config';
import { appendChildren, NODE_LABEL_URL, removeChildren } from '../CLDDocumentTree/cld-documentTree-reducer';
import { OCLDDocumentTree } from '../CLDDocumentTree/redux-config';
import { TLog } from '../LtdDashboard/@types';
import { DEFAULT_ACTIVITY_LOG_FILTER, TActivityLogFilter } from './Filters/activityLog';
import { DEFAULT_ROLES_FILTER, TRoleFilter } from './Filters/roles';
import utilities from '../../Resources/Utils';
import { TUser } from '../Authentication/@types';

export interface TCldDashboardState {
    loading: boolean;
    tree: Array<TNode>;
    initialTree: Array<TNode>;
    itemsSelected: Array<string>;
    idsToSearch: Array<SearchObj>;
    keywordText: string;
    activityLog: {
        logs: TLog[];
        pageNumber: number;
        filter?: TActivityLogFilter;
    };
    roles: {
        users: TUser[];
        total: number;
        pageNumber: number;
        searchTerm: string;
        filter?: TRoleFilter;
    };
}

const initialState: TCldDashboardState = {
    loading: false,
    keywordText: '',
    idsToSearch: [],
    initialTree: [],
    itemsSelected: [],
    tree: [],
    activityLog: {
        logs: [],
        pageNumber: 0,
        filter: { ...DEFAULT_ACTIVITY_LOG_FILTER },
    },
    roles: {
        users: [],
        total: 0,
        pageNumber: 0,
        searchTerm: '',
        filter: { ...DEFAULT_ROLES_FILTER },
    },
};

export const CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT = 20;

export const CldDashboard: Reducer<TCldDashboardState, TAction> = (state = initialState, action) => {
    const {
        CLD_DASHBOARD_FETCHED_CHILDREN,
        CLD_DASHBOARD_REMOVED_CHILDREN,
        CLD_DASHBOARD_RESET_TREE,
        CLD_DASHBOARD_TREE_ITEM_UNSELECTED,
        CLD_DASHBOARD_NEW_TREE_FETCHED,
        CLD_DASHBOARD_SET_LOADING,
        CLD_DASHBOARD_SET_KEYWORD_TEXT,
        CLD_DASHBOARD_ADDED_NEW_NODE,
        CLD_DASHBOARD_UPDATED_NODE,
        CLD_DASHBOARD_DELETED_NODE,
        // LOGS
        CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED,
        CLD_DASHBOARD_LOG_STATUS_UPDATED,
        CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED,
        CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER,
        // ROLES
        CLD_DASHBOARD_ROLES_FETCHED_USERS,
        CLD_DASHBOARD_ROLES_SET_SEARCH_TERM,
        CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER,
        CLD_DASHBOARD_ROLES_UPDATE,
        CLD_DASHBOARD_ROLES_SET_FILTER,
    } = OCldDashboard.actions;

    const { CLD_FETCHED_TREE_NODES } = OCLDDocumentTree.actions;

    switch (action.type) {
        case CLD_DASHBOARD_ROLES_UPDATE: {
            let updated_users = _.cloneDeep(state.roles.users);
            if (action.data.action === 'add') {
                updated_users = updated_users.map(user => {
                    if ((action.data.ids || []).includes(user.id)) {
                        user.roles.push({ name: action.data.role });
                    }
                    return user;
                });
            } else {
                updated_users = updated_users.map(user => {
                    if ((action.data.ids || []).includes(user.id)) {
                        user.roles = user.roles.filter(role => role.name !== action.data.role);
                    }
                    return user;
                });
            }
            return {
                ...state,
                roles: {
                    ...state.roles,
                    users: updated_users,
                },
            };
        }
        case CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER: {
            return { ...state, roles: { ...state.roles, pageNumber: action.data } };
        }
        case CLD_DASHBOARD_ROLES_SET_SEARCH_TERM: {
            return { ...state, roles: { ...state.roles, searchTerm: action.data } };
        }
        case CLD_DASHBOARD_ROLES_FETCHED_USERS: {
            return { ...state, roles: { ...state.roles, ...action.data } };
        }
        // TREE MANAGEMENT
        case CLD_DASHBOARD_UPDATED_NODE: {
            return { ...state };
        }
        case CLD_DASHBOARD_DELETED_NODE: {
            return { ...state };
        }
        case CLD_DASHBOARD_ADDED_NEW_NODE: {
            return { ...state };
        }
        case CLD_DASHBOARD_SET_KEYWORD_TEXT:
            return { ...state, keywordText: action.data };
        case CLD_DASHBOARD_SET_LOADING: {
            return { ...state, loading: action.data };
        }

        case CLD_DASHBOARD_NEW_TREE_FETCHED: {
            return { ...state, tree: action.data };
        }
        case CLD_DASHBOARD_TREE_ITEM_UNSELECTED:

        case CLD_DASHBOARD_RESET_TREE: {
            return { ...state, tree: _.cloneDeep(state.initialTree) };
        }
        case CLD_DASHBOARD_REMOVED_CHILDREN: {
            return { ...state, tree: removeChildren(state.tree, action.data) };
        }
        case CLD_DASHBOARD_FETCHED_CHILDREN: {
            const children = _.map(action.data.children, node => {
                node.hasChildrenMenuNode = !!node.childrenIds.length;
                return node;
            });
            return { ...state, tree: appendChildren(state.tree, children, action.data.parentId) };
        }
        case CLD_FETCHED_TREE_NODES: {
            const tree = _.map(action.data, node => {
                if (node.nodeType === 'LABEL') return { ...node, url: NODE_LABEL_URL[node.keyword_text] };
                else node.hasChildrenMenuNode = !!node.childrenIds.length;
                return node;
            });
            // here we store a copy of initial tree just in case the user wants to collapse/reset the tree
            return { ...state, tree, initialTree: _.cloneDeep(tree) };
        }

        // ACTIVITY LOGS
        case CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED: {
            const { appendResult = true } = action.data;
            return {
                ...state,
                activityLog: {
                    ...state.activityLog,
                    logs: appendResult ? [...state.activityLog.logs, ...action.data.logs] : action.data.logs || [],
                    pageNumber: action.data.pageNumber,
                },
            };
        }
        case CLD_DASHBOARD_LOG_STATUS_UPDATED: {
            if (_.isEmpty(action.data.logs) || !action.data.status) return state;
            let updatedActivityLogs: TLog[] = state.activityLog.logs.map<TLog>(log => {
                const _log = _.find(_.get(action.data, 'logs'), { id: log.id });
                const update = _.find(_.get(action.data, 'logUpdates'), { id: log.id });
                if (update || _log) return { ..._log, ...update, status: action.data.status };
                return log;
            });

            return { ...state, activityLog: { ...state.activityLog, logs: updatedActivityLogs } };
        }
        case CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED: {
            if (_.isEmpty(_.get(action, 'data.docPath')) || !_.get(action, 'data.logId')) return state;
            let activityLog = _.find(state.activityLog.logs, { id: action.data.logId }) as object;
            if (!activityLog) return state;
            const updateDocKey = action.data.updateDocKey || 'subject';
            let document = _.get(activityLog, updateDocKey) as object;
            if (_.isEmpty(document)) return state;
            document = { ...document, docPath: action.data.docPath };
            activityLog = _.set(activityLog, updateDocKey, document);
            return {
                ...state,
                activityLog: {
                    ...state.activityLog,
                    logs: utilities.updateItemList(state.activityLog.logs, { ...activityLog }, 'UPDATE'),
                },
            };
        }
        case CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER: {
            return { ...state, activityLog: { ...state.activityLog, filter: action.data } };
        }
        case CLD_DASHBOARD_ROLES_SET_FILTER: {
            return { ...state, roles: { ...state.roles, filter: action.data } };
        }
        default:
            return { ...state };
    }
};
