const contactUsZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
	<div id="c258" class="csc-default">
	<!--  Header: [begin] -->
		<div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">联系我们</h1></div>
	<!--  Header: [end] -->
		
	<!--  Text: [begin] -->
		<p  class="bodytext">
        联系国际刑事法院法律工具数据库（LTD）： <a href="mailto:info@cilrap.org">info@cilrap.org</a>. </p>
		<p  class="bodytext">
        你可以点击数据库菜单右上角的“报告问题”键：</p>
		<ul>
			<li>报告错误或者给出改进的建议；</li>
			<li>建议数据库增加一个特定文件；</li>
			<li>或者建议修改某文件的元数据。</li>
		</ul>
	<!--  Text: [end] -->
		</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
	</div>`;
export default contactUsZh;
