import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Snackbar, Button, SnackbarContent } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TToast, OApp } from '../RootReducer/AppReducer';
import Theme from '../Resources/Theme';

interface Props extends WithStyles<typeof STYLES> { }

interface IStateProps {
    toast: TToast
}

interface IDispatchProps {
    closeToast: Function
}


const ICONS = {
    success: 'check_circle',
    error: 'error'
}

class Toast extends Component<Props & IStateProps & IDispatchProps> {
    handleClose = () => {
        this.props.closeToast();
    }
    render() {
        const { classes, toast } = this.props
        return (
            <div>
                <Snackbar
                    open={toast.open}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoHideDuration={4000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        className={classes[toast.variant]}
                        // aria-describedby="client-snackbar"
                        message={
                            <span className={classes.message}>
                                <i className="material-icons">{ICONS[toast.variant]}</i>
                                {toast.message}
                            </span>
                        }
                        action={
                            <Button className={classes.btn} onClick={this.handleClose}>
                                <i className="material-icons">close</i>
                            </Button>
                        }
                    />

                </Snackbar>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.App, 'toast')
})

const mapDispatchToProps = dispatch => ({
    closeToast: () => dispatch(OApp.closeToast())
})

const STYLES = theme => createStyles({
    success: {
        background: Theme.Colors.Success
    },
    error: {
        background: Theme.Colors.Error
    },
    btn: {
        padding: 0,
        minWidth: 0,
        '& i': {
            color: 'white'
        }
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        '& i': {
            width: 30
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Toast))