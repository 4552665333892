const workToolStatusZh = `	
<div id="content" class="content">
<div id="breadcrumbs">
<p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/">Status of work on the Tools</a>
</p>
</div>
<div id="getUrlText">
<div style="cursor:pointer;float:right;" title="Collapse tree" id="collapseTree"><i
    class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();"
    style="float:right;"><i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:138/header [begin] -->
<div id="c138" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
    <h1 class="csc-firstHeader">工具系统现状</h1>
</div>
<!--  Header: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:138/header [end] -->

<!--  CONTENT ELEMENT, uid:137/html [begin] -->
<div id="c137" class="csc-default">
<!--  Raw HTML content: [begin] -->
<table class="public">
    <tbody>
        <tr>
            <td>国际刑事法院文件 </td>
            <td align="right">58,851</td>
        </tr>
        <tr>
            <td> 其他国际刑事管辖 </td>
            <td align="right">85,928</td>
        </tr>
        <tr>
            <td>国际法文件  </td>
            <td align="right">1,243</td>
        </tr>
        <tr>
            <td>人权法决定和文件</td>
            <td align="right">7,766</td>
        </tr>
        <tr>
            <td>其他国际法决定和文件</td>
            <td align="right">9,800</td>
        </tr>
        <tr>
            <td>国内刑事管辖 </td>
            <td align="right">20,101</td>
        </tr>
        <tr>
            <td>出版物 </td>
            <td align="right">3,825</td>
        </tr>
        <tr>
            <td>联合国战争罪委员会  </td>
            <td align="right">11,809</td>
        </tr>
        <tr>
            <td> 国际事实调查行动</td>
            <td align="right">1,802</td>
        </tr>
        <tr>
            <td></td>
            <td align="right"><strong>201,125</strong></td>
        </tr>
    </tbody>
</table>
<!--  Raw HTML content: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:137/html [end] -->

<!--  CONTENT ELEMENT, uid:50/text [begin] -->
<div id="c50" class="csc-default">
<!--  Text: [begin] -->
<p class="bodytext">（2023年4月5日数据）</p>
<p class="bodytext">
        数据库项目持续发展国际刑事法院法律工具数据库，每日更新文件和搜索数据。项目的明确目标是收录核心国际罪行司法从业者可能需要的所有法律文件 -- 无论是国际法还是国内法。
    </p>
<p class="bodytext">
数据库中几乎所有文件都根据法律工具项目的
     <a
        href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Metadata_Manual_version_1-1.pdf"
        target="_blank">《元数据手册》</a> （如文件名称和日期、发布时间和发布者）录入元数据。
</p>
<p class="bodytext">
    国际刑事法院致力于进一步发展法律工具系统的内容和技术指标。由于收集文件、登记每个文件的元数据、上传文件至数据库是劳动密集型工作，而国际刑事法院是一个人力资源有限的刑事法院，大部分法律工具相关工作由法院外部的人完成，并没有给法院带来额外支出。外部合作伙伴利用他们自己的人力资源并找到资金支持来完成这项工作。他们的贡献是以和国际刑事法院达成的正式合作协议为基础的。奥地利、德国、荷兰、挪威、瑞士政府支持了一些外部合作伙伴的法律工具相关工作。外部合作者之间的分工见下面的
     <a
        href="status-of-work-on-the-tools/table-of-responsibilities/">表格</a><a id="_anchor_1"
        name="_msoanchor_1"><u></u></a> .</p>
<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:50/text [end] -->
</div>

`;
export default workToolStatusZh;
