const coordinationZh = `
<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/" >Status of work on the Tools&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/background/" >Background</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:258/text [begin] -->
<div id="c258" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
<h1 class="csc-firstHeader">工作协调、案件矩阵网成员、机构合作者</h1></div>
<!--  Header: [end] -->			
<!--  Text: [begin] -->
<p class="bodytext"><span class="text_">
    根据国际刑事法院和国际法研究与政策中心
    (</span><a href="https://www.cilrap.org/purpose/" class="text_1">CILRAP</a><span class="text_">)
   于2021年3月9日签订的协议，CILRAP为国际刑事法院管理国际刑事法院法律工具数据库（LTD）。</span><a href="https://www.cilrap.org/bergsmo/" target="_blank">莫藤•伯格斯默</a>
   （Morten Bergsmo）是CILRAP负责人和法律工具项目的协调员。<a href="https://www.cilrap.org/bais/" target="_blank">德瓦希什•巴伊斯</a> （Devasheesh Bais）是法律工具项目的助理协调员。</p>
    <p class="bodytext"><span class="text_">
   CILRAP调度由40多名案件矩阵（CMN）成员组成的团队来协助数据库的更新和维护。
   CMN成员及下述机构合作者为数据库提供无偿服务。
CMN成员组成了一个富有多样性的团体，以公益精神长期为数据库贡献自己的力量。现任和前任CMN成员名单可</span><a target="_blank" href="https://www.casematrixnetwork.org/about-us/cmn-fellows/" class="text_3">在此</a>查阅。 有兴趣成为CMN成员者可发送简历至
    <a href="mailto:info@cilrap.org">
    info@cilrap.org</a>。
 选拔标准为：候选人的
 (a) 准确性 
 (b)长期投入的动力
 (c) 对知识传播和能力建设工作的兴趣 
 </span></p>
<p class="bodytext">
    以下是国际刑事法院与法律工具项目外部合作者达成的一些协议。这些合作伙伴在时间上有相互交替。 过往的合作者包括挪威人权中心、T.M.C Asser 研究所、TRIAL。最近几年，CMN成员更多地承担维持和更新法律工具数据库内容的工作。项目协调组感谢外部合作者和CMN成员的付出。
  </p>
<p class="bodytext">
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/051221_Intention_Agreement_Marburg__countersigned.pdf" class="text_3">051221_Intention_Agreement_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf" class="text_3">071121_Intention_Agreement_Amendment_Marburg__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090720_LT_CA_Korea_University__countersigned_.pdf" class="text_3">090720_LT_CA_Korea_University__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/090724_LT_CA_Nottingham__countersigned_.pdf" class="text_3">090724_LT_CA_Nottingham__countersigned_.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/091217_LT_CA_WRHWLI_countersigned.pdf" class="text_3">091217_LT_CA_WRHWLI_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100325_LT_CA_Goettingen__countersigned.pdf" class="text_3">100325_LT_CA_Goettingen__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf" class="text_3">100531_LT_CA_War_Crimes_Studies_Center__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/100921_LT_CA_Salzburg_countersigned.pdf" class="text_3">100921_LT_CA_Salzburg_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110117_LT_CA_University_of_Richmond.pdf" class="text_3">110117_LT_CA_University_of_Richmond.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/110610_LT_CA_Canadian_partners.pdf" class="text_3">110610_LT_CA_Canadian_partners.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111017_LT_Co-operation_Agreement_CEEI.pdf" class="text_3">111017_LT_Co-operation_Agreement_CEEI.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/111025_LT_Co-operation_Agreement_UTDT.pdf" class="text_3">111025_LT_Co-operation_Agreement_UTDT.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/140916_Co-operation_Agreement_Jindal_Global_University.pdf" class="text_3">140916_Co-operation_Agreement_Jindal_Global_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150424_LTP_Agreement_Ain_Shams_countersigned.pdf" class="text_3">150424_LTP_Agreement_Ain_Shams_countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150513_Agreement_UKZN-UCT__countersigned.pdf" class="text_3">150513_Agreement_UKZN-UCT__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf" class="text_3">150724_Aoyama_Gakuin_LTP_Agreement__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Nottingham.pdf" class="text_3">150918_LT_CA_Nottingham.pdf</a></div>
<div style="margin-bottom:8px;"><a lang="nb" target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150918_LT_CA_Stanford_University.pdf" class="text_3">150918_LT_CA_Stanford_University.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/150921_LT_CA_NUS_Faculty_of_Law.pdf" class="text_3">150921_LT_CA_NUS_Faculty_of_Law.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151007_LT_CA_PKU_Law_School__countersigned.pdf" class="text_3">151007_LT_CA_PKU_Law_School__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_Canadian_partners__countersigned.pdf" class="text_3">151019_LT_CA_Canadian_partners__countersigned.pdf</a></div>
<div style="margin-bottom:8px;"><a target="_blank" href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151019_LT_CA_ULB.pdf" class="text_3">151019_LT_CA_ULB.pdf</a></div>
</p>

<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:258/text [end] -->
</div>
`;
export default coordinationZh;
