import _ from 'lodash';
import { TBatchReviewState } from './@types';
import { BatchReview } from './redux-config';

const initialState: TBatchReviewState = {
    docIds: [],
    form: {},
    reviewDialogOpen: false,
    loading: false,
    publishedDocIds: [],
    selectedDocIds: []
}

export const BatchReviewReducer = (state = { ...initialState }, action: any): TBatchReviewState => {
    const {
        BATCH_REVIEW_DIALOG_CLOSE,
        BATCH_REVIEW_DIALOG_OPEN,
        BATCH_REVIEW_CLEAR_SELECTION,
        BATCH_REVIEW_SELECT_ALL_DOCUMENTS,
        BATCH_REVIEW_TOGGLE_DOC_SELECTION,
        BATCH_REVIEW_SET_FORM,
        BATCH_REVIEW_SET_LOADING,
        BATCH_REVIEW_DOCUMENTS_PUBLISHED
    } = BatchReview.actions;

    switch (action.type) {
        case BATCH_REVIEW_DOCUMENTS_PUBLISHED: {
            let _form = { ...state.form };
            _.forEach(action.data, id => {
                if (_form[id]) _form[id].isPublished = true;
            })
            return { ...state, form: _form }
        }
        case BATCH_REVIEW_SET_LOADING: {
            return { ...state, loading: action.data }
        }
        case BATCH_REVIEW_SET_FORM: {
            return { ...state, form: action.data };
        }
        case BATCH_REVIEW_CLEAR_SELECTION: {
            return { ...state, selectedDocIds: [] }
        }
        case BATCH_REVIEW_SELECT_ALL_DOCUMENTS: {
            return { ...state, selectedDocIds: [...state.docIds] }
        }
        case BATCH_REVIEW_TOGGLE_DOC_SELECTION: {
            const docId = action.data;
            const updatedSelection = state.selectedDocIds.includes(docId) ? state.selectedDocIds.filter(i => i !== docId) : [...state.selectedDocIds, docId];
            return { ...state, selectedDocIds: updatedSelection };
        }
        case BATCH_REVIEW_DIALOG_OPEN: {
            return { ...state, docIds: action.data, reviewDialogOpen: true };
        }
        case BATCH_REVIEW_DIALOG_CLOSE: {
            return { ...initialState };
        }
        default: return { ...state }
    }
}