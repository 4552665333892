import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme, Paper, Typography, Button, Fab } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import Tree from '../../../Components/Tree';
import { OLtdDashboard } from '../../../Features/LtdDashboard/redux-config';
import { ODocumentTree } from '../../../Features/DocumentsTree/redux-config';
import { OApp } from '../../../RootReducer/AppReducer';
import THEME from '../../../Resources/Theme';
import _ from 'lodash';
import { TNode } from '../../../Features/DocumentsTree/@types';
import { TGroup } from '../../../Features/LtdDashboard/@types';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';

interface IStateProps {
    tree: TNode[]
    selectedIds: string[]
    groupDetail: TGroup
}

interface IDispatchProps {
    fetchGroupDetail?: (groupId: string) => any
    onTreeItemCheck?: (id: string) => void
    openNode?: (parentId) => void
    closeNode?: (parentId) => void
    resetTree?: () => void
    closeSidebar?: () => void
    editGroup?: (formData, groupId) => any
    clearSelection?: () => void
}

interface IState {

}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {
}

class NodeTreeContainer extends Component<IProps, IState> {

    handleSidebarClose = () => {
        if (!this.props.closeSidebar)
            return;

        this.props.closeSidebar();
    }

    onCheck = (node: TNode) => {
        if (!this.props.onTreeItemCheck)
            return;

        this.props.onTreeItemCheck(node.id);
    }

    onTreeContentClick = () => {

    }

    openNode = (parentId: string) => {
        if (!this.props.openNode)
            return;
        this.props.openNode(parentId)
    }

    closeNode = (parentId: string) => {
        if (!this.props.closeNode) return;
        this.props.closeNode(parentId);
    }

    handleSubmit = async () => {
        if (!this.props.editGroup)
            return;

        const { groupDetail, selectedIds } = this.props;
        const formData = {
            assignedNodeIds: _.union(groupDetail.assignedNodeIds || [], selectedIds)
        };

        await this.props.editGroup(formData, groupDetail.id);

        if (this.props.fetchGroupDetail)
            this.props.fetchGroupDetail(groupDetail.id)
        if (this.props.clearSelection)
            this.props.clearSelection();
    }

    render() {
        const { classes, selectedIds, tree, groupDetail } = this.props;


        return (
            <div className={classes.sidebar}>
                <div className={classNames(classes.header, 'flex-row space-between align-start')}>
                    <Typography>
                        <b>
                            ASSIGN NEW NODE TO
                            <br />
                            {groupDetail.name}
                        </b>
                    </Typography>
                    <Fab className={classes.closeBtn} size='small' onClick={this.handleSidebarClose}>
                        <i className='material-icons'>close</i>
                    </Fab>
                </div>
                <div className={classes.container}>
                    <Tree
                        color='#005aa9'
                        classes={{ container: classes.treeContainer }}
                        expandOnKey='hasChildrenMenuNode'
                        onCollapse={this.props.resetTree}
                        onOpen={this.openNode}
                        onClose={this.closeNode}
                        onClick={this.onTreeContentClick}
                        disableCheck={false}
                        // onAdd={this.onAdd}
                        checked={selectedIds}
                        tree={tree}
                        onCheck={this.onCheck}
                    />
                </div>
                <Button
                    onClick={this.handleSubmit}
                    className={classes.submitBtn}
                    fullWidth
                    color='primary'
                    variant='contained'
                >
                    submit
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state): IStateProps => ({
    ..._.pick(state.LtdDashboard, ['selectedIds']),
    groupDetail: _.get(state.LtdDashboard, 'group.groupDetail'),
    tree: _.get(state, 'DocumentTree.tree'),
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchGroupDetail: (groupId: string) => dispatch(OLtdDashboard.fetchGroupDetail(groupId)),
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    onTreeItemCheck: (id: string) => dispatch(OLtdDashboard.toggleCheck(id)),
    openNode: (parentId) => dispatch(ODocumentTree.fetchChildren(parentId)),
    editGroup: (formData, groupId) => dispatch(OLtdDashboard.editGroup(formData, groupId)),
    closeNode: (parentId) => dispatch(ODocumentTree.removeChildren(parentId)),
    resetTree: () => dispatch(ODocumentTree.resetTree()),
    clearSelection: () => dispatch(OLtdDashboard.resetSelection())
})

const STYLES = (theme: Theme) => createStyles({
    sidebar: {
        position: 'relative',
        boxSizing: 'border-box',
        padding: '10px 20px',
        overflow: 'auto',
        background: THEME.Colors.Grayscale.White,
        width: 400,
    },
    container: {
        padding: '50px 0px',
    },
    treeContainer: {
        width: '100%'
    },
    header: {
        boxShadow: `0px 1px 3px ${fade('#000', 0.2)}`,
        position: 'fixed',
        height: 50,
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '0px 10px 0px 20px',
        background: 'white',
        width: 400,
        right: 0,
        top: 0,
        zIndex: 1,
    },
    closeBtn: {
        background: 'white',
        boxShadow: 'none'
    },
    submitBtn: {
        position: 'fixed',
        width: 400,
        color: 'white',
        bottom: 0,
        right: 0,
        borderRadius: 0,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(NodeTreeContainer))