import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    DialogTitle,
    Typography,
    Button,
    CircularProgress,
    Fab,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Theme from '../../Resources/Theme';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import QueryBuilder from './queryBuilder';
import { OAdvancedSearch } from './redux-config';
import { ODocuments } from '../LTDDocuments/redux-config';
import { TState } from '../../RootReducer';
import { OResponsive } from '../Responsive/redux-config';
import CLDQueryBuilder from './CLDQueryBuilder';
import { OCLDDocuments } from '../CLDDocuments/redux-config';
import { TCLDAvdancedSearchOperator } from './@types';
import {
    WithTranslationProps,
    withTranslations,
} from '../Translations/withTranslations';
import {
    ADVANCED_SEARCH_DIALOG_SEARCH_DECISION_BUTTON,
    ADVANCED_SEARCH_DIALOG_SEARCH_LEGAL_FINDING_BUTTON,
    ADVANCED_SEARCH_DIALOG_TITLE,
} from '../Translations/translationKeys.cld';
import {
    ADVANCED_SEARCH_RESET_BUTTON,
    ADVANCED_SEARCH_SEARCH_BUTTON,
} from '../Translations/translationKeys.ltd';

interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    WithTranslationProps { }

interface IDispatchProps {
    closeDialog: Function;
    applySearchTerm: Function;
    searchDocuments: Function;
    searchCLDDocuments: Function;
    buildQuery: Function;
    setDecisionSearch: (isDecisionSearch: boolean) => void;
    closeResponsiveDialog: () => void;
    buildCLDQuery: (queries) => void;
    applyCLDSearchTerm: (term) => void;
    setLTDFormData: (key, value) => void;
    setCLDFormData: (key, value) => void;
    setOperator: (value: TCLDAvdancedSearchOperator) => void;
    setBuildTermFromQuery: (flag: boolean) => void;
}

interface IStateProps {
    queries: Array<object>;
    operator: TCLDAvdancedSearchOperator;
    type: 'LTD' | 'CLD';
}

class AdvancedSearch extends Component<
    Props & IStateProps & WithTranslationProps
> {
    state = {
        queries: [{}],
    };

    componentDidMount = () => {
        this.setState({ queries: this.props.queries });
    };

    componentDidUpdate = prevProps => {
        // if (prevProps.queries !== this.props.queries && !_.isUndefined(this.props.queries))
        //     this.setState({ queries: this.props.queries });
    };

    addQueryBuilder = () => {
        this.setState({
            queries: [...this.state.queries, {}],
        });
    };

    removeQueryBuilder = (index: number) => {
        const queries = _.filter(this.state.queries, (query, i) => i !== index);
        this.setState({ queries });
    };

    onChange = (index: number, query: object) => {
        let queries = this.state.queries;

        if (index > _.size(queries)) return;

        queries[index] = query;
        this.setState({ queries });
    };

    searchLTDDocs = () => {
        const queryString = this.props.buildQuery(this.state.queries);
        this.props.applySearchTerm(queryString);
        this.props.setLTDFormData('term', queryString);
        this.props.searchDocuments();
    };

    searchCLDDocs = () => {
        this.props.setDecisionSearch(false);
        // const queryString = this.props.buildQuery(this.state.queries);
        const queryString = this.props.buildCLDQuery(this.state.queries);
        this.props.applyCLDSearchTerm(queryString);
        this.props.setCLDFormData('term', queryString);
        this.props.searchCLDDocuments();
    };

    onSearch = () => {
        // this.props.setBuildTermFromQuery(true);
        if (this.props.type === 'LTD') this.searchLTDDocs();
        else this.searchCLDDocs();

        this.props.closeResponsiveDialog();
        this.props.closeDialog();
    };

    onDecisionSearch = () => {
        this.props.setDecisionSearch(true);
        const queryString = this.props.buildCLDQuery(this.state.queries);
        this.props.applyCLDSearchTerm(queryString);
        this.props.setCLDFormData('term', queryString);
        this.props.searchCLDDocuments();
        this.props.closeResponsiveDialog();
        this.props.closeDialog();
    };

    changeOperator = (_, op) => {
        this.props.setOperator(op);
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.onSearch();
        }
    };

    reset = () => {
        this.setState({ queries: [{}] });
    };

    renderActionButtons = () => {
        const {
            classes,
            type = 'LTD',
            operator = 'AND',
            getTranslation,
        } = this.props;

        if (type === 'LTD')
            return (
                <div className={classes.footer}>
                    <Button
                        variant='text'
                        className='mx-2'
                        color='primary'
                        onClick={this.reset}
                    >
                        {getTranslation(ADVANCED_SEARCH_RESET_BUTTON)}
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.onSearch}
                    >
                        {getTranslation(ADVANCED_SEARCH_SEARCH_BUTTON)}
                    </Button>
                    {/* <Button onClick={e => this.props.closeDialog()}>Close</Button> */}
                </div>
            );

        return (
            <div className={classes.cldfooter}>
                {/* <div>

                    <RadioGroup
                        aria-label="advanced-search-operator"
                        name="operator"
                        className={classes.group}
                        value={operator}
                        onChange={this.changeOperator}
                    >
                        <FormControlLabel value="AND" control={<Radio color="primary" />} label="AND" />
                        <FormControlLabel value="OR" control={<Radio color="primary" />} label="OR" />
                        <FormControlLabel value="NOT" control={<Radio color="primary" />} label="NOT" />
                    </RadioGroup>
                </div> */}
                <div style={{ flex: 1 }} />
                <div>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.onSearch}
                    >
                        {getTranslation(
                            ADVANCED_SEARCH_DIALOG_SEARCH_LEGAL_FINDING_BUTTON
                        )}
                    </Button>
                    <Button color='primary' onClick={this.onDecisionSearch}>
                        {getTranslation(
                            ADVANCED_SEARCH_DIALOG_SEARCH_DECISION_BUTTON
                        )}
                    </Button>
                </div>
                {/* <Button onClick={e => this.props.closeDialog()}>Close</Button> */}
            </div>
        );
    };

    render() {
        const { classes, type = 'LTD', getTranslation } = this.props;

        const QueryBuilderComponent =
            type === 'LTD' ? QueryBuilder : CLDQueryBuilder;

        return (
            <div className={classes.root}>
                <div className={classes.row}>
                    <Typography align='center' variant='body1'>
                        {getTranslation(ADVANCED_SEARCH_DIALOG_TITLE)}
                    </Typography>
                    <Fab
                        className={classes.fab}
                        size='small'
                        onClick={e => this.props.closeDialog()}
                    >
                        <i className='material-icons'>close</i>
                    </Fab>
                </div>

                <form onKeyDown={this.handleKeyDown}>
                    <ul className={classes.queries}>
                        {_.map(this.state.queries, (query, index) => (
                            <li key={index}>
                                <QueryBuilderComponent
                                    index={index}
                                    isLastEntry={
                                        index === _.size(this.state.queries) - 1
                                    }
                                    query={query}
                                    addQuery={this.addQueryBuilder}
                                    removeQuery={this.removeQueryBuilder}
                                    // onClick={e => {
                                    //     index === _.size(this.state.queries) - 1
                                    //         ? this.addQueryBuilder()
                                    //         : this.removeQueryBuilder(index)
                                    // }}
                                    onChange={this.onChange}
                                />
                            </li>
                        ))}
                    </ul>
                </form>

                {this.renderActionButtons()}
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.AdvancedSearch, ['queries', 'type', 'operator']),
});

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(OAdvancedSearch.closeDialog()),
    applySearchTerm: term => dispatch(ODocuments.applySearchTerm(term)),
    applyCLDSearchTerm: term => dispatch(OCLDDocuments.applySearchTerm(term)),
    searchDocuments: () => dispatch(ODocuments.searchDocuments()),
    setDecisionSearch: (isDecisionSearch: boolean) =>
        dispatch(OCLDDocuments.setDecisionSearch(isDecisionSearch)),
    searchCLDDocuments: () => dispatch(OCLDDocuments.searchDocuments()),
    closeResponsiveDialog: () => dispatch(OResponsive.closeDialog()),
    buildQuery: queries => dispatch(OAdvancedSearch.buildQuery(queries)),
    buildCLDQuery: queries => dispatch(OAdvancedSearch.buildCLDQuery(queries)),
    setLTDFormData: (key, value) =>
        dispatch(ODocuments.setFormData(key, value)),
    setCLDFormData: (key, value) =>
        dispatch(OCLDDocuments.setFormData(key, value)),
    setOperator: value => dispatch(OAdvancedSearch.setOperator(value)),
    setBuildTermFromQuery: (flag: boolean) =>
        dispatch(OAdvancedSearch.setBuildTermFromQuery(flag)),
});

const STYLES = theme =>
    createStyles({
        root: {
            boxSizing: 'border-box',
            [theme.breakpoints.up('sm')]: {
                width: 1000,
                height: 600,
                padding: 30,
            },
        },
        fab: {
            boxShadow: 'none',
            background: 'white',
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            '& p': { flex: 1, fontSize: 28 },
        },
        queries: {
            borderTop: `1px solid ${Theme.Colors.Fourth}`,
            borderBottom: `1px solid ${Theme.Colors.Fourth}`,
            position: 'absolute',
            top: 80,
            bottom: 50,
            left: 30,
            right: 30,
            listStyleType: 'none',
            overflow: 'scroll',
            padding: 0,
            '& li': {
                margin: '28px 0px',
            },
            [theme.breakpoints.down('sm')]: {
                top: 30,
                left: 0,
                right: 0,
                bottom: 50,
            },
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            height: 50,
            left: 30,
            right: 30,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        cldfooter: {
            position: 'absolute',
            bottom: 0,
            height: 64,
            left: 30,
            right: 30,
            display: 'flex',
            // justifyContent: 'flex-end'
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div': {
                display: 'flex',
                justifyContent: 'flex-end',
            },
        },
        group: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            paddingLeft: 8,
            '& span': {
                // color: 'white'
            },
        },
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(AdvancedSearch)));
