import React, { createContext, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import LTD_THEME from '../../MuiTheme/ltd.theme';
import SCC_THEME from '../../MuiTheme/scc.theme';
import CLD_THEME from '../../MuiTheme/cld.theme'
import THEME from '../../MuiTheme'
import { TAppContext } from '../AppContext';

export const ThemeContext = createContext<TThemeContextProviderValue>({
    theme: LTD_THEME,
    currentTheme: 'ltd',
    setAppTheme: () => { }
});



export const ThemeContextProvider: React.FC = (props) => {
    const [currentTheme, setCurrentTheme] = useState<TAppContext>('ltd');

    const setAppTheme = (type: TAppContext) => setCurrentTheme(type);
    console.log('currentTheme', currentTheme);


    const providerValue: TThemeContextProviderValue = {
        theme: currentTheme === 'ltd' ? LTD_THEME : currentTheme === 'scc' ? SCC_THEME : CLD_THEME,
        currentTheme,
        setAppTheme
    }





    return (
        <ThemeContext.Provider value={providerValue}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export type TThemeContextProviderValue = {
    theme: Theme
    currentTheme: TAppContext
    setAppTheme: (type: TAppContext) => void
}
