import { TUTORIAL_FILM_LEXSITUS_URLS } from "../../../Resources/constants";
const lexsitusZh=`
<h1>Lexsitus</h1>
<p><a href="https://cilrap-lexsitus.org/" target="_blank">Lexsitus</a> 是一个开放获取的网络平台，其对法律工具数据库有补充作用。用户可以通过法律工具数据库找到相关的国际刑法文件，并通过Lexsitus免费获取以下资源：</p>
<ul>
<li>近2000页持续更新的关于《国际刑事法院规约》及《国际刑事法院程序与证据规则》的逐条评注； </li>
<li>两个关于核心国际罪行要素及其证明方法的案例摘要；</li>
<li>《国际刑事法院规约》及其修正案的准备工作文件；和</li>
<li>包括230多个关于《国际刑事法院规约》条款和国际刑法基本原则的讲座的视频库。</li>
</ul>  

<p>
Lexsitus的所有资源都根据《国际刑事法院规约》的条款结构排列（用户可以通过Lexsitus页面左边的规约条款或者菜单栏获取相关信息）。Lexsitus的资源还通过超级链接的方式连接到法律工具数据库（Lexsitus是在法律工具数据库基础上建立数据库和其他网络服务的有力证明）。
</p> 
<p>
最近关于<a href="https://mailchi.mp/cilrap/ps-9298146?e=eace4a0eba" target="_blank">Lexsitus 2.0.的公告</a>指出评注有英语、阿拉伯语、法语版本；视频的字幕包括英语、阿拉伯语、法语和波斯语；犯罪要素案例摘要有英语、阿拉伯语、法语、西班牙语版本 – 正如<a href="https://cilrap-lexsitus.org/en/introduction" target="_blank">国际刑事法院检察官卡里姆A.A.汗（Karim A. A. Khan）在这一视频中</a>强调的那样。 
</p>
<p>
Lexsitus已经被电子公共产品联盟（Digital Public Goods Alliance）认证为电子公共产品。Lexsitus系统由莫腾·伯格斯莫（Morten Bergsmo）创立，其协调员为安东尼奥·安格蒂（Antonio Angotti，可发送邮件至<a href="mailto:angotti@cilrap.org">angotti@cilrap.org</a>向其咨询Lexsitus相关问题）。 
</p>

<p>
以下视频介绍了如何使用Lexsitus的服务及其在法律工具数据库中的超级链接：
</p> 
<div class="grid"> 
${TUTORIAL_FILM_LEXSITUS_URLS.map(
    (url, i) => {
        i += 19;
        return `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i}.png" style="width: 100%;" alt="Tutorial ${i}" />
        </a>
        `;
    }
).join('')}

</div>
`;
export default lexsitusZh;