import React, { useEffect, useState } from 'react';
import { HISTORY } from '../screens/RootLayout';

const SESSION_TIME = 'surveySessionTime';
const SESSION_COUNT = 'surveySessionCount';
const LATER_MARK_DATE = 'surveyLaterMarkDate';
const DO_NOT_SHOW_AGAIN = 'surveyNeverMarked';

const SESSION_TIME_DISPLAY_DURATION = 5000;
const THREE_MONTHS_TIME_DURATION = 1000 * 60 * 60 * 24 * 30 * 3;
export const useSurvey = () => {
    const [isShowSurvey, setIsShowSurvey] = useState(false);
    const isEligibleForSurvey = () => {
        const doNotShow = localStorage.getItem(DO_NOT_SHOW_AGAIN || 'false');
        if (doNotShow === 'true') {
            return false;
        }
        const laterMarkedSurvey = parseInt(localStorage.getItem(LATER_MARK_DATE) || '0');
        const currentTime = new Date().getTime();

        if (currentTime - laterMarkedSurvey < 300000) {
            return false;
        }

        const sessionStartTime = parseInt(localStorage.getItem(SESSION_TIME) || '0');
        if (currentTime - sessionStartTime > SESSION_TIME_DISPLAY_DURATION) {
            return true;
        }
        return false;
    };
    const showLater = () => {
        localStorage.setItem(LATER_MARK_DATE, new Date().getTime().toString());
        setIsShowSurvey(false);
    };
    const doNotShowAgain = () => {
        localStorage.setItem(DO_NOT_SHOW_AGAIN, 'true');
        setIsShowSurvey(false);
    };
    useEffect(() => {
        localStorage.setItem(SESSION_TIME, new Date().getTime().toString());
        HISTORY.listen((location, action) => {
            if (location && location.pathname === '/' && isEligibleForSurvey()) {
                setTimeout(function() {
                    setIsShowSurvey(true);
                }, 1000);
            }
        });
    }, []);
    return {
        isShowSurvey,
        showLater,
        doNotShowAgain,
    };
};
