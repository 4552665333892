import { TUTORIAL_FILM_LEXSITUS_URLS } from "../../../Resources/constants";
const lexsitusEn=`
<h1>Lexsitus</h1>
<p><a href="https://cilrap-lexsitus.org/" target="_blank">Lexsitus</a> is an open access Internet platform that supplements the LTD.  Whereas the LTD helps you to find documents    relevant to international criminal law, you can use Lexsitus to get free online access to:</p>
<ul>
<li>
a continuously updated commentary of more than 2,000 book-pages covering every provision of the ICC Statute and the ICC Rules of Procedure and Evidence; </li>
<li>
two digests of case-law extracts on the elements of core international crimes and means of proof for these elements; 
</li>
<li>
the preparatory works of the ICC Statute and its amendments; and 
</li>
<li>a video library of more than 230 lectures on the provisions of the ICC Statute and general principles of international criminal law.</li>
</ul>  

<p>
All Lexsitus resources are structured on the level of the articles of the ICC Statute (so you can access the information through the articles in the left-margin spine of Lexsitus or the menus). Moreover, the Lexsitus resources are hyperlinked to the LTD (Lexsitus therefore exemplifies how databases and other online services can build on the LTD). 
</p> 
<p>
A recent <a href="https://mailchi.mp/cilrap/ps-9298146?e=eace4a0eba" target="_blank">announcement of Lexsitus 2.0.</a> describes how the commentary is available in English, Arabic and French; the video subtitles are available in English, Arabic, French and Persian; and the Elements Digest in English, Arabic, French and Spanish – as noted in <a href="https://cilrap-lexsitus.org/en/introduction" target="_blank">this film on Lexsitus 2.0. by ICC Prosecutor Karim A.A. Khan KC</a>.  
</p>
<p>
Lexsitus is certified by the Digital Public Goods Alliance as a digital public good. It was created by Morten Bergsmo and its Co-ordinator is Antonio Angotti (who can be contacted at <a href="mailto:angotti@cilrap.org">angotti@cilrap.org</a> for questions about Lexsitus). 
</p>

<p>
Below you find some tutorials on how to use the services in Lexsitus which are hyperlinked to the LTD.
</p> 
<div class="grid">
${TUTORIAL_FILM_LEXSITUS_URLS.map(
    (url, i) => {
        i += 19;
        return `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i}.png" style="width: 100%;" alt="Tutorial ${i}" />
        </a>
        `;
    }
).join('')}

</div>
`;
export default lexsitusEn;