import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import THEME from '../../Resources/Theme';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import TreeManagement from './TreeManagement/index';
import Home from './Home';
import Log from './Log/index';
import Partners from './Partners/index';
import Users from './Users/index';
import GroupDetail from './Partners/GroupDetail';
import _ from 'lodash';
import { ILtdPermissions, TUser } from '../../Features/Authentication/@types';
import utilities from '../../Resources/Utils';
import ImporterLog from './ImporterLog';
import { OLtdDashboard } from '../../Features/LtdDashboard/redux-config';
import BatchEditLog from './BatchEditLog';
import { TState } from '../../RootReducer';
import FullscreenLoader from '../../Components/FullscreenLoader';
import Suggestions from './Suggestions';
import MetaTag from '../../Components/MetaTag';
import StaticTranslation from './StaticTranslation';

interface IStateProps {
    user: TUser;
    loading: boolean;
}

interface IDispatchProps {
    getPermittedNodes: (userId: string) => void
}

interface IState { }

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps { }

export type TDashboardTabKey = "treeManagement"
    | 'log'
    | 'partners'
    | 'users'
    | 'importerLog'
    | 'batchEditLog'
    | 'csvImport'
    | 'suggestions'
    | 'translations'

export const SCC_DASHBOARD_ROUTES: { route?: string, path: string, component: any, name: string, key: TDashboardTabKey, exact?: boolean; }[] = [
    { path: "/scc-dashboard/tree-management", component: TreeManagement, name: 'DOCS', key: 'treeManagement' },
    // { path: "/scc-dashboard/log", component: Log, name: 'LOG', key: 'log' },
    // { path: "/scc-dashboard/batch-edit-log", component: BatchEditLog, name: 'BATCH EDIT', key: 'batchEditLog' },
    // { path: "/scc-dashboard/partners", component: Partners, name: 'PARTNERS/USERS', key: 'partners' },
    { path: "/scc-dashboard/users", component: Users, name: 'USERS', key: 'users' },
    // { path: "/scc-dashboard/importer-log", component: ImporterLog, name: 'IMPORTER LOG', key: 'importerLog' },
    // { path: "/scc-dashboard/suggestions", component: Suggestions, name: 'SUGGESTIONS', key: 'suggestions' },
    // {
    //     path: "/scc-dashboard/static-translation",
    //     route: "/scc-dashboard/static-translation/:path?",
    //     component: StaticTranslation,
    //     name: 'TRANSLATIONS',
    //     key: 'translations',
    //     exact: false
    // },
]

class SCCDashboard extends Component<IProps, IState> {

    componentDidMount() {
        const { user } = this.props;
        if (_.isEmpty(user))
            return;
        this.props.getPermittedNodes(user.id);
    }

    render() {
        const { classes, location, loading = false } = this.props;
        const path = location.pathname;


        if (!utilities.isAuthenticated()) {
            return <Redirect to="/" />
        }

        return (
            <div className={classes.root}>
                <MetaTag title='Dashboard' />
                <Switch>
                    <Route exact key={'/scc-dashboard'} path={'/scc-dashboard'} component={Home} />
                    {SCC_DASHBOARD_ROUTES.map(route => {
                        return (
                            <Route
                                key={route.path}
                                path={route.route || route.path}
                                exact={route.exact !== undefined ? route.exact : true}
                                component={route.component}
                            />
                        );
                    })}
                    <Route path='/scc-dashboard/partners/:id' exact component={GroupDetail} />
                </Switch>
                {loading ? <FullscreenLoader color='primary' /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state: TState): IStateProps => ({
    ..._.pick(state.Auth, ['user']),
    ..._.pick(state.LtdDashboard, 'loading')
})

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    getPermittedNodes: (userId) => dispatch(OLtdDashboard.getPermittedNodes(userId))
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        height: '100vh',
        paddingTop: 50,
        background: THEME.Colors.Grayscale.Grey5,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 2,
        position: 'absolute',
        overflowY: 'auto'
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(SCCDashboard)));