import _ from 'lodash';
import {
    METADATA_HEADER_CHINESE_LANG,
    METADATA_HEADER_ENG_LANG,
    METADATA_HEADER_FRENCH_LANG,
    METADATA_HEADER_RUSSIAN_LANG,
    METADATA_HEADER_SPANISH_LANG,
} from '../../Features/Translations/translationKeys.ltd';

import { StaticTranslationNode, TLeaf, TranslationLocale, TreeNode } from './@types';

type TTranslationItem = {
    name: string;
    value: string;
    translationKey?: string;
    tooltip?: string;
};
class TranslationModel {
    static defaultLanguages_cld: TTranslationItem[] = [
        {
            name: 'English',
            value: 'en',
            tooltip: '',
        },
        {
            name: 'French',
            value: 'fr',
            tooltip: 'Pour plus de résultats, sélectionnez « Anglais »',
        },
        {
            name: 'Spanish',
            value: 'es',
            tooltip: 'Para más resultados, seleccione inglés',
        },
    ];
    static defaultLanguages_ltd: TTranslationItem[] = [
        {
            name: 'English',
            value: 'en',
            translationKey: METADATA_HEADER_ENG_LANG,
        },
        {
            name: 'French',
            value: 'fr',
            translationKey: METADATA_HEADER_FRENCH_LANG,
        },
        {
            name: 'Spanish',
            value: 'es',
            translationKey: METADATA_HEADER_SPANISH_LANG,
        },
        {
            name: 'Russian',
            value: 'ru',
            translationKey: METADATA_HEADER_RUSSIAN_LANG,
        },
        {
            name: 'Chinese',
            value: 'zh',
            translationKey: METADATA_HEADER_CHINESE_LANG,
        },
    ];

    static mergeTrees = (translationJson: any, agencyTranslation?: any) => {
        /**
         * If there's no agencyTranslation right now in the agency,
         * then the translationJson will become the default translation tree.
         */
        if (!agencyTranslation) return translationJson;

        let node1 = { ...translationJson };
        let node2 = { ...agencyTranslation };
        const keys = _.uniq(Object.keys(node1).concat(Object.keys(node2)));
        for (let key of keys) {
            // If the current key is locale key (en, fr, de etc.)
            if (Object.keys(node1).includes(key) && !Object.keys(node2).includes(key)) node2[key] = node1[key];
            if (Object.keys(node1).includes(key) && Object.keys(node2).includes(key) && node2[key] === '') {
                node2[key] = node1[key];
            }
            if (typeof node1[key] === 'object') {
                node2[key] = TranslationModel.mergeTrees(
                    node1[key] as StaticTranslationNode,
                    node2[key] as StaticTranslationNode
                );
            }
        }
        return node2;
    };

    static traverse(currentNodeKey: string, translation: StaticTranslationNode): TreeNode {
        let title: string;
        title = currentNodeKey[0].toUpperCase();
        title += currentNodeKey.substr(1);
        for (let ch = 0; ch < title.length; ch++) {
            if (ch !== 0 && title[ch] >= 'A' && title[ch] <= 'Z') {
                title = title.substr(0, ch) + ' ' + title.substr(ch);
                ch++;
            }
        }
        let treeNode: TreeNode = {
            title: title,
            id: currentNodeKey,
            description: '',
            children: [],
            expanded: false,
        };
        for (let key in translation) {
            if (typeof translation[key] !== 'object' && key === 'label') {
                treeNode.description = translation[key] as string;
            }
            if (translation[key] !== null && typeof translation[key] === 'object') {
                //going one step down in the object tree!!
                treeNode.children.push(TranslationModel.traverse(key, translation[key] as StaticTranslationNode));
            }
        }
        return treeNode;
    }

    static treeBuilderWithoutTranslation(translation: StaticTranslationNode): TreeNode[] {
        let sortableTree: TreeNode[] = [];
        for (let i in translation) {
            if (typeof translation[i] === 'object') {
                sortableTree.push(TranslationModel.traverse(i, translation[i] as StaticTranslationNode));
            }
        }
        return sortableTree;
    }

    static getLeaves(data: StaticTranslationNode, pathFromRoot: string = ''): TLeaf[] {
        let leaves: TLeaf[] = [];
        const node = pathFromRoot ? (_.get(data, pathFromRoot) as StaticTranslationNode) : data;
        let leaf: TLeaf = { pathFromRoot, translations: [] };
        for (let key in node) {
            if (node[key] && typeof node[key] === 'string' && key !== 'label') {
                leaf.translations.push({
                    lang: key as TranslationLocale,
                    value: node[key] as string,
                });
            }
            if (node[key] && typeof node[key] === 'object') {
                leaves = leaves.concat(
                    TranslationModel.getLeaves(
                        data as StaticTranslationNode,
                        pathFromRoot ? pathFromRoot + `.${key}` : key
                    )
                );
            }
        }
        leaf.translations.length > 0 && leaves.push(leaf);
        return leaves;
    }

    static getUsedLanguages(node: StaticTranslationNode): TranslationLocale[] {
        let locales: TranslationLocale[] = [];
        for (let key in node) {
            if (node[key] && typeof node[key] === 'string' && key !== 'label') {
                locales.push(key as TranslationLocale);
            }
            if (node[key] && typeof node[key] === 'object') {
                locales = locales.concat(TranslationModel.getUsedLanguages(node[key] as StaticTranslationNode));
            }
        }
        return _.uniq(locales);
    }
}

export default TranslationModel;
